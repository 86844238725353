import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { OnDutyService } from 'src/app/@core/services/on-duty.service';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
@Component({
  selector: 'app-view-on-duty-request',
  templateUrl: './view-on-duty-request.component.html',
  styleUrls: ['./view-on-duty-request.component.scss']
})
export class ViewOnDutyRequestComponent implements OnInit {

  @Input() loader      : any = false;
  @Input() viewDetails : any;
  @Input() onDutyData  : any;
  @Input() onDutyID    : any;
  @Input() from        : any
  @Input() onDutyCancel: any

  @Output() closePanel           = new EventEmitter()

  viewstatusPanel      : boolean = false
  statusLoader         : boolean = false
  cancelReq            : boolean = false
  viewStatusData       : any
  viewStatusLevelCnt   : any
  viewStatusmaxLevel   : any
  emp_ID               : any
  cancelId             : any
  status               : any
  specificId           : any;

  constructor(
    private onDutyServ : OnDutyService,
    public appServ    : AppService,
    private notificationService: NotificationService,
    private investmentService: InvestmentDeclerationService,
  ) {
    this.investmentService.request_id.subscribe(result =>{
      if(Number(this.onDutyID) == Number(result))
        this.statusData(this.specificId);
    })
   }

  ngOnInit(): void {}

  statusData(id:any){
    this.viewstatusPanel = true;
    this.statusLoader    = true;
    this.specificId      = id;
    if(typeof this.onDutyID === 'number' && typeof id === 'number'){
      this.onDutyServ.viewStatusData(this.onDutyID,id).subscribe((res:any)=>{
        this.viewStatusData = res.body;
        if(!res?.body?.apporval_matrix_loading)
          this.statusLoader = false
        this.viewStatusLevelCnt = res?.body?.activity?.level_count
        this.viewStatusmaxLevel = res?.body?.activity?.maxlevel
      })
    }
  }

  cancelRequest(id:any,status:any,cancelStatus:any,ot:any,comp:any,arrear:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("On-duty request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else if(ot){
      this.notificationService.handleErrorNotification("OT request is already approved.","Error");
    }else if(comp){
      this.notificationService.handleErrorNotification("Comp off request is already approved.","Error");
    }else{
      this.cancelId = id
      this.cancelReq = true
      this.status = status
    }
  }

  close(e:any){
    this.cancelReq = e.show
    this.closePanel.emit(false)
  }
}
