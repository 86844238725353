import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExpenseHRReportsService } from 'src/app/@core/services/expense-hrreports.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-expense-main-filter',
  templateUrl: './expense-main-filter.component.html',
  styleUrls: ['./expense-main-filter.component.scss']
})

export class ExpenseMainFilterComponent implements OnInit {

  @Input() companyDD             : any;
  @Input() buDD                  : any;
  @Input() DesignationDD         : any;
  @Input() BranchDD              : any;
  @Input() DepDD                 : any;
  @Input() gradeDD               : any;
  @Input() empstatusDD           : any;
  @Input() emptypeDD             : any;
  @Input() checkListElements     : any;
  @Input() pageNumber            : any;
  @Input() expensePolicyDropdown : any;
  @Input() advancePolicyDropdown : any;
  // Above needs to be removed
  @Input() expenseMainForm       : any;
  @Input() ouFilterForm          : any;
  @Input() employeeDropdown      : any;
  @Input() viewDetail            : any;
  @Input() submitted             : any;
  @Input() resDataFilter         : any;
  @Input() disabled              : any;
  @Input() callReport            : any;
  @Input() salFilter             : any;
  @Input() closeControl          : any;
  @Input() lastPage              : any;
  @Input() reportName            : any;
  @Input() ordering              : any;

  @Output() callReports  = new EventEmitter();
  @Output() filterPanel  = new EventEmitter();
  @Output() resetFilter  = new EventEmitter();
  @Output() submitDone   = new EventEmitter();

  reimbTypeDD = [
    {id:'Manual',    name:"Manual"},
    {id:'Via Payroll',    name:"Via Payroll"},
  ];

  applyBtn            : boolean = false;
  initialValue        :any      = [];
  dataFilter          :any      = [];
  hasChange           :boolean  = false;

  dateFormat = ''

  constructor(
    public messageService: MessageService,
    private reportService: ReportsService,
    public appservice: AppService,
    public cd: ChangeDetectorRef,
    public expenseServ: ExpenseHRReportsService
  ) { }

  ngOnInit(): void {
    this.getDateFormat();
    this.selectAllForDropdownItems(this.reimbTypeDD);
    this.initialValue = this.expenseMainForm.value;

  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewDetail'] && changes['viewDetail'].currentValue) {
      // Dropdowns
      this.getAdvancePolicyList();
      this.getExpensePolicyList();
    }
  }
  getDateFormat(){
    this.dateFormat = this.appservice.getdatepickerformat()
    if(this.dateFormat == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  filterFormData(){
    let company            = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu                 = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept               = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig              = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch             = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list      = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade              = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type      = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let expense_policy     = this.stringifyCont(this.expenseMainForm.controls.expense_policy.value);
    let advance_policy     = this.stringifyCont(this.expenseMainForm.controls.advance_policy.value);
    let reimb_type         = this.stringifyCont(this.expenseMainForm.controls.reimb_type.value);

    return `company=${company}&bu=${bu}&department=${dept}&designation=${desig}&branch=${branch}&grade=${grade}&employee_list=${employee_list}&emp_status=${employment_status}&emp_type=${employee_type}&advance_policy=${advance_policy}&expense_policy=${expense_policy}&ordering=${this.ordering}&reimbursement_type=${reimb_type}`
  }


  submitFilterComponent(){
    this.submitted  = true;
    if (this.expenseMainForm.invalid) {
      return;
    }
    let offset      = 0;
    let limit       = 20;
    offset          = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber);

    // test start
    let formData   = this.filterFormData();
    this.submitDone.emit({
        limit      : limit,
        offset     : offset,
        filterForm : formData
    })
    // test end
    this.viewDetail = false;
    this.applyBtn   = false;
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  applyTagClose1(){
    if(this.closeControl == 'advance_policy' || this.closeControl == 'expense_policy' || this.closeControl == 'reimb_type'){
      this.expenseMainForm.get(this.closeControl)?.setValue('');
    }else{
      this.ouFilterForm.get(this.closeControl)?.setValue('')
    }
    this.submitFilterComponent();
  }

  getData() {

    const data = JSON.stringify({'company' : this.ouFilterForm.controls.company?.value == null ? '' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value == null ? '' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value == null ? '' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value == null ? '' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value == null ? '' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type.value, 'branch' : this.ouFilterForm.controls.branch.value == null ? '' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value == null ? '' : this.ouFilterForm.controls.employee_list.value,'expense_policy':this.expenseMainForm.controls.expense_policy?.value == null ? '' : this.expenseMainForm.controls.expense_policy?.value,'advance_policy':this.expenseMainForm.controls.advance_policy?.value == null ? '' : this.expenseMainForm.controls.advance_policy?.value,'reimbursement_type' : this.expenseMainForm.controls.reimb_type?.value == null ? '' : this.expenseMainForm.controls.reimb_type?.value});

    const emptyKeys = Object.keys(data)
    .filter((key:any) => this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }


  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.expenseMainForm.get('advance_policy')?.reset('');
      this.expenseMainForm.get('expense_policy')?.reset('');
      this.expenseMainForm.get('reimb_type')?.reset('');
    }
    this.callReports.emit({
      reportName : this.reportName
    })
  }

  resetPage(val:any){
    this.applyBtn = true;
    this.reportService.calculateOffset(0);
    this.cd.detectChanges();
  }

  clearcommonForm(item:any,index:any){
    if(item == "expense_policy"){
      let daata = this.expenseMainForm.value.expense_policy;
      daata.splice(index, 1);
      this.expenseMainForm.patchValue({
        expense_policy: daata
      });
    }
    if(item == 'reimb_type'){
      let daata = this.expenseMainForm.value.reimb_type;
      daata.splice(index, 1);
      this.expenseMainForm.patchValue({
        reimb_type: daata
      });
    }
    if(item == "advance_policy"){
      let daata = this.expenseMainForm.value.advance_policy;
      daata.splice(index, 1);
      this.expenseMainForm.patchValue({
        advance_policy: daata
      });
    }
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail);
  }

  // Select ALL option for Multiselect
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Expense Policy List
  getExpensePolicyList() {
    this.expenseServ.getExpensePolicyDropdown().subscribe((res: any) => {
      this.expensePolicyDropdown = res;
      this.selectAllForDropdownItems(this.expensePolicyDropdown);
    })
  }

  // Advance Policy List
  getAdvancePolicyList() {
    this.expenseServ.getAdvancePolicyDropdown().subscribe((res: any) => {
      this.advancePolicyDropdown = res;
      this.selectAllForDropdownItems(this.advancePolicyDropdown);
    })
  }


}
