<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='shortLeaveView === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>View</h5>
      <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32 py-24">
        <div class="d-flex mb-16">
          <p class="text-uppercase fw-500 mb-0">SHORT LEAVE REQUEST</p>
        </div>
        <app-view-loader *ngIf="loader"></app-view-loader>
          <ng-container *ngIf="!loader">
            <div class="row row-24 mb-24">
              <div class="col-12">
                <div class="card details-card shadow-0 px-24 py-16">
                    <div class="row row-24">
                        <div class="col-12 d-flex">
                            <p class="title mb-8">LEAVE DETAILS</p>
                            <span class=" fs-14 ms-auto px-2 fw-500 badge3" [ngClass]="{'badge-warning': shortLeaveData?.status === 'Pending','badge-success': shortLeaveData?.status === 'Accepted','badge-danger': shortLeaveData?.status === 'Rejected'}"  >
                              {{shortLeaveData?.status}}
                            </span>
                        </div>
                        <div class="col-12">
                          <div class="card card-c2 p-16">
                              <div class="row row-24">
                                <div class="col-md-4">
                                  <div class="detail-title">Date</div>
                                  <div class="detail-desc">{{this.appServ.dateFormatDisplay(shortLeaveData?.date)}}</div>
                                </div>
                                <!-- <div class="col-md-4">
                                  <div class="detail-title">Total Duration</div>
                                  <div class="detail-desc">{{durationToHours(shortLeaveData?.total_duration)}} Hours</div>
                                </div> -->
                                <div class="col-12" *ngIf="shortLeaveData?.short_leave_portion?.length">
                                  <ng-container *ngFor="let data of shortLeaveData?.short_leave_portion">
                                    <div class="row row-16">
                                      <div class="col-sm-4 col-12">
                                        <div class="detail-title">From time</div>
                                        <div class="detail-desc">{{this.appServ.timeFormatDisplay(data?.from_time)}}</div>
                                      </div>
                                      <div class="col-sm-4 col-12">
                                          <div class="detail-title">To time</div>
                                          <div class="detail-desc">{{this.appServ.timeFormatDisplay(data?.to_time)}}</div>
                                      </div>
                                      <div class="col-sm-4 col-12">
                                          <div class="detail-title">Duration</div>
                                          <div class="detail-desc">{{this.appServ.formatTime(data?.duration)}}</div>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                                <div *ngIf="shortLeaveData?.short_leave_portion?.length == 0">-</div>
                                <div class=" col-12">
                                  <div class="detail-title">Reason</div>
                                  <div class="detail-desc">{{shortLeaveData?.reason}}</div>
                                </div>
                                <div class=" col-12" *ngIf="requestName == 'Short Leave Cancellation'">
                                  <div class="detail-title">Reason for cancellation</div>
                                  <div class="detail-desc">{{shortLeaveData?.remark}}</div>
                                </div>
                                <div class=" col-12">
                                  <div class="detail-title">Document </div>
                                  <div class="detail-desc" *ngIf="shortLeaveData?.document == ''">-</div>
                                  <div class="detail-desc" *ngIf="shortLeaveData?.document != ''">
                                      <div class="card card-c2  bg-secondary">
                                        <div class="p-12 bg-secondary d-flex align-item-center">
                                          <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                            <span class="tips text-uppercase fs- fw-700 text-trim">{{this.appServ.getDocName(shortLeaveData?.document)}}</span>
                                            <span class="tips-helper ">{{this.appServ.getDocName(shortLeaveData?.document)}}</span>
                                            <div class="d-flex gap-8">
                                              <div class="link-primary" (click)="viewDocument(shortLeaveData?.document)">
                                                <i class="bi bi-eye me-1"></i>View
                                              </div>
                                              <div class="link-primary"
                                                (click)="downLoad(shortLeaveData?.document)">
                                                <i class="bi bi-download me-1"></i>Download
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
               </div>
            </div>
          </ng-container>
      </div>
    </div>
    <ng-container *ngIf="requestName != 'Short Leave Cancellation'">
      <footer class="side-panel-footer" *ngIf="(shortLeaveData?.status == 'Pending' || shortLeaveData?.status == 'Accepted' ) && !shortLeaveData?.cancellation_raised_status">
        <button type="submit" class="ms-auto btn btn-outline-danger text-uppercase btn-sm" (click)="cancelRequest(shortLeaveData?.status,shortLeaveData?.cancellation_raised_status)">
            CANCEL REQUEST
        </button>
      </footer>
    </ng-container>
  </div>
</div>

<!-- Rquest cancel section -->
<app-cancel-request-modal [requestCancel]="requestCancel" [requestName]="'Short Leave'" [requestId]="requestId" [requestStatus]="shortLeaveData?.status" (modal)="closeCancelModal($event)"></app-cancel-request-modal>


<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Activities</h5>
            <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>

        <app-view-status [loader]="statusLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData" [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>

    </form>
  </div>
</ng-container>
<!-- end view status panel -->

<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close1($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
