import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-team-employee-details',
  templateUrl: './my-team-employee-details.component.html',
  styleUrls: ['./my-team-employee-details.component.scss']
})

export class MyTeamEmployeeDetailsComponent implements OnInit {

  activeTab          = 1;
  navTab             = 1;
  view               = 1 ;
  isChecked: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
