import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel } from '@angular/forms';
import { FileUploadService } from 'src/app/@core/services/file-upload.service';
import { MessageService } from 'src/app/message.global';
declare var $: any;
@Component({
  selector: 'app-letter-template-edit-template',
  templateUrl: './letter-template-edit-template.component.html',
  styleUrls: ['./letter-template-edit-template.component.scss']
})
export class LetterTemplateEditTemplateComponent implements OnInit {
  @Input() formGp        !: FormGroup
  @Input() disabled = false
  @Input() tagList: any
  @Input() templateData: any

  @Output() submitMethod = new EventEmitter()
  @Output() fontDeatils = new EventEmitter()

  @ViewChild('summernoteEditor') summernoteEditor !: ElementRef;

  recentlyUsed: any = []
  submitted: boolean = false
  showAlert: boolean = true
  tagsArr: any = []
  searchString: any = []
  valueChanged: boolean = false
  imgModal: boolean =false
  collapsedclass1: boolean = false
  imgFile : any = ''
  imgUrl : any = ""
  isFormat : boolean = false
  isSize : boolean = false
  hintModal : boolean = false
  linkModal : boolean = false
  linkUrl: string = '';
  linkText: string = '';
  openInNewWindow: boolean = true;
  useDefaultProtocol: boolean = true;
  isTextRequired : boolean = false
  isURLRequired : boolean = false
  submitDone : boolean = false

  constructor(
    private cdr: ChangeDetectorRef,
    public messageService: MessageService,
    private elementRef: ElementRef,
    public upload : FileUploadService
  ) {}
  config = {
    placeholder: 'Enter text here',
    tabsize: 2,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear'
          ]
        ]
      ]
    },
    focus: true,
    uploadImagePath: '/upload',
    toolbar: [
      ['edit', ['undo', 'redo']],
      ['style', ['style']],
      // 'strikethrough','superscript','subscript','italic'
      ['font', ['bold', 'italic', 'underline', 'clear']],
      ['para', ['ul', 'ol', 'paragraph', 'lineheight']],
      ['fontsize', ['fontname', 'fontsize']],
      ['color', ['color']],
      ['table', ['table']],
      ['insert', ['hr']],
      ['view', ['codeview']],
      ['customButtons', ['linkBtn','imgBtn','hintBtn']],
    ],
    buttons: {
      imgBtn : this.imgCustomButton.bind(this),
      hintBtn : this.hintCustomButton.bind(this),
      linkBtn : this.linkCustomButton.bind(this)
    },
    fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '36'],
    fontNames: ['Courier New','Georgia','Tahoma','Times New Roman','Trebuchet MS', 'Work Sans'],
  };

  ngOnInit(): void {
    this.setDropdownValues();
    this.templateData.valueChanges.subscribe((newValue: any) => {
      this.recentlyUsed = []
      this.formGp.get('template')?.reset(null)
    });
    if (this.templateData.get('templateID')?.value != 0 && this.formGp.invalid) {
      this.formGp.patchValue({
        template: this.templateData.get('templateID')?.value
      })
      let validJSONString = this.templateData.get('tags')?.value.replace(/'/g, '"');
      this.recentlyUsed = this.pushWithoutDuplicates(JSON.parse(validJSONString))
      this.fontName = 'Work Sans'
    }
    this.formGp.get('template')?.valueChanges.subscribe((val) => {
      this.onContentChange(val);
      const regex = /^<p><span[^>]*>(?:﻿(?:&nbsp;|\s)*)?<\/span><br><\/p>$/;
      if (regex.test(val)) {
        this.formGp.get('template')?.reset(null);
        this.formGp.updateValueAndValidity()
      }
    });
    if (this.formGp.get('recentTags')?.value && this.formGp.get('recentTags')?.value?.length != 0) {
      this.recentlyUsed = []
      this.recentlyUsed = this.formGp.get('recentTags')?.value
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.formGp?.get('template')?.invalid) {
        const defaultFontOption = this.elementRef.nativeElement.querySelector('.note-dropdown-item[data-value="Work Sans"]');
        defaultFontOption?.click();
        this.summernoteEditor?.nativeElement?.focus()
      }
      if (this.formGp.get('template')?.value != null) {
        this.onContentChange(this.formGp.get('template')?.value);
      }
    }, 400);
  }

  pushWithoutDuplicates(source: any) {
    return [...new Set(source)];
  }

  // Validate
  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.formGp.get('recentTags')?.setValue(this.recentlyUsed)
    this.fontDeatils.emit(this.fontName)
    this.submitMethod.emit(3);
  }

  fontName: any = null
  onContentChange(val: any) {
    let data = document.getElementsByClassName('checked') as HTMLCollection
    this.fontName = this.getVal(data.length == 0 ? '' : data[0]?.innerHTML)
    if (this.hasContentWithoutJustTags(val)) {
      this.formGp.get('template')?.setErrors({ 'notValid': true })
    }
  }

  hasContentWithoutJustTags(htmlString: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    if (doc && doc.body) {
      const hasContent = doc.body.hasChildNodes() && !!(doc.body.textContent && doc.body.textContent.trim());
      const hasNoImages = htmlString != null && htmlString.includes('<img');
      if (hasContent && hasNoImages) {
        return false;
      } else if (hasContent || hasNoImages) {
        return false;
      } else {
        return true;
      }
    }else{
      return false
    }
  }


  getVal(innerHTML: any) {
    var match = innerHTML.match(/style="font-family: '([^']*)'/);
    if (match && match.length > 1) {
      return match[1];
    } else {
      return null;
    }
  }

  onDragStart(event: DragEvent, tag: any) {
    this.fontName == null ? 'sans-serif' : this.fontName
    const formattedText = `&nbsp;<span style="font-family : ${this.fontName};font-size: 15px; word-break:break-all;" contenteditable="false">{{${tag}}}</span>&nbsp;`;
    event.dataTransfer?.setData('text/html', formattedText);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    const draggedText = event.dataTransfer?.getData('text/html');
    const match = draggedText?.match(/{{([^<>]+)}}/);
    const extractedValue = match ? match[1] : null;
    const isDroppedFromRecentlyUsed = this.recentlyUsed.includes(extractedValue);
    if (!isDroppedFromRecentlyUsed) {
      this.recentlyUsed.push(extractedValue)
    }
  }

  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  setDropdownValues() {
    const allInnerValues: any = Object.values(this.tagList).reduce((acc: any, innerArray) => acc.concat(innerArray), []);
    this.tagsArr = allInnerValues.map((name: any, index: any) => ({
      id: index + 1,
      name: this.removeUnderscoreAddUpperCase(name),
      value: name
    }));
    this.selectAllForDropdownItems(this.tagsArr)
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  applyTagClose(val: any) {
    const index = this.searchString.indexOf(val);
    if (index > -1) {
      this.searchString = [...this.searchString.slice(0, index), ...this.searchString.slice(index + 1)];
      this.cdr.detectChanges()
    }
  }

  // CUSTOM BUTTON SETUPS

  // Custom Img Setup
  showImgModel(){
    this.imgModal = true
    this.cdr.detectChanges();
  }

  // IMG custom button
  contextVal : any
  imgCustomButton(context:any) {
    const ui = $.summernote.ui;
    const button = ui.button({
      contents: '<i class="note-icon-picture"></i>',
      tooltip: 'Picture',
      container: '.note-editor',
      className: 'note-btn',
      click: () => {
        this.contextVal = context
        this.showImgModel();
      }
    });
    return button.render();
  }

  // Custom Hint Setup
  showHintModel(){
    this.hintModal = true
    this.cdr.detectChanges();
  }

  // Hint custom button
  hintCustomButton(context:any){
    const ui = $.summernote.ui;
    const button = ui.button({
      contents: '<i class="note-icon-question"></i>',
      tooltip: 'Help',
      container: '.note-editor',
      className: 'note-btn',
      click: () => {
        this.contextVal = context
        this.showHintModel();
      }
    });
    return button.render();
  }

  // Custom Hint Setup
  showLinkModel(){
    this.linkModal = true
    this.cdr.detectChanges();
  }

  // Link custom button
  linkCustomButton(context:any){
    const ui = $.summernote.ui;
    const button = ui.button({
      contents: '<i class="note-icon-link"></i>',
      tooltip: 'Insert Link',
      container: '.note-editor',
      className: 'note-btn',
      click: () => {
        this.contextVal = context
        this.showLinkModel();
      }
    });
    return button.render();
  }

  // IMG VALIDATIONS FILE & URL
  fileUrl : any
  validateImg(ev:any,type:any){
    let url :any;
    let validExt = ['jpeg','jpg','png']
    const regrex1 = /^data:image\/(jpeg|jpg|png);base64,/
    const regrex2 = /\.(jpeg|jpg|png)$/i
    if(type == 'file'){
      this.imgUrl = ""
      if(this.imgFile != null && this.imgFile != ''){
        const extension       = ev?.target?.files[0]?.name.split('.').pop();
        url = extension?.toLowerCase();
        const file            = ev?.target?.files[0];
        if(Number(file?.size) <= (5*1024*1024)) {
          this.isSize = false
          const imageName       = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = event => {
            this.upload.fileUpload({name:imageName, dataURL:reader.result}).subscribe((res: any) => {
              if(res.status == 200) {
                this.fileUrl = res.body.data_url
              }
            })
          }
        }else{
          this.isSize = true
        }
        this.isFormat = !validExt.some((item:any) => item == url)
      }else{
        this.isFormat = false;
        this.isSize = false
      }
    }else{
      this.imgFile = ""
      if(this.imgUrl != '' && this.imgUrl != null && this.imgUrl.trim().length !== 0){
        if (regrex1.test(ev)) {
          const base64Data = ev.split(',')[1];
          try {
              atob(base64Data);
              this.isFormat = false;
          } catch (error) {
              this.isFormat = true;
          }
        }else if(regrex2.test(ev)){
          url = ev?.split('.')
          this.isFormat = !validExt.some((item:any) => item == url[url?.length-1])
        }else{
          this.isFormat = true
        }
      }
    }
    if((this.imgFile == '' && this.imgFile == null) || (this.imgUrl == '' && this.imgUrl == null)){
      this.isFormat = false
      this.isSize = false
    }
  }

  // IMG SUBMIT
  imgSubmit(){
    let url;
    if(this.imgFile != '' && this.imgFile != null){
       url = this.fileUrl
     }else{
       url = this.imgUrl
     }
    if(this.isFormat || url == undefined){
      return
    }
    this.contextVal.invoke('editor.saveRange');
    this.contextVal.invoke('editor.restoreRange');
    this.contextVal.invoke('editor.focus');
    this.contextVal.invoke('editor.insertImage',url)
    this.submitDone = true
    this.imgModal = false
    this.isFormat = false
    this.imgFile = ''
    this.imgUrl = ''
    this.isSize = false
  }

  // LINK VALIDATION
  checkLinkUrl(linkUrl: string): string {
    const MAILTO_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const TEL_PATTERN = /^(\+?\d{1,3}[\s-]?)?(\d{1,4})[\s-]?(\d{1,4})[\s-]?(\d{1,4})$/;
    const URL_SCHEME_PATTERN = /^([A-Za-z][A-Za-z0-9+-.]*\:|#|\/)/;
    if (MAILTO_PATTERN.test(linkUrl)) {
      return 'mailto://' + linkUrl;
    } else if (TEL_PATTERN.test(linkUrl)) {
      return 'tel://' + linkUrl;
    } else if (!URL_SCHEME_PATTERN.test(linkUrl)) {
      return 'http://' + linkUrl;
    }
    return linkUrl;
  }

  // text input validation
  textValid(){
    this.isTextRequired  = this.linkText.trim().length == 0
  }

  // url input validation on change
  urlValid(){
    this.isTextRequired  = this.linkText.trim().length == 0
    this.isURLRequired = this.linkUrl.trim().length == 0
    this.isFormat = !this.isValidUrl(this.linkUrl)
  }

  // validation check for url
  isValidUrl(str:any) {
    const pattern = new RegExp(
      '^(https?:\\/\\/|mailto:|tel:)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3})|' +
      '(localhost))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    const isMailtoOrTel = str.toLowerCase().startsWith('mailto:') || str.toLowerCase().startsWith('tel:');
    if (isMailtoOrTel) {
      return true;
    }
    return pattern.test(str);
  }

  // LINK SUBMIT
  linkSubmit(){
    this.isTextRequired  = this.linkText.trim().length == 0
    this.isURLRequired = this.linkUrl.trim().length == 0
    if(this.isFormat || this.isTextRequired || this.isURLRequired){
      return
    }
    let url = this.linkUrl;
    if (this.useDefaultProtocol) {
      url = this.checkLinkUrl(this.linkUrl);
    }
    if(this.linkText.trim().length != 0 && this.linkUrl.trim().length != 0){
      const link = $('<a>')
      .attr('href', url)
      .attr('target', this.openInNewWindow ? '_blank' : '_self')
      .text(this.linkText);
      this.contextVal.invoke('editor.saveRange');
      this.contextVal.invoke('editor.restoreRange');
      this.contextVal.invoke('editor.focus');
      this.contextVal.invoke('editor.insertNode',link[0])
    }
    this.linkModal = false
    this.linkUrl = ''
    this.linkText = ''
    this.openInNewWindow = true
    this.useDefaultProtocol = true
  }

}


