import { Component, OnInit,Input,Output,EventEmitter, Inject } from '@angular/core';
import { HealthInsuranceService } from 'src/app/@core/services/health-insurance.service';
import { FormBuilder,Validators,FormArray,FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';

@Component({
  selector: 'app-health-insurance',
  templateUrl: './health-insurance.component.html',
  styleUrls: ['./health-insurance.component.scss']
})
export class HealthInsuranceComponent implements OnInit {
  showSidePanel         = false;
  loader                = true;
  submitted             = false;
  editSubmitted         = false;
  isrequired            = true;
  showSidePanelEdit     = false;
  createSubmit          = false;
  deletedInsId          = 0;
  currentYear:number    = 0;
  gender:string         = "gender";
  genderPlaceHolder     = this.messageService.selectplaceholddisplay('gender');
  header:any            = "";
  loader1               = false;
  deleteToggle          = false;
  permissions:any       = [];
  showInfo              = false;
  infoMsg:any;
  heading = 'Add';
  deleteIndex : any;
  currentInsIndex:any;
  insuranceFormArray    = this.formBuilder.group({
                            insurance:this.formBuilder.array([])
                        });

  @Input() insuranceData:any;
  @Input() id:any;
  @Input() is_employeeRequest:any;
  @Output() insuranceSubmit        = new EventEmitter
  @Output() deleteInsuranceSubmit  = new EventEmitter;
  @Output() editInsuranceSubmit    = new EventEmitter;
  @Output() loadData               = new EventEmitter();
  @Input() routingUrl:any;
  @Input() loginAs:any;
  @Input() from:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;

  constructor(@Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
   public appservice:AppService,private insuranceService:HealthInsuranceService,private formBuilder:FormBuilder,public messageService : MessageService,private datepipe:DatePipe) { }

  ngOnInit(): void {
    this.getPermission();
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.loader       = false;
    this.currentYear  = new Date().getFullYear();
    this.loadData.emit();

  }
  getPermission(){
    this.permissions = this.appservice.getPermissions(this.routingUrl);
     if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getInsuranceData(){
    this.insuranceService.getInsuranceData(this.id).subscribe((res:any)=>{
      if(res.health_insurance.length>0)
      this.insuranceData = Object.values(res.health_insurance);
    })
  }

  createInsurance():FormGroup{
    return this.formBuilder.group({
                            id                :[null],
                            employee          :[this.id],
                            insuree_name      :['',[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
                            insuree_of_birth  :[,[Validators.required]],
                            insuree_gender    :[,[Validators.required]],
                            assured_sum       :[,[digitdecimalValidator(),Validators.maxLength(10)]],
                            insurance_company :[,[Validators.maxLength(100),UnamepatternValidator()]],
                            company_code      :[,[Validators.maxLength(100),UnamepatternValidator()]],
                            insurance_code    :[,[Validators.maxLength(100),UnamepatternValidator()]],
                            issue_date        :[],
                            valid_upto        :[],

                          },
                          { validators: CustomDateValidators.fromToDate('issue_date', 'valid_upto') }
    )
  }

  insurance() {
    return this.insuranceFormArray.get('insurance') as FormArray;
  }

  addInsurance() {
    this.insurance().push(this.createInsurance());
  }

  editInsurance(){
    this.insuranceFormArray.markAsUntouched()
    
    this.heading = 'Edit'
    if(this.is_employeeRequest?.is_health_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo            = false;
      this.editSubmitted  = false;

      this.loader1            = true;
      this.getInsuranceData();
      this.showSidePanel  = true;
      this.loader1            = false;

       let insuranceArr= []
      for(let key=0;key<this.insuranceData.length;key++){
        this.insurance().removeAt(key);
        this.addInsurance()
        insuranceArr.push({
         "insuree_name":this.insuranceData[key].insuree_name,
         "insuree_of_birth":this.insuranceData[key].insuree_of_birth,
         "insuree_gender":this.insuranceData[key].insuree_gender,
         "id":this.insuranceData[key].id,
         "employee":this.insuranceData[key].employee,
         "assured_sum":this.insuranceData[key].assured_sum,
         "insurance_company":this.insuranceData[key].insurance_company,
         "company_code":this.insuranceData[key].company_code,
         "insurance_code":this.insuranceData[key].insurance_code,
         "issue_date":this.insuranceData[key].issue_date,
         "valid_upto":this.insuranceData[key].valid_upto,
      });
    }

    this.insuranceFormArray.reset({
      insurance : insuranceArr
    })
    }

  }
  onSubmit(){

    if(this.insurance().invalid){
      for(let i in this.insurance().controls)
      this.insurance().controls[i].markAsTouched();
      return;
    } else {
      this.submitted  = true;
    }
    this.insuranceSubmit.emit(this.insurance().value);
    this.showSidePanel  = false;
  }

  addNewInsurance(){
    this.insuranceFormArray.markAsUntouched()
    if(this.is_employeeRequest?.is_health_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo            = false;
      this.showSidePanel  = true;
      this.submitted      = false;
      if(this.insurance().length==0){
        this.addInsurance()
      }
    }
  }

  deleteInsurance(index:number,id:any){
    if(id!==null && id!=''){
      this.deleteToggle     = true;
      this.deletedInsId     = this.insuranceData[index].id;
      this.currentInsIndex  = index;

    }
    else
    this.insurance().removeAt(index);


  }
  deleteInsuranceConfirm(){
    this.insurance().removeAt(this.currentInsIndex);
    this.deleteToggle       = false;
    this.deleteInsuranceSubmit.emit(this.deletedInsId);
  }

  disableDate(){
    return false;
  }
  issueDateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 10, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  validDateFilter(d:any){
    let dateRange = [new Date(),
      new Date(new Date().getFullYear() + 10, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  dobFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  dateChangeFunction(e:any,index:any,type:any,form:any){
    if(form == 'add'){
      if(type == 'valid_upto'){

        this.insurance().controls[index].get('issue_date')?.setValidators([Validators.required]);
        this.insurance().controls[index].get('issue_date')?.updateValueAndValidity();

      }else{
        this.insurance().controls[index].get('valid_upto')?.setValidators([Validators.required]);
        this.insurance().controls[index].get('valid_upto')?.updateValueAndValidity();
      }
    }
   }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }

}
