<form [formGroup]="formGp"  >
  <div class="container-fluid p-32 form-container">
      <div class="row row-16">
        <div class="col-12 d-flex">
          <p  class="text-uppercase fw-500 mb-0">MAP PAY COMPONENTS</p>
          <div class="ms-auto" style="width:22.688rem">
            <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='paymentPlaceholder' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchpaygroup($event)"></app-searchbar>

          </div>
        </div>
        <app-c1-loader *ngIf="loader"></app-c1-loader>
        <ng-container *ngIf="!loader">

          <div class="col-12 h-100" *ngIf="paycomponentlist?.length == 0">
            <!-- <div  role="alert" class="alert alert-warning mb-0 {{alertShow}}"> -->
              <!-- <p class="mb-0" *ngIf="paycomponentlist.length>0">
                <span class="text-warning">Note:</span> The Pay component’s will be listed in the same  order as below in the pay slip.
                <button class="btn-close" type="button" (click)="alertShow = '' "></button>
              </p> -->
              <!-- <p class="mb-0">
                <span class="text-warning">Note:</span> There are no pay components added, kindly add pay component first to configure pay group.
              </p>
            </div> -->

            <!-- Updated design -->
            <div class="flex-center gap-8 flex-column p-16 h-100 " style="min-height: calc(100vh - 19rem);">
              <img src="/assets/images/helper/helper3.png" alt="" srcset="">
              <p class="mb-0 fs-12 lh-base">
                  No data to show
              </p>
            </div>
            <!-- End -->
          </div>
          <div class="col-12 mt-8" *ngFor="let item of paycomponentlist, let i =index" >
            <div class="card card-c2">
              <div class="card-body px-24 py-10 d-flex align-items-center">
                <div class="form-check">
                  <label for="{{item['name']}}" class="form-check-label ">{{item['name']}}</label>
                  <input type="checkbox" [disabled]="disabled" [checked]="(this.formGp.value.paycomponents.indexOf(item['id']+'') != -1)?true:false" class="form-check-input" name="" [value]="item['id']" id="{{item['name']}}" (change)="onCheckChange($event,item['name'])" >
                </div>

                <a *ngIf="this.formGp.value.paycomponents.indexOf(item['id']+'') != -1 && disabled == false" class="link-primary align-items-center fs-14 d-flex ms-auto" (click)="showSidePanel = true;getPayComponentDetails(item['id']);saveClicked=false"><i class="icon-pencil-square me-1"></i> Edit</a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validateConfigureFlow()">NEXT</button>
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid" (click)="validateConfigureFlow()">NEXT</button>
  </footer>
</form>
<!-- Slider add start-->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" autocomplete="off" [formGroup]="SingleForm">
      <header class="side-panel-head">
          <h5 class="text-trim pe-24">{{this.SingleForm.controls.name.value | uppercase}}</h5>
          <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
                  <div class="row  row-16 ">
                    <div class=" col-lg-6 form-row">
                        <label for="" class="form-label required">Component name</label>
                        <input type="text" class="form-control" autocomplete="off" [pattern]="namePattern"  name="name" id="name" placeholder="{{this.messageService.placeholderdisp('Component name')}}" formControlName="name" (focusout)="offKey()" (keyup)="keyUp()"  [ngClass]="{ 'is-invalid': (f.name.touched) &&  f.name.errors}">
                        <div *ngIf="(f.name.touched) &&  f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                          <div *ngIf="(f.name.touched  || f.name.dirty) &&  f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                          <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                          <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                          </div>
                        </div>
                       </div>
                      <div class="col-lg-6 form-row" [ngClass]="{ 'is-invalid': (f.component_type.touched) &&  f.component_type.errors}">
                        <label for="" class="form-label required">Component type </label>
                        <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('component type')}}" formControlName="component_type" (change)="makeDefaultSettings()" [ngClass]="{ 'is-invalid': (f.component_type.touched) &&  f.component_type.errors}">
                          <ng-option *ngFor="let comp_type of componenttypelist" [value]="comp_type['id']">{{comp_type['name']}}</ng-option>
                        </ng-select>
                        <div *ngIf="(f.component_type.touched) &&  f.component_type.errors" class="invalid-feedback">
                          <div *ngIf="f.component_type.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        </div>
                      </div>
                      <div class="row  mb-24" *ngIf="defaultCompName=='Perquisite'">
                        <div class="col-lg-6 form-row">
                          <label for="perquisite_type" class="form-label required">Perquisite type</label>
                          <app-master-select [ngClass]="{ 'is-invalid': (f.perquisite_type.touched) && f.perquisite_type.errors }" [placeholder]="this.messageService.selectplaceholddisplay('perquisite types')" id="perquisite_type" [submitted]="true" [isdisabled]="false" [selectedItem]="f.perquisite_type.value" formControlName="perquisite_type" [type]="'perquisite_type'" [isrequired]="true" (ngModelChange)="changeSection()"></app-master-select>
                          <div *ngIf="(f.component_type.touched) &&  f.component_type.errors" class="invalid-feedback">
                            <div *ngIf="f.component_type.errors.required">{{this.messageService.validationDisplay('required')}}</div>

                          </div>
                        </div>
                      </div>
                    <div class="col-12">
                      <div class="hr-1"></div>
                    </div>
                  </div>

                 <div class="row ">
                  <label for="" class="form-label required">Pay type </label>
                    <div class="col-lg-6 form-row mb-10">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" name="pay_type" type="radio" id="Addition" formControlName="pay_type" [value]="true"  (change)="handleChange1(true)" >
                          <label  for="addition" class="form-check-label"> Addition </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label  for="deduction" class="form-check-label"> Deduction </label>
                          <input class="form-check-input" name="pay_type" type="radio" id="Deduction"  formControlName="pay_type" [value]="false" (change)="handleChange1(false)">
                      </div>
                    </div>
                  </div>
                 <div class="row row-12">
                  <label for="" class="form-label required">Set whichever is possible</label>
                   <div class="col-12 mt-8">
                    <input type="hidden" formControlName="possible_checkbox">
                    <div class="form-check">
                        <label for="ctc" class="form-check-label ">Set this component as part of CTC</label>
                        <input type="checkbox" class="form-check-input" name="" id="part-ctc" formControlName="set_component_ctc" (change)="checkBoxCheck($event,1)" >
                    </div>
                   </div>
                   <div class="col-12 ">
                    <div class="form-check">
                        <label for="gross" class="form-check-label ">Set this component as part of gross</label>
                        <input type="checkbox" class="form-check-input" name="" id="part-gross" formControlName="set_component_gross" (change)="checkBoxCheck($event,2)">
                    </div>
                   </div>
                   <div class="col-12 form-row is-invalid">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" name="" id="part-salary" formControlName="pay_component_salary" (change)="checkBoxCheck($event,3)">
                      <label for="part-salary" class="form-check-label ">Set this component as part of salary structure</label>
                    </div>
                  </div>
                  <div class="col-12 form-row is-invalid">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" name="" id="part-reimbursable" formControlName="set_reimbursable" (change)="checkBoxCheck($event,4)">
                      <label for="part-reimbursable" class="form-check-label ">Set this component as reimbursable</label>
                    </div>
                  </div>
                  <div class="col-12 form-row is-invalid">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" name="" id="part-fbp" formControlName="set_fbp" (change)="checkBoxCheck($event,5)">
                      <label for="part-fbp" class="form-check-label ">Set this component as flexi benefit plan (FBP)</label>
                    </div>
                  </div>
                  <div class="col-12 form-row mt-24" *ngIf="f.set_fbp.value">
                    <label for="" class="form-label">Flexi benefit plan (FBP)</label>
                  </div>
                  <div class="col-12 form-row is-invalid mt-0" *ngIf="f.set_fbp.value">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" name="" id="part-fbp-amount" formControlName="restric_fbp_amount" (change)="checkBoxCheck($event,6)">
                      <label for="part-fbp-amount" class="form-check-label ">Restrict employee from overriding the FBP amount</label>
                    </div>
                  </div>
                </div>
                <div class="row mt-24">
                  <label for="" class="form-label required">Nature of payment </label>
                    <div class="col-lg-6 form-row mb-24">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" name="nature_payment" type="radio"  id="Fixed" formControlName="nature_payment" [value]="true" (change)="prorateRestrict()">
                          <label  for="fixed" class="form-check-label"> Fixed </label>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" name="nature_payment" type="radio" id="Variable" formControlName="nature_payment" [value]="false" (change)="prorateRestrict()">
                          <label for="variable" class="form-check-label"> Variable </label>
                      </div>
                    </div>
                 </div>
                 <div class="row">
                  <label for="" class="form-label required">Prorate as per paid days </label>
                    <div class="col-lg-6 form-row mb-24">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="prorate_per_day" id="flexRadioDefault1" formControlName="prorate_per_day" [value]="true" >
                          <label  for="yes" class="form-check-label"> Yes </label>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="prorate_per_day" id="flexRadioDefault2" checked formControlName="prorate_per_day" [value]="false">
                          <label for="no" class="form-check-label"> No </label>
                      </div>
                    </div>
                 </div>
                 <div class="row">
                  <label for="" class="form-label required">Arrear applicable </label>
                    <div class="col-lg-6 form-row mb-24">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="arrear_applicable" id="flexRadioDefault1" formControlName="arrear_applicable" [value]="true" >
                          <label  for="yes" class="form-check-label"> Yes </label>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="arrear_applicable" id="flexRadioDefault2" checked formControlName="arrear_applicable" [value]="false" >
                          <label for="no" class="form-check-label"> No </label>
                      </div>
                    </div>
                 </div>
                <div class="row">
                  <label for="" class="form-label required">Do you want to process in joining month</label>
                    <div class="col-lg-6 form-row mb-24">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="process_join_month" id="flexRadioDefault1" formControlName="process_join_month" [value]="true" >
                          <label  for="Yes" class="form-check-label"> Yes </label>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="process_join_month" id="flexRadioDefault2" checked formControlName="process_join_month" [value]="false" >
                          <label for="No" class="form-check-label"> No </label>
                      </div>
                    </div>
                 </div>
                 <div class="row">
                  <label for="" class="form-label required">Do you want to process in settlement month </label>
                    <div class="col-lg-6 form-row mb-24">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="process_settlement_month" id="flexRadioDefault1" formControlName="process_settlement_month" [value]="true">
                          <label  for="Yes" class="form-check-label"> Yes </label>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="process_settlement_month" id="flexRadioDefault2" checked formControlName="process_settlement_month" [value]="false">
                          <label for="No" class="form-check-label"> No </label>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="hr-1"></div>
                    </div>
                 </div>
				        <p class="text-uppercase fw-500 fs-16">Statutory details </p>

                <div class="row row-8">
                  <div class="col-12 form-row">
                    <label for="" class="form-label required">Select whichever is applicable</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover='Select whichever statutory properties will be applicable for this component.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                  </div>
                    <div class="col-12">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" name="" id="part-tax" formControlName="pf_contribution" >
                        <label for="part-tax" class="form-check-label ">Consider for PF contribution</label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover='If this option is unchecked, no PF deduction will be calculated on this component. If this option is checked, Then select any of the below options.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                      </div>
                    </div>
                    <ng-container *ngIf="f.pf_contribution.value==true">
                      <div class="col-12 ">
                        <div class="form-check ms-20">
                          <!-- <input type="checkbox" class="form-check-input" name="" id="pf_always" formControlName="pf_always"> -->
                          <input class="form-check-input" type="radio" id="pf_always" formControlName="pf_always"
                          [value]="true">
                          <label for="pf_always" class="form-check-label ">Always consider for PF contribution</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                              ngbPopover='Select this option if you desire to always consider this component for PF contribution. For example, if the paycomponents Basic and Sepcial allowance have this option selected, the system will always consider these two components for PF contribution.'
                              popoverClass="popover-default popover-info" placement="bottom" container="body"
                              triggers="hover"></i></span>
                        </div>
                      </div>
                      <div class="col-12 ">
                        <div class="form-check ms-20">
                          <!-- <input type="checkbox" class="form-check-input" name="" id="pf_wage" formControlName="pf_always"> -->
                          <input class="form-check-input" type="radio" id="pf_wage" formControlName="pf_always"
                          [value]="false">
                          <label for="pf_wage" class="form-check-label ">Consider for PF contribution only when PF wage is less than
                            15000</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                              ngbPopover='If you want the Component to be considered for PF contribution when the PF wage is less than 15000, choose this option. If the sum of PF wage exceeds 15000 when this component is included, the EPF will be calculated only for a maximum amount of 15000.'
                              popoverClass="popover-default popover-info" placement="bottom" container="body"
                              triggers="hover"></i></span>
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-12 ">
                       <div class="form-check ">
                      <input type="checkbox" class="form-check-input" name="" id="part-tax" formControlName="pt_contribution" >
                      <label for="part-tax" class="form-check-label ">Consider for PT deduction</label>
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover='Select this option if you want to consider  this  paycomponent for PT deduction. PT deduction is done based on the Gross earnings and the state to which the employee is mapped to.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                      </div>
                    </div>
                    <div class="col-12 ">
                       <div class="form-check ">
                      <input type="checkbox" class="form-check-input" name="" id="part-Insurance" formControlName="esi_contribution" >
                      <label for="part-Insurance" class="form-check-label ">Consider for ESI contribution</label>
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover='Select this option if you want to consider  this  paycomponent for ESI deduction.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                    </div>
                    </div>
                    <div class="col-12 ">
                       <div class="form-check ">
                      <input type="checkbox" class="form-check-input" name="" id="esi_limit" formControlName="esi_limit" >
                      <label for="esi_limit" class="form-check-label ">Consider checking the limit of ESI applicability</label>
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover='Pay Components for which ESI limit applicability is enabled, will be considered to check ESI limit of Rs 21000. If sum of those pay components are less than 21000 then ESI will be deducted for the employee or else no ESI will be deducted.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                    </div>
                    </div>

                </div>
                 <div class="row mt-24">
                  <div class=" col-lg-6 form-row">
                    <label for="" class="form-label required">Round off configuration </label>
                    <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('round off configuration')}}" formControlName="roundoff"  [ngClass]="{ 'is-invalid': (f.roundoff.touched) &&  f.roundoff.errors}">
                      <ng-option value="Ceil">Ceil</ng-option>
                      <ng-option value="Nearest">Nearest</ng-option>
                      <ng-option value="None">None</ng-option>
                    </ng-select>
                    <div *ngIf="(f.roundoff.touched) && f.roundoff.errors" class="invalid-feedback">
                      <div *ngIf="f.roundoff.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                </div>

              <div class="row mt-24">
                <label for="" class="form-label required">Show on payslip </label>
                  <div class="col-lg-6 form-row mb-24">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio"  id="flexRadioDefault1" formControlName="show_salary_slip" [value]="true">
                      <label class="form-check-label" for="flexRadioDefault1">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio"  id="flexRadioDefault2" formControlName="show_salary_slip" [value]="false">
                      <label class="form-check-label" for="flexRadioDefault2">
                        No
                      </label>
                    </div>
                  </div>
               </div>
               <div class="row">
                <label for="" class="form-label required">Show on salary register </label>
                  <div class="col-lg-6 form-row mb-24">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="flexRadioDefault1" formControlName="show_salary_register" [value]="true" (change)="handleChange(true)">
                        <label  for="yes" class="form-check-label"> Yes </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="flexRadioDefault2" formControlName="show_salary_register" [value]="false" (change)="handleChange(false)">
                        <label for="no" class="form-check-label"> No </label>
                    </div>
                  </div>
               </div>
               <div class="row" *ngIf="sort_order">
                <div class=" col-lg-6 form-row mb-24">
                  <label for="salary_register_order" class="form-label required">Salary register sort order</label>
                  <input type="text"  class="form-control" autocomplete="off" name="name" id="name" placeholder="{{this.messageService.placeholderdisp('Salary register sort order')}}" formControlName="salary_register_order" [ngClass]="{ 'is-invalid': (f.salary_register_order.touched || f.salary_register_order.dirty) &&  (f.salary_register_order.errors )}">
                  <div *ngIf="(f.salary_register_order.touched || f.salary_register_order.dirty) &&  (f.salary_register_order.errors)" class="invalid-feedback">
                    <div *ngIf="f.salary_register_order.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}                    </div>
                    <div *ngIf="f.salary_register_order.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!(f.salary_register_order.errors?.pattern) && f.salary_register_order.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','4')}}</div>

                  </div>
                </div>
                <div class="col-12  ">
                  <div class="hr-1"></div>
                </div>
              </div>
              <p class="text-uppercase fw-500 fs-16 ">Tax details </p>
              <div class="row row-16">
                <div class="col-lg-6 form-row">
                  <label for="tax_configuration" class="form-label required">Tax configuration </label>
                  <app-master-select [ngClass]="{ 'is-invalid': (f.tax_configuration.touched) && f.tax_configuration.errors }" [placeholder]="taxConfigPlaceHolder" id="tax_configuration" [isdisabled]="tax_config_disable" [submitted]="taxSubmit" [selectedItem]="f.tax_configuration.value" formControlName="tax_configuration" [type]="taxConfig" [isrequired]="isrequired" (ngModelChange)="setTaxableLimit()"></app-master-select>
                  <div *ngIf="(f.tax_configuration.touched) && f.tax_configuration.errors" class="invalid-feedback">
                    <div *ngIf="f.tax_configuration.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  </div>
                </div>
                <div class="col-lg-6 form-row"  *ngIf="istaxable_limit && activeLimit">
                  <label for="non_taxable_limit" class="form-label required">Non-taxable limit</label>
                  <input type="text"  class="form-control" autocomplete="off" name="name" id="name" placeholder="{{this.messageService.placeholderdisp('non-taxable limit')}}" formControlName="non_taxable_limit" [ngClass]="{ 'is-invalid': (f.non_taxable_limit.touched || f.non_taxable_limit.dirty) &&  (f.non_taxable_limit.errors )}">
                  <div *ngIf="(f.non_taxable_limit.touched || f.non_taxable_limit.dirty) &&  (f.non_taxable_limit.errors)" class="invalid-feedback">
                    <div *ngIf="f.non_taxable_limit.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}                    </div>
                    <div *ngIf="f.non_taxable_limit.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!(f.non_taxable_limit.errors?.pattern) && f.non_taxable_limit.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}} </div>

                  </div>
              </div>
             </div>
            <div class="row mt-3" *ngIf="iscomputation && !taxrestricted">
              <label for="" class="form-label required">Tax computation  </label>
              <div class="col-lg-6 form-row">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="tax_computation" id="flexRadioDefault1" [value]="true">
                    <label  for="flexRadioDefault1" class="form-check-label"> Proportionally</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="tax_computation" id="flexRadioDefault2"  [value]="false">
                    <label for="flexRadioDefault2" class="form-check-label"> Pay month </label>
                </div>
              </div>
             </div>
            <div class="row row-16 mt-2" *ngIf="f.under_section_income.value">
              <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Map income under this section </label>
                <app-master-select [ngClass]="{ 'is-invalid': (sectionSubmit || f.under_section_income.touched) && f.under_section_income.errors }" [placeholder]="incomePlaceHolder" id="under_section_income" [submitted]="sectionSubmit" [selectedItem]="f.under_section_income.value" formControlName="under_section_income" [type]="incomeSection" [isrequired]="isrequired" (click)="sectionChange()" [isdisabled]='sectionIncomeDisable'></app-master-select>
                  <div *ngIf="(sectionSubmit || f.under_section_income.touched) && f.under_section_income.errors" class="invalid-feedback">
                    <div *ngIf="f.under_section_income.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
              </div>
           </div>

          </div>
      </div>
      <footer class="side-panel-footer gap-32">
          <button  class="ms-auto btn btn-primary text-uppercase btn-sm text-uppercase" [disabled]="!SingleForm.valid || componentError || saveClicked" type="submit" (click)="singlePayComponentSave()">SAVE</button>
      </footer>
  </form>

</div>
<!-- <pre>Form Values: {{SingleForm.value | json}}</pre> -->

<!-- slider section end -->
