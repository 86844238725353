import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { SalaryProcessService } from 'src/app/@core/services/salary-process.service';


@Component({
  selector: 'app-publish-salary',
  templateUrl: './publish-salary.component.html',
  styleUrls: ['./publish-salary.component.scss']
})
export class PublishSalaryComponent implements OnInit {
  sh = 1;
  isChecked = true;
  filter  = false;
  year:any;
  month:any;
  loader = true;
  page = 1
  status = 'Freeze';
  pageSize =20;
  searchString:any = "";
  company:any;
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];
  items: any[] = [];
  startDate=""
  endDate=""
  months:any = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  }
  selectedList:any = [];
  downloadMsg = false;
  componentLoader = true;
  // total:any;
  viewBreakUpDetails:any;
  viewBreakup = false;
  inputdata :any
  saveComplete  = false;
  confirmLabel:any


  errorList:any = []
  progress=0
  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  constructor(public route:ActivatedRoute,public sds:SalaryDashboardService,public notificationService:NotificationService,
    public messageService : MessageService, private fb: FormBuilder,public appService:AppService,public router: Router,
    private SalaryProcessService:SalaryProcessService) {
  }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })
    this.sds.getDateRangeSlider(this.company).subscribe((res:any)=>{
      res.body.data.forEach( (value:any, key:any)=>{
        if(value.payroll_month == this.month && value.year == this.year){
          this.defStartEndDate(value);
        }
      })
      this.sds.getProcessList(this.year,this.month,this.status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,this.startDate,this.endDate).subscribe((res:any)=>{
        this.items = res.body.data;
        if(this.status == 'Freeze')
          this.filterItems();
         this.loader = false
       });
    });


  }


  updateList (status:any){
    this.loader       = true;
    this.searchString = "";
    this.items        = [];
    this.sds.getProcessList(this.year,this.month,status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,this.startDate,this.endDate).subscribe((res:any)=>{
      this.items = res.body.data;
      if(status == 'Freeze')
        this.filterItems();
      this.selectedList = []
      this.loader = false

    });
  }

  defStartEndDate(value:any){
    let startYear = value.year;
    if(value.end_month=='January' && value.start_month=='December')
     startYear = value.year - 1;
    this.startDate = value.year+"-"+this.months[value.start_month]+"-"+this.pad(value.start_day)
    this.endDate = value.year+"-"+this.months[value.end_month]+"-"+this.pad(value.end_day)
  }
  pad(d:number) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

checkallSelected(){
  var newList = this.filterIt();
  var begin = (this.page-1) * this.pageSize ;
  var end = this.page* this.pageSize;
  var subArray = newList.slice(begin,end);
  if(subArray.length){
    return subArray.every(item => {
      return item.checked;
    });
  } else {
    return false;
  }
}
checkall(event:any){
  var newList = this.filterIt();
  var begin = (this.page-1) * this.pageSize ;
  var end = this.page* this.pageSize;
  var subArray = newList.slice(begin,end);
  subArray.forEach((item:any, key:any) => {
    item.checked = event.target.checked
  })
}

filterIt() {
  return this.items.filter((obj: any) => {
    return Object.keys(obj).some((key) => {
      if (obj[key] !== null) {
        const tempKey = obj[key].toString().toLowerCase();
        const tempSearch = this.searchString.toLowerCase();
        return tempKey.includes(tempSearch);
      }
    });
  });
}

filterItems() {
  var list:any = [];
  return this.items.filter((obj: any) => {
    return Object.keys(obj).some((key) => {
      if (key == 'status') {
        if(obj[key] == 'Freeze'){
          list.push(obj);
        }
        this.items = list;
      }
    });
  });
}

createSelectedLIst(status:any){
  this.SalaryProcessService.getProcessData().subscribe((res:any) => {
    if( res.data.percentage>0 &&  res.data.percentage<100){
      this.notificationService.handleErrorNotification("Salary publish cannot be done until the existing salary process is completed.","Salary publish");
    } else {
      this.selectedList = [];
      this.items.forEach((item:any, key:any) => {
        if(item.checked == true){
          if(status=='Publish'){

            this.selectedList.push({"id":item.id,
            "status": status,
            "start_date": this.startDate,
            "end_date": this.endDate,
            "salary": item.gross_pay});
          }
          else{
            this.selectedList.push({"id":item.id,
            "status": status,
            "start_date": this.startDate,
            "end_date": this.endDate,
            });
          }
        }
      });
      if(this.selectedList.length<=0){
        this.notificationService.handleErrorNotification("Select Employees before publishing Salary","Select Employees");
      } else  {
        this.downloadMsg = true;
        this.confirmLabel = status=='Freeze'?'Unpublish':status;
        // this.processSalary();
      }
    }
  })
}

filteredcount(){
  return this.filterIt().length;
}

processSalary(){

  this.saveComplete = true;
  this.startProgress = true;
  this.startProgressBar()
  this.sds.setProcessData(this.selectedList.length,this.router.url,"Publish")
  if(this.status=='Freeze'){
    var dataArray = {
      "year": Number(this.year),
      "month":this.months[this.month],
      "start_date": this.startDate,
      "end_date": this.endDate,
      "company":this.company,
      "employee_list":this.selectedList,
      "is_fnf": false
    };
    this.sds.saveProcessList(dataArray).subscribe((res: any) => {
        this.updateList(this.status);
        this.downloadMsg  = false;
        this.saveComplete = false;
    });
  }
  else{
    var data = {
      "year": Number(this.year),
      "month":this.month,
      "employee_list":this.selectedList,
      "is_fnf": false
    }
    this.sds.publishSalary(data).subscribe((res: any) => {
      this.notificationService.handleSuccessNotification(`Selected employee's salary has been unpublished successfully.`,"Salary Unpublish");
      this.updateList(this.status);
      this.downloadMsg  = false;
      this.saveComplete = false;
    })
  }

}

viewBreakUp(id:any,status:any){
  this.componentLoader = true;
  this.sds.getViewBreakupDetails(id,this.year,this.month,status).subscribe((res:any)=>{
    if(res){
      this.viewBreakUpDetails = res.body;
      // this.total = (this.viewBreakUpDetails.total_earnings[0].contribution_total + this.viewBreakUpDetails.total_earnings[0].gross_benefits + this.viewBreakUpDetails.total_earnings[0].other_benefits) - this.viewBreakUpDetails.total_earnings[0].recurring_deductions;
         this.viewBreakup = true;
         this.componentLoader = false;
    }
  })

}


resetFilter(){
  this.buList = []
  this.DepList = []
  this.DesignationList = []
  this.gradeList = []
  this.BranchList = []
  this.updateList(this.status);
  this.filter  = false
}
submitForm(data:any){
  this.filter  = false
  this.filtercomp.getBUList(this.company);
  this.buList = data.buList;
  this.DepList = data.DepList;
  this.DesignationList = data.DesignationList;
  this.gradeList = data.gradeList;
  this.BranchList = data.BranchList;
  this.updateList(this.status);
}
closeFilter(){
  this.filter  = false
}
selectAll(){
  this.inputdata = this.appService.selectAll(this.items)

}
countSelected(){
  return this.items.filter(obj => obj.checked === true).length;
}
unselectAll(){
  this.inputdata = this.appService.unselectAll(this.items)
}
startProgress = false
showProgress = false
progPercentage:any = 0
isProgressStart = false
timeLeft:any = ''
iscompleted = false
body = ''
errorLog=false
successMsg=''
exportData:any =[]
startProgressBar(){
  let cnt = 0;
  this.sds.setProgress(false);
  this.SalaryProcessService.processPercentage.subscribe(result =>{
    this.showProgress = true
    this.isProgressStart = true
    this.progPercentage = result.event.percentage
    // this.progPercentage = 50
    // this.timeLeft = "5 min 6 sec"
    this.body =  result.event.uploaded_count+" out of "+result.event.employee_count+" employee(s) salary published"
    if(result.event.percentage == 100 && cnt===0){
      this.updateList(this.status);
        cnt++

      this.timeLeft = ""
      this.isProgressStart = false
      this.iscompleted =true
      this.errorLog = true;
      this.successMsg = this.sds.PROCESSCOUNT +" out of "+this.sds.PROCESSCOUNT+" employee(s) salary published successfully"
    }
    else{
      this.convertMinSecs(result.event.time_left)
    }
  },
  (error:any)=>{
  this.closeProgress();
  this.notificationService.handleErrorNotification('Something went wrong','Error')

  })
}
convertMinSecs(min:any){
  let time = min*60;
  var minutes = Math.floor(time / 60);
  var seconds:any = time - minutes * 60;
  seconds = seconds.toFixed(2)
  this.timeLeft = minutes+" min "+seconds+" sec "
}
closeProgress(){
  this.sds.setProgress(true);
  this.isProgressStart=false;
  this.startProgress = false
}

}

