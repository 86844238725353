<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Geo Location</h3>
      <p class="text-helper mb-md-0">Employees geo location details are added and assigned for attendance tracking</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon " style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='locsearch'  [isLoading]="sLoading"
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchlocation($event)"></app-searchbar>
        </div>

        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13rem"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';filterItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';filterItemList('True')">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';filterItemList('False')">Deleted</button>
          </div>
        </div>

        <button class="btn flex-shrink-0 btn-outline-primary btn-add" *ngIf="permissions.a"
          (click)="viewSidePanel = true">Import </button>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addeditpanel = true; dataId=0; "
          *ngIf="permissions.a"><i class="icon-plus"></i>ADD geo location</button>
      </div>
    </div>
  </div>

  <!-- <div class="row row-16"> -->
  <div class="row row-16rem" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata && loader==false;else all_data" class="row " style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg11.png'"    [noDataText]="'NO LOCATION ADDED YET'" [hasPermission]="permissions?.a"
      (addData)="addheadercontent($event)"> </app-nodata-view>
  </div>

  <ng-template #all_data>
    <div class="row row-16rem " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let locationdata of geolocationlist let i = index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section align-items-center" (click)="viewlocation(locationdata.id)">
                <div class="logo-img {{locationdata.color_code}}">
                  {{this.appService.getFirstChar(locationdata.location_name,2)}}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-1 tips">{{locationdata.location_name}}</h6>
                  <span class="tips-helper">{{locationdata.location_name}}</span>
                  <span
                    class="fs-10 text-uppercase fw-500 {{(locationdata.is_active == true) ? 'text-success' : 'text-danger'}}">{{(locationdata.is_active
                    == true) ? 'Active' : 'Deleted'}}</span>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="locationdata.is_active == true">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical "></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem (click)="addeditpanel = true;dataId=locationdata.id " *ngIf="permissions.e ">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button class="text-danger" ngbDropdownItem
                    (click)="deletefn(locationdata.id,locationdata.count_employees);" *ngIf="permissions.d">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body p-16 gap-8 flex-column " (click)=" viewDetail=true">
              <div class="d-flex justify-content-between w-100">
                <span class="fs-10 text-light-400">Employees Mapped</span>
                <span class="fs-12 fw-500">{{locationdata.count_employees}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </ng-container>

      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>

  </ng-template>

  <app-geo-location-add *ngIf="addeditpanel" [addeditpanel]="addeditpanel" [dataId]="dataId" [from]="'location'"
    (panelclose)="closefn($event)" (locationdetailout)="locationdetailout($event)"></app-geo-location-add>


  <div class="side-panel side-pane-active" style="--sidepanel-width:56.4375rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Details</h5>
        <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions.v">
        <div class="container-fluid p-32">
          <app-c1-loader *ngIf="loader1"></app-c1-loader>
          <ng-container *ngIf="!loader1">
            <div class="d-flex mb-16">
              <p class="text-uppercase fw-500 mb-0">GEO LOCATION DETAILS</p>
            </div>
            <div class="row row-24 mb-24">
              <div class="col-12">
                <div class="card details-card shadow-0 px-24 py-16">
                  <div class="row row-24 ">
                    <div class="col-12 align-items-center d-flex"><span
                        class="title">{{locationViewdata.location_name}}</span>
                      <!-- <a class="link-danger ms-auto " (click)="deletefn(locationViewdata.id,mappedlength)" *ngIf="locationViewdata.is_active == true"><i class="icon-trash me-8 "></i> </a> -->
                    </div>
                    <div class="col-sm-4 col-12">
                      <div class="detail-title">Coordinates</div>
                      <div class="detail-desc">{{locationViewdata.latitude}}, {{locationViewdata.longitude}}</div>
                    </div>
                    <div class="col-sm-4 col-12">
                      <div class="detail-title">Radius</div>
                      <div class="detail-desc">{{locationViewdata.radius}} m</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row row-24 mb-24" *ngIf="mappedlength != 0">
              <div class="col-12">
                <div class="card details-card shadow-0 radius-8 px-24 py-16">
                  <div class="row row-24 ">
                    <div class="col-12 d-flex">
                      <span class="title">Employees mapped</span>
                    </div>
                    <div class="col-12">
                      <div class="form-icon w-100">
                        <app-searchbar [searchlist]='searchlistdata2' [searchplaceholder]='locsearch2'
                          (searchEmitter)="searchresults2($event)"
                          (keywordsearchEmitter)="searchlocation2($event)"></app-searchbar>
                      </div>
                    </div>
                    <app-c1-loader *ngIf="loader2"></app-c1-loader>
                    <ng-container *ngIf="!loader2">
                      <!-- <ng-container *ngFor="let locationmapped of mappedempdata let i = index" > -->
                      <ng-container
                        *ngFor='let locationmapped of $any(mappedempdata| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize),let i = index'>
                        <div class=" col-12">
                          <div class="card details-card card-c2 hstack align-items-center gap-16 p-24">
                            <img class="avatar-circle sq-56" src="{{locationmapped?.employee__profile_image}}"
                              *ngIf="locationmapped?.employee__profile_image !=  '' && locationmapped?.employee__profile_image != null" />
                            <div
                              class="avatar-circle avatar-border {{locationmapped?.employee__color_code}} sq-56 fs-14"
                              *ngIf="locationmapped?.employee__profile_image ==  ''|| locationmapped?.employee__profile_image == null">
                              {{this.appService.getFirstChar(locationmapped?.employee_full_name,2)}}</div>

                            <div class=" ">
                              <div class="fs-14 fw-600">{{locationmapped?.employee_full_name}}</div>
                              <div class="fs-12 fw-500 text-light-500">{{locationmapped?.employee__designation__name}}
                                |
                                {{locationmapped?.employee__employee_code}}</div>
                            </div>
                            <div class=" ">
                              <div class="detail-title">Last Check in </div>
                              <div class="detail-desc"
                                *ngIf="locationmapped?.latest_punch_date!=null && locationmapped?.latest_punch_date!==''">
                                {{this.appService.dateFormatDisplay(locationmapped?.latest_punch_date)}}
                                {{this.appService.timeFormatDisplay(locationmapped?.latest_punch_time)}}</div>
                              <div class="detail-desc"
                                *ngIf="locationmapped?.latest_punch_date==null || locationmapped?.latest_punch_date==''">
                                -</div>
                            </div>
                            <div class=" ">
                              <div class="detail-title">Added By </div>
                              <div class="detail-desc">{{locationmapped?.added_by_full_name}}
                                {{locationmapped?.added_by_employee_code!=null?'('+locationmapped?.added_by_employee_code+')':''}}
                              </div>
                            </div>
                            <div class=" ">
                              <div class="detail-title">Added On </div>
                              <div class="detail-desc">
                                {{this.appService.dateFormatDisplay(locationmapped?.created_at__date)}} </div>
                            </div>
                            <a class="link-danger ms-auto " (click)="deleteemp(locationmapped?.assigned_id)"
                              *ngIf="locationViewdata?.is_active==true"><i class="icon-trash me-8 "></i> </a>
                          </div>
                        </div>
                      </ng-container>
                      <div class="col-12" *ngIf="this.appService.filteredcount(mappedempdata,search)>pageSize">
                        <ngb-pagination class="d-flex justify-content-end"
                          [collectionSize]="this.appService.filteredcount(mappedempdata,search)" [(page)]="page"
                          [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                          [pageSize]="pageSize"></ngb-pagination>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>

  <div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16rem">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Location?</h4>
        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Location')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
            (click)="deleteToggle = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
            (click)="deleteLocation()">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal  modal-alert {{deleteempmodel == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16rem">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Mapped Employee?</h4>
        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Mapped Employee')}}</p>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger btn-sm text-uppercase" (click)="deletemappedemp();">Yes</button>
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
            (click)="deleteempmodel = false">No</button>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="deletealert==true">
    <app-alert-toggle (modals)="closed($event)" [alertToggle]="deletealert" [alertMsg]="alertMsg"></app-alert-toggle>
  </ng-container>

  <div class="side-panel side-pane-active" style="--sidepanel-width:50rem;"
    [class.side-pane-active]='viewSidePanel == true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Import Geo Location</h5>
        <button class="btn-reset text-light-600" (click)="viewSidePanel = false">
          <i class="icon-close-lg fs-16"></i></button>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32 form-container">
          <div class="row row-24">
            <div class="col-12">
              <div class="card card-c2 p-24">
                <div class="row row-16">
                  <div class="col-12">
                    <span class="title mb-8">Import Geo Location</span>
                  </div>
                  <ng-container *ngIf="fileName">
                    <div class="col-md-6">

                      <div class="card card-c2  bg-secondary">
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                            <span class="tips text-uppercase  fw-700 text-trim">{{fileName}}</span>
                            <span class="tips-helper ">{{fileName}}</span>

                          </div>
                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="clearData()"></i>
                        </div>
                      </div>
                      <!-- <div class="card card-c2 bg-secondary ">
                        <div class="d-flex p-24 ">
                          <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                          <span class="text-trim fw-500">{{fileName}}</span>
                          <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                          <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()"><i
                              class="bi bi-trash ms-8 "></i></span>
                        </div>
                      </div> -->
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                      class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                      <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                        (change)="onFileChange($event)" id="upload" hidden>
                      Select File to Upload
                    </label>
                    <span class="invalid-feedback"
                      *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card card-c2 p-24">
                <div class="row row-16">
                  <div class="col-12">
                    <span class="title">Instructions</span>
                  </div>

                  <div class="col-12 ">
                    <ol class="fs-14 ps-16 mb-0 li-24">
                      <li>Download the <a class="link-primary" href="assets/docs/sample-geo-location-import.xlsx"> <i
                            class="icon-download me-1"></i> Sample Excel template</a></li>
                      <li>Enter or update the Location details as per the format provided in the template.</li>
                      <li>Save the file.</li>
                      <li>Click on the "Select file to upload" button and select the file.</li>
                      <li>The file chosen should be in (.xls/.csv)format only.</li>
                      <li>Click on "upload".</li>

                    </ol>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <footer class="side-panel-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || iscompleted"
          (click)="uploadBalance()">Upload</button>
      </footer>
    </div>
  </div>

  <!-- Delete popup -->
  <div class="modal  modal-alert {{deleteToggle2 == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Error Occured</h4>
        </div>
        <div class="modal-body">
          <p class="mb-0">{{deleteMsg}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle2 = false;">Close</button>
        </div>
      </div>
    </div>
  </div>
  <app-upload-progress-bar *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted"
    [isProgressStart]="isProgressStart" [heading]="'Uploading'"></app-upload-progress-bar>
  <app-error-download [fileName]="'Location Import Error'" [successDataCount]="successDataCount"
    [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Import Location'"
    (closePanel)="downloadMsg = false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg">
  </app-error-download>
