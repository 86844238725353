import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-view-wfh-request',
  templateUrl: './view-wfh-request.component.html',
  styleUrls: ['./view-wfh-request.component.scss']
})
export class ViewWfhRequestComponent implements OnInit {
  @Input() workFromHomedata:any;
  @Input()from:any;
  @Input()loader1=false;
  @Input()workFromHomecancel:any;
  requestCancel = false;
  @Output() closeViewPage= new EventEmitter();
  @Output() closePanel           = new EventEmitter()
  cancelReq            : boolean = false

  constructor(
    public appService: AppService,
    private notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }
  cancelId:any;
  cancelRequest(status:any, cancelId:any,cancelStatus:any,arrear:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("Work from home request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else{
      this.cancelReq = true
      this.requestCancel = true;
      this.cancelId      = cancelId;
    }
  }

  // closeCancelModal(data:any){
  //   this.requestCancel = data?.show;
  //   if(data?.action == 'submit')
  //     this.closeViewPage.emit(data?.show);
  // }

  createarrayDisplay(array:any){
    let string:any =[];
    array.forEach((item:any) => {
      string.push(item);
    })
    return string.join(", ");
  }

  close(e:any){
    this.cancelReq = e.show
    this.closePanel.emit(false)
  }

}
