<div class="container-fluid px-32 py-24">
  <div class="row row-16">
    <div class="col-12">
      <div class="col-12 d-flex position-relative">
        <span class=" text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
        <button class="btn-reset ms-auto btn-star text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
          (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
          <i class="bi toggle-star"></i>
        </button>
      </div>
      <div class="col-12 mt-8 card-ribbon">
        <div class="w-100 d-flex align-items-start gap-8">
          <h5 class="mb-8 text-break" *ngIf="requestitem?.request_data?.is_cancel == false">Leave Request Approval -
            {{requestitem?.leave_type_name}}</h5>
          <h5 class="mb-8 text-break" *ngIf="requestitem?.request_data?.is_cancel == true">Leave Cancellation Request
            Approval -
            {{requestitem?.leave_type_name}}</h5>
          <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
            <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
              *ngIf="requestitem?.approved_status == 'Pending'">Pending</span>
            <span class="badge px-16 ms-auto fs-14 badge-success py-1"
              *ngIf="requestitem?.approved_status == 'Approved'">Accepted</span>
            <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
              *ngIf="requestitem?.approved_status == 'Rejected'">Rejected</span>
            <span class="text-light-400">|</span>
            <span class="link-primary fs-14 text-nowrap" (click)="requestViewStatus()">View Status</span>
          </ng-container>
          <!-- Invalid request -->
          <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
            class="ribbon ribbon-right ribbon-danger  ribbon-sm"
            style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
        </div>
        <span *ngIf="requestitem?.request_data?.is_cancel == false"
          class=" badge-custom {{requestitem?.request_type_color_code}} ">Leave Request</span>
        <span *ngIf="requestitem?.request_data?.is_cancel == true"
          class=" badge-custom {{requestitem?.request_type_color_code}} ">Leave Cancellation Request</span>
        <span
          *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
          class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
      </div>
    </div>

    <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar>

    <div class="col-12">
      <p class="mb-0 fs-14" [innerHTML]="requestitem?.message">
      </p>
    </div>
    <div>

      <!-- OT and Comp off Validation -->
      <div *ngIf="warningMsgToggle" class="col-12">
        <div role="alert" class="alert alert-warning show">
          <p class="mb-0 fs-12
        ">{{warningMsg}}</p>
          <div class="btn-close pointer" (click)="warningMsgToggle=false"></div>
        </div>
      </div>
      <!-- End -->

      <app-view-loader *ngIf="loader1"></app-view-loader>
      <ng-container  *ngIf="!loader1">
        <div class="row row-8">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pt-16 pb-24">
              <div class="row row-24">
                <div class="col-12">
                  <div class="d-flex">
                    <span class="title">Leave Details</span>
                    <span (click)="timeOffTrendFunction()" class="text-accent2 pointer ms-auto fs-12 fw-500"><i
                        class="bi bi-graph-up me-1"></i>Time off Trend</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="card card-c5 month-active text-light-500">
                    <div class="card-body bg8 flex-center flex-column  text-light-600">
                      <div class="fw-500 fs-24 text-uppercase">{{requestitem?.leave_data?.applied_days}} </div>
                      <div class="fs-14">days</div>
                    </div>
                    <div class="card-body gap-1 justify-content-center d-flex flex-column overflow-hidden">
                      <p class="fs-14 mb-0 text-trim" title="{{requestitem?.leave_data?.leave_type_name}}">
                        {{requestitem?.leave_data?.leave_type_name}}</p>
                      <p class="fs-14 mb-0 fw-500"
                        *ngIf="requestitem?.leave_data?.from_date != requestitem?.leave_data?.to_date">
                        {{this.appservice.dateFormatDisplay(requestitem?.leave_data?.from_date)}} -
                        {{this.appservice.dateFormatDisplay(requestitem?.leave_data?.to_date)}} </p>
                      <p class="fs-14 mb-0 fw-500"
                        *ngIf="requestitem?.leave_data?.from_date == requestitem?.leave_data?.to_date">
                        {{this.appservice.dateFormatDisplay(requestitem?.leave_data?.from_date)}} </p>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="detail-title">Full day</div>
                  <div class="detail-desc">{{(requestitem?.leave_data?.full_day_dates?.length == 0 ||
                    requestitem?.leave_data?.full_day_dates == null || requestitem?.leave_data?.full_day_dates == '') ?
                    '-' : fullday(requestitem?.leave_data?.full_day_dates)?.join(', ')}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Half day</div>
                  <div class="detail-desc">{{(requestitem?.leave_data?.half_day_dates?.length == 0 ||
                    requestitem?.leave_data?.half_day_dates == null || requestitem?.leave_data?.half_day_dates == '')? '-'
                    : halfday(requestitem?.leave_data?.half_day_dates, requestitem?.leave_data?.from_date_portion,
                    requestitem?.leave_data?.to_date_portion).join(', ')}}</div>
                </div>

                <div *ngIf="requestitem?.leave_data?.leave_type_name == 'Compensatory off'" class="col-12">
                  <div class="detail-title">Comp off date selected to compensate with</div>
                  <div class="detail-desc">{{compOffData?.join(', ')}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Reason</div>
                  <div class="detail-desc">{{requestitem?.leave_data?.reason}}</div>
                </div>
                <div class="col-12" *ngIf="requestitem?.request_data?.is_cancel == false">
                  <div class="detail-title mb-8">Document</div>
                  <div class="detail-desc" *ngIf="requestitem?.leave_data?.document == ''">-</div>
                  <div style="width:12.9375rem;" *ngIf="requestitem?.leave_data?.document != ''">

                    <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                      <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                      <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                        <span class="tips text-uppercase fs- fw-700 text-trim"
                          *ngIf="requestitem?.leave_data?.document != ''">{{
                          this.appservice.getDocName(requestitem?.leave_data?.document) }}</span>
                        <span class="tips-helper " *ngIf="requestitem?.leave_data?.document != ''">{{
                          this.appservice.getDocName(requestitem?.leave_data?.document) }}</span>
                        <div class="d-flex gap-8">

                          <div class="link-primary" (click)="viewDocument(requestitem?.leave_data?.document)">
                            <i class="bi bi-eye me-1"></i>View
                          </div>
                          <div class="link-primary"
                            (click)="this.appservice.downloadFile(requestitem?.leave_data?.document,this.appservice.getDocName(requestitem?.leave_data?.document))">
                            <i class="bi bi-download me-1"></i>Download
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="requestitem?.request_data?.is_cancel == true">
                  <div class="detail-title">Reason for cancellation</div>
                  <div class="detail-desc">{{requestitem?.request_data?.cancel_remark == null ? '-' :
                    requestitem?.request_data?.cancel_remark}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-16" *ngIf="requestitem?.request_data?.is_cancel == false">
            <div class="card details-card shadow-0 px-24 pt-16 pb-24">
              <div class="row row-24">
                <div class="col-12 d-flex">
                  <span class="title">BALANCE LEAVES</span>
                  <span class="link-primary1 fs-12 ms-auto" (click)="leaveBalanceFunction()"><i
                      class="bi bi-eye me-1"></i>Details</span>
                </div>
                <div
                  class="{{requestitem?.leave_data?.leave_type_name == 'Compensatory off' ? 'col-md-12' : 'col-md-6'}}">
                  <div class="card card-c2 bg14 h-100 px-24 py-16">
                    <div class="fs-24 fw-500 pb-8">{{requestitem?.leave_data?.available_leave}} days</div>
                    <div class="detail-desc fs-12">TOTAL AVAILABLE LEAVES AFTER BOOKING </div>
                    <div class="detail-title fs-12">As of today</div>
                  </div>
                </div>
                <div *ngIf="requestitem?.leave_data?.leave_type_name != 'Compensatory off'" class="col-md-6">
                  <div class="card card-c2 bg-warning h-100 px-24 py-16">
                    <div class="fs-24 fw-500 pb-8">{{requestitem?.leave_data?.total_available_leave}} days</div>
                    <div class="detail-desc fs-12">ESTIMATED TOTAL LEAVE BALANCE </div>
                    <div class="detail-title fs-12">As of {{this.appservice.dateFormatDisplay(lastDate)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form class="mt-20" [formGroup]="form">
            <ng-container *ngIf="!requestitem?.achieved_status">
              <div class="col-12">
                <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..."
                  class="form-control mb-8 textarea-comment" formControlName="comment"
                  [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                <div *ngIf="f.comment.errors" class="invalid-feedback">
                  <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex gap-16">
                <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                  (click)="confirm('Rejected')">Decline</button>
                <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
                  (click)="confirm('Approved')">Confirm</button>
              </div>
            </ng-container>
          </form>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<!-- Trend Graph Sidebar -->
<div *ngIf="timeOffTrend" class="side-panel" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='timeOffTrend === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5> Time off trend</h5>
      <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
    </header>

    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">


        <div class="row row-16">
          <div class="col-12 d-flex align-items-center">
            <p class="fw-500 mb-0">Leave Type</p>
            <ng-select class="form-ngselect ms-auto" style="width:15.3125rem" placeholder="All leave types"
              [(ngModel)]="selectedYear1" (change)="leaveTypeChange(selectedYear1)" name="selectedYear1">
              <ng-option *ngFor="let item of leaveType" [value]="item?.id">{{ item?.leave_name }}</ng-option>
            </ng-select>
          </div>
          <div class="col-12">
            <div class="card card-c2 pb-24  px-24 pt-24">
              <div class="row row-12">

                <div class="col-12 d-flex">
                  <div class="fs-24 me-auto">Time off trend - {{selectedYear}}</div>

                  <ng-select class="form-ngselect-inline pointer" placeholder="Select year" [(ngModel)]="selectedYear"
                    [clearable]="false" (change)="yearChange(selectedYear)" name="selectedYear">
                    <ng-option *ngFor="let year of yearList" [value]="year"> {{year}} </ng-option>
                  </ng-select>
                </div>
                <div class="col-12" *ngIf="barchartLoader">

                  <app-graph-loader></app-graph-loader>
                </div>
                <div *ngIf="!barchartLoader" class="col-12">
                  <!-- <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="multi" [gradient]="gradient"
                    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [legendPosition]="legendPosition"
                    [legendTitle]="legendTitle" [barPadding]="barPadding" [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                    [animations]="animations" (select)="onSelect($event)">
                  </ngx-charts-bar-vertical-stacked> -->

                  <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels"
                    [yText]="barChartYAxisLabel"></app-bar-chart>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </form>
</div>

<!-- Leave balance start -->
<div class="side-panel" style="--sidepanel-width:56.25rem;" [class.side-pane-active]='leaveBalance === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Total available balance after booking</h5>
      <a class="toggle-panel" (click)="leaveBalance = false"><i class="icon-close-lg"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="d-flex mb-16 align-items-center">
            <p class="text-uppercase fw-500 mb-0">LEAVE balance AFTER BOOKING</p>
            <!-- <ng-select class="form-ngselect ms-auto" style="min-width: 18.75rem;" placeholder="As of today">
            </ng-select> -->
            <div class="d-flex mb-16 align-items-center gap-8 ms-auto">
              <p class="mb-0">As of</p>
              <div class="input-group form-icon icon-right " style="width: 18.75rem;">
                <input (click)="datePicker3.open()" class="form-control" placeholder="today" matInput
                  [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker3" (keydown)="disableDate()"
                  (dateChange)="dateChangeFunction($event)"><i class="icon-calendar pointer"
                  (click)="datePicker3.open()"></i>
                <mat-datepicker touchUi="false" #datePicker3></mat-datepicker>
              </div>
            </div>
          </div>

          <!-- <ng-container *ngIf="!loader"> -->
          <!-- <div class="row row-16 mb-24"> -->
          <ng-container *ngFor="let leavetype of leaveTypeBalanceDataTile,let i = index">
            <div class="col-xl-4 col-6">
              <div class="card card-c2 card-highlight highlight{{(i+2)%5}}">
                <div class="d-flex px-16 py-12 align-items-center justify-content-between gap-8">
                  <span class="fw-500 fs-12 text-trim" ngbPopover="{{leavetype?.leave_type}}"
                    popoverClass="popover-input popover-info" container="body"
                    triggers="hover">{{leavetype?.leave_type}}</span>
                  <span class="fw-600 fs-20">{{leavetype?.available}}/{{leavetype?.total}}</span>
                </div>
              </div>
            </div>

          </ng-container>
          <!-- </div> -->
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0">LEAVE balance details</p>
          </div>
          <app-view-leave-balance [leaveBalanceTable]="leaveBalanceTable"></app-view-leave-balance>

          <!-- </ng-container> -->
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>

      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>

    </form>
  </div>
</ng-container>

<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
  [alertMsg]="investalertMsg"></app-alert-toggle>

<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
  [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
