<form [formGroup]="investmentDeclarationForm">
  <div class=" config-layout  h-fullscreen overflow-auto">
    <header class=" config-header">
      <h5 class="m-0">Confirmed investment declaration</h5>
      <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
    <div *ngIf="!loader">
      <div class=" config-sidebar ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="steps gap-24   ">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="step" [class]="{'success':investmentDeclarationForm['controls'].hra80gg.valid}">
              <div class="count">1</div>
              <div class="content">
                <span>Step 1</span>
                <h6>HRA/80GG</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-confirmed-hra80-gg [formGp]="hra80ggForm" [currency]="currency" (submitmethod)="submitOne()"
                [cityList]="cityDropDown" (onHra80ggValidation)="hra80ggValidation($event)"
                (onChap2Validation)="chap2Validation($event)" [latestProposed]="latestProposedData"
                [limitData]="limitData"></app-confirmed-hra80-gg>
            </ng-template>
          </li>

          <li [ngbNavItem]="2">
            <a ngbNavLink class="step"
              [class]="{'success':investmentDeclarationForm['controls'].chapt2.valid && tab2touched}">
              <div class="count">2</div>
              <div class="content">
                <span>Step 2</span>
                <h6>Chapter VI A - 80 C</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-confirmed-chapt2 [formGp]="chapt2Form" [currency]="currency" [tot_amount]="tot_amount"
                [limitData]="limitData" (submitmethod)="submitTwo($event)"
                (onChap2Validation)="chap2Validation($event)"></app-confirmed-chapt2>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink class="step"
              [class]="{'success':investmentDeclarationForm['controls'].deductions.valid && tab3touched}">
              <div class="count">3</div>
              <div class="content">
                <span>Step 3</span>
                <h6>Chapter VI A - Deductions </h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-confirmed-deductions [formGp]="deductionsForm" [currency]="currency" [limitData]="limitData"
                (submitmethod)="submitThree()"
                (onDeductionValidation)="deductionValidation($event)"></app-confirmed-deductions>
            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink class="step"
              [class]="{'success':investmentDeclarationForm['controls'].incomeloss.valid && tab4touched}">
              <div class="count">4</div>
              <div class="content">
                <span>Step 4</span>
                <h6>Income/Loss from property</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-confirmed-incomeloss-property [formGp]="incomeLossForm" [currency]="currency" [limitData]="limitData"
                [investmentDetails]="investmentDetails"
                (submitmethod)="submitFour()"></app-confirmed-incomeloss-property>
            </ng-template>
          </li>
          <li [ngbNavItem]="5">
            <a ngbNavLink class="step"
              [class]="{'success':investmentDeclarationForm['controls'].otherincome.valid && tab5touched}">
              <div class="count">5</div>
              <div class="content">
                <span>Step 5</span>
                <h6>Income from other sources</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-confirmed-other-income [formGp]="otherIncomeForm" [currency]="currency" [taxRegima]="taxRegima"
                (submitmethod)="submitForm()" (onOtherincome)="otherincome($event)"></app-confirmed-other-income>
            </ng-template>
          </li>

          <li [ngbNavItem]="6">
            <a ngbNavLink class="step" [class]="{'success': tab6touched}">
              <div class="count">6</div>
              <div class="content">
                <span>Step 6</span>
                <h6>Regime Comparison</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-confirmed-regime-comparison [taxRegimeData]="taxRegimeData" [currency]="currency"
                [investmentDetails]="investmentDetails"
                (submitmethod)="finalSubmit($event)"></app-confirmed-regime-comparison>
            </ng-template>
          </li>
        </ul>
      </div>
      <main class="config-container">
        <!-- <pre>Form Values: {{investmentDeclarationForm.value | json}}</pre> -->
        <div [ngbNavOutlet]="nav"></div>
      </main>
    </div>
  </div>
</form>