<form class="" [formGroup]="formGp" autoComplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row ">
      <p class="text-uppercase fw-500 mb-16">Edit details
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover="Enter the correct Checked-in and Checked-out timing in the appropriate fields."
            popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
      </p>
      <div class="col-12">
        <div  role="alert" class="alert alert-warning {{alertShow}}">
          <p class="mb-0">Once the regularization request is approved, this check-in and check-out timing will be considered as the final check-in and check-out of the day, and the working hours of the day will be calculated on these timings.
          </p>
          <div class="btn-close" (click)="alertShow='hide'"></div>
        </div>
      </div>
    </div>
    <div class="row row-16">
      <ng-container formArrayName="regularization_request" *ngFor="let item of detail().controls; let i=index; ">
        <ng-container [formGroupName]="i">
          <div class="col-12 form-row ">
            <div class="card card-c2">
              <div class="card-body pt-16 px-16 pb-24">
                <p class="fs-14 fw-600">
                  {{this.appServ.dateFormatDisplay(detail().controls[i].get('regulized_date')?.value)}}<i
                    class="icon-trash float-end pointer" (click)="removeDetail(i)"></i></p>
                <hr class="hr-1 mb-16">
                <div class="row row-16">
                  <div class="col-12">
                    <div class="card details-card p-16">
                      <div class="title">CHECK-IN</div>
                      <div class="row row-16 mt-1">
                        <div class=" col-md-6 form-row">
                          <label for="regulized_checkin_date" class="form-label required">Check-in date</label>
                          <div class="form-icon icon-right"
                            [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('regulized_checkin_date')?.dirty || detail().controls[i].get('regulized_checkin_date')?.touched) &&  (detail().controls[i].get('regulized_checkin_date')?.errors || (detail().controls[i].errors?.checkin))}">
                            <input (click)="datePicker1.open()" id="regulized_checkin_date"
                              formControlName="regulized_checkin_date"
                              placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control"
                              [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('regulized_checkin_date')?.dirty || detail().controls[i].get('regulized_checkin_date')?.touched) &&  (detail().controls[i].get('regulized_checkin_date')?.errors || (detail().controls[i].errors?.checkin))}"
                              matInput [matDatepicker]="datePicker1" (keydown)="disableDate()"
                              (dateInput)="!makeOutNull(i) && detail().controls[i].get('regulized_checkin_time')?.value != '' && detail().controls[i].get('regulized_checkout_time')?.value != '' && getDiff(i);checkValidation(i,'regulized_checkin_time');checkinShiftWindow(i)"><i
                              class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                            <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
                          </div>
                          <div
                            *ngIf="(submitted || detail().controls[i].get('regulized_checkin_date')?.dirty || detail().controls[i].get('regulized_checkin_date')?.touched) &&  (detail().controls[i].get('regulized_checkin_date')?.errors || (detail().controls[i].errors?.checkin))"
                            class="invalid-feedback">
                            <div *ngIf="detail().controls[i].get('regulized_checkin_date')?.errors?.required">
                              {{this.messageService.validationDisplay('required')}}</div>

                            <div *ngIf="detail().controls[i].errors?.checkin">Please select a valid date
                            </div>
                          </div>
                        </div>
                        <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Check-in time</label>
                          <div class="form-icon icon-right"
                            [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('regulized_checkin_time')?.dirty || detail().controls[i].get('regulized_checkin_time')?.touched) &&  detail().controls[i].get('regulized_checkin_time')?.errors}">
                            <igx-time-picker formControlName="regulized_checkin_time"
                              (ngModelChange)="!makeOutNull(i) && detail().controls[i].get('regulized_checkout_time')?.value !== '' && getDiff(i);checkValidation(i,'regulized_checkin_time');checkinShiftWindow(i)"
                              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                              [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('regulized_checkin_time')?.dirty || detail().controls[i].get('regulized_checkin_time')?.touched) &&  detail().controls[i].get('regulized_checkin_time')?.errors}"
                              class="form-control form-timepicker " (click)="picker1.open()" #picker1></igx-time-picker>
                          </div>
                          <div
                            *ngIf="(submitted || detail().controls[i].get('regulized_checkin_time')?.dirty || detail().controls[i].get('regulized_checkin_time')?.touched) && detail().controls[i].get('regulized_checkin_time')?.errors"
                            class="invalid-feedback">
                            <div *ngIf="detail().controls[i].get('regulized_checkin_time')?.errors?.required">
                              {{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="detail().controls[i].get('regulized_checkin_time')?.errors?.pattern">
                              {{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="detail().controls[i].get('regulized_checkin_time')?.errors?.sametime">
                              {{this.messageService.greaterThanMessage('check-in time', 'Check-out time')}}</div>
                            <div *ngIf="detail().controls[i].get('regulized_checkin_time')?.errors?.outoffshift">
                              {{this.messageService.shiftwindowMessage('Check-in','Check-out')}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card details-card p-16">
                      <div class="title">CHECK-OUT</div>
                      <div class="row row-16 mt-1">
                        <div class=" col-md-6 form-row">
                          <label for="regulized_checkout_date" class="form-label required">Check-out date</label>
                          <div class="form-icon icon-right"
                            [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('regulized_checkout_date')?.dirty || detail().controls[i].get('regulized_checkout_date')?.touched) &&  detail().controls[i].get('regulized_checkout_date')?.errors || (detail().controls[i].errors?.fromToDate1)}">
                            <input (click)="datePicker2.open()" id="regulized_checkout_date"
                              formControlName="regulized_checkout_date"
                              placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control"
                              [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('regulized_checkout_date')?.dirty || detail().controls[i].get('regulized_checkout_date')?.touched) &&  detail().controls[i].get('regulized_checkout_date')?.errors || (detail().controls[i].errors?.fromToDate1)}"
                              matInput [matDatepicker]="datePicker2" (keydown)="disableDate()"
                              (dateInput)="!makeOutNull(i) && detail().controls[i].get('regulized_checkin_time')?.value !== '' && detail().controls[i].get('regulized_checkout_time')?.value !== '' && getDiff(i);checkValidation(i,'regulized_checkout_time');checkoutShiftWindow(i)"><i
                              class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                            <mat-datepicker at-datepicker touchUi="false" #datePicker2></mat-datepicker>
                          </div>
                          <div
                            *ngIf="(submitted || detail().controls[i].get('regulized_checkout_date')?.dirty || detail().controls[i].get('regulized_checkout_date')?.touched) &&  detail().controls[i].get('regulized_checkout_date')?.errors || (detail().controls[i].errors?.fromToDate1)"
                            class="invalid-feedback">
                            <div *ngIf="detail().controls[i].get('regulized_checkout_date')?.errors?.required">
                              {{this.messageService.validationDisplay('required')}}</div>

                            <div *ngIf="(!detail().controls[i].get('regulized_checkout_date')?.errors?.required) && detail().controls[i].errors?.fromToDate1">Please select a valid date
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 form-row">
                          <label for="" class="form-label required">Check-out time</label>
                          <div class="form-icon icon-right"
                            [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('regulized_checkout_time')?.dirty || detail().controls[i].get('regulized_checkout_time')?.touched) &&  detail().controls[i].get('regulized_checkout_time')?.errors}">
                            <igx-time-picker formControlName="regulized_checkout_time"
                              [minValue]="isSameDates(i) == true ? this.detail().controls[i].get('regulized_checkin_time')?.value : '00:00'"
                              (ngModelChange)="detail().controls[i].get('check_in')?.value !== '' && getDiff(i);checkValidation(i,'regulized_checkout_time');checkoutShiftWindow(i)"
                              placeholder="{{this.messageService.descriptiontext('time')}}"
                              [minValue]="detail().controls[i].get('check_in')?.value" [inputFormat]="'HH:mm'"
                              [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('regulized_checkout_time')?.dirty || detail().controls[i].get('regulized_checkout_time')?.touched) &&  detail().controls[i].get('regulized_checkout_time')?.errors}"
                              class="form-control form-timepicker " (click)="picker2.open()" #picker2></igx-time-picker>
                          </div>
                          <div
                            *ngIf="(submitted || detail().controls[i].get('regulized_checkout_time')?.dirty || detail().controls[i].get('regulized_checkout_time')?.touched) && detail().controls[i].get('regulized_checkout_time')?.errors"
                            class="invalid-feedback">
                            <div *ngIf="detail().controls[i].get('regulized_checkout_time')?.errors?.required">
                              {{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="detail().controls[i].get('regulized_checkout_time')?.errors?.pattern">
                              {{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="detail().controls[i].get('regulized_checkout_time')?.errors?.sametime">
                              {{this.messageService.greaterThanMessage('check-in time', 'Check-out time')}}</div>

                            <div *ngIf="detail().controls[i].get('regulized_checkout_time')?.errors?.outoffshift">
                              {{this.messageService.shiftwindowMessage('Check-in','Check-out')}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label required">Hours </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Hours will be calculated based on Check-In and Check-Out time."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                    <input [readonly]="true" [attr.disabled]="true" type="text" class="form-control" autocomplete="off"
                      name="hours" id="hours" placeholder="{{this.messageService.descriptiontext('time')}}"
                      formControlName="hours"
                      [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('hours')?.dirty || detail().controls[i].get('hours')?.touched) &&  detail().controls[i].get('hours')?.errors}">
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="" class="form-label required">Shift </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="The Shift to which the employee is mapped to will appear automatically."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                    <input type="text" [readonly]="true" [attr.disabled]="true" class="form-control" autocomplete="off"
                      name="shift_name" id="shift_name"
                      placeholder="{{this.messageService.selectplaceholddisplay('shift')}}"
                      formControlName="shift_name_code"
                      [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('shift_name')?.dirty || detail().controls[i].get('shift_name')?.touched) &&  detail().controls[i].get('shift_name')?.errors}">
                  </div>
                  <div class=" col-md-12 form-row">
                    <label for="" class="form-label required">Reason</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter the reason for requesting a regularization."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                    <textarea type="text" rows="3" class="form-control" autocomplete="off" id="reason" name="reason"
                      placeholder="{{this.messageService.placeholderdisp('description here...')}}"
                      formControlName="reason"
                      [ngClass]="{ 'is-invalid': (submitted || detail().controls[i].get('reason')?.dirty || detail().controls[i].get('reason')?.touched) &&  detail().controls[i].get('reason')?.errors}"></textarea>
                    <div
                      *ngIf="(submitted || detail().controls[i].get('reason')?.dirty || detail().controls[i].get('reason')?.touched) && detail().controls[i].get('reason')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="detail().controls[i].get('reason')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="detail().controls[i].get('reason')?.errors?.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                      <div
                        *ngIf="!detail().controls[i].get('reason')?.errors?.pattern && detail().controls[i].get('reason')?.errors?.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid || !canSave"
      (click)="submitResignationDetails()">SAVE</button>
  </footer>
</form>
