<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row row-24">
        <div class="col-12">
            <h3>Email</h3>
            <p class="text-helper mb-md-0">Email display names can be configured here in order to send e-mails to the employees</p>
        </div>
        <app-edit-loader *ngIf="loader"></app-edit-loader>
        <ng-container *ngIf="loader==false">
          <form class="" autocomplete="off" [formGroup]="emailnameform">
          <div class="col-12">
            <div class="card card-c2 px-24 pt-16 pb-24">
                <div class="row row-24">
                    <div class="col-12"> <div class="title">DETAILS</div></div>
                    <div class="col-12">
                        <label for="" class="form-label required">Email display name </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the email display name that should be displayed for emails triggered by the system."  popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                        <input type="text" formControlName="name" class="form-control"
                                      autofill="name" [ngClass]="{ 'is-invalid': (submitted || f.name?.dirty || f.name?.touched) && f.name?.errors || f.name?.errors?.exist}"
                                      id="name" placeholder="{{this.messageService.placeholderdisp('email display name')}}" (keyup)="valuechange($event)" >
                                  <div *ngIf="(submitted || f.name?.dirty || f.name?.touched) && f.name?.errors || f.name?.errors?.exist"
                                      class="invalid-feedback">
                                          <div *ngIf="f.name?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                          <div *ngIf="!(f.name?.errors?.pattern) && f.name?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','50')}}
                                          </div>
                                          <div *ngIf="f.name?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                  </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-12 d-flex">
              <button class="btn btn-primary ms-auto btn-sm mt-24" [disabled]="btnenable==false" (click)="savemailname()">SAVE</button>
          </div>
        </form>
        </ng-container>
     </div>
</div>
