import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-fbp-declaration-approval',
  templateUrl: './fbp-declaration-approval.component.html',
  styleUrls: ['./fbp-declaration-approval.component.scss']
})
export class FbpDeclarationApprovalComponent implements OnInit {
  loader = true;

  @Input() requestitem:any;

  form = this.fb.group({
    read_status: [true,Validators.required],
    star: [false,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

   // View Status
   billLoader                      = false;
   viewstatusPanel                 = false;
   viewStatusData:any              = [];
   viewStatusLevelCnt:any;
   viewStatusmaxLevel:any;

  constructor(
    private inboxservice: InboxService,
    private fb: FormBuilder,
    public messageService : MessageService,
    private investmentService:InvestmentDeclerationService
  ) { }



  ngOnInit(): void {
    setTimeout(() => {
      if(this.requestitem.fbp_request_data?.fbp_request_category != undefined){
        this.loader = false;
      }
    }, 1500);
  }

  // ngOnChanges(){
  //   this.loader = true;
  //   setTimeout(() => {
  //     if(this.requestitem.fbp_request_data.fbp_request_category != undefined)
  //       this.loader = false;
  //   }, 1000);
  // }

  performAction(action:any,type:any=0,id:any,comment_value:any='',read_status:any=true,star:any=true){
    let dataArray:any = [];
    if(action == 1){
      if(read_status == false){
        read_status = true;
        dataArray = {
          "read_status": read_status,
       };
      }
    }else if(action == 2){
      if(star == false){
        star = true;
      }else{
        star = false;
      }
      dataArray = {
        "star": star,
     };
    }
    if(dataArray.length != 0){
      this.inboxservice.updateInboxItem(id,dataArray).subscribe((res: any) =>{
        // console.log("inbox",res);

        if(res){
          Object.entries(dataArray).forEach(([key, value]) => {
              if(this.requestitem['id'] == id){
                this.requestitem[key] = value;
              }
          })
        }
      })
    }
  }
  get f() { return this.form.controls; }

  confirm(status:any){
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form['controls'].comment.valid){
    this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
      // console.log("confirm",res);

      if(res){
        this.successEmitter.emit(true);
      }
    })
  }
  }

  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      // console.log("reqst",res);

      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
    }
}
