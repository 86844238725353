<div class="config-layout">
  <header class="config-header">
    <h5 class="m-0">{{title}} Letter Template</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class="config-sidebar">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step" [class]="{'success': letterTemplateForm['controls'].details.valid ||  letterTemplateForm['controls'].details.disabled }">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Letter template details</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-letter-template-details [formGp]="detailsForm" [editflag]="iseditForm" [modulesDropdown]="modulesDropdown" [typesDropdown]="typesDropdown" (moduleIdFn)="moduleIdFn($event)" (submitMethod)="submitMethod(2)"></app-letter-template-details>
          </ng-template>
          <li *ngIf="!showTab" [ngbNavItem]="2">
            <a ngbNavLink class="step" [class]="{'success':(letterTemplateForm['controls'].templateSelect.valid || letterTemplateForm['controls'].templateSelect.disabled) && (iseditForm || tab2touched)}">
              <div class="count">2</div>
              <div class="content">
                <span>Step 2</span>
                <h6>Select template</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-letter-template-select-template [formGp]="templateSelectForm" [editorForm]="templateEditorForm" [disabled]="disabled" [defaultTemplate]="defaultTemplate" (submitMethod)="submitMethod(3)"></app-letter-template-select-template>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink class="step" [class]="{'success':((letterTemplateForm['controls'].templateEditor.valid || letterTemplateForm['controls'].templateEditor.disabled)|| letterTemplateForm['controls'].templateEditor.disabled) && (iseditForm || tab3touched)}">
              <div class="count">{{showTab ? '2' : '3'}}</div>
              <div class="content">
                <span>Step {{showTab ? '2' : '3'}}</span>
                <h6>Edit template </h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-letter-template-edit-template [formGp]="templateEditorForm" [templateData]="templateSelectForm" [disabled]="disabled" [tagList]="tagList" (fontDeatils)="fontDeatils($event)" (submitMethod)="submitMethod(4)"></app-letter-template-edit-template>
            </ng-template>
          </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink class="step" [class]="{'success':((letterTemplateForm['controls'].preview.valid || letterTemplateForm['controls'].preview.disabled)|| letterTemplateForm['controls'].preview.disabled)}">
            <div class="count">{{showTab ? '3' : '4'}}</div>
            <div class="content">
              <span>Step {{showTab ? '3' : '4'}}</span>
              <h6>Preview</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-letter-template-preview [formGp]="previewForm" [showTab]="showTab" (submitMethod)="submitForm()"></app-letter-template-preview>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class="config-container">
      <!-- <pre>Form Values: {{templateEditorForm.value | json}}</pre> -->
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
