<div class="container-fluid p-24">
  <div class="row  mb-16">
    <div class="col-lg-4 ">
      <h3>Holiday </h3>
      <p class="text-helper mb-md-0">Access your Holiday calendar to manage your time off</p>
    </div>
    <div class="col-lg-8 ">
      <div class="d-flex justify-content-end  flex-wrap gap-16">
        <!-- <button (click)="CalenderListScroll()">Hello</button>   -->
        <div class=" " style="min-width:22.6875rem;">
          <div class="" style="width:22.6875rem;">
            <app-searchbar #search [searchlist]='holidays' [searchplaceholder]='holidaysearch'
              (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchday($event)" [isLoading]="sLoading"></app-searchbar>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block ">
          <button class="btn-square btn btn-secondary" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';listFilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';listFilter(false)">Floating holiday</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';listFilter(true)">National holiday</button>
            <button ngbDropdownItem [class]="{'active':statusBtn =='regular' }"
              (click)="statusBtn='regular';listFilter('regular')">Regular holiday</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-24 ">
    <!-- <ng-container *ngIf="shift_exception_flag">
            <div class="col-12">
                <div role="alert" class="alert alert-warning show m-0" *ngIf="isOpen">
                    <p class="mb-0"><span class="text-warning">Note: </span> Shift is not configured. For more details
                        contact HR. </p>
                        <div class="btn-close pointer" (click)="isOpen=false;"></div>
                </div>
            </div>
        </ng-container> -->

    <ng-container>
      <div class="col-8">
        <ng-container *ngIf="monthLoader">
          <app-calendar-loader></app-calendar-loader>
        </ng-container>
        <ng-container *ngIf="!monthLoader">
          <div class="card card-c2 ">
            <div class="card-body px-32 pt-24 pb-0">
              <full-calendar #calendar
                class="fullcalendar-reset fullcalendar-c2 fullcalendar-fulloption fullcalendar-c3"
                [options]="calendarOptions"></full-calendar>
            </div>
            <div class="card-body bg-white z-3 border-0 px-32 pt-16 pb-24 position-sticky bottom-0">
              <div class="d-flex flex-wrap  gap-16 fs-12 fw-600">

                <div class="d-flex align-items-center gap-8">
                  <span class="type-indicator sq-12 type-national-holiday"></span>NATIONAL HOLIDAY
                </div>
                <div class="d-flex align-items-center gap-8">
                  <span class="type-indicator sq-12 type-floating-holiday"></span>FLOATING HOLIDAY
                </div>
                <div class="d-flex align-items-center gap-8">
                  <span class="type-indicator sq-12 type-regular-holiday"></span>REGULAR HOLIDAY
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
      <div class="col-4">
        <ng-container *ngIf="listLoader">
          <div class="card card-c2 card-highlight-top p-24 gap-32 card-height h-100" >
            <app-c1-loader></app-c1-loader>
          </div>
        </ng-container>
        <ng-container *ngIf="!listLoader">
          <div class="card card-c2 card-highlight-top calendar-list p-24 gap-32 card-height h-100">
            <ng-container *ngIf="!holiday_exception_flag">
              <ng-container *ngFor="let holiday of calendarHolidays">
                <div class="d-flex flex-column gap-8 ">
                  <div class="holiday-title" id="{{holiday.month}}{{year}}">
                    <div class="fw-500 holiday-name">{{holiday.month}} {{year}}</div>
                    <div class="fs-12 fw-500 text-light-400" *ngIf="dayFlag">{{holiday.days}} day(s)
                    </div>
                    <div class="fs-12 fw-500 text-light-400" *ngIf="!dayFlag"></div>
                  </div>
                  <ng-container *ngFor="let list of holiday.holidays_list;let i=index">
                    <div class="card card-holiday national-holiday floating-holiday card-hover"
                      [ngClass]="{'national-holiday':(list.national_holiday || (list.national_holiday && list.floating)),'floating-holiday':(!list.national_holiday && list.floating),'regular-holiday':(!list.national_holiday && !list.floating)}"
                      (click)="goToDate(list.fromdate)">
                      <div class="holiday-day">
                        <span class="fs-12">{{getWeekDay(list.fromdate)}}</span>
                        <span class="fw-500">{{getDay(list.fromdate)}}</span>
                      </div>
                      <div class="holiday-desc fs-14">
                        <div class="fw-600 text-black text-break  ">{{list.holiday}}
                          {{list.day_name != null?'(day '+ list.day_name +')':''}}</div>
                        <div class="text-light-500" *ngIf="list.national_holiday">National
                          Holiday
                        </div>
                        <div class="text-light-500" *ngIf="list.floating">Floating
                          Holiday
                        </div>
                        <div class="text-light-500" *ngIf="!list.floating && !list.national_holiday">Regular Holiday
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="holiday_exception_flag">
              <div class="empty-container h-100 text-light-500">
                <i class="icon-holiday fs-88 opacity-25"></i>
                <p class="fs-14 fw-500">Holiday policy is not configured!</p>
              </div>
            </ng-container>
            <ng-container *ngIf="nodata==true && !holiday_exception_flag">
              <div class="empty-container h-100 text-light-500">
                <i class="icon-holiday fs-88 opacity-25"></i>
                <p class="fs-14 fw-500">No data found. </p>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
