<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='fbdeclarationFlag === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>FBP Declaration</h5>
      <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="d-flex mb-16">
          <div class="me-auto">
            <p class="fw-500 mb-0">FBP Declaration</p>
            <span class="fs-12">FY {{this.appservice.getFinancialYear()}} - FY {{(this.appservice.getFinancialYear() + 1)}}</span>
          </div>
          <div class="d-flex justify-content-end  gap-16" *ngIf="!is_FBP_requested">
            <div class=" " style="width:14.8125rem;">
              <div class="icon-left ">
                <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='categorysearch'
                  (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchFBPCategory($event)">
                </app-searchbar>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-16 mb-24"  *ngIf="is_FBP_requested">
          <div class="col-12">
            <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
              <p class="mb-0 fs-12">FBP declaration is closed for submission on 1 Mar {{currentYear}} and locked for the current financial year</p>
              <div class="btn-close" (click)="alertShow='hide'"></div>
            </div>
          </div>
        </div>
        <!--No data found-->
        <div class="row pt-5 flex-center text-center" *ngIf="nodata && !loader;else all_data">
          <!-- <div class="col-12 text-center">
            <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
              style="width: 27.375rem;">
            <p class="text-uppercase text-light-400 fw-700 fs-14">FBP CATEGORY HAVEN'T BEEN CONFIGURED YET</p>
          </div> -->
          <app-nodata-view [noDataText]="'FBP CATEGORY HAVE NOT BEEN CONFIGURED YET'" [hasPermission]="false"> </app-nodata-view>
        </div>
        <!--End-->
        <app-c1-loader *ngIf="loader"></app-c1-loader>
        <ng-template #all_data>
          <ng-container *ngIf="!loader">
            <div class="row row-24">
              <div class="col-4">
                <div class="card card-c2 card-highlight highlight-primary">
                  <div class="card-body p-16 text-center">
                    <h6 class="fw-500  fs-20">{{fbpSummaryData?.tile_data?.total_allocated_amount | currency: currency}}</h6>
                    <hr class="hr-1 mb-16 mt-16">
                    <div class="mb-0 fw-500 fs-12">Total allocated amount
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover=" This is the maximum amount allocated for your FBP components, you can declare the components within the specified limit set for each component." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" container="body"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card card-c2 card-highlight highlight1">
                  <div class="card-body p-16 text-center">
                    <h6 class="fw-500  fs-20">{{fbpSummaryData?.tile_data?.fbp_declared | currency: currency}}</h6>
                    <hr class="hr-1 mb-16 mt-16">
                    <div class="mb-0 fw-500 fs-12">FBP declared
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="This is the amount availed from the allotted amount. you need to submit relevant bills and claim reimbursements to receive tax exemption or else this amount will be taxable at the end of the  fiscal year." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" container="body"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card card-c2 card-highlight highlight2">
                  <div class="card-body p-16 text-center">
                    <h6 class="fw-500  fs-20">{{fbpSummaryData?.tile_data?.balance_aount | currency: currency}}</h6>
                    <hr class="hr-1 mb-16 mt-16">
                    <div class="mb-0 fw-500 fs-12">Balance amount
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="This is the amount remaining after FBP declaration which will be considered as your taxable income." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" container="body" ></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-16 mt-24" *ngIf="!is_FBP_requested">
              <div class="col-12 ">
                <div class="fw-500">FBP Category</div>
              </div>
              <div class="col-12">
                <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
                  <p class="mb-0 fs-12">Select the desired category and enter the annual amount.</p>
                  <div class="btn-close" (click)="alertShow='hide'"></div>
                </div>
              </div>

              <div class="col-12">
                <form [formGroup]="formGp" class="row row-16">
                  <ng-container formArrayName="fbpArray" *ngFor="let item of fbpArrays().controls; let i=index; ">
                    <ng-container [formGroupName]="i">
                      <div class="col-12">
                        <div class="card card-c2 py-16 px-24">
                          <div class="row row-16">
                            <div class="col-12 d-flex align-items-center">
                              <div class="form-check me-auto">
                                <label for="type{{i}}"
                                  class="form-check-label ">{{fbpArrays().controls[i].get('pay_component__name')?.value}}</label>
                                <input type="checkbox" class="form-check-input" id="type{{i}}" name="pay_component"
                                  formControlName="pay_component" (change)="fbpCheckboxFunction($event,i)">
                              </div>
                              <div class="fs-10 fw-500 text-light-400 text-uppercase">Max amount :
                                {{fbpArrays().controls[i].get('fbp_amount')?.value | currency: currency}}</div>
                            </div>
                            <ng-container *ngIf="(checkedArray[i]['checked'+i])">
                              <div class="col-6">
                                <label for="first_name" class="form-label required">Amount annually</label>

                                <div class="form-icon icon-right"
                                  [ngClass]="{ 'is-invalid': (fbpArrays().controls[i].get('annually_amount')?.dirty || fbpArrays().controls[i].get('annually_amount')?.touched) &&  fbpArrays().controls[i].get('annually_amount')?.errors}">
                                  <input
                                    [ngClass]="{ 'is-invalid': (fbpArrays().controls[i].get('annually_amount')?.dirty || fbpArrays().controls[i].get('annually_amount')?.touched) &&  fbpArrays().controls[i].get('annually_amount')?.errors}"
                                    type="text" class="form-control" autocomplete="off" id="annually_amount"
                                    placeholder="{{this.messageService.placeholderdisp('amount')}}"
                                    formControlName="annually_amount" (keyup)="calculateMonthlyAmt(i)">
                                  <span>{{currency}}</span>
                                </div>
                                <div
                                  *ngIf="(fbpArrays().controls[i].get('annually_amount')?.dirty || fbpArrays().controls[i].get('annually_amount')?.touched) && fbpArrays().controls[i].get('annually_amount')?.errors"
                                  class="invalid-feedback">
                                  <div *ngIf="fbpArrays().controls[i].get('annually_amount')?.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                  <div *ngIf="fbpArrays().controls[i].get('annually_amount')?.errors?.pattern">
                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                  <div
                                    *ngIf="!(fbpArrays().controls[i].get('annually_amount')?.errors?.pattern) && fbpArrays().controls[i].get('annually_amount')?.errors?.max">
                                    {{this.messageService.balanceAmountValidation()}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <label for="first_name" class="form-label required">Amount monthly</label>

                                <div class="form-icon icon-right"
                                  [ngClass]="{ 'is-invalid': (fbpArrays().controls[i].get('monthly_amount')?.dirty || fbpArrays().controls[i].get('monthly_amount')?.touched) &&  fbpArrays().controls[i].get('monthly_amount')?.errors}">
                                  <input
                                    [ngClass]="{ 'is-invalid': (fbpArrays().controls[i].get('monthly_amount')?.dirty || fbpArrays().controls[i].get('monthly_amount')?.touched) &&  fbpArrays().controls[i].get('monthly_amount')?.errors}"
                                    type="text" class="form-control" autocomplete="off" id="monthly_amount"
                                    formControlName="monthly_amount" (keyup)="calculateAnuallyAmt(i)">
                                  <span>{{currency}}</span>
                                </div>
                                <div
                                  *ngIf="(fbpArrays().controls[i].get('monthly_amount')?.dirty || fbpArrays().controls[i].get('monthly_amount')?.touched) && fbpArrays().controls[i].get('monthly_amount')?.errors"
                                  class="invalid-feedback">
                                  <div *ngIf="fbpArrays().controls[i].get('monthly_amount')?.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                  <div *ngIf="fbpArrays().controls[i].get('monthly_amount')?.errors?.pattern">
                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </form>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <footer class="side-panel-footer gap-32" *ngIf="!is_FBP_requested">
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" type="submit"
        [disabled]="!formGp.valid || disabledFunction() || saveClicked" (click)="requestSubmitFunction()">SUBMIT</button>
    </footer>
  </div>
</div>
