import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InboxRoutingModule } from './inbox-routing.module';
import { InboxComponent } from './inbox.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { LeaveApprovalComponent } from './leave-approval/leave-approval.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { RegularizationApprovalComponent } from './regularization-approval/regularization-approval.component';
import { OnDutyApprovalComponent } from './on-duty-approval/on-duty-approval.component';
import { EmployeeDataApprovalComponent } from './employee-data-approval/employee-data-approval.component';
import { InvestmentDeclarationApprovalComponent } from './investment-declaration-approval/investment-declaration-approval.component';
import { SalaryStructureApprovalComponent } from './salary-structure-approval/salary-structure-approval.component';
import { FloatingHolidayApprovalComponent } from './floating-holiday-approval/floating-holiday-approval.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import * as moment from 'moment';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SalaryRevisionComponent } from './salary-revision/salary-revision.component';
import { HrModule } from '../hr/hr.module';
import { ReimbursementApprovalComponent } from './reimbursement-approval/reimbursement-approval.component';
import { FbpDeclarationApprovalComponent } from './fbp-declaration-approval/fbp-declaration-approval.component';
// import { ReimbursementDetailsComponent } from './reimbursement-approval/reimbursement-details/reimbursement-details.component';
// import { ViewReimbursementDetailsComponent } from './reimbursement-approval/view-reimbursement-details/view-reimbursement-details.component';
// import { ReimbursementAnalysisComponent } from './reimbursement-approval/reimbursement-analysis/reimbursement-analysis.component';
import { InactiveRequestComponent } from './inactive-request/inactive-request.component';
import { FullAndFinalSettlementComponent } from './full-and-final-settlement/full-and-final-settlement.component';
import { FullAndFinalApprovalComponent } from './full-and-final-approval/full-and-final-approval.component';
import { WorkFromHomeApprovalComponent } from './work-from-home-approval/work-from-home-approval.component';
import { OvertimeApprovalComponent } from './overtime-approval/overtime-approval.component';
import { CompensatoryOffComponent } from './compensatory-off/compensatory-off.component';
import { InboxProgressBarComponent } from './inbox-progress-bar/inbox-progress-bar.component';
import { InboxSubmitFormComponent } from './inbox-submit-form/inbox-submit-form.component';
import { IgxTimePickerModule } from 'igniteui-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CheckinApprovalComponent } from './checkin-approval/checkin-approval.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { environment } from 'src/environments/environment';
import { AssetRequestApprovalComponent } from './asset-request-approval/asset-request-approval.component';
import { ManageAssetApprovalComponent } from './manage-asset-approval/manage-asset-approval.component';
import { ExpenseReportComponent } from './expense-report/expense-report.component';
import { TripRequestComponent } from './trip-request/trip-request.component';
import { AdvanceApprovalComponent } from './advance-approval/advance-approval.component';
import { AppointmentLetterComponent } from './appointment-letter/appointment-letter.component';
import { ConfirmationLetterComponent } from './confirmation-letter/confirmation-letter.component';
import { ConfirmationReqApprovalComponent } from './confirmation-req-approval/confirmation-req-approval.component';
import { InboxTitleComponent } from './inbox-title/inbox-title.component';
import { ShortLeaveComponent } from './short-leave/short-leave.component';
@NgModule({
  declarations: [
    InboxComponent,
    LeaveApprovalComponent,
    RegularizationApprovalComponent,
    OnDutyApprovalComponent,
    EmployeeDataApprovalComponent,
    InvestmentDeclarationApprovalComponent,
    SalaryStructureApprovalComponent,
    FloatingHolidayApprovalComponent,
    SalaryRevisionComponent,
    ReimbursementApprovalComponent,
    FbpDeclarationApprovalComponent,
    // ReimbursementDetailsComponent,
    // ViewReimbursementDetailsComponent,
    // ReimbursementAnalysisComponent,
    InactiveRequestComponent,
    FullAndFinalSettlementComponent,
    FullAndFinalApprovalComponent,
    WorkFromHomeApprovalComponent,
    OvertimeApprovalComponent,
    CompensatoryOffComponent,
    InboxProgressBarComponent,
    InboxSubmitFormComponent,
    CheckinApprovalComponent,
    AssetRequestApprovalComponent,
    ManageAssetApprovalComponent,
    ExpenseReportComponent,
    TripRequestComponent,
    AdvanceApprovalComponent,
    AppointmentLetterComponent,
    ConfirmationLetterComponent,
	ConfirmationReqApprovalComponent,
    InboxTitleComponent,
    ShortLeaveComponent
  ],
  imports: [
    CommonModule,
    InboxRoutingModule,
    DpDatePickerModule,
    NgxChartsModule,
    NgSelectModule,
    AngularMyDatePickerModule,
    MasterSelectModule,
    NgbModule,
    InfiniteScrollModule,
    PdfViewerModule,
    HrModule,
    Ng2SearchPipeModule,
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MMM/YYYY',
      clearLabel: 'Cancel'
    }),
    FormsModule,
    ReactiveFormsModule,
    IgxTimePickerModule,
    MatDatepickerModule,
    AgmCoreModule.forRoot({
      apiKey: environment.locKey,
      libraries: ['places']
    }),
    AgmDirectionModule
  ],
  providers: [
    GoogleMapsAPIWrapper
  ]
})
export class InboxModule { }
