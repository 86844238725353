import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';

@Component({
  selector: 'app-overtime-approval',
  templateUrl: './overtime-approval.component.html',
  styleUrls: ['./overtime-approval.component.scss']
})

export class OvertimeApprovalComponent implements OnInit {

  multi:any                 = [];

  timeOffTrend = false;
  editFinalOverTime = false;

  barchart:any               = [];
  barChartXAxisLabels:any    = [];
  barChartYAxisLabel:any;
  barchartLoader             = false;

  @Input() requestitem: any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

  // For view status
  billLoader                  = false;
  viewstatusPanel             = false;
  viewStatusData:any          = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;

  // For trend graph
  currentYear               =  new Date().getFullYear();
  selectedYearDisp          = String(this.currentYear);
  selectedYear              = this.currentYear;
  yearList: any             = [];
  empId: any;

  constructor(
    private inboxservice: InboxService,
    private fb: FormBuilder,
    private investmentService: InvestmentDeclerationService,
    public messageService: MessageService,
    public appservice: AppService
  ) { }

  ngOnInit(): void {
    this.yearListCreate();
    // this.getOvertimeTrendDetails(this.currentYear);
  }

  form = this.fb.group({
    read_status      : [true,Validators.required],
    approved_status  : [false,Validators.required],
    comment          : ['', Validators.maxLength(500)],
  })

  form1 = this.fb.group({
    final_overtime_hours : ['',Validators.required],
  })

  get f() {return  this.form.controls; }

  get f1() {return  this.form1.controls; }

  refreshInbox(event: boolean){
    if(event){
      this.successEmitter.emit(event);
    }
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,
      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  requestViewStatus(){
    this.viewstatusPanel  = true
    this.billLoader       = true;
     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }

  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }

  getOvertimeTrendDetails(event:any){
    this.barchartLoader   = true;
    this.selectedYearDisp = String(event);
    this.selectedYear     = event;
    this.empId = this.requestitem?.from_person?.id;
    if(this.empId != null && this.empId != '' && this.empId != undefined){
      this.inboxservice.getOvertimeTrend(event,this.empId).subscribe((res:any)=>{
        if(res){
          this.multi = res?.data;
          if(this.multi?.length > 0)
           this.barChartDataConstruction(this.multi);
        }
      })
    }
  }

  editFinalOverTimeFunction(from:any){
    this.editFinalOverTime = true;
    if(from == 'popup')
     this.form1.get('final_overtime_hours')?.setValue('');
    if(from == 'confirm'){
      this.inboxservice.editFinalOvertimeHours(this.requestitem?.overtime?.id,{"attendance_date": this.appservice.dateFormatConvert(this.requestitem?.overtime?.attendance_date), "employee": this.requestitem?.from_person?.id, "final_hours": moment(this.form1.value?.final_overtime_hours).format("HH:mm:ss")} ).subscribe((res:any)=>{
        if(res){
          this.requestitem.overtime.final_hours = this.appservice.hhmmTimeFormat(res?.final_hours);
          this.editFinalOverTime = false;
        }
      })
    }
  }

  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }

  confirm(status:any){

    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form['controls'].comment.valid){
      this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
        if(res){
          this.successEmitter.emit(true);
        }
      })
  }
  }

  // Bar chart
  barChartDataConstruction(data:any){

    let hours:any            = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel  = 'Hours';
    this.barchart            = [];
    data.forEach((item:any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1:any) => {
        hours.push(item1?.value)
      });
    });

    this.barchart.push({label: "Hours", data: hours, backgroundColor: '#0078CE', barThickness: 10 })

    if(this.barchart?.length > 0)
      this.timeOffTrend = true;
    this.barchartLoader = false;

  }
  // End

}
