<form class="side-panel-container" [formGroup]="leaveConfigForm" autocomplete="off">
    <ng-container formArrayName="leaveRequestcriteria" *ngFor="let config of leaveRequestCriteriaForm.controls; let i = index">
        <ng-container [formGroupName]="i">
  <ng-container *ngIf="leaveTypeCode==leaveRequestCriteriaForm.controls[i].get('leave_code')?.value">
<div class="container-fluid  py-24 px-16">
    <div class="row ">
        <p class="text-uppercase fw-500">LEAVE REQUEST CRITERIA</p>
    </div>
    <div class="row row-16">
        <div class="col-lg-12 form-row ">
            <div class="col-12 form-row">
                <label for="prior_days_required_req_leave" class="form-label  ">Prior days required to request leave </label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option to define how many days before the leave date, employee should apply for the leave." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                formControlName="prior_days_required_req_leave" [value]=true (ngModelChange)="addValidator()">
                <label class="form-check-label">Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" formControlName="prior_days_required_req_leave" type="radio" [value]=false  (ngModelChange)="addValidator()">
                <label class="form-check-label">No</label>
            </div>
        </div>
        <ng-container *ngIf="leaveRequestCriteriaForm.controls[i].get('prior_days_required_req_leave')?.value">
        <div class=" col-12 form-row"  >
            <input type="text" class="form-control " formControlName="prior_days" placeholder="Enter Days " [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestCriteriaForm.controls[i].get('prior_days')?.touched || leaveRequestCriteriaForm.controls[i].get('prior_days')?.dirty) &&  leaveRequestCriteriaForm.controls[i].get('prior_days')?.errors }">
            <div *ngIf="(saveBtnClicked || leaveRequestCriteriaForm.controls[i].get('prior_days')?.touched || leaveRequestCriteriaForm.controls[i].get('prior_days')?.dirty) && leaveRequestCriteriaForm.controls[i].get('prior_days')?.errors"
            class="invalid-feedback">
            <div *ngIf="leaveRequestCriteriaForm.controls[i].get('prior_days')?.errors.required">
                {{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="leaveRequestCriteriaForm.controls[i].get('prior_days')?.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
            <div *ngIf="!(leaveRequestCriteriaForm.controls[i].get('prior_days')?.errors?.pattern) && (leaveRequestCriteriaForm.controls[i].get('prior_days')?.errors?.min)">
              {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
            </div>
            </div>
        </div>
        </ng-container>
        <div class="col-12 ">
            <div class="form-row">
                <label for=" " class="form-label ">Is Back dated leave request allowed?</label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="This defines whether leave can be applied for previous dates(yesterday, day before yesterday and so on). If this option is enabled then enter backdated leave request is allowed for how many days." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" formControlName="backdated_leave_allow" type="radio" [value]=true (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" formControlName="backdated_leave_allow" type="radio" [value]=false (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>
        <ng-container *ngIf="leaveRequestCriteriaForm.controls[i].get('backdated_leave_allow')?.value">
        <div class="col-12 mt-8">
            <input type="text" class="form-control " formControlName="backdated_days" placeholder="Enter Days"  [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestCriteriaForm.controls[i].get('backdated_days')?.touched || leaveRequestCriteriaForm.controls[i].get('backdated_days')?.dirty) &&  leaveRequestCriteriaForm.controls[i].get('backdated_days')?.errors }"
            >
            <div *ngIf="(saveBtnClicked || leaveRequestCriteriaForm.controls[i].get('backdated_days')?.touched || leaveRequestCriteriaForm.controls[i].get('backdated_days')?.dirty) && leaveRequestCriteriaForm.controls[i].get('backdated_days')?.errors"
            class="invalid-feedback">
            <div *ngIf="leaveRequestCriteriaForm.controls[i].get('backdated_days')?.errors.required">
                {{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="leaveRequestCriteriaForm.controls[i].get('backdated_days')?.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
            <div *ngIf="!(leaveRequestCriteriaForm.controls[i].get('backdated_days')?.errors.pattern) && leaveRequestCriteriaForm.controls[i].get('backdated_days')?.errors.maxlength">
              {{this.messageService.fieldlengthvalidation('num','10')}}
            </div>
            <div *ngIf="!(leaveRequestCriteriaForm.controls[i].get('backdated_days')?.errors?.pattern) && (leaveRequestCriteriaForm.controls[i].get('backdated_days')?.errors?.min)">
              {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
            </div>
            </div>

        </div>
    </ng-container>
        <div class="col-12 ">
            <div class="form-row">
                <label for=" " class="form-label required">Can employee raise leave request during Probation?</label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover='Select "Yes" if employees are allowed to request leave during their probation period. Select "No" if they are not.' popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="leave_during_probation"  [value]=true >
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="leave_during_probation" [value]=false >
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>

        <div class="col-12 ">
          <div class="form-row">
              <label for=" " class="form-label required">Can employee raise leave request during notice period?</label>
              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover='Select "Yes" if employees are allowed to request leave during their notice period. Select "No" if they are not.' popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
          <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="leave_during_noticeperiod"  [value]=true >
              <label class="form-check-label" >
                  Yes
              </label>
          </div>
          <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="leave_during_noticeperiod" [value]=false >
              <label class="form-check-label" >
                  No
              </label>
          </div>
      </div>

        <div class="col-12 ">
            <div class="form-row">
                <label for=" " class="form-label ">Is document required for this leave? </label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option to define whether attachment is mandatory for applying leave." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="doc_required_leave"  [value]=true (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="doc_required_leave" [value]=false (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>
        <ng-container *ngIf="leaveRequestCriteriaForm.controls[i].get('doc_required_leave')?.value">
        <div class="col-12">
            <label for="name" class="form-label required">Document required if leave exceeds </label>
            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If an employee exceeds the specified leave limit, the employee must produce relevant documents. Enter the leave limit." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            <input type="text" class="form-control " formControlName="leave_exceed" placeholder="Enter Days"  [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.touched || leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.dirty) &&  leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.errors }">

            <div *ngIf="(saveBtnClicked || leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.touched || leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.dirty) && leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.errors"
            class="invalid-feedback">
            <div *ngIf="leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.errors.required">
                {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.errors.pattern) && leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','10')}}
                </div>
                <div *ngIf="!(leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.errors?.pattern) && (leaveRequestCriteriaForm.controls[i].get('leave_exceed')?.errors?.min)">
                  {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
                </div>


        </div>
        </div>
        </ng-container>
        <div class="col-12 ">
            <div class="form-row">
                <label for=" " class="form-label ">Is leave clubbing restricted with other leave types?</label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option to restrict employees from submitting leave requests for two different leave types on consecutive days. For example, if 'Sick Leave' is selected in the settings for 'Casual Leave', employees will not be allowed to apply for Sick Leave immediately before or after the Casual Leave.
                  " popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="leave_clubbing_not_allowed_status"  [value]=true (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="leave_clubbing_not_allowed_status" [value]=false (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>
        <ng-container *ngIf="leaveRequestCriteriaForm.controls[i].get('leave_clubbing_not_allowed_status')?.value">
        <div class="col-12">
            <label for="name" class="form-label required">Leave types</label>

            <ng-select placeholder="Select leave type"
            [ngClass]="{ 'is-invalid': (submitted || leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.touched) &&  leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.errors}"
            formControlName="leave_clubbing_leave_type" [items]="leaveType" [multiple]="true" groupBy="selectedAllGroup"
            [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="leave_name"
            [closeOnSelect]="false" bindValue="id" class="multiselect"  [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.touched || leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.dirty) &&  leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.errors }">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" />
                {{ item.leave_name }}
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.value.length ">
                    <span class="ng-value-label">{{leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.value.length }} leave type
                        selected</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.leave_name.length>15)? (item.leave_name | slice:0:15)+'..':(item.leave_name) }}
            </ng-template>
            </ng-select>

        <div *ngIf="(saveBtnClicked || leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.touched || leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.dirty) && leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.errors"
            class="invalid-feedback">
            <div *ngIf="leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.errors.required">
                {{this.messageService.validationDisplay('required')}}</div>


        </div>
        <ul class="selected-values mt-16" *ngIf="leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.value?.length != 0">
            <li class="ng-value" *ngFor="let item of leaveRequestCriteriaForm.controls[i].get('leave_clubbing_leave_type')?.value,let ki =index">
                <ng-container *ngFor="let insideitem of leaveType, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        {{ (insideitem['leave_name'].length>15)? (insideitem['leave_name'] |
                        slice:0:15)+'..':(insideitem['leave_name']) }}</span>
                </ng-container>
                <span class="ng-value-icon right"
                    (click)="clearcommonForm(ki,i)" aria-hidden="true">×</span>
            </li>
        </ul>
        </div>
    </ng-container>
        <div class="col-12 ">
            <div class="form-row">
                <label for=" " class="form-label ">Can this leave be availed only on birthday? </label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select 'Yes' if the leave can only be taken on the employee's birthday; select 'No' if the leave can be taken on other days as well." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="leave_taken_birthday"  [value]=true >
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="leave_taken_birthday"  [value]=false >
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>
        <div class="col-12 ">
            <div class="form-row">
                <label for=" " class="form-label ">Can this leave be availed only on wedding anniversary?</label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select 'Yes' if the leave can only be taken on the employee's wedding anniversary; select 'No' if the leave can be taken on other days as well." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="leave_taken_wedding_anniversary"  [value]=true >
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="leave_taken_wedding_anniversary" [value]=false >
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>

    </div>
</div>
</ng-container>
</ng-container>
</ng-container>
</form>
