import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { AppService } from 'src/app/app.global';
import { Router} from '@angular/router';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailinputValidator } from 'src/app/@shared/validators/emailinput.validators';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InboxService } from 'src/app/@core/services/inbox.service';


@Component({
  selector: 'app-final-settelement-expansion',
  templateUrl: './final-settelement-expansion.component.html',
  styleUrls: ['./final-settelement-expansion.component.scss']
})
export class FinalSettelementExpansionComponent implements OnInit {

  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  approvalRequired                = false;
  salaryRevisionHistory           = false;
  loader1                         = false;
  payslipToggle                   = false;
  isSend                          = false;
  modalToggle                     = false;
  currentDocName                  = 'Payslip';
  currentExtension                = 'pdf';
  pdfUrl:any                      = '';
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  completeData:any;
  @Input()showFinalSettlementView:any;
  @Input() from:any;
  @Input() employeeId:any;
  @Input() currentStatus:any;
  @Input() permissions:any;
  @Output() closeExpansion:any     = new EventEmitter();
  @Input() requestitem: any;
  @Output() refreshEmitter: EventEmitter<boolean> = new EventEmitter();
  payslipForm                      = this.formBuilder.group({
                                      email : [null, [Validators.required,EmailinputValidator()]]
  });
  form = this.formBuilder.group({
    read_status: [true,Validators.required],
    star: [false,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })
  loader:any  =  false;
  constructor(
    public finalService:FinalSettlementService,
    public appservice:AppService,
    public router: Router,
    private investmentService:InvestmentDeclerationService,
    private formBuilder: FormBuilder,
    public messageService : MessageService,
    private notificationService: NotificationService,
    private inboxservice:InboxService

    ) { }

  ngOnInit(): void {
    this.getFandFDetails();

  }
  getFandFDetails(){
    this.loader = true;
    this.finalService.getEmpFinalSettelement(this.employeeId).subscribe((res:any)=>{
      this.completeData = res.data;
      this.loader = false;
    })

  }
  closepopup(){
    this.showFinalSettlementView = false;
    this.closeExpansion.emit();


  }
  editData(){

    this.router.navigate(['/final-settlement/final-settlement-setup/'+this.employeeId]);
  }
  /******** REQUEST VIEW STATUS  ****/
  requestViewStatus(reqId: any){

    this.viewstatusPanel     = true;
    this.loader1             = true;
     this.approvalRequired   = true;
     this.investmentService.requestViewStatus(reqId).subscribe((res: any = []) =>{
       this.viewStatusData     = res;
       if(!res?.apporval_matrix_loading)
        this.loader1            = false;
       if(res.status == 1){
         this.viewStatusLevelCnt = res?.activity?.level_count;
         this.viewStatusmaxLevel = res?.activity?.maxlevel;
       }
     });


  }
  sendPayslip(){
    this.isSend = true;
    let data = {"id": this.employeeId, "email": this.payslipForm.get('email')?.value}
    this.finalService.sendPayslip(data).subscribe((res:any)=>{
      if(res.status==1)
      this.notificationService.handleSuccessNotification("Settlement slip send successfully","Success");
      this.payslipToggle  = false;
      this.payslipForm.reset();

    })
  }
  setEmail(){
    this.payslipForm.get('email')?.setValue(this.completeData?.employee_email);
    this.payslipToggle  = true;
    this.isSend = false;
  }
  get f()  { return this.payslipForm.controls; }


  get af() {return  this.form.controls; }


  confirm(status:any){
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }
    if(this.form['controls'].comment.valid){

      if(this.from == 'inbox'){
        this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
          if(res){
            this.refreshEmitter.emit(true);
          }
        })
      }

  }
  }
  viewSlip(type:any){
    this.modalToggle = true;
    if(type == 'pay'){
      this.currentDocName = this.appservice.getPayTaxName(this.completeData?.payslip,'Payslip');
      this.pdfUrl         = this.completeData?.payslip+"?"+Date.now();
    }else{
      this.currentDocName = this.appservice.getPayTaxName(this.completeData?.taxslip,'Taxslip');
      this.pdfUrl         = this.completeData?.taxslip+"?"+Date.now();
    }

  }
  close(bool:any){
    this.modalToggle = bool;
  }

  downLoad(){
    let url      = this.completeData?.payslip+"?"+Date.now();
    let doc_name = this.appservice.getPayTaxName(this.completeData?.payslip,'Payslip')
    this.appservice.downloadFile(url,doc_name)
  }
}
