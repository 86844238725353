import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-proposed-regime-comparison',
  templateUrl: './proposed-regime-comparison.component.html',
  styleUrls: ['./proposed-regime-comparison.component.scss']
})

export class ProposedRegimeComparisonComponent implements OnInit {

  taxRegimeSlab         = false;
  oldNewFlag            = false;
  loader1               = true;
  enableSaveBtn         = false;
  currentYear: number   = new Date().getFullYear();
  nextYear: number      = (this.currentYear+1);
  alertShow             = 'show';
  alertShow1            = 'show';
  alertShow2            = 'show';
  oldRegimeSummary:any  = [];
  newRegimeSummary:any  = [];
  hra80gg:any;
  selectedYear: any;
  alertToggle           = false;
  investalertMsg:any    = 'You cannot the change the regime since your salary has been processed for one more months in this financial year.';

  @Input()taxRegimeData:any;
  @Input()investmentDetails:any;
  @Output() submitmethod = new EventEmitter();
  taxData:any = [];

  @Input()currency:any;

  constructor(
    public appService: AppService
  ) { }


  ngOnInit(): void {
    this.selectedYear   = this.appService.getFinancialYear();
    setTimeout(() => {
      if(!!Object.keys(this.taxRegimeData)?.length == true){
        this.oldRegimeSummary = this.taxRegimeData.old_regime.regime_summary;
        this.newRegimeSummary = this.taxRegimeData.new_regime.regime_summary;

        if(this.taxRegimeData.old_regime.excemption_declaration.hasOwnProperty('hra'))
          this.hra80gg='hra';
        if(this.taxRegimeData.old_regime.excemption_declaration.hasOwnProperty('80gg'))
          this.hra80gg='80gg';

        // Restricting the selection of regime start
        var elements = document.getElementsByName("btnradio");
        setTimeout(() => {
          for(let i=0;i<elements?.length;i++){
            var element1 = <HTMLInputElement>elements[i];
            if(this.investmentDetails.count_info.proposed_regime == null){
              this.taxData = this.taxRegimeData?.old_regime;
              if(element1.id == 'btnradio1'){
                element1.checked  = true;
              }

            }
            else if(this.investmentDetails.count_info.proposed_regime == 'old'){
              this.oldNewFlag = false;
              this.taxData = this.taxRegimeData?.old_regime;
              if(element1.id == 'btnradio1'){
                element1.checked = true;
              }
              if(element1.id == 'btnradio2'){
                element1.disabled = true;
              }
            }
            else{
              this.oldNewFlag = true;
              this.taxData = this.taxRegimeData?.new_regime;
              if(element1.id == 'btnradio1'){
                element1.disabled = true;
              }
              if(element1.id == 'btnradio2'){
                element1.checked = true;
              }
            }


          }

        }, 1000);
        // Restricting the selection of regime end
        this.loader1 = false;

      }else{
        this.loader1 = true;
      }
    }, 2000);
  }

  formSubmit(){
    this.enableSaveBtn  = false;
    let declared_datail;
    if(this.oldNewFlag)
      declared_datail = JSON.stringify(this.taxRegimeData.new_regime);
    else
      declared_datail = JSON.stringify(this.taxRegimeData.old_regime);
    this.submitmethod.emit({tax_regime:this.oldNewFlag,declared_datail:declared_datail});
  }

  oldNewRadioBtnChangeFunction(e:any){

    // Popup for regime restricted due to salary processed
    if(this.investmentDetails?.count_info?.is_regime_restricted_salary_processed ){
      this.alertToggle = true;
    }else{
      this.alertToggle = false;
    }
    // End

    if(this.investmentDetails.count_info.proposed_regime == null){
      if(e == 'old'){
        this.oldNewFlag = false;
        this.taxData = this.taxRegimeData?.old_regime;
      }
      else{
        this.oldNewFlag = true;
        this.taxData = this.taxRegimeData?.new_regime;
      }
    }

  }

  ackCheckbox(e:any){
    if(e.target.checked == true){
      this.enableSaveBtn = true;
    }else{
      this.enableSaveBtn = false;
    }
  }

  closeTaxSlab(data:any){
    this.taxRegimeSlab = data;
  }

  getBetterRegime(){
    if(Number(this.oldRegimeSummary?.total_tax > this.newRegimeSummary?.total_tax))
      return 'New';
    else
     return 'Old';
  }

  closeInfo(bool:any){
    this.alertToggle = false;
  }

}
