import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { SalaryProcessService } from 'src/app/@core/services/salary-process.service';
import * as moment from 'moment';

@Component({
  selector: 'app-freez-publish-salary',
  templateUrl: './freez-publish-salary.component.html',
  styleUrls: ['./freez-publish-salary.component.scss']
})
export class FreezPublishSalaryComponent implements OnInit {

  isChecked = true;
  filter  = false;
  viewBreakup = false;
  page = 1;
  pageSize =20;
  items: any[] = [];
  allchecked:boolean[] = [];
  downloadMsg = false;
  alertToggle = false;
  searchString:any = "";
  empalertMsg = "";
  message = "";
  year:any;
  month:any;
  // total:any;
  processText = "Pending";
  status="Unfreeze";
  componentLoader = true;
  selectedList:any = [];
  months:any = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  }
  startDate=""
  endDate=""
  loader =true;
  company:any;
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];
  viewBreakUpDetails:any;
  flag = false;
  buttonText:any = ""
  statusList:any = ['Processed','Unprocessed','Unfreeze','Freeze','Hold'];
  inputdata :any;
  saveComplete:any = false;
  disableclick = false;

  errorMsg            = '';
  challanErrorLog     = false;
  challanSuccessMsg   = '';
  saveData:any;
  successCount:any;


  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  constructor(public route:ActivatedRoute,public sds:SalaryDashboardService,public notificationService:NotificationService,
    public messageService : MessageService, private fb: FormBuilder,public appservice:AppService,
    private SalaryProcessService:SalaryProcessService,public router: Router) {
  }



  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })
    this.sds.getDateRangeSlider(this.company).subscribe((res:any)=>{
      res.body.data.forEach( (value:any, key:any)=>{
        if(value.payroll_month == this.month && value.year == this.year){
          this.defStartEndDate(value);
        }
      })
      // let financilYear = this.appservice.getFinacialYearByMonth(this.months[res.body.configure_dict.paycycle_month],res.body.configure_dict.paycycle_year)
      let paycycledate = new Date(res.body.configure_dict.paycycle_year+'-'+this.months[res.body.configure_dict.paycycle_month]+"-"+'01');
      let selectedDate = new Date(this.year+'-'+this.months[this.month]+"-"+'01' );
      if(moment(selectedDate).format('YYYY-MM-DD') < moment(paycycledate).format('YYYY-MM-DD') && res.body.configure_dict.prior_payroll_status){
        this.disableclick = true;
      }
      else{
        this.disableclick = false;

      }
      this.sds.getProcessList(this.year,this.month,this.status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,this.startDate,this.endDate).subscribe((res:any)=>{
        this.items = res.body.data;
         this.loader = false
       });
    });

  }

  updateList (status:any){

    this.loader       = true
    this.status       = status;
    this.searchString = "";

    this.sds.getProcessList(this.year,this.month,status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,this.startDate,this.endDate).subscribe((res:any)=>{
      this.items = res.body.data;
      this.selectedList = []
      this.loader = false

    });
  }
  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }
  filterIt() {
    return this.items.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }
  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every(item => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  createSelectedLIst(value:any){
    this.selectedList = [];

    if(this.status == 'Freeze'){
      this.items.forEach((item:any, key:any) => {
        if(item.checked == true){
          if(item.status == 'Publish'){
            this.selectedList.push({"id":item.id,
            "status":"Publish",
            "start_date": this.startDate,
            "end_date": this.endDate,
            "salary": item.gross_pay
          });
          this.flag = true;
          }
          else if(item.status == 'Freeze'){
            this.selectedList.push({"id":item.id,
            "status":"Processed",
            "start_date": this.startDate,
            "end_date": this.endDate,
            "salary": item.gross_pay
          });
          }
        }
      });

    }else{
      if(value == 'Freeze'){
        this.items.forEach((item:any, key:any) => {
          if(item.checked == true){
            this.selectedList.push({"id":item.id,
              "status":"Freeze",
              "start_date": this.startDate,
              "end_date": this.endDate,
              "salary": item.gross_pay
            });
          }
        });
      } else {
        this.SalaryProcessService.getProcessData().subscribe((res:any) => {
          if(res.data?.percentage>0 && res.data?.percentage<100){
            this.notificationService.handleErrorNotification("Salary processing cannot be done until the existing salary process is completed.","Salary process");
          } else {
            this.items.forEach((item:any, key:any) => {
              if(item.checked == true){
                this.selectedList.push({"id":item.id,
                  "status":"Hold",
                  "start_date": this.startDate,
                  "end_date": this.endDate,
                  "salary": item.gross_pay
                });
              }
            });
            if(this.selectedList.length<=0 ){
              this.notificationService.handleErrorNotification(`Select Employees before holding salary`,"Select Employees");
              return;
            }
            var list:any = [];
            list = this.selectedList.filter((item:any)=>{

              if(this.statusList.includes(item.status)){
                return item;
              }
            })
            this.selectedList = list;

            this.flag = false;
            if(this.selectedList.length > 0){
              this.message = "Click the Hold button, If you want to Hold the salaries for the selected employees.";
              this.downloadMsg = true;
            }
          }
      })

      }
    }
    if(value != 'Hold'){

      if(this.selectedList.length<=0){
        var val:any;
        val = (value == 'Processed')?'unfreezing':(value == 'Freeze'?'freezing':'holding');
        this.notificationService.handleErrorNotification(`Select Employees before ${val} salary`,"Select Employees");
        return;
      }
      if(this.flag == true){
        this.empalertMsg = "The selected employee(s) payroll has already been Published.";
        this.alertToggle = true;
      }
      var list:any = [];
      list = this.selectedList.filter((item:any)=>{

        if(this.statusList.includes(item.status)){
          return item;
        }
      })
      this.selectedList = list;

      this.flag = false;
      if(this.selectedList.length > 0){
        var status:any='unfreeze';
        var msgTxt = value!='Processed'?value:'Unfreeze';
        this.message = "Click the " +msgTxt+ " button, If you want to " +msgTxt+ " the salaries for the selected employees.";
        this.downloadMsg = true;
      }
    }
  }
  startProgress = false
  publishSalaryData : any = [];
  processSalary(value:any=''){
    if(value=='Hold'){
      this.sds.setProcessData(this.selectedList.length,this.router.url,"Hold")
      this.startProgress = true
        this.startProgressBar()
    }
    this.loader       = true;
    this.saveComplete = true;
    this.searchString = "";

    var dataArray = {
      "year": Number(this.year),
      "month": this.month,
      "employee_list":this.selectedList,
      "is_fnf": false
   };
    this.sds.publishSalary(dataArray).subscribe((res: any) => {

      this.publishSalaryData = res?.body;
      this.sds.getProcessList(this.year,this.month,this.status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,this.startDate,this.endDate).subscribe((res:any)=>{
        this.items = res.body.data;
         this.loader = false
       });
       var val:any;
        var nitificationHead:any = 'Freeze/Unfreeze';

        if(value == 'Freeze'){
          val = 'freezed'
        }
        else if(value == 'Unfreeze'){
          val = 'unfreezed'
        }
        else{
          val               = 'held'
          nitificationHead  = 'Hold';
        }

       if(this.publishSalaryData.error_list?.length > 0 && val != 'held')
          this.errorPopup(this.publishSalaryData);


        if(val != 'held' && this.publishSalaryData.error_list?.length == 0)
          this.notificationService.handleSuccessNotification(`Selected employee's salary has been ${val} successfully.`,"Salary "+nitificationHead);
        this.downloadMsg  = false;
        this.saveComplete = false;
    },
    (error:any)=>{
      this.isProgressStart = false
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    }
    );
  }
  defStartEndDate(value:any){
    let startYear = value.year;
    if(value.end_month=='January' && value.start_month=='December')
     startYear = value.year - 1;
    this.startDate = value.year+"-"+this.months[value.start_month]+"-"+this.pad(value.start_day)
    this.endDate = value.year+"-"+this.months[value.end_month]+"-"+this.pad(value.end_day)
  }
  pad(d:number) {
      return (d < 10) ? '0' + d.toString() : d.toString();
  }



  filteredcount(){
    return this.filterIt().length;
  }

  viewBreakUp(id:any, status:any){
    this.componentLoader = true;
    this.sds.getViewBreakupDetails(id,this.year,this.month,status).subscribe((res:any)=>{
      if(res){
        this.viewBreakUpDetails = res.body;
        // this.total = (this.viewBreakUpDetails.total_earnings[0].contribution_total + this.viewBreakUpDetails.total_earnings[0].gross_benefits + this.viewBreakUpDetails.total_earnings[0].other_benefits) - this.viewBreakUpDetails.total_earnings[0].recurring_deductions;
           this.viewBreakup = true;
           this.componentLoader = false;
      }
    })

  }

  resetFilter(){
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.updateList(this.status);
    this.filter  = false
  }
  submitForm(data:any){
    this.filter  = false
    this.filtercomp.getBUList(this.company);
    this.buList = data.buList;
    this.DepList = data.DepList;
    this.DesignationList = data.DesignationList;
    this.gradeList = data.gradeList;
    this.BranchList = data.BranchList;
    this.updateList(this.status);
  }
  closeFilter(){
    this.filter  = false
  }

  selectAll(){
    this.inputdata = this.appservice.selectAll(this.items)


  }
  countSelected(){
    return this.items.filter(obj => obj.checked === true).length;
  }
  unselectAll(){
    this.inputdata = this.appservice.unselectAll(this.items)
  }
  closeInfo(bool:any){
    this.alertToggle = bool;
  }

  showProgress = false
  progPercentage:any = 0
  isProgressStart = false
  timeLeft:any = ''
  iscompleted = false
  body = ''
  errorLog = false
  successMsg =''
  exportData:any =[]
  startProgressBar(){
    this.sds.setProgress(false);
    this.SalaryProcessService.processPercentage.subscribe(result =>{
      this.showProgress = true
      this.isProgressStart = true
      this.progPercentage = result.event.percentage
      this.body =  result.event.uploaded_count+" out of "+result.event.employee_count+" employee(s) salary held"
      if(result.event.percentage == 100){
        this.timeLeft = ""
        this.isProgressStart = false
        this.iscompleted =true
        this.errorLog = true;
        this.successMsg = this.sds.PROCESSCOUNT +" out of "+this.sds.PROCESSCOUNT+" employee(s) salary held successfully"
      }
      else{
        this.convertMinSecs(result.event.time_left)
      }
    },
    (error:any)=>{
      this.closeProgress();
      this.notificationService.handleErrorNotification('Something went wrong','Error')

      }
    )
  }
  convertMinSecs(min:any){
    let time = min*60;
    var minutes = Math.floor(time / 60);
    var seconds:any = time - minutes * 60;
    seconds = seconds.toFixed(2)
    this.timeLeft = minutes+" min "+seconds+" sec "
  }
  closeProgress(){
    this.sds.setProgress(true);
    this.isProgressStart=false;
    this.startProgress = false;
  }

  errorPopup(data:any){
    this.updateList(this.status);
    this.challanErrorLog   = true;
    this.saveData   = data;
    this.exportData = [];
    this.successCount = this.selectedList?.length-this.saveData?.error_list?.length;
    if(this.saveData?.error_list?.length>0){
      for (let i = 0; i < this.saveData?.error_list?.length; i++) {
        this.exportData.push({'Employee code':this.saveData?.error_list[i]['employee_code'], 'Employee name':this.saveData?.error_list[i]['employee_name'], 'Error Description':this.saveData?.error_list[i]['error']});
      }
      this.challanSuccessMsg = this.successCount+" out of "+this.selectedList?.length+" employee(s) salary processed successfully";
      this.errorMsg   = this.saveData?.error_list?.length+' employee(s) have error data';
    } else
    this.challanSuccessMsg   = this.successCount+" out of "+this.selectedList?.length+" employee(s) salary processed successfully";
  }
}
