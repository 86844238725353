
<div class="container-fluid p-24"   *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>City</h3>
            <p class="text-helper mb-md-0">Add list of cities and map them to their respective states.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end gap-16">

                <div class="" style="width:22.6875rem;">
                    <div class="">
                        <app-searchbar [searchlist]='citiesDropdown' [searchplaceholder]='searchString' (searchEmitter)="filterCity($event)" (keywordsearchEmitter)="searchCity($event)" [isLoading]="isLoading"></app-searchbar>
                    </div>
                    <ul class="drop-down-menu search-result {{(inputSearchClass == true) ? 'active' : ''}}">
                        <p class="note"> No Result Found </p>
                        <p class="text-center text-helper mt-1">No City Found...</p>
                        <p class="note"> Loader </p>
                        <p class="loader text-center">
                            <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
                        </p>
                        <p class="note"> Search Results</p>

                    </ul>

                </div>
                <button class="btn-square btn btn-secondary" (click)="filterpanel = true" placement="bottom-left">
                    <i class="icon-filter-left fs-13"></i>
                </button>
                <!-- <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                        <button ngbDropdownItem  [class]="{'active':listType == 'all'  }" (click)="selectItemList('');listType='all'">All</button>
                        <button ngbDropdownItem  [class]="{'active':listType == 'active' }" (click)="selectItemList(true);listType='active'">Active </button>
                        <button ngbDropdownItem  [class]="{'active':listType == 'inactive'}" (click)="selectItemList(false);listType='inactive'">Deleted</button>
                    </div>
                </div> -->
                <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addCityForm()"><i
                        class="icon-plus"></i>Add  city</button>
            </div>

        </div>
    </div>

    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16rem" *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>

    <div *ngIf="nodata && !loader;else all_data"  class="row" style="height: calc(100vh - 15rem);">
        <!-- <div class="col-12 text-center">
            <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
                style="width: 438px;">
            <p class="text-uppercase text-light-400 fw-700 fs-14">NO CITY ADDED YET</p>
            <a *ngIf="permissions.a" class="text-uppercase link-primary fw-700 fs-14" (click)="addCityForm()">Add NOW</a>
        </div> -->
      <app-nodata-view class="vstack" [noDataImg]="'bg11.png'"  [noDataText]="'NO CITY ADDED YET'" [hasPermission]="permissions?.a" (addData)="addCityForm()"> </app-nodata-view>

    </div>
    <ng-template #all_data><div class="row row-16"  infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    (scrolled)="onScrollDown()">
        <ng-container  *ngIf="loader==false">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 ffff" *ngFor="let city of cities,let i = index">
                <div class="card card-custom1 card-hover">
                    <div class="card-body d-flex p-0 align-items-start">
                      <div class="d-flex   view-section" (click)="viewcity(city.id)">
                        <div class="logo-img {{city.color_code}}"  >{{this.appService.getFirstChar(city.name,1) | uppercase}}</div>
                        <div class="overflow-hidden">
                            <h6 class="mb-0 tips">{{city.name}}</h6>
                            <span class="tips-helper">{{city.name}}</span>
                            <span
                                class="fs-10 text-uppercase fw-500 {{(city.is_active == true) ? 'text-success' : 'text-danger'}}">{{city.is_active == true ? 'Active':'Deleted'}}</span>
                        </div>
                      </div>

                        <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="city.is_active && (permissions.e || permissions.d)">
                          <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                              ngbDropdownToggle>
                              <i class="icon-three-dots-vertical "></i>
                          </button>
                          <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                              <button *ngIf="permissions.e" ngbDropdownItem  (click)="editcity(city)" >
                                  <i   class="icon-pencil-square me-1"></i> Edit
                              </button>
                              <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deletecityfn(city.id)">
                                  <i class="icon-trash  me-1"></i> Delete
                              </button>
                          </div>
                      </div>

                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Loader updations -->
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>

      </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="infinityloader" >
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

  </div>
  <!-- view -->
  <div class="side-panel" style="--sidepanel-width:720px;" [class.side-pane-active]='showSidePanel1 === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg "></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid px-32 py-24">
              <!-- View loader start -->
              <app-view-loader *ngIf="loader1"></app-view-loader>
              <!-- View loader end -->
              <ng-container *ngIf="!loader1">
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0"> City </p>
                    <a class="link-primary fs-14 d-flex align-items-center ms-auto"  (click)="editcity(cityView)" *ngIf="citystatus && (permissions.e)">
                        <i   class="icon-pencil-square me-1"></i> Edit
                    </a>
                </div>
                <div class="row row-16 mb-16">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 py-16">
                            <div class="row row-16 " >
                                <div class="col-12 "><span class="title">CITY DETAILS</span></div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                    <div class="detail-title">City name</div>
                                    <div class="detail-desc">{{cityname}}
                                    </div>
                                </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Status</div>
                                  <div class="detail-desc">
                                    <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="citystatus">ACTIVE</span>
                                    <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="citystatus==false">DELETED</span>
                                  </div>
                              </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Country</div>
                                  <div class="detail-desc">{{country_name}}
                                  </div>
                              </div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                <div class="detail-title">State</div>
                                <div class="detail-desc">{{state_name}}
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>

                </div>
              </ng-container>
            </div>
        </div>
    </form>
  </div>
  <!-- Slider add start-->
  <div class="side-panel" style="--sidepanel-width:720px;" [class.side-pane-active]='showSidePanel === true'>
    <form [formGroup]="cityForm" (ngSubmit)="saveCity()" class="side-panel-container" autocomplete="off">

        <header class="side-panel-head">
            <h5><span [innerHTML]="header"></span> City</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg "></i></a>
        </header>

        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid px-32 py-24">
              <!-- Edit loader start -->
              <app-edit-loader *ngIf="loader1" ></app-edit-loader>
              <!-- Edit loader end -->
              <ng-container *ngIf="!loader1" >
                <!--Alert msg section-->
                <div class="alert alert-success show" role="alert" *ngIf="isValid==true">
                    City {{action}} successfully.
                <button class="btn-close" ></button>
                </div>
                <div class="alert alert-danger show" role="alert" *ngIf="isError==true">
                    You should check in on some of those fields below.
                <button class="btn-close" ></button>
                </div>
                <!--End-->
                <p class="text-uppercase fw-500 mb-12"><span [innerHTML]="header" class="text-uppercase"></span> CITY DETAILS </p>
                <input type="text" value="{{id}}" formControlName="id" id="id" hidden>
                <div class="row row-12 ">
                    <div class="col-6 form-row ">
                        <label for="name" class="form-label required fs-14">City name </label>
                        <input type="text" class="form-control " formControlName="name" id="name" placeholder="{{this.messageService.placeholderdisp('city name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched) &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()" >
                        <div *ngIf="(submitted || f.name.dirty || f.name.touched || f.name.errors?.exist) && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                            <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!f.name.errors?.pattern && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        </div>
                    </div>
                    <div class="col-6 form-row ">
                        <label for="country" class="form-label required fs-14">Country </label>
                        <!-- <select class="form-select" id="country" formControlName="country" (change)="getStateList()"  [ngClass]="{ 'is-invalid': submitted &&  f.country.errors}">
                            <option  disabled selected value="">Select Country</option>
                            <option *ngFor="let cntry of countries" [value]="cntry.id" >{{cntry.name}}</option>
                        </select> -->
                        <ng-select class="form-ngselect"
                        (change)="countrySelectFun()"
                                formControlName="country"
                                [ngClass]="{ 'is-invalid': (submitted || f.country.touched) &&  f.country.errors }"
                                name=""  id="country"
                                placeholder="Select country"  [clearable]="false">
                        <ng-option *ngFor="let cntry of countries"  [value]="cntry.id">{{cntry.name}}</ng-option>
                        </ng-select>
                        <div *ngIf="(submitted || f.country.touched) && f.country.errors" class="invalid-feedback">
                            <div *ngIf="f.country.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        </div>
                    </div>

                    <div class="col-6 form-row ">
                        <label for="state" class="form-label required fs-14">State </label>
                        <!-- <select class="form-select" id="state" formControlName="state" ng-model="selectedState" (change)="getStateList()" [ngClass]="{ 'is-invalid': submitted &&  f.state.errors}">
                            <option disabled selected value="">Select State</option>
                            <option *ngFor="let state of states" [value]="state.id" >{{state.name}}</option>
                        </select> -->
                        <ng-select [readonly]="!CountrySelect" class="form-ngselect"
                                formControlName="state"
                                [ngClass]="{ 'is-invalid': (submitted || f.state.touched) &&  f.state.errors }"
                                name=""  id="state"
                                placeholder="Select state">
                        <ng-option *ngFor="let state of states"  ng-selected="state.id == f.state.value" [value]="state.id">{{state.name}}</ng-option>
                        </ng-select>
                        <div *ngIf="(submitted || f.state.touched) && f.state.errors" class="invalid-feedback">
                            <div *ngIf="f.state.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
            <footer class="side-panel-footer "  *ngIf="permissions && !loader1">
            <button (click)="deleteAlert=true"  *ngIf="deleteClass==true  && permissions.d" class="btn btn-outline-danger text-uppercase btn-sm" [disabled]='disabled' type="button">DELETE</button>
            <button  *ngIf="(permissions.a || permissions.e)" type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"  [disabled]='disabled || !cityForm.valid' ><span [innerHTML]="addEditBtn"></span></button>
        </footer>

    </form>
  </div>
  <!-- slider section end -->

  <div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete City?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('City')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteAlert = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm"  [disabled]="deleteClicked" (click)="deleteCity()">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <app-common-filter [commonFilter]="cityFilter" [filterpanel]="filterpanel" (filterPanel)="panel($event)" (filterDone)="filterDone($event)" (resetFilter)="resetFilter()" ></app-common-filter>
