import { Component, OnInit, Input, Output, Inject, EventEmitter } from '@angular/core';
import { FormBuilder,Validators  } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { MessageService } from 'src/app/message.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-add-new-report',
  templateUrl: './add-new-report.component.html',
  styleUrls: ['./add-new-report.component.scss']
})

export class AddNewReportComponent implements OnInit {

  editReport = false;

  // test start
  @Input()newReport:any;
  @Input()editId:any = 0

  @Output() submitreport = new EventEmitter();
  @Output() closereport  = new EventEmitter();

  submitted              = false;
  fromMinDate            = new Date(new Date().getFullYear() - 5, 11, 31);
  toMinDate              = new Date(new Date().getFullYear() - 5, 11, 31);
  disable                = false;
  modelLabel:any         = 'Add';
  loader                 = false;
  // test end

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private formBuilder: FormBuilder,
    public messageService : MessageService,
    public appservice: AppService,
    private service:ExpenseReportRequestService,
    private notificationService:NotificationService,
  ) { }

  ngOnInit(): void {
    this.getDateFormat();
    if(this.editId!=0)
    this.editReportData()
  }

  // test start
  addReportForm = this.formBuilder.group({
    name              : ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
    business_purpose  : ['', [Validators.maxLength(500)]],
    from_date         : ['', [Validators.required]],
    to_date           : ['', [Validators.required]],
  })

  get f() { return this.addReportForm.controls; }

  getDateFormat() {
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if (this.matDateFormat.display.dateInput == '') {
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  keyUp(){
    // this.subjectKeyUp.next(this.f.name.value)
  }

  disableDate() {
    return false;
  }

  createReport(){
    this.submitted = true;
    if (this.addReportForm.invalid ) {
          return;
    }
    this.disable = true;
    if(this.editId!=0){
        let data = {
          'name':this.addReportForm.value?.name,
          'business_purpose':this.addReportForm.value?.business_purpose,
          'from_date':this.appservice.dateFormatConvert(this.addReportForm.value?.from_date),
          'to_date':this.appservice.dateFormatConvert(this.addReportForm.value?.to_date)}
        this.service.updateExpenseReport(this.editId,data).subscribe((res:any)=>{
        this.notificationService.handleSuccessNotification("Updated successfully.","Success");
        this.submitreport.emit();
        this.addReportForm.reset();
      })
    }
    else{

      this.service.createReport({'name':this.addReportForm.value?.name,'business_purpose':this.addReportForm.value?.business_purpose,'from_date':this.appservice.dateFormatConvert(this.addReportForm.value?.from_date),'to_date':this.appservice.dateFormatConvert(this.addReportForm.value?.to_date)}).subscribe((res: any) => {

        this.submitreport.emit();
        this.addReportForm.reset();

      },(err: any) => {this.disable = false});
           this.submitted        = false;
    }
  }

  closeReport(){
    this.closereport.emit()
  }

  dateChangeFunction() {
    this.addReportForm.get('to_date')?.setValue('');
    this.toMinDate = this.addReportForm.get('from_date')?.value;
  }
  editReportData(){
    this.modelLabel = 'Edit'
    this.loader     = true;
    this.service.getSingleReport(this.editId).subscribe((res:any)=>{

      Object.entries(res).forEach(([key, value]) => {       
          this.addReportForm.get(key)?.setValue(value)       
      })
      this.loader = false;
    })
  }


  // test end

}
