import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
​
@Injectable({
  providedIn: 'root'
})
export class SnapshotService {
  COMPANYLOGO:any;
  COMPANYNAME:any;
  DESIGNATION:any;
  FULLNAME:any;
  COLORCODE:any;
  COMPANYID:any
  PROFILEPIC:any
  EMPCODE:any
  EMPDETAILS : any
  constructor(
    private http: HttpClient,
    public router: Router
  ) {this.COMPANYLOGO = ""; this.COMPANYNAME = ""  }
​
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }
​
  getEmployeeData(id:number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-detail-view/${id}/`, this.httpOption)
  }
  updateEmployeeData(id: number, body:any){
    return this.http.put(`${this.API_PATH}organization/api/v1/employee-detail/${id}/`, body, this.httpOption)
  }
  companyDropDownData(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=${status}`, this.httpOption)
  }
  gardeListData(status:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=${status}`, this.httpOption)
  }
  businessUnitList(filter:string = ''){
    return this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=${filter}`, this.httpOption)
  }
  departmentListData(status:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=${status}`, this.httpOption)
  }
  designationListData(status:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown?is_active=${status}`, this.httpOption)
  }
  branchListData(status:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=${status}`, this.httpOption)
  }
  countryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/country-list/`, this.httpOption)
  }
  employeeList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-list/`, this.httpOption)
  }
  documentsList(status: any,limit:any,offset:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/document_list/?is_active=${status}&limit=${limit}&offset=${offset}`, this.httpOption)
  }
  getEmployeeBasicData(id:number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-basic/${id}/`, this.httpOption)
  }
  updateProfileImg(id: number, body:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/employee-detail/${id}/`, body, this.httpOption)
  }
  getCountryCode(){
    return this.http.get(`${this.API_PATH}organization/api/v1/telephonecode-list/`, this.httpOption)
  }
  getAllDocumentData(id:number){
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-document-list/?employee__id=${id}`, this.httpOption)
  }
  updateEmployeedata(id: number, body:any){
    return this.http.put(`${this.API_PATH}organization/api/v1/employee-update/${id}/`, body, this.httpOption)
  }
  updateTempEmployeedata(body:any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-temporary/`, body, this.httpOption)
  }
  getBuList(compid:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name&company_id=${compid}`, this.httpOption);
  }

  getReason(){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/reason-list/?is_active=True`, this.httpOption)
  }

  getnoticePeriod(id:number){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/seperation-policy-retrieve/${id}/`, this.httpOption)

  }


  setCompanyLogo(logo:any,name:any){
    this.COMPANYLOGO = logo;
    this.COMPANYNAME = name;
  }
  setCompanyID(id:any){
    this.COMPANYID = id;
  }
  getCompanyID(){
    return this.COMPANYID;
  }
  getCompanyLogo(){
    return this.COMPANYLOGO;
  }
  getCompanyname(){
    return this.COMPANYNAME;
  }
  getCurrentUserName(){
    return this.FULLNAME;
  }
  getCurrentUserDesignation(){
    return this.DESIGNATION;
  }
  getCurrentUserColor(){
    return this.COLORCODE;
  }
  getCurrentUserPic(){
    return this.PROFILEPIC;
  }
  setBasicDetails(basicEmpData:any){
    this.EMPDETAILS = basicEmpData
  
      this.DESIGNATION = basicEmpData.designation?basicEmpData.designation:"";
      if(basicEmpData?.first_name != undefined )
        this.FULLNAME = basicEmpData?.first_name+" "+(basicEmpData?.last_name!=null && basicEmpData?.last_name!=''?basicEmpData?.last_name:'');
      else
        this.FULLNAME = ""
      this.COLORCODE = basicEmpData.color_code?basicEmpData.color_code:"";
      this.PROFILEPIC = basicEmpData.profile_image?basicEmpData.profile_image:"";
      this.EMPCODE = basicEmpData.employee_code?basicEmpData.employee_code:"";
  }

  getTeamEmpProfileViewPermissions() {
    return this.http.get(`${this.API_PATH}organization/api/v1/enabled-directory-permissions/`, this.httpOption)
  }

  getAttendaceProcessData(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-check-relieving-date/`, body, this.httpOption)
  }
 
}
