import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SalaryStructureService {
  FILTERDATA:any;
  constructor(
    private http: HttpClient,
    public router: Router
  ) {this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      if( ( !event.url.includes('salary-structure') && !event.url.includes('salary-revision-setup')) ||event.url.includes('my-salary-structure')){
        this.setFilterData(null)
      }
    }
  }) }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  getEmployeeData(id:number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-basic/${id}/`, this.httpOption)
  }
  getSingleSalary(id:number) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/assign-salary-structure-detail/${id}/`, this.httpOption)
  }
  getSingleInboxsalary(id:number) {
    return this.http.get(`${this.API_PATH}inbox/api/v1/assign-salary-structure-detail/${id}/`, this.httpOption)
  }
  updateSalary(id:number,data:any) {
      return this.http.put(`${this.API_PATH}general_settings/api/v1/assign-salary-structure-detail/${id}/`,data, this.httpOption)
    }
  updateSalaryInbox(id:number,data:any) {
    return this.http.put(`${this.API_PATH}inbox/api/v1/assign-salary-structure-detail/${id}/`,data, this.httpOption)
  }

  getPayGroupList() {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-group-dropdown/?is_active=true&ordering=name`, this.httpOption)
  }
  getFormula(body: any,id:any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/pay-component-pay-group-list/${id}/`, body, this.httpOption)

  }
  createSalary(data:any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/assign-salary-structure/`, data, this.httpOption)
  }
  getEmployeeSalary(status:any,limit:any,offset:any,search:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/assign-salary-structure-landing/?is_assigned=${status}&limit=${limit}&is_active=true&offset=${offset}&search=${search}&is_revision=false`,this.httpOption)
  }
  getSearchData(status:any,ordering:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/assign-salary-structure-landing/?is_assigned=${status}&ordering=${ordering}&is_active=true&is_revision=false`,this.httpOption)
  }
  checkValidityDoc(data:any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/statuatory-validation/`, data, this.httpOption)
  }
  getEmployeeDetails(empId:number){
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-employee-data/${empId}`,this.httpOption)
  }
  salaryRevisionCreate(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/salary-revision-create/`, data, this.httpOption)
  }
  getStatutoryInfo(param:any) {
    return this.http.post(`${this.API_PATH}general_settings/api/v1/statuatory-locked-validation/`, param, this.httpOption)
  }
  getempSalarydata(id:number) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/my-assign-salary-structure-detail/${id}/`, this.httpOption)
  }
  getfilterdata(status:any,limit:any,offset:any,search:any,filterparam:any) {
      return this.http.get(`${this.API_PATH}general_settings/api/v1/assign-salary-structure-landing/?is_assigned=${status}&limit=${limit}&is_active=true&offset=${offset}&search=${search}&is_revision=false&${filterparam}`, this.httpOption)
  }
  getSearchfilterData(status:any,filterparam:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/assign-salary-structure-landing/?is_assigned=${status}&${filterparam}&is_active=true&is_revision=false&ordering=employee__user__first_name`,this.httpOption)
  }
  setFilterData(val:any){
    this.FILTERDATA = val
  }
}
