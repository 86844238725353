<ng-container *ngIf="requestitem.level == 1">
    <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Approved' || requestitem.approved_person_list[0]['status'] == 'Confirmed'">
      <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
        <i class="bi fs-16 bi-check-square-fill text-success "></i>
        <span class="">{{requestitem.approved_person_list[0]['status']}} by </span>
      <span class="fw-600 text-trim w-15ch">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id
        ?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
      </div>
    </div>
    <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Rejected' || requestitem.approved_person_list[0]['status'] == 'Terminated'">
      <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
        <i class="bi fs-16  bi-x-square-fill text-danger "></i>
        <span class="">{{requestitem.approved_person_list[0]['status']}} by </span>
      <span class="fw-600 text-trim w-15ch">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id
        ?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
      </div>
    </div>
    <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Pending'">
      <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
        <i class="bi fs-16  bi-x-square-fill text-warning "></i>
        <span class="">Pending with </span>
      <span class="fw-600 text-trim w-15ch">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id ||
        requestitem.approved_person_list[0]['id'] ==
        null?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
      </div>
    </div>
    <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Extended Probation'">
      <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
        <i class=" fs-18 icon-stop-fill text-primary "></i>
        <span class="">Probation Extended by </span>
        <span class="fw-600 text-trim w-15ch">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id || requestitem.approved_person_list[0]['id'] == null?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
      </div>
    </div>
</ng-container>

<ng-container *ngIf="requestitem.level > 1">
    <div class="col-12">
      <div class="card card-c2  px-16 pt-16 pb-8">
      <ul class="progress-vertical">
        <ng-container *ngFor="let item of requestitem.approved_person_list;let i = index">
          <ng-container
            *ngIf="requestitem.approved_person_list[i]['level'] < requestitem.level || requestitem.approved_person_list[i]['level'] == requestitem.level ">
            <li class="{{((requestitem.approved_person_list[i]['status'] == 'Approved') || (requestitem.approved_person_list[i]['status'] == 'Confirmed')) ? 'progress-status-success' :
              (requestitem.approved_person_list[i]['status']=='Rejected' || requestitem.approved_person_list[i]['status']=='Terminated' || requestitem.approved_person_list[i]['status']=='Invalid') ? 'progress-status-danger' :
              requestitem.approved_person_list[i]['status']=='Pending' ? 'progress-status-warning' :
              requestitem.approved_person_list[i]['status']=='Extended Probation' ? 'progress-status-primary' :
              requestitem.approved_person_list[i]['status']=='Intimation' ? 'progress-status-default' :''}}">
              <div>
                {{requestitem.approved_person_list[i]['status'] == 'Approved' ? 'Approved by' :
                requestitem.approved_person_list[i]['status']=='Confirmed' ? 'Confirmed by' :
                requestitem.approved_person_list[i]['status']=='Terminated' ? 'Terminated by' :
                requestitem.approved_person_list[i]['status']=='Extended Probation' ? 'Probation Extended by' :
                requestitem.approved_person_list[i]['status']=='Rejected' ? 'Rejected by' :
                requestitem.approved_person_list[i]['status']=='Invalid' ? 'Invalid' :
                requestitem.approved_person_list[i]['status']=='Pending' ? 'Pending with' :
                requestitem.approved_person_list[i]['status']=='Intimation' ? 'Intimation sent to' :''}}
              </div>
              <div *ngIf="(requestitem.approved_person_list[i]['status'] !='Pending' && requestitem.approved_person_list[i]['status'] !='Invalid')" class="fw-600 text-trim w-15ch mb-0 fs-12" [title]="requestitem.approved_person_list[i]['id'] ==
                requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name">
                {{requestitem.approved_person_list[i]['id'] ==
                requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
              <div *ngIf="requestitem.approved_person_list[i]['status'] =='Pending'" class="fw-600 text-trim w-15ch">
                {{requestitem.approved_person_list[i]['id'] == requestitem.to_person.id ||
                requestitem.approved_person_list[i]['id'] ==
                null?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
            </li>

          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
  <!-- <div class="col-12">
    <div class="card card-c2  px-16 pt-16 pb-8">
        <div class="dropdown_progres">
          <ul class="progress_drp progress_vertical">
            <ng-container *ngFor="let item of requestitem.approved_person_list;let i = index" >
              <ng-container *ngIf="requestitem.approved_person_list[i]['level'] < requestitem.level || requestitem.approved_person_list[i]['level'] == requestitem.level ">
                <li class="progress__item progress__item--accepted"   *ngIf="requestitem.approved_person_list[i]['status'] == 'Approved' || requestitem.approved_person_list[i]['status'] == 'Confirmed'">
                  <div class="progress__tile  ">
                    <div class="fs-12 text-center">
                      <div class="">{{requestitem.approved_person_list[i]['status']}} by </div>
                    <div class="">{{requestitem.approved_person_list[i]['id'] ==
                      requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
                    </div>
                  </div>
                </li>
                <li class="progress__item progress__item--rejected"  *ngIf="requestitem.approved_person_list[i]['status'] == 'Rejected' ||  requestitem.approved_person_list[i]['status'] == 'Terminated'">
                  <div class="progress__tile  " >
                    <div class="fs-12 text-center">
                      <div class="">{{requestitem.approved_person_list[i]['status']}} by </div>
                    <div class="">{{requestitem.approved_person_list[i]['id'] ==
                      requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
                    </div>
                  </div>
                </li>
              <li class="progress__item progress__item--pending"
                *ngIf="requestitem.approved_person_list[i]['status'] == 'Pending'">
                  <div class="progress__tile  ">
                    <div class="fs-12 text-center">
                      <div class="">Pending with </div>
                    <div class="">{{requestitem.approved_person_list[i]['id'] == requestitem.to_person.id ||
                      requestitem.approved_person_list[i]['id'] ==
                      null?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
                    </div>
                  </div>
                </li>
                <li class="progress__item progress__item--multiple"  *ngIf="requestitem.approved_person_list[i]['status'] == 'Extended Probation'">
                  <div class="progress__tile  ">
                    <div class="fs-12 text-center">
                      <div class="">Probation Extended by </div>
                      <div class="">{{requestitem.approved_person_list[i]['id'] == requestitem.to_person.id || requestitem.approved_person_list[i]['id'] == null?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
                    </div>
                  </div>
                </li>
                <li class="progress__item progress__item--intimation" *ngIf="requestitem.approved_person_list[i]['status'] == 'Intimation'" >
                  <div class="progress__tile  " >
                    <div class="fs-12 text-center">
                      <div class="">Intimation sent to </div>
                    <div class="">{{requestitem.approved_person_list[i]['id'] ==
                      requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ng-container>
            </ul>
      </div>
      </div>
  </div> -->
</ng-container>
