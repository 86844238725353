import { Component, OnInit,Input,Output,EventEmitter,ChangeDetectorRef } from '@angular/core';
import { PreviousEmpService } from 'src/app/@core/services/previous-emp.service';
import { FormBuilder,Validators } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';

@Component({
  selector: 'app-previous-employer',
  templateUrl: './previous-employer.component.html',
  styleUrls: ['./previous-employer.component.scss']
})
export class PreviousEmployerComponent implements OnInit {
  showSidePanel           = false;

  loader                  = true;
  submitted               = false;
  header:any              = "";
  loader1                 = false;
  permissions:any         = [];
  showInfo                = false;
  infoMsg:any;
  previousEmpForm         = this.formBuilder.group({
                                id                        : 0,
                                employee                  : 0,
                                income_after_exemption    : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                exempted_gratuity         : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                exempted_encashed_leave   : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                pf_amount                 : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                pt_amount           : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                nps_deduction       : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                income_tax          : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                surcharge           : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                cess                : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                                total_tds           : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
                     
                              });

  @Input() employerData:any ;
  @Input() currency:any ;
  @Input() id:any ;
  @Input() is_employeeRequest:any;
  @Output() employerSubmit = new EventEmitter();
  @Output() loadData       = new EventEmitter();
  @Input() routingUrl:any;
  @Input() loginAs:any;
  @Input() from:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;
  total_tds_amount:number  = 0;

  constructor(private empService:PreviousEmpService,private formBuilder:FormBuilder,public messageService : MessageService,private appservice:AppService,
  public cd : ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.loader = false;
    this.loadData.emit();


  }
  getPermission(){

    this.permissions = this.appservice.getPermissions(this.routingUrl);
     if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }

  }
  getEmployerData(){
    this.empService.getPersonalData(this.id).subscribe((res:any)=>{
      if(res.previous_employer_detail.length>0){

        this.employerData = res.previous_employer_detail[0];

        Object.entries(res.previous_employer_detail[0]).forEach(([key, value]) => {
          if(value == null) {
            this.previousEmpForm.get(key)?.setValue('');
          } else {
            this.previousEmpForm.get(key)?.setValue(value);
          }
        })
        this.totaltdsFunction()
      }
    })
  }
  editEmployerData(event:any){
    if(this.is_employeeRequest?.is_prev_employer_requested ){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo            = false;
      this.header         = event;
      this.showSidePanel  = true;
      this.submitted      = false;
      if(event == 'Edit')
        this.loader1        = true;
      this.getEmployerData();
      this.previousEmpForm.reset();
      if(event == 'Edit')
        this.loader1        = false;
      else
        this.previousEmpForm.get('id')?.setValue(0);

      this.previousEmpForm.get('employee')?.setValue(this.id);
    }

  }
  get f(){
    return this.previousEmpForm.controls;
  }
  onSubmit(){

    if(this.previousEmpForm.invalid){
      for(let i in this.previousEmpForm.controls)
      this.previousEmpForm.controls[i].markAsTouched();
      return;
    } else
    this.submitted = true;

    this.employerSubmit.emit(this.previousEmpForm.value);
    this.showSidePanel  = false;

  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }

  totaltdsFunction(){

    this.total_tds_amount = (Number(this.previousEmpForm.controls.income_tax?.value == "" ? 0 : this.previousEmpForm.controls.income_tax?.value == null ? 0 : this.previousEmpForm.controls.income_tax?.value))+(Number(this.previousEmpForm.controls.surcharge?.value == "" ? 0 : this.previousEmpForm.controls.surcharge?.value == null ? 0 : this.previousEmpForm.controls.surcharge?.value)) +(Number(this.previousEmpForm.controls.cess?.value == "" ? 0 : this.previousEmpForm.controls.cess?.value == null ? 0 : this.previousEmpForm.controls.cess?.value));
    this.previousEmpForm.get('total_tds')?.setValue(this.total_tds_amount);
    this.cd.detectChanges();
    return this.total_tds_amount;
  }
}
