import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { EmployeeReportsService } from 'src/app/@core/services/employee-reports.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Observable } from 'rxjs/internal/Observable';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';


@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  @Input() companyDD: any;
  @Input() gradeDD: any;
  @Input() BranchDD: any;
  @Input() buDD: any;
  @Input() DepDD: any;
  @Input() DesignationDD: any;
  @Input() checkListElements: any;
  @Input() disabled: any;
  @Input() empstatusDD: any;
  @Input() emptypeDD: any;
  @Input() employeeDropdown: any;
  // Above needs to be removed
  @Input() Employeedetails : any;
  @Input() salFilter: any;
  @Input() viewDetail: any;
  @Input() callemp : any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering : any
  @Input() ouFilterForm: any
  @Input() resDataFilter:any;


  get f()  { return this.Employeedetails.controls; }

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();

  constructor(public employeereportService:EmployeeReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService, public cd : ChangeDetectorRef,private leaveService : LeaveReportService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewDetail'] && changes['viewDetail'].currentValue) {
      this.getEmployeeList();
    }
  }

   // Employee List
   getEmployeeList() {
    this.leaveService.getEmpList(true)
      .subscribe((res: any) => {
        if (res?.length > 0) {
          for (let i = 0; i < res?.length; i++) {
            res[i]['name'] = res[i]['first_name'];
            if (res[i]['first_name'] != null)
              res[i]['name'] = res[i]['first_name'] + " ";
            if (res[i]['middle_name'] != null)
              res[i]['name'] += res[i]['middle_name'] + " ";
            if (res[i]['last_name'] != null)
              res[i]['name'] += res[i]['last_name'];
            res[i]['name'] += " (" + res[i]['employee_code'] + ")";
          }
          this.employeeDropdown = res;
          let len = this.employeeDropdown.length;
          for (let i = 0; i < len; i++) {
            this.employeeDropdown[i]['fullname'] = "";
            if (this.employeeDropdown[i]['first_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name'] + " ";
            if (this.employeeDropdown[i]['middle_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name'] + " ";
            if (this.employeeDropdown[i]['last_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
          }
          // this.selectAllForDropdownItems(this.employeeDropdown);
        }
      })
  }

  // Emiting the value of radio to parent
  radioBtnChangeFunctions(e:any,contrlName:any,val:any,type:any,form:any){
    if(e.target.value == 'on' ){
     this.Employeedetails?.get(contrlName)?.setValue(true);
    }
    else{
     this.Employeedetails?.get(contrlName)?.setValue(val);
     this.Employeedetails?.get(type)?.setValue('');
    }
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

    // Select All
    selectAllForDropdownItems(items: any[]) {
      let allSelect = (items: any[]) => {
        items.forEach(element => {
          element['selectedAllGroup'] = 'selectedAllGroup';
        });
      };
      allSelect(items);
    }

    clearcommonForm(item:any,index:any){
      if(item == "employeerm"){
        let daata = this.Employeedetails.value.reporting_manager;
        daata.splice(index, 1);
        this.Employeedetails.patchValue({
          reporting_manager: daata
        });
      }
      else if(item == "employeefm"){
        let daata = this.Employeedetails.value.functional_manager;
        daata.splice(index, 1);
        this.Employeedetails.patchValue({
          functional_manager : daata
        });
      }
    }

    resetFilterFunction(){
      if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.resetFilter.emit(true)
        }else{
          this.resetFilterControllers('reset');
        }
      }
    }


    resetFilterControllers(data:any){
      this.viewDetail = false;
      this.filterPanel.emit(this.viewDetail)
      if(data == 'reset'){
        this.ouFilterForm.get('company')?.reset('');
        this.ouFilterForm.get('department')?.reset('');
        this.ouFilterForm.get('bu')?.reset('');
        this.ouFilterForm.get('designation')?.reset('');
        this.ouFilterForm.get('grade')?.reset('');
        this.ouFilterForm.get('branch')?.reset('');
        this.ouFilterForm.get('employee_list')?.reset('');
        this.ouFilterForm.get('employment_status')?.reset('');
        this.ouFilterForm.get('employee_type')?.reset('');
        this.Employeedetails.get('reporting_manager')?.reset('');
        this.Employeedetails.get('functional_manager')?.reset('');
      }
      this.callMonthlyReport.emit({
        reportName : 'Employee Details'
      })
    }

  applyBtn : boolean = false

  filterFormData(use:any){
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let reporting_manager   = this.stringifyCont(this.Employeedetails.controls.reporting_manager.value)
    let functional_manager  = this.stringifyCont(this.Employeedetails.controls.functional_manager.value)
    if(use == 'filter'){
      return `company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&reporting_manager=${reporting_manager}&functional_manager=${functional_manager}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' :this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value,'reporting_manager':this.Employeedetails.controls.reporting_manager.value,'functional_manager':this.Employeedetails.controls.functional_manager.value})
    }
  }

    /************ Employee Details FILTER *****************/
submitEmployeeDetails(){
  this.submitted      = true;
  if (this.Employeedetails.invalid) {
    return;
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.employeereportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')

    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail           = false;
    this.applyBtn = false
}


// Tags closing
applyTagClose1(){
  this.applyBtn = true
  if(this.closeControl == 'emp_status'){
    this.empstatArr = [];
  }else if(this.closeControl == 'emp_type'){
    this.tempArr = [];
  }
  if(this.closeControl == 'reporting_manager' || this.closeControl == 'functional_manager'){
    this.Employeedetails.get(this.closeControl)?.setValue('');
  }else{
    this.ouFilterForm.get(this.closeControl)?.setValue('')
  }
  this.submitEmployeeDetails();
}


resetPage(){
  this.applyBtn = true
  this.employeereportService.calculateOffset(0)
  this.cd.detectChanges()
}


 getData(){
  const data = this.filterFormData('')
  const emptyKeys = Object.keys(data)
  .filter((key:any) => this.isValueEmpty(data[key]));
  return emptyKeys?.length ==  Object.keys(data)?.length ? "" : data
 }

 isValueEmpty(value:any) {
  return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }
}
