import { Component, OnInit, Input,ViewChild, EventEmitter, Output, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Validators } from '@angular/forms';


@Component({
  selector: 'app-ctc-per-salary',
  templateUrl: './ctc-per-salary.component.html',
  styleUrls: ['./ctc-per-salary.component.scss']
})
export class CtcPerSalaryComponent implements OnInit {

  @Input() companyDD: any;
  @Input() gradeDD: any;
  @Input() BranchDD: any;
  @Input() buDD: any;
  @Input() DepDD: any;
  @Input() checkListElements: any;
  @Input() disabled: any;
  @Input() empstatusDD: any;
  @Input() emptypeDD: any;
  @Input() employeeDropdown: any;
  // Above needs to be cleared
  @Input() ctcSalaryStructureForm: any;
  @Input() payGroupDD: any;
  @Input() salFilter: any;
  @Input() viewDetail: any;
  @Input() callMonthlyTDS: any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() pageNumber : any
  @Input() lastPage : any
  @Input() ordering : any
  @Input() ouFilterForm: any
  @Input() DesignationDD : any
  @Input() resDataFilter:any;
  @Input() Years : any


  get f()  { return this.ctcSalaryStructureForm.controls; }

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();

  year_placeholder  = 'Select year'
  month_placeholder = 'Select month'
  dojValue : any
  dolValue:any
  selectedYear = new Date().getFullYear();
  attendListOfMonth:string  = "listOfMonth";

  constructor(
    public appservice:AppService,
    public messageService: MessageService,
    private reportService:ReportsService,
    public cd :  ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.setRequired('date_of_joining');
    this.setRequired('date_of_leaving');
    this.getPayGroupList()
  }

  getPayGroupList() {
    this.reportService.getPayGroupList().subscribe((res:any)=>{
      this.payGroupDD = res;
      this.selectAllForDropdownItems(this.payGroupDD);
    });
  }

  applyBtn : boolean = false

  filterFormData(use:any){
    this.ctcSalaryStructureForm.controls.date_of_joining.value == true ? this.ctcSalaryStructureForm.get('date_of_joining')?.setValue(this.ctcSalaryStructureForm.controls.doj_specific_year.value+'-'+this.dojValue) : this.ctcSalaryStructureForm.get('date_of_joining')?.setValue(this.ctcSalaryStructureForm.controls.date_of_joining.value);
    this.ctcSalaryStructureForm.controls.date_of_leaving.value == true ? this.ctcSalaryStructureForm.get('date_of_leaving')?.setValue(this.ctcSalaryStructureForm.controls.dol_specific_year.value+'-'+this.dolValue) : this.ctcSalaryStructureForm.get('date_of_leaving')?.setValue(this.ctcSalaryStructureForm.controls.date_of_leaving.value);
    let date_of_joining = this.ctcSalaryStructureForm.controls.date_of_joining.value == null?'': this.ctcSalaryStructureForm.controls.date_of_joining.value;
    let date_of_leaving    = this.ctcSalaryStructureForm.controls.date_of_leaving.value == null?'': this.ctcSalaryStructureForm.controls.date_of_leaving.value;
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let pay_group = this.ctcSalaryStructureForm.controls.pay_group.value==''?JSON.stringify([]):JSON.stringify(this.ctcSalaryStructureForm.controls.pay_group.value);

    if(use == 'filter'){
      return `pay_group=${pay_group}&date_of_joining=${date_of_joining}&ctc_breakup=${this.ctcSalaryStructureForm.controls.ctc_breakup.value}&date_of_leaving=${date_of_leaving}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'date_of_joining' : this.ctcSalaryStructureForm.controls.date_of_joining.value,'pay_group' : this.ctcSalaryStructureForm.controls.pay_group.value,'ctc_breakup' : this.ctcSalaryStructureForm.controls.ctc_breakup.value, 'date_of_leaving' : this.ctcSalaryStructureForm.controls.date_of_leaving.value,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

  submitCtc(){
    this.submitted      = true;
    let offset = 0

    if (this.ctcSalaryStructureForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')

    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    })
    this.viewDetail           = false;
    this.applyBtn = false
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthType:any,yearType:any){
    if(e.target.value == 'on' ){
      this.ctcSalaryStructureForm?.get(monthType)?.setValue(null);
      this.ctcSalaryStructureForm?.get(yearType)?.setValue(null);
      this.ctcSalaryStructureForm?.get(contrlName)?.setValue(true);
      this.setRequired(contrlName)
    }
    else{
    this.ctcSalaryStructureForm?.get(contrlName)?.setValue(val);
    this.setRequired(contrlName)
    }
    this.cd.detectChanges()
  }

  resetFilterFunction(){
  if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
    if(!!Object.keys(this.resDataFilter)?.length == true){
      this.resetFilter.emit(true)
    }else{
      this.resetFilterControllers('reset');
    }
  }
  }

  resetFilterControllers(data:any){
  this.viewDetail = false;
  this.filterPanel.emit(this.viewDetail)
  if(data == 'reset'){
    this.ctcSalaryStructureForm.get('pay_group')?.reset('');
    this.ctcSalaryStructureForm.get('date_of_joining')?.reset('');
    this.ctcSalaryStructureForm.get('ctc_breakup')?.reset('monthly');
    this.ctcSalaryStructureForm.get('date_of_leaving')?.reset('');
    this.ouFilterForm.get('company')?.reset('');
    this.ouFilterForm.get('department')?.reset('');
    this.ouFilterForm.get('bu')?.reset('');
    this.ouFilterForm.get('designation')?.reset('');
    this.ouFilterForm.get('grade')?.reset('');
    this.ouFilterForm.get('branch')?.reset('');
    this.ouFilterForm.get('employee_list')?.reset('');
    this.ouFilterForm.get('employment_status')?.reset('');
    this.ouFilterForm.get('employee_type')?.reset('');
    this.ctcSalaryStructureForm.get('doj_specific_year')?.reset(null);
    this.ctcSalaryStructureForm.get('doj_specific_month')?.reset(null);
    this.ctcSalaryStructureForm.get('dol_specific_year')?.reset(null);
    this.ctcSalaryStructureForm.get('dol_specific_month')?.reset(null);
  }
  // change this
  this.callMonthlyReport.emit({
    reportName : 'CTC As Per Salary Structure'
  })
  }

  dojFilter(d:any){
  let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date()]
  return (d >= dateRange[0] && d<= dateRange[1])
  }

 // Tags closing
 applyTagClose1(){
  this.applyBtn = true
  if(this.closeControl == 'emp_status'){
    this.empstatArr = [];
  }else if(this.closeControl == 'emp_type'){
    this.tempArr = [];
  }

  if(this.closeControl == 'date_of_joining' || this.closeControl == 'date_of_leaving'){
    this.ctcSalaryStructureForm.get(this.closeControl)?.setValue('');
  }else{
    this.ouFilterForm.get(this.closeControl)?.setValue('');
  }
  this.submitCtc();
}


resetPage(){
  this.applyBtn = true
  this.reportService.calculateOffset(0)
  this.cd.detectChanges()
}

 getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => (key !== 'ctc_breakup' && key !== 'pay_group')  && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-2 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }
  constructDate(from:any){

    let event = this.ctcSalaryStructureForm.get(from).value
    var date      = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
    if(from=='doj_specific_month'){
      this.dojValue = String(date.getMonth() + 1).padStart(2, '0');
    }
    else if(from=='dol_specific_month'){
      this.dolValue = String(date.getMonth() + 1).padStart(2, '0');

    }

  }
  setRequired(type:any){
    if(type=='date_of_joining')
    var arr = ['doj_specific_month','doj_specific_year']
    else
    var arr = ['dol_specific_month','dol_specific_year']
    let val = this.ctcSalaryStructureForm.get(type).value;

    if(val == true){
      arr.forEach((item:any)=>{
        this.ctcSalaryStructureForm.get(item)?.setValidators([Validators.required]);
        this.ctcSalaryStructureForm.get(item)?.markAsTouched();
        this.ctcSalaryStructureForm.get(item)?.updateValueAndValidity();
      })
    }else{
      arr.forEach((item:any)=>{
        this.ctcSalaryStructureForm.get(item)?.clearValidators()
        this.ctcSalaryStructureForm.get(item)?.updateValueAndValidity();
      })
    }
  }

  // Close the selected items on filter panel
  clearcommonForm(item:any,index:any){
    if(item == "pay_group"){
        let daata = this.ctcSalaryStructureForm.value.pay_group;
        daata.splice(index, 1);
        this.ctcSalaryStructureForm.patchValue({
          pay_group: daata
        });
      }
    }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
