<form class="" [formGroup]="leaveConfigForm" autocomplete="off">
    <ng-container formArrayName="leaveform" *ngFor="let config of leaveForm.controls; let i = index">
        <ng-container [formGroupName]="i">
            <ng-container *ngIf="leaveTypeCode==leaveForm.controls[i].get('leave_code')?.value">

                <div class="container-fluid  py-24 px-16">

                    <div class="row ">
                        <p class="text-uppercase fw-500">LEAVE type DETAILS</p>
                    </div>
                    <div class="row row-16">
                        <div class=" col-md-6 form-row">
                            <label for="name" class="form-label required">Leave Type Code </label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                ngbPopover="Enter the Code for the leave type"
                                popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                            <input type="text" formControlName="leave_code" class="form-control " placeholder="" [attr.disabled]="true">

                        </div>
                        <div class=" col-md-6 form-row">
                            <label for="name" class="form-label required">Leave Type Name </label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                ngbPopover="Enter the Leave type name"
                                popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                            <input type="text" formControlName="leave_name" class="form-control " placeholder=""  [attr.disabled]="true">

                        </div>

                        <!-- <div class="col-lg-12 form-row ">
                            <div class="form-row">
                                <label for="leave_cycle" class="form-label required ">This Leave is </label>
                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                  ngbPopover="Select the leave is a paid or a un-paid leave" popoverClass="popover-default popover-info" placement="bottom"
                                  triggers="hover"></i></span>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="leave_unpaid_paid"
                                    [value]=true>
                                <label class="form-check-label">
                                    Paid Leave
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="leave_unpaid_paid"
                                    [value]=false>
                                <label class="form-check-label">
                                    Unpaid Leave
                                </label>
                            </div>
                        </div> -->
                        <div class="col-lg-12 form-row ">
                            <div class="form-row">
                                <label for="negative_balance_allowed" class="form-label  ">Is Negative balance
                                    allowed?</label>
                                    <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                      ngbPopover="Negative leave balance is an option where employee can avail leaves even if there is no leave balance. If negative balance is allowed for this leave type , then select the option “yes”" popoverClass="popover-default popover-info" placement="bottom"
                                      triggers="hover"></i></span>

                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="negative_balance_allowed"
                                    [value]=true (ngModelChange)="addConditionalValidator()">
                                <label class="form-check-label">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="negative_balance_allowed"
                                    [value]=false (ngModelChange)="addConditionalValidator()">
                                <label class="form-check-label">
                                    No
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="leaveForm.controls[i].get('negative_balance_allowed')?.value">
                            <div class=" col-12 form-row mt-8">
                                <input type="text" class="form-control " formControlName="negative_balance_days"
                                    placeholder="Enter days"
                                    [ngClass]="{ 'is-invalid': ( leaveForm.controls[i].get('negative_balance_days')?.touched || leaveForm.controls[i].get('negative_balance_days')?.dirty) &&  leaveForm.controls[i].get('negative_balance_days')?.errors}">
                                <div *ngIf="(leaveForm.controls[i].get('negative_balance_days')?.touched || leaveForm.controls[i].get('negative_balance_days')?.dirty) && leaveForm.controls[i].get('negative_balance_days')?.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leaveForm.controls[i].get('negative_balance_days')?.errors?.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="leaveForm.controls[i].get('negative_balance_days')?.hasError('min') && !(leaveForm.controls[i].get('negative_balance_days')?.errors?.pattern)">
                                      {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
                                        </div>
                                    <div *ngIf="leaveForm.controls[i].get('negative_balance_days')?.errors?.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-lg-12 form-row ">
                            <div class="form-row">
                                <label for=" " class="form-label">Is Half day leave allowed? </label>
                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                  ngbPopover="Enable this option to allow half day leave for this leave type."
                                  popoverClass="popover-default popover-info" popoverClass="popover-default popover-info" placement="bottom"
                                  triggers="hover"></i></span>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="halfday_leave_allow"
                                    [value]=true>
                                <label class="form-check-label">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="halfday_leave_allow"
                                    [value]=false>
                                <label class="form-check-label">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-12 form-row ">
                            <div class="form-row">
                                <label for=" " class="form-label ">Is leave cancellation allowed? </label>
                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option to allow employees to cancel leave requests after approval." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>

                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="leave_cancellation_allow"
                                    [value]=true>
                                <label class="form-check-label">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="leave_cancellation_allow"
                                    [value]=false>
                                <label class="form-check-label">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-12 form-row ">
                            <div class="form-row">
                                <label for=" " class="form-label "> Is this leave encashable?</label>
                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option if this leave type can be encashed." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>

                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="leave_encashable"
                                    [value]=true (ngModelChange)="encashableValidator(i,'leave_encashable')">
                                <label class="form-check-label">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="leave_encashable"
                                    [value]=false (ngModelChange)="encashableValidator(i,'leave_encashable')">
                                <label class="form-check-label">
                                    No
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="leaveForm.controls[i].get('leave_encashable')?.value">


                          <div class="col-lg-12 form-row ">
                              <div class="form-row">
                                  <label for=" " class="form-label  ">Maximum encashment limit </label>
                                  <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Specify the limit for leave encashment days." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                              </div>
                              <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" formControlName="max_encash_limit_status"
                                      [value]=false (ngModelChange)="addConditionalValidator()">
                                  <label class="form-check-label">
                                      All
                                      <span ><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if there is no limit on the amount of leave that can be encashed." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                  </label>
                              </div>
                              <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" formControlName="max_encash_limit_status"
                                      [value]=true (ngModelChange)="addConditionalValidator()">
                                  <label class="form-check-label">
                                      Set limit
                                      <span ><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover=" Select this option to specify a maximum limit for leave encashment. For example, if the limit is 30 days and an employee has 40 days of unused leave, only 30 days will be encashed." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                  </label>
                              </div>
                          </div>
                          <ng-container *ngIf="leaveForm.controls[i].get('max_encash_limit_status')?.value">
                              <div class=" col-12 form-row mt-8">
                                  <input type="text" class="form-control " formControlName="max_encash_limit"
                                      placeholder="Enter value"
                                      [ngClass]="{ 'is-invalid': ( leaveForm.controls[i].get('max_encash_limit')?.touched || leaveForm.controls[i].get('max_encash_limit')?.dirty) &&  leaveForm.controls[i].get('max_encash_limit')?.errors}">
                                  <div *ngIf="(leaveForm.controls[i].get('max_encash_limit')?.touched || leaveForm.controls[i].get('max_encash_limit')?.dirty) && leaveForm.controls[i].get('max_encash_limit')?.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="leaveForm.controls[i].get('max_encash_limit')?.errors?.required">
                                          {{this.messageService.validationDisplay('required')}}
                                      </div>
                                      <div *ngIf="leaveForm.controls[i].get('max_encash_limit')?.hasError('min') && !(leaveForm.controls[i].get('max_encash_limit')?.errors?.pattern)">
                                        <!-- {{this.messageService.fieldlengthvalidation('minvalue',0.1)}} -->
                                        {{this.messageService.validationDisplay('pattern')}}
                                          </div>
                                      <div *ngIf="leaveForm.controls[i].get('max_encash_limit')?.errors?.pattern
                    ">
                                          {{this.messageService.validationDisplay('pattern')}}
                                      </div>

                                  </div>
                              </div>
                          </ng-container>
                          <div class="col-lg-12 form-row ">
                              <div class="form-row">
                                  <label for=" " class="form-label ">Is leave encashment allowed during leave year end
                                      process?   <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option to allow leave encashment during the leave year end process. " popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                    </label>
                              </div>
                              <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio"
                                      formControlName="leave_encash_allow_leaveendyear" [value]=true>
                                  <label class="form-check-label">
                                      Yes
                                  </label>
                              </div>
                              <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" [value]=false
                                      formControlName="leave_encash_allow_leaveendyear">
                                  <label class="form-check-label">
                                      No
                                  </label>
                              </div>
                          </div>
                          <ng-container *ngIf="leaveForm.controls[i].get('leave_encash_allow_leaveendyear')?.value">
                              <div class="col-12">
                                  <div class="form-row">
                                      <label for=" " class="form-label ">How leave encashment works during the year end
                                          process?<span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Choose the appropriate option whether to encash the leave and carry foward the remaining to the next leave cycle or vise versa." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>

                                        </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="radio" [value]=false
                                          formControlName="how_encash_work_leaveendyear">
                                      <label class="form-check-label">
                                          Encash First and Carry Forward Remaining
                                      </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="radio" [value]=true
                                          formControlName="how_encash_work_leaveendyear">
                                      <label class="form-check-label">
                                          Carry Forward First and Encash Remaining
                                      </label>
                                  </div>
                              </div>
                          </ng-container>
                        </ng-container>

                        <div class="col-lg-12 form-row ">
                            <div class="form-row">
                                <label for=" " class="form-label ">Do you want to consider the week off as leave if it
                                    is adjoining a leave? <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option if you want to include the week off as leave when it adjoins another leave." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>

                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="do_you_want_weekoffleave" id="weekoff_leave_needed_yes"
                                    [value]=true (ngModelChange)="addConditionalValidator()">
                                <label class="form-check-label" for="weekoff_leave_needed_yes">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" formControlName="do_you_want_weekoffleave" id="weekoff_leave_needed_no"
                                  [value]=false (ngModelChange)="addConditionalValidator()">
                              <label class="form-check-label" for="weekoff_leave_needed_no">
                                  No
                              </label>
                          </div>
                        </div>
                        <ng-container *ngIf="leaveForm.controls[i].get('do_you_want_weekoffleave')?.value">

                            <div class="col-12">
                                <div class="row row-16 ms-16">


                                    <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="weekoffleave_choice"
                                                [value]="'If week off is between two leaves (Week end is sandwiched between leaves)'" id="week_bet">

                                            <label class="form-check-label" for="week_bet">
                                              If week off is between two leaves (Week end is sandwiched between leaves)
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want weekends to be considered as leave. If an employee takes continuous leave including weekends, the weekends will also be treated as leave, and leave balance will be deducted for the weekend also. " popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="weekoffleave_choice"
                                                [value]="'If leave is right before week off'" id="week_right_before">
                                            <label class="form-check-label" for="week_right_before">
                                              If leave is right before week off
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want to consider the weekend as leave when leave immediately falls before a weekend. For example, if an employee applies for leave on Friday (with Saturday and Sunday as the weekend), then a total of 3 days will be deducted from the leave balance. " popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="weekoffleave_choice"
                                                [value]="'If leave is right after week off'" id="week_right_after">
                                            <label class="form-check-label" for="week_right_after">
                                              If leave is right after week off
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want to consider the weekend as leave, when the leave falls immediately after a weekend. For example, if an employee applies for leave on monday (with Saturday and Sunday as the weekend), then a total of 3 days will be deducted from the leave balance." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>

                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="weekoffleave_choice"
                                                [value]="'If leave is between week offs'" id="week_between">
                                            <label class="form-check-label" for="week_between">
                                              If leave is between week offs
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want weekends to be considered as leave when leave falls between the weekends. For example, if an employee applies for leave from Monday to Friday (with Saturday and Sunday as the weekend), then a total of 9 days will be deducted from the leave balance." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>

                                            </label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="weekoffleave_choice"
                                                [value]="'If leave is before or after or in between week off'" id="week_before_after">
                                            <label class="form-check-label" for="week_before_after">
                                              If leave is before or after or in between week off
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option to consider weekend as leave if leave falls before, after, or in between weekends. " popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                            </label>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-12 form-row ">
                                      <div class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio" id="all_weekoff_set"
                                              formControlName="weekoffleave_choice"
                                              [value]="'All of the above'">
                                          <label class="form-check-label" for="all_weekoff_set">
                                            All of the above
                                            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If All of the above is selected, then either Week off is between two leaves or leave is right before Week off or leave is right after Week off or leave is between Week offs, Week off is considered as Leave." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                          </label>
                                      </div>
                                  </div>
                                  <div class="col-lg-12 form-row ">
                                    <div class="form-row">
                                        <label for=" " class="form-label ">If above setting is applicable when week off is adjoining a half day leave?
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If this option is enabled, then above mentioned 5 radio options for full day leave will work in the same way for half day leave when a week off adjoins it." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" formControlName="adjoin_halfday_weekoff" id="yes_adjoin_week"
                                            [value]=true>
                                        <label class="form-check-label" for="yes_adjoin_week">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="radio" formControlName="adjoin_halfday_weekoff" id="no_adjoin_week"
                                          [value]=false>
                                      <label class="form-check-label" for="no_adjoin_week">
                                          No
                                      </label>
                                  </div>
                                </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-lg-12 form-row ">
                            <div class="form-row">
                                <label for=" " class="form-label ">Do you want to consider the holiday as leave if it is
                                    adjoining a leave?  <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option if you want to include holidays as leave when it adjoins another leave." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span></label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="do_you_want_holidayleave" id="holiday_leave_want_yes"
                                    [value]=true (ngModelChange)="addConditionalValidator()">
                                <label class="form-check-label" for="holiday_leave_want_yes">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" [value]=false id="holiday_leave_want_no"
                                    formControlName="do_you_want_holidayleave"
                                    (ngModelChange)="addConditionalValidator()">
                                <label class="form-check-label" for="holiday_leave_want_no">
                                    No
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="leaveForm.controls[i].get('do_you_want_holidayleave')?.value">
                            <div class="col-12">
                                <div class="row row-16 ms-16">
                                    <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="holidayleave_choice"
                                                [value]="'If holiday is between two leaves (Holiday is sandwiched between leaves)'" id="holiday_bet_two">
                                            <label class="form-check-label" for="holiday_bet_two">
                                              If holiday is between two leaves (Holiday is sandwiched between leaves)
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want holiday to be considered as leave. If an employee takes continuous leave including holidays, the holidays will also be treated as leave, and leave balance will be deducted for the holiday also." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span></label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="holidayleave_choice"
                                                [value]="'If leave is right before holiday'" id="holiday_right_before">
                                            <label class="form-check-label" for="holiday_right_before">
                                              If leave is right before holiday
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want to consider the holiday as leave when the leave immediately falls before a holiday.  For example, If employee applies for leave on Tuesday (Wednesday is holiday) then total 2 days are deducted from the leave balance" popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span></label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="holidayleave_choice"
                                                [value]="'If leave is right after holiday'" id="holiday_right_after">
                                            <label class="form-check-label" for="holiday_right_after">
                                              If leave is right after holiday
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want to consider the holiday as leave, when the leave falls immediately after a holiday. For example, If employee applies for leave on Thursday (Wednesday is holiday) then total 2 days are deducted from the leave balance." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span></label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="holidayleave_choice"
                                                [value]="'If leave is between holidays'" id="holiday_between">
                                            <label class="form-check-label" for="holiday_between">
                                                If leave is between holidays
                                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want holiday to be considered as leave when leave falls between the holidays. For example. If employee applies for leaves on Thursday (Wednesday and Friday are holidays) then total 3 days are deducted from the leave balance." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span></label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-12 form-row ">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName="holidayleave_choice"
                                                [value]="'If leave is before or after or in between holiday'" id="holiday_before_or_after">
                                            <label class="form-check-label" for="holiday_before_or_after">
                                                If leave is before or after or in between holiday
                                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option to consider holiday as leave if leave falls before, after, or in between holidays.  " popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span></label>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-12 form-row ">
                                      <div class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio"
                                              formControlName="holidayleave_choice"
                                              [value]="'All of the above'" id="all_holiday">
                                          <label class="form-check-label" for="all_holiday">
                                              All of the above
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If All of the above is  selected, then either holiday is between two leaves or leave is right before holiday or leave is right after holiday or leave is between holiday, holiday is considered as leave." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                            </label>
                                      </div>
                                  </div>
                                  <div class="col-lg-12 form-row ">
                                    <div class="form-row">
                                        <label for=" " class="form-label ">If above setting is applicable if holiday is adjoining a half day leave?
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If this option is enabled, then above mentioned 5 radio options for full day leave will work in the same way for half day leave when holiday adjoins it." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" formControlName="adjoin_halfday_leave" id="yes_holiday_adjoin"
                                            [value]=true>
                                        <label class="form-check-label" for="yes_holiday_adjoin">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="radio" formControlName="adjoin_halfday_leave" id="no_holiday_adjoin"
                                          [value]=false>
                                      <label class="form-check-label" for="no_holiday_adjoin">
                                          No
                                      </label>
                                  </div>
                                </div>
                                </div>
                            </div>


                        </ng-container>


                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</form>
