<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="Snapshot" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
                <!-- New end -->
                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500  mb-0">Birthday</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="birthday" type="radio" id="today" class="form-check-input"
                                formControlName="birthday" value="today"
                                (change)="radioBtnChangeFunctions($event,'birthday','today','birthday_specific_date');">
                            <label for="today" class="form-check-label">Today</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="birthday" type="radio" id="tomorrow" class="form-check-input"
                                formControlName="birthday" value="tomorrow"
                                (change)="radioBtnChangeFunctions($event,'birthday','tomorrow','birthday_specific_date');">
                            <label for="tomorrow" class="form-check-label">Tomorrow</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="birthday" type="radio" id="this_week" class="form-check-input"
                                formControlName="birthday" value="this_week"
                                (change)="radioBtnChangeFunctions($event,'birthday','this_week','birthday_specific_date');">
                            <label for="this_week" class="form-check-label">This week</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="birthday" type="radio" id="this_month" class="form-check-input"
                                formControlName="birthday" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'birthday','this_month','birthday_specific_date');">
                            <label for="this_month" class="form-check-label">This month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="birthday" type="radio" id="next_month" class="form-check-input"
                                formControlName="birthday" value="next_month"
                                (change)="radioBtnChangeFunctions($event,'birthday','next_month','birthday_specific_date');">
                            <label for="next_month" class="form-check-label">Next month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="birthday" type="radio" id="specific" class="form-check-input "
                                formControlName="birthday" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'birthday',true,'birthday_specific_date');">
                            <label for="specific" class="form-check-label">Specific period</label>
                        </div>
                    </div>
                    <ng-container *ngIf="Snapshot.value.birthday==true">
                        <!-- <div class="input-group form-icon icon-right"
                    >
                            <input (click)="datePicker1.open()" class="form-control" placeholder="From date" matInput
                                formControlName="from_date_birth" [matDatepicker]="datePicker1"
                                [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                            <mat-datepicker  touchUi="false" #datePicker1>

                            </mat-datepicker>
                        </div>

                        <div class="input-group form-icon icon-right">
                                <input (click)="datePicker2.open()" class="form-control" placeholder="To date" matInput
                                    formControlName="to_date_birth" [matDatepicker]="datePicker2"
                                    [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                <mat-datepicker  touchUi="false" #datePicker2>
                                </mat-datepicker>
                        </div> -->

                        <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.from_date_birth?.touched || f?.from_date_birth?.dirty) &&  f?.from_date_birth?.errors}">
                        <input (click)="datePicker1.open()" class="form-control" placeholder="From date" matInput
                            formControlName="from_date_birth" [matDatepicker]="datePicker1" [ngClass]="{'is-invalid':(submitted || f?.from_date_birth?.touched || f?.from_date_birth?.dirty) &&  f?.from_date_birth?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                        <mat-datepicker  touchUi="false" #datePicker1>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.from_date_birth?.touched || f?.from_date_birth?.dirty) &&  f?.from_date_birth?.errors" class="invalid-feedback">
                      <div *ngIf="f?.from_date_birth?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                  <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.to_date_birth?.touched || f?.to_date_birth?.dirty) &&  f?.to_date_birth?.errors}">
                        <input (click)="datePicker2.open()" class="form-control" placeholder="To date" matInput
                            formControlName="to_date_birth" [matDatepicker]="datePicker2" [ngClass]="{'is-invalid':(submitted || f?.to_date_birth?.touched || f?.to_date_birth?.dirty) &&  f?.to_date_birth?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                        <mat-datepicker  touchUi="false" #datePicker2>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.to_date_birth?.touched || f?.to_date_birth?.dirty) &&  f?.to_date_birth?.errors" class="invalid-feedback">
                      <div *ngIf="f?.to_date_birth?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                    </ng-container>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500  mb-0">Created date</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="created_date" type="radio" id="today" class="form-check-input"
                                formControlName="created_date" value="today"
                                (change)="radioBtnChangeFunctions($event,'created_date','today','cd_specific_date')">
                            <label for="today" class="form-check-label">Today</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="created_date" type="radio" id="yesterday" class="form-check-input "
                                formControlName="created_date" value="yesterday"
                                (change)="radioBtnChangeFunctions($event,'created_date','yesterday','cd_specific_date')">
                            <label for="yesterday" class="form-check-label">Yesterday</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="created_date" type="radio" id="cd_specific_date" class="form-check-input "
                                formControlName="created_date" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'created_date',true,'cd_specific_date')">
                            <label for="cd_specific_date" class="form-check-label">Specific date</label>
                        </div>
                    </div>
                    <div class="col-12 form-row" *ngIf="Snapshot.value.created_date==true">
                        <!-- <ng-container>
                            <div class="input-group form-icon icon-right">
                                <input (click)="datePicker1.open()" class="form-control"
                                    placeholder="{{this.messageService.selectplaceholddisplay('date')}}" matInput
                                    formControlName="cd_specific_date" [matDatepicker]="datePicker1"
                                    [matDatepickerFilter]="salFilter">
                                <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                            </div>
                        </ng-container> -->
                        <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.cd_specific_date?.touched || f?.cd_specific_date?.dirty) &&  f?.cd_specific_date?.errors}">
                        <input (click)="datePickercreated.open()" class="form-control" placeholder="Select date" matInput
                            formControlName="cd_specific_date" [matDatepicker]="datePickercreated" [ngClass]="{'is-invalid':(submitted || f?.cd_specific_date?.touched || f?.cd_specific_date?.dirty) &&  f?.cd_specific_date?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePickercreated.open()"></i>
                        <mat-datepicker  touchUi="false" #datePickercreated>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.cd_specific_date?.touched || f?.cd_specific_date?.dirty) &&  f?.cd_specific_date?.errors" class="invalid-feedback">
                      <div *ngIf="f?.cd_specific_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Bank status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="bank_status" type="radio" id="bCompleted" class="form-check-input"
                                formControlName="bank_status" value="True">
                            <label for="bCompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="bank_status" type="radio" id="bnotcompleted" class="form-check-input "
                                formControlName="bank_status" value="False">
                            <label for="bnotcompleted" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Personal status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="personal_status" type="radio" id="pCompleted" class="form-check-input  "
                                formControlName="personal_status" value="True">
                            <label for="pCompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="personal_status" type="radio" id="pnot_completed" class="form-check-input "
                                formControlName="personal_status" value="False">
                            <label for="pnot_completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Document status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="document_status" type="radio" id="dCompleted" class="form-check-input  "
                                formControlName="document_status" value="True">
                            <label for="dCompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="document_status" type="radio" id="dnot_completed" class="form-check-input "
                                formControlName="document_status" value="False">
                            <label for="dnot_completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Education status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="education_status" type="radio" id="eCompleted" class="form-check-input  "
                                formControlName="education_status" value="True">
                            <label for="eCompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="education_status" type="radio" id="enot_completed" class="form-check-input "
                                formControlName="education_status" value="False">
                            <label for="enot_completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Family status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="family_status" type="radio" id="fCompleted" class="form-check-input  "
                                formControlName="family_status" value="True">
                            <label for="fCompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="family_status" type="radio" id="fnot_completed" class="form-check-input "
                                formControlName="family_status" value="False">
                            <label for="fnot_completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Health insurance status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="health_insurance_status" type="radio" id="hiCompleted"
                                class="form-check-input  " formControlName="health_insurance_status" value="True">
                            <label for="hiCompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="health_insurance_status" type="radio" id="hinot_completed"
                                class="form-check-input " formControlName="health_insurance_status" value="False">
                            <label for="hinot_completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Previous employer details status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="previous_employer_detail_status" type="radio" id="pedCompleted"
                                class="form-check-input  " formControlName="previous_employer_detail_status"
                                value="True">
                            <label for="pedCompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="previous_employer_detail_status" type="radio" id="pednot_completed"
                                class="form-check-input " formControlName="previous_employer_detail_status"
                                value="False">
                            <label for="pednot_completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Work experience status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="work_experience_status" type="radio" id="weCompleted"
                                class="form-check-input  " formControlName="work_experience_status" value="True">
                            <label for="weCompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="work_experience_status" type="radio" id="wenot_completed"
                                class="form-check-input " formControlName="work_experience_status" value="False">
                            <label for="wenot_completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Total experience</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="total_experience" type="radio" id="0to1" class="form-check-input"
                                formControlName="total_experience" [value]=[0,1]>
                            <label for="0to1" class="form-check-label">0-1 years</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="total_experience" type="radio" id="1to3" class="form-check-input"
                                formControlName="total_experience" [value]=[1,3]>
                            <label for="1to3" class="form-check-label">1-3 years</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="total_experience" type="radio" id="3to5" class="form-check-input "
                                formControlName="total_experience" [value]=[3,5]>
                            <label for="3to5" class="form-check-label">3-5 years</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="total_experience" type="radio" id="5to10" class="form-check-input "
                                formControlName="total_experience" [value]=[5,10]>
                            <label for="5to10" class="form-check-label">5-10 years</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="total_experience" type="radio" id="10to20" class="form-check-input "
                                formControlName="total_experience" [value]=[10,20]>
                            <label for="10to20" class="form-check-label">10-20 years</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="total_experience" type="radio" id="20+" class="form-check-input "
                                formControlName="total_experience" [value]=[20,100]>
                            <label for="20+" class="form-check-label">20+ years</label>
                        </div>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row ">
                        <label for="" class="form-label">Reporting manager</label>
                        <ng-select [readonly]="disabled"
                            placeholder="{{this.messageService.selectplaceholddisplay('Reporting manager')}}"
                            formControlName="reporting_manager" [items]="employeeDropdown" [multiple]="true"
                            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> Select All
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngIf="Snapshot.value.reporting_manager?.length">
                                    <span class="ng-value-label">{{Snapshot.value.reporting_manager?.length}}
                                        reporting manager
                                        selected.</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                                        [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                                        class="avatar-sm sq-24 img-fluid rounded-circle"
                                        *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                        src="{{item.profile_image}}">
                                    <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                        class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appservice.getFirstChar(item.first_name+"
                                            "+item.last_name,2)}}</span></span>
                                    <span class="mt-1 ms-1">{{ (item.name?.length>15)? (item.name |
                                        slice:0:30)+'..':(item.name) }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <ul class="selected-values mt-16" *ngIf="Snapshot.value.reporting_manager?.length !=0">
                            <li class="ng-value" *ngFor="let item of Snapshot.value.reporting_manager, let m =index">
                                <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                        <div class="d-inline-flex">
                                            <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                                *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                                src="{{insideitem.profile_image}}">
                                            <span
                                                *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                                class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                                                <span>
                                                    {{this.appservice.getFirstChar(insideitem.first_name+"
                                                    "+insideitem.last_name,2)}}</span>
                                            </span>
                                            <span class="mt-2 ms-1">
                                                {{insideitem.first_name+"
                                                "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                                ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                            </span>
                                        </div>
                                    </span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true"
                                    (click)="clearcommonForm('employeesrm',m)"><i class="icon-close"></i></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row ">
                        <label for="" class="form-label">Functional manager</label>
                        <ng-select [readonly]="disabled"
                            placeholder="{{this.messageService.selectplaceholddisplay('Functional manager')}}"
                            formControlName="functional_manager" [items]="employeeDropdown" [multiple]="true"
                            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> Select All
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngIf="Snapshot.value.functional_manager?.length">
                                    <span class="ng-value-label">{{Snapshot.value.functional_manager?.length}}
                                        functional manager
                                        selected.</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                                        [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                                        class="avatar-sm sq-24 img-fluid rounded-circle"
                                        *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                        src="{{item.profile_image}}">
                                    <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                        class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appservice.getFirstChar(item.first_name+"
                                            "+item.last_name,2)}}</span></span>
                                    <span class="mt-1 ms-1">{{ (item.name?.length>15)? (item.name |
                                        slice:0:30)+'..':(item.name) }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <ul class="selected-values mt-16" *ngIf="Snapshot.value.functional_manager?.length !=0">
                            <li class="ng-value" *ngFor="let item of Snapshot.value.functional_manager, let m =index">
                                <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                        <div class="d-inline-flex">
                                            <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                                *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                                src="{{insideitem.profile_image}}">
                                            <span
                                                *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                                class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                                                <span>
                                                    {{this.appservice.getFirstChar(insideitem.first_name+"
                                                    "+insideitem.last_name,2)}}</span>
                                            </span>
                                            <span class="mt-2 ms-1">
                                                {{insideitem.first_name+"
                                                "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                                ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                            </span>
                                        </div>
                                    </span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true"
                                    (click)="clearcommonForm('employeesfm',m)"><i class="icon-close"></i></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Date of leaving</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="date_of_leaving" type="radio" id="last_Month" class="form-check-input  "
                                formControlName="date_of_leaving" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_leaving','last_month','dol_specific_date')">
                            <label for="last_Month" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="date_of_leaving" type="radio" id="current_Month" class="form-check-input "
                                formControlName="date_of_leaving" value="current_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_leaving','current_month','dol_specific_date')">
                            <label for="current_Month" class="form-check-label">Current month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check mb-8">
                            <input name="date_of_leaving" type="radio" id="dol_specific_date" class="form-check-input "
                                formControlName="date_of_leaving" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'date_of_leaving',true,'dol_specific_date')">
                            <label for="dol_specific_date" class="form-check-label">Specific date</label>
                        </div>
                    </div>
                    <!-- <div class="col-12 form-row" *ngIf="Snapshot.value.date_of_leaving==true">
                        <ng-container>
                            <div class="input-group form-icon icon-right">
                                <input (click)="datePicker1.open()" class="form-control"
                                    placeholder="{{this.messageService.selectplaceholddisplay('Date')}}" matInput
                                    formControlName="dol_specific_date" [matDatepicker]="datePicker1"
                                    [matDatepickerFilter]="salFilter">
                                <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                            </div>
                        </ng-container>
                    </div> -->

                    <ng-container *ngIf="Snapshot.value.date_of_leaving==true">
                      <div class="col-6">
                          <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}">
                              <label for="" class="form-label">Month </label>
                              <app-master-select class="config-app-master" style="width:9rem"
                              [placeholder]="month_placeholder"
                                      id="month" [isrequired]="false" [selectedItem]="Snapshot.value.specific_month"
                                  [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                                      (ngModelChange)="attendancemonthChangeFn($event)" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}"> </app-master-select>
                          </div>
                          <div *ngIf="(submitted || f?.specific_month?.touched || f?.specific_month?.dirty)&& f?.specific_month?.errors" class="invalid-feedback">
                              <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                      <div class="col-6">
                          <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                              <label for="" class="form-label">Year </label>
                              <ng-select class="form-ngselect  fs-14"
                              formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                  <ng-option *ngFor="let yData of Years"
                                      [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                          </div>
                          <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                              <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                  </ng-container>

                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Date of joining</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input  type="radio" id="today_join" class="form-check-input"
                                formControlName="date_of_joining" value="today"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','today','doj_specific_date');">
                            <label for="today_join" class="form-check-label">Today</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input  type="radio" id="tomorrow_join" class="form-check-input"
                                formControlName="date_of_joining" value="tomorrow"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','tomorrow','doj_specific_date');">
                            <label for="tomorrow_join" class="form-check-label">Tomorrow</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input  type="radio" id="last_7_days_join" class="form-check-input"
                                formControlName="date_of_joining" value="last_7_days"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','last_7_days','doj_specific_date');">
                            <label for="last_7_days_join" class="form-check-label">Last 7 days</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input type="radio" id="last_15_days_join" class="form-check-input"
                                formControlName="date_of_joining" value="last_15_days"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','last_15_days','doj_specific_date');">
                            <label for="last_15_days_join" class="form-check-label">Last 15 days</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input  type="radio" id="last_month_join" class="form-check-input"
                                formControlName="date_of_joining" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','last_month','doj_specific_date');">
                            <label for="last_month_join" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input type="radio" id="this_week_join" class="form-check-input"
                                formControlName="date_of_joining" value="this_week"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','this_week','doj_specific_date');">
                            <label for="this_week_join" class="form-check-label">This week</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input type="radio" id="this_month_join" class="form-check-input"
                                formControlName="date_of_joining" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','this_month','doj_specific_date');">
                            <label for="this_month_join" class="form-check-label">This month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input  type="radio" id="next_month_join" class="form-check-input"
                                formControlName="date_of_joining" value="next_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','next_month','doj_specific_date');">
                            <label for="next_month_join" class="form-check-label">Next month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input type="radio" id="specific_doj" class="form-check-input "
                                formControlName="date_of_joining" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining',true,'doj_specific_date');">
                            <label for="specific_doj" class="form-check-label">Specific period</label>
                        </div>
                    </div>
                    <ng-container *ngIf="Snapshot.value.date_of_joining==true">
                        <!-- <div class="input-group form-icon icon-right"
                        >
                                <input (click)="datePicker1.open()" class="form-control" placeholder="From date" matInput
                                    formControlName="from_date_join" [matDatepicker]="datePicker1"
                                    [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                                <mat-datepicker  touchUi="false" #datePicker1>

                                </mat-datepicker>
                            </div>

                            <div class="input-group form-icon icon-right">
                                    <input (click)="datePicker2.open()" class="form-control" placeholder="To date" matInput
                                        formControlName="to_date_join" [matDatepicker]="datePicker2"
                                        [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                    <mat-datepicker  touchUi="false" #datePicker2>
                                    </mat-datepicker>
                            </div> -->
                            <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.from_date_join?.touched || f?.from_date_join?.dirty) &&  f?.from_date_join?.errors}">
                        <input (click)="datePickerdoj1.open()" class="form-control" placeholder="From date" matInput
                            formControlName="from_date_join" [matDatepicker]="datePickerdoj1" [ngClass]="{'is-invalid':(submitted || f?.from_date_join?.touched || f?.from_date_join?.dirty) &&  f?.from_date_join?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePickerdoj1.open()"></i>
                        <mat-datepicker  touchUi="false" #datePickerdoj1>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.from_date_join?.touched || f?.from_date_join?.dirty) &&  f?.from_date_join?.errors" class="invalid-feedback">
                      <div *ngIf="f?.from_date_join?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                  <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.to_date_join?.touched || f?.to_date_join?.dirty) &&  f?.to_date_join?.errors}">
                        <input (click)="datePickerdoj2.open()" class="form-control" placeholder="To date" matInput
                            formControlName="to_date_join" [matDatepicker]="datePickerdoj2" [ngClass]="{'is-invalid':(submitted || f?.to_date_join?.touched || f?.to_date_join?.dirty) &&  f?.to_date_join?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePickerdoj2.open()"></i>
                        <mat-datepicker  touchUi="false" #datePickerdoj2>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.to_date_join?.touched || f?.to_date_join?.dirty) &&  f?.to_date_join?.errors" class="invalid-feedback">
                      <div *ngIf="f?.to_date_join?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                    </ng-container>
                </div>
                <div class="row row-16 mb-16">

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Date of confirmation</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input  type="radio" id="last_7_days_confirm" class="form-check-input"
                                formControlName="date_of_confirmation" value="last_7_days"
                                (change)="radioBtnChangeFunctions($event,'date_of_confirmation','last_7_days','doc_specific_date');">
                            <label for="last_7_days_confirm" class="form-check-label">Last 7 days</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input type="radio" id="last_15_days_confirm" class="form-check-input"
                                formControlName="date_of_confirmation" value="last_15_days"
                                (change)="radioBtnChangeFunctions($event,'date_of_confirmation','last_15_days','doc_specific_date');">
                            <label for="last_15_days_confirm" class="form-check-label">Last 15 days</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input type="radio" id="last_month_confirm" class="form-check-input"
                                formControlName="date_of_confirmation" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_confirmation','last_month','doc_specific_date');">
                            <label for="last_month_confirm" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input type="radio" id="this_month_confirm" class="form-check-input"
                                formControlName="date_of_confirmation" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_confirmation','this_month','doc_specific_date');">
                            <label for="this_month_confirm" class="form-check-label">This month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input type="radio" id="specific_confirm" class="form-check-input "
                                formControlName="date_of_confirmation" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'date_of_confirmation',true,'doc_specific_date');">
                            <label for="specific_confirm" class="form-check-label">Specific period</label>
                        </div>
                    </div>
                    <ng-container *ngIf="Snapshot.value.date_of_confirmation==true">
                        <!-- <div class="input-group form-icon icon-right"
                        >
                                <input (click)="datePicker1.open()" class="form-control" placeholder="From date" matInput
                                    formControlName="from_date_confirm" [matDatepicker]="datePicker1"
                                    [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                                <mat-datepicker  touchUi="false" #datePicker1>

                                </mat-datepicker>
                            </div>

                            <div class="input-group form-icon icon-right">
                                    <input (click)="datePicker2.open()" class="form-control" placeholder="To date" matInput
                                        formControlName="to_date_confirm" [matDatepicker]="datePicker2"
                                        [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                    <mat-datepicker  touchUi="false" #datePicker2>
                                    </mat-datepicker>
                            </div> -->
                            <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.from_date_confirm?.touched || f?.from_date_confirm?.dirty) &&  f?.from_date_confirm?.errors}">
                        <input (click)="datePickerdoc1.open()" class="form-control" placeholder="From date" matInput
                            formControlName="from_date_confirm" [matDatepicker]="datePickerdoc1" [ngClass]="{'is-invalid':(submitted || f?.from_date_confirm?.touched || f?.from_date_confirm?.dirty) &&  f?.from_date_confirm?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePickerdoc1.open()"></i>
                        <mat-datepicker  touchUi="false" #datePickerdoc1>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.from_date_confirm?.touched || f?.from_date_confirm?.dirty) &&  f?.from_date_confirm?.errors" class="invalid-feedback">
                      <div *ngIf="f?.from_date_confirm?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                  <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.to_date_confirm?.touched || f?.to_date_confirm?.dirty) &&  f?.to_date_confirm?.errors}">
                        <input (click)="datePickerdoc2.open()" class="form-control" placeholder="To date" matInput
                            formControlName="to_date_confirm" [matDatepicker]="datePickerdoc2" [ngClass]="{'is-invalid':(submitted || f?.to_date_confirm?.touched || f?.to_date_confirm?.dirty) &&  f?.to_date_confirm?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePickerdoc2.open()"></i>
                        <mat-datepicker  touchUi="false" #datePickerdoc2>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.to_date_confirm?.touched || f?.to_date_confirm?.dirty) &&  f?.to_date_confirm?.errors" class="invalid-feedback">
                      <div *ngIf="f?.to_date_confirm?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitSnapshot()">
                Apply
            </button>
        </footer>
    </form>
</div>
