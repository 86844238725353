<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Approval Matrix</h3>
      <p class="text-helper mb-md-0">Create and allocate multiple custom workflows for any approval request as per your
        business needs</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <app-searchbar [searchlist]='matrixDropdown' [searchplaceholder]='searchString'
            (searchEmitter)="filterMatrix($event)" (keywordsearchEmitter)="searchmatrix($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':listType == 'all'  }"
              (click)="selectItemList('');listType='all'">All</button>
            <button ngbDropdownItem [class]="{'active':listType == 'active' }"
              (click)="selectItemList(true);listType='active'">Active </button>
            <button ngbDropdownItem [class]="{'active':listType == 'inactive'}"
              (click)="selectItemList(false);listType='inactive'">Deleted</button>
          </div>
        </div>

        <button *ngIf="permissions.a" routerLink="/approval-matrix/setup-approval-matrix"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add APPROVAL MATRIX</button>
      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <ng-template #all_data>
    <div class="row row-16 " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 " *ngFor="let item of items;let ind = index">
          <div class="card card-custom1 card-hover card-ribbon">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex p-16 {{(item.is_active == true) ? 'view-section' : 'w-100' }} "
                (click)="viewdataapproval(item.id);loader1=true">

                <div class="logo-img {{item.color_code}}">{{this.appService.getFirstChar(item.name,1)}}</div>
                <div class="overflow-hidden ">
                  <h6 class="tips text-trim mb-0">{{item.name}}</h6>
                  <span class="tips-helper">{{item.name}}</span>
                  <span
                    class="fs-10 text-uppercase fw-500 {{(item.is_active == true) ? 'text-success' : 'text-danger' }} ">{{item.is_active
                    == true ? 'Active':'Deleted'}}</span>
                </div>
              </div>
              <div *ngIf="item.is_active==true && (permissions.e || permissions.d)" ngbDropdown
                class="pe-16 py-16 dropdown-section d-inline-block ">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical fs-16"></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                  <button *ngIf="permissions.e" ngbDropdownItem (click)="editMatrix(item.id)">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem
                    (click)="deleteAlert=true;deleteId=item.id">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
              <!-- <img class="logo-img img-fluid" src=""> -->
              <!-- <div class="ms-auto d-flex gap-4 fs-14" *ngIf="item.is_active==true">
                                <button class="btn-reset link-primary" >
                                    <i class="icon-pencil-square"></i>
                                </button>
                                <button class="btn-reset " >
                                    <i class="icon-trash"></i>
                                </button>
                            </div>
                            <div class="ms-auto d-flex  fs-14" *ngIf="item.is_active==false">
                                <button class="btn-reset link-primary" (click)="editMatrix(item.id)">
                                    <i class="bi bi-eye "></i>
                                </button>
                            </div> -->
            </div>
            <span class="ribbon ribbon-right   ribbon-sm {{item.is_cancel
                            == true ? 'ribbon-danger' : 'ribbon-primary' }} "
              style="right:-0.0625rem;bottom: 0.75rem;">{{item.is_cancel
              == true ? 'Cancellation' : 'New' }} </span>
          </div>
        </div>
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>


    </div>
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader">
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->


  <div *ngIf="nodata && !loader;else all_data" class="row " style="height: calc(100vh - 12rem);">
    <app-nodata-view class="vstack" [imgHeight]="'11rem'" [noDataImg]="'bg4.png'" [noDataText]="'Streamline Approvals!'"
      [noDataPara]="'Set up and manage your approval workflows. Click \' ADD APPROVAL MATRIX\' to start creating powerful workflows.'"
      [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
  </div>
</div>
<div class="side-panel" style="--sidepanel-width:44.6875rem;" [class.side-pane-active]='showSidePanel === true'
  *ngIf="permissions">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid px-32 py-24">
        <!-- View loader start -->
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <!-- View loader end -->
        <ng-container *ngIf="!loader1">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">APPROVAL MATRIX</p>
            <a *ngIf="permissions.e && viewapprovmatrixdata.is_active"
              class="link-primary fs-14 d-flex align-items-center ms-auto"
              (click)="editMatrix(viewapprovmatrixdata.id)">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>
          <div class="row row-16 ">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">APPROVAL FLOW</span></div>
                  <div class="col-6">
                    <div class="detail-title">Flow name</div>
                    <div class="detail-desc">{{viewapprovmatrixdata.name}}</div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span class="fs-12 fw-500 text-success text-uppercase"
                        *ngIf="viewapprovmatrixdata.is_active">ACTIVE</span>
                      <span class="fs-12 fw-500 text-danger text-uppercase"
                        *ngIf="viewapprovmatrixdata.is_active==false">DELETED</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Flow description</div>
                    <div class="detail-desc">{{viewapprovmatrixdata.description}}</div>
                  </div>
                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                  <div class="col-12">
                    <div class="col-12 mb-8">
                      <p class="mb-0 fs-14">Approval flow</p>
                    </div>
                    <div class="row row-16">
                      <div class="col-sm-6"
                        *ngFor="let approval_flow of viewapprovmatrixdata.approval_flow, let i = index">
                        <div class="card card-c2">
                          <div class="card-body p-16">
                            <div class="d-flex mb-16">
                              <div class="text-success text-uppercase  fs-10" *ngIf="approval_flow.intimation == false">
                                <span class="dot">APPROVAL</span>
                              </div>
                              <div class="text-accent2 text-uppercase  fs-10" *ngIf="approval_flow.intimation== true">
                                <span class="dot">INITIMATION</span>
                              </div>
                              <div class="ms-auto fs-10" *ngIf="approval_flow.intimation == false">TAT :
                                {{approval_flow.days}} Days</div>

                              <div class="ms-auto fs-10">Due days : {{approval_flow.due_days}}</div>
                            </div>
                            <div class="fs-12 text-uppercase fw-600">{{i+1}}. {{approval_flow.role_name}}</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">ALLOCATION</span></div>
                  <div class="col-12">
                    <div class="detail-title">Module name</div>
                    <div class="detail-desc">{{allocatapprove.module_name}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Work flow</div>
                    <div class="detail-desc">
                      <div class="d-flex flex-wrap mt-8 gap-8">
                        <span class="badge3" *ngFor="let workflow of viewapprovmatrixdata.allocation, let i = index">
                          {{workflow.request_name}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Flow type</div>
                    <div class="detail-desc">{{viewapprovmatrixdata.is_cancel==true? 'Cancellation' : 'New'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">RIGHTS</span></div>
                  <div class="col-12">
                    <div class="detail-title">Applicable to</div>
                    <div class="detail-desc">{{rightsapprove?.applicable}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">{{rightsapprove?.applicable}}(s) </div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1">
                        <ng-container *ngIf="rightsapprove?.applicable == 'Employee'">
                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,2)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="rightsapprove?.applicable == 'Company'">
                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container
                          *ngIf="(rightsapprove?.applicable != 'Employee') && (rightsapprove?.applicable != 'Company')">
                          <div class="d-flex flex-wrap mt-8 gap-8">
                            <ng-container *ngFor="let rights of assignees, let i=index;">
                              <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                                slice:0:25)+'..':(rights) }}
                              </span>
                            </ng-container>

                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div *ngIf="deleteAlert" class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Approval Matrix?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Approval Matrix')}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm" [disabled]="deleteClicked"
          (click)="deleteMatrix(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
