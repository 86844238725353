import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-ytd-salary-details',
  templateUrl: './ytd-salary-details.component.html',
  styleUrls: ['./ytd-salary-details.component.scss']
})
export class YtdSalaryDetailsComponent implements OnInit {

  @Input() companyDD: any;
  @Input() gradeDD: any;
  @Input() BranchDD: any;
  @Input() buDD: any;
  @Input() DepDD: any;
  @Input() disabled: any;
  @Input() empstatusDD: any;
  @Input() emptypeDD: any;
  @Input() employeeDropdown: any;
  // Above needs to be cleared
  @Input() ytdSalaryForm: any;
  @Input() viewDetail: any;
  @Input() callMonthlyTDS: any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() payGroupDD: any;
  @Input() yearList: any;
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering :any
  @Input() DesignationDD: any
  @Input() ouFilterForm: any
  @Input() resDataFilter:any;

  currentYear         = new Date().getFullYear();
  selectedYear    = Number(new Date().getFullYear());

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  get f()  { return this.ytdSalaryForm.controls; }

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();

  constructor(
    private reportService:ReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService,
    public cd : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getPayGroupList();
    this.yearListCreate();
  }

  getPayGroupList() {
    this.reportService.getPayGroupList().subscribe((res:any)=>{
      this.payGroupDD = res;
      this.selectAllForDropdownItems(this.payGroupDD);
    });
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  clearcommonForm(item:any,index:any){
   if(item == "pay_group"){
      let daata = this.ytdSalaryForm.value.pay_group;
      daata.splice(index, 1);
      this.ytdSalaryForm.patchValue({
        pay_group: daata
      });
    }
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.ytdSalaryForm.get('pay_group')?.reset('');
      this.ytdSalaryForm.get('financial_year')?.reset(null);
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
    }
    this.callMonthlyReport.emit({
      reportName : 'YTD Salary Details'
    })
  }

  filterFormData(use:any){
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let pay_group    = this.ytdSalaryForm.controls.pay_group.value==''?JSON.stringify([]):JSON.stringify(this.ytdSalaryForm.controls.pay_group.value);

    if(use == 'filter'){
      return `pay_group=${pay_group}&financial_year=${this.ytdSalaryForm.controls.financial_year.value}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return  JSON.stringify({'financial_year' : this.ytdSalaryForm.controls.financial_year.value,'pay_group':this.ytdSalaryForm.controls.pay_group.value,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

  applyBtn : boolean = false

  submitYtd(){
    this.submitted          = true;
    let offset = 0
    if (this.ytdSalaryForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')

    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail       = false;
    this.applyBtn = false
  }

  // Tags closing
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }

    if(this.closeControl == 'pay_group'){
      this.ytdSalaryForm.get(this.closeControl)?.setValue('');
    }else{
      this.ouFilterForm.get(this.closeControl)?.setValue('');
    }
    this.submitYtd();
  }

  yearChange(value:any){
    this.selectedYear = Number(value);
  }

  yearListCreate(){
    this.yearList=[];
    var i:number;
    for(i = this.appservice.getFinancialYear(); i >= 2020 ; i--){
      this.yearList.push(String(i))
    }
  }

  financialYearRange(num:any){
    let a = num;
    a = Number(a) + 1;
    return a;
  }

  resetPage(){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'financial_year'  && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
