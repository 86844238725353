import { Component, OnInit ,ViewChild} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';

@Component({
  selector: 'app-expense-reimbursement',
  templateUrl: './expense-reimbursement.component.html',
  styleUrls: ['./expense-reimbursement.component.scss']
})
export class ExpenseReimbursementComponent implements OnInit {
  status              = "Pending";
  viewDetail          = false;
  searchString:any    = "";
  page                = 1;
  pageSize            = 20;
  viewPage            = 1;
  viewPageSize        = 20;
  buList:any          = [];
  DepList:any         = [];
  DesignationList:any = [];
  BranchList:any      = [];
  cityList:any        = [];
  gradeList:any       = [];
  chunkLen            = 10;
  percentage:any      = 0;
  isDataUpload        = 0;
  isProgressStart     = false;
  tableData: any[]    = [];
  HeaderText:any      = "Pending"
  filter              = false
  loader              = false
  selectedList:any    = []
  viewLoader          = false
  viewData:any        = []
  saveConfirm         = false
  message:any         = ""
  saveComplete        = false;
  processText:any     = ""
  failedData:any      = [];
  exportData:any      = [];
  successDataCount    = 0;
  errorMsg = ''
  successMsg = ''
  modelHead:any;
  downloadFile:any;
  downloadMsg         = false;


  year:any;
  month:any;
  company:any;
  inputdata :any;

  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  constructor(
    public route:ActivatedRoute,
    public messageService : MessageService,
    public sds:SalaryDashboardService,
    public appService: AppService,
    private notificationService: NotificationService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
      this.getLandingScreenData('Pending')
    })
  }
  getLandingScreenData(status:any){
    this.status = status;
    this.loader = true;
    this.searchString = "";
    this.sds.getReimbursementList(status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,this.month,this.year).subscribe((res:any)=>{

      this.tableData = res.body;

       this.loader = false
     });
  }

  checkall(event:any){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }
  filterIt() {
    if(this.viewDetail==false){

      return this.tableData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key].toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    }
    else{
      return this.viewData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key].toString().toLowerCase();
            const tempSearch = "";
            return tempKey.includes(tempSearch);
          }
        });
      });
    }
  }
  filteredcount(){
    return this.filterIt().length;
  }
  checkallSelected(){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item:any) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  countSelected(){
    return this.tableData.filter(obj => obj.checked === true).length;
  }
  selectAll(){
    this.inputdata = this.appService.selectAll(this.tableData)

  }
  unselectAll(){
    this.inputdata = this.appService.unselectAll(this.tableData)

  }
  closeFilter(){
    this.filter  = false
  }
  resetFilter(){
    this.buList           = []
    this.DepList          = []
    this.DesignationList  = []
    this.gradeList        = []
    this.BranchList        = []
    this.filter           = false
    this.getLandingScreenData(this.status)

  }
  submitForm(data:any){
    this.filter  = false
    this.filtercomp.getBUList(this.company);
    this.buList           = data.buList;
    this.DepList          = data.DepList;
    this.DesignationList  = data.DesignationList;
    this.gradeList        = data.gradeList;
    this.BranchList       = data.BranchList;
    this.getLandingScreenData(this.status)
  }
  confirmReimbursement(){
    this.saveConfirm  = true;
    this.processText  = this.status=='Pending'?'Reimburse':'Undo'
    this.message      = 'Click the '+this.processText+' Process button, If you want to '+this.processText+'  the expense for the selected employees.'
  }
  updateReimbursement(){
    this.saveComplete = true;
    this.selectedList = []
    this.tableData.forEach((item:any, key:any) => {
      if(item.checked == true){
        this.selectedList.push(item.id)
      }
    })
    var dataArray = {
      "pay_year"              : Number(this.year),
      "pay_month"             : this.month,
      "expense_employee_list" : this.selectedList,
      "status"                : this.status=='Pending'?'Reimbursed':'Pending'

   };
   this.sds.saveReimbursement(dataArray).subscribe((res:any)=>{

    let status:any = ""
    status         = this.status=='Pending'?'Reimbursed':'Undo'
    if(res.body.error_list.length==0)
    this.notificationService.handleSuccessNotification("Selected employee's expense "+status +" successfully.","Expense "+status);
    else{
      this.successDataCount = this.selectedList.length-res.body.error_list.length
      this.exportData = []
      for (let i = 0; i < res.body.error_list.length; i++) {
        let data = res.body.error_list[i]
        this.exportData.push({'Employee code':data['employee_code'],'Employee name':data['employee_name'],'Error description':data['error_description']});

      }
      this.successMsg   = this.successDataCount+" out of "+this.selectedList.length+" data reimbursed successfully "
      this.errorMsg     = res.body.error_list.length+' employee(s) have error data'
      this.downloadMsg  = true;
      this.modelHead    = 'Expense Reimbursement';
      this.downloadFile = 'Expense Reimbursement Error'
    }
    this.getLandingScreenData(this.status);
    this.saveComplete = false
    this.saveConfirm  = false
   })
  }
  getEmployeeReimbursement(id:any){
    this.viewDetail = true
    this.viewLoader = true;
    let month:any   = '';
    let year:any    = '';
    if(this.status!='Pending'){
      month = this.month;
      year  = this.year;
    }
    this.sds.getEmployeeReimbursement(id,this.status,month,year).subscribe((res:any)=>{

      this.viewData   = res.body;
      this.viewLoader = false
    })
  }
}
