import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-holiday-setup',
  templateUrl: './holiday-setup.component.html',
  styleUrls: ['./holiday-setup.component.scss']
})
export class HolidaySetupComponent implements OnInit {

  @Output() holidayReqClose             = new EventEmitter();
  @Output() holidaySearch               = new EventEmitter();
  @Output() holidayReqSave              = new EventEmitter();

  @Input()holidayRequestFlag:any;
  @Input()holidayFloatData:any;
  @Input()holidatSearchData:any;
  @Input()loader:any;
  @Input()nodata:any;
  @Input()saveClicked:any;

  searchlistdata:any                    = [];
  holidayPlaceHoldaer:string            = 'Search holidays';
  max_limit:any;
  holiday_request_count:any;
  can_request                           = false;
  alertToggle                           = false;
  investalertMsg:any                    = '';
  statusBtn:any                         = "Month (Ascending)";
  alertShow                             = true;
  searchKeyword                         = "";
  filterValue:any                       = "";
  requestData:any;

  @ViewChild(SearchbarComponent) child:any;

  constructor(
    private investmentService:InvestmentDeclerationService,
    private authService:AuthService,
  ) { }

  ngOnInit(): void {
    this.requestCount();
  }

  /********* CLOSE FUNCTION ****************/
  closeFunction(){
    this.holidayReqClose.emit({'show': false, 'action': 'close'});
  }

  /********* SORTING FUNCTION ****************/
  selectSorting(value:any){
    this.filterValue = value;
    this.getList();
  }

  getList(){
    this.loader = true;
    this.filterValue = this.statusBtn == 'Month (Ascending)' ? 'holiday_date' : this.statusBtn == 'Month (Descending)' ? '-holiday_date' : '';
    this.investmentService.holidayFloatList(this.filterValue,this.searchKeyword).subscribe((res: any) => {
      this.loader = false;
      this.holidayFloatData = res;

      // if(this.holidayFloatData?.length > 0){
      //   for(let i=0; i<this.holidayFloatData?.length; i++){

      //     if(this.holidayFloatData[i]['day_name'] != null && this.holidayFloatData[i]['day_name'] != '')
      //       this.holidayFloatData[i]['name'] = this.holidayFloatData[i]['holiday_name'] +' '+ 'day ' +this.holidayFloatData[i]['day_name'];
      //     else
      //       this.holidayFloatData[i]['name'] = this.holidayFloatData[i]['holiday_name'];
      //   }
      // }
      // this.child.clearBar();

    });
  }
   /********* SEARCH FUNCTION ****************/
  searchresults(data:any){
    this.searchKeyword = data;
    if(data == ""){
      // this.holidaySearch.emit({search: false, searchid: data});
      this.getList();
    }else{
      this.holidaySearch.emit({search: true, searchid: data});
    }
  }
  /********* SUBMIT FUNCTION ****************/
  onSubmit(id: number, day_name: any, day_date: any): void {
      this.saveClicked  = true;
      this.requestData   = {
                          employee: this.authService.isEmployeeId,
                          holiday_mapping: id,
                          day_name: day_name,
                          holiday_date: day_date
                        }
    this.investmentService.requestDetails().subscribe((res:any)=>{
      if(res.is_employee){
        if(res.count_info.hasOwnProperty('holiday_data')){
          if(res.count_info.holiday_data.hasOwnProperty('max_limit')){
            this.max_limit= res.count_info.holiday_data.max_limit;
          }if(res.count_info.holiday_data.hasOwnProperty('holiday_request_count')){
            this.holiday_request_count= res.count_info.holiday_data.holiday_request_count;
          }
          if(this.max_limit > this.holiday_request_count){
            // this.alertToggle    = false;
            // this.saveClicked = false;
            // this.holidayReqSave.emit({requestData : this.requestData, saveClicked : false });
            this.holidayReqSaveFunction( this.requestData)
          }else{
            this.alertToggle    = true;
            this.investalertMsg = 'Holiday request has exceeded the maximum limit as per the policy.';
          }
        }else{
          this.alertToggle    = true;
          this.investalertMsg = 'Holiday request has exceeded the maximum limit as per the policy.';
        }
      }else{
        this.alertToggle    = true;
        this.investalertMsg = 'Holiday request has exceeded the maximum limit as per the policy.';
      } },(error)=>{
        this.saveClicked    = false;

    });
  }

  /********* REQUEST COUNT FUNCTION ****************/
  requestCount(){
    this.investmentService.requestDetails().subscribe((res:any)=>{
      if(res.is_employee){
        if(res.count_info.hasOwnProperty('holiday_data')){
          if(res.count_info.holiday_data.hasOwnProperty('max_limit')){
            this.max_limit= res.count_info.holiday_data.max_limit;
          }if(res.count_info.holiday_data.hasOwnProperty('holiday_request_count')){
            this.holiday_request_count= res.count_info.holiday_data.holiday_request_count;
          }

        }
      }

    });
  }

  holidayReqSaveFunction(requestData:any){
    this.investmentService.holidayFloatCreate(requestData).subscribe((res: any) => {
      if(res?.error != ''){
        this.alertToggle = true;
        this.investalertMsg = res?.error;
        this.saveClicked = false;
      }else{
        this.alertToggle = false;
        this.saveClicked = false;
        this.holidayReqClose.emit({'show': false, 'action': 'save'});
      }
    });
  }

  closeInfo(bool:any){
    this.alertToggle = false;
    this.saveClicked = false;
  }

  searchrequest(searchKeyword:any){
    this.holidayFloatData = [];
    this.searchKeyword    = searchKeyword;
    if(searchKeyword != ""){
      this.getList();
    }
  }

}
