
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-checkin-request',
  templateUrl: './view-checkin-request.component.html',
  styleUrls: ['./view-checkin-request.component.scss']
})
export class ViewCheckinRequestComponent implements OnInit {

  @Input() viewData: any
  @Input() checkInId: any

  page = 1;
  pageSize = 20;
  viewSpecData: any = []
  viewMapPanel: boolean = false


  viewStatusData: any
  viewStatusLevelCnt: any
  viewStatusmaxLevel: any
  viewstatusPanel: boolean = false
  statusLoader: boolean = false

  style: any = "styled-class avatar-circle"
  userData : any;
  specificId: any;

  constructor(
    public appService : AppService,
    private invstServiec :  InvestmentDeclerationService,
    private authService : AuthService,
  ) {
    this.invstServiec.request_id.subscribe(result =>{
      if(Number(this.checkInId) == Number(result))
        this.statusData(this.specificId);
    })
   }

  ngOnInit(): void {
    this.viewSpecData = this.viewData?.requests
  }
  request:any
  // View Map
  showMap(req: any) {
    this.request = req
  }

  statusData(id: any) {
    this.viewstatusPanel = true;
    this.statusLoader    = true;
    this.specificId      = id;
    if(typeof this.checkInId === 'number' && typeof id === 'number'){
    this.invstServiec.viewStatusCheckInData(this.checkInId, id).subscribe((res: any) => {
        this.viewStatusData = res;
        if(!res?.apporval_matrix_loading)
          this.statusLoader     = false;
        this.viewStatusLevelCnt = res?.activity?.level_count
        this.viewStatusmaxLevel = res?.activity?.maxlevel
      })
    }
  }

}
