<div class="container-fluid px-32 py-24">
  <div class="row row-16">
    <div class="col-12 d-flex position-relative">
      <span class=" text-light-500 fs-12 text-nowrap">{{requestitem.created_display}}</span>
      <button class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
        (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8" *ngIf="!requestitem?.request_data?.is_cancel">On Duty request</h5>
        <h5 class="mb-8" *ngIf="requestitem?.request_data?.is_cancel">On Duty cancellation request</h5>
        <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
          <div class="d-flex ms-auto align-items-center gap-8">
            <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
              *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
            <span class="badge px-16 ms-auto fs-14 badge-success py-1"
              *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
            <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
              *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
            <span class="text-light-400"
              *ngIf="requestitem.approved_status == 'Pending' || requestitem.approved_status == 'Approved' || requestitem.approved_status == 'Rejected'">|</span>
            <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
          </div>
        </ng-container>
        <!-- Invalid request -->
        <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
          class="ribbon ribbon-right ribbon-danger  ribbon-sm" style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
      </div>
      <span *ngIf="!requestitem?.request_data?.is_cancel"
        class=" badge-custom {{requestitem.request_type_color_code}} ">On Duty</span>
      <span *ngIf="requestitem?.request_data?.is_cancel"
        class=" badge-custom {{requestitem.request_type_color_code}} ">On Duty cancellation request</span>
      <span
        *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
        class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>
    <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar>
    <div class="col-12">
      <p class="mb-0 fs-14" [innerHTML]="requestitem.message">
      </p>
    </div>
    <div class="col-12" *ngIf="!requestitem?.request_data?.is_cancel && !requestitem?.achieved_status">
      <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
        <p class="mb-0 fs-14"> You can select multiple requests by clicking on the checkbox. Once the
          status is updated it cannot be reversed.</p>
        <div class="btn-close" (click)="alertShow='hide'"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="card details-card shadow-0 px-24 pt-16 pb-24">
        <div class="row row-24" *ngIf="!requestitem?.request_data?.is_cancel">
          <div class="col-12">
            <div class="d-flex">
              <span class="title">ON DUTY REQUEST</span>
              <span (click)="showTrend = true;getOndutyTrendDetails(this.currentYear);"
                class="text-accent2 pointer ms-auto fs-12 fw-500"><i class="bi bi-graph-up me-1"></i>On
                Duty Request Trend</span>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-0">
              <app-table-loader *ngIf="!requestitem?.requests"></app-table-loader>
              <div *ngIf="requestitem?.requests" class="table-responsive radius-4">
                <table
                  class="table table-striped table-borderless td-12 sticky-header td-white-space td-fs-14 tr-fs-14 table-sm form-table">
                  <thead>
                    <tr>
                      <th class="text-center">
                        <input class="form-check-input checkbox-16 m-0" type="checkbox" (click)="checkall($event)"
                          [checked]="checkAllSelected()" value="">
                      </th>
                      <th class="fw-600" scope="col">Date</th>
                      <th class="fw-600" scope="col">Check-in</th>
                      <th class="fw-600" scope="col">Check-out</th>
                      <th class="fw-600" scope="col">Status</th>
                      <th class="fw-600" scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody class="cell-16 height-full">
                    <tr
                      *ngFor="let request of $any(requestitem?.requests | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                      <td class="text-center">
                        <input class="form-check-input mt-1 checkbox-16" [(ngModel)]="request.checked" type="checkbox"
                          [disabled]="request.status == 'Cancelled' || request?.status == 'Invalid'">
                      </td>
                      <td>{{this.appService.dateFormatDisplay(request?.onduty_request_date?.onduty_date)}}
                      </td>
                      <td>
                        {{appService.isValidDateTime(today+"
                        "+request?.onduty_request_date?.checkin_time) ? (today+"
                        "+request?.onduty_request_date?.checkin_time|date:this.appService.gettimeformat()):"
                        "}}
                      </td>
                      <td>{{appService.isValidDateTime(today+"
                        "+request?.onduty_request_date?.checkout_time) ? (today+"
                        "+request?.onduty_request_date?.checkout_time|date:this.appService.gettimeformat()):"
                        "}}

                      </td>
                      <td>
                        <div class="hstack gap-8 align-items-center">
                          <span *ngIf="request?.status == 'Pending'"
                            class="badge badge-warning fs-14 px-16 py-1">Pending</span>
                          <span *ngIf="request?.status == 'Approved'"
                            class="badge badge-success fs-14 px-16 py-1">Accepted</span>
                          <span *ngIf="request?.status == 'Rejected'"
                            class="badge badge-danger fs-14 px-16 py-1">Rejected</span>
                          <span *ngIf="request.status == 'Cancelled'"
                            class="badge badge-secondary fs-14 px-16 py-1">Cancelled</span>
                          <span *ngIf="request?.status == 'Invalid'"
                            class="badge fs-14 px-16 py-1  badge-danger1">Invalid </span>

                          <i *ngIf="request?.status == 'Invalid'"
                            class="bi bi-info-circle text-danger fs-12 tooltip-icon"
                            ngbPopover="{{request?.onduty_request_date?.remark == 'Overtime approved & attendance processed' ? 'The attendance has already been processed for this date.' : 'The compensatory off leave credited against this date has already been availed.'}}"
                            popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                        </div>
                      </td>
                      <td class="text-center">
                        <i (click)="viewData=request;viewDetails = true" class="bi bi-eye link-primary  fs-20"></i>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <ngb-pagination *ngIf="requestitem?.requests?.length>pageSize" class="d-flex justify-content-end"
            [collectionSize]="requestitem?.requests?.length" [(page)]="page" [maxSize]="3" [rotate]="true"
            [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
        </div>
        <div class="row row-16" *ngIf="!loader_OnDuty && requestitem?.request_data?.is_cancel">
          <div class="col-12">
            <div class="mb-0 fw-500">ON DUTY REQUEST</div>
          </div>
          <div class="col-12" *ngFor="let item of onDutyData">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16">
                <div class="col-12 d-flex align-items-start">
                  <span class="title">ON DUTY REQUEST</span>
                  <span (click)="showTrend = true;getOndutyTrendDetails(this.currentYear);"
                    class="text-accent2 pointer ms-auto fs-12 fw-500"><i class="bi bi-graph-up me-1"></i>On Duty
                    Trend</span>
                </div>
                <div class="col-12">
                  <div class="card card-c2 p-16">

                    <div class="row row-24">
                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Check-in date</div>
                        <div class="detail-desc">
                          {{this.appService.dateFormatDisplay(item?.checkin_date)}}

                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Check-in time</div>
                        <div class="detail-desc">
                          {{appService.isValidDateTime(today+" "+item?.checkin_time) ?
                          (today+"
                          "+item?.checkin_time|date:this.appService.gettimeformat()):" "}}
                        </div>
                      </div>

                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Check-out date</div>
                        <div class="detail-desc">
                          {{this.appService.dateFormatDisplay(item?.checkout_date)}}
                        </div>
                      </div>

                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Check-out time</div>
                        <div class="detail-desc">
                          {{appService.isValidDateTime(today+" "+item?.checkout_time) ?
                          (today+"
                          "+item?.checkout_time|date:this.appService.gettimeformat()):" "}}
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Shift</div>
                        <div class="detail-desc">{{item?.shift_name+' ('+item?.shift_code+')'}}
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="detail-title">Reason</div>
                        <div class="detail-desc">{{item?.reason}}</div>
                      </div>
                      <div class="col-12">
                        <div class="detail-title">Reason for cancellation</div>
                        <div class="detail-desc">{{requestitem?.request_data?.cancel_remark}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="!requestitem?.achieved_status" class="col-12" [formGroup]="form">
      <textarea name="" id="" rows="3" placeholder="{{this.messageService.descriptiontext('comment')}}"
        formControlName="comment" [ngClass]="{ 'is-invalid': f.comment.errors}"
        class="form-control textarea-comment"></textarea>
      <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
        {{this.messageService.fieldlengthvalidation('word','500')}}</div>
    </div>
    <div class="col-12 d-flex gap-16" *ngIf="requestitem?.request_data?.is_cancel && !requestitem?.achieved_status">
      <button class="ms-auto btn btn-outline-danger text-uppercase btn-sm fw-400"
        (click)="confirmCancel('Rejected')">Decline</button>
      <button class=" btn btn-primary text-uppercase btn-sm fw-400" (click)="confirmCancel('Approved')">Confirm</button>
    </div>

    <app-inbox-submit-form *ngIf="!requestitem?.request_data?.is_cancel && !requestitem?.achieved_status"
      class="sticky-footer" [form]="form" [requestitem]="requestitem"
      (successEmitter)="refreshInbox($event)"></app-inbox-submit-form>
  </div>
</div>
<div class="side-panel" style="--sidepanel-width:40.625rem;" [class.side-pane-active]='viewDetails === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>View</h5>
      <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12">
            <div class="mb-0 fw-500">ON DUTY REQUEST DETAILS</div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16">
                <div class="col-12 d-flex">
                  <span
                    class="title">{{this.appService.dateFormatDisplay(viewData?.onduty_request_date?.onduty_date)}}</span>
                  <div *ngIf="viewData?.status == 'Pending'" class=" fs-14 ms-auto px-2 fw-500 badge3 badge-warning">
                    Pending</div>
                  <div *ngIf="viewData?.status == 'Approved'" class=" fs-14 ms-auto px-2 fw-500 badge3 badge-success">
                    Accepted</div>
                  <div *ngIf="viewData?.status == 'Rejected'" class=" fs-14 ms-auto px-2 fw-500 badge3 badge-danger">
                    Rejected</div>
                  <div *ngIf="viewData?.status == 'Cancelled'"
                    class=" fs-14 ms-auto px-2 fw-500 badge3 badge-secondary">Cancelled</div>
                  <div *ngIf="viewData?.status == 'Invalid'" class="fs-14 ms-auto px-2 fw-500 badge3 badge-danger1">
                    Invalid</div>
                </div>
                <div class="col-12">
                  <div class="card card-c2 p-16">

                    <div class="row row-24">
                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Check-in date</div>
                        <div class="detail-desc">
                          {{this.appService.dateFormatDisplay(viewData?.onduty_request_date?.checkin_date)}}
                        </div>
                      </div>

                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Check-in time</div>
                        <div class="detail-desc" *ngIf="viewData?.onduty_request_date?.checkin_time">
                          {{appService.isValidDateTime(today+"
                          "+viewData?.onduty_request_date?.checkin_time) ? (today+"
                          "+viewData?.onduty_request_date?.checkin_time|date:this.appService.gettimeformat()):"
                          "}}
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Check-out date</div>
                        <div class="detail-desc">
                          {{this.appService.dateFormatDisplay(viewData?.onduty_request_date?.checkout_date)}}
                        </div>
                      </div>

                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Check-out time</div>
                        <div class="detail-desc" *ngIf="viewData?.onduty_request_date?.checkout_time">
                          {{appService.isValidDateTime(today+"
                          "+viewData.onduty_request_date.checkout_time) ? (today+"
                          "+viewData.onduty_request_date.checkout_time|date:this.appService.gettimeformat()):"
                          "}}
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Hours</div>
                        <div class="detail-desc">
                          {{this.appService.hhmmTimeFormat(viewData?.onduty_request_date?.hours)}}
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Shift</div>
                        <div class="detail-desc">{{viewData?.onduty_request_date?.shift_name ==
                          null ?'-' : viewData?.onduty_request_date?.shift_name+'
                          ('+viewData?.onduty_request_date?.shift_code+')'}}</div>
                      </div>
                      <div class="col-12">
                        <div class="detail-title">Reason</div>
                        <div class="detail-desc">{{viewData?.onduty_request_date?.reason}}</div>
                      </div>
                      <div *ngIf="viewData?.status == 'Cancelled'" class="col-12">
                        <div class="detail-title">Reason for cancellation</div>
                        <div class="detail-desc">{{viewData?.onduty_request_date?.remark}}</div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showTrend === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>On Duty requests</h5>
      <a class="toggle-panel" (click)="showTrend = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24">
          <div class="col-12 ">
            <div class="card card-c2 p-24">
              <div class="d-flex mb-16">
                <div class=" fs-24">On Duty requests</div>
                <ng-select class="form-ngselect ms-auto fs-14"
                  placeholder="{{this.messageService.selectplaceholddisplay('Year')}}" [clearable]="false"
                  (change)="getOndutyTrendDetails($event)" [(ngModel)]="selectedYearDisp"
                  [ngModelOptions]="{standalone: true}">
                  <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year.k">{{year.k}}</ng-option>
                </ng-select>
              </div>

              <div class="col-12" *ngIf="!graphLoader">
                <app-bar-chart [data]="multi" [labels]="xAxisLabel" [yText]="yAxisLabel"></app-bar-chart>
              </div>
              <div class="col-12" *ngIf="graphLoader">
                <app-graph-loader></app-graph-loader>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </form>
</div>

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>

      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"
        [from]="'onduty-inbox'"></app-view-status>
    </form>
  </div>
</ng-container>