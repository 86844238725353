import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import { FormBuilder,Validators} from '@angular/forms';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
@Component({
  selector: 'app-salary-employee-filters',
  templateUrl: './salary-employee-filters.component.html',
  styleUrls: ['./salary-employee-filters.component.scss']
})
export class SalaryEmployeeFiltersComponent implements OnInit {
    @Input()filter:any;
    @Input() isMemorized = false;
    @Output() filteroutput = new EventEmitter();
    @Output() filteroutput2 = new EventEmitter();
    @Output() filterclose = new EventEmitter();
    @Output() resetCalled = new EventEmitter();
    @Output() filteroutput3 = new EventEmitter();
    @Input()filterForm:any=[];
    @Input()from:any;
    @Input()inputFormat:any
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
  dateFormat          :any

  sort_by:any =[
    'All',
    'Latest',
    'Alphabetically (A-Z)',
    'Alphabetically (Z-A)'
  ];
  sort_by_employee:any =[
    'All',
    'Latest',
    'Alphabetically (A-Z)',
    'Alphabetically (Z-A)',
    'Active First',
    'Inactive First'
  ];
  sort_by_employee_directory:any =[
    'All',
    'Latest',
    'Alphabetically (A-Z)',
    'Alphabetically (Z-A)',
    'Active First',

  ];
  exit_date_type:any = [{id:'leaving_date',name:'Leaving Date'},{id:'settlement_date',name:'Final Settlement Date'}]
  submitted         = false;
  filterSubmit      = false;
  dataFilter: any;
  dataFilter1: any;
  ordering:any;
  ordering1:any = '';
  disabled =false;
  collapsedclass1=false;
  collapsedclass2=false;
  collapsedclass3=false;
  collapsedclass4=false;
  collapsedclass5=false;
  activeidcls:any;
  activeidcls1:any;
  activeidcls2:any;
  activeidcls3:any;
  activeidcls4:any;
  dataFilterArr: any
  emptypekey = this.messageService.selectplaceholddisplay('Employment type');
  empstakey = this.messageService.selectplaceholddisplay('Employment status');
  companyDD :any =  [{
    id            : 0,
    company_name  : ''
  }];

  buDD = [{
    id          : 0,
    name        : ''
  }];

  DepDD = [{
    id          : 0,
    name        : ''
  }];

  DesignationDD = [{
    id          : 0,
    name        : ''
  }];

  BranchDD = [{
    id          : 0,
    name        : ''
  }];

  gradeDD = [{
    id           : 0,
    name         : ''
  }];

  emptypeDD = [
    {id:"Contractual",    name:"Contractual"},
    {id:"Consultant",    name:"Consultant"},
    {id:"Permanent",    name:"Permanent"},
    {id:"Trainee",    name:"Trainee"},
    {id:"Wages",    name:"Wages"},
  ];
  empstatusDD = [
    {id:0,    name:"Probation"},
    {id:1,    name:"Confirmed"},
    {id:2,    name:"Relieved"},
    {id:3,    name:"Resigned"},
    {id:4,    name:"Settled"},
    {id:5,    name:"Terminated"},
    {id:6,    name:"Retired"},
  ];
  managerDD :any =[];
  funmanagerDD :any =[];
  fromDateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  constructor(
    private formBuilder: FormBuilder,
    public salaryService:SalaryStructureService,
    private employeeService : EmployeeService,
    public messageService : MessageService,
    public appService:AppService
  ) { }

  ngOnInit(): void {
    this.getCompanyList();
    this.getbulist();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    this.managerList();
    this.collapsedclsfn();
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);
    this.getDateFormat()
    let data = ['Probation','Confirmed','Resigned']
    if(this.inputFormat == 'Array' && !this.isMemorized){
      this.filterForm.get('emp_status').setValue(data);
      this.filterForm.get('sortby').setValue('Alphabetically (A-Z)');
      this.collapsedclass3 = false
      this.activeidcls2 = 'ngb-panel-2';
      this.activeidcls = 'ngb-panel-0'
    }
  }
  getDateFormat(){
    this.dateFormat = this.appService.getdatepickerformat();
    if(this.dateFormat == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }
  closefn(){
    this.filter = false;
    this.filterclose.emit(this.filter);
    this.filterForm.get('company')?.setValue(this.filterForm.controls.company.value);

  }
  resultfn(resdata:any,resdata1:any,resdata2:any){

    this.filteroutput.emit(resdata);
    this.filteroutput2.emit(resdata1);
    this.filteroutput3.emit(resdata2)
  }
  collapsedclsfn(){

    if(((this.filterForm.controls.company.value =='') || (this.filterForm.controls.company.value == null)) && (this.filterForm.controls.bu.value =='') && (this.filterForm.controls.department.value =='') && (this.filterForm.controls.designation.value =='') && (this.filterForm.controls.grade.value =='') && (this.filterForm.controls.branch.value =='')) {
    this.collapsedclass2 = true;
    this.activeidcls1 = '';
    }else{
    this.activeidcls1 = 'ngb-panel-1';
    }
    if((this.filterForm.controls.emp_status.value =='') && (this.filterForm.controls.emp_type.value =='')) {
    this.collapsedclass3 = true;
    this.activeidcls2 = '';
    }else{
      this.activeidcls2 = 'ngb-panel-2';
      }
     if((this.filterForm.controls.repomanager.value =='') && (this.filterForm.controls.funmanager.value =='')){
    this.collapsedclass4 = true;
    this.activeidcls3 = '';
    }else{
      this.activeidcls3 = 'ngb-panel-3';
      }
    // if(this.filterForm.controls.sortby?.value == 'All'){
    // this.collapsedclass1 = false;
    // this.activeidcls = 'ngb-panel-0';
    // console.log('if')
    // }else{
    //   this.collapsedclass1 = false
    //   this.activeidcls = 'ngb-panel-0';
    //   console.log('else')
    // }
    if((this.from=='FandF' && this.filterForm.controls.exit_date_type.value ==null)){
      this.collapsedclass5 = true;
      this.activeidcls4 = '';
    }else{
      this.activeidcls4 = 'ngb-panel-4';
      }
      this.collapsedclass1 = false
      this.activeidcls = 'ngb-panel-0';
   }
  resetFilterFunction(){

    this.filterForm.patchValue({
      company: '',
      department: '',
      bu: '',
      designation: '',
      grade: '',
      branch: '',
      emp_status: '',
      emp_type: '',
      repomanager: '',
      funmanager: '',
      from_date: '',
      to_date: '',
      expense_date_from: '',
      expense_date_to: ''
    });
    this.filterForm.get('sortby')?.setValue('Latest');
    this.filterForm.get('exit_date_type')?.setValue(null);
    this.ordering1 = ''
    if(this.inputFormat == 'Array'){
      this.filterForm.get('sortby').setValue('Alphabetically (A-Z)');
    }
    this.resetCalled.emit(true)
    this.applyfilter();
    // this.dataFilter = 'employee__company__in=&employee__businessunit__in=&employee__department__in=&employee__designation__in=&employee__branch__in=&employee__grade__in=&employee__employment_type__in=&employee__employment_status__in=&employee__reporting_manager__in=&employee__functional_manager__in'+ this.ordering;

    // this.dataFilter1 = 'company__in=&businessunit__in=&department__in=&designation__in=&branch__in=&grade__in=&employment_type__in=&employment_status__in=&reporting_manager__in=&functional_manager__in'+ this.ordering1;

    // this.resultfn(this.dataFilter,this.dataFilter1);
    // // this.collapsedclsfn();
    }
  applyfilter(){
    this.collapsedclsfn();
    this.submitted      = true;
    this.filterSubmit   = true;
    if (this.filterForm.invalid) {
      this.filterSubmit = false;
      return;
    }

    if(this.filterForm.controls.sortby.value=='Alphabetically (A-Z)'){
    this.ordering = '&ordering=employee__user__first_name'
  this.ordering1 = '&ordering=user__first_name'
    }else if(this.filterForm.value.sortby=='Alphabetically (Z-A)'){
      this.ordering = '&ordering=-employee__user__first_name'
      this.ordering1 = '&ordering=-user__first_name'
    }else if(this.filterForm.value.sortby=='Latest' || this.filterForm.value.sortby=='All'){
      this.ordering = '&ordering=-modified_at'
      if(this.filterForm.value.sortby=='Latest')
        this.ordering1 = '&ordering=-created_at'
      else if (this.filterForm.value.sortby=='All' && this.from !='Employee' && this.from !='send-new-user')
        this.ordering1 = '&ordering=user__first_name'
      else if (this.filterForm.value.sortby=='All' && (this.from =='Employee'||this.from == 'send-new-user'))
        this.ordering1 = '&ordering=-modified_at'

    }else if(this.filterForm.value.sortby=='Active First'){
      this.ordering1 = '&ordering=is_active'
    }else if(this.filterForm.value.sortby=='Inactive First'){
      this.ordering1 = '&ordering=-is_active'
    }
    else{
      this.ordering =''
      this.ordering1 =''
    }
    if(this.from=='FandF' && this.filterForm.value.exit_date_type!=null){
      this.ordering += '&exit_date_type='+this.filterForm.value.exit_date_type+'&from_date='+this.appService.dateFormatConvert(this.filterForm.value.from_date)+'&to_date='+this.appService.dateFormatConvert(this.filterForm.value.to_date)
    }
    let directoryData = ['Probation','Confirmed']
    let selectedDate:any='';
    if(this.from=='advancehr' && this.filterForm.value.expense_date_from!=null && this.filterForm.value.expense_date_to!=null  && this.filterForm.value.expense_date_from!='' && this.filterForm.value.expense_date_to!=''){
        let start1         = moment(this.filterForm.value?.expense_date_from,this.dateFormat).format('YYYY-MM-DD');
        let end1           = moment(this.filterForm.value?.expense_date_to,this.dateFormat).format('YYYY-MM-DD');
        selectedDate          = [start1,end1];
    }
    if(this.from=='expensehr' && this.filterForm.value.expense_date_from!=null && this.filterForm.value.expense_date_to!=null  && this.filterForm.value.expense_date_from!='' && this.filterForm.value.expense_date_to!=''){
        let start1         = moment(this.filterForm.value?.expense_date_from,this.dateFormat).format('YYYY-MM-DD');
        let end1           = moment(this.filterForm.value?.expense_date_to,this.dateFormat).format('YYYY-MM-DD');
        selectedDate       = '&from_date='+start1+'&to_date='+end1
    }
    let company     = this.filterForm.controls.company.value;
    let bu          = this.filterForm.controls.bu.value;
    let dept        = this.filterForm.controls.department.value;
    let desig       = this.filterForm.controls.designation.value;
    let grade       = this.filterForm.controls.grade.value;
    let emp_status  = this.from!='Employee_directory'?this.filterForm.controls.emp_status.value:directoryData;
    if(this.from=='salary_unassigned' && this.filterForm.controls.emp_status.value=='')
    emp_status      = ['Probation','Confirmed','Resigned']
    let emp_type    = this.filterForm.controls.emp_type.value;
    let branch      = this.filterForm.controls.branch.value;
    let repomanager  = this.filterForm.controls.repomanager.value;
    let funmanager   = this.filterForm.controls.funmanager.value;

    this.dataFilter = 'employee__company__in='+company+'&employee__businessunit__in='+bu+'&employee__department__in='+dept+'&employee__designation__in='+desig+'&employee__branch__in='+branch+'&employee__grade__in='+grade+'&employee__employment_type__in='+emp_type+'&employee__employment_status__in='+emp_status+'&employee__reporting_manager__in='+repomanager+'&employee__functional_manager__in='+funmanager+this.ordering;
    if(this.from == 'Employehrletter' && this.filterForm.value.sortby=='All'){
        this.ordering1 = '&ordering=-created_at'
    }
    if(this.inputFormat == "Array" || this.from == 'Employehrletter'){
      this.dataFilter1 = 'company=['+company+']&businessunit=['+bu+']&department=['+dept+']&designation=['+desig+']&grade=['+
      grade+']&branch=['+branch+']&employment_status='+(emp_status?.length == 0 ? '[]' : JSON.stringify(emp_status))+'&employment_type='+(emp_type?.length == 0 ? '[]' :JSON.stringify(emp_type))+'&reporting_manager=['+
      repomanager+']&functional_manager=['+funmanager+']'+this.ordering1

      this.dataFilterArr = 'company__in='+company+'&businessunit__in='+bu+'&department__in='+dept+'&designation__in='+desig+'&branch__in='+branch+'&grade__in='+grade+'&employment_type__in='+emp_type+'&employment_status__in='+emp_status+'&reporting_manager__in='+repomanager+'&functional_manager__in='+funmanager+this.ordering1;
    }else if(this.from=='advancehr'){
      this.dataFilter1 = 'company=['+company+']&bu=['+bu+']&department=['+dept+']&designation=['+desig+']&grade=['+
      grade+']&branch=['+branch+']&employment_status='+(emp_status?.length == 0 ? '[]' : JSON.stringify(emp_status))+'&employment_type='+(emp_type?.length == 0 ? '[]' :JSON.stringify(emp_type))+'&expense_date='+(selectedDate!=null && selectedDate!="" ?JSON.stringify(selectedDate):JSON.stringify([]));
    }else if(this.from=='expensehr'){
      this.dataFilter1 = 'company=['+company+']&bu=['+bu+']&department=['+dept+']&designation=['+desig+']&grade=['+
      grade+']&branch=['+branch+']&employment_status='+(emp_status?.length == 0 ? '[]' : JSON.stringify(emp_status))+'&employment_type='+(emp_type?.length == 0 ? '[]' :JSON.stringify(emp_type))+'&'+selectedDate;
    }else{
      this.dataFilter1 = 'company__in='+company+'&businessunit__in='+bu+'&department__in='+dept+'&designation__in='+desig+'&branch__in='+branch+'&grade__in='+grade+'&employment_type__in='+emp_type+'&employment_status__in='+emp_status+'&reporting_manager__in='+repomanager+'&functional_manager__in='+funmanager+this.ordering1;
    }

    this.resultfn(this.dataFilter,this.dataFilter1,this.dataFilterArr);
  }

  get f() {return  this.filterForm.controls; }

  getCompanyList(){
    this.employeeService.getCompanyList().subscribe((res: any) => {
          this.companyDD = res;
        this.selectAllForDropdownItems(this.companyDD);
    });
  }

  getbulist(){
      this.employeeService.getBuList().subscribe((res: any) => {
        this.buDD = res;
        this.selectAllForDropdownItems(this.buDD);
      });
  }

  getDepList(){
    this.employeeService.getDepartmentList().subscribe((res: any) => {
          this.DepDD = res;
          this.selectAllForDropdownItems(this.DepDD);
    });
  }

  getDesignationList(){
    this.employeeService.getDesignationList().subscribe((res: any) => {
          this.DesignationDD = res;
          this.selectAllForDropdownItems(this.DesignationDD);
    });
  }
  getGradeList(){
    this.employeeService.getGradeList().subscribe((res: any) => {
          this.gradeDD = res;
          this.selectAllForDropdownItems(this.gradeDD);
    });
  }
  loader = false
   managerList(){
    this.loader = true
    this.employeeService.managerList().subscribe((res: any) => {

     for(let i=0;i<res.length;i++){
            res[i]['name'] = res[i]['first_name'];
            if(res[i]['first_name'] != null)
            res[i]['name'] = res[i]['first_name']+" ";
            if(res[i]['middle_name'] != null)
            res[i]['name'] += res[i]['middle_name']+" ";
            if(res[i]['last_name'] != null)
            res[i]['name']   += res[i]['last_name'];
            res[i]['name']   +=" ("+res[i]['employee_code']+")";

          }
          this.managerDD = res;
          this.funmanagerDD =res;
          this.selectAllForDropdownItems(this.managerDD);
          this.loader = false
    });
  }
  getBranchList(){
    this.employeeService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }

  clearcommonForm(item:any,index:number){
    if(item == "company"){
      let daata = this.filterForm.value.company;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        company: daata
      });
    } else if(item == "bu"){
      let daata = this.filterForm.value.bu;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        bu: daata
      });
    } else if(item == "department"){
      let daata = this.filterForm.value.department;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        department: daata
      });
    } else if(item == "designation"){
      let daata = this.filterForm.value.designation;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        designation: daata
      });
    } else if(item == "grade"){
      let daata = this.filterForm.value.grade;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        grade: daata
      });
    } else if(item == "branch"){
      let daata = this.filterForm.value.branch;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        branch: daata
      });
    }else if(item == "emp_status"){
      let daata = this.filterForm.value.emp_status;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        emp_status: daata
      });
    }else if(item == "emp_type"){
      let daata = this.filterForm.value.emp_type;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        emp_type: daata
      });
    }else if(item == "repomanager"){
      let daata = this.filterForm.value.repomanager;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        repomanager: daata
      });
    }else if(item == "funmanager"){
      let daata = this.filterForm.value.funmanager;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        funmanager: daata
      });
    }
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  disableDate(){
    return false;
  }

}
