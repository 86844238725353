<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{reportName}}</h5>
    <button class="btn-reset text-light-600" routerLink="/expense-report"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <!-- <form class=""> -->
    <div class="container-fluid p-32 form-container">
      <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
          <h3 class="mb-0">{{reportName}}</h3>
        </div>
        <div class="col-md-8 col-lg-6">
          <div class="d-flex justify-content-end  gap-16">
            <button class="btn-square btn btn-secondary" ngbPopover="Add column"
              popoverClass="popover-default popover-info" container="body" triggers="hover"
              (click)="addColumnFilter = true">
              <i class="icon-plus fs-14"></i>
            </button>
            <!-- Add columns end -->

            <button class="btn-square btn btn-secondary" (click)="viewDetail = true">
              <i class="icon-filter-left fs-13"></i>
            </button>
            <!-- <button class="btn flex-shrink-0 fs-14 fw-600 text-uppercase btn-outline-primary"
                                  (click)="scheduleReportFunction()">Schedule
                                  Report</button> -->
            <button class="btn flex-shrink-0 fs-10 px-4 fw-700 text-uppercase btn-primary" [disabled]="isLoading"
              (click)="export()">DOWNLOAD
              REPORT</button>
            <!-- </ng-container> -->
          </div>
        </div>
      </div>
      <app-table-loader *ngIf="isLoading"></app-table-loader>
      <div class="col-12" *ngIf="appliedFilter != 0 && !isLoading">
        <ul class="selected-values  ">
          <ng-container *ngFor="let filter of appliedFilter | keyvalue ">
            <li class="ng-value" *ngIf="filter.value != '' && filter.value != null && filter.value != 'null'">
              <ng-container
                *ngIf="filter.key == 'selected_date' || filter.key == 'transaction_date__range' || filter.key == 'leave_credit_for' || filter.key == 'transaction_date' || filter.key == 'leave_period' || filter.key == 'from_date' || filter.key == 'to_date';else all_data">
                <ng-container *ngIf="filter.value != 'this_month' && filter.value != 'last_month'" ;>
                  <!-- <ng-container
                    *ngIf="(filter.key == 'transaction_date' && (filter.value != 'last_month' || filter.value !='this_month')) || (filter.key == 'leave_credit_for' && (filter.value != 'last_month' || filter.value != 'this_month')) || filter.key == 'leave_period'">
                    {{removeUnderscoreAddUpperCase(filter.key)}} : {{this.appService.dateFormatDisplay(dateSplit[0])+' - '+this.appService.dateFormatDisplay(dateSplit[1])}}
                  </ng-container> -->
                  <!-- <ng-template #val>
                                    <span class="ng-value-label ">
                                        {{removeUnderscoreAddUpperCase(filter.key)}} :
                                        {{this.appService.dateFormatDisplay(filter.value)}}</span>
                                 </ng-template> -->
                  <ng-container
                    *ngIf="filter.key == 'selected_date' || filter.key == 'from_date' || filter.key == 'to_date'">
                    <span class="ng-value-label ">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{this.appService.dateFormatDisplay(filter.value)}}</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="filter.value =='last_month' || filter.value == 'this_month'">
                  <span class="ng-value-label ">
                    {{removeUnderscoreAddUpperCase(filter.key)}} : {{removeUnderscoreAddUpperCase((reportName == 'Unpaid/LOP Report' || reportName == 'Leave Availed Report' || reportName == 'Leave Credit Report') && filter.value == 'this_month' ? 'current_month' :
                    filter.value)}}</span>
                </ng-container>
              </ng-container>
              <ng-template #all_data>
                <ng-container
                  *ngIf="filter.key === 'pay_group' || filter.key === 'branch' || filter.key === 'bu' || filter.key === 'bussiness_unit' || filter.key === 'city' || filter.key === 'company' || filter.key === 'country' || filter.key === 'department' || filter.key === 'designation' || filter.key === 'grade' || filter.key === 'employee_list' || filter.key === 'state' || filter.key === 'employment_status' || filter.key === 'employee_type' || filter.key === 'employment_type' || filter.key == 'expense_policy' || filter.key == 'advance_policy' || filter.key == 'compnay' || filter.key == 'reimbursement_type' || filter.key == 'business_unit'; else singleData">
                  <span class="d-flex align-items-center p-0 "
                    *ngIf="(filter.key === 'company' || filter.key === 'compnay') ? tagMultiDataFunction (appliedFilter.company):''">

                    Company :
                    <img class="img-fluid rounded-circle sq-24 mx-1 " src="{{tagMultiData[0].logo}}">
                    {{tagMultiData[0].company_name}}

                    <ng-container *ngIf="tagMultiCnt != 0"> ... <span
                        class="flex-center radius-24 bg-dark-400 px-2">{{tagMultiCnt}}</span>
                    </ng-container>
                  </span>
                  <!-- Employee -->
                  <span class="d-flex align-items-center p-0 "
                    *ngIf="filter.key === 'employee_list' ? tagMultiDataFunction (appliedFilter.employee_list):''">

                    Employees :
                    <img class="img-fluid rounded-circle sq-24 mx-1 "
                      *ngIf="tagMultiData[0].profile_image !==null && tagMultiData[0].profile_image !== ''; else firstName"
                      src="{{tagMultiData[0].profile_image}}">
                    <ng-template #firstName>
                      <span
                        class="avatar-sm sq-24 mx-1 rounded-circle {{tagMultiData[0]?.color_code}}">{{this.appService.getFirstChar(tagMultiData[0].first_name+"
                        "+(tagMultiData[0].middle_name!=null?tagMultiData[0].middle_name+" ":" "
                        )+tagMultiData[0].last_name,1)}}
                      </span>
                    </ng-template>
                    {{ tagMultiData[0].first_name+" "+(tagMultiData[0].middle_name!=null?tagMultiData[0].middle_name+"
                    ":" "
                    )+tagMultiData[0].last_name+ " (" +
                    tagMultiData[0].employee_code + ")" }}
                    <ng-container *ngIf="tagMultiCnt != 0"> ... <span class="flex-center radius-24 bg-dark-400 px-2"
                        (click)="onPopoverClick($event)" [ngbPopover]="popContentMultilpe"
                        popoverClass="user-list multi-user" triggers="click" container="body">{{tagMultiCnt}}</span>
                    </ng-container>
                  </span>
                  <ng-template #popContentMultilpe>
                    <ul>
                      <ng-container *ngFor="let re of  appliedFilter.employee_list, let i = index">
                        <ng-container *ngIf="i >= 1">
                          <li *ngIf="re?.profile_image !='' && re?.profile_image !=null">
                            <img class="user-avatar" src="{{re?.profile_image}}">
                            <div class="user-details">
                              <span class="user-label">{{re?.first_name+'
                                '+(re?.middle_name!=null?re?.middle_name:'')+'
                                '+re?.last_name}}</span>
                              <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                            </div>
                          </li>
                          <li *ngIf="(re?.profile_image =='' || re?.profile_image ==null )">
                            <span class="user-avatar {{re?.color_code}}">{{this.appService.getFirstChar(re?.first_name+'
                              '+re?.last_name,2)}}</span>
                            <div class="user-details">
                              <span class="user-label">{{re?.first_name+'
                                '+(re?.middle_name!=null?re?.middle_name:'')+'
                                '+re?.last_name}}</span>
                              <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                            </div>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ul>
                  </ng-template>
                  <!-- Company Logo Integration Start -->
                  <span class="d-flex align-items-center p-0 "
                    *ngIf="filter.key != 'company' && filter.key !='compnay' && filter.key != 'employee_list'">

                    {{removeUnderscoreAddUpperCase(filter.key)}} :
                    {{tagMultiDataFunction(filter.value)}}
                    <ng-container *ngIf="tagMultiCnt != 0"> ... <span
                        class="flex-center radius-24 bg-dark-400 px-2">{{tagMultiCnt}}</span>
                    </ng-container>
                  </span>
                  <!-- Company Logo Integration End -->
                </ng-container>
                <ng-template #singleData>
                  <span class=""> {{removeUnderscoreAddUpperCase(filter.key)}} :
                    {{removeUnderscoreAddUpperCase(filter.value)}}</span>
                </ng-template>
              </ng-template>
              <span aria-hidden="true" class="ng-value-icon right m-0"
              *ngIf="isFilterVisible(reportName, filter.key)"
              (click)="handleTagClose(reportName, filter.key)">×</span>
            </li>
          </ng-container>
        </ul>
      </div>
      <div *ngIf="noData && !isLoading ;else all_data" class="row row-16">
        <div class="col-12">
          <div class="card card-c2 overflow-hidden">
            <!-- <span class="fw-500 fs-14">There are no data currently</span> -->
            <div class="table-responsive " style="max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));
            ">
              <table
                class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header td-width-15ch td-white-space"
                mat-table [dataSource]="apiData" matSort cdkDropList cdkDropListOrientation="horizontal"
                (cdkDropListDropped)="drop($event)">
                <ng-container *ngFor="let prop of displayedColumns">
                  <ng-container [matColumnDef]="prop">
                    <th mat-header-cell cdkDrag *matHeaderCellDef  class="th-sort">
                      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                      {{prop}}
                      <i class="fs-14 ms-8 icon-info-circle" *ngIf="hint_dict[prop]"
                        ngbPopover="{{hint_dict[prop]}}" popoverClass="popover-default popover-info"
                        container="body" triggers="hover"></i>
                      <i class="fs-12 ms-8 icon-th-sort" [ngClass]="{
                              'th-sort-ascend': sortProperty === prop && direction == 'asc',
                              'th-sort-descend': sortProperty === prop && direction == 'desc'
                          }"></i>
                    </th>

                    <td mat-cell *matCellDef="let element">
                      {{element[prop]}}
                    </td>
                  </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              </table>
              <div class="text-center card card-c2 flex-center p-16 bg-dark-500 m-12">No data found</div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #all_data>
        <div class="row row-16" *ngIf="!isLoading">
          <div class="col-12" *ngIf="alertShow">
            <div role="alert" class="alert alert-info   mb-0 pe-32 show">
              <p class="mb-0 fs-14 hstack ">Click and drag the header’s to change the column position
              </p>
              <div (click)="alertShow = false" class="btn-close fs-9 pointer"></div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 overflow-hidden">
              <div class="table-responsive " style="  max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));
              ">
                <table
                  class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header  td-width-15ch td-white-space"
                  mat-table [dataSource]="apiData" matSort cdkDropList cdkDropListOrientation="horizontal"
                  (cdkDropListDropped)="drop($event)">
                  <ng-container *ngFor="let prop of displayedColumns">
                    <ng-container [matColumnDef]="prop">
                      <th mat-header-cell cdkDrag *matHeaderCellDef (click)="onSort(prop)" class="th-sort">
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        {{prop}}
                        <i class="fs-14 ms-8 icon-info-circle" *ngIf="hint_dict[prop]"
                          ngbPopover="{{hint_dict[prop]}}" popoverClass="popover-default popover-info"
                          container="body" triggers="hover"></i>
                        <i class="fs-12 ms-8 icon-th-sort" [ngClass]="{
                                'th-sort-ascend': sortProperty === prop && direction == 'asc',
                                'th-sort-descend': sortProperty === prop && direction == 'desc'
                            }"></i>
                      </th>
                      <td mat-cell *matCellDef="let element" title="{{element[prop]}}">
                        {{element[prop]}}
                      </td>
                    </ng-container>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12"
            *ngIf="lastPageCount > 20 && lastPageCount > apiData?.filteredData?.length">
            <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPageCount" [maxSize]="3"
              [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
              (pageChange)="pageChanged($event)"></ngb-pagination>
          </div>
        </div>
      </ng-template>
    </div>
    <!-- </form> -->
  </main>
</div>

<!-- <ng-container *ngIf="viewDetail == true || title == 'Called'"> -->
<app-expense-main-filter #expenseMainComp [reportName]="reportName" [ordering]="ordering"
  [expenseMainForm]="expenseMainForm" [ouFilterForm]="OuFilterForm"
  [pageNumber]="pageNumber"
  [lastPage]="lastPageCount" [viewDetail]="viewDetail" (filterPanel)="panel($event)"
  [closeControl]="closeControl" [salFilter]="salFilter" [resDataFilter]="resDataFilter" (resetFilter)="resetFilterFn($event)"
  (callReports)="reportFnCall($event)" (submitDone)="FilterApiConfig($event)"></app-expense-main-filter>
<!-- </ng-container> -->

<div class="modal  modal-alert {{resetFilterPop == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Reset Report Filter?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">This reset report back to default filter. You'll have to change to get it back in the
          list.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="resetFilterPop = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="resetFilterModalClicked"
          (click)="resetFilterApply()">Reset</button>
      </div>
    </div>
  </div>
</div>
<div class="modal  modal-alert {{applyFilterPop == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="filterSaveForm" autocomplete="off">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Custom Filters Applied</h4>
          <button class="btn-reset  ms-auto" (click)="applyFilterPop = false"> <i class="icon-close-lg fs-16"></i></button>
        </div>
        <div class="modal-body">
          <div class="row row-16">
            <div class="col-12 form-row">
              <div class="form-check">
                <input name="filter" formControlName="filter" type="radio" id="filter1" class="form-check-input "
                  [value]="true" checked>
                <label for="filter1" class="form-check-label">Set the filters as default for the
                  report</label>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check ">
                <input name="filter" formControlName="filter" type="radio" id="filter2" class="form-check-input "
                  [value]="false">
                <label for="filter2" class="form-check-label">Don't set the filter as default</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase"
            [disabled]="saveFilterModalClicked" (click)="saveFilterModal()">Apply</button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- <div class="modal  modal-alert {{scheduleReport == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <form class="" [formGroup]="scheduleReportFilterMemorizeForm" autocomplete="off">
          <div class="modal-content">
              <div class="modal-header gap-16">
                  <h4 class="modal-title">Custom Filters Applied</h4>
                  <button class="btn-reset  ms-auto" (click)="scheduleReport = false"> <i
                          class="icon-close-lg fs-16"></i></button>
              </div>
              <div class="modal-body">
                  <div class="row row-16">
                      <div class="col-12 form-row">
                          <div class="form-check">
                              <input name="filter" formControlName="filter" type="radio" id="filter1"
                                  class="form-check-input " [value]="true" checked>
                              <label for="filter1" class="form-check-label">Schedule the default report without
                                  filters</label>
                          </div>
                      </div>
                      <div class="col-12 form-row">
                          <div class="form-check ">
                              <input name="filter" formControlName="filter" type="radio" id="filter2"
                                  class="form-check-input " [value]="false">
                              <label for="filter2" class="form-check-label">{{scheduleText}}</label>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase"
                      (click)="reportMemorizeFunction()">Schedule Report</button>
              </div>
          </div>
      </form>
  </div>
</div> -->

<!-- Add columns start -->
<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='addColumnFilter === true'>
  <form class="side-panel-container" autocomplete="off" *ngIf="sidePanelForm" [formGroup]="sidePanelForm">
    <header class="side-panel-head">
      <h5>Add columns</h5>
      <a class="toggle-panel" (click)="addColumnFilter = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 mb-16">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-8">Select Columns
            </p>
          </div>
          <!-- With Form setup -->
            <ng-container formArrayName="columnsFormArr">
              <div class="col-12 form-row" *ngFor="let item of columnsFormArrForm().controls; let i = index" [formGroupName]="i">
                <label for="check{{i}}" class="form-check p-0 d-flex">
                  <input type="checkbox" formControlName="checkbox" class="form-check-input ms-0" name="check" id="check{{i}}" (change)="onCheckboxChange(i)">
                  <label for="check{{i}}" class="form-check-label fs-16" style="user-select: none;">{{ item?.get('name')?.value }}</label>
                </label>
              </div>
            </ng-container>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class=" btn btn-outline-primary text-uppercase btn-sm"
        (click)="resetColumns(); addColumnFilter = false; addedColumns = false">
        RESET COLUMNS
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        (click)="headersApply(); addColumnFilter = false; addedColumns = true">
        SAVE COLUMNS
      </button>
    </footer>
  </form>
</div>
<!-- Add columns end -->
