import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { FormBuilder,Validators } from '@angular/forms';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-confirmation-req-approval',
  templateUrl: './confirmation-req-approval.component.html',
  styleUrls: ['./confirmation-req-approval.component.scss']
})
export class ConfirmationReqApprovalComponent implements OnInit {
    @Input() requestitem: any;
    @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
    alertToggle                     = false;
    terminateToggle                 = false;
    probationToggle                 = false;
    viewDetail                      = false;
    billLoader                      = false;
    viewstatusPanel                 = false;
    viewMapPanel          : boolean = false;
    confirmationDetails             = false;
    confirm                         = false;
    viewStatusData:any              = [];
    viewStatusLevelCnt:any;
    viewStatusmaxLevel:any;
    regenerate = false;
    Viewdata:any=[];
    loader1 =false;
    fromViewStatus                  = '';
    form = this.fb.group({
      extension_period: ['',[Validators.required,NumberpatternValidator(),Validators.max(365),Validators.min(1)]],
      comment        : ['', [Validators.required,Validators.maxLength(500)]],
    }) 

    formterminate = this.fb.group({
      comment        : ['', [Validators.required,Validators.maxLength(500)]],
    }) 

    formconfirm = this.fb.group({
      comment        : ['', [Validators.maxLength(500)]],
    }) 

    approverdata:any=[];
    prevdata:any=[];
    currentdata:any=[];
    disablebtn=false;
    disablebtnterminate = false; 
    
    constructor(private inboxservice: InboxService,
      private investmentService:InvestmentDeclerationService,
      public messageService: MessageService,
      public appService: AppService,
      private fb: FormBuilder,
      ) { }
  
    ngOnInit(): void {
      this.viewapprover(this.requestitem.request_data['id']);
    }
  
    performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
      let status_value;
      let dataArray: any = [];
  
      if (action == 3) {
        if (type == 1) {
          status_value = "Approved"
        } else {
          status_value = "Rejected"
        }
        dataArray = {
          "approved_status": status_value,
          "comment": comment_value,
  
        };
      } else if (action == 1) {
        if (read_status == false) {
          read_status = true;
          dataArray = {
            "read_status": read_status,
          };
        }
      } else if (action == 2) {
        if (star == false) {
          star = true;
        } else {
          star = false;
        }
        dataArray = {
          "star": star,
        };
      }
      if (dataArray.length != 0) {
        this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
        })
      }
    }
  
    requestViewStatus(){
      this.viewstatusPanel  = true
      this.billLoader       = true;
  
       this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
        this.viewStatusData     = res;
        this.billLoader         = false;
        this.fromViewStatus     = ( res?.request_type == 'Employee Profile' && res?.category == 'document') ? 'employee-inbox' : '';
        this.viewStatusLevelCnt = res?.activity?.level_count;
        this.viewStatusmaxLevel = res?.activity?.maxlevel;
       });
    }
     
    get f() {return  this.form.controls; }
    get ft() {return  this.formterminate.controls; }
    get fc() {return  this.formconfirm.controls; }

    Extendfn(){
      let extendlimit= this.requestitem?.requests?.confirmation_policy?.max_extension - this.requestitem?.requests?.probation_extended_count;
      if(extendlimit == 0 || extendlimit < 0){
        this.alertToggle =true;
      }else{
        this.probationToggle=true;
        this.form.get('extension_period')?.setValue(this.requestitem?.requests?.confirmation_policy?.extension_period);
      }
    }

    Extend(){
      this.disablebtn = true; 
      this.probationToggle=false;
      this.inboxservice.updateInboxItem(this.requestitem['id'],{
        'extension_period':this.form.controls['extension_period']?.value,
        'comment':this.form.controls['comment']?.value,
        'approved_status':'Extended Probation'
        }).subscribe((res: any)=>{ 
          this.successEmitter.emit(true);
        })
    }

    confirmemp(){
      this.disablebtn = true; 
      this.inboxservice.updateInboxItem(this.requestitem['id'],{
        'comment':this.formconfirm.controls['comment']?.value,
        'approved_status':'Confirmed'
       }).subscribe((res: any)=>{ 
        this.successEmitter.emit(true);
      })
    }

    terminate(){
      this.disablebtnterminate = true; 
      this.terminateToggle = false;
      this.inboxservice.updateInboxItem(this.requestitem['id'],{
        'comment':this.formterminate.controls['comment']?.value,
        'approved_status':'Terminated'
      }).subscribe((res: any)=>{ 
        this.successEmitter.emit(true);
      })
    }

    viewapprover(id:any){
    this.inboxservice.approverhistory(this.requestitem['id']).subscribe((res: any)=>{ 
      this.approverdata = res;
      this.approverdata.approved_person_list?.filter((obj :any) => {
        if(obj?.id == this.approverdata?.approved_person){
              this.currentdata.push(obj);
        }else{
              this.prevdata.push(obj);
        }
      })             
    })
    }

  }
  
