import { Component, OnInit } from '@angular/core';
import { ErrorLogService } from 'src/app/@core/services/error-log.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
import { FormBuilder,Validators} from '@angular/forms';
import { ApprovalMatrixService } from 'src/app/@core/services/approval-matrix.service';
import { AmountValidator } from 'src/app/@shared/validators/amount.validators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  filterpanel = false;
  showSidePanel     = false;
  errorData:any[]   = [];
  searchString:any  = "";
  page              = 1;
  pageSize          = 20;
  noDataFound       = false;
  loader            = true;
  errorEmpData:any  = [];
  view_loader       = false;
  permissions:any   = [];
  insideLoop        = false;
  statusBtn:any;
  selected: any;
  selected1: any;
  datafilter:any;
  submitted =false;
  ranges: any = {
                 'Today'       : [moment(), moment()],
                 'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                 'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
                 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                 'This Month'  : [moment().startOf('month'), moment().endOf('month')],
                 'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
               }
 isInvalidDate = (m: moment.Moment) =>  {
 }
 filterForm                = this.formBuilder.group({
  module               : null,
  status               : '',
  count                : ['',[Validators.maxLength(10), AmountValidator()]],
  occured_on           : '',
  corrected_on         : ''
}
);
status_list  = [
  {id:true,    name:"Corrected"},
  {id:false,    name:"Not corrected"},
];
module_list:any =[];
disabled=false;
constructor(private errorService:ErrorLogService,public appservice:AppService, public messageService : MessageService, private formBuilder: FormBuilder, private ams:ApprovalMatrixService,
    ) {

    this.errorService.error_count.subscribe(result =>{

      for(let i=0; i<this.errorData.length; i++){

        if(this.errorData[i]['id'] == result.event['id']){
          this.insideLoop                       = true;
          this.errorData[i]['employee_details'] = result.event.employee_details;
          this.errorData[i]['corrected_on']     = result.event.corrected_on;
          this.errorData[i]['status']           = result.event.status;
        }

      }
      if(result.event.employee_details.length==1 && this.insideLoop==false){

        this.errorData.unshift(result.event);

      }

    })
  }


  ngOnInit(): void {
    this.getPermission();
    this.getLogData();
    this.errorService.requestSocket();
    this.getmoduledata();
    this.selectAllForDropdownItems(this.status_list);

  }
  get f() { return this.filterForm.controls; }

  getmoduledata(){
    this.ams.moduleList().subscribe((res:any)=>{
    this.module_list = res;
    this.selectAllForDropdownItems(this.module_list);

    })
  }
  resetfilter(){
    this.searchString = '';
    this.filterpanel=false;
    this.filterForm.get('module')?.reset('');
    this.filterForm.get('status')?.reset('');
    this.filterForm.get('count')?.reset('');
    this.filterForm.get('occured_on')?.reset('');
    this.filterForm.get('corrected_on')?.reset('');
    this.datafilter = 'module__in=&status=&occured_on__range=[]&corrected_on__range=[]&effected_employee=';
  this.loader            = true;
  this.errorService.getAllErrorDatafilter(this.datafilter).subscribe((res:any)=>{
  this.loader            = false;
    if(res.length>0){

      this.errorData    = res;
      this.noDataFound  = false;
    }
    else
    this.noDataFound    = true;
  })
  }
  applyfilter(){
  this.submitted      = true;
  if (this.filterForm.invalid) {
    return;
  }
  this.filterpanel=false;
  let selectedDate:any;
  let selectedDate1:any;
  this.searchString = '';


  let module__in     = this.filterForm.controls.module.value;
  let status__in      = this.filterForm.controls.status.value;
  let count        = this.filterForm.controls.count.value;
  let occured_on__range       = this.filterForm.controls.occured_on.value;
  let corrected_on__range      = this.filterForm.controls.corrected_on.value;
  if(occured_on__range.startDate!=null && occured_on__range.startDate!=''){
    let start         = moment(occured_on__range.startDate._d).format('YYYY-MM-DD');
    let end           = moment(occured_on__range.endDate._d).format('YYYY-MM-DD');
    selectedDate      = [start,end];
  }
  if(corrected_on__range.startDate!=null && corrected_on__range.startDate!=''){
    let start1         = moment(corrected_on__range.startDate._d).format('YYYY-MM-DD');
    let end1           = moment(corrected_on__range.endDate._d).format('YYYY-MM-DD');
    selectedDate1      = [start1,end1];
  }
  this.datafilter = 'module__in='+module__in+'&status='+status__in+'&occured_on__range='+(selectedDate!=null && selectedDate!="" ?JSON.stringify(selectedDate):JSON.stringify([]))+'&effected_employee='+count+'&corrected_on__range='+(selectedDate1!=null && selectedDate1!="" ?JSON.stringify(selectedDate1):JSON.stringify([]));
  this.loader            = true;
  this.errorService.getAllErrorDatafilter(this.datafilter).subscribe((res:any)=>{
  this.loader            = false;
    if(res.length>0){

      this.errorData    = res;
      this.noDataFound  = false;
    }
    else
    this.noDataFound    = true;
  })
}
  getPermission(){
    this.permissions = this.appservice.getPermissions('');

    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getLogData(){
    this.loader            = true;
    this.errorService.getAllErrorData().subscribe((res:any)=>{
    this.loader            = false;
      if(res.length>0){

        this.errorData    = res;
        this.noDataFound  = false;
      }
      else
      this.noDataFound    = true;
    })
  }
  filter(data:any,status:boolean){
    this.loader            = true;
    this.errorService.getFilterErrorData(data,status).subscribe((res:any)=>{
      this.loader          = false;
      if(res.length>0){

        this.errorData    = res;
        this.noDataFound  = false;
      }
      else
      this.noDataFound    = true;

    })
  }
  errorAffectView(id:any,count:number){
    if(count>0){
      this.view_loader      = true;
      this.showSidePanel    = true;
      this.errorService.getErrorAffetctedEmp(id).subscribe((res:any)=>{
        this.errorEmpData   = res[0].employee_details;
        let len = this.errorEmpData.length;
        for (let i = 0; i < len; i++) {
          this.errorEmpData[i]['fullname']  = "";
          if(this.errorEmpData[i]['first_name'] != null)
           this.errorEmpData[i]['fullname'] += this.errorEmpData[i]['first_name']+" ";
          if(this.errorEmpData[i]['middle_name'] != null)
           this.errorEmpData[i]['fullname'] += this.errorEmpData[i]['middle_name']+" ";
          if(this.errorEmpData[i]['last_name'] != null)
           this.errorEmpData[i]['fullname'] += this.errorEmpData[i]['last_name'];

        }
        this.view_loader    = false;

      })
    }
  }


  clearcommonForm(item:any,index:number){
   if(item == "module"){
      let daata = this.filterForm.value.module;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        module: daata
      });
    } else if(item == "status"){
      let daata = this.filterForm.value.status;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        status: daata
      });
    }else if(item == "count"){
      let daata = this.filterForm.value.count;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        count: daata
      });
    }
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}


