<div class="container-fluid p-24 " *ngIf="permissions && !viewempmap && !viewDetail">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>{{header}} Location</h3>
            <p class="text-helper mb-md-0">{{subheader}}</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div class="form-icon " style="width:22.6875rem;">
                    <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='locsearch'
                        (searchEmitter)="searchresults($event)"
                        (keywordsearchEmitter)="searchlocation($event)" [isLoading]="sLoading"></app-searchbar>
                </div>

                <div ngbDropdown class="d-inline-block">
                    <button class=" btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
                        id="dropdownBasic1" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
                    (filterClose)="closefn($event)"  (resetCalled)="resetCalled($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
                </div>

                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" (click)="status='mapView'" name="btnradio" id="btnradio1"
                        autocomplete="off" [checked]='status=="mapView"'>
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 fw-700" for="btnradio1">MAP view</label>
                    <input type="radio" class="btn-check" (click)="status='listView'" name="btnradio" id="btnradio2" autocomplete="off" [checked]='status=="listView"'>
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 fw-700" for="btnradio2">LIST view</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-16">
        <app-c1-loader *ngIf="loader && status!='listView'"></app-c1-loader>
        <ng-container *ngIf="loader==false">
            <div class="col-12">
                <div class="card card-c2 px-24 py-16 card-hover indicater4 indicater-warning"
                    (click)="gpslostlist();viewDetail = true">
                    <div class=" hstack gap-16 align-items-center">
                        <div class="fs-32">{{GPSLostCnt}}</div>
                        <div class="fs-12 text-uppercase fw-500 text-light-500">EMPLOYEES HAVING BAD GPS SETTINGS
                            TODAY
                        </div>
                        <span class="text-accent1  ms-auto ">
                            <i class="icon-chevron-right-lg fs-18 "></i>
                        </span>

                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="status!='listView'">
                <agm-map style="height: 450px;" [latitude]="18.520430" [longitude]="73.856744" [zoom]="4" [styles]="styles" (mapReady)="onMapReady($event)">
                      <marker-cluster [imagePath]="'/assets/images/helper/map-img/map-cluster'" [imageExtension]="'png'" (clusterClick)="clickOnCluster($event)">
                        <ng-container *ngFor="let m of geolocationlist; let i = index">
                            <agm-overlay [latitude]="m.lat" [longitude]="m.long" [markerDraggable]="false" [title]="m.employee_id"
                           (mouseOver)=" showInfo(i)" (mouseOut)="hideInfo(i)" (markerClick)="openWindow(i)">
                                <div class="avatar-marker">
                                    <div *ngIf="m?.profile_image == '' || m?.profile_image == null"
                                        class="avatar-circle sq-36 fw-500 {{m?.color_code}}">
                                        {{m?.label}}
                                    </div>
                                    <img *ngIf="(m?.profile_image != null && m?.profile_image!='')" class="avatar-circle sq-36"
                                        src="{{m?.profile_image}}" alt="">
                                </div>

                                <agm-info-window  [isOpen]="isInfoWindowOpen(i)">
                                    <div class=" hstack align-items-start gap-16 overflow-hidden">
                                        <div *ngIf="m?.profile_image == '' || m?.profile_image == null"
                                            class="avatar-circle sq-64 fs-18 fw-400 {{m?.color_code}}">
                                            {{m?.label}}
                                        </div>
                                        <img *ngIf="(m?.profile_image != null && m?.profile_image!='')" class="avatar-circle sq-64 "
                                            src="{{m?.profile_image}}" alt="">
                                        <div class="vstack gap-5 " style="max-width: 20rem;">
                                            <p
                                                class="fs-12 fw-500 text-light-500 d-flex lh-base mb-0 align-items-baseline">
                                                <i class="icon-person1 text-accent1 me-8"></i>
                                                <span class="text-trim">{{m?.name}} </span>
                                                <span class="px-1">|</span>
                                                <span class="text-trim">{{m?.employee_code}}</span>
                                            </p>
                                            <p
                                                class="fs-12 fw-500 text-light-500 d-flex lh-base mb-0 align-items-baseline">
                                                <i class="bi bi-geo-alt text-accent1 me-8"></i>
                                                {{m.location}}
                                            </p>
                                            <p *ngIf="appService.isValidDateTime(m.current_datetime)"
                                                class="fs-12 fw-500 text-light-500 d-flex lh-base mb-0 align-items-baseline">
                                                <i class="icon-calendar text-accent1 me-8"></i>
                                                {{m?.current_datetime !=
                                                  ''?appService.datetimeconvert(m?.current_datetime):'--'}}
                                            </p>
                                        </div>
                                    </div>
                                </agm-info-window>
                        </agm-overlay>
                      </ng-container>
                    </marker-cluster>
                </agm-map>
            </div>
        </ng-container>
        <ng-container *ngIf="status!='mapView'">
            <div class="col-12">
                <div class="card card-c2">
                    <app-table-loader *ngIf="loader"></app-table-loader>
                    <div class="table-responsive radius-4" *ngIf="!loader">
                        <table
                            class="table sticky-header td-white-space  td-12 th-12 td-fs-14 tr-fs-14 sticky-last-col table-sm form-table">
                            <thead>
                                <tr>
                                    <th class="fw-600 text-uppercase" scope="col">Employee NAME</th>
                                    <th class="fw-600 text-uppercase" scope="col">EMPLOYEE CODE</th>
                                    <th class="fw-600 text-uppercase" scope="col">MARK IN DETAILS</th>
                                    <th class="fw-600 text-uppercase" scope="col">MARK IN LOCATION</th>
                                    <th class="fw-600 text-uppercase" scope="col">CURRENT LOCATION</th>
                                    <th class="fw-600 text-uppercase" scope="col">last updated date & time</th>
                                    <th class="fw-600 text-uppercase" scope="col">GPS</th>
                                    <th class="fw-600 text-uppercase" scope="col">VIEW MAP</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="nodata ">
                                <tr>
                                    <td colspan="9">

                                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                                            <span class="fw-500 fs-14">No data found</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="cell-16 " *ngIf="!nodata ">

                                <tr
                                    *ngFor='let locationdata of $any(geolocationlist| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize),let i = index'>
                                    <td>{{locationdata?.name}}</td>
                                    <td>{{locationdata?.employee_code}}</td>
                                    <td>{{locationdata?.mark_in_details !=  '' && appService.isValidDateTime(locationdata?.mark_in_details)?appService.datetimeconvert(locationdata?.mark_in_details):'--'}}</td>
                                    <td>{{locationdata?.mark_in_location != ''?locationdata?.mark_in_location:'--'}}
                                    </td>
                                    <td>{{locationdata?.current_lat_long != ''?locationdata?.current_lat_long:'--'}}
                                    </td>
                                    <td>{{locationdata?.current_datetime != ''  && appService.isValidDateTime(locationdata?.current_datetime)?appService.datetimeconvert(locationdata?.current_datetime):'--'}}</td>
                                    <td>{{locationdata?.gps_on_or_off}}</td>
                                    <td (click)="viewemployee(locationdata?.employee_id)"><i
                                            class="icon-eye fs-18 link-primary "></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="this.appService.filteredcount(geolocationlist,searchKeyword)>pageSize">
                <ngb-pagination class="d-flex justify-content-end"
                    [collectionSize]="this.appService.filteredcount(geolocationlist,searchKeyword)" [(page)]="page"
                    [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                    [pageSize]="pageSize"></ngb-pagination>
            </div>
        </ng-container>
    </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:27rem;"
    [class.side-pane-active]='viewEmployees === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Employees</h5>
            <a class="toggle-panel" (click)="viewEmployees = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">

                <div class="row row-24 ">
                    <div class="col-12 d-flex">
                        <span class="title">Employees mapped</span>
                    </div>
                    <div class="col-12">
                        <div class="form-icon icon-left w-100">
                            <i class="icon-search"></i>
                            <input type="text" class="form-control fs-14" name="" placeholder="Search "
                                [(ngModel)]="searchString" [ngModelOptions]="{standalone: true}" id="">
                        </div>
                    </div>
                    <div class=" col-12" *ngFor="let i of this.viewEmployeesList | filter:searchString">
                        <div class="card details-card card-c2 hstack align-items-center gap-16 p-24">
                            <!-- <img class="avatar-circle sq-56" src=" " /> -->
                            <img *ngIf="(i?.profile_image != null && i?.profile_image!='')" class="avatar-circle sq-64"
                                src="{{i?.profile_image}}" alt="">
                            <div *ngIf="(i?.profile_image == null || i?.profile_image=='')"
                                class="avatar-circle avatar-border {{i?.color_code}} sq-56 fs-14">{{i?.label}}</div>
                            <div class="vstack gap-3 ">
                                <div class="fs-14 fw-600">{{i?.name}} </div>
                                <div class="fs-12 fw-500 text-light-500">{{i?.designation}} | {{i?.employee_code}}</div>
                                <div class="fs-10 fw-500 text-light-500"><i
                                        class="bi bi-geo-alt text-accent1 me-8"></i>{{i?.location}}</div>
                                <div class="fs-10 fw-500 text-light-500"  *ngIf="appService.isValidDateTime(i?.current_datetime)"><i
                                        class="icon-calendar text-accent1 me-8"> </i>
                                        {{i?.current_datetime !=''?appService.datetimeconvert(i?.current_datetime):'--'}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-gps-lost-table *ngIf="viewDetail" [gpsdata]="gpsdata" [loadergps]="loadergps"
    (closepanel)="viewDetail=false"></app-gps-lost-table>
<app-employee-map-view *ngIf="viewempmap" [data]="viewdata" [origin]="origin" [destination]="destination"
    (closepanel)="viewempmap=false"></app-employee-map-view>
