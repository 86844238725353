<div class="container-fluid px-32 py-24">
    <div class="row row-16">
        <div class="col-12 d-flex gap-8  align-items-center">
            <span class=" text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
                <button class="btn-reset btn-star ms-auto text-light-500 fs-12"
                    [class.mail-stared]="requestitem['star']==true"
                    (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
                    <i class="bi toggle-star"></i>
                </button>
        </div>
        <div class="col-12 mt-8 card-ribbon">
            <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
                <h5 class="mb-8">{{requestitem.request_type}} </h5>
                <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
                    <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
                        *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
                    <span class="badge px-16 ms-auto fs-14 badge-success py-1"
                        *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
                    <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
                        *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
                    <!-- <span class="text-light-400">|</span>
                    <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span> -->
                </ng-container>
                <!-- Invalid request -->
                <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
                    class="ribbon ribbon-right ribbon-danger  ribbon-sm"
                    style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
            </div>
            <span *ngIf="!requestitem?.request_data?.is_cancel"
            class=" badge-custom {{requestitem.request_type_color_code}} ">{{requestitem.request_type}}</span>
            <span *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
                class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
        </div>
        <!-- <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar> -->
        <div class="col-12">
            <p class="mb-0 fs-14" [innerHTML]="requestitem?.message">
            </p>
        </div>
        <div class="col-12">
            <div class="card details-card shadow-0 p-16">
                <div class="row row-16">
                    <div class="col-12">
                        <p class="title mb-8">EMPLOYEE DETAILS</p>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Name</div>
                        <div class="detail-desc">{{requestitem?.requests?.employee?.first_name}} {{requestitem?.requests?.employee?.middle_name?requestitem?.requests?.employee?.middle_name:''}} {{requestitem?.requests?.employee?.last_name}}</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Employee code</div>
                        <div class="detail-desc">{{requestitem?.requests?.employee?.employee_code}}</div>
                    </div>
                <ng-container *ngIf="requestitem.request_type !='Confirmation Letter' && requestitem.request_type !='Termination Letter' && requestitem.request_type !='Probation Extension Letter'">
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Company</div>
                        <div class="detail-desc">{{requestitem?.requests?.employee?.company}}</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Department</div>
                        <div class="detail-desc">{{requestitem?.requests?.employee?.department}}</div>
                    </div>
                </ng-container>
                <ng-container  *ngIf="requestitem.request_type =='Confirmation Letter'">
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Date of joining</div>
                        <div class="detail-desc">{{requestitem?.requests?.date_of_joined}}</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Date of confirmation</div>
                        <div class="detail-desc">{{requestitem?.requests?.date_of_confirmation}}</div>
                    </div>
                </ng-container>
                <ng-container  *ngIf="requestitem.request_type =='Probation Extension Letter'">
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Date of joining</div>
                        <div class="detail-desc">{{requestitem?.requests?.date_of_joined}}</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Extension period(in days)</div>
                        <div class="detail-desc">{{requestitem?.requests?.confirmation_request?.extension_period}}</div>
                    </div>
                    <div class="col-sm-4 col-12">
                        <div class="detail-title">Reason for probation extension</div>
                        <div class="detail-desc">{{requestitem?.requests?.confirmation_request?.reason}}</div>
                    </div>
                </ng-container>
                <ng-container  *ngIf="requestitem.request_type =='Termination Letter'">
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Date of joining</div>
                        <div class="detail-desc">{{requestitem?.requests?.date_of_joined}}</div>
                    </div>
                    <div class="col-sm-4 col-12">
                        <div class="detail-title">Reason for Termination</div>
                        <div class="detail-desc">{{requestitem?.requests?.confirmation_request?.reason}}</div>
                    </div>
                </ng-container>
                <div class="col-12" *ngIf="requestitem.approved_status != 'Pending'">
                  <div class="detail-title">Remarks</div>
                  <div class="detail-desc">{{(requestitem?.requests?.remarks != null && requestitem?.requests?.remarks != '') ? requestitem?.requests?.remarks : '-'}}</div>
                </div>
                </div>
            </div>
        </div>

    <ng-container *ngIf="!requestitem?.achieved_status">
        <div class="col-12 d-flex gap-16 py-16 align-items-center position-sticky bottom-0 bg-white">
            <button type="button" class="btn btn-outline-primary btn-sm fw-400" (click)="regenerate=true">Generate letter</button>
        </div>
    </ng-container>
    </div>
</div>

<app-generate-letter *ngIf="regenerate== true" [regenerate]="regenerate" (panelclose)="closegen($event)" (regenerateop)="regeneratefn($event)" [from]="'inbox'" [Viewdata]="this.requestitem?.requests" [loader1]="loader1" [reqtype]="requestitem.request_type">
</app-generate-letter>

<ng-container *ngIf="viewstatusPanel">
    <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
      <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
          <h5>Activities</h5>
          <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
          [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"
          [from]="fromViewStatus"></app-view-status>

      </form>
    </div>
</ng-container>
