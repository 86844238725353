<div class="container-fluid px-32 py-24">
    <div class="row row-16">
        <div class="col-12">
            <div class="col-12 d-flex position-relative">
                <span class=" text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
                <button class="btn-reset btn-star ms-auto text-light-500 fs-12"
                    [class.mail-stared]="requestitem['star']==true"
                    (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
                    <i class="bi toggle-star"></i>
                </button>
            </div>
            <div class="col-12 mt-8 card-ribbon">
                <div class="w-100 d-flex align-items-center gap-8">
                    <h5 class="mb-8" *ngIf="!requestitem?.request_data?.is_cancel">Work From Home Request Approval </h5>
                    <h5 class="mb-8" *ngIf="requestitem?.request_data?.is_cancel">Work From Home Cancellation Request
                        Approval</h5>
                    <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
                        <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
                            *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
                        <span class="badge px-16 ms-auto fs-14 badge-success py-1"
                            *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
                        <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
                            *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
                        <span class="text-light-400">|</span>
                        <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
                    </ng-container>
                    <!-- Invalid request -->
                    <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
                        class="ribbon ribbon-right ribbon-danger  ribbon-sm"
                        style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
                </div>
                <span *ngIf="!requestitem?.request_data?.is_cancel"
                    class=" badge-custom {{requestitem.request_type_color_code}} ">WFH Request</span>
                <span *ngIf="requestitem?.request_data?.is_cancel"
                    class=" badge-custom {{requestitem.request_type_color_code}} ">WFH cancellation request</span>
                <span
                    *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
                    class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
            </div>
        </div>
        <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar>
        <div class="col-12">
            <p class="mb-0 fs-14" [innerHTML]="requestitem?.message">
            </p>
        </div>

        <!-- OT and Comp off Validation -->
        <div *ngIf="warningMsgToggle" class="col-12">
            <div role="alert" class="alert alert-warning show">
                <p class="mb-0 fs-12
            ">{{warningMsg}}</p>
                <div class="btn-close pointer" (click)="warningMsgToggle=false"></div>
            </div>
        </div>
        <!-- End -->

        <div class="col-12">
            <div class="row row-8">
                <div class="col-12">
                    <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                        <div class="row row-16">
                            <div class="col-12 d-flex mb-8">
                                <span class="title">Work from home details</span>
                                <span class="text-accent2 pointer ms-auto fs-12 fw-500"
                                    (click)="timeOffTrend = true;this.getwfhtrendDetails(this.currentYear);"><i
                                        class="bi bi-graph-up me-1"></i>Time off
                                    Trend</span>
                            </div>
                            <app-table-loader *ngIf="loader"></app-table-loader>
                            <ng-container *ngIf="!loader">
                                <div class="col-12">
                                    <div class="card card-c2">
                                        <div class="card-body p-16">
                                            <div class="d-flex align-items-center gap-16">
                                                <div class=" bg5 flex-center flex-column radius-4 sq-88  ">
                                                    <div class="fw-500 fs-24 text-uppercase lh-1">{{totday}}</div>
                                                    <div class="fs-14">days</div>
                                                </div>
                                                <div class="d-flex flex-column gap-8">
                                                    <div class="fs-14 ">Work From Home</div>
                                                    <div class="fs-14 fw-500">
                                                        {{this.appService.dateFormatDisplay(requestitem?.requests?.start_date)}}
                                                        -
                                                        {{this.appService.dateFormatDisplay(requestitem?.requests?.end_date)}}
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap ms-auto gap-16 fs-12">
                                                    <div class="d-flex align-items-center gap-8">
                                                        <span class="type-indicator sq-12 type-holiday"></span>Holiday
                                                    </div>
                                                    <div class="d-flex align-items-center gap-8">
                                                        <span class="type-indicator sq-12 type-weekoff"></span>Weekoff
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body p-16 overflow-auto" style="max-height: 235px;">
                                            <div class="row row-8">
                                                <div class="col-12"
                                                    *ngFor="let request of $any(requestitem?.requests?.requests),let ind = index">
                                                    <div class="card card-c2 pointer">
                                                        <div
                                                            class="card-body d-flex align-items-center gap-16 indicater px-16 py-8">
                                                            <span
                                                                class="fw-500 ">{{this.appService.dateFormatDisplay(request?.wfh_date)}}</span>
                                                            <span class="fs-10">( {{request?.shift_start_time}} -
                                                                {{request?.shift_end_time}} )</span>
                                                            <span class="fs-10">( {{request?.shift_name}} )</span>
                                                            <span class="type-indicator sq-12 type-holiday"
                                                                *ngIf="request?.holiday_status"></span>
                                                            <span class="type-indicator sq-12 type-weekoff"
                                                                *ngIf="request?.weekoff_status"></span>
                                                            <span
                                                                class="ms-auto fw-500 fs-12 text-accent1">{{request?.day_portion}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Reason</div>
                                    <div class="detail-desc">{{requestitem?.requests?.reason}}</div>
                                </div>
                                <div *ngIf="requestitem?.request_data?.is_cancel == true || requestitem?.requests?.status == 'Cancelled'"
                                    class="col-12">
                                    <div class="detail-title">Reason for cancellation</div>
                                    <div class="detail-desc">{{requestitem?.requests?.remark}}</div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container [formGroup]="form" *ngIf="!requestitem?.achieved_status">
            <div class="col-12">
                <textarea name="" id="" rows="3" placeholder="Comment here..." formControlName="comment"
                    class="form-control my-12 textarea-comment"
                    [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                <div *ngIf="f.comment.errors" class="invalid-feedback">
                    <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex gap-16">
                <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                    (click)="confirm('Rejected')">Decline</button>
                <button class=" btn btn-primary text-uppercase btn-sm fw-400"
                    (click)="confirm('Approved')">Confirm</button>
            </div>
        </ng-container>

    </div>
</div>
<!-- Trend Graph Sidebar -->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='timeOffTrend === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5> Time off trend</h5>
            <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-24">
                    <div class="col-12 ">
                        <div class="card card-c2 pb-24  px-24 pt-24">
                            <div class="row row-12">
                                <div class="col-12 d-flex">
                                    <div class="fs-24 me-auto">Time off trend - {{selectedYearDisp}} </div>
                                    <ng-select class="form-ngselect ms-auto fs-14" [clearable]="false"
                                        placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                                        (change)="getwfhtrendDetails($event)" [(ngModel)]="selectedYearDisp"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option class="fs-12" *ngFor="let year of yearList"
                                            [value]="year.k">{{year.k}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-12" *ngIf="!graphLoader">
                                    <!-- <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="multi"
                                        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                                        [legend]="showLegend" [legendPosition]="legendPosition"
                                        [legendTitle]="legendTitle" [barPadding]="barPadding"
                                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [animations]="animations"
                                        (select)="onSelect($event)">
                                    </ngx-charts-bar-vertical-stacked> -->
                                    <app-bar-chart [data]="multi" [labels]="xAxisLabel"
                                        [yText]="yAxisLabel"></app-bar-chart>
                                </div>
                                <div class="col-12" *ngIf="graphLoader">

                                    <app-graph-loader></app-graph-loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
    <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
        <form class="side-panel-container" autocomplete="off">
            <header class="side-panel-head">
                <h5>Activities</h5>
                <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
            </header>

            <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
                [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"
                [from]="'reimbursement-inbox'"></app-view-status>



        </form>
    </div>
</ng-container>