import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
@Component({
  selector: 'app-expense-report-details',
  templateUrl: './expense-report-details.component.html',
  styleUrls: ['./expense-report-details.component.scss']
})

export class ExpenseReportDetailsComponent implements OnInit {

  @Input() reportListData:any;
  @Input() reportSearchData:any;
  @Input() reportListLoader:any;
  @Input() currency:any;

  @Input()formGp : FormGroup = this.fb.group({

  })

  @Output() searchemit           = new EventEmitter();
  @Output() submitmethod         = new EventEmitter();
  @Output() setAdvanceAndReceipt = new EventEmitter();

  searchkey     = this.messageService.searchdisplay('report');
  searchKeyword = "";

  newReport     = false;

  constructor(
    public fb:FormBuilder,
    public appService: AppService,
    public messageService : MessageService,
    private service:ExpenseReportRequestService,
  ) { }

  ngOnInit(): void {
  }

  setReport(i:any,index:any){
    this.formGp.get('id')?.setValue(i?.id);
    this.formGp.get('name')?.setValue(i?.name);
    this.formGp.get('business_purpose')?.setValue(i?.business_purpose);
    this.formGp.get('from_date')?.setValue(i?.from_date);
    this.formGp.get('to_date')?.setValue(i?.to_date);
    this.formGp.get('total_amount')?.setValue(i?.total_amount);
    this.formGp.get('to_reimbursed')?.setValue(i?.to_reimbursed);
    this.formGp.get('status')?.setValue(i?.status);
    this.formGp.get('index')?.setValue(index);
    this.setAdvanceAndReceipt?.emit({'advances': i?.advances, 'receipts':i?.receipts})
  }

  searchresults(event:any){
    this.reportListData   = [];
    this.reportSearchData = [];
    if(event == ""){
      this.searchKeyword  = "";
      this.searchemit.emit({'search':this.searchKeyword});
     }
    else{
      this.reportListLoader   = true;
      this.service.getSingleReport(event).subscribe((res: any) => {
        this.reportListData   = [res];
        this.reportSearchData = res;
        this.reportListLoader = false;
      });
    }
  }

  searchemp(searchKeyword:any){
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchemit.emit({'search':this.searchKeyword});
    }

  }

  get f() {return  this.formGp.controls; }

  submitreport(){
    this.newReport = false;
    this.searchemit.emit({'search':this.searchKeyword});
  }

  closereport(){
    this.newReport = false;
  }

  changeTab(){
    if(this.formGp.invalid)
      return
    this.submitmethod.emit();
  }

}
