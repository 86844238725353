import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestRoutingModule } from './request-routing.module';
import { RequestComponent } from './request/request.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { LeaveRequestSetupComponent } from './leave/leave-request-setup/leave-request-setup.component';
import { LeaveRequestComponent } from './leave/leave-request/leave-request.component';
import { LrLeaveDetailsComponent } from './leave/leave-request-setup/lr-leave-details/lr-leave-details.component';
import { LrLeaveSummaryComponent } from './leave/leave-request-setup/lr-leave-summary/lr-leave-summary.component';
import { OnDutySetupComponent } from './on-duty/on-duty-setup/on-duty-setup.component';
import { OdEditDetailsComponent } from './on-duty/on-duty-setup/od-edit-details/od-edit-details.component';
import { RegularizationSetupComponent } from './regularization/regularization-setup/regularization-setup.component';
import { RegularizationEditDetailsComponent } from './regularization/regularization-setup/regularization-edit-details/regularization-edit-details.component';
import { RegularizationSelectDateComponent } from './regularization/regularization-setup/regularization-select-date/regularization-select-date.component';
import { OdSelectDateComponent } from './on-duty/on-duty-setup/od-select-date/od-select-date.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ProposedInvestmentDeclerationSetupComponent } from './investment-decleration/proposed-investment-decleration-setup/proposed-investment-decleration-setup.component';
import { ConfirmedInvestmentDeclerationSetupComponent } from './investment-decleration/confirmed-investment-decleration-setup/confirmed-investment-decleration-setup.component';
import { ProposedHra80GGComponent } from './investment-decleration/proposed-investment-decleration-setup/proposed-hra80-gg/proposed-hra80-gg.component';
import { ProposedChapt2Component } from './investment-decleration/proposed-investment-decleration-setup/proposed-chapt2/proposed-chapt2.component';
import { ProposedDeductionsComponent } from './investment-decleration/proposed-investment-decleration-setup/proposed-deductions/proposed-deductions.component';
import { ProposedOtherIncomeComponent } from './investment-decleration/proposed-investment-decleration-setup/proposed-other-income/proposed-other-income.component';
import { ConfirmedHra80GgComponent } from './investment-decleration/confirmed-investment-decleration-setup/confirmed-hra80-gg/confirmed-hra80-gg.component';
import { ConfirmedChapt2Component } from './investment-decleration/confirmed-investment-decleration-setup/confirmed-chapt2/confirmed-chapt2.component';
import { ConfirmedDeductionsComponent } from './investment-decleration/confirmed-investment-decleration-setup/confirmed-deductions/confirmed-deductions.component';
import { ConfirmedOtherIncomeComponent } from './investment-decleration/confirmed-investment-decleration-setup/confirmed-other-income/confirmed-other-income.component';
import { InvestmentDeclerationViewComponent } from './investment-decleration/investment-decleration-view/investment-decleration-view.component';
import { HolidaySetupComponent } from './holiday-request/holiday-setup/holiday-setup.component';
import { HolidayRequestViewComponent } from './holiday-request/holiday-request-view/holiday-request-view.component';
import { ProposedIncomelossPropertyComponent } from './investment-decleration/proposed-investment-decleration-setup/proposed-incomeloss-property/proposed-incomeloss-property.component';
import { ProposedRegimeComparisonComponent } from './investment-decleration/proposed-investment-decleration-setup/proposed-regime-comparison/proposed-regime-comparison.component';
import { ReimbursementSetupComponent } from './reimbursement/reimbursement-setup/reimbursement-setup.component';
import { ReimbursementCATEGORYComponent } from './reimbursement/reimbursement-setup/reimbursement-category/reimbursement-category.component';
import { ReimbursementDetailsComponent } from './reimbursement/reimbursement-setup/reimbursement-details/reimbursement-details.component';
import { ReimbursementSummaryComponent } from './reimbursement/reimbursement-setup/reimbursement-summary/reimbursement-summary.component';
import { ConfirmedIncomelossPropertyComponent } from './investment-decleration/confirmed-investment-decleration-setup/confirmed-incomeloss-property/confirmed-incomeloss-property.component';
import { ConfirmedRegimeComparisonComponent } from './investment-decleration/confirmed-investment-decleration-setup/confirmed-regime-comparison/confirmed-regime-comparison.component';
import { ReimbursementViewComponent } from './reimbursement/reimbursement-view/reimbursement-view.component';
import { FbpDeclarationViewComponent } from './fbp-declaration/fbp-declaration-view/fbp-declaration-view.component';
import { FbpDeclarationSetupComponent } from './fbp-declaration/fbp-declaration-setup/fbp-declaration-setup.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { WorkFromHomeSetupComponent } from './work-from-home/work-from-home-setup/work-from-home-setup.component';
import { WorkFromHomeRequestComponent } from './work-from-home/work-from-home-setup/work-from-home-request/work-from-home-request.component';
import { WorkFromHomeSummaryComponent } from './work-from-home/work-from-home-setup/work-from-home-summary/work-from-home-summary.component';
import { WorkFromHomeComponent } from './work-from-home/work-from-home/work-from-home.component';
import { CompensatoryOffSetupComponent } from './compensatory-off/compensatory-off-setup/compensatory-off-setup.component';
import { IgxTimePickerModule } from 'igniteui-angular';
import { ShortLeaveSummaryComponent } from './short-leave-request-setup/short-leave-summary/short-leave-summary.component';
import { ShortLeaveRequestComponent } from './short-leave-request-setup/short-leave-request/short-leave-request.component';
import { ShortLeaveRequestSetupComponent } from './short-leave-request-setup/short-leave-request-setup.component';
import { AssetRequestComponent } from './asset-request/asset-request.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';



FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);
@NgModule({
  declarations: [
    RequestComponent,
    LeaveRequestSetupComponent,
    LeaveRequestComponent,
    LrLeaveDetailsComponent,
    LrLeaveSummaryComponent,
    OnDutySetupComponent,
    OdSelectDateComponent,
    OdEditDetailsComponent,
    ProposedInvestmentDeclerationSetupComponent,
    ConfirmedInvestmentDeclerationSetupComponent,
    ProposedHra80GGComponent,
    ProposedChapt2Component,
    ProposedDeductionsComponent,
    ProposedOtherIncomeComponent,
    ConfirmedHra80GgComponent,
    ConfirmedChapt2Component,
    ConfirmedDeductionsComponent,
    ConfirmedOtherIncomeComponent,
    InvestmentDeclerationViewComponent,
    OdSelectDateComponent,
    OdEditDetailsComponent,
    RegularizationSetupComponent,
    RegularizationEditDetailsComponent,
    RegularizationSelectDateComponent,
    HolidaySetupComponent,
    HolidayRequestViewComponent,
    ProposedIncomelossPropertyComponent,
    ProposedRegimeComparisonComponent,
    ReimbursementSetupComponent,
    ReimbursementCATEGORYComponent,
    ReimbursementDetailsComponent,
    ReimbursementSummaryComponent,
    ConfirmedIncomelossPropertyComponent,
    ConfirmedRegimeComparisonComponent,
    ReimbursementViewComponent,
    FbpDeclarationViewComponent,
    FbpDeclarationSetupComponent,
    WorkFromHomeSetupComponent,
    WorkFromHomeRequestComponent,
    WorkFromHomeSummaryComponent,
    WorkFromHomeComponent,
    CompensatoryOffSetupComponent,
    ShortLeaveSummaryComponent,
    ShortLeaveRequestComponent,
    ShortLeaveRequestSetupComponent,
	AssetRequestComponent
    ],
  imports: [
    CommonModule,
    RequestRoutingModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    MasterSelectModule,
    InfiniteScrollModule,
    PdfViewerModule,
    AngularMyDatePickerModule,
    MatDatepickerModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
    FullCalendarModule,
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MMM/YYYY',
      clearLabel: 'Cancel'
    }),
    IgxTimePickerModule,
    Ng2SearchPipeModule,
  ],
  exports: [HolidaySetupComponent, FbpDeclarationSetupComponent]
})
export class RequestModule { }
