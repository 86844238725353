<!-- test start -->
<div class="row row-16">
<!-- OLD REGIME -->
<ng-container *ngIf="!oldNewFlag">
  <div class="col-12">
    <div class="fw-600 fs-18">Earnings</div>
  </div>
  <div class="col-12">
    <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel id="ngb-panel-0">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">
            Gross
            <div class="ms-auto fw-500">{{this.taxRegimeData?.earnings?.gross | currency: currency}}</div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="d-flex flex-column gap-12 fs-14">
            <ng-container *ngFor="let salaryBreak of taxRegimeData?.earnings?.salary_break_down | keyvalue">
              <div class="d-flex w-100">{{salaryBreak?.key}}<div class="ms-auto ">{{stringToNumConversion(salaryBreak.value) | currency: currency}}</div>
              </div>
            </ng-container>

          </div>
        </ng-template>
      </ngb-panel>

    </ngb-accordion>
  </div>
  <div class="col-12 mt-8" *ngFor="let otherSource of taxRegimeData?.earnings?.income_from_othersource?.income_from_othersource_input_data | keyvalue">
    <div class="box-1 fw-500 bg-body"> <div class="me-32 "> {{fieldName(otherSource?.key)}}</div> <div class="ms-auto ">{{stringToNumConversion(otherSource?.value) | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total exemptions under income from other sources</div> <div class="ms-auto ">{{taxRegimeData?.earnings?.income_from_othersource?.income_from_othersource | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 bg-body"><div class="me-32 ">Income from previous employer</div> <div class="ms-auto ">{{this.taxRegimeData?.earnings?.prev_employer_income | currency: currency}} </div>
    </div>
  </div>

  <!-- Income/Loss from Property start -->
  <div class="col-12">
    <div class="fw-500">Income/Loss from Property</div>
  </div>
  <ng-container *ngIf="(taxRegimeData?.earnings?.incomeloss_property | keyvalue)?.length">
    <div class="col-12 mt-8" *ngIf="(taxRegimeData?.earnings?.incomeloss_property?.income_from_self_occupiedproperty?.incomeself_lender | keyvalue)?.length">
      <div class="box-1 fw-500 bg-body"><div class="me-32 ">Interest on Housing Loan (Self Occupied) </div> <div class="ms-auto ">{{taxRegimeData?.earnings?.incomeloss_property?.income_from_self_occupiedproperty?.incomeself_lender_amount | currency: currency}} </div>
      </div>
    </div>

    <div class="col-12" *ngIf="(taxRegimeData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender | keyvalue)?.length">
      <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
        <ngb-panel id="ngb-panel-0">
          <ng-template ngbPanelHeader>
            <button ngbPanelToggle class="accordion-button">
              Income from let-out property
              <div class="ms-auto fw-500">{{taxRegimeData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender_net_amount | currency: currency}}</div>
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="d-flex flex-column gap-12 fs-14">
              <ng-container *ngFor="let incomeLess of taxRegimeData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender,let i = index">
                <div class="d-flex w-100">Property {{(i+1)}}<div class="ms-auto ">{{incomeLess?.net_income_houseproperty | currency: currency}}</div>
                </div>
              </ng-container>

            </div>
          </ng-template>
        </ngb-panel>

      </ngb-accordion>
    </div>
  </ng-container>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total income/loss from property</div> <div class="ms-auto ">{{taxRegimeData?.earnings?.incomeloss_property?.total_income_loss_property | currency: currency}} </div>
    </div>
  </div>
  <!-- Income/Loss from Property end -->

  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total income FY {{this.appservice.getFinancialYearRange(currentYear)}}</div> <div class="ms-auto ">{{taxRegimeData?.earnings?.total_taxable_income | currency: currency}} </div>
    </div>
  </div>
  <!-- NEW -->
  <div class="col-12 mt-24">
    <div class="fw-600 fs-18">Exemptions under Section 16</div>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 bg-body"><div class="me-32 ">Professional tax</div> <div class="ms-auto ">{{this.taxRegimeData?.excemption_under_section16?.professional_tax | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 bg-body"><div class="me-32 ">Standard deduction</div> <div class="ms-auto ">{{this.taxRegimeData?.excemption_under_section16?.standard_deduction | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total exemptions under section 16 </div><div class="ms-auto ">{{taxRegimeData?.taxable_income?.excemption_under_section16 | currency: currency}} </div>
    </div>
  </div>
  <!-- END -->
  <div class="col-12 mt-24">
    <div class="fw-600 fs-18">Exemptions based on declaration</div>
  </div>
  <div class="col-12">
    <div class="d-flex w-100 fs-14">Particulars<div class="ms-auto ">Declared amount</div>
    </div>
  </div>
  <div class="col-12">
    <hr class="hr-1">
  </div>
  <div class="col-12">
    <div class="fw-500">Exemptions (section 10) </div>
  </div>
  <div class="col-12"  *ngIf="hra80gg == 'hra'">
    <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel id="ngb-panel-0">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">
            HRA
            <div class="ms-auto fw-500">{{taxRegimeData?.excemption_declaration?.hra | currency: currency}}</div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="d-flex flex-column gap-12 fs-14" >
            <div *ngFor="let rentData of taxRegimeData?.excemption_declaration?.rent_data | keyvalue" class="d-flex w-100">{{removeUnderscoreAddUpperCase(rentData.key)}}<div class="ms-auto ">{{stringToNumConversion(rentData?.value) | currency: currency}}</div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="col-12" *ngIf="hra80gg == '80gg'">
    <div class="box-1 fw-500 bg-body"><div class="me-32 ">80GG</div> <div class="ms-auto ">{{taxRegimeData?.excemption_declaration?.['80gg'] | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12" *ngIf="this.taxRegimeData?.excemption_declaration?.Child_Education_Allowance">
    <div class="box-1 fw-500 bg-body"><div class="me-32 ">Children education allowance</div> <div class="ms-auto ">{{this.taxRegimeData?.excemption_declaration?.Child_Education_Allowance | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12 mt-8" *ngIf="this.taxRegimeData?.excemption_declaration?.Child_Hostel_Allowance">
    <div class="box-1 fw-500 bg-body"><div class="me-32 ">Children hostel allowance</div> <div class="ms-auto ">{{this.taxRegimeData?.excemption_declaration?.Child_Hostel_Allowance | currency: currency}} </div>
    </div>
  </div>

  <!-- Reimbursement component wise start -->
  <div class="col-12" *ngIf="taxRegimeData?.excemption_declaration?.Reimbursement_Bill_Amount?.total_bill_amount > 0">
    <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel id="ngb-panel-0">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">
            Reimbursement
            <div class="ms-auto fw-500">{{taxRegimeData?.excemption_declaration?.Reimbursement_Bill_Amount?.total_bill_amount | currency: currency}}</div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="d-flex flex-column gap-12 fs-14" >
            <div *ngFor="let billAmt of taxRegimeData?.excemption_declaration?.Reimbursement_Bill_Amount?.accepted_bills_dict | keyvalue" class="d-flex w-100">{{(billAmt.key)}}<div class="ms-auto ">{{stringToNumConversion(billAmt?.value) | currency: currency}}</div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <!-- Reimbursement component wise end -->

  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total expemtions under section 10</div> <div class="ms-auto ">{{this.taxRegimeData?.excemption_declaration?.total_excemption | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12">
    <div class="fw-500">Chapter VI A - 80 C</div>
  </div>
  <div class="col-12 mt-8" *ngFor="let chapter4a of taxRegimeData?.chapter4a80c?.chapter4a80c_input_data | keyvalue">
    <div class="box-1 fw-500 bg-body"><div class="me-32 ">{{chapter4aFieldName(chapter4a?.key)}}</div> <div class="ms-auto ">{{stringToNumConversion(chapter4a?.value) | currency: currency}} </div>
    </div>
  </div>

  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total exemptions under chapter VI A - 80C</div> <div class="ms-auto ">{{taxRegimeData?.chapter4a80c?.chapter4a80c_data | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12">
    <div class="fw-500">Chapter VI A - deductions</div>
  </div>

  <div class="col-12 mt-8" *ngFor="let deduction of taxRegimeData?.chapter4adeduction?.chapter4adeduction_input_data | keyvalue">
    <div class="box-1 fw-500 bg-body"><div class="me-32 ">{{deductionFieldName(deduction?.key)}} </div><div class="ms-auto ">{{stringToNumConversion(deduction?.value) | currency: currency}} </div>
    </div>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total exemptions under chapter VI A - deductions</div> <div class="ms-auto ">{{taxRegimeData?.chapter4adeduction?.chapter4adeduction_data | currency: currency}} </div>
    </div>
  </div>
  <!-- NEW -->

  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total exemptions based on declration</div> <div class="ms-auto ">{{taxRegimeData?.excemption_based_on_declaration | currency: currency}} </div>
    </div>
  </div>
  <!-- END -->

  <!-- NEW -->
  <div class="col-12">
    <div class="fw-600 fs-18">Taxable Income </div>
  </div>
  <div class="col-12">
    <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel id="ngb-panel-0">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">
            Taxable Income details
            <div class="ms-auto fw-500">{{taxRegimeData?.taxable_income?.total_taxable_income_in_FY | currency: currency}}</div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="d-flex flex-column gap-12 fs-14">
            <div class="d-flex w-100">Earning’s<div class="ms-auto ">{{taxRegimeData?.taxable_income?.earnings | currency: currency}}</div>
            </div>
            <div class="d-flex w-100">Exemption’s under section 16<div class="ms-auto text-danger">{{taxRegimeData?.taxable_income?.excemption_under_section16 == 0 ? (0| currency: currency) : (-(taxRegimeData?.taxable_income?.excemption_under_section16)| currency: currency)}}</div>
            </div>
            <div class="d-flex w-100">Exemption’s based on declaration<div class="ms-auto text-danger">{{(taxRegimeData?.taxable_income?.excemption_based_on_declaration == 0 ? (0| currency: currency) : (-(taxRegimeData?.taxable_income?.excemption_based_on_declaration)| currency: currency))}}</div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total tax income FY {{this.appservice.getFinancialYearRange(currentYear)}} </div><div class="ms-auto ">{{taxRegimeData?.taxable_income?.total_taxable_income_in_FY | currency: currency}}</div>
    </div>
  </div>
  <!-- END -->

  <div class="col-12">
    <div class="fw-600 fs-18">Tax </div>
  </div>
  <div class="col-12">
    <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel id="ngb-panel-0">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">
            Tax details
            <div class="ms-auto fw-500">{{taxRegimeData?.tax_details?.total_payable_tax | currency: currency}}</div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="d-flex flex-column gap-12 fs-14">
            <div class="d-flex w-100">Raw tax on total taxable income<div class="ms-auto ">{{taxRegimeData?.tax_details?.Tax_on_total_taxable_income | currency: currency}}</div>
            </div>
            <div class="d-flex w-100">Rebate under section 87A<div class="ms-auto {{taxRegimeData?.tax_details?.Rebate_under_Section_87A < 0 ? 'text-danger' : ''}}">{{taxRegimeData?.tax_details?.Rebate_under_Section_87A | currency: currency}}</div>
            </div>
            <div class="d-flex w-100">Raw tax on total taxable income after rebate<div class="ms-auto ">{{taxRegimeData?.tax_details?.tax_on_total_taxable_income_after_rebate| currency: currency}}</div>
            </div>
            <div class="d-flex w-100">Surcharge on raw tax<div class="ms-auto ">{{taxRegimeData?.tax_details?.surcharge_charge| currency: currency}}
              </div>
            </div>
            <div class="d-flex w-100">Education cess ({{taxRegimeData?.tax_details?.education_cess_charge}}% of total tax)<div class="ms-auto ">{{taxRegimeData?.tax_details?.education_cess_amount| currency: currency}}
            </div>
          </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total tax payable for FY {{this.appservice.getFinancialYearRange(currentYear)}} </div><div class="ms-auto ">{{taxRegimeData?.tax_details?.total_payable_tax| currency: currency}}</div>
    </div>
  </div>

  <div class="col-12">
    <div class="fw-600 fs-18">Tax Payable </div>
  </div>
  <div class="col-12">
    <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel id="ngb-panel-0">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">
            Tax paid so far
            <div class="ms-auto fw-500">{{taxRegimeData?.tax_payable?.tax_paid_so_far_sum| currency: currency}}</div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container  *ngIf="taxRegimeData?.tax_payable?.tax_paid_so_far?.length > 0">
            <div class="d-flex flex-column gap-12 fs-14">
              <div  *ngFor="let taxPaid of taxRegimeData?.tax_payable?.tax_paid_so_far" class="d-flex w-100">{{taxPaid?.month == 'tax_paid_by_prev_employer'?'Tax paid till now':taxPaid?.month}}<div class="ms-auto ">{{stringToNumConversion(taxPaid?.tax) | currency: currency}}</div>
              </div>

            </div>
          </ng-container>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="ngb-panel-1">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">
            Balance payable
            <div class="ms-auto fw-500">{{taxRegimeData?.tax_payable?.tax_balance_payable_sum| currency: currency}}</div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container  *ngIf="taxRegimeData?.tax_payable?.tax_balance_payable?.length > 0">
            <div class="d-flex flex-column gap-12 fs-14" >
              <div class="d-flex w-100" *ngFor="let taxBalance of taxRegimeData?.tax_payable.tax_balance_payable">{{taxBalance?.month}}<div class="ms-auto ">{{stringToNumConversion(taxBalance?.tax) | currency: currency}}</div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="col-12 mt-8">
    <div class="box-1 fw-500 "><div class="me-32 ">Total tax payable in upcoming months</div><div class="ms-auto ">{{taxRegimeData?.tax_payable?.tax_balance_payable_sum| currency: currency}}</div>
    </div>
  </div>

</ng-container>

<!-- NEW REGIME -->
<ng-container *ngIf="oldNewFlag">
<div class="col-12">
  <div class="fw-600 fs-18">Earnings </div>
</div>
<div class="col-12">
  <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
    <ngb-panel id="ngb-panel-0">
      <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="accordion-button">
          Gross
          <div class="ms-auto fw-500">{{this.taxRegimeData?.earnings.gross | currency: currency}}</div>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="d-flex flex-column gap-12 fs-14">
          <ng-container *ngFor="let salaryBreak of taxRegimeData?.earnings?.salary_break_down | keyvalue">
            <div class="d-flex w-100">{{salaryBreak?.key}}<div class="ms-auto ">{{stringToNumConversion(salaryBreak.value) | currency: currency}}</div>
            </div>
          </ng-container>

        </div>
      </ng-template>
    </ngb-panel>

  </ngb-accordion>
</div>
<div class="col-12">
  <div class="fw-600 fs-18">Income from other sources </div>
</div>
<div class="col-12 mt-8" *ngFor="let otherSource of taxRegimeData?.earnings?.income_from_othersource?.income_from_othersource_input_data | keyvalue">
  <div class="box-1 fw-500 bg-body"><div class="me-32 ">{{fieldName(otherSource.key)}}</div> <div class="ms-auto ">{{otherSource?.value}} </div>
  </div>
</div>
<div class="col-12 mt-8">
  <div class="box-1 fw-500 "><div class="me-32 ">Total exemptions under income from other sources </div><div class="ms-auto ">{{taxRegimeData?.earnings?.income_from_othersource?.income_from_othersource | currency: currency}} </div>
  </div>
</div>
<div class="col-12 mt-8">
  <div class="box-1 fw-500 bg-body"><div class="me-32 ">Income from previous employer</div> <div class="ms-auto ">{{this.taxRegimeData?.earnings?.prev_employer_income | currency: currency}} </div>
  </div>
</div>

<!-- Income/Loss from Property start -->
<div class="col-12">
  <div class="fw-500 fs-18">Income/Loss from Property</div>
</div>
<ng-container *ngIf="(taxRegimeData?.earnings?.incomeloss_property | keyvalue)?.length">

  <div class="col-12" *ngIf="(taxRegimeData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender | keyvalue)?.length">
    <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel id="ngb-panel-0">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">
            Income from let-out property
            <div class="ms-auto fw-500">{{taxRegimeData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender_net_amount | currency: currency}}</div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="d-flex flex-column gap-12 fs-14">
            <ng-container *ngFor="let incomeLess of taxRegimeData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender,let i = index">
              <div class="d-flex w-100">Property {{(i+1)}}<div class="ms-auto ">{{incomeLess?.net_income_houseproperty | currency: currency}}</div>
              </div>
            </ng-container>

          </div>
        </ng-template>
      </ngb-panel>

    </ngb-accordion>
  </div>
</ng-container>
<div class="col-12 mt-8">
  <div class="box-1 fw-500 "><div class="me-32 ">Total income/loss from property</div> <div class="ms-auto ">{{taxRegimeData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender_net_amount | currency: currency}} </div>
  </div>
</div>
<!-- Income/Loss from Property end -->

<div class="col-12 mt-8">
  <div class="box-1 fw-500 "><div class="me-32 ">Total taxable income FY {{this.appservice.getFinancialYearRange(currentYear)}} </div><div class="ms-auto ">{{this.taxRegimeData?.earnings?.total_taxable_income | currency: currency}} </div>
  </div>
</div>

<div class="col-12 mt-24">
  <div class="fw-600 fs-18">Exemptions under Section 16</div>
</div>

<div class="col-12 mt-8">
  <div class="box-1 fw-500 bg-body"><div class="me-32 ">Standard deduction</div> <div class="ms-auto ">{{this.taxRegimeData?.excemption_under_section16?.standard_deduction | currency: currency}} </div>
  </div>
</div>
<div class="col-12 mt-8">
  <div class="box-1 fw-500 "><div class="me-32 ">Total exemptions under section 16 </div><div class="ms-auto ">{{taxRegimeData?.taxable_income?.excemption_under_section16 | currency: currency}} </div>
  </div>
</div>

 <!-- NEW -->
 <div class="col-12">
  <div class="fw-600 fs-18">Taxable Income </div>
</div>
<div class="col-12">
  <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
    <ngb-panel id="ngb-panel-0">
      <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="accordion-button">
          Taxable Income details
          <div class="ms-auto fw-500">{{taxRegimeData?.taxable_income?.total_taxable_income_in_FY | currency: currency}}</div>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="d-flex flex-column gap-12 fs-14">
          <div class="d-flex w-100">Earning’s<div class="ms-auto ">{{taxRegimeData?.taxable_income?.earnings | currency: currency}}</div>
          </div>
          <div class="d-flex w-100">Exemption’s under section 16<div class="ms-auto text-danger">{{taxRegimeData?.taxable_income?.excemption_under_section16 == 0 ? (0| currency: currency) : (-(taxRegimeData?.taxable_income?.excemption_under_section16)| currency: currency)}}</div>
          </div>
          <div class="d-flex w-100">Exemption’s based on declaration<div class="ms-auto text-danger">{{(taxRegimeData?.taxable_income?.excemption_based_on_declaration == 0 ? (0| currency: currency) : (-(taxRegimeData?.taxable_income?.excemption_based_on_declaration)| currency: currency))}}</div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
<div class="col-12 mt-8">
  <div class="box-1 fw-500 "><div class="me-32 ">Total tax income FY {{this.appservice.getFinancialYearRange(currentYear)}} </div><div class="ms-auto ">{{taxRegimeData?.taxable_income?.total_taxable_income_in_FY | currency: currency}}</div>
  </div>
</div>
<!-- END -->

<div class="col-12">
  <div class="fw-600 fs-18">Tax </div>
</div>
<div class="col-12">
  <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
    <ngb-panel id="ngb-panel-0">
      <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="accordion-button">
          Tax Details
          <div class="ms-auto fw-500">{{taxRegimeData?.tax_details?.total_payable_tax | currency: currency}}</div>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="d-flex flex-column gap-12 fs-14">
          <div class="d-flex w-100">Raw tax on total taxable income<div class="ms-auto ">{{taxRegimeData.tax_details?.Tax_on_total_taxable_income | currency: currency}}</div>
          </div>
          <div class="d-flex w-100">Rebate under section 87A (a)<div class="ms-auto {{taxRegimeData?.tax_details?.Rebate_under_Section_87A > 0 ? 'text-danger' : ''}}">{{-taxRegimeData?.tax_details?.Rebate_under_Section_87A | currency: currency}}</div>
          </div>
          <div class="d-flex w-100">Relief Under Section 87A (b)<div class="ms-auto {{taxRegimeData?.tax_details?.marginal_relief_of_normal_slab > 0 ? 'text-danger' : ''}}">{{-taxRegimeData?.tax_details?.marginal_relief_of_normal_slab | currency: currency}}</div>
          </div>
          <div class="d-flex w-100">Raw tax on total taxable income after rebate<div class="ms-auto ">{{taxRegimeData?.tax_details?.tax_on_total_taxable_income_after_rebate | currency: currency}}</div>
          </div>
          <div class="d-flex w-100">Surcharge on raw tax<div class="ms-auto ">{{taxRegimeData?.tax_details?.surcharge_charge| currency: currency}}
            </div>
          </div>
          <div class="d-flex w-100">Education cess ({{taxRegimeData?.tax_details?.education_cess_charge}}% of total tax)<div class="ms-auto ">{{taxRegimeData?.tax_details?.education_cess_amount | currency: currency}}
          </div>
        </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
<div class="col-12 mt-8">
  <div class="box-1 fw-500 "><div class="me-32 ">Total tax payable for FY {{this.appservice.getFinancialYearRange(currentYear)}} </div><div class="ms-auto ">{{taxRegimeData?.tax_details?.total_payable_tax | currency: currency}}</div>
  </div>
</div>

<div class="col-12">
  <div class="fw-600 fs-18">Tax Payable </div>
</div>
<div class="col-12">
  <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
    <ngb-panel id="ngb-panel-0">
      <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="accordion-button">
          Tax paid so far
          <div class="ms-auto fw-500">{{taxRegimeData?.tax_payable?.tax_paid_so_far_sum | currency: currency}}</div>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <ng-container  *ngIf="taxRegimeData?.tax_payable?.tax_paid_so_far?.length > 0">
          <div class="d-flex flex-column gap-12 fs-14">
            <div *ngFor="let taxPaid of taxRegimeData?.tax_payable?.tax_paid_so_far" class="d-flex w-100">{{taxPaid?.month == 'tax_paid_by_prev_employer'?'Tax paid till now':taxPaid?.month}}<div class="ms-auto ">{{taxPaid?.tax | currency: currency}}</div>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="ngb-panel-1">
      <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="accordion-button">
          Balance payable
          <div class="ms-auto fw-500">{{taxRegimeData?.tax_payable?.tax_balance_payable_sum | currency: currency}}</div>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <ng-container  *ngIf="taxRegimeData?.tax_payable?.tax_balance_payable?.length > 0">
          <div class="d-flex flex-column gap-12 fs-14">
            <div  *ngFor="let taxBalance of taxRegimeData?.tax_payable?.tax_balance_payable" class="d-flex w-100">{{taxBalance?.month}}<div class="ms-auto ">{{taxBalance?.tax | currency: currency}}</div>
            </div>

          </div>
        </ng-container>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
<div class="col-12 mt-8">
  <div class="box-1 fw-500 "><div class="me-32 ">Total tax payable in upcoming months</div><div class="ms-auto ">{{taxRegimeData?.tax_payable?.tax_balance_payable_sum | currency: currency}}</div>
  </div>
</div>
</ng-container>
</div>
<!-- test end -->
