import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-confirmed-regime-comparison',
  templateUrl: './confirmed-regime-comparison.component.html',
  styleUrls: ['./confirmed-regime-comparison.component.scss']
})
export class ConfirmedRegimeComparisonComponent implements OnInit {
  activeTab = 1;
  taxRegimeSlab = false;
  oldNewFlag = false;
  loader1 = true;
  enableSaveBtn = false;
  hra80gg: any;
  currentYear: number = new Date().getFullYear();
  nextYear: number = (this.currentYear + 1);
  alertShow = 'show';
  alertShow1 = 'show';
  alertShow2 = 'show';
  taxData: any = [];

  @Input() taxRegimeData: any;
  @Input() investmentDetails: any;
  @Output() submitmethod = new EventEmitter();

  @Input() currency: any;

  oldRegimeSummary: any = [];
  newRegimeSummary: any = [];
  selectedYear: any;
  alertToggle           = false;
  investalertMsg:any    = 'You cannot the change the regime since your salary has been processed for one more months in this financial year.';

  constructor(
    public appService: AppService
  ) { }

  ngOnInit(): void {
    this.selectedYear   = this.appService.getFinancialYear();
    setTimeout(() => {
      if (!!Object.keys(this.taxRegimeData)?.length == true) {
        this.loader1 = false;
        this.oldRegimeSummary = this.taxRegimeData.old_regime.regime_summary;
        this.newRegimeSummary = this.taxRegimeData.new_regime.regime_summary;
        if (this.taxRegimeData.old_regime.excemption_declaration.hasOwnProperty('hra'))
          this.hra80gg = 'hra';
        if (this.taxRegimeData.old_regime.excemption_declaration.hasOwnProperty('80gg'))
          this.hra80gg = '80gg';

        // To make the Old and New regime selection section start
        var elements = document.getElementsByName("btnradio");
        setTimeout(() => {
          for (let i = 0; i < elements?.length; i++) {
            var element1 = <HTMLInputElement>elements[i];
            if (this.investmentDetails.count_info.proposed_regime == null) {
              this.taxData = this.taxRegimeData?.old_regime;
              if (element1.id == 'btnradio1') {
                element1.checked = true;
              }

            }
            else if (this.investmentDetails.count_info.proposed_regime == 'old') {
              this.oldNewFlag = false;
              this.taxData = this.taxRegimeData?.old_regime;
              if (element1.id == 'btnradio1') {
                element1.checked = true;
              }
              if (element1.id == 'btnradio2') {
                element1.disabled = true;
              }
            }
            else {
              this.taxData = this.taxRegimeData?.new_regime;
              this.oldNewFlag = true;
              if (element1.id == 'btnradio1') {
                element1.disabled = true;
              }
              if (element1.id == 'btnradio2') {
                element1.checked = true;
              }
            }


          }

        }, 1000);
        this.loader1 = false;

      } else {
        this.loader1 = true;
      }
    }, 2000);
  }

  formSubmit() {
    let declared_datail;
    this.enableSaveBtn = false;
    if (this.oldNewFlag)
      declared_datail = JSON.stringify(this.taxRegimeData.new_regime);
    else
      declared_datail = JSON.stringify(this.taxRegimeData.old_regime);
    this.submitmethod.emit({ tax_regime: this.oldNewFlag, declared_datail: declared_datail });
  }

  oldNewRadioBtnChangeFunction(e: any) {

    // Popup for regime restricted due to salary processed
    if(this.investmentDetails?.count_info?.is_regime_restricted_salary_processed ){
      this.alertToggle = true;
    }else{
      this.alertToggle = false;
    }
    // End

    if (this.investmentDetails.count_info.proposed_regime == null) {
      if (e == 'old') {
        this.taxData = this.taxRegimeData?.old_regime;
        this.oldNewFlag = false;
      }
      else {
        this.taxData = this.taxRegimeData?.new_regime;
        this.oldNewFlag = true;
      }
    }
  }

  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  fieldName(data: any) {
    let str;
    if (data == 'othersource_amount')
      str = 'Income from other sources';
    if (data == 'sb_account_amount')
      str = 'Interest earned through Saving Bank Account';
    if (data == 'source_80TTA_80TTB_amount')
      str = '80TTA/ 80TTB';
    if (data == 'intrest_earned_amount')
      str = 'Interest earned from national savings certificate';
    return str;
  }

  chapter4aFieldName(data: any) {
    let str;
    if (data == 'employee_contribute_pf_amount')
      str = 'Employee Contribution towards PF';
    if (data == 'ppf_amount')
      str = 'Contribution towards 15 Years PPF';
    if (data == 'POTD_amount')
      str = 'Five-year Post Office Time Deposit (POTD) Scheme';
    if (data == 'nsc_amount')
      str = 'Subscription to National Saving Scheme (NSC)';
    if (data == 'nps_amount')
      str = 'Deduction in respect of contribution to NPS (80 CCD(1))';
    if (data == 'ELSS_amount')
      str = 'Equity Linked Saving Schemes (Tax Saving Mutual Funds) ELSS';
    if (data == 'ULIP_amount')
      str = 'ULIP';
    if (data == 'interest_NSC_amount')
      str = 'Interest on National Saving Certificate';
    if (data == 'repay_house_amount')
      str = 'Repayment of Housing Principal';
    if (data == 'stampduty_amount')
      str = 'Stamp Duty paid for Purchase of House property';
    if (data == 'ssy_amount')
      str = 'Sukanya Samriddhi Yojana';
    if (data == 'nabard_rural_bond_amount')
      str = 'NABARD Rural Bonds';
    if (data == 'lic_amount')
      str = 'Life insurance premium';
    if (data == 'annuity_amount')
      str = 'Annuity plan of LIC or other insurer towards Pension Scheme (80 CCC)';
    if (data == 'contribution_vpf_amount')
      str = 'Contribution towards VPF';
    if (data == 'previous_employer_pf_amount')
      str = 'Previous Employer PF';
    if (data == 'tuition_fees')
      str = 'Children Education Tuition Fees';
    return str;
  }

  deductionFieldName(data: any) {
    let str;
    if (data == 'health_insurance_selfspouse80d_amount')
      str = '80D : Health insurance premium for Self / Spouse or Dependent Children';
    if (data == 'health_checkup_selfspouse80d_amount')
      str = '80D : Preventive health checkup for Self / Spouse or Dependent Children';
    if (data == 'health_checkup_parents80d_amount')
      str = '80D : Preventive health checkup for Dependent parents';
    if (data == 'medicalbill_senior80d_amount')
      str = 'Medical bills for super senior citizen';
    if (data == 'employee_contribution_amount')
      str = '80CCD1(B): Employee’s additional contribution to NPS';
    if (data == 'tta_ttb_amount')
      str = '80TTA: Exemption of interest from banks, post office, etc. (or) 80TTB: Exemption of interest from banks, post office, etc.';
    if (data == 'interest_education_amount')
      str = '80E: Interest on education loan';
    if (data == 'interest_home_loan_amount80ee')
      str = '80EE: Additional Interest on home loan for first-time homeowners borrowed between 1st April 2016 to 31st March 2017 ';
    if (data == 'interest_home_loan_amount80eea')
      str = '80EEA: Additional Interest on home loan for first-time homeowners borrowed between 1st April 2019 to 31st March 2022';
    if (data == 'interest_electrical_vehicle_loan_amount')
      str = '80EEB: Interest on electrical vehicle loan borrowed between 1st April 2019 to 31st March 2023';
    if (data == 'handicapped_dependent_amount')
      str = '80 DD: Medical treatment for handicapped dependent';
    if (data == 'medical_expenditure_amount')
      str = '80DDB: Medical Expenditure as per rule 11DD';
    if (data == 'self_suffering_amount')
      str = '80U: Self-suffering from disability';
    if (data == 'donate_social_cause100_amount')
      str = '80G: Deduction for donations towards social causes (100% exemption)';
    if (data == 'donate_social_cause50_amount')
      str = '80G: Deduction for donations towards social causes (50% exemption)';
    if (data == 'royalty_patent_amount')
      str = '80RRB: Deductions on Income by way of Royalty of a Patent';
    if (data == 'contribution_policalparties_amount')
      str = '80GGC: Contribution by individuals to political parties';
    if (data == 'health_insurance_parents80d_amount')
      str = '80D : Health insurance premium for Dependent parents';
    if (data == 'total_amount')
      str = '80GGA: Deduction in respect of donations to certain funds, charitable institutions, scientific research, and rural development etc. ';
    if (data == 'employer_contribution_amount')
      str = '80CCD(2): Employer’s contribution to NPS account, deducted from salary';
    return str;
  }

  ackCheckbox(e: any) {
    if (e.target.checked == true) {
      this.enableSaveBtn = true;
    } else {
      this.enableSaveBtn = false;
    }
  }

  closeTaxSlab(data:any){
    this.taxRegimeSlab = data;
  }

  getBetterRegime(){
    if(Number(this.oldRegimeSummary?.total_tax > this.newRegimeSummary?.total_tax))
      return 'New';
    else
     return 'Old';
  }

  closeInfo(bool:any){
    this.alertToggle = false;
  }

}
