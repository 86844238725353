import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseManagementService } from 'src/app/@core/services/expense-management.service';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { AppService } from 'src/app/app.global';
import {  FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-expense-listing',
  templateUrl: './expense-listing.component.html',
  styleUrls: ['./expense-listing.component.scss']
})
export class ExpenseListingComponent implements OnInit {

  @Input() listData:any = []
  @Input() searchString:any
  @Input() page = 1
  @Input() loader = false
  @Output() refreshList  = new EventEmitter();

  currency :any;

  viewDetail = false;
  manualReimbursement = false;
  activeTab2 = 1;
  pageSize = 20
  viewData:any = []
  viewDataLoader = false

  viewAttchments          = false;
  receiptId = 0
  uploadedAttachments:any  = []

  viewReciept = false;
  viewRecieptLoader = false;
  viewRecieptData:any = [];

  viewAdvance   = false;
  advanceid     = 0;
  viewLoader    = false;
  viewAdvdata:any  = [];
   
  viewreimLoader = false
  viewreimbData:any = []

  balance = ""
  btnText = ""

  myForm: FormGroup = this.fb.group({
    description: ['', [Validators.maxLength(500)]],
    returned:[true],
    amount:[''],
    date:['']
  })

  modalToggle     = false
  fileName:any
  currentDocName  = ""
  currentExtension= ""

  saveManualLoader = false
  minDate:any
  dateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 1, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  disablebtn = false;

  constructor(public appservice:AppService,private EMS:ExpenseManagementService,private ExpenseReportRequestService:ExpenseReportRequestService,public fb:FormBuilder,public messageService : MessageService) { }

  ngOnInit(): void {
  }

  filterIt() {
    return this.listData.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  get f() { return this.myForm.controls; }
  
  viewDetailReport(id:any){
    this.viewDataLoader =true
    this.EMS.reportDetails(id).subscribe((res:any)=>{
      this.viewData = res
      this.currency =res.currency;
      this.minDate = ''
      this.viewData?.advances?.forEach((element: any) => {
        this.minDate = this.minDate != '' && this.minDate < element.expense_date ? this.minDate:element.expense_date
      });
      setTimeout(() => {
          this.viewDataLoader = false;
      }, 500);
    });
    this.viewDetail = true
    this.EMS.reimbursementDetails(id).subscribe((res:any)=>{
      this.viewreimbData = res
  
        if(this.viewreimbData.total_reimburseable_amount < 0){
          
          this.viewreimbData.reimb_amount = this.viewreimbData.total_reimburseable_amount
          this.greaterReturnedValidation()
          this.balance = "greater"


        } else  if(this.viewreimbData.total_reimburseable_amount > 0){
          this.myForm.reset();
          this.setValidations('date')
          this.setValidations('amount')
          // this.setValidations('description')
          this.myForm.get('amount')?.setValue(this.viewreimbData.total_reimburseable_amount);
          this.myForm.get('amount')?.disable()

          this.balance = "less"
          this.viewreimbData.reimb_amount = this.viewreimbData.total_reimburseable_amount
          this.viewreimbData.refund_amount = this.viewreimbData.refund_amount
          this.viewreimbData.carryForward = 0
          this.btnText = "Record Reimbursement"
        } else {
  
          this.myForm.reset();
          this.setValidations('date')

          this.viewreimbData.reimb_amount = 0
          this.viewreimbData.refund_amount = 0
          this.viewreimbData.carryForward = 0
          this.balance = "equal"
          this.btnText = "Mark as Reimbursed"
        }
      
      });
  }

  setValidations(item:any){
    this.myForm.get(item)?.setValidators([Validators.required]);
    this.myForm.get(item)?.updateValueAndValidity();
  }

  clearValidations(item:any){
    this.myForm.get(item)?.clearValidators()
    this.myForm.get(item)?.updateValueAndValidity();
  }

  greaterReturnedValidation(){
    this.myForm.reset();
    this.setValidations('date')
    // this.setValidations('description')
    this.myForm.get('returned')?.setValue(true);
    this.viewreimbData.refund_amount=this.viewreimbData.reimb_amount*-1; this.viewreimbData.carryForward=0; this.btnText = 'Record Advance Refund'
  }
  greaterCarryForwardValidation(){
    this.myForm.reset();
    this.clearValidations('date')
    this.clearValidations('description')
    this.myForm.get('returned')?.setValue(false);
    this.viewreimbData.carryForward=this.viewreimbData.reimb_amount*-1; this.viewreimbData.refund_amount=0; this.btnText = 'Record Advance Carry Forward'
  }

  viewAttachmentFunction(index:any,id:any){
    this.viewAttchments      = true;
    this.receiptId           = id;
    this.uploadedAttachments = this.viewData?.receipts[index].attachments;
  }

  detailedReceiptView(id:any){
    this.viewRecieptLoader = true;
    this.viewReciept = true;
    this.getSigleReceiptView(id);
  }

  getSigleReceiptView(id:any){
    this.viewRecieptData = [];
    this.ExpenseReportRequestService.getSingleReceiptData(id).subscribe((res:any)=>{
      this.viewRecieptData = res;
      this.viewRecieptLoader  = false;
    })
  }
  viewAdvanceFunction(id:any){
    this.viewLoader  = true;
    this.viewAdvance = true;
    this.advanceid   = id;
    this.viewAdvdata    = [];
    this.ExpenseReportRequestService.getAdvanceDetailedView(this.advanceid).subscribe((res: any) => {
      this.viewAdvdata   = res;
      this.viewLoader = false;
   });
  }

  reimbursementDetails(id:any){
    this.viewreimLoader =true
    setTimeout(() => {
      this.viewreimLoader = false;
  }, 500);
    this.manualReimbursement = true;
    this.disablebtn = false;
  }

  saveReimbursement(isPayroll=false){
    this.disablebtn = true;
    let data:any = []
    if(isPayroll){
      data= {"report":this.viewData.id,
      "total_receipt_amount":  this.viewreimbData.total_receipt_amount,
      "total_amount": this.viewData.total_amount,
      "total_advance_amount": this.viewreimbData.total_advance_amount,
      "advance_carry_forward": this.viewreimbData.carryForward,
      "is_automatic_or_manual": true,
      "is_advance_refund_or_carry_forward": false}
    } else {
      data= {"report":this.viewData.id,
      "total_receipt_amount":  this.viewreimbData.total_receipt_amount,
      "total_amount": this.viewreimbData.total_amount,
      "total_advance_amount": this.viewreimbData.total_advance_amount,
      "advance_carry_forward": this.viewreimbData.carryForward,
      "is_automatic_or_manual": false,
      "is_advance_refund_or_carry_forward": this.f.returned.value,
      "description": this.f.description.value,
      "pay_date" : this.f.date.value!= null?this.appservice.dateFormatConvert(this.f.date.value):null,
      "pay_month" :this.f.date.value!= null?new Date(this.f.date.value).toLocaleString('en-US', { month: 'long' }):null,
      "pay_year" :this.f.date.value!= null?new Date(this.f.date.value).getFullYear():null,}
    }
    this.saveManualLoader = true
    // console.log(data)
    this.EMS.createReimbursement(data).subscribe((res:any)=>{
      this.saveManualLoader = false
      this.manualReimbursement = false
      this.viewDetail = false
      this.refreshList.emit()
    });
  }
  viewDocument(filename:any,docType:any){

    this.modalToggle      = true;
    this.fileName         = filename;
    this.currentDocName   = docType;
    this.currentExtension = this.fileName?.split('.').pop();
  }
  close(bool:any){
    this.modalToggle = bool;
  }

}
