import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {  ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select'
@Component({
  selector: 'app-master-select',
  templateUrl: './master-select.component.html',
  styleUrls: ['./master-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MasterSelectComponent),
      multi: true
    }
  ]
})
export class MasterSelectComponent implements  ControlValueAccessor {

  @Input() submitted:any;
  @Input() type:any;
  @Input() selectedItem:any;
  @Input() appendTo:any;
  @Input() dropdownPosition:any;
  @Input() placeholder:any;
  @Input() isrequired:any;
  @Input() selectError:any;
  @Input() className:any;
  @Input() range:any;
  @Input() startPoint:any;
  @Input() lastDay: boolean = true;
  items:any;
  disabled: boolean = false;
  @Input() isdisabled: any;
  @ViewChild('ngSelecter') ngselect: any;
  private _key: any;

  @Output() selectedValue = new EventEmitter()



  data:any =
  {
    salutation:[
      { id: 'Mr.', value: 'Mr.' },
      { id: 'Ms.', value: 'Ms.' },
      { id: 'Mrs.', value: 'Mrs.' },
      { id: 'Dr.', value: 'Dr.' },
      { id: 'Prof.', value: 'Prof.'},
    ],
    yesno:[
      { id: 'Yes', value: 'Yes' },
      { id: 'No', value: 'No' },
    ],
    gender:[
      { id: 'Male', value: 'Male' },
      { id: 'Female', value: 'Female' },
      { id: 'Other', value: 'Other' },
    ],
    currency:[
      {id:"INR",value:"INR"},
      {id:"USD",value:"USD"},
      {id:"AED",value:"AED"},
    ],
    marital_status:[
      {id:"Single",value:"Single"},
      {id:"Married",value:"Married"},
      {id:"Widow",value:"Widow"},
      {id:"Separated",value:"Separated"},
    ],
    relation:[
      {id:'Brother',value:"Brother"},
      {id:'Daughter',value:"Daughter"},
      {id:'Father',value:"Father"},
      {id:'Mother',value:"Mother"},
      {id:'Son',value:"Son"},
      {id:'Sister',value:"Sister"},
      {id:'Spouse',value:"Spouse"},
      {id:'Self',value:"Self"},
      {id:'Other',value:"Other"},
    ],
	  payment_mode:[
      {id:'Cash',value:"Cash"},
      {id:'Cheque',value:"Cheque"},
      {id:'Online Transfer',value:"Online Transfer"},
    ],
    nocash_payment_mode:[
      {id:'Draft',value:"Draft"},
      {id:'Cheque',value:"Cheque"},
    ],
    listOfMonth:[
      {id:'January',value:"January"},
      {id:'February',value:"February"},
      {id:'March',value:"March"},
      {id:'April',value:"April"},
      {id:'May',value:"May"},
      {id:'June',value:"June"},
      {id:'July',value:"July"},
      {id:'August',value:"August"},
      {id:'September',value:"September"},
      {id:'October',value:"October"},
      {id:'November',value:"November"},
      {id:'December',value:"December"},
    ],
    blood_Group:[
      {id:"A+",value:"A+"},
      {id:"A-",value:"A-"},
      {id:"AB+",value:"AB+"},
      {id:'AB-',value:"AB-"},
      {id:'B+',value:"B+"},
      {id:'B-',value:"B-"},
      {id:'O+',value:"O+"},
      {id:'O-',value:"O-"},
  ],
  gratuity_month:[
    {id:0,value:"0 month"},
    {id:1,value:"1 month"},
    {id:2,value:"2 month"},
    {id:3,value:"3 month"},
    {id:4,value:"4 month"},
    {id:5,value:"5 month"},
    {id:6,value:"6 month"},
    {id:7,value:"7 month"},
    {id:8,value:"8 month"},
    {id:9,value:"9 month"},
    {id:10,value:"10 month"},
    {id:11,value:"11 month"},
  ],
  gratuity_year:[
    {id:0,value:"0 year"},
    {id:1,value:"1 year"},
    {id:2,value:"2 year"},
    {id:3,value:"3 year"},
    {id:4,value:"4 year"},
    {id:5,value:"5 year"},
    {id:6,value:"6 year"},
    {id:7,value:"7 year"},
    {id:8,value:"8 year"},
    {id:9,value:"9 year"},
    {id:10,value:"10 year"},
    {id:11,value:"11 year"},
    {id:12,value:"12 year"},
    {id:13,value:"13 year"},
    {id:14,value:"14 year"},
    {id:15,value:"15 year"},

  ],
  employment_Type : [
    {id:"Contractual",value:"Contractual"},
    {id:"Consultant",value:"Consultant"},
    {id:"Permanent",value:"Permanent"},
    {id:"Trainee",value:"Trainee"},
    {id:"Wages",value:"Wages"}
  ],
  employment_Status : [
    {id:"Probation",value:"Probation"},
    {id:"Confirmed",value:"Confirmed"},
    {id:"Resigned",value:"Resigned"},
    {id:"Relieved",value:"Relieved"},
    {id:"Settled",value:"Settled"},
    {id:"Terminated",value:"Terminated"},
    {id:"Retired",value:"Retired"},
  ],

  nationality : [
    {id:"INDIAN",value:"Indian"},
  ],
  skill_type : [
    {id:"Semi Skilled",value:"Semi Skilled"},
    {id:"Skilled",value:"Skilled"},
    {id:"Unskilled",value:"Unskilled"},
  ],
  tax_config:[
    {id:'Taxable',value:'Taxable'},
    {id:'Non-Taxable',value:'Non-Taxable'},
    {id:'Partially Taxable',value:'Partially Taxable'}
  ],
  income_section:[
    {id:'Section 17(1)',value:'Section 17(1)'},
    {id:'Section 17(2)',value:'Section 17(2)'},
    {id:'Section 17(3)',value:'Section 17(3)'}
  ],
  issues:[
    {id:'Neurological Disease',value:'Neurological Disease'},
    {id:'Malignant Cancer',value:'Malignant Cancer'},
    {id:'AIDS',value:'AIDS'},
    {id:'Chronic Renal Failure',value:'Chronic Renal Failure'},
    {id:'Hematological Thalassaemia Disorders',value:'Hematological Thalassaemia Disorders'},
    {id:'Hematological Hemophilia Disorders',value:'Hematological Hemophilia Disorders'}

  ],
  fund_type:[
    {id:'Government or any approved local authority, institution or association to be utilised for the purpose of promoting family planning',value:'Government or any approved local authority, institution or association to be utilised for the purpose of promoting family planning'},
    {id:'Government or any local authority to be utilised for any charitable purpose other than the purpose of promoting family planning',value:'Government or any local authority to be utilised for any charitable purpose other than the purpose of promoting family planning'},
    {id:'Indira Gandhi Memorial Trust',value:'Indira Gandhi Memorial Trust'},
    {id:'Jawaharlal Nehru Memorial Fund',value:'Jawaharlal Nehru Memorial Fund'},
    {id:'National Blood Transfusion Council or to any State Blood Transfusion Council',value:'National Blood Transfusion Council or to any State Blood Transfusion Council'},
    {id:'National Children\'s Fund',value:'National Children\'s Fund'},
    {id:'National Culture Fund',value:'National Culture Fund'}

  ],
  credit_period:[
    {id:'Monthly',value:'Monthly'},
    {id:'Quarterly',value:'Quarterly'},
    {id:'Half Yearly',value:'Half Yearly'},
    {id:'Yearly',value:'Yearly'}
  ],
  leave_credit_join:[
    {id:'Including joining month',value:'Including joining month'},
    {id:'Excluding joining month',value:'Excluding joining month'},
    {id:'Rule 15 will be applicable',value:'Rule 15 will be applicable'}
  ],
  applicable_to:[
    {id:'Employee',value:'Employee'},
    {id:'BU',value:'BU'},
    {id:'Department',value:'Department'},
    {id:'Designation',value:'Designation'},
    {id:'Grade',value:'Grade'},
    {id:'Company',value:'Company'}
  ],
  report_schedule:[
    {id:'Daily',value:'Daily'},
    {id:'Weekly',value:'Weekly'},
    {id:'Monthly',value:'Monthly'},
    {id:'Yearly',value:'Yearly'},
  ],
  weekdays:[
    {id:'Sunday',value:'Sunday'},
    {id:'Monday',value:'Monday'},
    {id:'Tuesday',value:'Tuesday'},
    {id:'Wednesday',value:'Wednesday'},
    {id:'Thursday',value:'Thursday'},
    {id:'Friday',value:'Friday'},
    {id:'Saturday',value:'Saturday'},
  ],
  metro_nonmetro_city:[
    {id:'Metro',value:'Metro'},
    {id:'Non metro',value:'Non metro'},
  ],
  minor_head_code:[
    {id:'TDS payable by taxpayer',value:'TDS payable by taxpayer'},
    {id:'TDS regular assessment',value:'TDS regular assessment'},
  ],
  deductor_type:[
    {id:'ARTIFICIAL JURIDICAL PERSON',value:'ARTIFICIAL JURIDICAL PERSON'},
    {id:'ASSOCIATION OF PERSON (AOP)',value:'ASSOCIATION OF PERSON (AOP)'},
    {id:'ASSOCIATION OF PERSON (TRUST)',value:'ASSOCIATION OF PERSON (TRUST)'},
    {id:'AUTONOMOUS BODY(CENTRAL GOVERNMENT)',value:'AUTONOMOUS BODY(CENTRAL GOVERNMENT)'},
    {id:'AUTONOMOUS BODY (STATE GOVERNMENT)',value:'AUTONOMOUS BODY (STATE GOVERNMENT)'},
    {id:'BODY OF INDIVIDUALS',value:'BODY OF INDIVIDUALS'},
    {id:'BANCH/DIVISION OF COMPANY',value:'BANCH/DIVISION OF COMPANY'},
    {id:'CENTRAL GOVERNMENT',value:'CENTRAL GOVERNMENT'},
    {id:'COMPANY',value:'COMPANY'},
    {id:'FIRM',value:'FIRM'},
    {id:'INDIVIDUAL/HUF',value:'INDIVIDUAL/HUF'},
    {id:'LOCAL AUTHORITY (CENTRAL GOVERNMENT)',value:'LOCAL AUTHORITY (CENTRAL GOVERNMENT)'},
    {id:'LOCAL AUTHORITY (STATE GOVERNMENT)',value:'LOCAL AUTHORITY (STATE GOVERNMENT)'},
    {id:'STATE GOVERNMENT',value:'STATE GOVERNMENT'},
    {id:'STATUTORY BODY (CENTRAL GOVERNMENT)',value:'STATUTORY BODY (CENTRAL GOVERNMENT)'},
    {id:'STATUTORY BODY (STATE GOVERNMENT)',value:'STATUTORY BODY (STATE GOVERNMENT)'},
  ],
  payment_sec_code:[
    {id:'Payments made to Government employees',value:'Payments made to Government employees'},
    {id:'Payments made to employees other than government employees',value:'Payments made to employees other than government employees'},
  ],
  perquisite_type:[
    {id:'Accommodation',value:'Accommodation'},
    {id:'Cars/ other automotive',value:'Cars/ other automotive'},
    {id:'Sweeper, Gardner, watchman, or personal attendant',value:'Sweeper, Gardner, watchman, or personal attendant'},
    {id:'Gas, electricity, water',value:'Gas, electricity, water'},
    {id:'Interest free or concessional loans',value:'Interest free or concessional loans'},
    {id:'Holiday expenses',value:'Holiday expenses'},
    {id:'Free or concessional travel',value:'Free or concessional travel'},
    {id:'Free meals',value:'Free meals'},
    {id:'Free education',value:'Free education'},
    {id:'Gift, Vouchers, etc',value:'Gift, Vouchers, etc'},
    {id:'Credit card expenses',value:'Credit card expenses'},
    {id:'Club expenses',value:'Club expenses'},
    {id:'Use of movable assets by the employer',value:'Use of movable assets by the employer'},
    {id:'Transfer of assets to employees',value:'Transfer of assets to employees'},
    {id:'Value of any other benefit/ amenity/ service/ privilege',value:'Value of any other benefit/ amenity/ service/ privilege'},
    {id:'Stock options (non- qualified options)',value:'Stock options (non- qualified options)'},
    {id:'Other benefits of amenities',value:'Other benefits of amenities'},
    {id:'Tax paid by employer',value:'Tax paid by employer'},
  ],
  notice_pay_recovery:[
    {id:'Notice pay',value:'Notice pay'},
    {id:'Notice recovery',value:'Notice recovery'},
  ],
  MonthWithoutLast:[
    {id:'January',value:"January"},
    {id:'February',value:"February"},
    {id:'March',value:"March"},
    {id:'April',value:"April"},
    {id:'May',value:"May"},
    {id:'June',value:"June"},
    {id:'July',value:"July"},
    {id:'August',value:"August"},
    {id:'September',value:"September"},
    {id:'October',value:"October"},
    {id:'November',value:"November"},

  ],

  profile_section_emp_status : [
    {id:"Probation",value:"Probation"},
    {id:"Confirmed",value:"Confirmed"},
    {id:"Resigned",value:"Resigned"},
    {id:"Relieved",value:"Relieved"},
    {id:"Terminated",value:"Terminated"},
    {id:"Retired",value:"Retired"},
  ],
  inventory_status : [
    {id:"In Use",value:"In Use"},
    {id:"In Stock",value:"In Stock"},
    {id:"In Repair",value:"In Repair"},
    {id:"Missing",value:"Missing"},
    {id:"Retired",value:"Retired"},
    {id:"Damaged",value:"Damaged"},
  ],

  };
  // select: any;
  // select: NgSelectComponent | undefined;

  ngOnInit(): void {

  }


  @HostListener('change') ngOnChanges() {
    this.items = this.data[this.type];
    this.selectedValue.emit(this.key)
  }
  @HostListener('window:resize', ['$event'])
  sizeChange(event : any | null) {
    this.ngselect?.close();
  }


  constructor(
    private cdRef: ChangeDetectorRef
  ) { }

  get key(): any {
    return this._key;
  }
  set key(val) {
    this._key = val;
    this.propagateChange(this._key);
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.key = obj;
    }
  }
  createRange(num:number,start=1){

    var items: any[] = [];
    for(var i = start; i <= num; i++){
       items.push(i);
    }
    if(this.lastDay==true)
    items.push('Last day of month')

    return items;
  }
  ordinal_suffix_of(i:any) {
    var j = i % 10,
        k = i % 100;

    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    if (i == "Last day of month"){
      return i;
    }
    return i + "th";
 }
  propagateChange = (_: any) => { };
  propagateTouche = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouche = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  change() {
    this.propagateTouche(this._key);
  }
}


