import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { SalaryRevisionService } from 'src/app/@core/services/salary-revision';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-salary-revision-details-view',
  templateUrl: './salary-revision-details-view.component.html',
  styleUrls: ['./salary-revision-details-view.component.scss']
})
export class SalaryRevisionDetailsViewComponent implements OnInit {

  @Input() from: any;
  @Input() hisId: any;
  @Input() requestitem: any;
  @Output() refreshEmitter: EventEmitter<boolean> = new EventEmitter();

  data:any =[]
  empSalaryData:any;
  gross_benefits:any;
  other_benefits:any;
  contributions:any;
  recurring_deductions:any;
  gross_amount= 0;
  viewLoader = false;
  tableData:any = [];


  /********VIEW STATUS VARIABLES  ****/
  viewStatusData:any              = [];
  approvalRequired                = false;
  viewstatusPanel                 = false;
  loader1                         = false;
  salaryRevisionHistory           = false;
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;


  form = this.fb.group({
    read_status: [true,Validators.required],
    star: [false,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })
  constructor(private salaryRevisionService:SalaryRevisionService,private fb: FormBuilder,private inboxservice:InboxService, private investmentService:InvestmentDeclerationService,public appService:AppService ) { }

  ngOnInit(): void {

  this.viewLoader = true;
    this.getDetails();


  }

  ngOnChanges(){

    this.getDetails();

  }
  getDetails(){
    this.salaryRevisionService.getRevisionDetail(this.hisId).subscribe((res: any) => {
      this.empSalaryData = res.body.revise_structure;

        this.getTableData(res.body.employee.id)
        this.data     = res.body;

        var gross =  this.empSalaryData.assign_salary.filter(function(gross_sal:any) {

          return gross_sal.component_type == "gross_benefits" && gross_sal?.is_display==true;
        });
        var other =  this.empSalaryData.assign_salary.filter(function(other_sal:any) {

          return other_sal.component_type == "other_benefits" && other_sal?.is_display==true;
        });
        var contibution =  this.empSalaryData.assign_salary.filter(function(contribution_sal:any) {

          return contribution_sal.component_type == "contributions" && contribution_sal?.is_display==true;
        });
        var recurring =  this.empSalaryData.assign_salary.filter(function(recurring_sal:any) {

          return recurring_sal.component_type == "recurring_deductions" && recurring_sal?.is_display==true;
        });

        this.gross_benefits       = gross;
        this.other_benefits       = other;
        this.contributions        = contibution;
        this.recurring_deductions = recurring;

    });

  }


  get f() {return  this.form.controls; }


  confirm(status:any){
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }
    if(this.form['controls'].comment.valid){
      if(this.from == 'inbox'){
        this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
          if(res){
            this.refreshEmitter.emit(true);
          }
        })
      }

  }
  }
  monthManipulation(data:any){
    data = data.toString();
    data = data.replace(/,/g, ", ");
    return data;
  }


  /******** REQUEST VIEW STATUS  ****/
  requestViewStatus(reqId: any){
    this.viewstatusPanel     = true;
    this.loader1             = true;

     this.approvalRequired = true;
     this.investmentService.requestViewStatus(reqId).subscribe((res: any = []) =>{
       this.viewStatusData     = res;
       if(!res?.apporval_matrix_loading)
        this.loader1            = false;
       if(res.status == 1){
         this.viewStatusLevelCnt = res?.activity?.level_count;
         this.viewStatusmaxLevel = res?.activity?.maxlevel;
       }
     });


  }
  getTableData(empid:any){
    this.salaryRevisionService.getRevisionTable(empid).subscribe((res: any) => {
    this.tableData =  res.body;
    this.viewLoader           = false;

    })
  }



}
