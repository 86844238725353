import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';

@Component({
  selector: 'app-work-from-home-approval',
  templateUrl: './work-from-home-approval.component.html',
  styleUrls: ['./work-from-home-approval.component.scss']
})
export class WorkFromHomeApprovalComponent implements OnInit {
  multi:any                 = [];

  xAxisLabel:any = [];
  yAxisLabel: string = 'Days';
  graphLoader             = false;
  loader                    = false;
  from_day:any;
  to_day:any;
  totday = 0;

  timeOffTrend = false

  currentYear =  new Date().getFullYear();

  selectedYearDisp = String(this.currentYear)
  selectedYear = this.currentYear
  yearList: any = [];
  empId : any;

  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  warningMsgToggle:any            = false;
  warningMsg:any                  = '';

  @Input() requestitem: any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

  constructor(public messageService : MessageService,public appService: AppService ,private investmentService:InvestmentDeclerationService,private inboxservice: InboxService,private fb: FormBuilder) { }


  onSelect(event:any) {
    // console.log(event);
  }

  ngOnInit(): void {
    this.yearListCreate();
    
    setTimeout(() => {
      this.validation();
    }, 1000);
  }

  ngOnChanges(){
    this.loader       = true;
      setTimeout(() => {
        this.getwfhdaysdata();
      }, 2000);
    }

    requestViewStatus(){
      this.viewstatusPanel  = true;
      this.billLoader       = true;

       this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
        this.viewStatusData     = res;
        this.billLoader         = false;
        this.viewStatusLevelCnt = res?.activity?.level_count;
        this.viewStatusmaxLevel = res?.activity?.maxlevel;
       });
    }

  getwfhdaysdata(){
  this.totday = 0;
    for(let i=0;i<this.requestitem.requests?.requests?.length;i++){
      if(this.requestitem.requests?.requests[i].day_portion=='Full Day'){
        this.totday = this.totday+1;
      }else{
        this.totday = this.totday+0.5;
      }
    }
  this.loader       = false;
  }

  confirm(status:any){
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }
      this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
        if(res){
          this.successEmitter.emit(true);
        }
      })
  }

  form = this.fb.group({
    read_status: [true,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  get f() {return  this.form.controls; }

  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }

  getwfhtrendDetails(event:any){
    this.graphLoader = true;
    this.selectedYearDisp = String(event)
    this.empId = this.requestitem.from_person.id;
    if(this.empId != null && this.empId != '' && this.empId != undefined){
      this.inboxservice.getwfhTrend(event,this.empId).subscribe((res:any)=>{
        if(res){
          this.prepareTrendData(res.data)
        }
       })
    }
  }
  prepareTrendData(data:any){

    let approved:any         = [];
    let pending:any          = [];
    let rejected:any         = [];
    let cancelled:any         = [];
    this.xAxisLabel = [];

    this.multi           = [];
    data.forEach((item:any) => {
      this.xAxisLabel?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Approved"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else if(item1?.name == "Cancelled"){
            cancelled.push(item1?.value)
          }else{
            rejected.push(item1?.value)
          }
      });
    });
    this.multi.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 },{label: "Cancelled", data: cancelled, backgroundColor: '#7d92a1', barThickness: 10 })
    this.graphLoader = false;

  }
  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,

      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  // Validation fot OT and Comp off
  validation(){
    if(this.requestitem?.requests?.invalid_dates?.length > 0){
      this.warningMsgToggle = true;
      for(let i=0;i<this.requestitem?.requests?.invalid_dates?.lengt;i++){
        this.requestitem.invalid_dates[i] = this.appService.dateFormatDisplay(this.requestitem?.requests?.invalid_dates[i])
      }
      if(this.requestitem?.requests?.invalid_reason == 'Overtime approved & attendance processed')
        this.warningMsg = "Overtime is already processed for the selected dates" +' ('+this.requestitem?.requests?.invalid_dates?.join(', ')+')'+ ". You cannot approve the work from home request for these dates.";
      else
        this.warningMsg = "Compensatory off credited against the selected dates" +' ('+this.requestitem?.requests?.invalid_dates?.join(', ')+')'+ ". has already been availed. You cannot approve work from home request for the selected dates.";
    }else{
      this.warningMsgToggle = false;
      this.warningMsg = '';
    }
  }
  // End
}
