<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="finalSettlementForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
                <div class="row row-16 mb-16">
                    <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.settlement_date.errors}">
                        <div class="col-12 form-row">
                            <p class="text-uppercase fw-500 mb-0 required">Settlement Date</p>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="settlement_date" type="radio" id="settlement_date1"
                                    class="form-check-input  " formControlName="settlement_date" value="last_month"
                                    (change)="radioBtnChangeFunctions($event,'settlement_date','last_month','specific_month')">
                                <label for="settlement_date1" class="form-check-label">Last month</label>
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="settlement_date" type="radio" id="settlement_date2"
                                    class="form-check-input " formControlName="settlement_date" value="this_month"
                                    (change)="radioBtnChangeFunctions($event,'settlement_date','this_month','specific_month')">
                                <label for="settlement_date2" class="form-check-label">This month</label>
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="settlement_date" type="radio" id="settlement_date3"
                                    class="form-check-input " formControlName="settlement_date" [value]="true"
                                    (change)="radioBtnChangeFunctions($event,'settlement_date',true,'specific_month')">
                                <label for="settlement_date3" class="form-check-label">Specific month</label>
                            </div>
                        </div>
                        <!-- <ng-container *ngIf="finalSettlementForm.value.settlement_date==true">
                            <div class="col-12 form-row">
                                <div class="input-group">
                                    <input (click)="datePicker2.open()" class="form-control"
                                        placeholder="{{this.messageService.selectplaceholddisplay('Period')}}" matInput
                                        formControlName="specific_month" [matDatepicker]="datePicker2"
                                        [ngClass]="{ 'is-invalid': (submitted) &&  f.specific_month.errors}">
                                    <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                                </div>
                            </div>


                        </ng-container> -->
                        <ng-container *ngIf="finalSettlementForm.value.settlement_date==true">

                          <div class="col-6">
                              <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                  <label for="" class="form-label">Year </label>
                                  <ng-select class="form-ngselect  fs-14"
                                  formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                      <ng-option *ngFor="let yData of Years"
                                          [value]="yData">{{yData}}</ng-option>
                                  </ng-select>
                              </div>
                              <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                                  <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                  </div>
                              </div>
                          </div>
                            <div class="col-6">
                              <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}">
                                  <label for="" class="form-label">Month </label>
                                  <app-master-select class="config-app-master" style="width:9rem"
                                  [placeholder]="month_placeholder"
                                          id="month" [isrequired]="false" [selectedItem]="finalSettlementForm.value.specific_month"
                                      [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                                          (ngModelChange)="constructDate($event)" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}"> </app-master-select>
                              </div>
                              <div *ngIf="(submitted || f?.specific_month?.touched || f?.specific_month?.dirty)&& f?.specific_month?.errors" class="invalid-feedback">
                                  <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                  </div>
                              </div>
                          </div>
                      </ng-container>
                    </div>
                    <div *ngIf="(submitted) && f.settlement_date.errors" class="invalid-feedback">
                        <div *ngIf="f.settlement_date.errors.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <label for="" class="form-label">Settlement Done By</label>
                        <ng-select [readonly]="disabled"
                            placeholder="{{this.messageService.selectplaceholddisplay('Employees')}}"
                            formControlName="settlement_done_by" [items]="employeeDropdown" [multiple]="true"
                            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> Select All
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngIf="finalSettlementForm.value.settlement_done_by?.length">
                                    <span class="ng-value-label">{{finalSettlementForm.value.settlement_done_by?.length}}
                                        employee
                                        selected.</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                                        [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                                        class="avatar-sm sq-24 img-fluid rounded-circle"
                                        *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                        src="{{item.profile_image}}">
                                    <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                        class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span></span>
                                    <span class="mt-1 ms-1">{{ (item.name?.length>15)? (item.name |
                                        slice:0:30)+'..':(item.name) }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <ul class="selected-values mt-16" *ngIf="finalSettlementForm.value.settlement_done_by?.length !=0">
                            <li class="ng-value" *ngFor="let item of finalSettlementForm.value.settlement_done_by, let m =index">
                                <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                        <div class="d-inline-flex">
                                            <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                                *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                                src="{{insideitem.profile_image}}">
                                            <span
                                                *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                                class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                                                <span>
                                                    {{this.appservice.getFirstChar(insideitem.first_name+" "+insideitem.last_name,2)}}</span>
                                            </span>
                                            <span class="mt-2 ms-1">
                                                {{insideitem.first_name+"
                                                "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                                ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                            </span>
                                        </div>
                                    </span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true"
                                    (click)="clearcommonForm('employeebd',m)"><i class="icon-close"></i></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
                <!-- New end -->
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
                (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                (click)="resetPage();submitfinalSettlementForm()">
                Apply
            </button>
        </footer>
    </form>
</div>





