<div class="container-fluid p-24rem" *ngIf="permissions">
    <div class="row mb-16rem">
        <div class="col-md-4 col-lg-6">
            <h3>Company</h3>
            <p class="text-helper mb-md-0">A company is a registered legal entity. Company profile details can be added
                here.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16rem">
                <div class="" style="width:22.6875rem;">
                    <div class="">
                        <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='companysearch' [isLoading]="isLoading"
                            (searchEmitter)="searchresults($event)"
                            (keywordsearchEmitter)="searchCompany($event)"></app-searchbar>
                    </div>
                    <ul class="drop-down-menu search-result {{(inputFocusClass == true) ? 'active' : ''}}">
                        <p class="note"> No Result msg </p>
                        <p class="text-center text-helper mt-1">No Company Found...</p>
                        <p class="note"> Loader </p>
                        <p class="loader text-center">
                            <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
                        </p>
                        <p class="note"> Search Results</p>
                    </ul>
                </div>

                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13rem"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end">
                        <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
                            (click)="statusBtn='All';selectItemList('')">All</button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
                            (click)="statusBtn='Active';selectItemList(true)">Active </button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
                            (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>

                    </div>
                </div>
                <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add"
                    (click)="addCompany()"><i class="icon-plus"></i>Add
                    Company</button>
            </div>
        </div>
    </div>
    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16" *ngIf="loader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>

    <div *ngIf="!company_list_flag && !loader;else all_data "  class="row" style="height: calc(100vh - 15rem);">
        <!-- <div class="col-12 text-center">
            <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
                style="width: 27.375rem;">
            <p class="text-uppercase text-light-400 fw-700 fs-14rem">NO COMPANY ADDED YET</p>
            <ng-container *ngIf="permissions.a">
            <a    class="text-uppercase link-primary fw-700 fs-14rem" (click)="showSidePanel = true">Add NOW</a></ng-container>
        </div> -->
        <app-nodata-view class="vstack" [noDataImg]="'bg7.png'"  [noDataText]="'NO COMPANY ADDED YET'" [hasPermission]="permissions?.a"
            (addData)="showSidePanel = true"> </app-nodata-view>

    </div>
    <ng-template #all_data>
        <div class="row row-16rem" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            [fromRoot]="true" (scrolled)="onScrollDown()">
            <ng-container *ngIf="loader==false">
                <!--<p class="note"> Company Card Display </p>-->
                <ng-container *ngFor="let company of companyDetails,let i = index">
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div class="card card-custom1 card-hover">
                            <div class="card-body p-0 d-flex align-items-start">
                                <div class="d-flex view-section" (click)="viewCompany(company.id)">

                                    <img *ngIf="company.logo != null" class="logo-img img-fluid" src="{{company.logo}}">
                                    <div *ngIf="company.logo == null" class="logo-img bg{{i%5}}">
                                        {{this.appService.getFirstChar(company.company_name,2)}}
                                    </div>
                                    <div class="overflow-hidden me-auto">
                                        <h6 class="mb-0 tips">{{company.company_name}} </h6>
                                        <span class="tips-helper">{{company.company_name}}</span>

                                        <span
                                            class="fs-10rem text-uppercase fw-500 {{(company.is_active == true) ? 'text-success' : 'text-danger'}}">{{(company.is_active
                                            == true) ? 'Active' : 'Deleted'}}
                                        </span>

                                    </div>
                                </div>
                                <div ngbDropdown class="d-inline-block dropdown-section"
                                    *ngIf="company.is_active && (permissions.e || permissions.d)">
                                    <button class="btn-reset p-0 h-100" placement="bottom-left" id="dropdownBasic1"
                                        ngbDropdownToggle>
                                        <i class="icon-three-dots-vertical "></i>
                                    </button>
                                    <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                                        <button *ngIf="permissions.e" ngbDropdownItem
                                            (click)="companyDetail(company.id)">
                                            <i class="icon-pencil-square me-1"></i> Edit
                                        </button>
                                        <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem
                                            (click)="deleteCompany(company.id)">
                                            <i class="icon-trash  me-1"></i> Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div (click)="viewCompany(company.id)" *ngIf="company.signatory_name != null"
                                class="card-body pt-8rem justify-content-between overflow-hidden">
                                <div class="d-flex align-items-center">
                                    <img *ngIf="company.signatory_name.employee != null && company.signatory_name.employee.profile_image !=  null && company.signatory_name.employee.profile_image !=  ''"
                                        class="user-img img-fluid rounded-circle"
                                        src="{{company.signatory_name.employee.profile_image}}">
                                    <div *ngIf="company.signatory_name.employee != null && (company.signatory_name.employee.profile_image ==  '' || company.signatory_name.employee.profile_image == null)"
                                        class="user-img rounded-circle">
                                        <span>{{this.appService.getFirstChar(company.signatory_name.first_name,1)}}</span>
                                    </div>
                                    <div class="fs-12rem fw-500 text-light-600 text-trim">
                                        {{company.signatory_name.first_name}}</div>
                                </div>
                                <div *ngIf="company.industry != null"
                                    class="fs-12rem fw-500 text-light-400 text-trim ms-3">
                                    {{company.industry.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Loader updations -->
            <ng-container *ngIf="infinityloader">
                <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
                    *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
            </ng-container>
        </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="!infinityloader">
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->

    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->

    <!-- view -->
    <div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel1 === true'>
        <form class="side-panel-container" autocomplete="off">
            <header class="side-panel-head">
                <h5>Details</h5>
                <a class="toggle-panel" (click)="showSidePanel1 = false;removeviewParam()"><i
                        class="icon-close-lg "></i></a>
            </header>
            <div class="side-panel-body" style="overflow-y: auto;">
                <div class="container-fluid px-32rem py-24rem">
                    <!-- View loader start -->
                    <app-view-loader *ngIf="loader1"></app-view-loader>
                    <!-- View loader end -->
                    <ng-container *ngIf="!loader1">
                        <div class="d-flex mb-16rem">
                            <p class="text-uppercase fw-500 mb-0"> COMPANY</p>
                            <a class="link-primary fs-14rem d-flex align-items-center ms-auto"
                                (click)="companyDetail(companyView.id)"
                                *ngIf="companyView.is_active && (permissions.e)">
                                <i class="icon-pencil-square me-1"></i> Edit
                            </a>
                        </div>
                        <div class="row row-16rem mb-16rem">
                            <div class="col-12">
                                <div class="card details-card shadow-0 px-24rem py-16rem">
                                    <div class="row row-16rem ">
                                        <div class="col-12 "><span class="title">COMPANY DETAILS</span></div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Company logo</div>
                                            <div class="detail-desc mt-8">

                                                <img class="avatar sq-56 radius-8" src={{companyView.logo}} alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Company name</div>
                                            <div class="detail-desc"> {{companyView.company_name}}</div>
                                        </div>

                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Status</div>
                                            <div class="detail-desc">
                                                <span class="fs-12rem fw-500 text-success text-uppercase"
                                                    *ngIf="companyView.is_active">ACTIVE</span>
                                                <span class="fs-12rem fw-500 text-danger text-uppercase"
                                                    *ngIf="companyView.is_active==false">DELETED</span>
                                            </div>
                                        </div>
                                        <div class=" col-12">
                                            <div class="detail-title">Registered address</div>
                                            <div class="detail-desc"> {{companyView.register_address}}</div>
                                        </div>

                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">City</div>
                                            <div class="detail-desc">{{companyView.city_name}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">State</div>
                                            <div class="detail-desc">{{companyView.state_name}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Country</div>
                                            <div class="detail-desc">{{companyView.country_name}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">PIN</div>
                                            <div class="detail-desc">{{companyView.pin}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Phone number</div>
                                            <div class="detail-desc">{{companyView.phone_code!=null &&
                                                companyView.phone_code!=''
                                                ?'+'+companyView.phone_code+'-':'-'}}{{companyView.phone_number!='' &&
                                                companyView.phone_number!=null ?companyView.phone_number:''}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Fax</div>
                                            <div class="detail-desc">{{companyView.fax_number!=''&&
                                                companyView.fax_number!=null ?companyView.fax_number:'-'}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Currency</div>
                                            <div class="detail-desc">{{companyView.currency}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Contact person</div>
                                            <div class="detail-desc">{{companyView.contact_person!='' &&
                                                companyView.contact_person!=null ?companyView.contact_person:'-'}}</div>


                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Industry</div>
                                            <div class="detail-desc">{{companyView.industry_name}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Signatory name</div>
                                            <div class="detail-desc">
                                                <img *ngIf="(companyView.signatory_name_detail !=null && (companyView.signatory_name_detail.profile_image != '' && companyView.signatory_name_detail.profile_image != null))"
                                                    class="user-img img-fluid rounded-circle sq-32rem"
                                                    src="{{companyView.signatory_name_detail.profile_image}}">
                                                <div *ngIf="(companyView.signatory_name_detail !=null && (companyView.signatory_name_detail.profile_image == '' || companyView.signatory_name_detail.profile_image == null))"
                                                    class="avatar-circle  fs-14rem fw-500 {{companyView.signatory_name_detail.color_code}} sq-32rem rounded-circle">
                                                    <span>{{this.appService.getFirstChar(companyView.signatory_name_detail.first_name,1)}}</span>
                                                </div>
                                                <div class="detail-desc p-2"
                                                    *ngIf="companyView.signatory_name_detail !=null && companyView.signatory_name_detail!='';else other_content">
                                                    {{(companyView.signatory_name_detail?.first_name+"
                                                    "+(companyView.signatory_name_detail?.middle_name
                                                    !=null?companyView.signatory_name_detail?.middle_name:'')+"
                                                    "+companyView.signatory_name_detail?.last_name+(companyView.signatory_name_detail?.employee_code!=null
                                                    && companyView.signatory_name_detail?.employee_code!=''?"
                                                    ("+companyView.signatory_name_detail?.employee_code+")":''))}}
                                                </div>
                                                <ng-template #other_content>
                                                    <div class="detail-desc p-2"> -
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">GSTIN</div>
                                            <div class="detail-desc">{{companyView.gstin!='null'&&
                                                companyView.gstin!=null?companyView.gstin:'-'}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </form>
    </div>
    <div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>

        <form [formGroup]="companyForm" (ngSubmit)="companyAdd()" class="side-panel-container" autocomplete="off">
            <header class="side-panel-head">
                <h5>{{slider_head}} Company</h5>
                <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg "></i></a>
            </header>
            <div class="side-panel-body" style="overflow-y: auto;">
                <div class="container-fluid px-32rem py-24rem">
                    <!-- Edit loader start -->
                    <app-edit-loader *ngIf="loader1"></app-edit-loader>
                    <!-- Edit loader end -->
                    <ng-container *ngIf="!loader1">

                        <p class="text-uppercase fw-500 mb-12rem">{{slider_head | uppercase}} COMPANY DETAILS</p>
                        <div class="row row-12rem mb-12rem">
                            <div *ngIf="imageUrl==''" class="col-md-4 col-sm-6 mx-auto">
                                <label for="company-logo" class="form-label required">Company logo</label>
                                <label for="company-logo"
                                    [ngClass]="{ 'is-invalid': (submitted ||  f.logo.touched || invalidFile) && (f.logo.errors || invalidFile) }"
                                    class="upload-btn {{is_active==false && create_flag==false?'disabled':''}} {{imageUrl!=''?'uploaded':''}}">
                                    <img *ngIf="imageUrl!=''" class="upload-img" [src]="imageUrl">
                                    <div class="upload-text"><span class="text-primary"> Upload </span> your company
                                        logo </div>
                                    <!-- <div class="upload-text"> <span class="text-primary">Upload </span> Other image</div> -->
                                    <!--<input type="file" (change)="openCropperDialog($event)"  class="form-control" id="company-logo"  placeholder="" hidden>-->
                                    <app-file-upload [crop]="true" [round]="false" [id]="inputId" [width]="504"
                                        [FileType]="'.png,.jpeg,.jpg'" (dataURL)="getURL($event)"
                                        (fileformat)="getfileformat($event)"
                                        (validsize)="getvalidsize($event)"></app-file-upload>
                                </label>
                                <div *ngIf="(submitted ||  f.logo.touched || invalidFile) && (f.logo.errors || invalidFile)"
                                    class="invalid-feedback">
                                    <div *ngIf="f.logo.errors.required && !(invalidFile)">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="invalidFile">{{errormsg}}</div>
                                </div>
                            </div>
                            <div *ngIf="imageUrl!=''" class="col-md-4 col-sm-6 mx-auto ">
                                <label for="company-logo" class="form-label required">Company logo</label>
                                <label for="company-logo"
                                    class="upload-btn {{is_active==false && create_flag==false?'disabled':''}} {{imageUrl!=''?'uploaded':''}}"
                                    [ngClass]="{ 'is-invalid': (invalidFile) }">
                                    <img class="upload-img" [src]="imageUrl" alt="">
                                    <div class="upload-text"> <span class="text-primary">Upload </span> New image</div>
                                    <button *ngIf="is_active==true" class=" btn-reset p-0 upload-delete "
                                        (click)="deleteLogo($event)">
                                        <i class="icon-trash"></i>
                                    </button>
                                    <!--<input type="file" (change)="openCropperDialog($event)" class="form-control" id="company-logo" placeholder="" hidden>-->
                                    <app-file-upload [crop]="true" [round]="false" [id]="inputId" [width]="504"
                                        [FileType]="'.png,.jpeg,.jpg'" (dataURL)="getURL($event)"
                                        (fileformat)="getfileformat($event)"
                                        (validsize)="getvalidsize($event)"></app-file-upload>
                                </label>


                            </div>
                            <div class="col-md-8">
                                <div class="row row-12rem">
                                    <div class="col-12 form-group form-row">
                                        <label for="company_name" class="form-label required">Company name</label>

                                        <input type="text" formControlName="company_name" class="form-control"
                                            autofill="comp_name"
                                            [ngClass]="{ 'is-invalid': (submitted || f.company_name.dirty || f.company_name.touched) && f.company_name.errors || f.company_name.errors?.exist}"
                                            id="company_name"
                                            placeholder="{{this.messageService.placeholderdisp('company name')}}"
                                            (keyup)="keyUp()">
                                        <!--<div class="invalid-feedback">Looks good!</div>-->
                                        <!-- <div *ngIf="f.company_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.company_name.errors.required">Name is required.</div>
                                        </div> -->
                                        <div *ngIf="(submitted || f.company_name.dirty || f.company_name.touched) && f.company_name.errors || f.company_name.errors?.exist"
                                            class="invalid-feedback">
                                            <!-- <div *ngIf="company_name_err; else name_error">{{c_name_error}}</div> -->
                                            <!-- <div *ngIf="c_name_error!=''; else name_error">{{c_name_error}}</div> -->
                                            <!-- <ng-template #name_error> -->
                                            <div *ngIf="f.company_name.hasError('exist')">
                                                {{this.messageService.validationDisplay(f.company_name.value)}}</div>
                                            <div *ngIf="f.company_name.errors?.required">
                                                {{this.messageService.validationDisplay('required')}}</div>
                                            <div *ngIf="f.company_name.errors?.pattern">
                                                {{this.messageService.validationDisplay('pattern')}}</div>
                                            <div
                                                *ngIf="!(f.company_name.errors?.pattern) && f.company_name.errors?.maxlength">
                                                {{this.messageService.fieldlengthvalidation('word','100')}}
                                            </div>
                                            <!-- </ng-template> -->
                                        </div>
                                    </div>
                                    <div class="col-12 form-row">
                                        <label for="register_address" class="form-label required">Registered
                                            address</label>
                                        <input type="text" formControlName="register_address" autocomplete="none"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': (submitted || f.register_address.dirty || f.register_address.touched) && (f.register_address.errors || !addValid) }"
                                            name="" id="register_address"
                                            placeholder="{{this.messageService.placeholderdisp('full address')}}">
                                        <div *ngIf="(submitted || f.register_address.dirty || f.register_address.touched) && (f.register_address.errors || !addValid)"
                                            class="invalid-feedback">
                                            <div *ngIf="f.register_address.errors.required">
                                                {{this.messageService.validationDisplay('required')}}</div>
                                            <!-- <div *ngIf="f.register_address.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div> -->
                                            <div
                                                *ngIf="!(f.register_address.errors.pattern) && f.register_address.errors.maxlength">
                                                {{this.messageService.fieldlengthvalidation('word','500')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row row-12rem ">
                            <div class="col-12 col-md-6 form-row ">
                                <label for="pin" class="form-label required">PIN</label>
                                <input type="text" formControlName="pin" class="form-control"
                                    [ngClass]="{ 'is-invalid': (submitted || f.pin.dirty || f.pin.touched) && (f.pin.errors || !pinValid) }"
                                    name="" id="pin" value="151001" placeholder="Enter PIN">
                                <div *ngIf="(submitted || f.pin.dirty || f.pin.touched) && (f.pin.errors || !pinValid)"
                                    class="invalid-feedback">
                                    <div *ngIf="f.pin.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.pin.errors.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="!(f.pin.errors.pattern) && f.pin.errors.maxlength">
                                        {{this.messageService.validationdigits('Pin','6')}}</div>
                                    <div *ngIf="!(f.pin.errors.pattern) && f.pin.errors.minlength">
                                        {{this.messageService.validationdigits('Pin','6')}}</div>
                                    <!-- <div *ngIf="f.pin.errors.pattern && f.pin.errors.maxlength; else second">Enter a valid PIN. Maximum 6 characters allowed.</div>
                                    <ng-template #second>
                                        <ng-container *ngIf="f.pin.errors.pattern; else third">Enter a valid PIN.</ng-container>
                                    </ng-template>
                                    <ng-template #third>
                                        <ng-container *ngIf="f.pin.errors.maxlength || f.pin.errors.minlength">Pin should be 6 digits.</ng-container>
                                    </ng-template> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="country" class="form-label required">Country</label>
                                <!-- <select (change)="regionList()" formControlName="country" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.country.errors }" name="" id="country">
                                    <option value="" hidden disabled selected>Select Country</option>
                                    <ng-container *ngFor="let li of country_list" >
                                    <option value="{{li.id}}">{{li.name}}</option>
                                    </ng-container>
                                </select> -->
                                <ng-select class="form-ngselect" formControlName="country"
                                    [ngClass]="{ 'is-invalid': (submitted || f.country.touched) && f.country.errors }"
                                    name="" id="country"
                                    placeholder="{{this.messageService.selectplaceholddisplay('country')}}"
                                    (change)="countrySelectFun()">
                                    <ng-option *ngFor="let li of country_list" [value]="li.id">{{li.name}}</ng-option>
                                </ng-select>
                                <div *ngIf="(submitted || f.country.touched) && f.country.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.country.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="state" class="form-label required">State </label>
                                <!-- <select  class="form-select" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" name="" id="state">
                                    <option value="" hidden disabled selected>Select State</option>
                                    <ng-container *ngFor="let li of region_list" >
                                    <option value="{{li.id}}">{{li.name}}</option>
                                    </ng-container>
                                </select> -->
                                <ng-select [readonly]="!CountrySelect" class="form-ngselect" formControlName="state"
                                    [ngClass]="{ 'is-invalid': (submitted || f.state.touched) && f.state.errors }"
                                    name="" id="state"
                                    placeholder="{{this.messageService.selectplaceholddisplay('state')}}"
                                    (change)="stateSelectFun(); f.city.reset(); StateSelect=true;">
                                    <ng-option *ngFor="let li of region_list" [value]="li.id">{{li.name}}</ng-option>
                                </ng-select>
                                <div *ngIf="(submitted || f.state.touched) && f.state.errors" class="invalid-feedback">
                                    <div *ngIf="f.state.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                </div>

                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="city" class="form-label required">City </label>
                                <ng-select [readonly]="!StateSelect" class="form-ngselect" formControlName="city"
                                    [ngClass]="{ 'is-invalid': (submitted || f.city.touched) && f.city.errors }" name=""
                                    id="city" placeholder="{{this.messageService.selectplaceholddisplay('city')}}">
                                    <ng-option *ngFor="let li of city_list" [value]="li.id">{{li.name}}</ng-option>
                                </ng-select>

                                <div *ngIf="(submitted || f.city.touched) && f.city.errors" class="invalid-feedback">
                                    <div *ngIf="f.city.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                </div>
                            </div>


                            <div class="col-12 col-md-6 form-row">
                                <label for="phone_number" class="form-label">Phone number</label>
                                <!-- <div class="input-group1 "
                                    [ngClass]="{ 'is-invalid': (submitted || f.phone_number.dirty || f.phone_number.touched) &&  f.phone_number.errors || !mobValid}">
                                    <ng-select class="form-ngselect" style="width: 6rem; " [clearable]="false"
                                        formControlName="country_code">
                                        <ng-option value="india">
                                            <img src="../../../../../assets/images/flag-India.png" class="p-1"
                                                width="20px" height="20px" />
                                            +91
                                        </ng-option>
                                    </ng-select>
                                    <input type="text" class=" form-control" name="" id="phone_number"
                                        placeholder="{{this.messageService.placeholderdisp('number')}}"
                                        formControlName="phone_number"
                                        [ngClass]="{ 'is-invalid': (submitted || f.phone_number.dirty || f.phone_number.touched) &&  (f.phone_number.errors || !mobValid)}">
                                </div> -->
                                <div class="d-flex position-relative"
                                    [ngClass]="{ 'is-invalid': (submitted || f.phone_number.dirty || f.phone_number.touched) &&  f.phone_number.errors || !mobValid}">
                                    <!-- <ng-select class="form-ngselect" formControlName="phone_code"
                                    [ngClass]="{ 'is-invalid': (submitted || f.phone_number.dirty || f.phone_number.touched) &&  f.phone_number.errors || !mobValid || f.phone_code.errors }" placeholder="+"
                                    style="width: 9.375rem;">
                                    <ng-option *ngFor="let li of phoneCode" [value]="li.phone">{{"+"+li.phone}}
                                    </ng-option>
                                </ng-select> -->
                                    <div class=" flag_div position-absolute gap-8 flex-center">
                                        <img class="img-fluid " src="../../../../../assets/images/flag-India.png"
                                            style="width: 1.25rem; ">
                                        <div class=""> +91 </div>
                                    </div>
                                    <!-- <div *ngIf="submitted && f.phone_code.errors" class="invalid-feedback">
                                    <div *ngIf="f.phone_code.errors.required">Country is required.</div>
                                </div> -->

                                    <input type="text" class=" form-control" style="padding-left:4.3125rem;" name=""
                                        id="phone_number"
                                        placeholder="{{this.messageService.placeholderdisp('number')}}"
                                        formControlName="phone_number"
                                        [ngClass]="{ 'is-invalid': (submitted || f.phone_number.dirty || f.phone_number.touched) &&  (f.phone_number.errors || !mobValid)}">

                                </div>
                                <!-- <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                                    <div *ngIf="f.phone_number.errors.pattern">Please enter a valid contact no.</div>
                                </div> -->
                                <div *ngIf="(submitted || f.phone_number.dirty || f.phone_number.touched) && (f.phone_number.errors || !mobValid)"
                                    class="invalid-feedback">
                                    <div *ngIf="f.phone_number.errors.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="!(f.phone_number.errors.pattern) && f.phone_number.errors.maxlength">
                                        {{this.messageService.validationdigits('Contact number','10')}}
                                    </div>
                                    <div *ngIf="!(f.phone_number.errors.pattern) && f.phone_number.errors.minlength">
                                        {{this.messageService.validationdigits('Contact number','10')}}
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="fax_number" class="form-label">Fax</label>
                                <input type="text" formControlName="fax_number" class="form-control" name=""
                                    id="fax_number"
                                    [ngClass]="{ 'is-invalid': (submitted || f.fax_number.dirty ) &&  (f.fax_number.errors || !faxValid)}"
                                    placeholder="{{this.messageService.placeholderdisp('fax number')}}">
                                <div *ngIf="(submitted || f.fax_number.dirty ) && (f.fax_number.errors || !faxValid)"
                                    class="invalid-feedback">
                                    <div *ngIf="f.fax_number.errors.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="!(f.fax_number.errors.pattern) && f.fax_number.errors.maxlength">
                                        {{this.messageService.validationdigits('Fax','10')}}</div>
                                    <div *ngIf="!(f.fax_number.errors.pattern) && f.fax_number.errors.minlength">
                                        {{this.messageService.validationdigits('Fax','10')}}</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="currency" class="form-label required">Currency</label>
                                <app-master-select
                                    [ngClass]="{ 'is-invalid': (submitted || f.currency.touched) && f.currency.errors }"
                                    [placeholder]="currencyPlaceHolder" id="currency" [submitted]="submitted"
                                    [selectedItem]="f.currency.value" formControlName="currency" [type]="currency"
                                    [isrequired]="isrequired"></app-master-select>
                                <div *ngIf="(submitted || f.currency.touched) && f.currency.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.currency.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                </div>

                                <!-- <select formControlName="currency" class="form-select" name="" id="currency" [ngClass]="{ 'is-invalid': submitted && f.currency.errors }">
                                    <option value="" hidden disabled selected>Select Currency </option>
                                    <option value="INR">INR</option>
                                    <option value="AED">AED</option>
                                    <option value="USD">USD</option>
                                </select> -->
                                <!-- <ng-select class="form-ngselect"
                                            formControlName="currency"
                                            [ngClass]="{ 'is-invalid': submitted && f.currency.errors }"
                                            name=""  id="currency"
                                            placeholder="Select Currency ">
                                    <ng-option *ngFor="let li of ['INR', 'AED',  'USD']" value="{{li}}">{{li}}</ng-option>
                                 </ng-select> -->

                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="contact_person" class="form-label ">Contact person</label>
                                <!-- <select formControlName="signatory_name" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.signatory_name.errors }" id="signatory_name">
                                    <option value="" hidden disabled selected>Select Person</option>
                                    <ng-container *ngFor="let li of user_list" >
                                    <option value="{{li.id}}">{{li.first_name}}&nbsp;{{li.last_name}}</option>
                                    </ng-container>
                                </select> -->
                                <input type="text" formControlName="contact_person" class="form-control"
                                    name="contact_person" id="contact_person"
                                    [ngClass]="{ 'is-invalid': (submitted || f.contact_person.dirty || f.contact_person.touched) && f.contact_person.errors || f.contact_person.errors?.exist}"
                                    placeholder="{{this.messageService.placeholderdisp('contact person')}}">

                                <div *ngIf="(submitted || f.contact_person.dirty || f.contact_person.touched) && f.contact_person.errors || f.contact_person.errors?.exist"
                                    class="invalid-feedback">

                                    <div *ngIf="f.contact_person.hasError('exist')">
                                        {{this.messageService.validationDisplay(f.contact_person.value)}}</div>

                                    <div *ngIf="f.contact_person.errors?.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div
                                        *ngIf="!(f.contact_person.errors?.pattern) && f.contact_person.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('word','100')}}
                                    </div>

                                </div>

                                <!-- <ng-select class="form-ngselect"  formControlName="contact_person" placeholder="{{this.messageService.selectplaceholddisplay('Contact Person')}}">
                              <ng-option *ngFor="let item of user_list" [value]="item.id">
                              <div class="d-inline-flex  align-items-center">

                                  <img class="avatar-sm me-8 sq-32rem img-fluid rounded-circle"  *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                                  <span *ngIf="item.profile_image ==  ''|| item.profile_image == null" class="avatar-sm sq-32rem me-8 rounded-circle bg1" >
                                    <span>
                                      {{this.appService.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                                    </span>
                                  <span *ngIf="item.employee_code !=  '' && item.employee_code != null">
                                      {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":" ")+item.last_name+" ("+item.employee_code+")"}}
                                  </span>
                                  <span *ngIf="item.employee_code ==  '' || item.employee_code == null">
                                    {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":" ")+item.last_name}}
                                  </span>
                                </div>
                              </ng-option>
                            </ng-select> -->
                                <!-- <div *ngIf="(submitted || f.contact_person.touched) && f.contact_person.errors"
                                class="invalid-feedback">
                                <div *ngIf="f.contact_person.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            </div> -->
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="industry" class="form-label required">Industry</label>
                                <!-- <select formControlName="industry" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.industry.errors }" id="industry">
                                    <option value=""  hidden disabled selected>Select Industry</option>
                                    <ng-container *ngFor="let li of industry_list" >
                                    <option value="{{li.id}}">{{li.name}}</option>
                                    </ng-container>
                                </select> -->
                                <ng-select class="form-ngselect" formControlName="industry"
                                    [ngClass]="{ 'is-invalid': (submitted || f.industry.touched) && f.industry.errors }"
                                    name="" id="industry"
                                    placeholder="{{this.messageService.selectplaceholddisplay('industry')}}">
                                    <ng-option *ngFor="let li of industry_list" [value]="li.id">{{li.name}}</ng-option>
                                </ng-select>
                                <div *ngIf="(submitted || f.industry.touched) && f.industry.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.industry.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="signatory_name" class="form-label ">Signatory name</label>
                                <!-- <select formControlName="signatory_name" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.signatory_name.errors }" id="signatory_name">
                                    <option value="" hidden disabled selected>Select Signatory name</option>
                                    <ng-container *ngFor="let li of user_list" >
                                    <option value="{{li.id}}">{{li.first_name}}&nbsp;{{li.last_name}}</option>
                                    </ng-container>
                                </select> -->




                                <ng-select
                                    placeholder="{{this.messageService.selectplaceholddisplay('signatory name')}}"
                                    formControlName="signatory_name" [items]="user_list" class="form-ngselect"
                                    bindValue="id" bindLabel="first_name">
                                    <ng-template ng-label-tmp let-item="item">
                                        <span class="ng-value-label">
                                            <div class="d-inline-flex  align-items-center">

                                                <img class="avatar-sm me-8 sq-32rem img-fluid rounded-circle"
                                                    *ngIf="item.profile_image !=  '' && item.profile_image != null"
                                                    src="{{item.profile_image}}">
                                                <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                                                    class="avatar-sm sq-32rem me-8 rounded-circle {{item.color_code}}">
                                                    <span>
                                                        {{this.appService.getFirstChar(item.first_name+"
                                                        "+item.last_name,2)}}</span>
                                                </span>
                                                <span *ngIf="item.employee_code !=  '' && item.employee_code != null">
                                                    {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":"
                                                    ")+item.last_name+" ("+item.employee_code+")"}}
                                                </span>
                                                <span *ngIf="item.employee_code ==  '' || item.employee_code == null">
                                                    {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":"
                                                    ")+item.last_name}}
                                                </span>
                                            </div>
                                        </span> </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                        <div class="d-inline-flex  align-items-center">

                                            <img class="avatar-sm me-8 sq-32rem img-fluid rounded-circle"
                                                *ngIf="item.profile_image !=  '' && item.profile_image != null"
                                                src="{{item.profile_image}}">
                                            <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                                                class="avatar-sm sq-32rem me-8 rounded-circle {{item.color_code}}">
                                                <span>
                                                    {{this.appService.getFirstChar(item.first_name+"
                                                    "+item.last_name,2)}}</span>
                                            </span>
                                            <span *ngIf="item.employee_code !=  '' && item.employee_code != null">
                                                {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":"
                                                ")+item.last_name+" ("+item.employee_code+")"}}
                                            </span>
                                            <span *ngIf="item.employee_code ==  '' || item.employee_code == null">
                                                {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":"
                                                ")+item.last_name}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                                <!-- <div *ngIf="(submitted || f.signatory_name?.touched) && f.signatory_name.errors"
                                class="invalid-feedback">
                                <div *ngIf="f.signatory_name.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            </div> -->
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="gstin" class="form-label">GSTIN</label>
                                <input [readonly]="!countryInd" type="text" formControlName="gstin" class="form-control"
                                    [ngClass]="{ 'is-invalid': (submitted || f.gstin.dirty ) && (f.gstin.errors || !gstValid) }"
                                    placeholder="" value="GST87383872387" id="gstin" placeholder="Enter GSTIN">
                                <div *ngIf="(submitted || f.gstin.dirty) && (f.gstin.errors || !gstValid)"
                                    class="invalid-feedback">
                                    <div *ngIf="f.gstin.errors.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="!(f.gstin.errors.pattern) && f.gstin.errors.maxlength">
                                        {{this.messageService.validationdigits('GST','15')}}</div>
                                    <div *ngIf="!(f.gstin.errors.pattern) && f.gstin.errors.minlength">
                                        {{this.messageService.validationdigits('GST','15')}}</div>
                                </div>
                                <!--<div *ngIf="submitted && !gstValid" class="invalid-feedback">
                                    <div *ngIf="!gstValid">{{gstError}}</div>
                                </div>-->
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <footer class="side-panel-footer gap-32rem" *ngIf="!loader1">
                <button *ngIf="create_flag==false && permissions.d" type="button"
                    class="btn btn-outline-danger text-uppercase btn-sm"
                    [disabled]="is_active==false && create_flag==false"
                    (click)="deleteCompany(company_id)">DELETE</button>
                <button *ngIf="(permissions.e && create_flag==false) || (permissions.a && create_flag==true)"
                    type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                    [disabled]="(is_active==false && create_flag==false) || submitted == true || !companyForm.valid">{{create_flag
                    == true ? 'SAVE':'SAVE'}}</button>

            </footer>
        </form>
    </div>


    <div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-header gap-16rem">
                    <i class="bi bi-exclamation-triangle text-danger"></i>
                    <h4 class="modal-title">Delete Company?</h4>
                </div>
                <div class="modal-body">
                    <p class="mb-0">{{this.messageService.Deletemsgdisplay('Company')}}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                        (click)="deleteToggle = false">Cancel</button>
                    <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
                        (click)="confirmDelete()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>
