import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute,   Params,   Router } from '@angular/router';
import { CompanyService } from '../../../../@core/services/company.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { GstnumValidator } from 'src/app/@shared/validators/gstnum.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';

import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'
import { SnapshotService } from 'src/app/@core/services/snapshot.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  currentstate:any;
  companyForm: any;
  res_body: any;
  user_list: any;
  industry_list: any;
  country_list: any;
  region_list: any;
  city_list: any;
  file_object: any;
  companyView:any =[];
  submitted = false;
  create_flag = true;
  is_active: any = true;
  isrequired = true;
  company_id:any = 0;
  logo_flag = false;
  status_flag: any = true;
  slider_head:string="";
  c_name_error = '';
  company_name_err = false;
  searchlistdata:any = [];
  companysearch:string =this.messageService.searchdisplay('company');
  deleteToggle= false;
  deleteId : any;
  currency:string ="currency";
  currencyPlaceHolder = this.messageService.selectplaceholddisplay('currency');
  inputId: string = "company-logo";
  FileType: string = "image/png, image/gif, image/jpeg"
  signatory:any;
  contact:any;
   @ViewChild(SearchbarComponent) child:any;

  CountrySelect = false;
  StateSelect = false;

  gstValid = true;
  pinValid = true;
  mobValid = true;
  faxValid = true;
  countryInd = false;
  gstLength = true;
  addValid = true;
  deleteClicked =false
  searchKeyword = ""
  public company_list_flag: boolean = true;
  public companyName: string = '';

  public imageUrl: any = "";
  msg:any = '';
  loader = false;
  errormsg:any          = "";
  invalidFile           = false;
  statusBtn:any         = "Active";

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  companyDetailsList:any  = [];
  infinityloader          = false;

  inputFocusClass = false;
  companyDetails:any = [];
  showSidePanel = false;
  showSidePanel1 = false;
  loader1 = false;
  permissions:any = [];
  phoneCode:any[]             = [];

  companyUnit : any
  routeComponent : any
  validated : boolean = false
  editflag :boolean = false
  value:any
  isLoading = false
  searchData : Subject<void> = new Subject();
  compId = 0
  private subjectKeyUp = new Subject<any>();

constructor(
    private companyService: CompanyService,
    public router: Router,
    public formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public messageService : MessageService,
    private route: ActivatedRoute,
    public existDataService :  ExistDatasService,
    public SnapshotService:SnapshotService
  ) {  }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.getPermission();
    this.route.params
      .subscribe(params => {
        if(params.view && this.permissions != undefined){
          this.viewCompany(params.view)
          this.route.snapshot.params = [];
        }
      }
    );
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });

    this.route.url.subscribe((res:any)=>{
      this.routeComponent = res[0].path
    })

    this.searchData.pipe(delay(1000),
    switchMap(()=> this.companyService.getcompanyList(this.status_flag,this.compId,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })

    this.companyForm = this.formBuilder.group({
      logo: ['', [Validators.required]],
      company_name: ['', [Validators.required,UnamepatternValidator(), Validators.maxLength(100)]],
      register_address: ['', [Validators.required, Validators.maxLength(500)]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      pin: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6),NumberpatternValidator()]],
      phone_code: [''],
      phone_number: ['', [Validators.minLength(10), Validators.maxLength(10),NumberpatternValidator()]],
      contact_person: ['', [NamefieldValidator(), Validators.maxLength(100)]],
      industry: ['', [Validators.required]],
      signatory_name: [null],
      fax_number: ['', [Validators.minLength(10), Validators.maxLength(10),NumberpatternValidator()]],
      currency: ['', [Validators.required]],
      gstin: ['', [Validators.minLength(15), Validators.maxLength(15),GstnumValidator()]],
    });
    this.companyList();
    this.industryList();
    this.countryList();
    this.cityList();
    this.userList();
    this.regionList();
    this.getPhonecode();

  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.companyForm.controls; }
  getPhonecode(){

    this.companyService.getCountryCode().subscribe((res:any)=>{
      this.phoneCode  = res.body;

    })

  }


  //Add company
  addCompany() {
    this.companyForm.reset();
    this.CountrySelect    = false;
    this.StateSelect      = false;
    this.imageUrl         = '';
    this.slider_head      ="Add";
    this.companyForm.enable();
    this.create_flag      = true;
    this.submitted        = false;
    this.company_id       = 0;
    this.is_active        = true;
    this.logo_flag        = false;
    this.c_name_error     = '';
    this.company_name_err = false;
    this.gstValid         = true;
    this.pinValid         = true;
    this.mobValid         = true;
    this.faxValid         = true;
    this.addValid         = true;
    this.showSidePanel    = true;
    this.invalidFile      = false;
    this.errormsg         = '';
    this.editflag = false
    this.child.clearBar();
  }

  /***************** COUNTRY SELECT **************************/
  countrySelectFun() {
    this.companyForm.get('state').reset();
    this.companyForm.get('city').reset();
    this.region_list = [];
    this.city_list   = [];
    this.regionList();

    this.CountrySelect = true;
    if(this.companyForm.value.country == 105) {
      this.countryInd = true;
    } else {
      this.companyForm.get('gstin').reset();
      this.countryInd = false;
    }
  }
  /********* STATUS FILTER **************/
  selectItemList(value:any){
    this.offset            = 0;
    this.companyDetails = [];
    this.status_flag = value;

    this.child.clearBar();
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.companyList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.companyDetails.push(this.companyDetailsList[i]);
    }

    }
  /**************** METHOD FOR GETTING COMPANY LIST *********************/
  companyList() {
    this.loader = true;
    this.companyService.getcompanyList(this.status_flag,'',this.defaultlimit, this.offset,this.searchKeyword)
    .subscribe((res: any) => {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res.body.results.length;
    if(this.offset ==0){
      this.companyDetails = [];
    }
    if (res.body.results.length != 0) {
      this.companyDetailsList = res.body.results;
    }
    if (res.body.results.length == 0) {
      this.company_list_flag = this.offset == 0 ? false:true;
    }else{
      this.company_list_flag = true;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }
  /************ METHOD FOR GETTING USER LIST FOR CONTACT PERSON AND SIGNATORY NAME ***********/
  userList() {
    this.companyService.getUserList()
    .subscribe((res: any) => {

        this.user_list = res.body;

    })
  }

  /**************** METHOD FOR GETTING INDUSTRY LIST *********************/
  industryList() {
    this.companyService.getIndustryList()
    .subscribe((res: any) => {

      if (res.length != 0) {
        this.industry_list = res.body;
      }
    })
  }

  /**************** METHOD FOR GETTING COUNTRY LIST *********************/
  countryList() {
    this.companyService.getCountryList()
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.country_list = res.body;
      }
    })
  }

  /**************** METHOD FOR GETTING REGION LIST *********************/
  regionList() {
    if (typeof this.companyForm.value.country === 'number'){
      this.companyService.getRegionList(this.companyForm.value.country)
      .subscribe((res: any) => {
        if (res.length != 0) {
          this.region_list = res.body;
        }
      })
    }
  }

  /**************** METHOD FOR GETTING CITY LIST *********************/
  stateSelectFun(){
    this.companyForm.get('city').reset();
    this.city_list = [];
    if(this.companyForm?.value?.state != null){
      this.cityList();
    }
  }

  cityList() {
    this.companyService.getCityList(this.companyForm.value.state, 'true')
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.city_list = res.body;
      }
    })
  }

  searchCompany(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.companyDetails = [];
    this.compId = 0
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.offset = 0;
      this.companyDetails = [];
      this.searchKeyword  = "";
      this.compId = 0
      this.searchData.next()
    }else{
      this.compId = data
      this.searchData.next();
    }
  }


  deleteCompany(id:any){
    this.deleteId = id
    this.deleteToggle = true
  }

  confirmDelete(){
    this.deleteClicked = true
    this.companyService.companyDelete(this.deleteId).subscribe((res: any) => {

      if(res.status == 200){
        this.deleteToggle = false
        this.showSidePanel = false
        this.offset            = 0;
        this.companyDetails = [];
        this.deleteClicked = false
        this.companyList();
      }
    },
    (err: any) => {
      this.deleteClicked = false
      this.deleteToggle = false
      this.showSidePanel = false
    });
  }


  getURL(event: any) {
    this.invalidFile = false;
    this.errormsg = "";
    this.imageUrl = event;
    this.logo_flag = true;
    this.companyForm.get('logo').setValue(event);
  }


  //Delete Logo
  deleteLogo(event: any) {
    this.invalidFile = false;
    this.errormsg = "";
    this.companyForm.get('logo').setValue('');
    this.imageUrl = '';
    this.logo_flag = true;
  }

  /**************** METHOD FOR CREATING COMPANY *********************/

  isNullOrWhitespace(input: any) {
    return !input || !input.trim();
  }

  gstError = '';
  companyAdd() {
    this.c_name_error = '';
    this.company_name_err = false;
    this.submitted = true;

    if(this.companyForm.valid){
      if(this.companyForm.value.phone_number!=null && this.companyForm.value.phone_number!='')
        this.companyForm.get('phone_code').setValue('91');
      this.companyService.companyCreate(this.companyForm.value, this.company_id)
      .subscribe((res: any) => {
        if (res) {
          if(this.SnapshotService.getCompanyID() == this.company_id)
            this.SnapshotService.setCompanyLogo(this.companyForm.value.logo,this.companyForm.value.company_name);
          this.showSidePanel = false;
          this.offset            = 0;
          this.companyDetails = [];
          this.companyList();
        } else {
        }
      },
      (err: any) => {
        this.submitted = false;
        this.c_name_error = err.error.detail;
        this.company_name_err = true;
      })
    } else {
      this.submitted = false;
    }
  }

  /**************** METHOD FOR COMPANY DETAIL *********************/
  companyDetail(id: number){
    this.showSidePanel1 =false;
    this.showSidePanel = true;
    this.submitted = false
    this.loader1 = true;
    this.companyService.companyGet(id).subscribe((res: any) => {
      this.loader1 = false;
      if(res.status == 200) {
        Object.entries(res.body).forEach(([key, value]) => {
          if(key != 'created_at' && key != 'modified_at' && key != 'created_by' && key != 'modified_by' && key != 'city_name' && key != 'state_name' && key != 'country_name' && key != 'contact_person_detail' && key != 'industry_name' && key != 'signatory_name_detail') {
            if(key == 'id') {
              this.create_flag  = false;
              this.company_id = value;
            } else if(key == 'is_active'){
              this.is_active = value;
              if(this.is_active==true){
                this.companyForm.enable();
                this.slider_head = "Edit";
                this.editflag = true
              }
              else{
                this.companyForm.disable();
                this.slider_head = "View";
              }

            } else if(key == 'currency'){
              this.companyForm.get(key).patchValue(value);
            }
            else if(key == 'phone_code' || key == 'fax_number' ||  key == 'gstin'){
              if(value == null || value == '' || value == 'null') {
                this.companyForm.get(key).setValue(null);
              } else {
                this.companyForm.get(key).setValue(value);
              }
            }
            else {
              this.companyForm.get(key).setValue(value);
            }
          }
        });
        if(this.companyForm.value.country == 105) {
          this.countryInd = true;
        } else {
          this.companyForm.get('gstin').reset();
          this.countryInd = false;
        }
        this.CountrySelect = true;
        this.StateSelect = true;
        this.imageUrl = this.companyForm.value.logo;
        this.logo_flag = false;
        this.gstValid = true;
        this.pinValid = true;
        this.mobValid = true;
        this.faxValid = true;
        this.addValid = true;
        this.cityList();
        this.regionList();
      }
    });
  }

  viewCompany(id:any){
    if(this.permissions.v){
      this.loader1 = true;
      this.showSidePanel1 = true;
      this.companyService.companyGet(id).subscribe((res: any) => {
        this.companyView = res.body;
        this.contact = this.companyView.contact_person_detail;
        this.signatory = this.companyView.contact_person_detail;
        this.loader1 = false;
      })
    }
  }
  getfileformat(event:any){
    this.companyForm.get('logo').setValue('');
    this.imageUrl    = '';
    this.invalidFile = false;
    if(event==false){
      this.invalidFile = true;
      this.errormsg = this.messageService.validateFileFormat('jpeg,png,jpg');
    }
  }

  getvalidsize(event:any){
    this.companyForm.get('logo').setValue('');
    this.imageUrl    = '';
    this.invalidFile = true;
    this.errormsg    = event;
  }

  removeviewParam(){
    const urlArray = this.router.url.split(';');
    this.router.navigate([urlArray[0]]);
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":this.routeComponent,
        "field":"company_name",
        "value": this.f.company_name.value
      }
    }else{
       this.value ={
        "id":this.company_id,
        "page":this.routeComponent,
        "field":"company_name",
        "value": this.f.company_name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.companyUnit = res.body

      this.loader = false

      if(this.companyUnit.data ==='invalid'){
        this.f.company_name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.company_name.value)
  }
}
