
<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="inventoryForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
                <div class="row row-16 mb-16">
                  <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.assigned_on.errors}">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Assigned on</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="assigned_on" type="radio" id="attendance_month1"
                                class="form-check-input  " formControlName="assigned_on" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'assigned_on','last_month','specific_month','specific_year');setRequired(false)">
                            <label for="attendance_month1" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="assigned_on" type="radio" id="attendance_month2"
                                class="form-check-input " formControlName="assigned_on" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'assigned_on','this_month','specific_month','specific_year');setRequired(false)">
                            <label for="attendance_month2" class="form-check-label">Current month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="assigned_on" type="radio" id="attendance_month3"
                                class="form-check-input " formControlName="assigned_on" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'assigned_on',true,'specific_month','specific_year');setRequired(true)">
                            <label for="attendance_month3" class="form-check-label">Specific month</label>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="inventoryForm.value.assigned_on==true">

                    <div class="col-12 form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                        <label for="" class="form-label">Year </label>
                        <ng-select class="form-ngselect h-36 fs-14"
                        formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                            <ng-option *ngFor="let yData of Years"
                                [value]="yData">{{yData}}</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                        <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                    <div class="col-12 form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched) &&  f?.specific_month?.errors}">
                        <label for="" class="form-label">Month </label>
                        <app-master-select class="config-app-master" style="width:9rem"
                        [placeholder]="month_placeholder"
                                id="month" [isrequired]="false" [selectedItem]="inventoryForm.value.specific_month"
                               [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                                (ngModelChange)="attendancemonthChangeFn($event)" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched) &&  f?.specific_month?.errors}"> </app-master-select>
                    </div>
                    <div *ngIf="(submitted || f?.specific_month?.touched)&& f?.specific_month?.errors" class="invalid-feedback">
                        <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>

                    </ng-container> -->
                    <ng-container *ngIf="inventoryForm.value.assigned_on==true">

                      <div class="col-6">
                          <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                              <label for="" class="form-label">Year </label>
                              <ng-select class="form-ngselect  fs-14"
                              formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                  <ng-option *ngFor="let yData of Years"
                                      [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                          </div>
                          <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                              <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                      <div class="col-6">
                          <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}">
                              <label for="" class="form-label">Month </label>
                              <app-master-select class="config-app-master" style="width:9rem"
                              [placeholder]="month_placeholder"
                                      id="month" [isrequired]="false" [selectedItem]="inventoryForm.value.specific_month"
                                  [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                                      (ngModelChange)="attendancemonthChangeFn($event)" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}"> </app-master-select>
                          </div>
                          <div *ngIf="(submitted || f?.specific_month?.touched || f?.specific_month?.dirty)&& f?.specific_month?.errors" class="invalid-feedback">
                              <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                  </ng-container>
                  </div>
                  <div *ngIf="(submitted) && f.assigned_on.errors" class="invalid-feedback">
                      <div *ngIf="f.assigned_on.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>

                  <div class="col-12 ">
                    <label for="name" class="form-label text-uppercase fw-500">Asset category</label>

                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('asset category')}}" formControlName="asset_category" [items]="categoryDD" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" >
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                        Select All
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-inline-flex align-items-center overflow-hidden w-100">
                          <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>

                          <span class="text-trim">{{item.name}}</span>
                        </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="inventoryForm.value.asset_category?.length">
                          <span class="ng-value-label">{{ inventoryForm.value.asset_category.length }} Category Selected.</span>
                        </div>
                      </ng-template>
                    </ng-select>
                    <ul class="selected-values mt-16" *ngIf="inventoryForm.value.asset_category?.length >0">
                      <li class="ng-value" *ngFor=" let item of inventoryForm.value.asset_category; let ki = index" >
                        <ng-container *ngFor="let insideitem of categoryDD; let k = index">
                          <span class="ng-value-label" *ngIf="insideitem['id'] == item">
                            {{insideitem["name"].length > 15? (insideitem["name"] | slice : 0 : 15) + "..": insideitem["name"]}}</span >
                        </ng-container>
                        <span  class="ng-value-icon right" (click)="clearcommonForm('asset_category', ki)" aria-hidden="true">×</span>
                      </li>
                    </ul>
                  </div>

                  <div class="col-12">
                    <label for="name" class="form-label text-uppercase fw-500">Asset subcategory</label>

                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('asset subcategory')}}" formControlName="asset_sub_category" [items]="subCategoryDD" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                        Select All
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-inline-flex align-items-center overflow-hidden w-100">
                          <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>

                          <span class="text-trim">{{item.name}}</span>
                        </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="inventoryForm.value.asset_sub_category?.length">
                          <span class="ng-value-label">{{ inventoryForm.value.asset_sub_category.length }} Subcategory Selected.</span>
                        </div>
                      </ng-template>
                    </ng-select>
                    <ul class="selected-values mt-16" *ngIf="inventoryForm.value.asset_sub_category?.length >0">
                      <li class="ng-value" *ngFor=" let item of inventoryForm.value.asset_sub_category; let ki = index" >
                        <ng-container *ngFor="let insideitem of subCategoryDD; let k = index">
                          <span class="ng-value-label" *ngIf="insideitem['id'] == item">
                            {{insideitem["name"].length > 15? (insideitem["name"] | slice : 0 : 15) + "..": insideitem["name"]}}</span >
                        </ng-container>
                        <span  class="ng-value-icon right" (click)="clearcommonForm('asset_sub_category', ki)" aria-hidden="true">×</span>
                      </li>
                    </ul>
                  </div>

                  <div class="col-12">
                    <label for="name" class="form-label text-uppercase fw-500 ">assigned by</label>

                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Assigned by')}}"
                    formControlName="assigned_by" [items]="employeeDropdown" class="form-ngselect" bindValue="id"
                    bindLabel="name">
                    <ng-template ng-label-tmp let-item="item">
                      <span class="ng-value-label">
                        <div class="d-inline-flex align-items-center">
                          <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null"
                            class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1">
                            <span>{{this.appService.getFirstChar(item.name,2)}}</span>
                          </div>
                          <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"
                            *ngIf="item.profile_image !=  '' && item.profile_image !=  null"
                            [src]="item.profile_image">
                          {{item.name}}
                        </div>
                      </span> </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex  align-items-center">
                        <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                          *ngIf="item.profile_image !=  '' && item.profile_image != null"
                          src="{{item.profile_image}}">
                        <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                          class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                          <span>
                            {{this.appService.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                        </span>
                        {{item.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                  </div>
                  <div class="col-12">
                    <label for="name" class="form-label text-uppercase fw-500 ">assigned to</label>

                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Assigned to')}}"
                    formControlName="assigned_to" [items]="employeeDropdown" class="form-ngselect" bindValue="id"
                    bindLabel="name">
                    <ng-template ng-label-tmp let-item="item">
                      <span class="ng-value-label">
                        <div class="d-inline-flex align-items-center">
                          <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null"
                            class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1">
                            <span>{{this.appService.getFirstChar(item.name,2)}}</span>
                          </div>
                          <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"
                            *ngIf="item.profile_image !=  '' && item.profile_image !=  null"
                            [src]="item.profile_image">
                          {{item.name}}
                        </div>
                      </span> </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex  align-items-center">
                        <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                          *ngIf="item.profile_image !=  '' && item.profile_image != null"
                          src="{{item.profile_image}}">
                        <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                          class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                          <span>
                            {{this.appService.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                        </span>
                        {{item.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                  </div>

                  <div class="col-12">
                    <label for="name" class="form-label text-uppercase fw-500 ">Status</label>

                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('status')}}" formControlName="status" [items]="status" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                        Select All
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-inline-flex align-items-center overflow-hidden w-100">
                          <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>

                          <span class="text-trim">{{item.name}}</span>
                        </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="inventoryForm.value.status?.length">
                          <span class="ng-value-label">{{ inventoryForm.value.status.length }} Status Selected.</span>
                        </div>
                      </ng-template>
                    </ng-select>
                    <ul class="selected-values mt-16" *ngIf="inventoryForm.value.status?.length >0">
                      <li class="ng-value" *ngFor=" let item of inventoryForm.value.status; let ki = index" >
                        <ng-container *ngFor="let insideitem of status; let k = index">
                          <span class="ng-value-label" *ngIf="insideitem['name'] == item">
                            {{insideitem["name"].length > 15? (insideitem["name"] | slice : 0 : 15) + "..": insideitem["name"]}}</span >
                        </ng-container>
                        <span  class="ng-value-icon right" (click)="clearcommonForm('status', ki)" aria-hidden="true">×</span>
                      </li>
                    </ul>
                  </div>

                  <div class="col-12">
                    <!-- <div class="card card-c2 p-16">
                      <div class="row row-16"> -->
                        <!-- <div class="col-12">
                          <div class="title">Managed by</div>

                        </div> -->
                        <div class="row row-16">
                          <div class="col-12">
                            <label for="name" class="form-label text-uppercase fw-500 ">Managed by </label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='select the appropriate OU.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                            <ng-select [readonly]="disabled" class="form-ngselect" [attr.disabled]="disabled? true : null" [ngClass]="{ 'is-invalid': (f.applicable.touched) &&  f.applicable.errors}" (change)="changeApplicable(0)"  formControlName="applicable" placeholder="{{this.messageService.selectplaceholddisplay('novalue')}}">
                                <ng-option *ngFor="let app of applicableList" [value]="app.name">{{app.name}}</ng-option>
                            </ng-select>
                            <div *ngIf="(f.applicable.touched) && f.applicable.errors" class="invalid-feedback"><div *ngIf="f.applicable.errors.required">{{this.messageService.validationDisplay('required')}}</div></div>

                          </div>

                      <ng-container *ngIf="f.applicable.value!=null">
                        <div class=" col-lg-6 " *ngIf="loaderlist">
                          <div class="d-flex py-4 flex-center text-center " >
                              <div class="fs-12 text-muted">Loading </div>
                              <div class="ms-4 dot-pulse"></div>
                           </div>
                        </div>
                        <div class="col-12" *ngIf="!loaderlist && list.length">
                          <label for="" class="form-label text-uppercase fw-500 required">{{f.applicable.value}}</label>
                          <ng-select  [ngClass]="{ 'is-invalid': (f.assignees.touched || submitted) &&  f.assignees.errors}" placeholder="{{this.messageService.selectplaceholddisplay('novalue')}}" formControlName="assignees" [items]="list" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [attr.disabled]="disabled? true : null"  >
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline-flex align-items-center">
                                <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                <img class="avatar-sm sq-24 img-fluid rounded-circle me-8"  *ngIf="item.logo !=  null && item.logo !=  '' && f.applicable.value  ==  'Company'" src="{{item.logo}}">
                                <span *ngIf="(item.logo ==  null || item.logo ==  '') && (f.applicable.value  ==  'Company')" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>

                                <img class="avatar-sm sq-24 img-fluid rounded-circle me-8"  *ngIf="item.profile_image !=  null && item.profile_image !=  '' && f.applicable.value  ==  'Employee'"  src="{{item.profile_image}}">
                                <span *ngIf="(item.profile_image ==  null || item.profile_image ==  '') && (f.applicable.value  ==  'Employee')" class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}" ><span>{{this.appService.getFirstChar(item.first_name+" "+item.last_name,2)}}</span></span>
                                <span class="">               {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}                    </span>
                              </div>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngIf="f.assignees.value.length">
                                    <span class="ng-value-label">{{f.assignees.value.length}} {{f.applicable.value}} selected.</span>
                                </div>
                            </ng-template>
                        </ng-select>

                        <div *ngIf="(f.assignees.touched || submitted) && f.assignees.errors" class="invalid-feedback"><div *ngIf="f.assignees.errors.required">{{this.messageService.validationDisplay('required')}}</div></div>
                        </div>
                        <div class="col-12">
                        <ul class="selected-values " *ngIf="f.assignees.value.length != 0">
                            <li class="ng-value" *ngFor="let item of f.assignees.value, let m =index">
                                <ng-container *ngFor="let insideitem of list, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  <div class="d-inline-flex align-items-center">
                                    <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null && f.applicable.value  ==  'Employee'" src="{{insideitem.profile_image}}">
                                    <span *ngIf="(insideitem.profile_image ==  null || insideitem.profile_image ==  '') && (f.applicable.value  ==  'Employee')" class="avatar-sm sq-24 me-8 rounded-circle {{insideitem.color_code}}" >
                                        <span>
                                        {{this.appService.getFirstChar(insideitem.first_name+" "+insideitem.last_name,2)}}</span>
                                    </span>

                                    <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null && f.applicable.value  ==  'Company'" src="{{insideitem.logo}}">
                                    <span *ngIf="(insideitem.logo ==  null || insideitem.logo ==  '') && (f.applicable.value  ==  'Company')" class="avatar-sm sq-24 rounded-circle bg{{k%5}}" >
                                        <span>
                                        {{this.appService.getFirstChar(insideitem.name,1)}}</span>
                                    </span>
                                    <span class=""> {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                 </div>
                                </span>
                                </ng-container>
                                <span  class="ng-value-icon right" aria-hidden="true" (click)="clear(m,0)"><i class="icon-close"></i></span>
                            </li>
                        </ul>

                        </div>
                      </ng-container>
                    </div>
                      <!-- </div>
                    </div> -->
                  </div>

                </div>
            </div>
          </div>
          <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage('reset');resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage('apply');submitInventory()">
                Apply
            </button>
        </footer>
        </form>
      </div>


