
import { Component, Input, OnInit,Output,EventEmitter, ViewChild, HostListener,ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { Subject,  } from 'rxjs';
import { delay,catchError,switchMap  } from 'rxjs/operators';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';


@Component({
  selector: 'app-ss-salary-details',
  templateUrl: './ss-salary-details.component.html',
  styleUrls: ['./ss-salary-details.component.scss']
})
export class SsSalaryDetailsComponent implements OnInit {
  @ViewChild('ngSelecter') ngselect: any;
  @Input() empSalaryData:any    = []
  @Input() employeeData:any     = []
  @Input() payGroupDD:any       = [];

  @Input() calculationData:any  = [];
  @Input() gross_benefits:any;
  @Input() other_benefits:any;
  @Input() contributions:any;
  @Input() recurring_deductions:any;
  @Input() statutoryLockedinfo:any;
  @Input() componentLoader:any;
  @Input() calculatedAmount:any;
  @Input() variableComponent:any
  @Input() salaryForm:any;
  @Input() empid:any;
  @Output() submitmethod               = new EventEmitter();
  @Output() recalculateComponent       = new EventEmitter();
  saveData:any;
  moduleDropdown:any=[  {id:'January',value:"Jan"},
                        {id:'February',value:"Feb"},
                        {id:'March',value:"Mar"},
                        {id:'April',value:"Apr"},
                        {id:'May',value:"May"},
                        {id:'June',value:"Jun"},
                        {id:'July',value:"Jul"},
                        {id:'August',value:"Aug"},
                        {id:'September',value:"Sep"},
                        {id:'October',value:"Oct"},
                        {id:'November',value:"Nov"},
                        {id:'December',value:"Dec"}
                      ];
  @Input()formGp : FormGroup = this.fb.group({
                  gross_amount    : '',
                  pay_group       : '',
                  pay_group_name:'',
                  ctc             : '',
                  gross_month     : [],
                  other_month     : [],
                  recurring_month : [],
  });
  submitted             = false;
  completed             = false;
  Isalert               = true;
  ctcalert              = true;
  other_allowanceIndex  = 0;
  recalculatedData:any;
  isRecalculated:any    = false
  searchData : Subject<void> = new Subject();

  @Input() monthlyError:any
  @Input() yearlyError:any;
  @Input() fbpError:any;
  @Input() fbpYearError:any;
  @Output() setErrorArray               = new EventEmitter();

  pay_group_name:any
  constructor(public messageService : MessageService,public fb:FormBuilder,
    public appService:AppService,private SSS:SalaryStructureService,
    private changeDetectorRef: ChangeDetectorRef
    ) { }
  @HostListener('window:resize', ['$event'])
  sizeChange(event : any | null) {
   if(this.ngselect)
    this.ngselect.close();
  }
  ngOnInit(): void {
    this.formGp.get('gross_amount')?.disable();
    this.formGp.get('ctc')?.disable();
    this.formGp.get('pay_group_name')?.disable();
    this.formGp.get('pay_group')?.disable();
    for(let i=0;i<this.payGroupDD.length;i++){
      if(this.payGroupDD[i].id ==this.formGp.get('pay_group')?.value)
      this.formGp.get('pay_group_name')?.setValue(this.payGroupDD[i].name)
    }

    // this.searchData.pipe(delay(100),
    // switchMap(() =>  this.SSS.getFormula(this.saveData,this.empid)),
    // retry(1)).subscribe((res:any)=>{
      
    //   this.recalculateComponent.emit(res);
    //   this.isRecalculated = false

    // },
    // (error:any)=>{
    //   return of(null)
    // });
    this.searchData.pipe(delay(100),
    switchMap(() =>  this.SSS.getFormula(this.saveData,this.empid).pipe(
      catchError(async (error) => {})
      ))).subscribe((res:any)=>{
        if(res){
          this.recalculateComponent.emit(res);
          this.isRecalculated = false
        }

    });
    this.errorPopulation()


  }
  month() : FormArray {
    return this.formGp.get("gross_month") as FormArray
  }
  otherMonth() : FormArray {
    return this.formGp.get("other_month") as FormArray
  }
  recurringMonth() : FormArray {
    return this.formGp.get("recurring_month") as FormArray
  }

  get f() { return  this.formGp.controls; }

  Submit(){

    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.completed = true;
    this.submitmethod.emit();
  }


  RemoveData(index:any,control:any,control_index:any,select_control:any){
    if(select_control != '')
      select_control?.close();
    if(control=='gross_month'){

      let selected_data = this.month().controls[control_index].value.gross_month;
      selected_data.splice(index, 1);
      this.month().controls[control_index].get('gross_month')?.setValue(selected_data);

    }
    else if(control=='other_month'){

      let selected_data = this.otherMonth().controls[control_index].value.other_month;
      selected_data.splice(index, 1);
      this.otherMonth().controls[control_index].get('other_month')?.setValue(selected_data);

    }
    else{

      let selected_data = this.recurringMonth().controls[control_index].value.recurring_month;
      selected_data.splice(index, 1);
      this.recurringMonth().controls[control_index].get('recurring_month')?.setValue(selected_data);

    }
    this.CalculateMonthlyPay(control_index,control);

  }
  CalculateMonthlyPay(index:any,control:any){

    var selectedMonth;
    var yearlyValue;
    var MonthlyValue :any   = 0;
    var calculatedData :any = 0;
    if(control=='gross_month'){

      selectedMonth = this.month().controls[index].get('gross_month')?.value;
      yearlyValue   = this.gross_benefits[index].year_renum;
      if(selectedMonth.length>0)
      MonthlyValue    = (Number(yearlyValue)/selectedMonth.length);
      calculatedData  = Number(MonthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.month().controls[index].get('monthly_renum')?.setValue(this.appService.roundUp(calculatedData));
    }
    else if(control=='other_month'){

      selectedMonth = this.otherMonth().controls[index].get('other_month')?.value;
      yearlyValue   = this.other_benefits[index].year_renum;
      if(selectedMonth.length>0)
      MonthlyValue    = (Number(yearlyValue)/selectedMonth.length);
      calculatedData  = Number(MonthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.otherMonth().controls[index].get('monthly_renum')?.setValue(this.appService.roundUp(calculatedData));

    }
    else{

      selectedMonth = this.recurringMonth().controls[index].get('recurring_month')?.value;
      yearlyValue   = this.recurring_deductions[index].year_renum;
      if(selectedMonth.length>0)
      MonthlyValue  = (Number(yearlyValue)/selectedMonth.length);
      calculatedData  = Number(MonthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.recurring_deductions[index].monthly_renum=calculatedData
      this.recurringMonth().controls[index].get('monthly_renum')?.setValue(this.appService.roundUp(calculatedData));

    }

  }
  monthlyValidator(index:any,control:any){
    this.isRecalculated = true
    var yearlyValue:any;
    var monthPay;
    var fbpAmount;
    var totalAmount   = 0;
    var ctc           = this.formGp.get('ctc')?.value
    var selectedMonth:any;

    /* reset yearly value w.r.t month value*/
    if(control=='gross_benefits'){
      monthPay      = this.month().controls[index].get('monthly_renum')?.value;
      fbpAmount     = this.gross_benefits[index].fbp_amount;

      if(!(this.month().controls[index]?.get('monthly_renum')?.errors)){

        selectedMonth = this.month().controls[index].get('gross_month')?.value;
        yearlyValue   = (selectedMonth!=null && selectedMonth.length>0)?Number(monthPay)*Number(selectedMonth.length):Number(monthPay)*12;
        this.gross_benefits[index].year_renum = Number(yearlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        this.month().controls[index]?.get('year_renum')?.setValue(this.gross_benefits[index].year_renum)
        if(fbpAmount>0){

          if(Number(yearlyValue) > Number(fbpAmount) ){
            this.month().controls[index].get('monthly_renum')?.setErrors({
              'fbplimit' : true
            })
            if(this.fbpError.length==0)
            this.fbpError.push(index)
            else{
              if(!this.fbpError.includes(index))
               this.fbpError.push(index)

            }

            this.setErrorArray.emit({'from':'fbpMonth','error':this.fbpError})

          }
          else{
            if(this.fbpError.includes(index))
            {
              const indexData = this.fbpError.indexOf(index);
              if (indexData > -1) {
                this.fbpError.splice(indexData, 1);
              }
            }
            this.setErrorArray.emit({'from':'fbpMonth','error':this.fbpError})

          }

        }

      }
      else{

        if(monthPay=='' || monthPay==null){

          this.gross_benefits[index].year_renum = 0;
          this.month().controls[index]?.get('year_renum')?.setValue(this.gross_benefits[index].year_renum)

        }

      }
    }
    else if(control=='other_benefits'){

      monthPay      = this.otherMonth().controls[index].get('monthly_renum')?.value;
      fbpAmount     = this.other_benefits[index].fbp_amount;


      if(!(this.otherMonth().controls[index]?.get('monthly_renum')?.errors)){


        selectedMonth = this.otherMonth().controls[index].get('other_month')?.value;
        yearlyValue   = (selectedMonth!=null && selectedMonth.length>0)?Number(monthPay)*Number(selectedMonth.length):Number(monthPay)*12;
        this.other_benefits[index].year_renum = Number(yearlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        this.otherMonth().controls[index]?.get('year_renum')?.setValue(this.other_benefits[index].year_renum)

        if(fbpAmount>0){

          if(Number(yearlyValue) > Number(fbpAmount) ){
            this.otherMonth().controls[index].get('monthly_renum')?.setErrors({
              'fbplimit' : true
            })

          }

        }
      }
      else{

        if(monthPay=='' || monthPay==null){

          this.other_benefits[index].year_renum = 0;
          this.otherMonth().controls[index]?.get('year_renum')?.setValue(this.other_benefits[index].year_renum)
        }


      }
    }

    /*end*/

    for(let i=0;i<this.gross_benefits.length;i++)
    {
      if(this.gross_benefits[i]['component_type_name']!='other allowance')
      totalAmount+=this.gross_benefits[i].year_renum;
      else
        this.other_allowanceIndex = i;
    }
    for(let j=0;j<this.other_benefits.length;j++)
    {
      totalAmount+=this.other_benefits[j].year_renum;
    }

    /*check total yearly amount exceeds CTC*/
    if(Number(totalAmount)>Number(ctc)){

      if(control=='gross_benefits'){

        this.month().controls[index].get('monthly_renum')?.setErrors({
          'max' : true
        })
        if(this.monthlyError.length==0)
          this.monthlyError.push(index)
        else{
          if(!this.monthlyError.includes(index))
            this.monthlyError.push(index)

        }

      }
      if(control=='other_benefits'){

        this.otherMonth().controls[index].get('monthly_renum')?.setErrors({
          'max' : true
        })
      }
      this.setErrorArray.emit({'from':'month','error':this.monthlyError})
    }
    else{
      for(let j=0;j<this.month().controls.length;j++){
        if(this.month().controls[j].get('monthly_renum')?.errors?.max==true){
          this.month().controls[j].get('monthly_renum')?.setErrors(null);

        }
      }
      for(let j=0;j<this.otherMonth().controls.length;j++){
        if(this.otherMonth().controls[j].get('monthly_renum')?.errors?.max==true){
          this.otherMonth().controls[j].get('monthly_renum')?.setErrors(null);

        }
      }
      if(this.monthlyError.includes(index))
      {
        const indexData = this.monthlyError.indexOf(index);
        if (indexData > -1) {
          this.monthlyError.splice(indexData, 1);
        }
      }
      this.setErrorArray.emit({'from':'month','error':this.monthlyError})

      if(!(this.month().controls[index]?.get('monthly_renum')?.errors))
      this.recalculation();
    }


  }
  yearlyValidator(index:any,control:any){
    this.isRecalculated = true
    var monthlyValue:any;
    var yearPay;
    var fbpAmount;
    var totalAmount   = 0;
    var ctc           = this.formGp.get('ctc')?.value
    var newOther      = 0;
    var contribution  = 0;
    var selectedMonth:any;

    /* reset yearly value w.r.t month value*/
    if(control=='gross_benefits'){
      yearPay       = this.month().controls[index].get('year_renum')?.value;
      fbpAmount     = this.gross_benefits[index].fbp_amount;

      if(!(this.month().controls[index]?.get('year_renum')?.errors)){

        this.gross_benefits[index].year_renum = Number(yearPay)
        selectedMonth   = this.month().controls[index].get('gross_month')?.value;
        monthlyValue    = (selectedMonth!=null && selectedMonth.length>0)?Number(yearPay)/Number(selectedMonth.length):Number(yearPay)/12;
        this.gross_benefits[index].monthly_renum = Number(monthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        this.month().controls[index].get('monthly_renum')?.setValue(this.gross_benefits[index].monthly_renum)
        if(fbpAmount>0){

          if(Number(yearPay) > Number(fbpAmount) ){
            this.month().controls[index].get('year_renum')?.setErrors({
              'fbplimit' : true
            })
            if(this.fbpYearError.length==0)
            this.fbpYearError.push(index)
            else{
              if(!this.fbpYearError.includes(index))
               this.fbpYearError.push(index)

            }

            this.setErrorArray.emit({'from':'fbpYear','error':this.fbpYearError})

          }
          else{

            if(this.fbpYearError.includes(index)){
              const indexData = this.fbpYearError.indexOf(index);
              if (indexData > -1) {
                this.fbpYearError.splice(indexData, 1);
              }
            }
            this.setErrorArray.emit({'from':'fbpYear','error':this.fbpYearError})

          }

        }
      }
      else{

        if(yearPay=='' || yearPay==null){

          this.gross_benefits[index].monthly_renum = 0;
          this.month().controls[index].get('monthly_renum')?.setValue(this.gross_benefits[index].monthly_renum)
          this.gross_benefits[index].year_renum = 0

        }

      }
    }
    else if(control=='other_benefits'){

      yearPay      = this.otherMonth().controls[index].get('year_renum')?.value;
      fbpAmount     = this.other_benefits[index].fbp_amount;


      if(!(this.otherMonth().controls[index]?.get('year_renum')?.errors)){


        selectedMonth = this.otherMonth().controls[index].get('other_month')?.value;
        monthlyValue   = (selectedMonth!=null && selectedMonth.length>0)?Number(yearPay)/Number(selectedMonth.length):Number(yearPay)/12;
        this.other_benefits[index].monthly_renum = Number(monthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        this.otherMonth().controls[index].get('monthly_renum')?.setValue(this.other_benefits[index].monthly_renum)
        this.other_benefits[index].year_renum = Number(yearPay)

        if(fbpAmount>0){

          if(Number(yearPay) > Number(fbpAmount) ){
            this.otherMonth().controls[index].get('year_renum')?.setErrors({
              'fbplimit' : true
            })

          }

        }
      }
      else{

        if(yearPay=='' || yearPay==null){

          this.other_benefits[index].monthly_renum = 0;
          this.otherMonth().controls[index].get('monthly_renum')?.setValue(this.other_benefits[index].monthly_renum)
          this.other_benefits[index].year_renum = 0

        }

      }
    }

    /*end*/

    for(let i=0;i<this.gross_benefits.length;i++)
    {
      if(this.gross_benefits[i]['component_type_name']!='other allowance')
      totalAmount+=Number(this.gross_benefits[i].year_renum);
      else
        this.other_allowanceIndex = i;
    }
    for(let j=0;j<this.other_benefits.length;j++)
    {
      totalAmount+=this.other_benefits[j].year_renum;
    }

    /*check total yearly amount exceeds CTC*/
    if(Number(totalAmount)>Number(ctc)){

      if(control=='gross_benefits'){

        this.month().controls[index].get('year_renum')?.setErrors({
          'max' : true
        })
        if(this.yearlyError.length==0)
        this.yearlyError.push(index)
        else{
          if(!this.yearlyError.includes(index))
           this.yearlyError.push(index)

        }
        this.setErrorArray.emit({'from':'year','error':this.yearlyError})

      }
      if(control=='other_benefits'){

        this.otherMonth().controls[index].get('year_renum')?.setErrors({
          'max' : true
        })
      }
    }
    else{
      for(let j=0;j<this.month().controls.length;j++){
        if(this.month().controls[j].get('year_renum')?.errors?.max==true){
          this.month().controls[j].get('year_renum')?.setErrors(null);

        }
      }
      for(let j=0;j<this.otherMonth().controls.length;j++){
        if(this.otherMonth().controls[j].get('year_renum')?.errors?.max==true){
          this.otherMonth().controls[j].get('year_renum')?.setErrors(null);

        }
      }
      if(this.yearlyError.includes(index))
      {
        const indexData = this.yearlyError.indexOf(index);
        if (indexData > -1) {
          this.yearlyError.splice(indexData, 1);
        }
      }
      this.setErrorArray.emit({'from':'year','error':this.yearlyError})

      if(!(this.month().controls[index]?.get('year_renum')?.errors))
      this.recalculation();
    }

  }
  recalculation(){

    let other_month_array     = this.formGp.get('other_month')?.value ;
    let gross_month_array     = this.formGp.get('gross_month')?.value ;
    let recurring_month_array = this.formGp.get('recurring_month')?.value ;

    for(let i=0;i< gross_month_array.length;i++){
      for(let j=0; j< this.calculationData.length;j++){

        if(gross_month_array[i]['comp_id']==this.calculationData[j]['pay_component']){
          if(gross_month_array[i]['gross_month']!=null)
          this.calculationData[j]['pay_month']      = gross_month_array[i]['gross_month'];
          if(this.calculationData[j]['component_type_name']!="other allowance"){
            this.calculationData[j]['monthly_renum']  = Number(gross_month_array[i]['monthly_renum']);
            this.calculationData[j]['year_renum']     = Number(gross_month_array[i]['year_renum']);
          }
        }

      }
    }

    for(let i=0;i< other_month_array.length;i++){

      for(let j=0; j< this.calculationData.length;j++){

        if(other_month_array[i]['comp_id']==this.calculationData[j]['pay_component']){
          if(other_month_array[i]['other_month']!=null)
          this.calculationData[j]['pay_month']      = other_month_array[i]['other_month'];
          this.calculationData[j]['monthly_renum']  = Number(other_month_array[i]['monthly_renum']);
          this.calculationData[j]['year_renum']     = Number(other_month_array[i]['year_renum']);

        }

      }
    }
    for(let i=0;i< recurring_month_array.length;i++){

      for(let j=0; j< this.calculationData.length;j++){

        if(recurring_month_array[i]['comp_id']==this.calculationData[j]['pay_component'] && recurring_month_array[i]['recurring_month']!=null){
          this.calculationData[j]['pay_month']      = recurring_month_array[i]['recurring_month'];
          this.calculationData[j]['monthly_renum']  = Number(recurring_month_array[i]['monthly_renum']);
          this.calculationData[j]['year_renum']     = Number(recurring_month_array[i]['year_renum']);
        }

      }
    }

    this.recalculatedData = this.calculationData?.slice();
    for(let j=0;j<this.calculationData.length;j++){
      for(let i=0;i<this.recalculatedData.length;i++){

        if(this.recalculatedData[i]['pay_component'] == null || this.recalculatedData[i]['component_type_name']=='other allowance' || this.recalculatedData[i]['pay_component']=='null'){
          this.recalculatedData.splice(i, 1);

        }
      }
    }
    let paygrp        = '';
    for(let i=0;i<this.payGroupDD.length;i++){
      if(this.payGroupDD[i].name ==this.salaryForm.getRawValue().structure.pay_group){
        paygrp = this.payGroupDD[i].id;
      }
    }
    this.saveData = {
      "CTC"                       : parseInt(this.salaryForm.getRawValue().structure.ctc),
      "pay_group"                 : paygrp!=''?paygrp:this.salaryForm.getRawValue().structure.pay_group,
      "provident_fund"            : this.salaryForm.getRawValue().statutory.provident_fund,
      "employee_state_insurance"  : this.salaryForm.getRawValue().statutory.employee_state_insurance,
      "professional_tax"          : this.salaryForm.getRawValue().statutory.professional_tax,
      "labour_welfare_fund"       : this.salaryForm.getRawValue().statutory.labour_welfare_fund,
      "volentary_provident_fund"  : this.salaryForm.getRawValue().statutory.volentary_provident_fund,
      "amount"                    : this.salaryForm.getRawValue().statutory.amount,
      "gratuity"                  : this.salaryForm.getRawValue().statutory.gratuity,
      "employer_contribution_nps" : this.salaryForm.getRawValue().statutory.employer_contribution_nps,
      "percentage"                : this.salaryForm.getRawValue().statutory.percentage,
      "recalculate"               : true,
      'assign_salary'             : this.recalculatedData
    };

    this.searchData.next();

  }
  errorPopulation(){

    setTimeout(() => {
      this.submitted = true
      for(let i=0;i<this.fbpError.length;i++){
        this.month().controls[this.fbpError[i]].get('monthly_renum')?.setErrors({
          'fbplimit' : true
        })
      }
      for(let j=0;j<this.fbpYearError.length;j++){
        this.month().controls[this.fbpYearError[j]].get('year_renum')?.setErrors({
          'fbplimit' : true
        })
      }
      for(let j=0;j<this.monthlyError.length;j++){
        this.month().controls[this.monthlyError[j]].get('monthly_renum')?.setErrors({
          'max' : true
        })
      }
      for(let j=0;j<this.yearlyError.length;j++){
        this.month().controls[this.yearlyError[j]].get('year_renum')?.setErrors({
          'max' : true
        })
      }
      this.changeDetectorRef.detectChanges();

  })
  }
  monthlyRDValidator(index:any){
    let mnth = this.recurring_deductions[index]?.nature ? 12 : (this.recurringMonth().controls[index].value.recurring_month?.length?this.recurringMonth().controls[index].value.recurring_month.length:12)
    this.recurringMonth().controls[index]?.get('year_renum')?.setValue (this.appService.roundUp(this.recurringMonth().controls[index]?.get('monthly_renum')?.value*mnth))
    this.calculateDeds(index,'monthly_renum')
  }
  yearlyRDValidator(index:any){    
    let mnth = this.recurring_deductions[index]?.nature ? 12 : (this.recurringMonth().controls[index].value.recurring_month?.length?this.recurringMonth().controls[index].value.recurring_month.length:12)
    this.recurringMonth().controls[index]?.get('monthly_renum')?.setValue (this.appService.roundUp(this.recurringMonth().controls[index]?.get('year_renum')?.value/mnth))
    this.calculateDeds(index,'year_renum')
  }
  calculateDeds(index:any,fld:any){
    this.recurring_deductions[index].year_renum =Number(this.recurringMonth().controls[index]?.get('year_renum')?.value)
    this.recurring_deductions[index].monthly_renum =Number(this.recurringMonth().controls[index]?.get('monthly_renum')?.value)
    let ded:GLfloat = 0
    for(let j=0;j<this.recurringMonth().controls.length;j++){
      ded += Number(this.recurringMonth().controls[j].get('year_renum')?.value)
      this.recurringMonth().controls[j].get('year_renum')?.setErrors(null);
      this.recurringMonth().controls[j].get('monthly_renum')?.setErrors(null);
    }
    if(ded>this.calculatedAmount[0]){
      this.recurringMonth().controls[index].get(fld)?.setErrors({
        'max' : true
      })
    } else
    this.recurringMonth().controls[index].get(fld)?.setErrors(null)
    this.calculatedAmount[1] = this.calculatedAmount[0]-ded
  }


}
