import {  Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';
import { Router  } from '@angular/router';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-salary-structure-setup',
  templateUrl: './salary-structure-setup.component.html',
  styleUrls: ['./salary-structure-setup.component.scss']
})
export class SalaryStructureSetupComponent implements OnInit {
  activeTab               = 1;
  empid                   = 0;
  employeeData:any        = [];
  empSalaryData:any       = [];
  previousData:any        = [];
  payGroupDD:any          = [];
  calculatedAmount:any    = [];
  salaryId                = 0;
  loader                  = false;
  componentLoader         = false;
  invalidStatutory        = 0;
  isAssign                = true;
  exceedCTC               = true;
  hasChange: boolean      = false;
  monthlyError:any        = [];
  yearlyError:any         = [];
  fbpError:any            = [];
  fbpYearError:any        = [];
  variableComponent:any;
  calculationData:any;
  gross_benefits:any;
  other_benefits:any;
  contributions:any;
  recurring_deductions:any;
  statutoryLockedinfo:any;

  constructor(private _location: Location,public fb:FormBuilder,public route:ActivatedRoute,private SSS:SalaryStructureService,private router:Router,
    private previousRouteService: PreviousRouteService,public appService: AppService) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.salaryId = +params['id'];
        this.loader   = true;
        if(this.previousRouteService.getPreviousUrl() == '/inbox')
          this.getSingleInboxsalary()
        else
          this.getSinglesalary();
      }

    })
    this.payGroupList();
  }

  backClicked() {
    if(this.previousRouteService.getPreviousUrl() == '/inbox'){
      this.router.navigate(['inbox']);
    }else{
      let id= (this.isAssign==true)?1:2;
      this.router.navigate(['salary-structure',id]);
    }



  }

  salaryForm: FormGroup = this.fb.group({
    id: 0,
    statutory: this.fb.group({
                  tax_regime                : [null, [Validators.required]],
                  provident_fund            : [false, ],
                  pf_number                 : [null, ],
                  uan_number                : [null, ],
                  pension                   : [false, ],
                  pension_number            : [null, ],
                  employee_state_insurance  : [false, ],
                  esi_number                : [null, ],
                  dispensry_name            : [null, ],
                  labour_welfare_fund       : [false, ],
                  professional_tax          : [false, ],
                  gratuity                  : [false, ],
                  // gratuity_in_ctc           : [false, ],
                  volentary_provident_fund  : [false, ],
                  amount                    : [0, ],
                  employer_contribution_nps : [false, ],
                  percentage                : [0, ],
                  vpf_month                 : [],
                  vpf_year                  : [],
                }),
    structure: this.fb.group({
                  pay_group       : [null, [Validators.required]],
                  pay_group_name : [null],
                  gross_amount    : [null],
                  ctc             : [null, [Validators.required,digitdecimalValidator(),Validators.max(9999999999)]],
                  gross_month     : this.fb.array([]),
                  other_month     : this.fb.array([]),
                  recurring_month : this.fb.array([]),
    }),
    details : this.fb.group({

    })
  });
  month() : FormArray {
    return this.structureForm.get("gross_month") as FormArray
  }
  otherMonth() : FormArray {
    return this.structureForm.get("other_month") as FormArray
  }
  recurringMonth() : FormArray {
    return this.structureForm.get("recurring_month") as FormArray
  }
  newmonth(): FormGroup {

    return this.fb.group({
     'comp_id'            : [null],
     'gross_month'        : [null],
     'monthly_renum'      : ['',digitdecimalValidator()],
     'year_renum'         : ['',digitdecimalValidator()]

    })
  }
  newOthermonth(): FormGroup {

    return this.fb.group({
     'comp_id'            : [null],
     'other_month'        : [null],
     'monthly_renum'      : ['',digitdecimalValidator()],
     'year_renum'         : ['',digitdecimalValidator()],

    })
  }
  newmRecurringonth(): FormGroup {

    return this.fb.group({
     'comp_id'            : [null],
     'recurring_month'    : [null],
     'monthly_renum'      : ['',digitdecimalValidator()],
     'year_renum'         : ['',digitdecimalValidator()],

    })
  }
  addmonth() {
    this.month().clear();
    if(this.gross_benefits!=null && this.gross_benefits!=undefined && this.gross_benefits!=''){

      for(let i=0;i<this.gross_benefits.length;i++){

        this.month().push(this.newmonth());
        this.month().controls[i].get('comp_id')?.setValue(this.gross_benefits[i].pay_component);
        this.month().controls[i].get('monthly_renum')?.setValue(this.appService.roundUp(this.gross_benefits[i].monthly_renum));
        this.month().controls[i].get('year_renum')?.setValue(this.appService.roundUp(this.gross_benefits[i].year_renum));
        if(this.gross_benefits[i].pay_month && this.gross_benefits[i].pay_month.length>0){
         this.month().controls[i].get('gross_month')?.setValue(this.gross_benefits[i].pay_month)

        }
        // if(this.gross_benefits[i].nature==false)
        this.month().controls[i].get('monthly_renum')?.setValidators([digitdecimalValidator(),Validators.max(9999999999),Validators.required]);
        this.month().controls[i].get('year_renum')?.setValidators([digitdecimalValidator(),Validators.max(9999999999),Validators.required]);
      }
    }

  }
  addOthermonth() {
    this.otherMonth().clear();

    if(this.other_benefits!=null && this.other_benefits!=undefined && this.other_benefits!=''){

      for(let i=0;i<this.other_benefits.length;i++){

        this.otherMonth().push(this.newOthermonth());
        this.otherMonth().controls[i].get('comp_id')?.setValue(this.other_benefits[i].pay_component)
        this.otherMonth().controls[i].get('monthly_renum')?.setValue(this.appService.roundUp(this.other_benefits[i].monthly_renum))
        this.otherMonth().controls[i].get('year_renum')?.setValue(this.appService.roundUp(this.other_benefits[i].year_renum))
        if(this.other_benefits[i].pay_month && this.other_benefits[i].pay_month.length>0){
          this.otherMonth().controls[i].get('other_month')?.setValue(this.other_benefits[i].pay_month);
        }

        this.otherMonth().controls[i].get('monthly_renum')?.setValidators([digitdecimalValidator(),Validators.max(9999999999),Validators.required]);
        this.otherMonth().controls[i].get('year_renum')?.setValidators([digitdecimalValidator(),Validators.max(9999999999),Validators.required]);

      }

    }

  }
  addrecurringmonth() {
    this.recurringMonth().clear();
    if(this.recurring_deductions!=null && this.recurring_deductions!=undefined && this.recurring_deductions!=''){

      for(let i=0;i<this.recurring_deductions.length;i++){

        this.recurringMonth().push(this.newmRecurringonth());
        this.recurringMonth().controls[i].get('comp_id')?.setValue(this.recurring_deductions[i].pay_component)
        this.recurringMonth().controls[i].get('monthly_renum')?.setValue(this.appService.roundUp(this.recurring_deductions[i].monthly_renum))
        this.recurringMonth().controls[i].get('year_renum')?.setValue(this.appService.roundUp(this.recurring_deductions[i].year_renum))

        if(this.recurring_deductions[i].pay_month.length>0){
          this.recurringMonth().controls[i].get('recurring_month')?.setValue(this.recurring_deductions[i].pay_month)

        }
        if(this.recurring_deductions[i].nature==false)
        // this.recurringMonth().controls[i].get('recurring_month')?.setValidators([Validators.required]);

        this.recurringMonth().controls[i].get('monthly_renum')?.setValidators([digitdecimalValidator(),Validators.max(9999999999),Validators.required]);
        this.recurringMonth().controls[i].get('year_renum')?.setValidators([digitdecimalValidator(),Validators.max(9999999999),Validators.required]);

      }
    }

  }
  currentCTC:any;
  currentPayGp:any;
  getSinglesalary(){
      this.SSS.getSingleSalary(this.salaryId).subscribe((res:any)=>{

        this.empid          = res.body.employee;
        this.empSalaryData  = res.body;
        this.previousData   = res.body;
        this.currentCTC = res.body.ctc
        this.currentPayGp = res.body.pay_group
        this.getStatutoryInfo();
        if(this.empSalaryData.ctc==0 || this.empSalaryData.is_revision==true)
          this.isAssign =  false;
        Object.entries(res.body).forEach(([key, value]) => {
          if(value == null) {

            this.salaryForm['controls'].statutory.get(key)?.setValue('');
            this.salaryForm['controls'].structure.get(key)?.setValue('');

          }
          else if((key=='amount' || key=='percentage' || key=='ctc') && value==0){
            this.salaryForm['controls'].statutory.get(key)?.setValue('');
            this.salaryForm['controls'].structure.get(key)?.setValue('');

          }
          else if(key=='assign_salary'){
              this.calculationData = value;
              this.calculatedAmount = [];
            if(this.calculationData.length>0){
              this.calculatedAmount.push(res.body.gross_amount);
              this.calculatedAmount.push(res.body.net_gross);
              this.constructComponent()
              this.addmonth();
              this.addOthermonth();
              this.addrecurringmonth();
              this.exceedCTC  = false;


            }

          }
          else if(key=='employee_data'){

            this.employeeData = value;

            if(this.employeeData.first_name)
              this.employeeData.name=this.employeeData.first_name;


            if(this.employeeData.middle_name)
              this.employeeData.name+=" "+this.employeeData.middle_name;

            if(this.employeeData.last_name)
              this.employeeData.name+=" "+this.employeeData.last_name;

            this.employeeData.nameShort =  this.appService.getFirstChar(this.employeeData.first_name+" "+this.employeeData.last_name,2);
            this.employeeData.empid     = this.empid;

            if(this.employeeData.color_code)
            this.employeeData.color_code=this.employeeData.color_code;
          }
          else {

            this.salaryForm['controls'].statutory.get(key)?.setValue(value);
            this.salaryForm['controls'].structure.get(key)?.setValue(value);

          }


        })

      })
      this.loader     = false;
  }
  getSingleInboxsalary(){
    this.SSS.getSingleInboxsalary(this.salaryId).subscribe((res:any)=>{

      this.empid          = res.body.employee;
      this.empSalaryData  = res.body;
      this.previousData   = res.body;
      this.currentCTC = res.body.ctc
      this.currentPayGp = res.body.pay_group
      this.getStatutoryInfo();
      if(this.empSalaryData.ctc==0 || this.empSalaryData.is_revision==true)
        this.isAssign =  false;
      Object.entries(res.body).forEach(([key, value]) => {
        if(value == null) {

          this.salaryForm['controls'].statutory.get(key)?.setValue('');
          this.salaryForm['controls'].structure.get(key)?.setValue('');

        }
        else if((key=='amount' || key=='percentage' || key=='ctc') && value==0){
          this.salaryForm['controls'].statutory.get(key)?.setValue('');
          this.salaryForm['controls'].structure.get(key)?.setValue('');

        }
        else if(key=='assign_salary'){
            this.calculationData = value;
            this.calculatedAmount = [];
          if(this.calculationData.length>0){
            this.calculatedAmount.push(res.body.gross_amount);
            this.calculatedAmount.push(res.body.net_gross);
            this.constructComponent()
            this.addmonth();
            this.addOthermonth();
            this.addrecurringmonth();
            this.exceedCTC  = false;


          }

        }
        else if(key=='employee_data'){

          this.employeeData = value;

          if(this.employeeData.first_name)
            this.employeeData.name=this.employeeData.first_name;


          if(this.employeeData.middle_name)
            this.employeeData.name+=" "+this.employeeData.middle_name;

          if(this.employeeData.last_name)
            this.employeeData.name+=" "+this.employeeData.last_name;

          this.employeeData.nameShort =  this.appService.getFirstChar(this.employeeData.first_name+" "+this.employeeData.last_name,2);
          this.employeeData.empid     = this.empid;

          if(this.employeeData.color_code)
          this.employeeData.color_code=this.employeeData.color_code;
        }
        else {

          this.salaryForm['controls'].statutory.get(key)?.setValue(value);
          this.salaryForm['controls'].structure.get(key)?.setValue(value);

        }


      })

    })
    this.loader     = false;
}
  getStatutoryInfo(){
    let data = {'employee':this.empid};
    this.SSS.getStatutoryInfo(data).subscribe((res:any)=>{
      this.statutoryLockedinfo = "";

    },
    (error:any)=>{
      if(error.error.statuatory_error==1)
        this.statutoryLockedinfo = error.error.error;
    }
    )
  }

  StatutoryError(error:any){
    this.invalidStatutory = error;
  }


  get statutoryForm() {
    return this.salaryForm.get('statutory') as FormGroup;
  }

  get structureForm() {
    return this.salaryForm.get('structure') as FormGroup;
  }
  get f() {return  this.structureForm.controls; }

  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {
      if (this.salaryForm['controls'].statutory.invalid || this.invalidStatutory>0) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 3) {

      if ((this.salaryForm['controls'].structure.get('ctc')?.invalid || this.salaryForm['controls'].structure.get('pay_group')?.invalid
      ) || (this.salaryForm['controls'].statutory.invalid || this.invalidStatutory>0)) {
        changeEvent.preventDefault();

      }
      else{
          changeEvent.preventDefault();
          this.makeDefaultSettings();

      }

    }
  }


  changeTab(tabIndex:any){

     if(tabIndex==3){

       this. makeDefaultSettings();


     }
     else
     this.activeTab = tabIndex;


  }
  payGroupList(){
    this.SSS.getPayGroupList().subscribe((res:any)=>{
      this.payGroupDD = res.body;
    });
  }
  submitForm(){

    let salaryStructure = this.salaryForm.getRawValue().structure;
    Object.entries(salaryStructure).forEach(([key, value]) => {
      if(key=='pay_group'){
        for(let i=0;i<this.payGroupDD.length;i++){

          if(this.payGroupDD[i]['name']!= undefined && this.payGroupDD[i]['name'] == value){
            salaryStructure[key]   = '';
            salaryStructure[key]   = (this.payGroupDD[i]['id']);

          }
        }
      }
    })

    this.salaryForm['controls'].statutory.get('amount')?.setValue(this.salaryForm['controls'].statutory.value.amount!=''?this.salaryForm['controls'].statutory.value.amount:0);
    this.salaryForm['controls'].statutory.get('percentage')?.setValue(this.salaryForm['controls'].statutory.value.percentage!=''?this.salaryForm['controls'].statutory.value.percentage:0);

    let other_month_array     = this.salaryForm['controls'].structure.value.other_month ;
    let gross_month_array     = this.salaryForm['controls'].structure.value.gross_month ;
    let recurring_month_array = this.salaryForm['controls'].structure.value.recurring_month ;

    for(let i=0;i< gross_month_array.length;i++){
      for(let j=0; j< this.calculationData.length;j++){

        if(gross_month_array[i]['comp_id']==this.calculationData[j]['pay_component']){
          if(gross_month_array[i]['gross_month']!=null)
          this.calculationData[j]['pay_month']      = gross_month_array[i]['gross_month'];
          if(this.calculationData[j]['component_type_name']!="other allowance"){
            this.calculationData[j]['monthly_renum']  = (gross_month_array[i]['monthly_renum']);
            this.calculationData[j]['year_renum']     = (gross_month_array[i]['year_renum']);
          }
        }

      }
    }

    for(let i=0;i< other_month_array.length;i++){

      for(let j=0; j< this.calculationData.length;j++){

        if(other_month_array[i]['comp_id']==this.calculationData[j]['pay_component']){
          if(other_month_array[i]['other_month']!=null)
          this.calculationData[j]['pay_month']      = other_month_array[i]['other_month'];
          this.calculationData[j]['monthly_renum']  = (other_month_array[i]['monthly_renum']);
          this.calculationData[j]['year_renum']     = (other_month_array[i]['year_renum']);

        }

      }
    }
    for(let i=0;i< recurring_month_array.length;i++){

      for(let j=0; j< this.calculationData.length;j++){

        if(recurring_month_array[i]['comp_id']==this.calculationData[j]['pay_component'] && recurring_month_array[i]['recurring_month']!=null){
          this.calculationData[j]['pay_month']      = recurring_month_array[i]['recurring_month'];
          this.calculationData[j]['monthly_renum']  = (recurring_month_array[i]['monthly_renum']);
          this.calculationData[j]['year_renum']     = (recurring_month_array[i]['year_renum']);

        }

      }
    }

    let assign_salary = {'assign_salary':this.calculationData,'employee':this.empid,'is_assigned':true,'is_revision':false};
    let mergedData = { ...this.salaryForm.getRawValue().statutory, ...salaryStructure, ...assign_salary};

    if(this.previousRouteService.getPreviousUrl() == '/inbox'){
      this.SSS.updateSalaryInbox(this.salaryId,mergedData).subscribe((res:any)=>{
        this.router.navigate(['inbox']);
      })
    } else {
      this.SSS.updateSalary(this.salaryId,mergedData).subscribe((res:any)=>{
        this.router.navigate(['salary-structure']);
      })
    }
  }
  makeDefaultSettings(){

    let count:number  = 0;
    let paygrp        = '';

    for(let i=0;i<this.payGroupDD.length;i++){
      if(this.payGroupDD[i].name ==this.salaryForm.getRawValue().structure.pay_group){
        paygrp = this.payGroupDD[i].id;
      }

    }

    let data :any= {
                "CTC"                       : parseInt(this.salaryForm.getRawValue().structure.ctc),
                "pay_group"                 : paygrp!=''?paygrp:this.salaryForm.getRawValue().structure.pay_group,
                "provident_fund"            : this.salaryForm.getRawValue().statutory.provident_fund,
                "employee_state_insurance"  : this.salaryForm.getRawValue().statutory.employee_state_insurance,
                "professional_tax"          : this.salaryForm.getRawValue().statutory.professional_tax,
                "labour_welfare_fund"       : this.salaryForm.getRawValue().statutory.labour_welfare_fund,
                "volentary_provident_fund"  : this.salaryForm.getRawValue().statutory.volentary_provident_fund,
                "amount"                    : this.salaryForm.getRawValue().statutory.amount,
                "gratuity"                  : this.salaryForm.getRawValue().statutory.gratuity,
                "employer_contribution_nps" : this.salaryForm.getRawValue().statutory.employer_contribution_nps,
                "percentage"                : this.salaryForm.getRawValue().statutory.percentage
          };

    Object.entries(data).forEach(([key, value]) => {
      if(key=='CTC')
      {
        if(value != this.previousData['ctc']){
          this.previousData['ctc'] = value;
          count++;
        }
      }
      else{
        if( value != this.previousData[key]){
          this.previousData[key] = value;
          count ++
        }
      }

    })

    if((count > 0) || !(this.isAssign) ){

      this.isAssign = true;
      this.componentLoader = true;
      this.calculatedAmount = [];

      this.SSS.getFormula(data,this.empid).subscribe((res:any)=>{

        this.calculationData = res.body.assign_salary;
        this.calculatedAmount.push(res.body.gross_amount);
        this.calculatedAmount.push(res.body.net_gross);
        this.constructComponent();
        this.salaryForm['controls'].structure.get('gross_amount')?.setValue(res.body.gross_amount);
        this.componentLoader      = false;
        this.addmonth();
        this.addOthermonth();
        this.addrecurringmonth();
        this.exceedCTC  = false;
        this.activeTab  = 3;
      },
      (err: any) => {
        this.exceedCTC  = true;
      }
      );
    }
    else
      if(this.exceedCTC==false)
      this.activeTab = 3;

  }
  constructComponent(){

    var gross =  this.calculationData.filter(function(gross_sal:any) {

      return gross_sal.component_type == "gross_benefits" && gross_sal?.is_display==true;
    });
    var other =  this.calculationData.filter(function(other_sal:any) {

      return other_sal.component_type == "other_benefits" && other_sal?.is_display==true;
    });
    var contibution =  this.calculationData.filter(function(contribution_sal:any) {

      return contribution_sal.component_type == "contributions" && contribution_sal?.is_display==true;
    });
    var recurring =  this.calculationData.filter(function(recurring_sal:any) {

      return recurring_sal.component_type == "recurring_deductions" && recurring_sal?.is_display==true;
    });
    var variable = this.calculationData.filter(function(variable:any) {

      return variable?.is_display==false;
    });
    this.variableComponent = ''
    for(let i=0;i<variable.length;i++){
      if(i==0)
      this.variableComponent = variable[i]['name']
      else if(i!=variable.length-1)
      this.variableComponent +=', ' +variable[i]['name']
      else
      this.variableComponent +=' and ' +variable[i]['name']

    }
    this.gross_benefits       = gross;
    this.other_benefits       = other;
    this.contributions        = contibution;
    this.recurring_deductions = recurring;
  }
  recalculateComponent(res:any){
    this.calculationData  = res.body.assign_salary;
    this.calculatedAmount = []
    this.calculatedAmount.push(res.body.gross_amount);
    this.calculatedAmount.push(res.body.net_gross);
    // if(this.salaryId==0)
    //   this.constructComponent();
    // else
    //   this.arrangeComponents()

    this.constructComponent();
    this.addmonth();
    this.addOthermonth();
    this.addrecurringmonth();
    this.salaryForm['controls'].structure.get('gross_amount')?.setValue(res.body.gross_amount);
  }
  // setErrorArray(data:any){
  //   if(data.from=='fbpMonth'){
  //     this.fbpError = data?.error
  //   }
  //   else if(data.from=='fbpYear'){
  //     this.fbpYearError = data?.error
  //   }
  //   else if(data.from=='month'){
  //     this.monthlyError = data?.error
  //   }
  //   else if(data.from=='year'){
  //     this.yearlyError = data?.error
  //   }

  // }
  // arrangeComponents(){
  //   var gross = this.filterComponents('gross_benefits',this.gross_benefits.slice(),true)
  //   var other = this.filterComponents('other_benefits',this.other_benefits.slice(),true)
  //   var contibution = this.filterComponents('contributions',this.contributions.slice(),true)
  //   var recurring = this.filterComponents('recurring_deductions',this.recurring_deductions.slice(),true)
  //   var variable = this.calculationData.filter(function(variable:any) {

  //     return variable?.is_display==false;
  //   });


  //   this.variableComponent = ''
  //   for(let i=0;i<variable.length;i++){
  //     if(i==0)
  //     this.variableComponent = variable[i]['name']
  //     else if(i!=variable.length-1)
  //     this.variableComponent +=', ' +variable[i]['name']
  //     else
  //     this.variableComponent +=' and ' +variable[i]['name']

  //   }
  //   this.gross_benefits       = gross;
  //   this.other_benefits       = other;
  //   this.contributions        = contibution;
  //   this.recurring_deductions = recurring;

    
  // }
  // filterComponents(cmpType:any,list:any,disp:any){
  //   var arr  = this.calculationData.filter(function(gross_sal:any) {
  //     return gross_sal.component_type == cmpType && gross_sal?.is_display==disp;
  //   });
  //   // console.log("list",list)
  //   // console.log("arr",arr)
  //   var filtItem
  //   list.forEach((item:any, key:any) => {
  //     filtItem = arr.filter(function(arrItem:any) {
  //       return arrItem.pay_component == item.pay_component && arrItem.name ==item.name
  //     });
  //     list[key]['monthly_renum'] = filtItem[0]?.monthly_renum?filtItem[0]?.monthly_renum:0
  //     list[key]['year_renum'] = filtItem[0]?.year_renum?filtItem[0]?.year_renum:0
  //     list[key]['removed'] = filtItem[0]?false:true
  //   })

  //   arr.forEach((item:any, key:any) => {
  //     filtItem = list.filter(function(arrItem:any) {
  //       return arrItem.pay_component == item.pay_component && arrItem.name ==item.name
  //     });
  //     if(filtItem.length <= 0){
  //       list.push(item)
  //     }
  //   })
  //   return list
  // }

}
