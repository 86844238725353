<div class="col-12">
  <div class="row" *ngIf="!loader && (filterIt().length<=0)" style="height: calc(100vh - 14rem);">
    <app-nodata-view class="vstack" [imgHeight]="'16rem'" [noDataImg]="'bg2.png'"
      [noDataText]="'Hey! Managing Expenses is a Breeze!'"
      [noDataPara]="'Expense Reports will appear here once they’re submitted and approved. Stay tuned to manage them with ease!.'"
      [hasPermission]="false"> </app-nodata-view>
  </div>
  <app-table-loader *ngIf="loader"></app-table-loader>
  <div class="card card-c2"  *ngIf="!loader && (filterIt().length>0)">
    <div class="table-responsive radius-4"
     style="max-height: calc(100vh - {{(filterIt().length>pageSize) ? '16rem':'13rem'}})">
      <table
        class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table sticky-header">
        <thead>
          <tr>
            <th class="fw-600 text-uppercase" scope="col">Report number</th>
            <th class="fw-600 text-uppercase" scope="col">Report name</th>
            <th class="fw-600 text-uppercase" scope="col">Employee Name</th>
            <th class="fw-600 text-uppercase" scope="col">Employee code</th>
            <th class="fw-600 text-uppercase" scope="col">Report Date</th>
            <th class="fw-600 text-uppercase" scope="col">Total</th>
            <th class="fw-600 text-uppercase" scope="col">to be reimbursed</th>
            <th class="fw-600 text-uppercase" scope="col">Status</th>
          </tr>
        </thead>
        <tbody class="cell-16 ">
          <!-- <tr *ngIf="filterIt().length<=0">
            <td colspan="8">
              <div class="card card-c2 flex-center p-16 bg-dark-500">
                <span class="fw-500 fs-14">No data founds</span>
              </div>
            </td>
          </tr> -->
          <ng-container
            *ngFor="let item of $any(listData |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
            <tr>
              <td><a (click)="viewDetailReport(item.id)" class="link-primary1">{{item.number}}</a></td>
              <td>{{item.name}}</td>
              <td>{{item.employee.first_name+' '+(item.employee.middle_name !=null? item.employee.middle_name+' ' :
                '')+item.employee.last_name }}</td>
              <td>{{item.employee.employee_code}}</td>
              <td> {{this.appservice.dateFormatDisplay(item.from_date)}} -
                {{this.appservice.dateFormatDisplay(item.to_date)}} </td>
              <td>{{item.total_amount| currency : item.currency}}</td>
              <td>{{item.to_reimbursed| currency : item.currency}}</td>
              <td><span
                  class="badge  py-1 px-3 {{item.status=='Reimbursed'?'badge-primary':'badge-warning'}}">{{item.status=='Accepted'?'Awaiting
                  Reimbursement':item.status}}</span></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="col-12  mt-10" *ngIf="!loader && (filterIt().length>pageSize)">
  <ngb-pagination class="d-flex justify-content-end" [collectionSize]=" filterIt().length" [pageSize]="pageSize"
    [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
  </ngb-pagination>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:57.625rem;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel " (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewDataLoader"></app-view-loader>
        <ng-container *ngIf="!viewDataLoader">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">REPORT DETAILS</p>
            <div class="d-flex align-items-center ms-auto gap-16 fs-14 "
              *ngIf="viewData?.expense_slip!=null && viewData?.expense_slip!=''">
              <a class="link-primary" (click)="viewDocument(viewData?.expense_slip, 'Expense Slip')">
                View slip
              </a>
              <span class="text-light-400">|</span>
              <a class="link-primary fs-14 d-flex align-items-center ms-auto"
                (click)="this.appservice.downloadFile(viewData?.expense_slip,'Expense slip')">
                <i class="icon-download me-1"></i>Download slip
              </a>

            </div>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">{{viewData.name}}</p>
                    <span
                      class="badge {{viewData.status=='Reimbursed'?'badge-primary':'badge-warning'}} ms-auto py-1 px-3">{{viewData.status=='Accepted'?'Awaiting
                      Reimbursement':viewData.status}}</span>
                  </div>
                  <div class="col-12">
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col-sm-3  col-12">
                          <div class="detail-title">Employee Name</div>
                          <div class="detail-desc">{{viewData.employee_name}}</div>
                        </div>
                        <div class="col-sm-3  col-12">
                          <div class="detail-title">Employee Code</div>
                          <div class="detail-desc">{{viewData.employee_code}}</div>
                        </div>
                        <div class="col-sm-3  col-12">
                          <div class="detail-title">Report Name</div>
                          <div class="detail-desc">{{viewData.name}}</div>
                        </div>
                        <div class=" col-12">
                          <div class="detail-title">Business Purpose</div>
                          <div class="detail-desc">{{viewData.business_purpose!=""?viewData.business_purpose:"--"}}
                          </div>
                        </div>
                        <div class="col-sm-3 col-12">
                          <div class="detail-title">From Date</div>
                          <div class="detail-desc">{{appservice.dateFormatDisplay(viewData.from_date)}}</div>
                        </div>
                        <div class="col-sm-3 col-12">
                          <div class="detail-title">To Date</div>
                          <div class="detail-desc">{{appservice.dateFormatDisplay(viewData.to_date)}}</div>
                        </div>
                        <div class="col-sm-3 col-12">
                          <div class="detail-title">Total Amount</div>
                          <div class="detail-desc">{{viewData.total_amount| currency : viewData.currency}}</div>
                        </div>
                        <div class="col-sm-3 col-12">
                          <div class="detail-title">Advance Amount</div>
                          <div class="detail-desc">{{viewreimbData.total_advance_amount| currency : viewreimbData.currency}}</div>
                        </div>
                        <div class="col-sm-3 col-12">
                          <div class="detail-title">Non Reimbursible Amount </div>
                          <div class="detail-desc">{{viewreimbData.non_reimburseable_amount| currency : viewreimbData.currency}}</div>
                        </div>
                        <div class="col-sm-3 col-12">
                          <div class="detail-title">Amount to be Reimbursed</div>
                          <div class="detail-desc">{{viewData.to_reimbursed| currency : viewreimbData.currency}}</div>
                        </div>
                        <!-- <div class="col-sm-6 col-12">
                        <div class="detail-title">Trip</div>
                        <div class="detail-desc">Trip 1</div>
                      </div> -->
                      </div>
                    </div>
                  </div>


                  <div class="col-12" *ngIf="viewData.status=='Reimbursed'">
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col-12 align-items-center d-flex">
                          <p class="title mb-8">Reimbursement DETAILS </p>
                        </div>
                        <ng-container
                          *ngIf="viewData.is_automatic_or_manual===false && viewData.is_advance_refund_or_carry_forward">
                          <div class="col-sm-6  col-12">
                            <div class="detail-title">Have you recieved the balance advance?</div>
                            <div class="detail-desc">Yes, Employee has returned the balance amount</div>
                          </div>
                        </ng-container>

                        <ng-container
                          *ngIf="viewData.is_automatic_or_manual===false && viewData.is_advance_refund_or_carry_forward == false">
                          <div class="col-sm-9  col-12">
                            <div class="detail-title">Have you recieved the balance advance?</div>
                            <div class="detail-desc">No, Employee can carry forward the balance advance to use it on
                              future reports.</div>
                          </div>
                        </ng-container>
                        <div class="col-sm-3  col-12">
                          <div class="detail-title">Reimbursed On</div>
                          <div class="detail-desc">{{appservice.dateFormatDisplay(viewData.reimbursed_on)}}</div>
                        </div>
                        <div class="col-sm-3  col-12" *ngIf="viewData.is_automatic_or_manual===false">
                          <div class="detail-title">Reimbursement mode</div>
                          <div class="detail-desc">Manual reimbursement</div>
                        </div>

                        <div class="col-sm-5  col-12" *ngIf="viewData.is_automatic_or_manual">
                          <div class="detail-title">Reimbursement mode</div>
                          <div class="detail-desc">Reimbursement via payroll</div>
                        </div>
                        <div class=" col-12" *ngIf="viewData.reimbursement_detail?.description && viewData.reimbursement_detail?.description!=''">
                          <div class="detail-title">Description</div>
                          <div class="detail-desc">{{viewData.reimbursement_detail?.description}}</div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-12">
                    <div class="card details-card shadow-0  ">
                      <div class="card-body px-16 py-0">
                        <nav ngbNav #nav1="ngbNav" [(activeId)]="activeTab2" class="nav nav-custom shadow-0 p-0 ">
                          <li [ngbNavItem]="1" class="success">
                            <a ngbNavLink class="nav-link">Expense</a>
                            <ng-template ngbNavContent>
                              <div class="row row-16">
                                <div class="col-12">
                                  <div class="card card-c2">
                                    <div class="table-responsive radius-4">
                                      <table
                                        class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                                        <thead>

                                          <tr>
                                            <th class="fw-600 text-uppercase" scope="col">EXPENSE Date</th>
                                            <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                                            <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                                            <th class="fw-600 text-uppercase" scope="col">Report name</th>
                                            <th class="fw-600 text-uppercase" scope="col">ATTACHMENT</th>
                                          </tr>
                                        </thead>
                                        <tbody class="cell-16 ">
                                          <ng-container *ngIf="viewData?.receipts?.length == 0">
                                            <tr>
                                              <td colspan="5">
                                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                                  <span class="fw-500 fs-14">No data found</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </ng-container>
                                          <ng-container
                                            *ngFor="let rec of $any(viewData.receipts | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                            <tr>
                                              <td> <a (click)="detailedReceiptView(rec?.id)"
                                                  class="link-primary1">{{appservice.dateFormatDisplay(rec.expense_date)}}</a>
                                              </td>
                                              <td>{{rec.category?.name}}</td>
                                              <td>{{rec.amount| currency : currency}}</td>
                                              <td>{{rec?.report?.name}}</td>
                                              <td><span class="link-primary"><i class="icon-eye fs-18 "
                                                    (click)="viewAttachmentFunction(ind,rec?.id)"></i></span></td>
                                            </tr>
                                          </ng-container>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </li>
                          <li [ngbNavItem]="2" class="success">
                            <a ngbNavLink class="nav-link">Advances & Refunds</a>
                            <ng-template ngbNavContent>
                              <div class="row row-16">
                                <div class="col-12">
                                  <div class="card card-c2">
                                    <div class="table-responsive radius-4">
                                      <table
                                        class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                                        <thead>
                                          <tr>
                                            <th class="fw-600 text-uppercase" scope="col">Date</th>
                                            <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                                            <th class="fw-600 text-uppercase" scope="col">Dispensed Amount</th>
                                          </tr>
                                        </thead>
                                        <tbody class="cell-16 ">
                                          <ng-container *ngIf="viewData?.advances?.length == 0">
                                            <tr>
                                              <td colspan="3">
                                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                                  <span class="fw-500 fs-14">No data found</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </ng-container>
                                          <ng-container
                                            *ngFor="let rec of $any(viewData?.advances | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                            <tr>
                                              <td><span class="text-nowrap link-primary1"
                                                  (click)="viewAdvanceFunction(rec?.id)">{{appservice.dateFormatDisplay(rec.expense_date)}}</span>
                                              </td>
                                              <td>{{rec.amount| currency : currency}}</td>
                                              <td>{{rec.dispansed_amount| currency : currency}}</td>
                                            </tr>
                                          </ng-container>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </li>
                        </nav>
                      </div>
                      <div class="card-body px-16 ">
                        <div [ngbNavOutlet]="nav1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer justify-content-end gap-16" *ngIf="viewData.status == 'Accepted'">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="saveReimbursement(true)">
        Payroll REIMBURSEMENT
      </button>
      <button type="submit" class="btn btn-primary text-uppercase btn-sm" (click)="reimbursementDetails(viewData.id)">
        MANUAL REIMBURSEMENT
      </button>
    </footer>
  </div>
</div>

<div *ngIf="manualReimbursement" class="side-panel side-pane-active" style="--sidepanel-width:57.625rem;"
  [class.side-pane-active]='manualReimbursement === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Manual Reimbursement</h5>
      <a class="toggle-panel" (click)="manualReimbursement = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="saveManualLoader"></app-view-loader>
        <ng-container *ngIf="!saveManualLoader">
        <div class="row row-16 ">
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0">Details</p>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12  ">
                  <p class="title mb-8">Reimbursement summary</p>
                </div>
                <div class="col-12">
                  <div class="card details-card shadow-0 p-16">
                    <div class="row row-16">
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Total Reimbursible Amount</div>
                        <div class="detail-desc">{{viewreimbData.total_receipt_amount| currency : viewreimbData.currency}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Total Advance</div>
                        <div class="detail-desc">{{viewreimbData.total_advance_amount| currency : viewreimbData.currency}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Total Advance Refunds</div>
                        <div class="detail-desc">{{viewreimbData.refund_amount| currency : viewreimbData.currency}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Total Advance Carry Forward</div>
                        <div class="detail-desc">{{viewreimbData.carryForward| currency : viewreimbData.currency}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Amount to be Reimbursed</div>
                        <div class="detail-desc">
                          <span
                            class="{{viewreimbData.reimb_amount>0?'text-success':viewreimbData.reimb_amount<0?'text-danger':''}}">{{viewreimbData.reimb_amount|
                            currency : viewreimbData.currency}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container [formGroup]="myForm">
            <!-- <pre>Form Values: {{myForm.value | json}}</pre>  -->
            <div class="col-12 form-row" *ngIf="balance=='greater'">
              <div class="row row-8">
                <div class="col-12">
                  <label for="" class="form-label required mb-0 ">Have you recieved the balace advance
                  </label>
                </div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input type="radio" id="amount" class="form-check-input" [value]="true" formControlName="returned"
                      (click)="greaterReturnedValidation()">
                    <label for="amount" class="form-check-label">Yes, Employee has returned the balance amount</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input type="radio" id="mileage" class="form-check-input" [value]="false" formControlName="returned"
                      (click)="greaterCarryForwardValidation()">
                    <label for="mileage" class="form-check-label">No, Employee can carry forward the balance advance to
                      use it on future reports.</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="balance=='less'">
              <label for="" class="form-label  ">Amount to be Reimbursed</label>
              <input type="text" [disabled]=true class="form-control" placeholder="Enter amount" id=""
                formControlName="amount">
            </div>
            <ng-container *ngIf="balance!='greater' || f.returned.value == true">
              <div class="col-12">
                <label for="" class="form-label required">Reimbursed On </label>
                <!-- <input type="text"  class="form-control" placeholder="Enter date" id=""  formControlName="date"  name="date"> -->
                <div class="input-group form-icon icon-right"
                  [ngClass]="{ 'is-invalid': ( f.date.touched) && f.date.errors }" (click)="datePicker1.open()">
                  <input class="form-control" placeholder="{{this.messageService.selectplaceholddisplay('Date')}}"
                    matInput formControlName="date" [matDatepicker]="datePicker1" [matDatepickerFilter]="dateFilter"
                    (keydown)="false" [min]="minDate"> <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                </div>
                <div *ngIf="( f.date.touched) && f.date.errors" class="invalid-feedback">
                  <div *ngIf="f.date.errors.required">{{this.messageService.validationDisplay('required')}}
                  </div>
                </div>
              </div>
              <div class=" col-12 form-row" *ngIf="balance!='equal'">
                <label for="" class="form-label ">Description </label>
                <textarea [ngClass]="{ 'is-invalid': ( f.description.touched) && f.description.errors }" type="text"
                  class="form-control " rows="4" placeholder="Enter description here..."
                  formControlName="description"></textarea>
                <div *ngIf="( f.description.touched) && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required">{{this.messageService.validationDisplay('required')}}
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="!myForm.valid || disablebtn || saveManualLoader"
        (click)="saveReimbursement(false)">{{btnText}}</button>
    </footer>
  </div>
</div>

<app-view-multiple-attachments *ngIf="viewAttchments" [from]="'expenseReportRequestView'"
  [viewAttchments]="viewAttchments" [uploadedAttachments]="uploadedAttachments" [receiptId]="receiptId"
  (closeViewAttachment)="viewAttchments = false"></app-view-multiple-attachments>


<app-view-expense-receipt [currency]="currency" *ngIf="viewReciept" [from]="'inbox'" [viewReciept]="viewReciept"
  [viewRecieptLoader]="viewRecieptLoader" [viewRecieptData]="viewRecieptData"
  (closeView)="viewReciept  = false"></app-view-expense-receipt>

<app-emp-advance-details-view [currency]="currency" *ngIf="viewAdvance" [viewAdvance]="viewAdvance"
  [viewdata]="viewAdvdata" [advanceid]="advanceid" [viewLoader]="viewLoader" [from]="'inbox'"
  (panelclose)="viewAdvance=false"> </app-emp-advance-details-view>
<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
    [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
