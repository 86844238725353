<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="reimbursementRequestForm" autocomplete="off">
    <header class="side-panel-head">
        <h5>Filters</h5>
        <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 mb-16 " [ngClass]="{ 'is-invalid': (submitted) && f.financial_year.errors}">
          <div class="col-12 form-row">
            <p class="text-uppercase required fw-500 mb-8">Financial year</p>
          </div>
          <div class="col-12 form-row">
              <div class="form-check">
                  <input name="financial_year" type="radio" id="financial_year1" class="form-check-input  "
                      formControlName="financial_year" value="previous">
                  <label for="financial_year1" class="form-check-label">Previous</label>
              </div>
          </div>
          <div class="col-12 form-row">
              <div class="form-check  mb-8">
                  <input name="financial_year" type="radio" id="financial_year2" class="form-check-input "
                      formControlName="financial_year" value="current">
                  <label for="financial_year2" class="form-check-label">Current</label>
              </div>
          </div>
        </div>
          <div *ngIf="(submitted) && f.financial_year.errors" class="invalid-feedback">
            <div *ngIf="f.financial_year.errors.required">
                {{this.messageService.validationDisplay('required')}}</div>
          </div>
        <div class="row row-16 mb-16 " [ngClass]="{ 'is-invalid': (submitted) && f.requested_on.errors}">
          <div class="col-12 form-row">
            <p class="text-uppercase required fw-500 mb-8">Requested on</p>
          </div>
          <div class="col-12 form-row">
              <div class="form-check">
                  <input name="requested_on" type="radio" id="requested_on1" class="form-check-input  "
                      formControlName="requested_on" value="last_month"
                      (change)="radioBtnChangeFunctions($event,'requested_on','last_month','specific_month','specific_year');setRequired(false)">
                  <label for="requested_on1" class="form-check-label">Last month</label>
              </div>
          </div>
          <div class="col-12 form-row">
              <div class="form-check">
                  <input name="requested_on" type="radio" id="requested_on2" class="form-check-input "
                      formControlName="requested_on" value="this_month"
                      (change)="radioBtnChangeFunctions($event,'requested_on','this_month','specific_month','specific_year');setRequired(false)">
                  <label for="requested_on2" class="form-check-label">This month</label>
              </div>
          </div>
          <div class="col-12 form-row">
            <div class="form-check">
                <input name="requested_on" type="radio" id="requested_on3" class="form-check-input "
                    formControlName="requested_on" [value]="true"
                    (change)="radioBtnChangeFunctions($event,'requested_on',true,'specific_month','specific_year');setRequired(true)">
                <label for="requested_on3" class="form-check-label">Specific month</label>
            </div>
          </div>
            <ng-container *ngIf="reimbursementRequestForm.value.requested_on==true">
                <!-- <div class="col-12 form-row">
                    <div class="input-group form-icon icon-right">
                        <input (click)="datePicker1.open()" class="form-control"
                            placeholder="{{this.messageService.selectplaceholddisplay('Period')}}" matInput
                            formControlName="sm_specific_month" [matDatepicker]="datePicker1"
                            [matDatepickerFilter]="salFilter">
                        <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                        <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                    </div>
                </div> -->
                <div class="col-6">
                  <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}">
                      <label for="" class="form-label">Month </label>
                      <app-master-select class="config-app-master" style="width:9rem"
                      [placeholder]="month_placeholder"
                              id="month" [isrequired]="false" [selectedItem]="reimbursementRequestForm.value.specific_month"
                          [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                              (ngModelChange)="monthChangeFn($event)" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}"> </app-master-select>
                  </div>
                  <div *ngIf="(submitted || f?.specific_month?.touched || f?.specific_month?.dirty)&& f?.specific_month?.errors" class="invalid-feedback">
                      <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                </div>
                <div class="col-6">
                    <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                        <label for="" class="form-label">Year </label>
                        <ng-select class="form-ngselect  fs-16"
                        formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                            <ng-option *ngFor="let yData of Years"
                                [value]="yData">{{yData}}</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                        <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="(submitted) && f.requested_on.errors" class="invalid-feedback">
          <div *ngIf="f.requested_on.errors.required">
              {{this.messageService.validationDisplay('required')}}</div>
        </div>
        <div class="row row-16 mb-16 ">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-8">Request type</p>
          </div>
          <div class="col-12 form-row">
            <div class="form-check">
                <input name="request_type" type="radio" id="request_type1" class="form-check-input  "
                    formControlName="request_type" value="new">
                <label for="request_type1" class="form-check-label">New</label>
            </div>
          </div>
          <div class="col-12 form-row">
            <div class="form-check  mb-8">
                <input name="request_type" type="radio" id="request_type2" class="form-check-input "
                    formControlName="request_type" value="cancel">
                <label for="request_type2" class="form-check-label">Cancel</label>
            </div>
          </div>
        </div>
        <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
      <div class="row row-16 mb-16">
        <div class="col-12 form-row" >
          <label for="name" class="form-label ">Reimbursement component</label>
          <ng-select [readonly]="disabled" placeholder="{{this.messageService.selectplaceholddisplay('Reimbursement Component')}}"
              formControlName="reimbursement_component" [items]="reimubrse_drop" [multiple]="true"
              groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
              bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                      [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="d-inline-flex align-items-center">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                      {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                  </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngIf="reimbursementRequestForm.value.reimbursement_component?.length ">
                      <span class="ng-value-label">{{reimbursementRequestForm.value.reimbursement_component?.length }}
                          reimbursement component
                          selected.</span>
                  </div>
              </ng-template>
          </ng-select>
          <ul class="selected-values mt-16" *ngIf="reimbursementRequestForm.value.reimbursement_component?.length != 0">
              <li class="ng-value" *ngFor="let item of reimbursementRequestForm.value.reimbursement_component,let ki =index">
                  <ng-container *ngFor="let insideitem of reimbursement_dropdown, let k =index">
                      <span class="ng-value-label" *ngIf="insideitem['name']==item">
                          {{ (insideitem['name'].length>15)? (insideitem['name'] |
                          slice:0:15)+'..':(insideitem['name']) }}</span>
                  </ng-container>
                  <span class="ng-value-icon right"
                      (click)="clearcommonForm('reimbursement_component',ki)" aria-hidden="true">×</span>
              </li>
          </ul>
      </div>
        <div class="col-12 form-row">
          <p class="text-uppercase fw-500 mb-8">Request status</p>
        </div>
        <div class="col-12 form-row">
          <div class="form-check">
              <input name="request_status" type="radio" id="request_status1"
                  class="form-check-input  " formControlName="request_status" value="pending">
              <label for="request_status1" class="form-check-label">Pending</label>
          </div>
      </div>
      <div class="col-12 form-row">
          <div class="form-check">
              <input name="request_status" type="radio" id="request_status2"
                  class="form-check-input " formControlName="request_status" value="approved" >
              <label for="request_status2" class="form-check-label">Approved</label>
          </div>
      </div>
      <div class="col-12 form-row">
          <div class="form-check">
              <input name="request_status" type="radio" id="request_status3"
                  class="form-check-input " formControlName="request_status" value="rejected">
              <label for="request_status3" class="form-check-label">Rejected</label>
          </div>
      </div>
    </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
          (click)="resetPage();resetFilterFunction()">
          reset
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
          (click)="resetPage();submitReimbursement()">
          Apply
      </button>
  </footer>
  </form>
</div>
