<div class="side-panel-body" style="overflow-y: auto">
  <div class="container-fluid px-32 py-24">
    <app-c1-loader *ngIf="loader"></app-c1-loader>
    <ng-container *ngIf="!loader">
      <!-- SELF APPROVAL -->
      <ng-container *ngIf="!approvalRequired">
        <div class="alert alert-success show" role="alert">
          This request is self approved as per organizational policy.
        </div>
      </ng-container>
      <!-- INBOX APPROVAL -->
      <ng-container *ngIf="approvalRequired">
        <ng-container *ngIf="(viewStatusData?.status == 0 && viewStatusData?.is_approval_mtx_error)">
          <div class="alert alert-danger show" role="alert">
            Your Approval Matrix has not been configured yet
          </div>
        </ng-container>
        <ng-container *ngIf="(viewStatusData?.status == 0 && !viewStatusData?.is_approval_mtx_error)">
          <div class="alert alert-danger show" role="alert">
            Error
          </div>
        </ng-container>

        <!-- Invalid due to overtime, comp off, and asset -->
        <ng-container *ngIf="(viewStatusData?.status == 1 && viewStatusData?.request_type == 'Invalid')">
          <div class="alert alert-danger show" role="alert">
            {{viewStatusData?.reason == 'Overtime approved & attendance processed' ? 'The request is invalid as the attendance has already been processed for this date.' : viewStatusData?.reason == 'Asset returned by HR' ? 'Asset status has been updated manually.' : 'The request is invalid as the compensatory off leave credited against this date has already been availed.'}}
          </div>
        </ng-container>
        <!-- End -->

        <ng-container *ngIf="viewStatusData.status == 1 && viewStatusData?.request_type != 'Invalid'">
          <p class="text-uppercase fw-500">
            Activities ({{ viewStatusLevelCnt }}/{{ viewStatusmaxLevel }})
          </p>
          <div class="dropdown_progres">
            <ul class="progress_drp">
              <ng-container *ngFor="let output of viewStatusData?.output; let i = index">
                <li class="progress__item {{
                    output.status == 'Approved'
                      ? 'progress__item--accepted'
                      : output.status == 'Confirmed'
                      ? 'progress__item--accepted'
                      : output.status == 'Pending'
                      ? 'progress__item--pending'
                      : output.status == 'Rejected'
                      ? 'progress__item--rejected'
                      : output.status == 'Terminated'
                      ? 'progress__item--rejected'
                      : output?.status == 'Cancelled'
                      ? 'progress__item--rejected'
                      : output?.status == 'Extended Probation'
                      ? 'progress__item--multiple'
                      : output?.status == 'Invalid'
                      ? 'progress__item--rejected'
                      : 'progress__item--intimation'
                  }}">
                  <div class="progress__tile pb-16 ms-40">
                    <p class="progress__title">
                      {{
                      output.status == "Approved"
                      ? "Accepted by"
                      :output.status == "Confirmed"
                      ? "Confirmed by"
                      : output.status == "Pending"
                      ? "Pending with"
                      : output.status == "Rejected"
                      ? "Rejected by"
                      : output.status == "Terminated"
                      ? "Terminated by"
                      : output.status == "Extended Probation"
                      ? "Probation Extended by"
                      : output.status == "Cancelled"
                      ? ""
                      : output.status == "Invalid"
                      ? ""
                      : "Intimation"
                      }}
                    </p>
                    <div class="card details-card flex-row p-16"
                      *ngIf="output.action != 'error' && output?.status != 'Cancelled' && output.action != 'Intimation'">
                      <ng-container *ngIf="output?.employee_data?.length == 1">
                        <ng-container *ngFor="
                            let empData of output?.employee_data;
                            let j = index
                          ">
                          <img *ngIf="
                              empData?.profile_image != '' &&
                              empData?.profile_image != null
                            " src="{{ empData?.profile_image }}" alt="" class="avatar-circle sq-42 me-12"
                            [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                            container="body" />
                          <div *ngIf="
                              empData?.profile_image == '' ||
                              empData?.profile_image == null
                            " class="avatar-sm avatar-circle sq-42 me-12 {{
                              empData?.color_code
                            }}" [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                            container="body">
                            <span>{{
                              this.appservice.getFirstChar(
                              empData?.first_name + " " + empData?.last_name,
                              2
                              )
                              }}</span>
                          </div>
                          <ng-template #popContentSingle>
                            <div class="user-details">
                              <span class="user-label">{{
                                empData?.first_name +
                                " " +
                                (empData?.middle_name != null
                                ? empData?.middle_name
                                : "") +
                                " " +
                                empData?.last_name
                                }}</span>
                              <span class="user-code">EMP CODE: {{ empData?.employee_code }}
                              </span>
                            </div>
                          </ng-template>
                          <div class="overflow-hidden w-100">
                            <div class="d-flex align-items-start">
                              <p class="fs-14 fw-600 text-light-600 mb-0 me-16 text-trim"
                              title="{{empData?.first_name+' '+(empData?.middle_name != null? empData.middle_name: '')+' '+empData?.last_name+' ('+empData?.employee_code+')'}}">
                                {{ empData?.first_name }}
                                {{
                                empData?.middle_name != null
                                ? empData.middle_name
                                : ""
                                }}
                                {{ empData?.last_name }} ({{
                                empData?.employee_code
                                }})
                              </p>
                              <span class="ms-auto text-light-400 text-end fs-10 text-nowrap" *ngIf="
                                  output?.status == 'Approved' ||
                                  output?.status == 'Rejected'
                                ">{{
                                this.appservice.timeFormatDisplay(
                                output.modified_at_time
                                )
                                }}
                                {{
                                this.appservice.dateFormatDisplay(
                                output.modified_at
                                )
                                }}</span>
                            </div>
                            <div class="fs-12 fw-500 text-light-400 mt-1">
                              {{ empData?.designation }} |
                              {{ empData?.company }}
                            </div>

                            <!-- For Reimbursement view status start -->
                            <ng-container
                              *ngIf="output.action != 'Intimation' && (from == 'reimbursement-inbox' || from == 'reimbursement-request')">
                              <div *ngIf="
                                  viewStatusData?.request_type ==
                                  'Reimbursement'
                                " class="fs-12 fw-500 text-light-400 mt-1">
                                <span class="text-success">{{
                                  output?.status == "Pending"
                                  ? 0
                                  : output?.approved_count
                                  }}
                                  Approved</span>
                                |
                                <span class="text-danger">{{
                                  output?.status == "Pending"
                                  ? 0
                                  : output?.rejected_count
                                  }}
                                  Rejected</span>
                              </div>
                            </ng-container>
                            <!-- For Reimbursement view status end -->

                            <!-- For Employee document view status start -->
                            <ng-container
                              *ngIf=" output.action != 'Intimation' && (from == 'employee-inbox' || from == 'employee-request')">
                              <div *ngIf="viewStatusData?.request_type == 'Employee Profile'"
                                class="fs-12 fw-500 text-light-400 mt-1"> <span class="text-success">{{output?.status ==
                                  'Pending' ? 0 : output?.approved_count}} Approved</span> | <span
                                  class="text-danger">{{output?.status == 'Pending' ? 0 : output?.rejected_count}}
                                  Rejected</span> </div>
                            </ng-container>
                            <!-- For Employee document view status end -->

                            <!-- For Attendance regularization view status start -->
                            <ng-container
                              *ngIf=" output.action != 'Intimation' && (from == 'regularization-inbox' || from == 'regularization-request')">
                              <div *ngIf="viewStatusData?.request_type == 'Attendance Regularization'"
                                class="fs-12 fw-500 text-light-400 mt-1"> <span class="text-success">{{output?.status ==
                                  'Pending' ? 0 : output?.approved_count}} Approved</span> | <span
                                  class="text-danger">{{output?.status == 'Pending' ? 0 : output?.rejected_count}}
                                  Rejected</span> | <span class="text-primary-emphasis">{{output?.status == 'Pending' ?
                                  0 : output?.invalid_count}} Invalid</span> </div>
                            </ng-container>
                            <!-- For Attendance regularization view status end -->

                            <!-- For On duty view status start -->
                            <ng-container
                              *ngIf=" output.action != 'Intimation' && (from == 'onduty-inbox' || from == 'onduty-request')">
                              <div *ngIf="viewStatusData?.request_type == 'On Duty'"
                                class="fs-12 fw-500 text-light-400 mt-1"> <span class="text-success">{{output?.status ==
                                  'Pending' ? 0 : output?.approved_count}} Approved</span> | <span
                                  class="text-danger">{{output?.status == 'Pending' ? 0 : output?.rejected_count}}
                                  Rejected</span> | <span class="text-primary-emphasis">{{output?.status == 'Pending' ?
                                  0 : output?.invalid_count}} Invalid</span> </div>
                            </ng-container>
                            <!-- For On duty view status end -->

                            <!-- For Asset view status start -->
                            <ng-container
                              *ngIf=" output.action != 'Intimation' && (from == 'asset-inbox' || from == 'asset-request')">
                              <div *ngIf="viewStatusData?.request_type == 'Asset'"
                                class="fs-12 fw-500 text-light-400 mt-1"> <span class="text-success">{{output?.status ==
                                  'Pending' ? 0 : output?.approved_count}} Approved</span> | <span
                                  class="text-danger">{{output?.status == 'Pending' ? 0 : output?.rejected_count}}
                                  Rejected</span>
                                   <!-- | <span class="text-primary-emphasis">{{output?.status == 'Pending' ?
                                  0 : output?.invalid_count}} Invalid</span>  -->
                                </div>
                            </ng-container>
                            <!-- For Asset view status end -->

                            <div class="detail-desc mt-1" *ngIf="output.comment != ''">
                              {{ output?.comment }}
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="output?.employee_data?.length > 1">
                        <div class="d-flex align-items-center overlap-avatar overlap-left">
                          <ng-container *ngFor="
                              let multiEmpData of output.employee_data;
                              let k = index
                            ">
                            <img class="avatar-sm sq-32 avatar-circle" *ngIf="
                                k < 3 &&
                                multiEmpData?.profile_image != '' &&
                                multiEmpData?.profile_image != null
                              " src="{{ multiEmpData?.profile_image }}" [ngbPopover]="popContentSingle"
                              popoverClass="user-list single-user" triggers="hover" container="body" />

                            <div class="avatar-sm sq-32 avatar-circle {{
                                multiEmpData?.color_code
                              }}" *ngIf="
                                k < 3 &&
                                (multiEmpData?.profile_image == '' ||
                                  multiEmpData?.profile_image == null)
                              " [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                              container="body">
                              <span>{{
                                this.appservice.getFirstChar(
                                multiEmpData?.first_name +
                                " " +
                                multiEmpData?.last_name,
                                2
                                )
                                }}</span>
                            </div>
                            <ng-template #popContentSingle>
                              <div class="user-details">
                                <span class="user-label">{{
                                  multiEmpData?.first_name +
                                  " " +
                                  (multiEmpData?.middle_name != null
                                  ? multiEmpData?.middle_name
                                  : "") +
                                  " " +
                                  multiEmpData?.last_name
                                  }}</span>
                                <span class="user-code">EMP CODE: {{ multiEmpData?.employee_code }}
                                </span>
                              </div>
                            </ng-template>
                            <div *ngIf="k == 3" class="avatar-sm sq-32 avatar-circle" [ngbPopover]="popContentMultilpe"
                              popoverClass="user-list multi-user" triggers="click" container="body">
                              <span>+{{
                                this.appservice.subEmp(
                                output?.employee_data?.length
                                )
                                }}</span>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-template #popContentMultilpe>
                        <ul>
                          <ng-container *ngFor="
                              let actdata of output?.employee_data;
                              let h = index
                            ">
                            <ng-container *ngIf="h >= 3">
                              <li *ngIf="
                                  actdata?.profile_image != '' &&
                                  actdata?.profile_image != null
                                ">
                                <img class="user-avatar" src="{{ actdata?.profile_image }}" />
                                <div class="user-details">
                                  <span class="user-label">{{
                                    actdata?.first_name +
                                    " " +
                                    (actdata?.middle_name != null
                                    ? actdata?.middle_name
                                    : "") +
                                    " " +
                                    actdata?.last_name
                                    }}</span>
                                  <span class="user-code">EMP CODE :
                                    {{ actdata?.employee_code }}</span>
                                </div>
                              </li>
                              <li *ngIf="
                                  actdata?.profile_image == '' ||
                                  actdata?.profile_image == null
                                ">
                                <span class="user-avatar {{ actdata?.color_code }}">{{
                                  this.appservice.getFirstChar(
                                  actdata?.first_name +
                                  " " +
                                  actdata?.last_name,
                                  2
                                  )
                                  }}</span>
                                <div class="user-details">
                                  <span class="user-label">{{
                                    actdata?.first_name +
                                    " " +
                                    (actdata?.middle_name != null
                                    ? actdata?.middle_name
                                    : "") +
                                    " " +
                                    actdata?.last_name
                                    }}</span>
                                  <span class="user-code">EMP CODE :
                                    {{ actdata?.employee_code }}</span>
                                </div>
                              </li>
                            </ng-container>
                          </ng-container>
                        </ul>
                      </ng-template>
                    </div>

                    <!-- test start -->
                    <div class="card details-card flex-row p-16 gap-24"
                      *ngIf="output.action != 'error' && output?.status == 'Cancelled'">
                      <i class="bi bi-x-circle fs-18 text-danger"></i>
                      <div>
                        <p class="mb-0 fs-14 text-danger fw-600">{{output?.status}} </p>
                        <div class="mb-0 fs-14 text-light-600 me-16"> <p
                            class="fw-600 mb-0"
                            title="{{output?.employee_data[0]?.first_name +' '+(output?.employee_data[0]?.middle_name != null? output?.employee_data[0]?.middle_name: '') +' ' +output?.employee_data[0]?.last_name+' ('+output?.employee_data[0]?.employee_code+')'}}">
                              {{output?.employee_data[0]?.first_name +
                            " " +
                            (output?.employee_data[0]?.middle_name != null
                            ? output?.employee_data[0]?.middle_name
                            : "") +
                            " " +
                            output?.employee_data[0]?.last_name}} ({{output?.employee_data[0]?.employee_code}})</p>
                          {{(output?.status == 'Cancelled' && output?.cancelled_count > 0 &&
                          output?.is_request_was_pending ) ? 'has withdrawn this request.' : (output?.status ==
                          'Cancelled' && output?.cancelled_count > 0 && (!output?.is_request_was_pending)) ? 'has cancelled an approved request.' : (output?.status == 'Cancelled' && output?.cancel_status ==
                          'Cancelled' && output?.is_request_was_pending) ? 'has withdrawn this request.' :
                          (output?.status == 'Cancelled' && output?.cancel_status == 'Cancelled' &&
                          !(output?.is_request_was_pending)) ? 'has cancelled an approved request.' :
                          output?.cancel_status == 'Pending' ? 'has raised a cancellation request and is in pending' :
                          output?.cancel_status == 'Accepted' ? 'has raised a cancellation request and it is approved by' : output?.cancel_status == 'Rejected' ? 'has raised a cancellation request and it is rejected by ' : ''}} <span
                            *ngIf="output?.cancel_status == 'Accepted'  || output?.cancel_status == 'Rejected' "
                            class="fw-600 text-trim"
                            title="{{output?.cancel_approver[0]?.first_name +' '+(output?.cancel_approver[0]?.middle_name != null? output?.cancel_approver[0]?.middle_name: '') +' ' +output?.cancel_approver[0]?.last_name+' ('+output?.cancel_approver[0]?.employee_code+')'}}">
                              {{output?.cancel_approver[0]?.first_name +
                            " " +
                            (output?.cancel_approver[0]?.middle_name != null
                            ? output?.cancel_approver[0]?.middle_name
                            : "") +
                            " " +
                            output?.cancel_approver[0]?.last_name }}
                            ({{output?.cancel_approver[0]?.employee_code}})</span></div>

                        <!-- <p
                          class="detail-desc mt-1"
                          *ngIf="output?.cancel_status == 'Cancelled' && output?.cancel_comment != ''"
                        > -->
                        <!-- <p
                          class="detail-desc mt-1">
                          {{ (output?.cancel_status == 'Cancelled' && output?.cancel_comment != '') ?output?.cancel_comment : (output?.cancel_status == 'Cancelled' && output?.comment != '') ? output?.comment : (output?.cancel_status == 'Cancelled' && output?.cancelled_count > 0 ) ? output?.cancelled_count+' cancelled' : (output?.cancel_status != 'Cancelled' && output?.comment != '') ? output?.comment : ''  }}
                        </p> -->

                        <!-- test start -->
                        <p class="detail-desc mt-1 mb-0">{{ (output?.status == 'Cancelled' && output?.cancelled_count > 0 ) ?
                          output?.cancelled_count+' cancelled' : (output?.cancel_status == 'Cancelled' &&
                          output?.cancel_comment != '') ?output?.cancel_comment : (output?.cancel_status == 'Cancelled'
                          && output?.comment != '') ? output?.comment : (output?.cancel_status != 'Cancelled' &&
                          output?.comment != '') ? output?.comment : '' }}</p>
                        <!-- test end -->

                        <!-- <p
                          class="detail-desc mt-1"
                          *ngIf="output?.cancel_status != 'Cancelled' && output?.comment != ''"
                        >
                          {{ output?.comment }}
                        </p> -->
                      </div>
                    </div>
                    <!-- test end -->

                    <!-- Intimation start -->
                    <div class="card details-card px-16"
                      *ngIf="output.action != 'error' && output?.action == 'Intimation'">

                        <div class="card-body px-0 d-flex align-item-start" *ngFor="
                            let empData of output?.employee_data;
                            let j = index
                          ">
                          <img *ngIf="
                              empData?.profile_image != '' &&
                              empData?.profile_image != null
                            " src="{{ empData?.profile_image }}" alt="" class="avatar-circle sq-42 me-12"
                            [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                            container="body" />
                          <div *ngIf="
                              empData?.profile_image == '' ||
                              empData?.profile_image == null
                            " class="avatar-sm avatar-circle sq-42 me-12 {{
                              empData?.color_code
                            }}" [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                            container="body">
                            <span>{{
                              this.appservice.getFirstChar(
                              empData?.first_name + " " + empData?.last_name,
                              2
                              )
                              }}</span>
                          </div>
                          <ng-template #popContentSingle>
                            <div class="user-details">
                              <span class="user-label">{{
                                empData?.first_name +
                                " " +
                                (empData?.middle_name != null
                                ? empData?.middle_name
                                : "") +
                                " " +
                                empData?.last_name
                                }}</span>
                              <span class="user-code">EMP CODE: {{ empData?.employee_code }}
                              </span>
                            </div>
                          </ng-template>
                          <div class="overflow-hidden w-100">
                            <div class="d-flex align-items-start">
                              <p class="fs-14 fw-600 text-light-600 mb-0 me-16 text-trim"
                              title="{{empData?.first_name+' '+(empData?.middle_name != null ? empData.middle_name: '')+' '+empData?.last_name+' ('+empData?.employee_code+')'}}">
                                {{ empData?.first_name }}
                                {{
                                empData?.middle_name != null
                                ? empData.middle_name
                                : ""
                                }}
                                {{ empData?.last_name }} ({{
                                empData?.employee_code
                                }})
                              </p>

                            </div>
                            <div class="fs-12 fw-500 text-light-400 mt-1">
                              {{ empData?.designation }} |
                              {{ empData?.company }}
                            </div>


                            <div class="detail-desc mt-1" *ngIf="output.comment != ''">
                              {{ output?.comment }}
                            </div>
                          </div>
                        </div>
                    </div>
                    <!-- Intimation end -->

                    <div class="alert alert-danger show" role="alert" *ngIf="output?.action == 'Error'">
                      {{ output?.error_data__error_reason }}
                    </div>
                  </div>
                </li>
                <!-- <li class="progress__item progress__item--rejected">
                  <div class="progress__tile pb-16 ms-40">
                    <p class="progress__title">Rejected by</p>
                    <div class="card details-card flex-row p-16 gap-24">
                      <i class="bi bi-x-circle fs-18 text-danger"></i>
                      <div>
                        <p class="mb-0 fs-14 text-danger fw-600">Cancelled </p>
                        <p class="mb-0 fs-14 text-light-600"> <span class="fw-600">Remya EN (EMP00010)</span>  has cancelled this request</p>
                      </div>
                    </div>
                  </div>
                </li> -->
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- WILL BE INTEGRATE IN FUTURE -->

    <!-- <p class="text-uppercase fw-500"> For multiple approvals</p>
        <div class="dropdown_progres">
          <ul class="progress_drp">
              <li class="progress__item progress__item--multiple">
                <div class="progress__tile pb-16 ms-40">
                  <p class="progress__title">Activity 1</p>
                    <div class="card details-card flex-row p-16">
                      <img src="/assets/images/uploads/user-img/user1.jpg" alt="" class="avatar-circle sq-42 me-12" >
                      <div class="overflow-hidden  w-100">
                        <div class="d-flex align-items-start">
                          <div class="fs-14 fw-600 text-light-600">Floyd Miles</div>
                          <span class="ms-auto text-light-400 fs-10 ">10:34 AM 25/10/2012</span>
                        </div>
                        <div class="fs-12 fw-500 text-light-400 mt-1"> UX Designer | Skizzle </div>
                      <div class="fs-12 fw-500 text-light-400 mt-1"> <span class="text-danger">4 Rejected</span> | <span class="text-warning">2 pending</span> </div>
                      <div class="detail-desc mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt elit vitae volutpat laoreet. Phasellus molestie</div>
                      </div>
                    </div>
                </div>
              </li>
              <li class="progress__item progress__item--multiple">
                <div class="progress__tile pb-16 ms-40">
                  <p class="progress__title">Activity 2</p>
                  <div class="card details-card flex-row  p-16">
                    <img src="/assets/images/uploads/user-img/user1.jpg" alt="" class="avatar-circle sq-42 me-12">
                    <div class="overflow-hidden  w-100">
                      <div class="d-flex align-items-start">
                        <div class="fs-14 fw-600 text-light-600">Floyd Miles</div>
                        <span class="ms-auto text-light-400 fs-10 ">10:34 AM 25/10/2012</span>
                      </div>
                      <div class="fs-12 fw-500 text-light-400 mt-1"> UX Designer | Skizzle </div>
                      <div class="fs-12 fw-500 text-light-400 mt-1"> <span class="text-success">5 Approved</span> | <span class="text-warning">1 pending</span></div>
                      <div class="detail-desc mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt elit vitae volutpat laoreet. Phasellus molestie
                      </div>
                    </div>
                  </div>
                </div>
              </li>


            </ul>
        </div> -->
  </div>
</div>
