import { Component, OnInit,Input, Output,EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BankService } from 'src/app/@core/services/bank.service';
import { Validators,FormBuilder,ValidatorFn,ValidationErrors,AbstractControl } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { IfscValidator } from 'src/app/@shared/validators/ifsc.validators';
import { AccountnumValidator } from 'src/app/@shared/validators/accountnum.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { Subject } from 'rxjs';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankDetailsComponent implements OnInit {
  showSidePanel       = false;
  loader              = true;
  submitted           = false;
  isrequired          = true;
  header:any          = "";
  loader1             = false;
  paymentPlaceholder  = this.messageService.selectplaceholddisplay('payment mode');
  payment:string      = "payment_mode";
  activeOnlineTrans   = false;
  permissions:any     = [];
  field               : any
  edit                : boolean = false;
  value               : any
  bankUnit            : any = []
  saveClicked         : boolean = false
  showInfo            = false;
  infoMsg:any;
  @Input() bankData:any ;
  @Input() id:any;
  @Input() is_employeeRequest:any;
  @Output() submitmethod = new EventEmitter();
  @Output() loadData     = new EventEmitter();
  @Input() routingUrl:any;
  @Input() loginAs :any;
  @Input() from:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;

  private subjectKeyUp = new Subject<any>();


  checkAccountNumber: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {

    let acc_no        = group.get('account_number')?.value;
    let re_acc_no     = group.get('re_account_number')?.value

    if(acc_no!='' && re_acc_no!=''){
      return acc_no === re_acc_no ? null : { notSame: true }

    }
    else
      return null
  }
  bankForm            = this.formBuilder.group({
                            id                  : 0,
                            employee            : 0,
                            salary_pay_mode     : [,Validators.required],
                            account_number      : [,[Validators.required,AccountnumValidator()]],
                            re_account_number   : [,[Validators.required,AccountnumValidator()]],
                            account_holdername  : [,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
                            bank_name           : [,[Validators.required,Validators.maxLength(100),UnamepatternValidator()]],
                            ifsc_code           : [,[Validators.required,IfscValidator()]],
                            branch_name         : [,[Validators.maxLength(100),UnamepatternValidator()]],
                            branch_address      : [,[Validators.maxLength(500)]]
                        },
                        { validators: this.checkAccountNumber}
                        );
  constructor(private bankService:BankService,private formBuilder:FormBuilder, public messageService : MessageService,private existDataService : ExistDatasService,
    private changeDetectorRef: ChangeDetectorRef,
    private appservice:AppService) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService(d.fieldName,d.fieldValue)
    })


    this.loader = false;
    this.getPermission();

   if(this.bankData.length!=0)
    this.loadData.emit();
    this.getBankData();
    this.addConditionalValidation();
  }
  getPermission(){
    this.permissions = this.appservice.getPermissions(this.routingUrl);
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  addConditionalValidation(){
    this.submitted = false
    this.addValidation("account_number",AccountnumValidator(),false);
    this.addValidation("re_account_number",AccountnumValidator(),false);
    this.addValidation("account_holdername",NamefieldValidator(),100);
    this.addValidation("ifsc_code",IfscValidator(),false);
    this.addValidation("branch_name",UnamepatternValidator(),100);
    this.addValidation("bank_name",UnamepatternValidator(),100);
    this.addValidation("branch_address",UnamepatternValidator(),500);
  }

  addValidation(inpt:any,pattern:any,maxlength:any){
      let paymentOption = this.bankForm.get('salary_pay_mode')?.value;
      this.bankForm.get(inpt)?.setValue('');
      if(paymentOption=='Online Transfer'){
        this.activeOnlineTrans = true;

        if(maxlength==false) {
            this.bankForm.get(inpt)?.setValidators([Validators.required,pattern])

        }
        else {
          if((inpt=='branch_name') || (inpt=='branch_address'))
          this.bankForm.get(inpt)?.setValidators([Validators.maxLength(maxlength),pattern])
          else
          this.bankForm.get(inpt)?.setValidators([Validators.required,Validators.maxLength(maxlength),pattern])

        }
      }
      else {
        this.activeOnlineTrans = false;
        this.bankForm.get(inpt)?.clearValidators();
      }
      this.bankForm.controls[inpt].updateValueAndValidity();
      this.bankForm.controls[inpt].markAsUntouched()
    }

  getBankData(){

    this.bankService.getPersonalData(this.id).subscribe((res:any)=>{
      if(res.bank_detail.length>0){

        this.bankData = res.bank_detail[0];
        Object.entries( this.bankData ).forEach(([key, value]) => {

          if(value == null) {
            this.bankForm.get(key)?.setValue('');
          } else {
            this.bankForm.get(key)?.setValue(value);

          }

        })
        this.bankForm.get('re_account_number')?.setValue(this.bankData.account_number)

      }

    })
  }
  get f(){
     return this.bankForm.controls;
  }
  editPaymentmode(event:any){
    if(this.is_employeeRequest?.is_bank_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo            = false;
      this.header = event;
      if(event == 'Edit')
        this.loader1 = true;
      else
        this.bankForm.reset();
      this.getBankData();

      this.showSidePanel  = true;
      this.submitted      = false;
      if(event == 'Edit'){
        this.edit = true
        this.loader1        = false;
      }else
        this.bankForm.get('id')?.setValue(0);

      this.bankForm.get('employee')?.setValue(this.id)
    }

  }
  onSubmit(){
    if(this.bankForm.invalid){
      for(let i in this.bankForm.controls)
      this.bankForm.controls[i].markAsTouched();
      return;
    } else {
      this.submitted  = true;
    }

    this.submitted = false;
    this.submitmethod.emit(this.bankForm.value);
    this.showSidePanel  = false;

  }

  // Simplified function to use for validation
  existService(fieldName :any, fieldValue:any){
    const fc = this.bankForm.get(fieldName);
    if(!this.edit){
      this.value = {
        "page":"BankDetail",
        "field":`${fieldName}`,
        "value": `${fieldValue}`
      }
    }else{
      this.value = {
        "id": this.bankForm.get('id')?.value,
        "page":"BankDetail",
        "field":`${fieldName}`,
        "value": `${fieldValue}`
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.bankUnit = res.body

      this.loader = false

        if(this.bankUnit.data ==='invalid'){
          fc?.setErrors({
            'exist' : true
          })
          this.saveClicked = true

        }else{
          this.saveClicked = false
        }
      this.changeDetectorRef.detectChanges();

    })
  }

  keyup(fieldName :any, fieldValue:any){
    this.subjectKeyUp.next({fieldName,fieldValue});
  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }

}
