import { Component, OnInit, Inject, ViewChild, ElementRef, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { RoleService } from 'src/app/@core/services/role.service';
import { MessageService } from 'src/app/message.global';
import { MonthlyTDSComponent } from './monthly-tds/monthly-tds.component';
import { EsiComponent } from './esi/esi.component';
import { PtComponent } from './pt/pt.component';
import { PfComponent } from './pf/pf.component';
import { CtcPerSalaryComponent } from './ctc-per-salary/ctc-per-salary.component';
import { SalaryRegisterComponent } from './salary-register/salary-register.component';
import { PaySalaryComponent } from './pay-salary/pay-salary.component';;
import { PayComponentService } from 'src/app/@core/services/pay-component.service';
import { YtdSalaryDetailsComponent } from './ytd-salary-details/ytd-salary-details.component';
import { InvestmentRequestComponent } from './investment-request/investment-request.component';
import { CtcRevisionComponent } from './ctc-revision/ctc-revision.component';
import { SalaryOnHoldComponent } from './salary-on-hold/salary-on-hold.component';
import { LwfComponent } from './lwf/lwf.component';
import { EmployeeStatutoryComponent } from './employee-statutory/employee-statutory.component';
import { ReimbursementRequestComponent } from './reimbursement-request/reimbursement-request.component';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { FinalSettlementReportComponent } from './final-settlement-report/final-settlement-report.component';
import { AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service'
import * as moment from 'moment';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InvestmentNotSubmittedComponent } from './investment-not-submitted/investment-not-submitted.component';
import { VariablePaidComponent } from './variable-paid/variable-paid.component';
import { OvertimePaymentComponent } from './overtime-payment/overtime-payment.component';
import { GratitudePaidComponent } from './gratitude-paid/gratitude-paid.component';
import { NpsEmployerComponent } from './nps-employer/nps-employer.component';
import { TdsComputationComponent } from './tds-computation/tds-computation.component';

@Component({
  selector: 'app-payroll-report-configure',
  templateUrl: './payroll-report-configure.component.html',
  styleUrls: ['./payroll-report-configure.component.scss'],
})
export class PayrollReportConfigureComponent implements OnInit {
scrollLeft(e:any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft -= 150;
 }
 scrollRight(e:any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft += 150;
 }
// Calling the close fn of tag in parent from child
  @ViewChild(MonthlyTDSComponent, { static: false }) appMonthly!: MonthlyTDSComponent;
  @ViewChild(EsiComponent, { static: false }) appEsi!: EsiComponent;
  @ViewChild(PtComponent, { static: false }) appPt!: PtComponent;
  @ViewChild(PfComponent, { static: false }) appPf!: PfComponent;
  @ViewChild(CtcPerSalaryComponent, { static: false }) appCtc!: CtcPerSalaryComponent;
  @ViewChild(SalaryRegisterComponent, { static: false }) appsalaryRegister!: SalaryRegisterComponent;
  @ViewChild(PaySalaryComponent, { static: false }) appReleaseSalary!: PaySalaryComponent;
  @ViewChild(YtdSalaryDetailsComponent, { static: false }) appYtdSalaryDetails!: YtdSalaryDetailsComponent;
  @ViewChild(InvestmentRequestComponent, { static: false }) appInvestmentRequest!: InvestmentRequestComponent;
  @ViewChild(CtcRevisionComponent, { static: false }) appCTCRevision!: CtcRevisionComponent;
  @ViewChild(SalaryOnHoldComponent, { static: false }) appSalaryOnHold!: SalaryOnHoldComponent;
  @ViewChild(LwfComponent, { static: false }) appLwf!: LwfComponent;
  @ViewChild(EmployeeStatutoryComponent, {static: false}) appEmployee_statutory!: EmployeeStatutoryComponent
  @ViewChild(ReimbursementRequestComponent, {static: false}) appReimburse!: ReimbursementRequestComponent
  @ViewChild(FinalSettlementReportComponent, {static: false}) appFinalSettlement!: FinalSettlementReportComponent
  @ViewChild(InvestmentNotSubmittedComponent, {static: false}) investmentNotSubmitted!: InvestmentNotSubmittedComponent
  @ViewChild(VariablePaidComponent, { static: false }) appVarPaid!: VariablePaidComponent;
  @ViewChild(OvertimePaymentComponent, { static: false }) appOverPy!: OvertimePaymentComponent;
  @ViewChild(GratitudePaidComponent, { static: false }) appGratitude!: GratitudePaidComponent;
  @ViewChild(NpsEmployerComponent, { static: false }) appNPS!: NpsEmployerComponent;
  @ViewChild(TdsComputationComponent, { static: false }) appTDSComp!: TdsComputationComponent;

  alertShow = true;
  table: any;
  scheduleReport      = false;
  applyFilter         = false;
  resetFilter         = false;
  viewDetail          = false;
  addColumnFilter     = false;
  dataSource:any      = [];
  columnNames:any     = [];
  page                = 1;
  pageSize            = 20;
  showTotalPages      = 3;
  fileType            = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension       = '.xlsx';
  fileName:any;
  loader              = true;
  nodata              = false;
  reportName:any      = '';
  paginateData: any[] = [];
  callReports : any
  callMonthlyTDS :boolean = false
  displayedColumns:any  = [];
  columnOptions:any     = [];
  columnOptions1:any    = [];
  datasControl          = new FormControl();
  columnsControl        = new FormControl();
  checkColumn:any       = [];
  tempArr: any          = [] ;
  empstatArr : any      = [];
  fullData:any          = [];
  yearList : any        = [];
  currentYear               = new Date().getFullYear();
  checkListValue:any
  responseLength :any
  headerCheck :any
  checkList :any
  headerList:any
  data:any
  closeControl :any
  event : any
  contrlName : any
  val : any
  type:any
  form:any
  item:any
  index:any
  columnsArr : any = []
  restFilterSave : boolean = false
  filterFormData      : any     = ''
  childFilterSubmit   : boolean = false
  //FILTER MEMORIZE VARIABLES
  dataFilter: any = [];
  header_filter: any    = [];
  resDataFilter:any;
  resHeader_filter:any  = [];
  filterDisable         = false;
  resetReportName:any;
  is_colfilter          = false;
  //SCHEDULE REPORT MEMORIZE VARIABLES
  isFilter              = false;
  scheduleText:any      = '';
  //TAG FILTER VARIABLES
  appliedFilter:any     = [];
  tagMultiData:any      = [];
  tagMultiCnt:any       = 0;
  OuFilterForm = this.formBuilder.group({
    leave_policy          : [''],
    leave_type            : [''],
    company               : [''],
    bu                    : [''],
    branch                : [''],
    designation           : [''],
    department            : [''],
    employee_list         : [''],
    grade                 : [''],
    employee_type         : [''],
    employment_status     : [''],
  })
  //FILTER FORMES
  employeeForm = this.formBuilder.group({
    salary_month          : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm
  });
  investmentForm = this.formBuilder.group({
    investment_type       : ['',[Validators.required]],
    requested_on          : '',
    req_specific_month    : null,
    req_specific_year     : null,
    financial_year        : [null,[Validators.required]],
    ouFilterForm          : this.OuFilterForm
  });
  ptForm = this.formBuilder.group({
    salary_month       : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm
  });
  lwfForm = this.formBuilder.group({
    salary_month        : ['this_month',[Validators.required]],
    sm_specific_month   : null,
    sm_specific_year    : null,
    ouFilterForm        : this.OuFilterForm
  });
  salaryRegisterForm = this.formBuilder.group({
    salary_month          : ['this_month',[Validators.required]],
    sm_specific_month     : null,
    sm_specific_year      : null,
    pay_group             : '',
    date_of_joining       : '',
    doj_specific_month    : null,
    doj_specific_year     : null,
    date_of_leaving       : '',
    dol_specific_month    : null,
    dol_specific_year     : null,
    ouFilterForm          : this.OuFilterForm
  });
  ctcSalaryStructureForm = this.formBuilder.group({
    ctc_breakup           : ['monthly',[Validators.required]],
    pay_group             : ['',[Validators.required]],
    date_of_joining       : '',
    doj_specific_month    : null,
    doj_specific_year     : null,
    date_of_leaving       : '',
    dol_specific_month    : null,
    dol_specific_year     : null,
    ouFilterForm          : this.OuFilterForm
  });
  releseSalaryForm = this.formBuilder.group({
    salary_month          : ['this_month',[Validators.required]],
    sm_specific_month     : null,
    sm_specific_year      : null,
    ouFilterForm          : this.OuFilterForm
  });
  esiForm = this.formBuilder.group({
    salary_month          : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    pay_group             : '',
    ouFilterForm          : this.OuFilterForm
  });
  ytdSalaryForm = this.formBuilder.group({
    ouFilterForm          : this.OuFilterForm,
    pay_group             : '',
    financial_year        : [null ,[Validators.required]],
  });
  salaryOnoldForm = this.formBuilder.group({
    salary_month          : ['this_month',[Validators.required]],
    sm_specific_month     : null,
    sm_specific_year      : null,
    ouFilterForm          : this.OuFilterForm,
  });
  monthlyTDSForm = this.formBuilder.group({
    salary_month          :['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm,
  })
   // Employee Statutory
   employeeStatutoryForm = this.formBuilder.group({
    ouFilterForm          : this.OuFilterForm,
    date_of_joining       : '',
    doj_specific_month    : null,
    doj_specific_year     : null,
    date_of_leaving       : '',
    dol_specific_month    : null,
    dol_specific_year     : null,
    esi_applicable        :'',
    pf_applicable                    :'',
    pt_applicable                    : '',
    lwf_applicable                   :'',
    tax_regime            : ''
  })
  // CTC Revision
  ctcRevisionForm = this.formBuilder.group({
    ouFilterForm          : this.OuFilterForm,
    date_of_joining       : '',
    doj_specific_month    : null,
    doj_specific_year     : null,
    date_of_leaving       : '',
    dol_specific_month    : null,
    dol_specific_year     : null,
    pay_group             :'',
    effective_month       : '',
    effective_specific_month : null,
    effective_specific_year : null,
    payout_month          : '',
    payout_specific_month : null,
    payout_specific_year : null
  })
  // Reimbursement Request
  reimbursementRequestForm = this.formBuilder.group({
    ouFilterForm          : this.OuFilterForm,
    reimbursement_component : '',
    request_status        : '',
    requested_on          : ['',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    request_type          : '',
    financial_year        : ['',[Validators.required]]
  })
  // Final settlemnt
  finalSettlementForm = this.formBuilder.group({
    settlement_date       : ['',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm,
    settlement_done_by    : ''
  })
  investmentnsForm = this.formBuilder.group({
    investment_type       :this.formBuilder.array( [true,true],
    c => {
      const atLestOneChecked = (c as FormArray).controls.find(
        x => x.value === true
      );
      if (atLestOneChecked) {
        return {};
      }
      return { required: true };
    }),
    financial_year        : [null,[Validators.required]],
    ouFilterForm          : this.OuFilterForm
  });

  variablePaidForm = this.formBuilder.group({
    salary_month          : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    variable_component    : '',
    ouFilterForm          : this.OuFilterForm
  });

  overtimePaymentForm = this.formBuilder.group({
    ot_payment_month      : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm
  });

  gratitudePaidForm = this.formBuilder.group({
    financial_year        : ['',[Validators.required]],
    ouFilterForm          : this.OuFilterForm
  });

  npsForm = this.formBuilder.group({
    salary_month          : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm
  });

  tdsComputationForm = this.formBuilder.group({
    financial_year        : ['',[Validators.required]],
    ouFilterForm          : this.OuFilterForm
  });

 disabled=false;
 companyDD :any =  [{
    id            : 0,
    company_name  : ''
  }];
  buDD = [{
    id          : 0,
    name        : ''
  }];
  DepDD = [{
    id          : 0,
    name        : ''
  }];
  DesignationDD = [{
    id          : 0,
    name        : ''
  }];
  countryDD = [{
    id          : 0,
    name        : ''
  }];
  stateDD = [{
    id          : 0,
    name        : ''
  }];
  BranchDD = [{
    id          : 0,
    name        : ''
  }];
  cityDD = [{
    id          : 0,
    name        : ''
  }];
  gradeDD = [{
    id           : 0,
    name         : ''
  }];
  employeeDropdown:any  = [];
  payGroupDD:any        = [];
  reimburse_component:any;
  reimbursement_dropdown:any = []
  filterSaveForm = this.formBuilder.group({
    filter: true,
  });
  scheduleReportFilterMemorizeForm = this.formBuilder.group({
    filter    : [true],
  });
  testform = this.formBuilder.group({
    checkbox  : [''],
  })
  submitted         = false;
  filterSubmit      = false;
  checkFilterSubmit = false;
  toppings = new FormControl();
  checkboxData:any = [
    { id: '1', value: 'One' },
    { id: '2', value: 'Two' },
  ]
  columns1: string[] = [];
  columns2: string[] = [];
  employeetype: any[] = [
    {name: 'Permanent'},
    {name: 'Consultant'},
    {name: 'Wages'},
    {name: 'Trainee'},
    {name: 'Contractual'},
    {name: 'Apprentice'},
  ];
  employeestatus: any[] = [
    {name: 'Probation'},
    {name: 'Confirmed'},
    {name: 'Suspended'},
    {name: 'Absconding'},
    {name: 'Resigned'},
    {name: 'Terminated'},
    {name: 'Relieved'},
    {name: 'Retired'},
  ];
  emptypeDD = [
    {id:"Contractual",    name:"Contractual"},
    {id:"Consultant",    name:"Consultant"},
    {id:"Permanent",    name:"Permanent"},
    {id:"Trainee",    name:"Trainee"},
    {id:"Wages",    name:"Wages"},
  ];
  empstatusDD = [
    {id:0,    name:"Probation"},
    {id:1,    name:"Confirmed"},
    {id:2,    name:"Relieved"},
    {id:3,    name:"Resigned"},
    {id:4,    name:"Settled"},
    {id:5,    name:"Terminated"},
    {id:6,    name:"Retired"},
  ];
  reportId = 0;
  element: any;
  hasChange: boolean = false;
  hasChangeCount:any = 0;
  defaultHeaderList:any = []
  selectedYear : any
  Years : any = []
  hint_dict: any;
  columnChangedArr : any = []
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    this.columnChangedArr = this.displayedColumns
    this.filterSubmit = true
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(public router: Router,
    public route:ActivatedRoute,
    private reportService:ReportsService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    private roleService:RoleService,
    public messageService : MessageService,
    public pay_component : PayComponentService,
    private leave_service : LeaveReportService,
    public cd : ChangeDetectorRef,
    public attendanceReportService:AttendanceReportsService,
    private notify : NotificationService
  ) { }
  ngOnInit(): void {
    this.childFilterSubmit = true
    this.yearListFunction()
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
          this.reportId = params['id'];
      }
      this.initialLoaded = true
      if(params['name'] == 'PF(ProvidentFund)')
        this.pfReport();
      else if(params['name'] == 'InvestmentRequest')
        this.investmentReport();
      else if(params['name'] == 'ESI(EmployeeStateInsurance)')
        this.esiReport();
      else if(params['name'] == 'PT(ProfessionalTax)')
        this.ptReport();
      else if(params['name'] == 'SalaryRegister')
       this.salaryregisterReport();
      else if(params['name'] == 'YTDSalaryDetails')
       this.salaryregisterYtdReport();
      else if(params['name'] == 'PaySalary(BankTransfer)Report'){
       this.reportName = 'Pay Salary (Bank Transfer) Report'
       this.releaseSalaryReport();
      }
      else if(params['name'] == 'SalaryOnHold')
       this.salaryHoldReport();
      else if(params['name'] == 'CTCAsPerSalaryStructure'){
        this.reportName = 'CTC As Per Salary Structure';
        this.ctcSalaryStructureReport();
      }
      else if(params['name'] == 'LWF(LabourWelfareFund)'){
       this.reportName = 'LWF (Labour Welfare Fund)';
       this.lwfReport();
      }
      else if(params['name'] == 'MonthlyTDSDeductionReport'){
        this.reportName = 'Monthly TDS Deduction Report'
        this.monthlyTDSReport()
      }
      else if(params['name'] == 'EmployeeStatutoryReport'){
        this.reportName = 'Employee Statutory Report'
        this.employeeStatutoryReport()
      }
	    else if(params['name'] == 'CTCRevision'){
	        this.reportName = 'CTC Revision'
	        this.ctcRevisionReport()
	    }else if(params['name'] == 'ReimbursementRequest'){
          this.reportName = 'Reimbursement Request'
          this.reimbursementRequest()
      }else if(params['name'] == 'FinalSettlement'){
        this.reportName = 'Final Settlement'
        this.FinalSettlementReport()
      }
      else if(params['name'] == 'InvestmentNotSubmitted'){
        this.reportName = 'Investment Not Submitted'
        this.InvestmentNotSubmittedReport()
      }
      else if(params['name'] == 'VariablePaid'){
        this.reportName = 'Variable Paid'
        this.variablePaidReport()
      }
      else if(params['name'] == 'OvertimePayment'){
        this.reportName = 'Overtime Payment'
        this.overtimePaymentReport()
      }
      else if(params['name'] == 'GratuityPaid'){
        this.reportName = 'Gratuity Paid'
        this.gratitudePaidReport()
      }
      else if(params['name'] == 'NPSEmployerContribution'){
        this.reportName = 'NPS Employer Contribution'
        this.npsEmployerReport();
      }
      else if(params['name'] == 'TDSComputation'){
        this.reportName = 'TDS Computation'
        this.tdsComputationReport();
      }
    })
    this.selectAllForDropdownItems(this.reimbursement_dropdown)
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);

  }
  /******** PF REPORT  ****/
  pfReport(){
    this.loader             = true;
    this.reportName         = "PF (Provident Fund)";
    this.appliedFilter      = [];
     this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.pfReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date Of Birth'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Birth']);
            this.dataSource[i]['Date of leaving']   = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of leaving']);
            this.dataSource[i]['Salary month'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary month']);
            this.dataSource[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Joining']);
          }
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        // this.fullData   = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != '' && this.appliedFilter.salary_month != null){
          this.employeeForm?.get('salary_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.salary_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.employeeForm.get('specific_year')?.setValue(split_year_month[0]);
          this.employeeForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.employeeForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
      this.nodata = res.data?.length==0?true:false;
      this.columns2       = res['check_list'];
      this.columnOptions  = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      /**Reset form**/
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.employeeForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != '' && this.resDataFilter.salary_month != null){
            this.employeeForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.employeeForm.get('specific_year')?.setValue(split_year_month[0]);
            this.employeeForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.employeeForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
 /************ INVESTMENT REPORT  *****************/
  investmentReport(){
    this.loader             = true;
    this.reportName         = "Investment Request";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.investmentReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res?.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        // Date conversion
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Request On'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request On']);
            this.dataSource[i]['Approved On']  = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved On']);
            this.dataSource[i]['Date of joining']  = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
          }
        }
        this.dataSource     = new MatTableDataSource(this.dataSource);
        // this.fullData       = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.requested_on != 'last_month' && this.appliedFilter.requested_on != 'this_month' && this.appliedFilter.requested_on != ''){
          this.investmentForm.get('requested_on')?.setValue(true);
          // this.investmentForm.get('req_specific_month')?.setValue(new Date(this.appliedFilter.requested_on));
        }
        else{
          this.investmentForm.get('requested_on')?.reset(this.appliedFilter.requested_on);
        }
        if(this.appliedFilter.requested_on == undefined){
          this.investmentForm.get('requested_on')?.reset('');
        }
      this.columns2       = res['check_list'];
      this.columnOptions  = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      this.investSelectedData();
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************ INVESTMENT SELECTED DATA DISPLAY  *****************/
  investSelectedData(){
    /**Filter data**/
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        // Form reset
        this.investmentForm.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status?.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type?.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
        if(this.resDataFilter?.requested_on != 'last_month' && this.resDataFilter?.requested_on != 'this_month' && this.resDataFilter?.requested_on != ''){
          this.investmentForm.get('requested_on')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.requested_on
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.investmentForm.get('req_specific_year')?.setValue(split_year_month[0]);
          this.investmentForm.get('req_specific_month')?.setValue(atten_month);
          // this.investmentForm.get('req_specific_month')?.reset(new Date(this.resDataFilter?.requested_on));
          // this.investmentForm.get('investment_type')?.reset(this.resDataFilter?.investment_type);
        }
        else{
          this.investmentForm.get('requested_on')?.setValue(this.appliedFilter.requested_on);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(this.defaultHeaderList)
    }
  }
  /************ PT REPORT  *****************/
  ptReport(){
    this.loader             = true;
    this.reportName         = "PT (Professional Tax)";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.ptReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage  = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Salary Month'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Month']);
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != '' && this.appliedFilter.salary_month != null){
          this.ptForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.appliedFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.ptForm.get('specific_year')?.setValue(split_year_month[0]);
            this.ptForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.ptForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
        if(this.appliedFilter.salary_month == undefined){
          this.ptForm.get('salary_month')?.reset('this_month');
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      /**Reset form**/
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.ptForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.ptForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != '' && this.resDataFilter.salary_month != null){
            this.ptForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.ptForm.get('specific_year')?.setValue(split_year_month[0]);
            this.ptForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.ptForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************ LWF REPORT  *****************/
  lwfReport(){
    this.loader             = true;
    this.initialLoaded      = true
    this.reportName         = "LWF (Labour Welfare Fund)";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.lwfReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Salary Month'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Month']);
            this.dataSource[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Joining']);
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != '' && this.appliedFilter.salary_month != null){
          this.lwfForm?.get('salary_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.salary_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.lwfForm.get('sm_specific_year')?.setValue(split_year_month[0]);
          this.lwfForm.get('sm_specific_month')?.setValue(atten_month);
        }
        else{
          this.lwfForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
        if(this.appliedFilter.salary_month == undefined){
          this.lwfForm.get('salary_month')?.reset('this_month');
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      /**Reset form**/
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.lwfForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != '' && this.resDataFilter.salary_month != null){
            this.lwfForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.lwfForm.get('sm_specific_year')?.setValue(split_year_month[0]);
            this.lwfForm.get('sm_specific_month')?.setValue(atten_month);
          }
          else{
            this.lwfForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************ SALARY REGISTER REPORT  *****************/
  salaryregisterReport(){
    this.loader             = true;
    this.reportName         = "Salary Register";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.salaryregisterReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage  = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Salary Date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Date']);
            this.dataSource[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Joining']);
            this.dataSource[i]['Salary Processing Date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Processing Date']);
            this.dataSource[i]['Salary Release Date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Release Date']);
            this.dataSource[i]['Date of Releaving'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Releaving']);
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != ''){
          this.salaryRegisterForm.get('salary_month')?.setValue(true);
          // this.salaryRegisterForm.get('sm_specific_month')?.setValue(new Date(this.appliedFilter.salary_month));
        }
        else{
          this.salaryRegisterForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
        if(this.appliedFilter.date_of_joining != 'last_month' && this.appliedFilter.date_of_joining != 'this_month' && this.appliedFilter.date_of_joining != ''){
          this.salaryRegisterForm.get('date_of_joining')?.setValue(true);
          // this.salaryRegisterForm.controls['doj_specific_month']?.setValue(new Date(this.appliedFilter.date_of_joining));
        }
        else{
          this.salaryRegisterForm.get('date_of_joining')?.reset(this.appliedFilter.date_of_joining);
        }
        if(this.appliedFilter.date_of_joining == undefined){
          this.salaryRegisterForm.get('date_of_joining')?.reset('');
        }
        if(this.appliedFilter.date_of_leaving != 'last_month' && this.appliedFilter.date_of_leaving != 'this_month' && this.appliedFilter.date_of_leaving != ''){
          this.salaryRegisterForm.get('date_of_leaving')?.setValue(true);
          // this.salaryRegisterForm.controls['dol_specific_month']?.setValue(new Date(this.appliedFilter.date_of_leaving));
        }
        else{
          this.salaryRegisterForm.get('date_of_leaving')?.reset(this.appliedFilter.date_of_leaving);
        }
        if(this.appliedFilter.date_of_leaving == undefined){
          this.salaryRegisterForm.get('date_of_leaving')?.reset('');
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.salaryRegisterForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != ''){
            this.salaryRegisterForm.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.salaryRegisterForm.get('sm_specific_year')?.setValue(split_year_month[0]);
            this.salaryRegisterForm.get('sm_specific_month')?.setValue(atten_month);
          }
          else{
            this.salaryRegisterForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
          if(this.resDataFilter.date_of_joining != 'last_month' && this.resDataFilter.date_of_joining != 'this_month' && this.resDataFilter.date_of_joining != ''){
            this.salaryRegisterForm.get('date_of_joining')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.date_of_joining
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.salaryRegisterForm.get('doj_specific_year')?.setValue(split_year_month[0]);
            this.salaryRegisterForm.get('doj_specific_month')?.setValue(atten_month);
          }
          else{
            this.salaryRegisterForm.get('date_of_joining')?.reset(this.resDataFilter.date_of_joining);
          }
          if(this.resDataFilter.date_of_leaving != 'last_month' && this.resDataFilter.date_of_leaving != 'this_month' && this.resDataFilter.date_of_leaving != '' && this.resDataFilter.date_of_leaving != null){
            this.salaryRegisterForm.get('date_of_leaving')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.date_of_leaving
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.salaryRegisterForm.get('dol_specific_year')?.setValue(split_year_month[0]);
            this.salaryRegisterForm.get('dol_specific_month')?.setValue(atten_month);
          }
          else{
            this.salaryRegisterForm.get('date_of_leaving')?.reset(this.resDataFilter.date_of_leaving);
          }
        }
       }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
         this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************ CTC REPORT  *****************/
  ctcSalaryStructureReport(){
    this.loader             = true;
    this.reportName         = "CTC As Per Salary Structure";
    this.appliedFilter      = [];
    this.initialLoaded = true
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.ctcSalaryStructureReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res?.data?.length != 0){
        this.lastPage = res?.count
        this.dataSource       = new MatTableDataSource(res?.data);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.date_of_joining != 'last_month' && this.appliedFilter.date_of_joining != 'this_month' && this.appliedFilter.date_of_joining != ''  && this.appliedFilter.date_of_joining != null){
          this.ctcSalaryStructureForm.get('date_of_joining')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.date_of_joining
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.ctcSalaryStructureForm.get('doj_specific_year')?.setValue(split_year_month[0]);
          this.ctcSalaryStructureForm.get('doj_specific_month')?.setValue(atten_month);
        }
        else{
          this.ctcSalaryStructureForm.get('date_of_joining')?.reset(this.appliedFilter.date_of_joining);
        }
        if(this.appliedFilter.date_of_joining == undefined){
          this.ctcSalaryStructureForm.get('date_of_joining')?.reset('');
        }
        if(this.appliedFilter.date_of_leaving != 'last_month' && this.appliedFilter.date_of_leaving != 'this_month' && this.appliedFilter.date_of_leaving != '' && this.appliedFilter.date_of_leaving != null){
          this.ctcSalaryStructureForm.get('date_of_leaving')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.date_of_leaving
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.ctcSalaryStructureForm.get('dojl_specific_year')?.setValue(split_year_month[0]);
          this.ctcSalaryStructureForm.get('dol_specific_month')?.setValue(atten_month);
        }
        else{
          this.ctcSalaryStructureForm.get('date_of_leaving')?.reset(this.appliedFilter.date_of_leaving);
        }
        if(this.appliedFilter.date_of_leaving ==undefined){
          this.ctcSalaryStructureForm.get('date_of_leaving')?.reset('');
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      // this.ctcSalaryStructureForm.get('ctc_breakup')?.setValue(this.appliedFilter.ctc_breakup);
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.ctcSalaryStructureForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.date_of_joining != 'last_month' && this.resDataFilter.date_of_joining != 'this_month' && this.resDataFilter.date_of_joining != '' && this.resDataFilter.date_of_joining != null){
            this.ctcSalaryStructureForm.get('date_of_joining')?.setValue(true);
            const attendance_year_month = this.appliedFilter?.date_of_joining
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.ctcSalaryStructureForm.get('doj_specific_year')?.setValue(split_year_month[0]);
            this.ctcSalaryStructureForm.get('doj_specific_month')?.setValue(atten_month);
          }
          else{
            this.ctcSalaryStructureForm.get('date_of_joining')?.reset(this.resDataFilter.date_of_joining);
          }
          if(this.resDataFilter.date_of_leaving != 'last_month' && this.resDataFilter.date_of_leaving != 'this_month' && this.resDataFilter.date_of_leaving != '' && this.resDataFilter.date_of_leaving != null){
            this.ctcSalaryStructureForm.get('date_of_leaving')?.setValue(true);
            const attendance_year_month = this.appliedFilter?.date_of_leaving
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.ctcSalaryStructureForm.get('dol_specific_year')?.setValue(split_year_month[0]);
            this.ctcSalaryStructureForm.get('dol_specific_month')?.setValue(atten_month);
          }
          else{
            this.ctcSalaryStructureForm.get('date_of_leaving')?.reset(this.resDataFilter.date_of_leaving);
          }
          if(this.resDataFilter.pay_group != '' && this.resDataFilter.pay_group != null){
            this.ctcSalaryStructureForm.get('pay_group')?.reset(this.resDataFilter.pay_group);
            var elements = document.getElementsByName("pay_group");
            setTimeout(() => {
              for(let i=0;i<this.payGroupDD?.length;i++){
                var element = <HTMLInputElement>elements[i];
                  if(element.id == this.resDataFilter?.pay_group){
                    element.checked =true;
                  }
              }
            }, 1000);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
         this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************ RELEASE SALARY REPORT  *****************/
  releaseSalaryReport(){
    this.loader             = true;
    this.reportName         = "Pay Salary (Bank Transfer) Report";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.releaseSalaryReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = new MatTableDataSource(res?.data);
        this.lastPage = res?.count
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != ''){
          this.releseSalaryForm.get('salary_month')?.setValue(true);
          // this.releseSalaryForm.get('sm_specific_month')?.setValue(new Date(this.appliedFilter.salary_month));
          const attendance_year_month = this.appliedFilter?.salary_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.releseSalaryForm.get('sm_specific_year')?.setValue(split_year_month[0]);
          this.releseSalaryForm.get('sm_specific_month')?.setValue(atten_month);
        }
        else{
          this.releseSalaryForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
       /**Reset form**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.releseSalaryForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != ''){
            this.releseSalaryForm.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month      = attendance_year_month.split('-')
            const atten_month           = moment(split_year_month[1], 'M').format('MMMM');
            this.releseSalaryForm.get('sm_specific_year')?.setValue(split_year_month[0]);
            this.releseSalaryForm.get('sm_specific_month')?.setValue(atten_month);
          }
          else{
            this.releseSalaryForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
        this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************ ESI REPORT  *****************/
  esiReport(){
    this.loader             = true;
    this.reportName         = "ESI (Employee State Insurance)";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.esiReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Joining']);
          }
        }
        this.dataSource     = new MatTableDataSource(this.dataSource);
        // this.fullData       = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res?.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != '' && this.appliedFilter.salary_month != null){
          this.esiForm?.get('salary_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.salary_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.esiForm.get('specific_year')?.setValue(split_year_month[0]);
          this.esiForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.esiForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
      this.columns2        = res['check_list'];
      this.columnOptions   = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      /**Reset form**/
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.esiForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != ''  && this.resDataFilter.salary_month != null){
            this.esiForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.esiForm.get('specific_year')?.setValue(split_year_month[0]);
            this.esiForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.esiForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
         this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************ YTD REPORT  *****************/
  salaryregisterYtdReport(){
    this.loader             = true;
    this.reportName         = "YTD Salary Details";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.salaryregisterYtdReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage  = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Salary Date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Date']);
            this.dataSource[i]['Salary Release Date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Release Date']);
            this.dataSource[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Joining']);
            this.dataSource[i]['Salary Processing Date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Processing Date']);
            this.dataSource[i]['Date Of Releaving'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Releaving']);
          }
        }
        this.dataSource       = new MatTableDataSource(res.data);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      /**Reset form**/
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.ytdSalaryForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
         this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************  SALARY HOLD REPORT  *****************/
  salaryHoldReport(){
    this.loader               = true;
    this.reportName           = "Salary On Hold";
    this.appliedFilter        = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.salaryHoldReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource         = res?.data;
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Salary hold date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary hold date']);
          }
        }
        this.dataSource         = new MatTableDataSource(this.dataSource);
        this.fullData           = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList  = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
       if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != '' && this.appliedFilter.salary_month != null){
          this.salaryOnoldForm?.get('salary_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.salary_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.salaryOnoldForm.get('sm_specific_year')?.setValue(split_year_month[0]);
          this.salaryOnoldForm.get('sm_specific_month')?.setValue(atten_month);
        }
        else{
          this.salaryOnoldForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
        if(this.appliedFilter.salary_month == undefined){
          this.salaryOnoldForm.get('salary_month')?.reset('this_month');
        }
      this.columns2           = res['check_list'];
      this.columnOptions      = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.salaryOnoldForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company != ''){
            this.getBUList();
            if(this.resDataFilter?.bu != ''){
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
            }
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != '' && this.resDataFilter.salary_month != null){
            this.salaryOnoldForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.salaryOnoldForm.get('sm_specific_year')?.setValue(split_year_month[0]);
            this.salaryOnoldForm.get('sm_specific_month')?.setValue(atten_month);
          }
          else{
            this.salaryOnoldForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
      }
       /**Header filter data**/
       if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
         this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  // Monthly TDS Deduction Report Report
  monthlyTDSReport(){
    this.loader             = true;
    this.reportName         = "Monthly TDS Deduction Report";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.monthlyTDSReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Joining']);
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      this.nodata = res.data?.length==0?true:false;
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != '' && this.appliedFilter.salary_month != null){
          this.monthlyTDSForm?.get('salary_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.salary_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.monthlyTDSForm.get('specific_year')?.setValue(split_year_month[0]);
          this.monthlyTDSForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.monthlyTDSForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.monthlyTDSForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != '' && this.resDataFilter.salary_month != null){
            this.monthlyTDSForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.monthlyTDSForm.get('specific_year')?.setValue(split_year_month[0]);
            this.monthlyTDSForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.monthlyTDSForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
       }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
        this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    })
  }

  // // Submit after filter applied, getting value from child
  submitDone(value:any){
    if(this.callMonthlyTDS && Object.keys(this.resDataFilter)?.length > 0){
      this.filterMemorizeFunction('saveFilter','')
    }
    this.callMonthlyTDS = false
    this.limit = value.limit
    this.offset = value.offset
    this.filterFormData = value.filterForm
    this.filterSubmit = !this.filterFormData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '')
    })
    this.childFilterSubmit = false
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true
    this.callAllReportsFunctions()
    this.pageChangeDetect = false
    this.viewDetail = false
  }
  // Employee Statutory Report
  employeeStatutoryReport(){
    this.loader             = true;
    this.reportName         = "Employee Statutory Report";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.employeeStatutoryReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource = res?.data
         this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['DOJ'] = this.appservice.dateFormatDisplay(this.dataSource[i]['DOJ']);
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.date_of_joining != 'last_month' && this.appliedFilter.date_of_joining != 'this_month' && this.appliedFilter.date_of_joining != '' && this.appliedFilter.date_of_joining != undefined){
          this.employeeStatutoryForm.get('date_of_joining')?.setValue(true);
          const date_of_joining = this.appliedFilter?.date_of_joining
          const split_year_month = date_of_joining.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.employeeStatutoryForm.get('doj_specific_year')?.setValue(split_year_month[0]);
          this.employeeStatutoryForm.get('doj_specific_month')?.setValue(atten_month);
        }
        else{
          this.employeeStatutoryForm.get('date_of_joining')?.reset(this.appliedFilter.date_of_joining);
        }
        // if(this.appliedFilter.date_of_joining == undefined){
        //   this.employeeStatutoryForm.get('date_of_joining')?.reset('this_month');
        // }
        if(this.appliedFilter.date_of_leaving != 'last_month' && this.appliedFilter.date_of_leaving != 'this_month' && this.appliedFilter.date_of_leaving != '' && this.appliedFilter.date_of_leaving != undefined){
          this.employeeStatutoryForm.get('date_of_leaving')?.setValue(true);
          const date_of_leaving = this.appliedFilter?.date_of_leaving
          const split_year_month = date_of_leaving.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.employeeStatutoryForm.get('dol_specific_year')?.setValue(split_year_month[0]);
          this.employeeStatutoryForm.get('dol_specific_month')?.setValue(atten_month);
        }
        else{
          this.employeeStatutoryForm.get('date_of_leaving')?.reset(this.appliedFilter.date_of_leaving);
        }
        // if(this.appliedFilter.date_of_leaving == undefined){
        //   this.employeeStatutoryForm.get('date_of_leaving')?.reset('this_month');
        // }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2
      this.nodata           = res?.data?.length == 0 ? true : false;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.employeeStatutoryForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.date_of_joining != 'last_month' && this.resDataFilter.date_of_joining != 'this_month' && this.resDataFilter.date_of_joining != null && this.resDataFilter.date_of_joining != ''){
            this.employeeStatutoryForm.get('date_of_joining')?.setValue(true);
            const date_of_joining = this.appliedFilter?.date_of_joining
            const split_year_month = date_of_joining.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.employeeStatutoryForm.get('doj_specific_year')?.setValue(split_year_month[0]);
            this.employeeStatutoryForm.get('doj_specific_month')?.setValue(atten_month);
          }
          else{
            this.employeeStatutoryForm.get('date_of_joining')?.reset(this.resDataFilter.date_of_joining);
          }
          if(this.resDataFilter.date_of_leaving != 'last_month' && this.resDataFilter.date_of_leaving != 'this_month' && this.resDataFilter.date_of_leaving != '' && this.resDataFilter.date_of_leaving != null){
            this.employeeStatutoryForm.get('date_of_leaving')?.setValue(true);
            const date_of_leaving = this.appliedFilter?.date_of_leaving
            const split_year_month = date_of_leaving.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.employeeStatutoryForm.get('dol_specific_year')?.setValue(split_year_month[0]);
            this.employeeStatutoryForm.get('dol_specific_month')?.setValue(atten_month);
          }
          else{
            this.employeeStatutoryForm.get('date_of_leaving')?.reset(this.resDataFilter.date_of_leaving);
          }
        }
       }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader  = false;
      this.pageChangeDetect = false
    })
  }
 // CTC Revision
  ctcRevisionReport(){
    this.loader             = true;
    this.reportName         = "CTC Revision";
    this.appliedFilter      = [];
       this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.ctcRevisionReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource = res?.data;
        this.lastPage = res?.count
         // Formating as per standard for dates
         if(this.dataSource?.length > 0){
          for(let i=0; i<res.data?.length;i++){
            this.dataSource[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Joining']);
            this.dataSource[i]['Date Of Leaving'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Leaving']);
            this.dataSource[i]['Salary Revised from'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Salary Revised from']);
            this.dataSource[i]['Arrear with effect from'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Arrear with effect from']);
          }
         }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.date_of_joining != 'last_month' && this.appliedFilter.date_of_joining != 'this_month' && this.appliedFilter.date_of_joining != '' && this.appliedFilter.date_of_joining != null){
          // this.ctcRevisionForm.get('doj_specific_month')?.reset(new Date(this.appliedFilter.date_of_joining));
          this.ctcRevisionForm.get('date_of_joining')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.date_of_joining
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.ctcRevisionForm.get('doj_specific_year')?.setValue(split_year_month[0]);
          this.ctcRevisionForm.get('doj_specific_month')?.setValue(atten_month);
        }else{
          this.ctcRevisionForm.get('date_of_joining')?.reset(this.appliedFilter.date_of_joining);
        }
        if(this.appliedFilter.date_of_joining == undefined){
          this.ctcRevisionForm.get('date_of_joining')?.reset('');
        }
        if(this.appliedFilter.date_of_leaving != 'last_month' && this.appliedFilter.date_of_leaving != 'this_month' && this.appliedFilter.date_of_leaving != '' && this.appliedFilter.date_of_leaving != null){
          // this.ctcRevisionForm.get('dol_specific_month')?.reset(new Date(this.appliedFilter.date_of_leaving));
          this.ctcRevisionForm.get('date_of_leaving')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.date_of_leaving
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.ctcRevisionForm.get('dol_specific_year')?.setValue(split_year_month[0]);
          this.ctcRevisionForm.get('dol_specific_month')?.setValue(atten_month);
        }else{
          this.ctcRevisionForm.get('date_of_leaving')?.reset(this.appliedFilter.date_of_leaving);
        }
        if(this.appliedFilter.date_of_leaving == undefined){
          this.ctcRevisionForm.get('date_of_leaving')?.reset('');
        }
        if(this.appliedFilter.effective_month != 'last_month' && this.appliedFilter.effective_month != 'this_month' && this.appliedFilter.effective_month != '' && this.appliedFilter.effective_month != null){
          // this.ctcRevisionForm.get('effective_specific_month')?.reset(new Date(this.appliedFilter.effective_month));
          this.ctcRevisionForm.get('effective_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.effective_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.ctcRevisionForm.get('effective_specific_year')?.setValue(split_year_month[0]);
          this.ctcRevisionForm.get('effective_specific_month')?.setValue(atten_month);
        }else{
          this.ctcRevisionForm.get('effective_month')?.reset(this.appliedFilter.effective_month);
        }
        if(this.appliedFilter.effective_month == undefined){
          this.ctcRevisionForm.get('effective_month')?.reset('');
        }
        if(this.appliedFilter.payout_month != 'last_month' && this.appliedFilter.payout_month != 'this_month' && this.appliedFilter.payout_month != '' && this.appliedFilter.payout_month != null){
          // this.ctcRevisionForm.get('payout_specific_month')?.reset(new Date(this.appliedFilter.payout_month));
          this.ctcRevisionForm.get('payout_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.payout_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.ctcRevisionForm.get('payout_specific_year')?.setValue(split_year_month[0]);
          this.ctcRevisionForm.get('payout_specific_month')?.setValue(atten_month);
        }else{
          this.ctcRevisionForm.get('payout_month')?.reset(this.appliedFilter.payout_month);
        }
        if(this.appliedFilter.payout_month == undefined){
          this.ctcRevisionForm.get('payout_month')?.reset('');
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.ctcRevisionForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          // Setting for the specific month to be active if no other option is selected
          if(this.resDataFilter.date_of_joining != 'last_month' && this.resDataFilter.date_of_joining != 'this_month' && this.resDataFilter.date_of_joining != '' && this.resDataFilter.date_of_joining != null){
            // this.ctcRevisionForm.get('doj_specific_month')?.reset(new Date(this.resDataFilter.date_of_joining));
            this.ctcRevisionForm.get('date_of_joining')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.date_of_joining
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.ctcRevisionForm.get('doj_specific_year')?.setValue(split_year_month[0]);
            this.ctcRevisionForm.get('doj_specific_month')?.setValue(atten_month);
          }else{
            this.ctcRevisionForm.get('date_of_joining')?.reset(this.resDataFilter.date_of_joining);
          }
          if(this.resDataFilter.date_of_leaving != 'last_month' && this.resDataFilter.date_of_leaving != 'this_month' && this.resDataFilter.date_of_leaving != '' && this.resDataFilter.date_of_leaving != null){
            // this.ctcRevisionForm.get('dol_specific_month')?.reset(new Date(this.resDataFilter.date_of_leaving));
            this.ctcRevisionForm.get('date_of_leaving')?.setValue(true);
            const attendance_year_month = this.appliedFilter?.date_of_leaving
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.ctcRevisionForm.get('dol_specific_year')?.setValue(split_year_month[0]);
            this.ctcRevisionForm.get('dol_specific_month')?.setValue(atten_month);
          }else{
            this.ctcRevisionForm.get('date_of_leaving')?.reset(this.resDataFilter.date_of_leaving);
          }
          if(this.resDataFilter.effective_month != 'last_month' && this.resDataFilter.effective_month != 'this_month' && this.resDataFilter.effective_month != '' && this.resDataFilter.effective_month != null){
            const effectiveMont = this.resDataFilter?.effective_month
            this.ctcRevisionForm.get('effective_month')?.setValue(true);
            const split_year_month = effectiveMont.split('-')
            const eff_month = moment(split_year_month[1], 'M').format('MMMM');
            this.ctcRevisionForm.get('effective_specific_year')?.setValue(split_year_month[0]);
            this.ctcRevisionForm.get('effective_specific_month')?.setValue(eff_month);
          }else{
            this.ctcRevisionForm.get('effective_month')?.reset(this.resDataFilter.effective_month);
          }
          if(this.resDataFilter.payout_month != 'last_month' && this.resDataFilter.payout_month != 'this_month' && this.resDataFilter.payout_month != '' && this.resDataFilter.payout_month != null){
            const payoutMonth = this.resDataFilter?.payout_month
            this.ctcRevisionForm.get('payout_month')?.setValue(true);
            const split_year_month = payoutMonth.split('-')
            const payMonth = moment(split_year_month[1], 'M').format('MMMM');
            this.ctcRevisionForm.get('payout_specific_year')?.setValue(split_year_month[0]);
            this.ctcRevisionForm.get('payout_specific_month')?.setValue(payMonth)
          }else{
            this.ctcRevisionForm.get('payout_month')?.reset(this.resDataFilter.payout_month);
          }
          if(this.resDataFilter.pay_group != '' && this.resDataFilter.pay_group != null){
            this.ctcRevisionForm.get('pay_group')?.reset(this.resDataFilter.pay_group);
            var elements = document.getElementsByName("pay_group");
            setTimeout(() => {
              for(let i=0;i<this.payGroupDD?.length;i++){
                var element = <HTMLInputElement>elements[i];
                  if(element.id == this.resDataFilter?.pay_group){
                    element.checked =true;
                  }
              }
            }, 1000);
          }
        }
       }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader  = false;
      this.pageChangeDetect = false
    })
  }
  // Reimbursement Request
  reimbursementRequest(){
    this.loader             = true;
    this.reportName         = "Reimbursement Request";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.reimbursementRequest(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource = res?.data
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of Joining']);
            this.dataSource[i]['Requested On'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Requested On']);
            this.dataSource[i]['Approved On'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved On']);
            }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter?.requested_on != 'last_month' && this.appliedFilter?.requested_on != 'this_month' && this.appliedFilter?.requested_on != '' && this.appliedFilter?.requested_on != null){
          this.reimbursementRequestForm?.get('requested_on')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.requested_on
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.reimbursementRequestForm.get('specific_year')?.setValue(split_year_month[0]);
          this.reimbursementRequestForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.reimbursementRequestForm.get('requested_on')?.reset(this.appliedFilter.requested_on);
        }
        if(this.appliedFilter?.requested_on == undefined){
          this.reimbursementRequestForm.get('requested_on')?.reset('this_month');
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.reimbursementRequestForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter?.requested_on != 'last_month' && this.resDataFilter?.requested_on != 'this_month' && this.resDataFilter?.requested_on != '' && this.resDataFilter?.requested_on != null){
            this.reimbursementRequestForm?.get('requested_on')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.requested_on
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.reimbursementRequestForm.get('specific_year')?.setValue(split_year_month[0]);
            this.reimbursementRequestForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.reimbursementRequestForm.get('requested_on')?.reset(this.resDataFilter.requested_on);
          }
          if(this.resDataFilter?.financial_year != 'previous' && this.resDataFilter?.financial_year != 'current' && this.resDataFilter?.financial_year != '' && this.resDataFilter?.financial_year != null){
            this.reimbursementRequestForm.get('financial_year')?.reset(this.resDataFilter.financial_year);
          }
        }
       }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader  = false;
      this.pageChangeDetect = false
    })
  }
  FinalSettlementReport(){
    this.loader             = true;
    this.reportName         = "Final Settlement";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.finalSettlementReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
          this.dataSource[i]['Date of leaving'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of leaving']);
          this.dataSource[i]['Date of resignation'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of resignation']);
          this.dataSource[i]['Date of settlement'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of settlement']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      // this.fullData   = this.dataSource?.filteredData;
    }
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter.settlement_date != 'last_month' && this.appliedFilter.settlement_date != 'this_month' && this.appliedFilter.settlement_date != ''){
        this.finalSettlementForm.get('settlement_date')?.setValue(true);
        // this.finalSettlementForm.get('specific_month')?.setValue(new Date(this.appliedFilter.settlement_date));
      }
      else{
        this.finalSettlementForm.get('settlement_date')?.reset(this.appliedFilter.settlement_date);
      }
      if(this.appliedFilter.settlement_date == undefined){
        this.finalSettlementForm.get('settlement_date')?.reset('this_month');
      }
    this.nodata = res.data?.length==0?true:false;
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.finalSettlementForm.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.finalSettlementForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
        if(this.resDataFilter.settlement_date != 'last_month' && this.resDataFilter.settlement_date != 'this_month' && this.resDataFilter.settlement_date != ''){
          // this.finalSettlementForm.get('settlement_date')?.setValue(true);
          // this.finalSettlementForm.get('specific_month')?.setValue(new Date(this.resDataFilter.settlement_date));

          this.finalSettlementForm?.get('settlement_date')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.settlement_date
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.finalSettlementForm.get('specific_year')?.setValue(split_year_month[0]);
          this.finalSettlementForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.finalSettlementForm.get('settlement_date')?.reset(this.resDataFilter.settlement_date);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.loader  = false;
    this.pageChangeDetect = false
  });
  }

  variablePaidReport(){
    this.loader             = true;
    this.reportName         = "Variable Paid";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.variablePaidReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Official Email'] = this.dataSource[i]['Work Email']
          }
        }
        this.dataSource     = new MatTableDataSource(this.dataSource);
        // this.fullData       = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      this.resDataFilter     = res?.data_filter;
      this.resHeader_filter  = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res?.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != '' && this.appliedFilter.salary_month != null){
          this.variablePaidForm?.get('salary_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.salary_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.variablePaidForm.get('specific_year')?.setValue(split_year_month[0]);
          this.variablePaidForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.variablePaidForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
      this.columns2        = res['check_list'];
      this.columnOptions   = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      /**Reset form**/
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.variablePaidForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != ''  && this.resDataFilter.salary_month != null){
            this.variablePaidForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.variablePaidForm.get('specific_year')?.setValue(split_year_month[0]);
            this.variablePaidForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.variablePaidForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
         this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }

  overtimePaymentReport(){
    this.loader             = true;
    this.reportName         = "Overtime Payment";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.overtimePaymentReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            // this.dataSource[i]['Official Email'] = this.dataSource[i]['Work Email']
          }
        }
        this.dataSource     = new MatTableDataSource(this.dataSource);
        // this.fullData       = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      this.resDataFilter     = res?.data_filter;
      this.resHeader_filter  = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res?.applied_filter;
        if(this.appliedFilter.ot_payment_month != 'last_month' && this.appliedFilter.ot_payment_month != 'this_month' && this.appliedFilter.ot_payment_month != '' && this.appliedFilter.ot_payment_month != null){
          this.overtimePaymentForm?.get('ot_payment_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.ot_payment_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.overtimePaymentForm.get('specific_year')?.setValue(split_year_month[0]);
          this.overtimePaymentForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.overtimePaymentForm.get('ot_payment_month')?.reset(this.appliedFilter.ot_payment_month);
        }
      this.columns2        = res['check_list'];
      this.columnOptions   = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      /**Reset form**/
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.overtimePaymentForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.ot_payment_month != 'last_month' && this.resDataFilter.ot_payment_month != 'this_month' && this.resDataFilter.ot_payment_month != ''  && this.resDataFilter.ot_payment_month != null){
            this.overtimePaymentForm?.get('ot_payment_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.ot_payment_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.overtimePaymentForm.get('specific_year')?.setValue(split_year_month[0]);
            this.overtimePaymentForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.overtimePaymentForm.get('ot_payment_month')?.reset(this.resDataFilter.ot_payment_month);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
         this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }

  gratitudePaidReport(){
    this.loader             = true;
    this.reportName         = "Gratuity Paid";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.gratitudePaidReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource = res?.data
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Date of Relieving'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Relieving']);
            // this.dataSource[i]['Official Email'] = this.dataSource[i]['Work Email']
            this.dataSource[i]['Salary Month'] = this.dataSource[i]['Salary month']
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.gratitudePaidForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter?.finantial_year != 'previous' && this.resDataFilter?.finantial_year != 'current' && this.resDataFilter?.finantial_year != '' && this.resDataFilter?.finantial_year != null){
            this.gratitudePaidForm.get('financial_year')?.reset(this.resDataFilter.finantial_year);
          }
        }
       }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader  = false;
      this.pageChangeDetect = false
    })
  }

  npsEmployerReport(){
    this.loader             = true;
    this.reportName         = "NPS Employer Contribution";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.npsEmployerReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            // this.dataSource[i]['Official Email'] = this.dataSource[i]['Work Email']
          }
        }
        this.dataSource     = new MatTableDataSource(this.dataSource);
        // this.fullData       = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      this.resDataFilter     = res?.data_filter;
      this.resHeader_filter  = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res?.applied_filter;
        if(this.appliedFilter.salary_month != 'last_month' && this.appliedFilter.salary_month != 'this_month' && this.appliedFilter.salary_month != '' && this.appliedFilter.salary_month != null){
          this.npsForm?.get('salary_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.salary_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.npsForm.get('specific_year')?.setValue(split_year_month[0]);
          this.npsForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.npsForm.get('salary_month')?.reset(this.appliedFilter.salary_month);
        }
      this.columns2        = res['check_list'];
      this.columnOptions   = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      /**Reset form**/
      if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.npsForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter.salary_month != 'last_month' && this.resDataFilter.salary_month != 'this_month' && this.resDataFilter.salary_month != ''  && this.resDataFilter.salary_month != null){
            this.npsForm?.get('salary_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.salary_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.npsForm.get('specific_year')?.setValue(split_year_month[0]);
            this.npsForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.npsForm.get('salary_month')?.reset(this.resDataFilter.salary_month);
          }
        }
      }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
         this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }

  tdsComputationReport(){
    this.loader             = true;
    this.reportName         = "TDS Computation";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.tdsComputationReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res.data?.length != 0){
        this.dataSource = res?.data
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Date of Relieving'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Relieving']);
            // this.dataSource[i]['Official Email'] = this.dataSource[i]['Work Email']
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;
        this.setDataSourceAttributes();
      }
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.tdsComputationForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter?.finantial_year != 'previous' && this.resDataFilter?.finantial_year != 'current' && this.resDataFilter?.finantial_year != '' && this.resDataFilter?.finantial_year != null){
            this.tdsComputationForm.get('financial_year')?.reset(this.resDataFilter.finantial_year);
          }
        }
       }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader  = false;
      this.pageChangeDetect = false
    })
  }

  empTypeCheck(data:any,type:any, length:any){
    var elements = document.getElementsByName("emp_type");
    setTimeout(() => {
      for(let i=0;i<elements?.length;i++){
        var element1 = <HTMLInputElement>elements[i];
        element1.checked = false;
      }
      if(length == 1){
      for(let i=0;i<elements?.length;i++){
        var element = <HTMLInputElement>elements[i];
        for(let j=0;j<data?.length;j++){
          if(element.defaultValue == data[j]){
            element.checked = true;
            if(type == 'default')
              this.tempArr.push(data[j]);
          }
        }
      }
    }
    }, 1000);
  }
  empStatusCheck(data:any,type:any, length:any){
    var elements = document.getElementsByName("emp_status");
    setTimeout(() => {
      for(let i=0;i<elements?.length;i++){
        var element1 = <HTMLInputElement>elements[i];
        element1.checked = false;
      }
      if(length == 1){
      for(let i=0;i<elements?.length;i++){
        var element = <HTMLInputElement>elements[i];
        for(let j=0;j<data?.length;j++){
          if(element.defaultValue == data[j]){
            element.checked = true;
            if(type == 'default')
              this.empstatArr.push(data[j]);
          }
        }
      }
    }
    }, 1000);
  }
  dynamicHeader(data_length:any,header_list:any,check_list:any){
    // this.nodata           = data_length==0?true:false;
    this.columns1         = header_list?.slice();
    // this.displayedColumns = this.columnsArr?.length > 0 ? this.dupliCateRemove(this.columnsArr) : this.dupliCateRemove(this.columns1)
    this.displayedColumns = this.dupliCateRemove(this.columns1)
    this.columnOptions1   = this.columns1;
  }
  dupliCateRemove(data:any){
    const uniqueColumnsSet = new Set();
    data?.filter((column:any) => {
      if (!uniqueColumnsSet.has(column)) {
        uniqueColumnsSet.add(column);
        return true;
      }
      return false;
    });
    return Array.from(uniqueColumnsSet)
  }
// copied for avoiding the errors on other section currently working on Monthly TDS Deduction Report
  dynamicHeaders(value:any){
    this.responseLength = value.length
    this.headerCheck = value.header
    this.checkList = value.checklist
    this.nodata           = this.responseLength==0?true:false;
    this.columns1         = this.headerCheck;
    this.displayedColumns = this.columns1;
    this.columnOptions1   = this.columns1;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort      = this.sort;
    this.getCompanyList();
    this.getBUList();
    this.getDepList();
    this.getDesignationList();
    this.getGradeList();
    this.getBranchList();
    this.getEmployeeList();
    this.getReimbursementDropdown();
    this.getVariableDropdown();
    this.getPayGroupList();
    this.yearListCreate();
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);
    this.selectAllForDropdownItems(this.reimbursement_dropdown)
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  ordering : any = 'Employee Code'
  direction : string = 'asc'
  sortProperty : string = 'Employee Code'
  onSort(val:any) {
    if (this.sortProperty === val) {
      this.direction = this.direction === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortProperty = val;
      this.direction = 'desc';
    }
    const active = val.split(' ').join('_').toLowerCase();
    const newOrder = this.direction === 'desc' ? '-' : '';
    this.ordering = newOrder + (val.includes(' ') ? active : val.toLowerCase());
    this.cd.detectChanges()
    this.filterFormData = this.filterFormData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.pageChangeDetect = true
    this.columnApplied == true ? this.columnApplied = true : this.columnApplied = false
    this.callAllReportsFunctions()
    // this.columnApplied = false
  }

  compare(a: any, b: any, isAsc: boolean) {
    if (a < b) {
      return isAsc ? -1 : 1;
    } else if (a > b) {
      return isAsc ? 1 : -1;
    } else {
      return 0;
    }
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    if(this.dataSource != undefined && this.dataSource != null){
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort      = this.sort;
    }
  }
  checkChange : boolean  = false
  headerFilterAdd(e:any,index:any){
    this.checkChange = true
    this.columnApplied = true
    this.header_filter=this.dupliCateRemove(this.header_filter)
    if(e.target.checked){
      this.submitted = true;
      this.checkColumn.push(e.target.value);
      this.header_filter.push(e.target.value);
    }
    else{
      if(this.header_filter.length==1){
        var elements = document.getElementsByName("check");
        setTimeout(() => {
            var element = <HTMLInputElement>elements[index];
              element.checked = true;
        })
      }
      else{
        this.checkColumn = this.dupliCateRemove(this.checkColumn)
        const index: number        = this.checkColumn.indexOf(e.target.value);
        const header_index: number = this.header_filter.indexOf(e.target.value);
        const column_1: number     = this.columns1.indexOf(e.target.value);
        this.checkColumn.splice(index, 1);
        this.header_filter.splice(header_index,1);
        this.columns1.splice(column_1,1);
      }
    }
  }
  previousChecked : any
columnApplied : boolean = false
  headerFilterApply(){
    if(!this.checkChange){
      return
    }
    let array3 = this.columns1?.concat(this.checkColumn);
    array3     = array3?.filter((item:any,index:any)=>{
        return (array3.indexOf(item) == index)
    })
    if(this.columnChangedArr?.length !=0){
      array3 = this.reorderArray(this.columnChangedArr,array3)
    }
    this.displayedColumns   = array3;
    this.previousChecked = this.checkColumn
    this.columnsArr  = this.displayedColumns;
    if(this.checkColumn?.length > 0 && this.columnApplied)
      this.checkFilterSubmit  = true;
    else
      this.checkFilterSubmit  = false;
    // this.checkFilterSubmit  = true;
    this.checkChange = false
  }
  yearListCreate(){
    this.yearList=[];
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push(String(i))
    }
  }
  financialYearRange(num:any){
    let a = num;
    a = Number(a) + 1;
    return a;
  }
  getCompanyList(){
    this.roleService.getCompanyList().subscribe((res: any) => {
      if(res.status == 200 && res.body?.length > 0) {
          this.companyDD = res.body;
        this.selectAllForDropdownItems(this.companyDD);
      }
    });
  }
  getBUListFunction(){
    if(this.reportName == 'ESI (Employee State Insurance)'){
      this.esiForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Salary On Hold'){
      this.salaryOnoldForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Investment Request'){
      this.investmentForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Salary Register'){
      this.salaryRegisterForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'PT (Professional Tax)'){
      this.ptForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'LWF (Labour Welfare Fund)'){
      this.lwfForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'PF (Provident Fund)'){
      this.employeeForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'YTD Salary Details'){
      this.ytdSalaryForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Pay Salary (Bank Transfer) Report'){
      this.releseSalaryForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'CTC As Per Salary Structure'){
      this.ctcSalaryStructureForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Monthly TDS Deduction Report'){
      this.monthlyTDSForm.get('bu')?.setValue('');
    }
    this.buDD = [];
    this.getBUList();
  }
  getBUList(){
    let comp;
    if(this.reportName == 'ESI (Employee State Insurance)'){
      comp = this.esiForm.value.company;
      this.esiForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Salary On Hold'){
      comp = this.salaryOnoldForm.value.company;
      this.salaryOnoldForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Investment Request'){
      comp = this.investmentForm.value.company;
      this.investmentForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Salary Register'){
      comp = this.salaryRegisterForm.value.company;
      this.salaryRegisterForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'PT (Professional Tax)'){
      comp = this.ptForm.value.company;
      this.ptForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'LWF (Labour Welfare Fund)'){
      comp = this.lwfForm.value.company;
      this.lwfForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'PF (Provident Fund)'){
      comp = this.employeeForm.value.company;
      this.employeeForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'YTD Salary Details'){
      comp = this.ytdSalaryForm.value.company;
      this.ytdSalaryForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Pay Salary (Bank Transfer) Report'){
      comp = this.releseSalaryForm.value.company;
      this.releseSalaryForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'CTC As Per Salary Structure'){
      comp = this.ctcSalaryStructureForm.value.company;
      this.ctcSalaryStructureForm.get('bu')?.setValue('');
    }
    if(this.reportName == 'Monthly TDS Deduction Report'){
      comp = this.monthlyTDSForm.value.company;
      this.monthlyTDSForm.get('bu')?.setValue('');
    }
    if(comp != undefined && comp != null && comp != ''){
      this.reportService.getBuList(comp).subscribe((res: any) => {
        this.buDD = res;
        this.selectAllForDropdownItems(this.buDD);
    });
    }
  }

  // Reorder Arr
  reorderArray(arr1:any, arr2:any) {
    const set1 = new Set(arr1);
    const matchingItems = arr2.filter((item:any) => set1.has(item));
    const extraItems = arr2.filter((item:any) => !set1.has(item));
    matchingItems.sort((a:any, b:any) => arr1.indexOf(a) - arr1.indexOf(b));
    return matchingItems.concat(extraItems);
  }

  getDepList(){
    this.roleService.getDepartmentList().subscribe((res: any) => {
      if(res.status == 200 && res.body?.length > 0) {
          this.DepDD = res.body;
          this.selectAllForDropdownItems(this.DepDD);
      }
    });
  }
  getDesignationList(){
    this.roleService.getDesignationList().subscribe((res: any) => {
      if(res.status == 200 && res.body?.length > 0) {
          this.DesignationDD = res.body;
          this.selectAllForDropdownItems(this.DesignationDD);
      }
    });
  }
  getGradeList(){
    this.roleService.getGradeList().subscribe((res: any) => {
      if(res.status == 200 && res.body?.length > 0) {
          this.gradeDD = res.body;
          this.selectAllForDropdownItems(this.gradeDD);
      }
    });
  }
  getBranchList(){
    this.reportService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }
  getEmployeeList(){
    this.leave_service.getEmpList(true)
    .subscribe((res: any) => {
      // if(res.status == 200) {
        if (res?.length > 0) {
          for(let i=0;i<res?.length;i++){
            res[i]['name'] = res[i]['first_name'];
            if(res[i]['first_name'] != null)
            res[i]['name'] = res[i]['first_name']+" ";
            if(res[i]['middle_name'] != null)
            res[i]['name'] += res[i]['middle_name']+" ";
            if(res[i]['last_name'] != null)
            res[i]['name']   += res[i]['last_name'];
            res[i]['name']   +=" ("+res[i]['employee_code']+")";
          }
          this.employeeDropdown = res;
          let len = this.employeeDropdown.length;
          for (let i = 0; i < len; i++) {
          this.employeeDropdown[i]['fullname']  = "";
          if(this.employeeDropdown[i]['first_name'] != null)
           this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name']+" ";
          if(this.employeeDropdown[i]['middle_name'] != null)
           this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name']+" ";
          if(this.employeeDropdown[i]['last_name'] != null)
           this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
          }
          // this.selectAllForDropdownItems(this.employeeDropdown);
        }
      // }
    })
  }
  getPayGroupList() {
    this.reportService.getPayGroupList().subscribe((res:any)=>{
      this.payGroupDD = res;
      this.selectAllForDropdownItems(this.payGroupDD);
    });
  }
  getReimbursementDropdown(){
    this.pay_component.getPayComponentList(true,false).subscribe((res:any)=>{
      this.reimburse_component = res
      this.reimburse_component.filter((item:any)=>{
        if(item.component_type_name == "Reimbursement"){
          this.reimbursement_dropdown.push(item)
        }
      })
      this.selectAllForDropdownItems(this.reimbursement_dropdown)
    })
  }

  variableDropdown : any = []
  getVariableDropdown(){
    this.reportService.getVariableComponent().subscribe((res:any)=>{
      this.variableDropdown = res
      this.selectAllForDropdownItems(this.variableDropdown)
    })
  }

  get f()  { return this.employeeForm.controls; }
  get f1()  { return this.releseSalaryForm.controls; }
  get f2()  { return this.esiForm.controls; }
  get f3()  { return this.salaryRegisterForm.controls; }
  get f4()  { return this.ytdSalaryForm.controls; }
  get f5()  { return this.ctcSalaryStructureForm.controls; }
  get f6()  { return this.salaryOnoldForm.controls; }
  get f7()  { return this.ptForm.controls; }
  get f8()  { return this.monthlyTDSForm.controls; }

// Same of above copied for checking otherwise parameter issue will occur
  radioBtnChangeFunctions(value:any){
    this.event = value.e;
    this.contrlName = value.salary_month
    this.val = value.this_month
    this.type = value.sm_specific_month
    this.form = value.form
    let forms;
    if(this.form == 'employeeForm')
      forms = this.employeeForm;
    if(this.form == 'salaryRegisterForm')
      forms = this.salaryRegisterForm;
    if(this.form == 'ptForm')
      forms = this.ptForm;
    if(this.form == 'esiForm')
      forms = this.esiForm;
    if(this.form == 'releseSalaryForm')
      forms = this.releseSalaryForm;
    if(this.form == 'ctcSalaryStructureForm')
      forms = this.ctcSalaryStructureForm;
    if(this.form == 'salaryOnoldForm')
      forms = this.salaryOnoldForm;
    if(this.form == 'monthlyTDSForm')
      forms = this.monthlyTDSForm;
    if(this.form == 'investmentForm')
      forms = this.investmentForm;
      if(this.event.target.value == 'on' ){
        forms?.get(this.contrlName)?.setValue(true);
      }
      else{
        forms?.get(this.contrlName)?.setValue(this.val);
        forms?.get(this.type)?.setValue('');
      }
  }
  /********* FILE EXPORT FUNCTION ****************/
  extremeLimit : boolean = false
  export() {
    let limit = this.lastPage
    let offset = 0
    this.fullData = []
    if(this.initialLoaded){
      // this.loader = true
      if(this.reportName == 'PF (Provident Fund)'){
        this.reportService.pfReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date Of Birth'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Birth']);
                this.fullData[i]['Date of leaving']   = this.appservice.dateFormatDisplay(this.fullData[i]['Date of leaving']);
                this.fullData[i]['Salary month'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary month']);
                this.fullData[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Joining']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Investment Request'){
        this.reportService.investmentReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Request On'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request On']);
                this.fullData[i]['Approved On']  = this.appservice.dateFormatDisplay(this.fullData[i]['Approved On']);
                this.fullData[i]['Date of joining']  = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'PT (Professional Tax)'){
        this.reportService.ptReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage  = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Salary Month'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Month']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'LWF (Labour Welfare Fund)'){
        this.reportService.lwfReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Salary Month'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Month']);
                this.fullData[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Joining']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Salary Register'){
        this.reportService.salaryregisterReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage  = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Salary Date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Date']);
                this.fullData[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Joining']);
                this.fullData[i]['Salary Processing Date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Processing Date']);
                this.fullData[i]['Salary Release Date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Release Date']);
                this.fullData[i]['Date of Releaving'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Releaving']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'CTC As Per Salary Structure'){
        this.reportService.ctcSalaryStructureReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length !=0){
            this.fullData = res?.data
            this.lastPage = res?.count
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Pay Salary (Bank Transfer) Report'){
        this.reportService.releaseSalaryReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData = res?.data
            this.lastPage = res?.count
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'ESI (Employee State Insurance)'){
        this.reportService.esiReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Joining']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'YTD Salary Details'){
        this.reportService.salaryregisterYtdReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage  = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Salary Date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Date']);
                this.fullData[i]['Salary Release Date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Release Date']);
                this.fullData[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Joining']);
                this.fullData[i]['Salary Processing Date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Processing Date']);
                this.fullData[i]['Date Of Releaving'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Releaving']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Salary On Hold'){
        this.reportService.salaryHoldReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData         = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Salary hold date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary hold date']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Monthly TDS Deduction Report'){
        this.reportService.monthlyTDSReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Joining']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Employee Statutory Report'){
        this.reportService.employeeStatutoryReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData = res?.data
             this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['DOJ'] = this.appservice.dateFormatDisplay(this.fullData[i]['DOJ']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
	    else if(this.reportName == 'CTC Revision'){
        this.reportService.ctcRevisionReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<res.data?.length;i++){
                this.fullData[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Joining']);
                this.fullData[i]['Date Of Leaving'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Leaving']);
                this.fullData[i]['Salary Revised from'] = this.appservice.dateFormatDisplay(this.fullData[i]['Salary Revised from']);
                this.fullData[i]['Arrear with effect from'] = this.appservice.dateFormatDisplay(this.fullData[i]['Arrear with effect from']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
	    }
      else if(this.reportName == 'Reimbursement Request'){
        this.reportService.reimbursementRequest(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData = res?.data
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date Of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of Joining']);
                this.fullData[i]['Requested On'] = this.appservice.dateFormatDisplay(this.fullData[i]['Requested On']);
                this.fullData[i]['Approved On'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved On']);
                }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Final Settlement'){
        this.reportService.finalSettlementReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
                this.fullData[i]['Date of leaving'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of leaving']);
                this.fullData[i]['Date of resignation'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of resignation']);
                this.fullData[i]['Date of settlement'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of settlement']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Investment Not Submitted'){
        this.reportService.investmentNotSubmittedReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date Of Joining']  = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
                this.fullData[i]['Date of relieving']  = this.appservice.dateFormatDisplay(this.fullData[i]['Date of relieving']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Variable Paid'){
        this.reportService.variablePaidReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
                this.fullData[i]['Official Email'] = this.fullData[i]['Work Email']
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Overtime Payment'){
        this.reportService.overtimePaymentReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
                this.fullData[i]['Official Email'] = this.fullData[i]['Work Email']
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'Gratuity Paid'){
        this.reportService.gratitudePaidReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
                this.fullData[i]['Date of Relieving'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Relieving']);
                this.fullData[i]['Official Email'] = this.fullData[i]['Work Email']
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'NPS Employer Contribution'){
        this.reportService.npsEmployerReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
                this.fullData[i]['Date of Relieving'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Relieving']);
                this.fullData[i]['Official Email'] = this.fullData[i]['Work Email']
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
      else if(this.reportName == 'TDS Computation'){
        this.reportService.tdsComputationReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.fullData       = res?.data;
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
                this.fullData[i]['Date of Relieving'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Relieving']);
                this.fullData[i]['Official Email'] = this.fullData[i]['Work Email']
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
    }
  }

  commonLogic(data:any){
    let column = this.displayedColumns;
    const newArray = [];
    for (const obj of data) {
      const newObject : any = {};
      for (const key of column) {
          if (obj.hasOwnProperty(key)) {
              newObject[key] = obj[key];
          }
      }
      newArray.push(newObject);
    }
    if(data != undefined )
     this.exportExcel(newArray, this.reportName);
     this.loader = false
     this.extremeLimit = false
     this.cd.detectChanges()
  }
  excelHeaders: string[] = [];
  templateToExcel:string[][] =[]
  public exportExcel(jsonData: any[], fileName: string): void {
    if(!this.nodata){
     const ws: XLSX.WorkSheet  = XLSX.utils.json_to_sheet(jsonData);
     const wb: XLSX.WorkBook   = { Sheets: { 'data': ws }, SheetNames: ['data'] };
     const excelBuffer: any    = XLSX.write(wb,{ bookType: 'xlsx', type: 'array'});
     this.saveExcelFile(excelBuffer, fileName);
     }
    else{
    for(let i=0;i<this.displayedColumns.length;i++){
      this.excelHeaders.push(this.displayedColumns[i])
    this.templateToExcel = [this.excelHeaders,[]];
     }
    const wss: XLSX.WorkSheet=XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const wbb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wbb, wss, 'Sheet1');
    XLSX.writeFile(wbb,fileName + this.fileExtension)
  }
}
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
   clearcommonForm(item:any,index:number){
    if(item == "company"){
      let daata = this.salaryOnoldForm.value.company;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        company: daata
      });
      this.getBUListFunction();
      // this.getBUList();
    }
    else if(item == "companyesi"){
      let daata = this.esiForm.value.company;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        company: daata
      });
      this.getBUListFunction();
      // this.getBUList();
    } else if(item == "bu"){
      let daata = this.salaryOnoldForm.value.bu;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        bu: daata
      });
    }else if(item == "buesi"){
      let daata = this.esiForm.value.bu;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        bu: daata
      });
    } else if(item == "department"){
      let daata = this.salaryOnoldForm.value.department;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        department: daata
      });
    } else if(item == "departmentesi"){
      let daata = this.esiForm.value.department;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        department: daata
      });
    } else if(item == "city"){
      let daata = this.salaryOnoldForm.value.city;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        city: daata
      });
    }else if(item == "cityesi"){
      let daata = this.esiForm.value.city;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        city: daata
      });
    } else if(item == "designation"){
      let daata = this.salaryOnoldForm.value.designation;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        designation: daata
      });
    }else if(item == "designationesi"){
      let daata = this.esiForm.value.designation;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        designation: daata
      });
    } else if(item == "grade"){
      let daata = this.salaryOnoldForm.value.grade;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        grade: daata
      });
    }else if(item == "gradeesi"){
      let daata = this.esiForm.value.grade;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        grade: daata
      });
    } else if(item == "employee"){
      let daata = this.ytdSalaryForm.value.employee_list;
      daata.splice(index, 1);
      this.ytdSalaryForm.patchValue({
        employee_list: daata
      });
    }  else if(item == "branch"){
      let daata = this.salaryOnoldForm.value.branch;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        branch: daata
      });
    }else if(item == "branchesi"){
      let daata = this.esiForm.value.branch;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        branch: daata
      });
    }else if(item == "country"){
      let daata = this.salaryOnoldForm.value.country;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        country: daata
      });
      this.countryChangeFunction();
    }else if(item == "countryesi"){
      let daata = this.esiForm.value.country;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        country: daata
      });
      this.countryChangeFunction();
    }else if(item == "state"){
      let daata = this.salaryOnoldForm.value.state;
      daata.splice(index, 1);
      this.salaryOnoldForm.patchValue({
        state: daata
      });
      this.stateChangeFunction();
    }else if(item == "stateesi"){
      let daata = this.esiForm.value.state;
      daata.splice(index, 1);
      this.esiForm.patchValue({
        state: daata
      });
      this.stateChangeFunction();
    }
  }
  countryChangeFunction(){
    this.stateDD  = [];
    this.cityDD   = [];
    this.BranchDD = [];
    // this.getStateList();
    this.getBranchList();
    // this.getCityList();
  }
  stateChangeFunction(){
    this.cityDD   = [];
    this.BranchDD = [];
    this.getBranchList();
    // this.getCityList();
  }
  onChangeCategory(event:any, paygroup: any){
    const index: number = this.tempArr.indexOf(event.target.value);
    if(event.target.checked){// Use appropriate model type instead of any
    this.tempArr.push(paygroup);
    }else{
      this.tempArr.splice(index, 1);
    }
  }
  onChangEmpStatus(event:any, data: any){ // Use appropriate model type instead of any
    const index: number = this.empstatArr.indexOf(event.target.value);
    if(event.target.checked){
      this.empstatArr.push(data);
    }else{
      this.empstatArr.splice(index, 1);
    }
  }
  scheduleReportFunction(){
    this.isFilter       = true;
    this.scheduleText = '';
    if(this.resDataFilter != undefined && this.resDataFilter != null  && this.resHeader_filter != undefined  && this.resHeader_filter != null){
      if((!!Object.keys(this.resDataFilter)?.length == true || this.resHeader_filter?.length) && (this.submitted == false)){
        this.scheduleReport = true;
        this.scheduleText = 'Schedule the report with filters';
      }else if(this.submitted == true){
        this.scheduleReport = true;
        this.scheduleText = 'Save filters and schedule the report'
      }
      else{
        this.applyFilter    = false;
        this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
      }
    }
  }
  saveFilterClicked = false
  saveFilter(){
    this.columnsArr = [];
    if(this.filterSaveForm.value.filter == true){
      this.saveFilterClicked = true
      this.filterMemorizeFunction('saveFilter','');
    }else{
      this.applyFilter  = false;
      this.submitted    = false;
      this.filterSubmit = false;
      this.checkFilterSubmit = false;
    }
    this.router.navigate(['/payroll-report'])
  }
  // Arrange data based on 1st & remove if not in 2nd
  rearragneAndRemove(arr1:any,arr2:any){
    let result = arr1.filter((item:any) => arr2.includes(item))
    let additionalItems = arr2.filter((item:any) => !arr1.includes(item));
    result.push(...additionalItems);
    return result
  }

  filterMemorizeFunction(data:any,data1:any){
    if(this.header_filter?.length > 0){
      var mySet = new Set(this.header_filter);
      this.header_filter = [...mySet];
    }

    let headerNeeded = !this.arrayAreSame(this.displayedColumns, this.defaultHeaderList)
    if(this.columnChangedArr?.length !=0){
      this.columnChangedArr = this.rearragneAndRemove(this.columnChangedArr,this.checkColumn)
    }
    if(this.reportName == 'Salary On Hold'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appSalaryOnHold.getData() : ""
    }
    else if(this.reportName == 'YTD Salary Details'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appYtdSalaryDetails.getData() : ""
    }
    else if(this.reportName == 'Monthly TDS Deduction Report'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appMonthly.getData()
      }
    }
    else if(this.reportName == 'CTC Revision'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appCTCRevision.getData() : ""
    }
    else if(this.reportName == 'LWF (Labour Welfare Fund)'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appLwf.getData()
      }
    }
    else if(this.reportName == 'PF (Provident Fund)'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appPf.getData()
      }
    }
    else if(this.reportName == 'PT (Professional Tax)'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appPt.getData()
      }
    }
    else if(this.reportName == 'Investment Request'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appInvestmentRequest.getData() : ""
    }
    else if(this.reportName == 'Salary Register'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appsalaryRegister.getData()
      }
    }
    else if(this.reportName == 'CTC As Per Salary Structure'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.ctc_breakup == 'monthly'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appCtc.getData()
      }
    }
    else if(this.reportName == 'Pay Salary (Bank Transfer) Report'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appReleaseSalary.getData()
      }
    }
    else if(this.reportName == 'ESI (Employee State Insurance)'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appEsi.getData()
      }
    }
    else if(this.reportName == 'Employee Statutory Report'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appEmployee_statutory.getData() : ""
    }
    else if(this.reportName == 'Reimbursement Request'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appReimburse.getData() : ""
    }
    else if(this.reportName == 'Final Settlement'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appFinalSettlement.getData() : ""
    }
    else if(this.reportName == 'Investment Not Submitted'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.investmentNotSubmitted.getData() : ""
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.investment_type == 'confirmed,proposed'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.investmentNotSubmitted.getData()
      }
    }
    else if(this.reportName == 'Variable Paid'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appVarPaid.getData()
      }
    }
    else if(this.reportName == 'Overtime Payment'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.ot_payment_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appOverPy.getData()
      }
    }
    else if(this.reportName == 'Gratuity Paid'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appGratitude.getData() : ""
    }
    else if(this.reportName == 'NPS Employer Contribution'){
      if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.salary_month == 'this_month'){
        this.dataFilter = ""
      }else if(Object.keys(this.appliedFilter).length > 0){
        this.dataFilter = this.appNPS.getData()
      }
    }
    else if(this.reportName == 'TDS Computation'){
      this.dataFilter =  Object.keys(this.appliedFilter).length > 0 ? this.appTDSComp.getData() : ""
    }
    if(this.dataFilter == undefined || this.dataFilter == null || this.dataFilter == "" || this.childCall){
      this.dataFilter = JSON.stringify({});
    }

    this.reportService.filterMemorize({'data_filter' : this.childCall == true ? JSON.stringify({}) : this.dataFilter, 'header_filter' : this.columnChangedArr?.length != 0 ? JSON.stringify(this.columnChangedArr) : ((headerNeeded && !this.is_colfilter) ? JSON.stringify(this.displayedColumns) : JSON.stringify([])), 'is_active' : true, 'report' : Number(this.reportId)}).subscribe((res: any) => {
      if(this.callMonthlyTDS || this.resetFilter){
        this.notify.handleSuccessNotification("Updated Successfully","Success")
      }else{
        this.notify.handleSuccessNotification("Created Successfully","Success")
      }
      this.submitted   = false;
      this.filterSubmit = false;
      this.saveFilterClicked = false
      this.restFilterSave = false
      this.checkFilterSubmit = false
      this.hasChangeCount = 0;
      if(data == 'saveFilter'){
        this.resetFilterControllers('save');
        this.applyFilter = false;
      }
      else if(data == 'resetFilter'){
        this.resetFilter = false;
        this.childCall = false
        if(data1 == 'dataFilter'){
          this.resetFilterControllers('reset');
        }else if(data1 == 'colFilter')
          this.resetAllUnDefaultColumns();
        this.viewDetail = false;
      }
      if(data1 == 'saveAndSchedule'){
        this.scheduleReport = false;
        setTimeout(() => {
          if(this.reportId != undefined && this.reportId != null)
            this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/true']);
        }, 3000);
      }
    });
  }

  arrayAreSame(res1:any,res2:any){
    if(res1.length !== res2.length){
      return false
    }
    for(let i=0;i<res1.length;i++){
      if(res1[i] !== res2[i]){
        return false
      }
    }
    return true
  }

  fiterResetDisableFunction(){
  if(!!Object.keys(this.resDataFilter)?.length == true)
    this.filterDisable = true;
  else
    this.filterDisable = false;
  }
  resetFilterFunction(data:any){
    this.resetReportName = data;
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter = true;
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterSubmit = false;
    if(this.reportName == 'Salary On Hold'){
      this.appSalaryOnHold.resetFilterControllers('reset')
    }
    else if(this.reportName == 'YTD Salary Details'){
      this.appYtdSalaryDetails.resetFilterControllers('reset')
    }
    else if(this.reportName == 'Monthly TDS Deduction Report'){
      this.appMonthly.resetFilterControllers('reset')
    }
    else if(this.reportName == 'CTC Revision'){
      this.appCTCRevision.resetFilterControllers('reset');
    }
    else if(this.reportName == 'LWF (Labour Welfare Fund)'){
      this.appLwf.resetFilterControllers('reset');
    }
    else if(this.reportName == 'PF (Provident Fund)'){
      this.appPf.resetFilterControllers('reset');
    }
    else if(this.reportName == 'PT (Professional Tax)'){
      this.appPt.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Investment Request'){
      this.appInvestmentRequest.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Salary Register'){
      this.appsalaryRegister.resetFilterControllers('reset');
    }
    else if(this.reportName == 'CTC As Per Salary Structure'){
      this.appCtc.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Pay Salary (Bank Transfer) Report'){
      this.appReleaseSalary.resetFilterControllers('reset');
    }
    else if(this.reportName == 'ESI (Employee State Insurance)'){
      this.appEsi.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Employee Statutory Report'){
      this.appEmployee_statutory.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Reimbursement Request'){
      this.appReimburse.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Final Settlement'){
      this.appFinalSettlement.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Investment Not Submitted'){
      this.investmentNotSubmitted.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Variable Paid'){
      this.appVarPaid.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Overtime Payment'){
      this.appOverPy.resetFilterControllers('reset');
    }
    else if(this.reportName == 'Gratuity Paid'){
      this.appGratitude.resetFilterControllers('reset');
    }
    else if(this.reportName == 'NPS Employer Contribution'){
      this.appNPS.resetFilterControllers('reset');
    }
    else if(this.reportName == 'TDS Computation'){
      this.appTDSComp.resetFilterControllers('reset');
    }
  }
  // After reseting the form from child, main report fn is called
  callReport(value:any){
    this.filterSubmit = false
    this.childFilterSubmit = true
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true
    this.callAllReportsFunctions()
  }
  resetFilterApply(){
    this.restFilterSave = true
    if(this.is_colfilter == true){
      this.header_filter = [];
      this.childCall = false
      this.filterMemorizeFunction('resetFilter','colFilter');
      this.is_colfilter = false
      this.childFilterSubmit = true;
    }
    else{
      this.childCall = true
      this.dataFilter = JSON.stringify({});
      this.filterMemorizeFunction('resetFilter','dataFilter');
    }
  }

  headerFilterImplementation(headerFilter:any){
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for(let i1=0;i1<elements?.length;i1++){
        var elementss = <HTMLInputElement>elements[i1];
        elementss.checked = false;
      }
    }, 100);
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for(let i=0;i<elements?.length;i++){
        var element = <HTMLInputElement>elements[i];
        for(let j=0;j<headerFilter?.length;j++){
          if(element.defaultValue == headerFilter[j]){
            element.checked   = true;
            element.disabled  = false;
            this.checkColumn.push(headerFilter[j]);
            this.header_filter.push(headerFilter[j]);
          }
        }
      }
    }, 1000);
    for (let i = 0; i < headerFilter?.length; i++) {
      const element = headerFilter[i];
      if (!this.displayedColumns.includes(element)) {
        this.displayedColumns.push(element);
      }
    }
  }

  checkListElements(){
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for(let i=0;i<elements?.length;i++){
        var element = <HTMLInputElement>elements[i];
        for(let j=0;j<this.columns1?.length;j++){
          if(element.defaultValue == this.columns1[j]){
            element.disabled  = false;
          }
        }
      }
    }, 1000);
  }

  resetColumnFilterFunction(){
    if(this.resHeader_filter?.length != 0){
      this.resetFilter = true;
      this.is_colfilter = true;
    }else{
      this.resetAllUnDefaultColumns();
    }
    this.columnChangedArr = []
  }
   // test start
  resetAllUnDefaultColumns(){
    this.checkFilterSubmit  = false;
    this.checkColumn = []
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for(let i=0;i<elements?.length;i++){
        var element = <HTMLInputElement>elements[i];
        if(element.disabled == false)
          element.checked = false;
      }
      for(let j=0;j<this.header_filter?.length;j++){
        const index: number        = this.displayedColumns.indexOf(this.header_filter[j]);
        this.displayedColumns.splice(index, 1);
      }
      if(this.resHeader_filter?.length==0){
        this.dynamicHeader(this.defaultHeaderList?.length,this.defaultHeaderList,this.columns2);
        this.headerFilterImplementation(this.defaultHeaderList)
      }
      this.header_filter = []
      this.columnsArr =[]
      // this.checkColumn = [];
    }, 1000);
    if(this.resHeader_filter?.length != 0){
      this.callAllReportsFunctions()
    }
  }
  // test end
  reportMemorizeFunction(){
    if(this.reportId != undefined && this.reportId != null){
      if(this.scheduleReportFilterMemorizeForm.value.filter == false){
        if(this.scheduleText == 'Save filters and schedule the report'){
          this.filterMemorizeFunction('saveFilter','saveAndSchedule');
        }else{
          this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/true']);
        }
      }else{
        this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
      }
      this.page = 1;
      this.pageNumber = 0
      this.pageResetElseFlag = false
      this.cd.detectChanges()
    }
  }
  disableDate(){
    return false;
  }
  confirm():boolean {
    // return false;
    if(this.isFilter){
      return true;
    }
    else{
      // boolean value from child
    if(this.filterSubmit == true || this.checkFilterSubmit == true) {
      this.applyFilter  = true;
      this.filterSubmit = false;
      this.checkFilterSubmit = false;
      return false;
    }else{
      this.applyFilter = false;
      return true;
    }
  }
  }
  public isChangesSaved() {
    if(this.submitted == true) {
     return confirm("Do you want to discard changes?")
    }
    return true;
  }
  applyTagClose(rptName:any,controlName:any){
    Object.keys(this.appliedFilter).length > 0 || this.columnApplied ? this.filterSubmit = true : this.filterSubmit = false;
    this.resetOffset  = true
    controlName = controlName == 'emp_type' ? 'employee_type'  : controlName
    controlName = controlName == 'emp_status' ? 'employment_status' : controlName
    if(rptName == 'PF (Provident Fund)'){
      this.callMonthlyTDS = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'Investment Request'){
      this.callMonthlyTDS = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'PT (Professional Tax)'){
      this.callMonthlyTDS = true
      this.closeControl = controlName;
    }
    if(rptName == 'LWF (Labour Welfare Fund)'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'Salary Register'){
      this.callMonthlyTDS = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'CTC As Per Salary Structure'){
      this.callMonthlyTDS = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'Pay Salary (Bank Transfer) Report'){
      this.callMonthlyTDS = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'ESI (Employee State Insurance)'){
      this.callMonthlyTDS = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'YTD Salary Details'){
      this.callMonthlyTDS = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'Salary On Hold'){
      this.callMonthlyTDS = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'Monthly TDS Deduction Report'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'CTC Revision'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'Reimbursement Request'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'Employee Statutory Report'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'Final Settlement'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'Investment Not Submitted'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'Variable Paid'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'Overtime Payment'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'Gratuity Paid'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'NPS Employer Contribution'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
    if(rptName == 'TDS Computation'){
      this.callMonthlyTDS = true
      this.closeControl = controlName
    }
  }
  // Tags
  removeUnderscoreAddUpperCase(str:any) {
    var i, frags = str?.split('_');
    for (i=0; i<frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
  tagMultiDataFunction(data:any){
    this.tagMultiData = [];
    this.tagMultiCnt = 0;
    this.tagMultiData.push(data[0]);
    if(data?.length == 1){
      return this.tagMultiData;
    }else{
      this.tagMultiCnt = '+'+JSON.stringify(data?.length-1);
      return this.tagMultiData;
    }
  }
  // Select ALL option for Multiselect
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  dojFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  salFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  // FIlter Panel ON and OFF
  panel(value:any){
    this.viewDetail = value
  }
  childCall : boolean = false
  // Reset filter btn
  resetFilterFn(value:any){
    this.resetFilter = value
    this.columnApplied=false
  }
  // Loader
  loaderFn(value:any){
    this.loader = value
  }
  // Filtersubmit
  filterSubmits(value:any){
    this.filterSubmit = value
  }
  // Datafilters for filter save
  dataFilters(value:any){
    this.dataFilter = value.data
  }
  limit = 20
  offset = 0
  lastPage : any
  initialLoaded : boolean  = true
  pageNumber :  any = 1
  pageResetElseFlag = false
  resetOffset = false
  pageChangeDetect : boolean = false
  pageChanged(val: any) {
    this.pageNumber = val-1
    this.offset = this.reportService.calculateOffset(val-1)
    this.cd.detectChanges()
    this.pageChangeDetect = true
    this.callAllReportsFunctions();
    if (!this.columnApplied) {
      this.columnApplied = false;
    } else {
      this.columnApplied = true;
    }
  }
    callParentFunction(val:any){
      this.initialLoaded = val
    }
    resetCall(val:any){
      this.page = 1
      this.pageNumber = 0
      this.resetOffset = true
    }
  callAllReportsFunctions(){
    if(this.reportName == 'PF (Provident Fund)'){
      this.pfReport();
    }
    else if(this.reportName == 'Investment Request'){
      this.investmentReport();
    }
    else if(this.reportName == 'PT (Professional Tax)'){
      this.ptReport();
    }
    else if(this.reportName == 'LWF (Labour Welfare Fund)'){
      this.lwfReport();
    }
    else if(this.reportName == 'Salary Register'){
      this.salaryregisterReport();
    }
    else if(this.reportName == 'CTC As Per Salary Structure'){
      this.ctcSalaryStructureReport();
    }
    else if(this.reportName == 'Pay Salary (Bank Transfer) Report'){
      this.releaseSalaryReport();
    }
    else if(this.reportName == 'ESI (Employee State Insurance)'){
      this.esiReport();
    }
    else if(this.reportName == 'YTD Salary Details'){
      this.salaryregisterYtdReport();
    }
    else if(this.reportName == 'Salary On Hold'){
      this.salaryHoldReport();
    }
    else if(this.reportName == 'Monthly TDS Deduction Report'){
      this.monthlyTDSReport();
    }
    else if(this.reportName == 'Employee Statutory Report'){
      this.employeeStatutoryReport();
    }
    else if(this.reportName == 'CTC Revision'){
        this.ctcRevisionReport();
      }
      else if(this.reportName == 'Reimbursement Request'){
      this.reimbursementRequest();
    }
    else if(this.reportName == 'Final Settlement'){
      this.FinalSettlementReport();
    }
    else if(this.reportName == 'Investment Not Submitted'){
      this.InvestmentNotSubmittedReport();
    }
    else if(this.reportName == 'Variable Paid'){
      this.variablePaidReport();
    }
    else if(this.reportName == 'Overtime Payment'){
      this.overtimePaymentReport();
    }
    else if(this.reportName == 'Gratuity Paid'){
      this.gratitudePaidReport();
    }
    else if(this.reportName == 'NPS Employer Contribution'){
      this.npsEmployerReport();
    }
    else if(this.reportName == 'TDS Computation'){
      this.tdsComputationReport();
    }
  }
  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }
  yearListFunction(){
    this.selectedYear = new Date().getFullYear();
    this.attendanceReportService.yearListDropdown().subscribe((res: any) => {
     if(res?.created_year == this.selectedYear){
        this.Years.push(res?.created_year)
      }
      else{
        for(let i=this.selectedYear;i>=res?.created_year;i--){
          this.Years.push(i)
        }
      }
    })
  }

  /************ INVESTMENT REPORT  *****************/
  InvestmentNotSubmittedReport(){
    this.loader             = true;
    this.reportName         = "Investment Not Submitted";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.reportService.investmentNotSubmittedReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res?.data?.length != 0){
        this.dataSource       = res?.data;
        this.lastPage = res?.count
        // Date conversion
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date Of Joining']  = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Date of relieving']  = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of relieving']);
          }
        }
        this.dataSource     = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
      if(res.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;

      this.columns2       = res['check_list'];
      this.columnOptions  = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res.data?.length==0?true:false;
      /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
      this.investNotSubData();
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.pageChangeDetect = false
      this.loader  = false;
    });
  }
  /************ INVESTMENT SELECTED DATA DISPLAY  *****************/
  investNotSubData(){
    /**Filter data**/
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        if(this.resDataFilter.investment_type=="True")
          this.resDataFilter.investment_type = [false,true]
        else if(this.resDataFilter.investment_type=="False")
          this.resDataFilter.investment_type = [true,false]
        // Form reset
        this.investmentnsForm.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status?.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type?.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }

      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(this.defaultHeaderList)
    }
  }
}
