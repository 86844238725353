<!-- <app-view-loader *ngIf="loader1"></app-view-loader> -->
  <!-- <ng-container  *ngIf="!loader1"> -->
  <div class="side-panel-body" style="overflow-y: auto;height: calc(100vh - 8.125rem);">
    <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <div class="row row-16" *ngIf="!loader1">
            <div class="col-12">
                <div class="mb-0 fw-500 text-uppercase">Claim Information</div>
            </div>
            <div class="col-12">
                <div class="card card-c2 p-24">
                    <div class="row row-24">
                        <div class="col-12"><span class="title">Reimbursement Category</span></div>
                        <div class="col-12">
                            <div class="card details-card indicater1 indicater-bg2 bg-secondary px-16 py-12">
                              <div class="row">
                                <div class="col-5">
                                  <div class="fw-600 fs-14">{{reimbursementData?.paycomponent}}</div>
                                  <div class="fs-10 text-success fw-500 mt-1" *ngIf="reimbursementData?.as_of_date == 'Monthly'">AS OF {{currentMonth}} {{currentYear}}</div>

                                  <div class="fs-10 text-success fw-500 mt-1" *ngIf="reimbursementData?.as_of_date == 'Yearly'">FISCAL YEAR {{this.appservice.getFinancialYearRange(currentYear)}}</div>
                                </div>

                                <div class="col">
                                  <div class="detail-title">Eligible</div>
                                  <div class="detail-desc">{{reimbursementData?.balance |currency:currency}}</div>
                                </div>
                                <div class="col">
                                    <div class="detail-title">Claimed</div>
                                    <div class="detail-desc">{{reimbursementData?.claimed|currency:currency}}</div>
                                  </div>
                              </div>
                            </div>
                          </div>

                    </div>
                </div>
            </div>
            <ng-container *ngFor="let item of reimbursementData?.reimbursement;let i=index">
              <div class="col-12">
                  <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                      <div class="row row-16">
                          <!-- <div class="col-12 d-flex align-items-center gap-8">
                              <span class="title">Bill {{i+1}}</span>
                              <ng-container *ngIf="(from == 'request') && (item.status=='Pending' || item.status=='Accepted')">
                                <span class="link-danger fs-14 fw-500 text-nowrap" (click)="cancelRequest(reimbursementData?.status, item.id)" >Cancel request</span>
                                <span class="text-light-400">|</span>
                              </ng-container>
                              <span *ngIf="item.status!='Cancelled'" class="badge px-16 ms-auto fs-14 {{item.status=='Pending'?'badge-warning':item.status=='Rejected'?'badge-danger': item.status=='Accepted' ? 'badge-success' : ''}}
                                  py-1">{{item.status}}</span>
                              <span *ngIf="item.status=='Cancelled'" class="px-16 ms-auto fs-14 py-1 ribbon ribbon-secondary">{{item.status}}</span>
                              <span class="text-light-400">|</span>
                              <span class="link-primary fs-14" (click)="viewSingleBillData(item.id)">View Status</span>
                          </div> -->

                          <!-- test start -->
                          <div class="col-12 d-flex align-items-start">
                            <span class="title">Bill {{i+1}}</span>
                            <!-- <div class="d-flex align-items-center gap-8 ms-auto" *ngIf="reimbursementType != 'Reimbursement Cancellation'"> -->
                              <div class="d-flex align-items-center gap-8 ms-auto">
                                <!-- <span class="ribbon ribbon-right ribbon-secondary"  >Cancelled</span>
                                <span  class="text-light-400">|</span> -->
                                <ng-container *ngIf="(from == 'request' && reimbursementType != 'Reimbursement Cancellation') && (item.status=='Pending' || item.status=='Accepted')">
                                  <span class="link-danger fs-14 fw-500 text-nowrap" (click)="cancelRequest(reimbursementData?.status, item?.id, item?.cancellation_raised_status)">Cancel Request</span>
                                  <span  class="text-light-400">|</span>
                                </ng-container>
                                <div *ngIf="item.status!='Cancelled'" class=" fs-14 ms-auto px-2 fw-500 badge3 {{item.status=='Pending'?'badge-warning':item.status=='Rejected'?'badge-danger': item.status=='Accepted' ? 'badge-success' : ''}}">
                                  {{item.status}}
                                </div>
                                <span *ngIf="item.status=='Cancelled'" class="ribbon ribbon-right ribbon-secondary"  >{{item.status}}</span>
                                <span  class="text-light-400">|</span>
                                <span class="link-primary1 fs-14 fw-500 text-nowrap" (click)="viewSingleBillData(item.id)">View Status</span>
                            </div>
                          </div>
                          <!-- test end -->
                          <div class="col-sm-6 col-12">
                              <div class="detail-title">Claim date</div>
                              <div class="detail-desc">{{this.appservice.dateFormatDisplay(reimbursementData.claim_date)}}</div>
                          </div>
                          <div class="col-sm-6 col-12">
                              <div class="detail-title">Bill date</div>
                              <div class="detail-desc">{{this.appservice.dateFormatDisplay(item.bill_date)}}</div>
                          </div>

                          <div class="col-sm-6 col-12">
                              <div class="detail-title">Bill no</div>
                              <div class="detail-desc">{{item.bill_no}}</div>
                          </div>
                          <div class="col-sm-6 col-12">
                              <div class="detail-title">Bill amount</div>
                              <div class="detail-desc">{{item.bill_amount |currency: currency}} </div>
                          </div>
                          <div class="col-12" *ngIf="(item.description!='' && item.description!=null)">
                            <div class="detail-title">Description</div>
                            <div class="detail-desc">{{item.description}}</div>
                          </div>
                          <div class="col-12">
                            <div class="detail-title mb-2">Document</div>
                            <div style="width:12.9375rem;">
                                <div class="card card-c2  bg-secondary" >
                                    <div class="p-12 bg-secondary d-flex align-item-center">

                                        <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                            <span class="tips text-uppercase fs- fw-700 text-trim" >{{this.appservice.getDocName(item.document)}}</span>
                                            <span class="tips-helper ">{{this.appservice.getDocName(item.document)}}</span>

                                            <div class="d-flex gap-8">
                                              <div class="link-primary" (click)="viewDocument(item.document)">
                                                <i class="bi bi-eye me-1"></i>View
                                              </div>
                                              <div class="link-primary" (click)="this.appservice.downloadFile(item.document,this.appservice.getDocName(item.document))">
                                                <i class="bi bi-download me-1"></i>Download
                                              </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
            </ng-container>
            <div class="col-12">
                <div class="card card-c2 flex-row align-items-center px-24 py-16 bg8">
                    <span class="fs-14 fw-500">Total claim amount</span>
                    <span class="ms-auto fw-500">{{reimbursementData?.bill_amount|currency:currency}}</span>
                </div>
            </div>
        </div>
    </div>
  </div>

  <!-- test start -->
  <ng-container *ngIf="from == 'request' && requestCancel">
    <!-- <footer class="side-panel-footer" *ngIf="reimbursementData?.status == 'Pending' || reimbursementData?.status == 'Accepted' ">
      <button type="submit" class="ms-auto btn btn-outline-danger text-uppercase btn-sm" (click)="cancelRequest(reimbursementData?.status)">
          CANCEL REQUEST
      </button>
    </footer> -->

    <app-cancel-request-modal [requestCancel]="requestCancel" [requestName]="'reimbursement'" [requestId]="cancelId" [requestStatus]="reimbursementData?.status" (modal)="closeCancelModal($event)"></app-cancel-request-modal>
  </ng-container>

  <!-- test end -->

<!-- </ng-container> -->

<!-- un supported view format alert -->
<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{alertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="alertToggle = false">Ok</button>
      </div>
    </div>
  </div>
</div>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close1($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Activities</h5>
            <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>

        <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData" [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>

    </form>
  </div>
</ng-container>
<!-- end view status panel -->
