<form class="" [formGroup]="formGp">
  <div class="container-fluid p-32 form-container">
    <div class="row ">
      <p class="text-uppercase fw-500 mb-16">REIMBURSEMENT DETAILS</p>
    </div>
    <div class="row row-16">

      <ng-container formArrayName="reimbursement" *ngFor="let item of reimbursementreq().controls; let i=index; ">
        <ng-container [formGroupName]="i">
          <div class=" col-12 form-row">
            <div class="card card-c2 ">
              <div class="card-body pt-16 px-16 pb-24">
                <p class="fs-14 mb-16 fw-600  align-items-center d-flex">BILL {{i+1}}<i *ngIf="i!=0"
                    class="icon-trash ms-auto pointer" (click)="removeReimbursementreq(i)"></i></p>
                <hr class="hr-1 mb-16">
                <div class="row row-16">
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label required">Claim date</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="The claim date is the date on which you submit the bills."
                        popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                    <div class="input-group form-icon icon-right"
                      [ngClass]="{ 'is-invalid': (submitted || reimbursementreq().controls[i].get('claim_date')?.dirty || reimbursementreq().controls[i].get('claim_date')?.touched) &&  reimbursementreq().controls[i].get('claim_date')?.errors}">
                      <input (click)="datePicker1.open()" class="form-control"
                        placeholder="{{this.messageService.selectplaceholddisplay('claim date')}}" matInput
                        formControlName="claim_date" [matDatepicker]="datePicker1" (keydown)="disableDate()"
                        [ngClass]="{ 'is-invalid': (submitted || reimbursementreq().controls[i].get('claim_date')?.dirty || reimbursementreq().controls[i].get('claim_date')?.touched) &&  reimbursementreq().controls[i].get('claim_date')?.errors}"><i
                        class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                      <mat-datepicker touchUi="false" #datePicker1>

                      </mat-datepicker>
                    </div>
                    <div
                      *ngIf="(submitted || reimbursementreq().controls[i].get('claim_date')?.dirty || reimbursementreq().controls[i].get('claim_date')?.touched) &&  reimbursementreq().controls[i].get('claim_date')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="reimbursementreq().controls[i].get('claim_date')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label required">Bill date</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter the bill's date present on the bill."
                        popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                    <div class="input-group form-icon icon-right"
                      [ngClass]="{ 'is-invalid': (submitted || reimbursementreq().controls[i].get('bill_date')?.dirty || reimbursementreq().controls[i].get('bill_date')?.touched) &&  reimbursementreq().controls[i].get('bill_date')?.errors}">
                      <input (click)="datePicker.open()" autocomplete="off" class="form-control"
                        placeholder="{{this.messageService.selectplaceholddisplay('bill date')}}" matInput
                        formControlName="bill_date" [matDatepickerFilter]="billFilter" [matDatepicker]="datePicker"
                        (keydown)="disableDate()"
                        [ngClass]="{ 'is-invalid': (submitted || reimbursementreq().controls[i].get('bill_date')?.dirty || reimbursementreq().controls[i].get('bill_date')?.touched) &&  reimbursementreq().controls[i].get('bill_date')?.errors}"><i
                        class="icon-calendar pointer" (click)="datePicker.open()"></i>
                      <mat-datepicker touchUi="false" #datePicker>

                      </mat-datepicker>
                    </div>
                    <div
                      *ngIf="(submitted || reimbursementreq().controls[i].get('bill_date')?.dirty || reimbursementreq().controls[i].get('bill_date')?.touched) &&  reimbursementreq().controls[i].get('bill_date')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="reimbursementreq().controls[i].get('bill_date')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label required">Bill no </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter the bill's number that is present on the bill."
                        popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                    <input type="text" class="form-control" autocomplete="off" placeholder="Enter bill no"
                      formControlName="bill_no"
                      [ngClass]="{ 'is-invalid': (submitted || reimbursementreq().controls[i].get('bill_no')?.dirty || reimbursementreq().controls[i].get('bill_no')?.touched) &&  reimbursementreq().controls[i].get('bill_no')?.errors}">
                    <div
                      *ngIf="(submitted || reimbursementreq().controls[i].get('bill_no')?.dirty || reimbursementreq().controls[i].get('bill_no')?.touched) && reimbursementreq().controls[i].get('bill_no')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="reimbursementreq().controls[i].get('bill_no')?.errors">
                        <div *ngIf="reimbursementreq().controls[i].get('bill_no')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}} </div>
                        <div *ngIf="reimbursementreq().controls[i].get('bill_no')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}} </div>
                        <div
                          *ngIf="!(reimbursementreq().controls[i].get('bill_no')?.errors?.pattern) && reimbursementreq().controls[i].get('bill_no')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','20')}} </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="" class="form-label required">Bill amount </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter the bill amount for the bill submitted."
                        popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                    <input type="text" class="form-control" autocomplete="off"
                      placeholder="{{this.messageService.placeholderdisp('bill amount')}}" formControlName="bill_amount"
                      [ngClass]="{ 'is-invalid': (submitted || reimbursementreq().controls[i].get('bill_amount')?.dirty || reimbursementreq().controls[i].get('bill_amount')?.touched) &&  reimbursementreq().controls[i].get('bill_amount')?.errors}">
                    <div
                      *ngIf="(submitted || reimbursementreq().controls[i].get('bill_amount')?.dirty || reimbursementreq().controls[i].get('bill_amount')?.touched) && reimbursementreq().controls[i].get('bill_amount')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="reimbursementreq().controls[i].get('bill_amount')?.errors">
                        <div *ngIf="reimbursementreq().controls[i].get('bill_amount')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="reimbursementreq().controls[i].get('bill_amount')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>
                        <div
                          *ngIf="!(reimbursementreq().controls[i].get('bill_amount')?.errors?.pattern) && !(reimbursementreq().controls[i].get('bill_amount')?.errors?.max) && reimbursementreq().controls[i].get('bill_amount')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('num','10')}}</div>
                        <div
                          *ngIf="!(reimbursementreq().controls[i].get('bill_amount')?.errors?.pattern) && reimbursementreq().controls[i].get('bill_amount')?.errors?.max">
                          {{this.messageService.balanceAmountValidation()}}</div>
                      </div>
                    </div>
                  </div>
                  <div class=" col-md-12 form-row">
                    <label for="" class="form-label ">Description </label>
                    <textarea type="text" rows="3" class="form-control" autocomplete="off" id="reason"
                      placeholder="{{this.messageService.descriptiontext('desc')}}" formControlName="description"
                      [ngClass]="{ 'is-invalid': (submitted || reimbursementreq().controls[i].get('description')?.dirty ) &&  reimbursementreq().controls[i].get('description')?.errors}"></textarea>
                    <div
                      *ngIf="(submitted || reimbursementreq().controls[i].get('description')?.dirty || reimbursementreq().controls[i].get('description')?.touched) && reimbursementreq().controls[i].get('description')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="reimbursementreq().controls[i].get('description')?.errors">

                        <div
                          *ngIf="!(reimbursementreq().controls[i].get('description')?.errors?.pattern) && reimbursementreq().controls[i].get('description')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">


                    <label for="ppf_document" class="form-label upload-doc mb-0 fs-16 fw-500 required"
                      (click)="setCurrentControl(i)">
                      <i class="bi bi-cloud-upload m-1"></i>Upload document
                    </label>
                  </div>
                  <label class="is-invalid" for="ppf_document" style="display: none;">
                    <app-file-upload [crop]="false" [round]="false" [id]="'ppf_document'" [FileType]="fileType"
                      [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                      (validsize)="getvalidsize($event)"></app-file-upload>
                  </label>
                  <span class="invalid-feedback"
                    *ngIf="(docLoadersArray.length>0 && docLoadersArray[i]['filesize'+i] && !docLoadersArray[i]['loader'+i])">{{errormsg}}</span>
                  <ng-container
                    *ngIf="reimbursementreq().controls[i].get('document')?.value != '' && reimbursementreq().controls[i].get('document')?.value != null && !(docLoadersArray.length>0 && docLoadersArray[i]['filesize'+i])">
                    <div class="col-12  " *ngIf="(docLoadersArray.length>0 && !docLoadersArray[i]['loader'+i])">
                      <!-- <ul class="selected-values ">
                        <li class="ng-value">
                          <span
                            class="">{{this.appservice.getDocName(reimbursementreq().controls[i].get('document')?.value)}}</span>
                          <span aria-hidden="true" class=" right">
                            <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(i)"></i>
                          </span>
                        </li>
                      </ul> -->

                      <!-- test start -->
                      <div class="card card-c2  bg-secondary" style="width:16.375rem;">
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                            <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(reimbursementreq().controls[i].get('document')?.value)}}</span>
                            <span class="tips-helper ">{{this.appservice.getDocName(reimbursementreq().controls[i].get('document')?.value)}}</span>
                            <div class="d-flex gap-8">
                              <div class="link-primary" (click)="viewDocument(reimbursementreq().controls[i].get('document')?.value)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(reimbursementreq().controls[i].get('document')?.value,this.appservice.getDocName(reimbursementreq().controls[i].get('document')?.value))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(i)"></i>
                        </div>
                      </div>
                      <!-- test end -->
                    </div>
                  </ng-container>
                  <div class="" *ngIf="(docLoadersArray.length>0 && docLoadersArray[i]['loader'+i])">
                    <div class="fs-12 text-muted">Loading </div>
                    <div class="ms-4 dot-pulse"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="col-12 d-flex">
        <a class="btn btn-outline-primary h-40 px-64 radius-2" (click)="addReimbursementreq()">Add More Bills</a>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="formGp.invalid"
      (click)="changeTab()">Next</button>
  </footer>
</form>

<!-- test start -->
<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle=false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<!-- un supported view format alert -->
<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{alertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="alertToggle = false">Ok</button>
      </div>
    </div>
  </div>
</div>
<!-- test end -->
