import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-holiday-request-view',
  templateUrl: './holiday-request-view.component.html',
  styleUrls: ['./holiday-request-view.component.scss']
})

export class HolidayRequestViewComponent implements OnInit {

  @Input()holidayRequestView:any;
  @Input()loader:any;
  @Input()holidayRequestSingleData:any;
  @Input()from:any;
  @Input()requestId:any;
  @Input()requestName:any;

  requestCancel = false;

  @Output() holidayViewClose  = new EventEmitter();
  @Output() closeViewPage= new EventEmitter();

  constructor(
    public appservice:AppService,
    private notificationService: NotificationService
  ) { }

  checkOutAction = false;
  ngOnInit(): void {
  }

  /********* CLOSE FUNCTION ****************/
  closeFunction(){
    this.holidayViewClose.emit(false);
  }

  cancelRequest(status:any, cancelStatus:any,arrear:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("Holiday request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else{
      this.requestCancel = true;
    }
  }

  closeCancelModal(data:any){
  this.requestCancel = data?.show;
  if(data?.action == 'submit')
    this.closeViewPage.emit(data?.show);
  }

}
