<app-edit-loader *ngIf="loader"></app-edit-loader>
<ng-container *ngIf="!loader">

  <div class="flex-center flex-column full-page bg-nonlogin">
    <!-- <a routerLink="/">
    <img class="img-fluid org-logo" src="../../assets/images/SkizzleHR-Logo/SkizzleHR-HorizontalLogo-Color.svg" alt="">
  </a> -->
    <div class="card form-card mx-auto">
      <div class="card-body  p-32">
        <a routerLink="/login" class="text-center d-block">
          <img class="img-fluid org-logo" src="{{currentlogodata}}" alt=""
            *ngIf="currentlogodata != '' && currentlogodata != null">
          <img class="img-fluid org-logo" src="../../assets/images/SkizzleHR-Logo/SkizzleHR-HorizontalLogo-Color.svg"
            alt="" *ngIf="currentlogodata == '' || currentlogodata == null">
        </a>
        <!-- <h3 class="fw-600 text-center py-40">Forgot Password?  </h3> -->
        <h3 class="pb-4  pt-40 fw-600">Forgot Password? <br>Don’t worry! we got your back</h3>
        <p>Please enter your email or employee code, we’ll send you an OTP to reset your password</p>
        <form [formGroup]="forgotForm" (ngSubmit)="forgotPasswordClick()">

          <div class="mb-3">
            <label for="" class="form-label">Email or Employee code</label>
            <input autofocus type="text" class="form-control" name="" id="" aria-describedby="helpId" placeholder=""
              formControlName="username"
              [ngClass]="{ 'is-invalid': (f.username.dirty || f.username.touched || submitted)  &&  ((f.username.errors && !inValidInput) || (inValidInput && !(f.username.errors)))}">
            <div
              *ngIf="(f.username.dirty || f.username.touched || submitted) && ((f.username.errors && !inValidInput) || (inValidInput && !(f.username.errors)))"
              class="invalid-feedback">
              <div *ngIf="f.username.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="!(f.username.errors) && inValidInput">{{errorMsg}}</div>

            </div>

          </div>
          <div class="d-grid gap-2 pt-5">
            <button (click)="forgotPasswordClick()" type="button" class="btn btn-primary">Send</button>
          </div>
        </form>
      </div>
    </div>
    <p class="text-center mb-0 text-light-400 py-24"><small><a
          href="https://www.skizzlehr.tech/pages/privacy-policy.php" class="link-secondary" target="_blank">Privacy
          policy</a> | <a href="https://www.skizzlehr.tech/pages/terms-and-condition.php" class="link-secondary"
          target="_blank">Terms and conditions</a> | {{this.appservice.version}}</small></p>
  </div>
</ng-container>