import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyItDeclarationService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getMyItDeclarations(employee_id:any,year:any,proposed_confirm:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/my-investment-declaration/?employee_id=${employee_id}&year=${year}&proposed_confirm=${proposed_confirm}`, this.httpOption)
  }
}
