import { Component, OnInit, Inject, ViewChild,ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {  AssetReportServiceService } from 'src/app/@core/services/asset-report-service.service';
import { FormBuilder, Validators, FormControl ,FormGroup} from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { RoleService } from 'src/app/@core/services/role.service';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { AssetRequestReportComponent } from './asset-request-report/asset-request-report.component';
import { HardwareInventoryReportComponent } from './hardware-inventory-report/hardware-inventory-report.component';
import { AssetHistoryReportComponent } from './asset-history-report/asset-history-report.component';

/*Inventory*/
import { AssetPolicyService } from 'src/app/@core/services/asset-policy.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';

/*End*/

@Component({
  selector: 'app-asset-report-configure',
  templateUrl: './asset-report-configure.component.html',
  styleUrls: ['./asset-report-configure.component.scss']
})
export class AssetReportConfigureComponent implements OnInit {
  alertShow = true;
 // test start
//  table: any;
 scheduleReport      = false;
 applyFilter         = false;
 resetFilter         = false;
 viewDetail          = false;
 addColumnFilter     = false;
 dataSource:any      = [];
 columnNames:any     = [];
 page                = 1;
 pageSize            = 20;
 showTotalPages      = 3;
 fileType            = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
 fileExtension       = '.xlsx';
 fileName:any;
 loader              = true;
 nodata              = false;
 reportName:any      = '';
 paginateData: any[] = [];
 startDate: any;
 endDate: any;
 displayedColumns:any  = [];
 columnOptions:any     = [];

 columnOptions1:any    = [];

 columnsControl        = new FormControl();

 checkColumn:any       = [];
 fullData:any          = [];
 callReports: any;
 callemp: boolean      = false
 checkListValue: any;
 checkList: any;
 multiData:any = [];
 applicableData:any = [];
 applicableCount = 0

 closeControl: any;

 //FILTER MEMORIZE VARIABLES
 dataFilter: any;
 header_filter: any    = [];
 resDataFilter:any;
 resHeader_filter:any  = [];
 filterDisable         = false;
 resetReportName:any;
 is_colfilter          = false;

 //SCHEDULE REPORT MEMORIZE VARIABLES
 isFilter              = false;
 scheduleText:any      = '';

 //TAG FILTER VARIABLES
 appliedFilter: any      = [];
 tagMultiData: any       = [];
 tagMultiCnt: any        = 0;
 columnsArr: any         = []
 restFilterSave: boolean = false

 disabled=false;
 columnChangedArr : any = []
 submitted         = false;
 filterSubmit      = false;
 checkFilterSubmit = false;

  Years : any = [];

  reportId = 0;
  selectedYear: any;

  limit                     = 20;
  offset                    = 0;
  lastPage: any;
  initialLoaded: boolean    = false;
  pageNumber: any           = 1;
  pageResetIfFlag           = true;
  pageResetElseFlag         = false;

  ordering: any             = 'Employee Code';
  pageChangeDetect: boolean = false;
  timeSetup: any;

  defaultHeaderList:any     = [];

  reportingDD: any          = [];

  dynamicColumn : string = 'Employee Code'
  dynamicSortDirection: 'asc' | 'desc' = 'asc';

  hasChange: boolean = false;
  hasChangeCount:any = 0;

  excelHeaders: string[]   = [];
  templateToExcel:  any[]  = [];
  columnApplied : boolean       = false

 companyDD :any =  [{
    id            : 0,
    company_name  : ''
  }];

  buDD = [{
    id          : 0,
    name        : ''
  }];

  DepDD = [{
    id          : 0,
    name        : ''
  }];

  DesignationDD = [{
    id          : 0,
    name        : ''
  }];

  countryDD = [{
    id          : 0,
    name        : ''
  }];

  stateDD = [{
    id          : 0,
    name        : ''
  }];

  BranchDD = [{
    id          : 0,
    name        : ''
  }];

  cityDD = [{
    id          : 0,
    name        : ''
  }];

  gradeDD = [{
    id           : 0,
    name         : ''
  }];

  employeeDropdown:any  = [];


  filterSaveForm = this.formBuilder.group({
    filter: true,
  });

  scheduleReportFilterMemorizeForm = this.formBuilder.group({
    filter    : [true],
  });

  assetform = this.formBuilder.group({
    checkbox  : [''],
  })

  columns1: string[] = [];
  columns2: string[] = [];

  employeetype: any[] = [
    {name: 'Permanent'},
    {name: 'Consultant'},
    {name: 'Wages'},
    {name: 'Trainee'},
    {name: 'Contractual'},
    {name: 'Apprentice'},
  ];

  employeestatus: any[] = [
    {name: 'Probation'},
    {name: 'Confirmed'},
    {name: 'Suspended'},
    {name: 'Absconding'},
    {name: 'Resigned'},
    {name: 'Terminated'},
    {name: 'Relieved'},
    {name: 'Retired'},
  ];

  emptypeDD = [
    {id:"Contractual", name:"Contractual"},
    {id:"Consultant", name:"Consultant"},
    {id:"Permanent", name:"Permanent"},
    {id:"Trainee", name:"Trainee"},
    {id:"Wages", name:"Wages"},
  ];

  empstatusDD = [
    {id:0,    name:"Probation"},
    {id:1,    name:"Confirmed"},
    {id:2,    name:"Relieved"},
    {id:3,    name:"Resigned"},
    {id:4,    name:"Settled"},
    {id:5,    name:"Terminated"},
    {id:6,    name: 'Retired'},

  ];

  requestStatus = [{name:'Pending',value:'Pending'},{name:'Approved',value:'Accepted'},{name:'Rejected',value:'Rejected'}]

  requestType = [{name:'New',value:'New'},{name:'Return',value:'Return'}];

  listOfMonth = [{id:1,name:"January"},{id:2,name:"February"},{id:3,name:"March"},{id:4,name:"April"},{id:5,name:"May"},{id:6,name:"June"},{id:7,name:"July"},
  {id:8,name:"August"},{id:9,name:"September"},{id:10,name:"October"},{id:11,name:"November"},{id:12,name:"December"}];

  hint_dict:any;
  filterFormData: any = ''
  childFilterSubmit: boolean = false

  constructor(
    public assetReportService: AssetReportServiceService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public roleService:RoleService,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
    public cd : ChangeDetectorRef,
    public assetPolicyService:AssetPolicyService,
    private notify : NotificationService,
    private assetSettings : AssetSettingsService

  ) { }

  // Calling the close fn of tag in parent from child
  @ViewChild(AssetRequestReportComponent, { static: false }) appLive !: AssetRequestReportComponent;
  @ViewChild(HardwareInventoryReportComponent, { static: false }) inventory !: HardwareInventoryReportComponent;
  @ViewChild(AssetHistoryReportComponent, { static: false }) history !: AssetHistoryReportComponent;


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    this.columnChangedArr = this.displayedColumns
    this.filterSubmit = true
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort;

  scrollLeft(e:any): void {
    let wrapper         = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft -= 150;
  }

  scrollRight(e:any): void {
    let wrapper         = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft += 150;
   }

   OuFilterForm = this.formBuilder.group({
    company               : [''],
    bu                    : [''],
    branch                : [''],
    designation           : [''],
    department            : [''],
    employee_list         : [''],
    grade                 : [''],
    employee_type         : [''],
    employment_status     : [''],
  })

  liveLocationForm = this.formBuilder.group({
    request_month   : ['this_month',[Validators.required]],
    specific_month  : null,
    specific_year   : null,
    request_status  : ['',[Validators.required]],
    request_type    : ['',[Validators.required]],
    ouFilterForm    : this.OuFilterForm
  })

  ngOnInit(): void {
    this.childFilterSubmit = true
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);


    this.initialLoaded = true
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
          this.reportId = params['id'];
      }
       if(params['name'] == 'AssetRequest'){
        this.reportName   = 'Asset Request'
        this.sortProperty = 'Employee Code'
        this.ordering = 'Employee Code'
        this.assetRequestReport();
      }
      else if(params['name']=='HardwareInventoryReport'){
        this.reportName   = 'Hardware Inventory'
        this.assetInventoryReport();
      }
      else if(params['name']=='AssetHistory'){
        this.reportName   = 'Asset History'
        this.sortProperty = 'Employee code'
        this.ordering = 'Employee code'
        this.assetHistoryReport();
      }

    })
    this.selectAllForDropdownItems(this.status_list);

  }

  resetOffset : boolean = false;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort      = this.sort;
    this.getCompanyList();
    this.getBUList();
    this.getDepList();
    this.getDesignationList();
    this.getGradeList();
    this.getBranchList();
    this.getEmployeeList();
    this.getCategoryList();
    this.getSubCategoryList();
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);
    this.selectAllForDropdownItems(this.requestStatus);
    this.selectAllForDropdownItems(this.requestType);
    this.yearListFunction();
  }

  // test start

  getCompanyList(){
    this.roleService.getCompanyList().subscribe((res: any) => {
      if(res.status == 200 && res.body?.length > 0) {
          this.companyDD = res.body;

        this.selectAllForDropdownItems(this.companyDD);
      }
    });
  }

  getDepList(){
    this.roleService.getDepartmentList().subscribe((res: any) => {
      if(res.status == 200 && res.body?.length > 0) {
          this.DepDD = res.body;
          this.selectAllForDropdownItems(this.DepDD);
      }
    });
  }

  getDesignationList(){
    this.roleService.getDesignationList().subscribe((res: any) => {
      if(res.status == 200 && res.body?.length > 0) {
          this.DesignationDD = res.body;
          this.selectAllForDropdownItems(this.DesignationDD);
      }
    });
  }

  getGradeList(){
    this.roleService.getGradeList().subscribe((res: any) => {
      if(res.status == 200 && res.body?.length > 0) {
          this.gradeDD = res.body;
          this.selectAllForDropdownItems(this.gradeDD);
      }
    });
  }

  getBranchList(){
    this.assetReportService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }


  yearListFunction(){
    this.selectedYear = new Date().getFullYear();
    this.assetReportService.yearListDropdown().subscribe((res: any) => {
     if(res?.created_year == this.selectedYear){
        this.Years.push(res?.created_year)
      }
      else{
        for(let i=this.selectedYear;i>=res?.created_year;i--){
          this.Years.push(i)
        }
      }
    })
  }
 empLoader : boolean = false
  getEmployeeList(){
    this.empLoader = true
    this.assetSettings.empDDList(true,'user__first_name')
    .subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body?.length > 0) {
          for(let i=0;i<res.body?.length;i++){
            res.body[i]['name'] = res.body[i]['first_name'];
            if(res.body[i]['first_name'] != null)
            res.body[i]['name'] = res.body[i]['first_name']+" ";
            if(res.body[i]['middle_name'] != null)
            res.body[i]['name'] += res.body[i]['middle_name']+" ";
            if(res.body[i]['last_name'] != null)
            res.body[i]['name']   += res.body[i]['last_name'];
          res.body[i]['fullname'] = res.body[i]['name'].slice()
            res.body[i]['name']   +=" ("+res.body[i]['employee_code']+")";


          }
          this.employeeDropdown = res.body;
          this.empLoader = false
        }
      }
    })
  }

  assetRequestReport(){
    this.loader             = true;
    this.reportName         = "Asset Request";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);
    this.selectAllForDropdownItems(this.requestStatus);
    this.selectAllForDropdownItems(this.requestType);

    this.assetReportService.assetRequestReport(this.limit,this.offset,this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if(res?.data?.length != 0){
        this.dataSource = res?.data
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
            this.dataSource[i]['Request on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request on']);
            this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']);
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter        = res?.data_filter;
        this.resHeader_filter     = res?.header_filter;
      // }

      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}

      if(res?.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter?.request_month != 'this_month' && this.appliedFilter?.request_month != 'last_month'  && this.appliedFilter?.request_month != '' && this.appliedFilter?.request_month != null){
          this.liveLocationForm?.get('request_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.request_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.liveLocationForm.get('specific_year')?.setValue(split_year_month[0]);
          this.liveLocationForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.liveLocationForm.get('request_month')?.setValue(this.appliedFilter.request_month);
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata           = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res?.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res?.data?.length,res['header_list'],res['check_list']);
      }

       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.liveLocationForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
          if(this.resDataFilter?.request_month != 'this_month' && this.resDataFilter?.request_month != 'last_month'  && this.resDataFilter?.request_month != '' && this.resDataFilter?.request_month != null){
            this.liveLocationForm?.get('request_month')?.setValue(true);
            const attendance_year_month = this.resDataFilter?.request_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.liveLocationForm.get('specific_year')?.setValue(split_year_month[0]);
            this.liveLocationForm.get('specific_month')?.setValue(atten_month);
          }
          else{
            this.liveLocationForm.get('request_month')?.setValue(this.resDataFilter.request_month);
          }

        }
       }

      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
         this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader           = false;
      this.pageChangeDetect = false
    })
  }

   // Reorder Arr
   reorderArray(arr1:any, arr2:any) {
    const set1 = new Set(arr1);
    const matchingItems = arr2.filter((item:any) => set1.has(item));
    const extraItems = arr2.filter((item:any) => !set1.has(item));
    matchingItems.sort((a:any, b:any) => arr1.indexOf(a) - arr1.indexOf(b));
    return matchingItems.concat(extraItems);
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  resetCall(val:any){
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true
  }

  callParentFunction(val:any){
    this.initialLoaded = val
  }

  loaderFn(value:any){
    this.loader = value
  }

  dataFilters(value:any){
    this.dataFilter = value.data
  }

  panel(value:any){
    this.viewDetail = value
  }

  resetFilterFn(value:any){
    this.resetFilter = value
    this.columnApplied = false
  }

  filterSubmits(value:any){
    this.filterSubmit = value
  }

  // After reseting the form from child, main report fn is called
callReport(value:any){
  this.filterSubmit = false
  this.childFilterSubmit = true
  this.page = 1
  this.pageNumber = 0
  this.resetOffset = true
  this.callAllReportsFunctions()
}

 // Submit after filter applied, getting value from child
submitDone(value:any){
  if(this.callemp && Object.keys(this.resDataFilter)?.length > 0){
    this.filterMemorizeFunction('saveFilter','')
  }else{
    this.callemp = false
  }
  this.limit = value.limit
  this.offset = value.offset
  this.filterFormData = value.filterForm
  this.filterSubmit = !this.filterFormData?.split('&').every((data:any)=>{
    const [key, value] = data?.split('=')
    if(key === 'ordering'){
      return true;
    }
    return (value === '[]' || value === '')
  })
  this.childFilterSubmit = false
  this.page = 1
  this.pageNumber = 0
  this.resetOffset = true
  this.callAllReportsFunctions()
  this.pageChangeDetect = false
  this.viewDetail = false
}

callAllReportsFunctions(){
  if(this.reportName == 'Asset Request'){
    this.assetRequestReport();
  }
  else if(this.reportName == 'Hardware Inventory'){
    this.assetInventoryReport();
  }
  else if(this.reportName == 'Asset History')
    this.assetHistoryReport();
}

setDataSourceAttributes() {
  if(this.dataSource != undefined && this.dataSource != null){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort      = this.sort;
  }
}

  checkListElements(){
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for(let i=0;i<elements?.length;i++){
        var element = <HTMLInputElement>elements[i];
        for(let j=0;j<this.columns1?.length;j++){
          if(element.defaultValue == this.columns1[j]){
            // element.checked   = true;
            element.disabled  = false;

          }
        }
      }
    }, 1000);
  }

  getBUList(){
    let comp;

    if(this.reportName == 'Asset Request'){
      comp = this.OuFilterForm.value.company;
      this.liveLocationForm.get('bu')?.setValue('');
    }
    if(comp != undefined && comp != null && comp != ''){
      this.assetReportService.getBuList(comp).subscribe((res: any) => {
        this.buDD = res;
        this.selectAllForDropdownItems(this.buDD);
    });
    }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  removeUnderscoreAddUpperCase(str:any) {
    var i, frags = str?.split('_');
    for (i=0; i<frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
  return frags.join(' ');
  }

  tagMultiDataFunction(data:any){
    this.tagMultiData = [];
    this.tagMultiCnt  = 0;
    this.tagMultiData.push(data[0]);
    if(data?.length == 1){
      return this.tagMultiData;
    }else{
      this.tagMultiCnt = '+'+JSON.stringify(data?.length-1);
      return this.tagMultiData;
    }
  }

  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  applyTagClose(rptName:any,controlName:any){
    controlName = controlName == 'employment_type' ? 'emp_type'  : controlName
    controlName = controlName == 'employment_status' ? 'emp_status'  : controlName
    Object.keys(this.appliedFilter).length > 0 || this.columnApplied ? this.filterSubmit = true : this.filterSubmit = false;
    this.resetOffset = true
    this.callemp = true;

    this.cd.detectChanges();
    if(rptName == 'Asset Request'){
      controlName = controlName == 'emp_status' ?  'employment_status' : controlName
      controlName = controlName == 'emp_type' ? 'employee_type'  : controlName
      this.closeControl   = controlName;
    }
    else
     this.closeControl   = controlName;

  }

  sortProperty : string = 'Employee Code'
  direction : string = 'asc'
  onSort(val:any) {
    if (this.sortProperty === val) {
      this.direction = this.direction === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortProperty = val;
      this.direction = 'desc';
    }
    const newOrder = this.direction === 'desc' ? '-' : '';
    this.ordering = newOrder + val
    this.cd.detectChanges()
    this.filterFormData = this.filterFormData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.pageChangeDetect = true
    this.columnApplied == true ? this.columnApplied = true : this.columnApplied = false
    this.reportDataLoading()
    // this.columnApplied = false
  }

  pageChanged(val: any) {
    this.pageNumber = val-1
    this.offset = this.assetReportService.calculateOffset(val-1)
    this.cd.detectChanges()
    this.pageChangeDetect = true
    this.reportDataLoading()

    if (!this.columnApplied) {
      this.columnApplied = false;
    } else {
      this.columnApplied = true;
    }
  }
  // test end


  extremeLimit : boolean = false
  export() {
   let limit = this.lastPage
   let offset = 0
   this.fullData = []
   this.loader = true
    if(this.reportName == 'Asset Request'){
        this.assetReportService.assetRequestReport(limit,offset,this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if(res?.body?.data?.length != 0){
            this.fullData = res?.data
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
                this.fullData[i]['Request on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request on']);
                this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']);
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
        else  if(this.reportName == 'Hardware Inventory'){


         this.assetReportService.assetInventoryReport(limit,offset,this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if(res?.body?.data?.length != 0){
            this.fullData = res?.data
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Assigned on']           = this.fullData[i]['Assigned on']!="None"?this.appservice.dateFormatDisplay(this.fullData[i]['Assigned on']):'';
                this.fullData[i]['End of life']           = this.fullData[i]['End of life']!="None"?this.appservice.dateFormatDisplay(this.fullData[i]['End of life']):'';
                this.fullData[i]['Last audit date']       = this.fullData[i]['Last audit date']!="None"?this.appservice.dateFormatDisplay(this.fullData[i]['Last audit date']):'';
                this.fullData[i]['Manufacturing date']    = this.fullData[i]['Manufacturing date']!="None"?this.appservice.dateFormatDisplay(this.fullData[i]['Manufacturing date']):'';
                this.fullData[i]['Return date']           = this.fullData[i]['Return date']!="None"? this.appservice.dateFormatDisplay(this.fullData[i]['Return date']):'';
                this.fullData[i]['Invoice date']          = this.fullData[i]['Invoice date']!="None"? this.appservice.dateFormatDisplay(this.fullData[i]['Invoice date']):'';
                this.fullData[i]['Warranty expiry']       = this.appservice.dateFormatDisplay(this.fullData[i]['Warranty expiry']);
                this.fullData[i]['Asset Code']            = this.fullData[i]['Asset code'];
                this.fullData[i]['Asset Name']            = this.fullData[i]['Asset name'];
                this.fullData[i]['Asset Category']     = this.fullData[i]['Asset category'];
              }
            }
            this.commonLogic(this.fullData)
          }
        })
        }
        else  if(this.reportName == 'Asset History'){
         this.assetReportService.assetHistoryReport(limit,offset,this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if(res?.body?.data?.length != 0){
            this.fullData = res?.data
            this.lastPage = res?.count
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Assigned on'] = this.fullData[i]['Assigned on']!="None"?this.appservice.dateFormatDisplay(this.fullData[i]['Assigned on']):'';
                this.fullData[i]['Approved on']  = this.fullData[i]['Approved on']!="None"?this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']):'';
                this.fullData[i]['Return date'] =this.fullData[i]['Return date']!="None"? this.appservice.dateFormatDisplay(this.fullData[i]['Return date']):'';
                this.fullData[i]['Requested on'] =this.fullData[i]['Requested on']!="None"? this.appservice.dateFormatDisplay(this.fullData[i]['Requested on']):'';
              }
            }
            this.commonLogic(this.fullData)
          }
        })
      }
  }

 commonLogic(data:any){

   let column = this.displayedColumns;
   const newArray = [];
   for (const obj of data) {
     const newObject : any = {};
     for (const key of column) {
         if (obj.hasOwnProperty(key)) {
             newObject[key] = obj[key];
         }
     }
     newArray.push(newObject);
   }
  //  this.nodata = newArray?.length == 0 ? true : false
   if(data != undefined )
    this.exportExcel(newArray, this.reportName);
    this.loader = false
    this.extremeLimit = false
    this.cd.detectChanges()
 }

  public exportExcel(jsonData: any[], fileName: string): void {
    if(!this.nodata){
      const ws: XLSX.WorkSheet  = XLSX.utils.json_to_sheet(jsonData);
      const wb: XLSX.WorkBook   = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any    = XLSX.write(wb,{ bookType: 'xlsx', type: 'array'});
      this.saveExcelFile(excelBuffer, fileName);
    }
    else{
      for(let i=0;i<this.displayedColumns.length;i++){
        this.excelHeaders.push(this.displayedColumns[i])
        this.templateToExcel = this.excelHeaders
      }
      const header = this.templateToExcel
      const data : any = []
      const ws : XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header,...data]);
      const wb : XLSX.WorkBook = {Sheets : {'data':ws}, SheetNames:['data']};
      const excelBuffer : any = XLSX.write(wb, {bookType : 'xlsx', type:'array'})
      this.saveExcelFile(excelBuffer,fileName)
    }
  }

 private saveExcelFile(buffer: any, fileName: string): void {
  const data: Blob = new Blob([buffer], {type: this.fileType});
  FileSaver.saveAs(data, fileName + this.fileExtension);
}

  checkChange : boolean  = false
headerFilterAdd(e:any,index:any){
  this.checkChange = true
  this.columnApplied = true
  this.header_filter=this.dupliCateRemove(this.header_filter)

  if(e.target.checked){
    this.submitted = true;
    this.checkColumn.push(e.target.value);
    this.header_filter.push(e.target.value);
  }else{
    if(this.header_filter.length==1){
      var elements = document.getElementsByName("check");
      setTimeout(() => {

          var element = <HTMLInputElement>elements[index];
            element.checked = true;

      })
    }
    else{
      this.checkColumn = this.dupliCateRemove(this.checkColumn)
      const index: number        = this.checkColumn.indexOf(e.target.value);
      const header_index: number = this.header_filter.indexOf(e.target.value);
      const column_1: number     = this.columns1.indexOf(e.target.value);
      this.checkColumn.splice(index, 1);
      this.header_filter.splice(header_index,1);
      this.columns1.splice(column_1,1);
    }
  }
}

resetColumnFilterFunction(){
  if(this.resHeader_filter?.length != 0){
    this.resetFilter = true;
    this.is_colfilter = true;
  }else{
    this.resetAllUnDefaultColumns();
  }
  this.columnChangedArr = []
}

dupliCateRemove(data:any){
  const uniqueColumnsSet = new Set();
  data.filter((column:any) => {
    if (!uniqueColumnsSet.has(column)) {
      uniqueColumnsSet.add(column);
      return true;
    }
    return false;
  });
  return Array.from(uniqueColumnsSet)
}

resetAllUnDefaultColumns(){
  this.checkFilterSubmit  = false;
  this.checkColumn = []
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      if(element.disabled == false)
        element.checked = false;
    }

    for(let j=0;j<this.header_filter?.length;j++){
      const index: number        = this.displayedColumns.indexOf(this.header_filter[j]);
      if(index != -1){
      this.displayedColumns.splice(index, 1);
      }
    }
    if(this.resHeader_filter?.length==0){

      this.dynamicHeader(this.defaultHeaderList?.length,this.defaultHeaderList,this.columns2);
      this.headerFilterImplementation(this.defaultHeaderList)

    }
    this.header_filter = [];
    this.columnsArr    = [];


  }, 1000);
  if(this.resHeader_filter?.length != 0){
    this.reportDataLoading();
  }
}
reportDataLoading(){
  if(this.reportName == 'Asset Request'){
    this.assetRequestReport();
  }
  else if(this.reportName == 'Hardware Inventory'){
    this.assetInventoryReport();
  }
  else if(this.reportName == 'Asset History')
    this.assetHistoryReport();
}

dynamicHeader(data_length:any,header_list:any,check_list:any){
  // this.nodata           = data_length==0?true:false;
  this.columns1         = header_list?.slice();
    // this.displayedColumns = this.columnsArr?.length > 0 ? this.dupliCateRemove(this.columnsArr) : this.dupliCateRemove(this.columns1)
  this.displayedColumns = this.dupliCateRemove(this.columns1)

  this.columnOptions1   = this.columns1;
}

headerFilterImplementation(headerFilter:any){
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i1=0;i1<elements?.length;i1++){
      var elementss = <HTMLInputElement>elements[i1];
      elementss.checked = false;
    }
  }, 100);

  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      for(let j=0;j<headerFilter?.length;j++){
        if(element.defaultValue == headerFilter[j]){
          element.checked   = true;
          element.disabled  = false;
          this.checkColumn.push(headerFilter[j]);
          this.header_filter.push(headerFilter[j]);
        }
      }
    }
  }, 1000);

  for (let i = 0; i < headerFilter?.length; i++) {
    const element = headerFilter[i];
    if (!this.displayedColumns.includes(element)) {
      this.displayedColumns.push(element);
    }
  }
}

headerFilterApply(){
  if(!this.checkChange){
    return
  }
  let array3 = this.columns1?.concat(this.checkColumn);
  array3     = array3?.filter((item:any,index:any)=>{
      return (array3.indexOf(item) == index)
  })
  if(this.columnChangedArr?.length !=0){
    array3 = this.reorderArray(this.columnChangedArr,array3)
  }
  this.displayedColumns   = array3;
  this.columnsArr  = this.displayedColumns;
  if(this.checkColumn?.length > 0 && this.columnApplied)

    this.checkFilterSubmit  = true;
  else
    this.checkFilterSubmit  = false;

  // this.checkFilterSubmit  = true;
  this.checkChange = false
}

childCall : boolean = false;

resetFilterApply(){
  this.restFilterSave = true
  if(this.is_colfilter == true){
    this.header_filter = [];
    this.childCall = false
    this.filterMemorizeFunction('resetFilter','colFilter');
    this.is_colfilter = false
    this.childFilterSubmit = true
  }
  else{
    this.childCall =  true
    this.dataFilter = JSON.stringify({});
    this.filterMemorizeFunction('resetFilter','dataFilter');
  }
}
arrayAreSame(res1:any,res2:any){
  if(res1.length !== res2.length){
    return false
  }
  for(let i=0;i<res1.length;i++){
    if(res1[i] !== res2[i]){
      return false
    }
  }
  return true
}

// Arrange data based on 1st & remove if not in 2nd
rearragneAndRemove(arr1:any,arr2:any){
  let result = arr1.filter((item:any) => arr2.includes(item))
  let additionalItems = arr2.filter((item:any) => !arr1.includes(item));
  result.push(...additionalItems);
  return result
}

filterMemorizeFunction(data:any,data1:any){
  if(this.header_filter?.length > 0){
    var mySet = new Set(this.header_filter);
    this.header_filter = [...mySet];
  }
  let headerNeeded = !this.arrayAreSame(this.displayedColumns, this.defaultHeaderList)
  if(this.columnChangedArr?.length !=0){
    this.columnChangedArr = this.rearragneAndRemove(this.columnChangedArr,this.checkColumn)
  }
  if(this.reportName == 'Asset Request'){
    if(Object.keys(this.appliedFilter).length == 1 && this.appliedFilter?.request_month == 'this_month'){
      this.dataFilter = ''
    }else{
      this.dataFilter = this.appLive.getData()
    }
  }
  else if(this.reportName == 'Hardware Inventory'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0? this.inventory.getData():''
  }
  else if(this.reportName == 'Asset History'){
    if(Object.keys(this.appliedFilter).length == 2 && this.appliedFilter?.assigned_on == 'this_month' && this.appliedFilter?.requested_on == 'this_month'){
      this.dataFilter = ''
    }else{
      this.dataFilter = this.history.getData()
    }
  }


  if(this.dataFilter == undefined || this.dataFilter == null || this.dataFilter == ""){
    this.dataFilter = JSON.stringify({});
  }
  this.assetReportService.filterMemorize({'data_filter' : this.childCall == true ? JSON.stringify({}): this.dataFilter, 'header_filter' : this.columnChangedArr?.length != 0 ? JSON.stringify(this.columnChangedArr) : ((headerNeeded && !this.is_colfilter) ? JSON.stringify(this.displayedColumns) : JSON.stringify([])), 'is_active' : true, 'report' : Number(this.reportId)}).subscribe((res: any) => {
    if(this.callemp || this.resetFilter){
      this.notify.handleSuccessNotification("Updated Successfully","Success")
    }else{
      this.notify.handleSuccessNotification("Created Successfully","Success")
    }
    this.saveFilterClicked = false
    this.restFilterSave = false
    this.submitted   = false;
    this.filterSubmit = false;
    this.checkFilterSubmit = false
    this.hasChangeCount = 0;
    if(data == 'saveFilter'){
      this.resetFilterControllers('save');
      this.applyFilter = false;
    }
    else if(data == 'resetFilter'){
      this.resetFilter = false;
      this.childCall = false
      if(data1 == 'dataFilter')
        this.resetFilterControllers('reset');
      else if(data1 == 'colFilter')
        this.resetAllUnDefaultColumns();

      this.viewDetail = false;
    }
    if(data1 == 'saveAndSchedule'){
      this.scheduleReport = false;
      setTimeout(() => {
        if(this.reportId != undefined && this.reportId != null)
          this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/true']);
      }, 3000);
    }
  });
}

resetFilterControllers(data:any){
  this.viewDetail   = false;
  this.filterSubmit = false;

  if(this.reportName == 'Asset Request'){
    this.appLive.resetFilterControllers('reset')
  }

  else if(this.reportName == 'Hardware Inventory'){
    this.inventory.resetFilterControllers('reset')
  }
 else if(this.reportName == 'Asset History'){
    this.history.resetFilterControllers('reset')
  }


}

saveFilterClicked = false;

 saveFilter(){
  this.columnsArr = [];
  if(this.filterSaveForm.value.filter == true){
    this.saveFilterClicked = true
    this.filterMemorizeFunction('saveFilter','');
  }else{
    this.applyFilter  = false;
    this.submitted    = false;
    this.filterSubmit = false;
    this.checkFilterSubmit = false;
  }
  this.router.navigate(['/asset-report']);
}

confirm():boolean {
  // return false;
  if(this.isFilter){
    return true;
  }
  else{
    // boolean value from child
  if(this.filterSubmit == true || this.checkFilterSubmit == true) {
    this.applyFilter  = true;
    this.filterSubmit = false;
    this.checkFilterSubmit = false;
    return false;
  }else{
    this.applyFilter = false;
    return true;
  }
}

}


/******************HADWARE INVETORY REPORT************************/

inventoryForm = this.formBuilder.group({
  asset_category      : [],
  asset_sub_category  : [],
  status              : [],
  assigned_to         : [],
  assigned_by         : [],
  applicable          : [],
  assignees           : [],
  assigned_on         : [],
  specific_month      : null,
  specific_year       : null,
})
categoryDD:any              = []
subCategoryDD:any           = []
status_list:any             = [
                              {id:"In use",name:"In Use"},
                              {id:"In Stock",name:"In Stock"},
                              {id:"In Repair",name:"In Repair"},
                              {id:"Missing",name:"Missing"},
                              {id:"Retired",name:"Retired"},
                              {id:"Damaged",name:"Damaged"},
];

applicable                  = [
                              { id: 1, name: 'BU' },
                              { id: 2, name: 'Company' },
                              { id: 3, name: 'Department' },
                              { id: 4, name: 'Designation' },
                              { id: 5, name: 'Employee' },
                              { id: 6, name: 'Grade' },
];

assetInventoryReport(){
  this.loader             = true;
  this.reportName         = "Hardware Inventory";
  this.appliedFilter      = [];
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page

  this.assetReportService.assetInventoryReport(this.limit,this.offset,this.ordering,this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {

    if(res?.data?.length != 0){
      this.dataSource = res?.data
      this.lastPage   = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Assigned on']        = this.dataSource[i]['Assigned on']!="None"?this.appservice.dateFormatDisplay(this.dataSource[i]['Assigned on']):'';
          this.dataSource[i]['End of life']        = this.dataSource[i]['End of life']!="None"?this.appservice.dateFormatDisplay(this.dataSource[i]['End of life']):'';
          this.dataSource[i]['Last audit date']    = this.dataSource[i]['Last audit date']!="None"?this.appservice.dateFormatDisplay(this.dataSource[i]['Last audit date']):'';
          this.dataSource[i]['Manufacturing date'] = this.dataSource[i]['Manufacturing date']!="None"?this.appservice.dateFormatDisplay(this.dataSource[i]['Manufacturing date']):'';
          this.dataSource[i]['Return date']        = this.dataSource[i]['Return date']!="None"? this.appservice.dateFormatDisplay(this.dataSource[i]['Return date']):'';
          this.dataSource[i]['Invoice date']       = this.dataSource[i]['Invoice date']!="None"? this.appservice.dateFormatDisplay(this.dataSource[i]['Invoice date']):'';
          this.dataSource[i]['Warranty expiry']    = this.appservice.dateFormatDisplay(this.dataSource[i]['Warranty expiry']);
          this.dataSource[i]['Asset Code']         = this.dataSource[i]['Asset code'];
          this.dataSource[i]['Asset Name']         = this.dataSource[i]['Asset name'];
          this.dataSource[i]['Asset Category']     = this.dataSource[i]['Asset category'];
        }
      }
      this.dataSource       = new MatTableDataSource(this.dataSource);
      this.setDataSourceAttributes();
    }


    // if(this.childFilterSubmit){
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
    // }

    this.defaultHeaderList    = res?.header_list?.slice()
    this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}

    if(res?.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      this.timeSetup = res?.applied_filter?.stop_time
      if(this.appliedFilter.assigned_on != 'last_month' && this.appliedFilter.assigned_on != 'this_month' && this.appliedFilter.assigned_on != ''  && this.appliedFilter.assigned_on != null){
        this.inventoryForm.get('assigned_on')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.assigned_on
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.inventoryForm.get('doj_specific_year')?.setValue(split_year_month[0]);
        this.inventoryForm.get('doj_specific_month')?.setValue(atten_month);
      }
      else{
        this.inventoryForm.get('assigned_on')?.reset(this.appliedFilter.assigned_on);
      }
    this.columns2         = res['check_list'];
    this.columnOptions    = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata           = res?.data?.length == 0 ? true : false;
    this.cd.detectChanges()

    // /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res?.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res?.data?.length,res['header_list'],res['check_list']);
    }

     /**Filter data**/
     if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.inventoryForm.reset(this.resDataFilter);
        this.setFormControlsToNullObj(this.inventoryForm)
          if (this.appliedFilter?.managed_by) {

          let data = this.appliedFilter?.managed_by
          this.inventoryForm.get('applicable')?.setValue(data["applicable"])
          this.inventory.changeApplicable(0)
          this.inventoryForm.get('assignees')?.setValue(data["ids"])
        }
        if(this.resDataFilter?.assigned_on != 'this_month' && this.resDataFilter?.assigned_on != 'last_month'  && this.resDataFilter?.assigned_on != '' && this.resDataFilter?.assigned_on != null){
          this.inventoryForm?.get('assigned_on')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.assigned_on
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.inventoryForm.get('specific_year')?.setValue(split_year_month[0]);
          this.inventoryForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.inventoryForm.get('assigned_on')?.setValue(this.resDataFilter.assigned_on);
        }
      }
     }

    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
       this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }

    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.loader           = false;
    this.pageChangeDetect = false
  })
}

getCategoryList(){
  this.assetPolicyService.assetCategoryDropDown(true).subscribe((res:any)=>{
    this.categoryDD = res;
    this.selectAllForDropdownItems(this.categoryDD);

  })
}
getSubCategoryList(){

  this.assetPolicyService.assetSubCategoryDropDown(true).subscribe((res: any) => {
    this.subCategoryDD  = res
    this.selectAllForDropdownItems(this.subCategoryDD);

  })

}
populateMultiData(key:any){
  this.multiData = this.appliedFilter[key]
  return this.multiData;
}
populateApplicableData(){


  this.applicableData = [];
  this.applicableCount  = 0;
  this.applicableData = this.appliedFilter['managed_by']['objects']

  if(this.appliedFilter['managed_by']['ids'].length == 1){
    return this.tagMultiData;
  }else{
    this.applicableCount = this.appliedFilter['managed_by']['ids'].length-1
    return this.applicableData;
  }
}

historyForm = this.formBuilder.group({
  asset_category      : [null],
  asset_sub_category  : [null],
  // status              : [null],
  assigned_to         : [null],
  assigned_by         : [null],
  applicable          : [null],
  assignees           : [null],
  assigned_on         : ['this_month'],
  specific_month      : null,
  specific_year       : null,
  requested_on        : ['this_month'],
  specific_month_req  : null,
  specific_year_req   : null,
  approved_on         : [null],
  specific_month_app  : null,
  specific_year_app   : null,

})
assetHistoryReport(){
  this.loader             = true;
  this.reportName         = "Asset History";
  this.appliedFilter      = [];
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page

  this.assetReportService.assetHistoryReport(this.limit,this.offset,this.ordering,this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {

    if(res?.data?.length != 0){
      this.dataSource = res?.data
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Assigned on']   = this.dataSource[i]['Assigned on']!="None"?this.appservice.dateFormatDisplay(this.dataSource[i]['Assigned on']):'';
          this.dataSource[i]['Approved on']   = this.dataSource[i]['Approved on']!="None"?this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']):'';
          this.dataSource[i]['Return date']   = this.dataSource[i]['Return date']!="None"? this.appservice.dateFormatDisplay(this.dataSource[i]['Return date']):'';
          this.dataSource[i]['Requested on']  = this.dataSource[i]['Requested on']!="None"? this.appservice.dateFormatDisplay(this.dataSource[i]['Requested on']):'';
        }
      }
      this.dataSource       = new MatTableDataSource(this.dataSource);
      this.setDataSourceAttributes();
    }


    // if(this.childFilterSubmit){
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
    // }

    this.defaultHeaderList    = res?.header_list?.slice()
    this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}

    if(res?.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      this.timeSetup = res?.applied_filter?.stop_time
      if(this.appliedFilter?.assigned_on != 'this_month' && this.appliedFilter?.assigned_on != 'last_month'  && this.appliedFilter?.assigned_on != '' && this.appliedFilter?.assigned_on != null){
        this.historyForm?.get('assigned_on')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.assigned_on
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.historyForm.get('specific_year')?.setValue(split_year_month[0]);
        this.historyForm.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.historyForm.get('assigned_on')?.setValue(this.appliedFilter.assigned_on);
      }

      if(this.appliedFilter?.approved_on != 'this_month' && this.appliedFilter?.approved_on != 'last_month'  && this.appliedFilter?.approved_on != '' && this.appliedFilter?.approved_on != null){
        this.historyForm?.get('approved_on')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.approved_on
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.historyForm.get('specific_year_app')?.setValue(split_year_month[0]);
        this.historyForm.get('specific_month_app')?.setValue(atten_month);
      }
      else{
        this.historyForm.get('approved_on')?.setValue(this.appliedFilter.approved_on);
      }

      if(this.appliedFilter?.requested_on != 'this_month' && this.appliedFilter?.requested_on != 'last_month'  && this.appliedFilter?.requested_on != '' && this.appliedFilter?.requested_on != null){
        this.historyForm?.get('requested_on')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.requested_on
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.historyForm.get('specific_year_req')?.setValue(split_year_month[0]);
        this.historyForm.get('specific_month_req')?.setValue(atten_month);
      }
      else{
        this.historyForm.get('requested_on')?.setValue(this.appliedFilter.requested_on);
      }
    this.columns2         = res['check_list'];
    this.columnOptions    = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata           = res?.data?.length == 0 ? true : false;
    this.cd.detectChanges()

    // /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res?.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res?.data?.length,res['header_list'],res['check_list']);
    }

     /**Filter data**/
     if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.historyForm.reset(this.resDataFilter);
        this.setFormControlsToNullObj(this.historyForm)
          if (this.resDataFilter?.managed_by) {
          let data = JSON.parse(this.resDataFilter?.managed_by)
          this.historyForm.get('applicable')?.setValue(data["applicable"])
          this.history.changeApplicable(0)
          this.historyForm.get('assignees')?.setValue(data["ids"])
        }
        if(this.resDataFilter?.assigned_on != 'this_month' && this.resDataFilter?.assigned_on != 'last_month'  && this.resDataFilter?.assigned_on != '' && this.resDataFilter?.assigned_on != null){
          this.historyForm?.get('assigned_on')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.assigned_on
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.historyForm.get('specific_year')?.setValue(split_year_month[0]);
          this.historyForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.historyForm.get('assigned_on')?.setValue(this.resDataFilter.assigned_on);
        }

        if(this.resDataFilter?.approved_on != 'this_month' && this.resDataFilter?.approved_on != 'last_month'  && this.resDataFilter?.approved_on != '' && this.resDataFilter?.approved_on != null){
          this.historyForm?.get('approved_on')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.approved_on
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.historyForm.get('specific_year_app')?.setValue(split_year_month[0]);
          this.historyForm.get('specific_month_app')?.setValue(atten_month);
        }
        else{
          this.historyForm.get('approved_on')?.setValue(this.resDataFilter.approved_on);
        }

        if(this.resDataFilter?.requested_on != 'this_month' && this.resDataFilter?.requested_on != 'last_month'  && this.resDataFilter?.requested_on != '' && this.resDataFilter?.requested_on != null){
          this.historyForm?.get('requested_on')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.requested_on
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.historyForm.get('specific_year_req')?.setValue(split_year_month[0]);
          this.historyForm.get('specific_month_req')?.setValue(atten_month);
        }
        else{
          this.historyForm.get('requested_on')?.setValue(this.resDataFilter.requested_on);
        }
      }
     }

    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
       this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }

    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.loader           = false;
    this.pageChangeDetect = false
  })
}
setFormControlsToNullObj(formGroup:FormGroup) {
  for (const controlName of Object.keys(formGroup.controls)) {
    const control = formGroup.get(controlName);
    if (control?.value === '') {
      control?.setValue(null);
    }
  }
}

reportMemorizeFunction() {
  if (this.reportId != undefined && this.reportId != null) {
    if (this.scheduleReportFilterMemorizeForm.value.filter == false) {
      if (this.scheduleText == 'Save filters and schedule the report') {
        this.filterMemorizeFunction('saveFilter', 'saveAndSchedule');
      } else {
        this.router.navigate(['/scheduled-reports/reports-setup/' + this.reportId + '/true']);
      }
    } else {
      this.router.navigate(['/scheduled-reports/reports-setup/' + this.reportId + '/false']);
    }
  }
}

scheduleReportFunction(){
  this.isFilter       = true;
  this.scheduleText = '';
  if(this.resDataFilter != undefined && this.resDataFilter != null  && this.resHeader_filter != undefined  && this.resHeader_filter != null){
    if((!!Object.keys(this.resDataFilter)?.length == true || this.resHeader_filter?.length) && (this.submitted == false)){
      this.scheduleReport = true;
      this.scheduleText = 'Schedule the report with filters';
    }else if(this.submitted == true){
      this.scheduleReport = true;
      this.scheduleText = 'Save filters and schedule the report'
    }
    else{
      this.applyFilter    = false;
      this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
    }
  }else{
      this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
  }
}
}
