<div class="container-fluid " [class.p-24]='!fromfile' *ngIf="permissions||fromfile">
  <div class="row row-16">
    <div class="col-12 d-flex align-items-start">
      <ng-container *ngIf="fromfile == true">
        <div class="slider-input-container td-dropdown position-relative" style="width:16.25rem;">
          <input (click)="datePicker1.open()" class="form-control w-100 position-absolute"
            placeholder="{{this.messageServ.selectplaceholddisplay('date')}}" matInput [(ngModel)]="pickDate"
            (dateInput)="fromDates($event)" [matDatepicker]="datePicker1" [min]="minDate" [max]="maxDate"
            style="visibility : hidden;">
          <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
          <button class="slider-btn-left" (click)="changeWeek('left')"><i class="icon-chevron-left-lg"></i></button>
          <div class="slider-input">
            <div class="align-items-center d-flex justify-content-around" (click)="datePicker1.open()">
              <span class="fs-14 fw-500">{{datePickDay}}</span>
              <span class="fs-10 fw-500 text-light-500">{{weekRange}}</span>
            </div>
          </div>
          <button class="slider-btn-right" (click)="changeWeek('right')"><i class="icon-chevron-right-lg"></i></button>
        </div>
      </ng-container>
      <ng-container *ngIf="fromfile != true">
        <div class="vstack">
          <h3>Manage Shift</h3>
          <p class="text-helper mb-md-0">View and manage employees shifts here.</p>
        </div>
      </ng-container>
      <div class="d-flex justify-content-end gap-16 ms-auto">
        <app-searchbar [searchlist]='searchListData' [searchplaceholder]='searchHolder' [isLoading]="sLoading"
          (searchEmitter)="searchResults($event)" (keywordsearchEmitter)="searchEmp($event)"></app-searchbar>
        <ng-container *ngIf="fromfile != true">
          <!-- <div class="col-12 d-flex"> -->
          <div class="slider-input-container td-dropdown position-relative" style="width:16.25rem;">
            <input (click)="datePicker1.open()" class="form-control w-100 position-absolute"
              placeholder="{{this.messageServ.selectplaceholddisplay('date')}}" matInput [(ngModel)]="pickDate"
              [min]="minDate" [max]="maxDate" (dateInput)="fromDates($event)" [matDatepicker]="datePicker1"
              style="visibility : hidden;">
            <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
            <button class="slider-btn-left" (click)="changeWeek('left')"><i class="icon-chevron-left-lg"></i></button>
            <div class="slider-input">
              <div class="align-items-center d-flex justify-content-around" (click)="datePicker1.open()">
                <span class="fs-14 fw-500">{{datePickDay}}</span>
                <span class="fs-10 fw-500 text-light-500">{{weekRange}}</span>
              </div>
            </div>
            <button class="slider-btn-right" (click)="changeWeek('right')"><i
                class="icon-chevron-right-lg"></i></button>
          </div>
          <!-- </div> -->
        </ng-container>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle
            (click)="filterpanel=true">
            <i class="icon-filter-left fs-13"></i>
          </button>
        </div>
        <button class="btn flex-shrink-0 radius-2 btn-outline-primary fs-10 fw-700 text-uppercase px-4"
          routerLink="/employee-shift/employee-shift-import" *ngIf="permissions?.a">Import
          shift</button>
        <button class="btn flex-shrink-0 radius-2 btn-primary fs-10 fw-700 text-uppercase px-4"
          routerLink="/employee-shift/shift-mapping-setup" *ngIf="permissions?.a">Assign shift</button>
      </div>
    </div>
    <div class="col-12" *ngIf="selectedList?.length!=0 && !loader && alertDiv">
      <div role="alert" class="alert alert-warning show mb-0" id="mapShow">
        <p class="mb-0 fs-14"> The shift has not been assigned for {{selectedList?.length}} employees. <a
            class="link-primary1" (click)="showSidePanel=true">Click here</a> to view the details</p>
        <div class="btn-close pointer" (click)="alertDiv = false"></div>
      </div>
    </div>
    <!-- Filter -->
      <app-common-employee-filters class="mt-0" [filterForm]="filterForm" [from]="from" [filter]="filterpanel"
      (filterClose)="closefn($event)" (filterOutput)="resultfilterfn($event)" (dropOutput)="searchDrop($event)" [isMemorized]="isMemorized" (resetCalled)="resetCalled($event)"></app-common-employee-filters>
    <div class="col-12">
      <app-table-loader *ngIf="loader"></app-table-loader>
      <div *ngIf="noData && !loader;else all_data" class="row row-16">
        <div class="card card-c2 overflow-hidden">
          <div class="table-responsive "
            style="max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));">
            <table
              class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header td-width-15ch td-white-space"
              mat-table [dataSource]="landData" matSort cdkDropList cdkDropListOrientation="horizontal">
              <ng-container *ngFor="let prop of displayedColumns">
                <ng-container [matColumnDef]="prop">
                  <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header
                    ngbPopover="Click and drag  the header’s  to change the column position"
                    popoverClass="popover-default popover-info" container="body" triggers="hover">
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    {{prop}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{element[prop]}}
                  </td>
                </ng-container>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            </table>
            <div class="text-center card card-c2 flex-center p-16 bg-dark-500 m-12">No data found</div>
          </div>
        </div>
      </div>
      <ng-template #all_data>
        <div class="card card-c2 overflow-hidden">
          <div class="table-responsive  scrollbar-10 table-minheight"
            style="max-height: calc(100vh - {{(selectedList?.length!=0 && !loader && alertDiv) ? '17.9rem':'13.5rem'}})"
            *ngIf="!loader && landData.length!=0">
            <table class="table sticky-header table-sm form-cell-sm td-0  sticky-first-col">
              <thead>
                <tr>
                  <th class="fw-600 text-center" scope="col">Employee </th>
                  <th class="fw-600 text-center text-uppercase" scope="col" style="min-width: 7.375rem"
                    *ngFor="let date of landData[0].shift_detail ; let i = index">{{date?.assigndate?.slice(0,3)}} -
                    {{getDayMonth(date?.assignday)}}</th>
                </tr>
              </thead>
              <tbody class="">
                <tr *ngFor="let item of landData ; let i = index">
                  <td>
                    <div class=" p-12 d-flex align-items-center">
                      <img *ngIf="(item?.profile_image!=null && item?.profile_image!='')" class="avatar-circle sq-32"
                        src="{{item?.profile_image}}" alt="">
                      <div *ngIf="(item?.profile_image ==  null || item?.profile_image == '')"
                        class="avatar-circle fs-12 sq-32 {{item?.color_code}}">
                        {{this.appService.getFirstChar(item?.name,2)}}</div>
                      <div class="overflow-hidden ms-16 d-flex flex-column">
                        <p class="fs-14 fw-600 w-15ch text-trim mb-0" ngbPopover="{{item?.name}}"
                          popoverClass="popover-input popover-info" container="body" triggers="hover">{{item?.name}}</p>
                        <p class="fs-10 mb-0  text-trim lh-base">{{item?.employee_code}}</p>
                      </div>
                    </div>
                  </td>
                  <td *ngFor="let day of landData[i].shift_detail" class="cell-status {{day?.attendance_class}} ">
                    <div
                      class="card-cell p-8 bg-opacity-30 {{isCurrentDate(day?.assignday) ? 'td-today' : ''}} {{day?.shift != null? day?.shift.color_code : ''}}">
                      <div class="card-body d-flex p-0 align-items-start">
                        <div class="overflow-hidden vstack">
                          <p class="mb-0 fs-12 fw-600 w-15ch text-trim"
                            ngbPopover="{{(day?.shift == null ? 'No shift' : day?.shift?.shift_name+ ' - '+day?.shift?.shift_code)}}"
                            popoverClass="popover-input popover-info" container="body" triggers="hover">{{(day?.shift ==
                            null ? 'No shift' :
                            day?.shift?.shift_name+ " - "+day?.shift?.shift_code)}}
                            <!-- <span class="sq-12 ms-8 rounded-circle "></span> -->
                          </p>
                          <span class="fs-10 text-nowrap"
                            *ngIf="day?.shift != null">{{this.appService.timeFormatDisplay(day?.shift.shift_start_time)}}
                            - {{this.appService.timeFormatDisplay(day?.shift.shift_end_time)}}</span>
                        </div>
                        <div ngbDropdown class="d-inline-block ms-auto" *ngIf="day?.shift!=null && permissions?.e">
                          <button class="btn-reset p-0 text-light-400" placement="bottom-left" id="dropdownBasic1"
                            ngbDropdownToggle>
                            <i class="icon-three-dots-vertical "></i>
                          </button>
                          <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="editForm(day?.id,day?.assignday,item?.employee_id)">
                              <i class="icon-pencil-square me-1"></i> Edit
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-body p-0" style="max-height: 1.375rem;" *ngIf="day?.shift_weekoff != '' || day?.weekoff_day != null">
                      <span class="fs-10 fw-500 text-light-500">{{(day?.shift_weekoff || day?.weekoff_day)? '(week off)'
                        : ''}}</span>
                    </div> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="col-12" *ngIf="!loader && !noData && lastPage > landData.length && lastPage > 20">
      <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPage" [maxSize]="3"
        [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
        (pageChange)="getPageFromService($event)"></ngb-pagination>
    </div>
  </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:50rem;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Edit shift</h5>
      <a class="toggle-panel" (click)="viewDetail = false;init = true;"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;" [formGroup]="shift_from_to_date" autocomplete="off">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewloader"></app-view-loader>
        <ng-container *ngIf="!viewloader">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">Shift </p>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-6">
              <label for="from" class="form-label required">From </label>
              <div class="input-group form-icon icon-right"
                [ngClass]="{ 'is-invalid': (f.from.touched || f.from.dirty) && f.from.errors}">
                <input (click)="datePicker1.open()" (dateInput)="fromDateVal($event)" id="from" autocomplete="off"
                  placeholder="{{this.messageServ.placeholderdisp('date')}}" class="form-control" [min]="minDate"
                  [ngClass]="{ 'is-invalid': ( f.from.touched || f.from.dirty) && f.from.errors}" matInput
                  formControlName="from" [matDatepicker]="datePicker1" (keydown)="disableDate()"><i
                  class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
              </div>
              <div *ngIf="( f.from.touched || f.from.dirty) && f.from.errors" class="invalid-feedback">
                <div *ngIf="f.from.errors.required">{{this.messageServ.validationDisplay('required')}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <label for="to" class="form-label required">To </label>
              <div class="input-group form-icon icon-right"
                [ngClass]="{ 'is-invalid': (f.to.touched || f.to.dirty) && f.to.errors }">
                <input (click)="datePicker2.open()" (dateInput)="toDateVal()" autocomplete="off"
                  placeholder="{{this.messageServ.placeholderdisp('date')}}" class="form-control" id="to"
                  [ngClass]="{ 'is-invalid': (f.to.touched || f.to.dirty) && f.to.errors }" matInput
                  formControlName="to" [min]="f.from.value" [max]="maxValue" [matDatepicker]="datePicker2"
                  (keydown)="disableDate()"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
              </div>
              <div *ngIf="(f.to.touched || f.to.dirty) && f.to.errors" class="invalid-feedback">
                <div *ngIf="f.to.errors">{{this.messageServ.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 overflow-hidden">
                <app-table-loader *ngIf="loaderTable"></app-table-loader>
                <div class="table-responsive" *ngIf="!loaderTable">
                  <table class="table table-striped form-table table-sm td-white-space">
                    <thead>
                      <tr>
                        <th scope="col" class="form-cell" width="1%">
                          <input class="form-check-input m-0" type="checkbox" value="" (click)="checkAll($event)"
                            [checked]="allCheck()">
                        </th>
                        <th class="fw-600 col-3" scope="col">DAYS</th>
                        <th class="fw-600 col-3 required" scope="col">shift</th>
                        <th class="fw-600 col-3" scope="col">Weekly off</th>
                      </tr>
                    </thead>
                    <tbody formArrayName="assign_shift_date" id="tBody">
                      <tr *ngFor="let shift of controls().controls; let $index = index" [formGroupName]="$index"
                        id="row{{$index+1}}">
                        <td class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" value=" " formControlName="checkedValue"
                            [checked]="" (click)='individualCheck($event,$index)'>
                        </td>
                        <td scope="col">{{shiftDayBetween[$index]['dateDisplayFormat']}}</td>
                        <td class="form-cell" scope="col" [id]="$index">
                          <ng-select class="form-ngselect"
                            placeholder="{{this.messageServ.selectplaceholddisplay('shift')}}"
                            appendTo=".side-panel-container" formControlName="shift" (change)="selectedFirst($index)"
                            [ngClass]="{ 'is-invalid': (this.controls().controls[$index].get('shift')?.touched) && this.controls().controls[$index].get('shift')?.errors}">
                            <ng-option *ngFor="let shift of shiftDropdown" [value]="shift.id">{{shift.shift_name+ "
                              ("+shift?.shift_code+')'}} ({{this.appService.timeFormatDisplay(shift?.shift_start_time)}}
                              - {{this.appService.timeFormatDisplay(shift?.shift_end_time)}})</ng-option>
                          </ng-select>
                          <div
                            *ngIf="(this.controls().controls[$index].get('shift')?.touched) && this.controls().controls[$index].get('shift')?.errors"
                            class="invalid-feedback">
                            <div *ngIf="this.controls().controls[$index].get('shift')?.errors">
                              {{this.messageServ.validationDisplay('required')}}</div>
                          </div>
                        </td>
                        <td class="form-cell" scope="col">
                          <ng-select class="form-ngselect"
                            placeholder="{{this.messageServ.selectplaceholddisplay('weekoff')}}"
                            appendTo=".side-panel-container" [items]="weekoffDropdown" bindLabel="name"
                            bindValue="value" formControlName="weekoff_day">
                            <!-- <ng-option *ngFor="let weekoff of weekoffDropdown"
                              [value]="weekoff.name">{{weekoff.name}}</ng-option> -->
                          </ng-select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        [disabled]="!shift_from_to_date.valid || init || submitted" (click)="saveData()">
        Save
      </button>
    </footer>
  </div>
</div>
<div class="modal  modal-alert {{ copyData == true ? 'show' : '' }}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="icon-info-circle text-warning"></i>
        <h4 class="modal-title">Copy shift to all dates?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">
          The shift you had selected will reflect to all the dates
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm fw-500 text-uppercase" (click)="copyData = false">
          Cancel
        </button>
        <button type="button" class="btn btn-sm fw-500 text-uppercase btn-primary" (click)="copyIt()">YES</button>
      </div>
    </div>
  </div>
</div>
<app-error-download [fileName]="'Assign Shift'" [successDataCount]="successCount" [downloadMsg]="errorLog"
  [failedData]="exportData" [modalHeading]="'Assign Shift'" (closePanel)="close()" [successMsg]="successMsg"
  [errorMsg]="errorMsg"> </app-error-download>
<ng-container *ngIf="showSidePanel">
  <app-non-mapped-empp [showSidePanel]="showSidePanel" [selectedList]="selectedList" [selectedName]="selectedName"
    (closePanel)="closePanel($event)" [searchNeeded]="'true'"></app-non-mapped-empp>
</ng-container>
