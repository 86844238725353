import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-inbox-submit-form',
  templateUrl: './inbox-submit-form.component.html',
  styleUrls: ['./inbox-submit-form.component.scss']
})
export class InboxSubmitFormComponent implements OnInit {
  @Input() requestitem: any;
  @Input() form: any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();



  constructor(private notificationService: NotificationService,private inboxservice: InboxService,public messageService : MessageService) { }

  ngOnInit(): void {
  }
  get f() {return  this.form.controls; }
  confirm(){
    var status: any;
    var flag = true;
    var type = ""
    if(this.requestitem?.request_type == "On Duty" )
      type = "On Duty Requests"
    else if(this.requestitem?.request_type == "Attendance Regularization" )
      type = "Attendance Regularization Requests"
    else if (this.requestitem?.request_type == "Check-in Request" )
      type = "Check-in Request"
    else 
      type = this.requestitem?.request_type
    for(let i = 0;i<this.requestitem?.requests.length;i++){
      if(this.requestitem?.requests[i].status == 'Pending'){

        this.notificationService.handleErrorNotification(type+" cannot be submitted in pending state.","Pending "+type);
        return;
      }
      if(flag == true && this.requestitem?.requests[i].status == 'Approved'){
        status = 'Approved';
        flag = false;
      }
      if(flag == true && this.requestitem?.requests[i].status == 'Rejected'){
        status = 'Rejected';
      }
    }
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form['controls'].comment.valid){
        this.inboxservice.updateInboxItem(this.requestitem?.id,this.form.value).subscribe((res)=>{
          if(res){
            this.successEmitter.emit(true);
          }
        })
    }
  }

  countSelected(){
    var delArray:any = [];
    this.requestitem?.requests?.forEach((item:any, value:any) => {
      if(item?.checked == true )
        delArray.push(value)
    });
    return delArray.length
  }

  approveReject(val:any){
    if(this.countSelected() == 0)
      return;
    this.requestitem?.requests?.forEach((item:any, value:any) => {
      if(item?.checked == true ){
      if(this.requestitem?.request_type == "On Duty" ){
        item.checked = false;
        this.inboxservice.updateOndutyItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
          this.requestitem.requests[value].status = val;
        });
      }
      else if(this.requestitem?.request_type == "Attendance Regularization"){
        item.checked = false;
        this.inboxservice.updateRegularizationItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
          this.requestitem.requests[value].status = val;
        });
      }
      else if(this.requestitem?.request_type == "Check-in Request"){
        item.checked = false;
        this.inboxservice.updateCheckIn(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
          this.requestitem.requests[value].status = val;
        });
      }
    }

    });

    this.notificationService.handleSuccessNotification("Updated Successfully","Success");
  }

}
