import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-asset-request-approval',
  templateUrl: './asset-request-approval.component.html',
  styleUrls: ['./asset-request-approval.component.scss']
})
export class AssetRequestApprovalComponent implements OnInit {

  @Input() requestitem: any;

  billLoader               = false;
  viewstatusPanel          = false;
  viewStatusData: any      = [];
  viewStatusLevelCnt: any;
  viewStatusmaxLevel: any;

  pageSize1 = 20;
  page1     = 1;

  pageSize2 = 20;
  page2     = 1;

  viewDetails   = false;
  viewData:any  = [];
  assetType:any = '';

  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  // test end

  constructor(
    private inboxservice: InboxService,
    private fb: FormBuilder,
    private investmentService: InvestmentDeclerationService,
    public messageService: MessageService,
    public appservice: AppService,
    private notificationService: NotificationService
  ) { }

  form = this.fb.group({
    read_status     : [true,Validators.required],
    approved_status : [false,Validators.required],
    comment         : ['', Validators.maxLength(500)],
  })

  ngOnInit(): void {
  }

  get f() {return  this.form.controls; }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,
      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  requestViewStatus(){
    this.viewstatusPanel  = true
    this.billLoader       = true;
     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }

  checkall(event:any){
    var newList  = this.requestitem?.requests?.new_assets_inbox;
    var begin    = (this.page1-1) * this.pageSize1 ;
    var end      = this.page1* this.pageSize1;
    var subArray = newList?.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkAllSelected(){
    var newList  = this.requestitem?.requests?.new_assets_inbox;
    var begin    = (this.page1-1) * this.pageSize1 ;
    var end      = this.page1* this.pageSize1;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
          return item.checked;

      });
    } else {
      return false;
    }
  }

  checkallReturn(event:any){
    var newList   = this.requestitem?.requests?.return_assets_inbox;
    var begin     = (this.page2-1) * this.pageSize2 ;
    var end       = this.page2* this.pageSize2;
    var subArray  = newList?.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      if(item?.status != 'Invalid' )
        item.checked = event.target.checked;
    })
  }

  checkAllSelectedReturn(){
    var newList  = this.requestitem?.requests?.return_assets_inbox;
    var begin    = (this.page2-1) * this.pageSize2 ;
    var end      = this.page2* this.pageSize2;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
          return item.checked;

      });
    } else {
      return false;
    }
  }

  countSelected(){
    if(this.requestitem?.requests?.new_assets_inbox != undefined && this.requestitem?.requests?.return_assets_inbox != undefined){
        var delArray:any               = [];
        var asset_request_product: any = [];
        asset_request_product          = [...this.requestitem?.requests?.new_assets_inbox,...this.requestitem?.requests?.return_assets_inbox];
        asset_request_product?.forEach((item:any, value:any) => {
          if(item?.checked == true )
            delArray.push(value);
        });
        return delArray?.length;
    }
  }

  approveReject(val:any){
    if(this.countSelected() == 0)
      return;
      this.requestitem?.requests?.new_assets_inbox?.forEach((item:any, value:any) => {
        if(item?.checked == true ){
          item.checked = false;
          this.inboxservice.updateAssetItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
            this.requestitem.requests.new_assets_inbox[value].status = val;
          });
        }
      });

      this.requestitem?.requests?.return_assets_inbox?.forEach((item:any, value:any) => {
        if(item?.checked == true ){
          item.checked = false;
          this.inboxservice.updateAssetItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
            this.requestitem.requests.return_assets_inbox[value].status = val;
          });
        }
      });
  }

  confirm(){
    var status: any;
    var flag = true;
    var type = "Asset Request";

    if(this.requestitem?.requests?.new_assets_inbox != undefined && this.requestitem?.requests?.return_assets_inbox != undefined){

      var asset_request_product: any = [];
      asset_request_product          = [...this.requestitem?.requests?.new_assets_inbox,...this.requestitem?.requests?.return_assets_inbox];

      for(let i = 0;i<asset_request_product?.length;i++){
        if(asset_request_product[i].status == 'Pending'){

          this.notificationService.handleErrorNotification(type+" cannot be submitted in pending state.","Pending "+type);
          return;
        }
        if(flag == true && asset_request_product[i].status == 'Approved'){
          status = 'Approved';
          flag = false;
        }
        if(flag == true && asset_request_product[i].status == 'Rejected'){
          status = 'Rejected';
        }
      }
      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }

      if(this.form['controls'].comment.valid){
          this.inboxservice.updateInboxItem(this.requestitem?.id,this.form.value).subscribe((res)=>{
            if(res){
              this.successEmitter.emit(true);
            }
          })
      }
    }
  }
}
