import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-cancel-request-modal',
  templateUrl: './cancel-request-modal.component.html',
  styleUrls: ['./cancel-request-modal.component.scss']
})

export class CancelRequestModalComponent implements OnInit {

  @Input() requestCancel: any;
  @Input() requestName: any;
  @Input() requestId: any;
  @Input() requestStatus:any;

  @Output() modal= new EventEmitter();

  saveClicked = false;

  form = this.fb.group({
    comment: ['', [Validators.required,Validators.maxLength(500)]],
  })

  constructor(
    private fb: FormBuilder,
    public messageService: MessageService,
    private investmentService:InvestmentDeclerationService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.form.controls['comment'].setValue('');
  }

  get f() {return  this.form.controls; }

  close(){
    this.form.patchValue({
      comment : ''
    })
    this.modal.emit({'show': false, 'action': 'close'});
  }

  submitRequestCancel(){
    if (this.form.invalid) {
      return;
    }

    this.saveClicked = true;

    this.investmentService.requestCancel({"request_id": this.requestId, 'request_name': this.requestName, "comment": this.form.controls['comment'].value}).subscribe((res: any) => {

      this.saveClicked   = false;

      this.modal.emit({'show': false, 'action': 'submit'});

      if(this.requestStatus == 'Pending')
        this.notificationService.handleSuccessNotification("Your cancellation request for the pending request has been successfully processed.","Success");
      else
      this.notificationService.handleSuccessNotification("Your cancellation request for the approved request has been successfully added.","Success");

    },(error)=>{
      this.saveClicked    = false;
      this.modal.emit({'show': false, 'action': 'error'});
    });
  }

}
