<div class=" config-layout" [formGroup]="leaverequestForm">
  <header class=" config-header">
    <h5 class="m-0">Leave Request</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar ">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success':leaverequestForm['controls'].leaverequest.valid}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Leave Request</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-lr-leave-details [formGp]="leaveDetialsForm" (submitmethod)="submitOne($event)"
            (validationError)="validationError($event)" (validationResult)="validationResult($event)"
            (validationPortionResult)="validationPortionResult($event)"
            (overtimeCompoffError)="overtimeCompoffError($event)"></app-lr-leave-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Summary</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-lr-leave-summary [formGp]="leaveDetialsForm" [validationData]="validationData"
            [validationPortionData]="validationPortionData" [totalLeaveDays]="totalLeaveDays"
            (submitmethod)="submitForm($event)"></app-lr-leave-summary>
        </ng-template>
      </li>

    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>

<!-- test start -->

<app-alert-toggle *ngIf="invalidPass" (modals)="invalidPass=false" [alertToggle]="invalidPass"
  [alertMsg]="errorPass"></app-alert-toggle>
<!-- test end -->
