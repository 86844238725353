<div class="modal   {{verificationModal  ? 'show':''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width:37.5rem">
    <div class="modal-content radius-24">
      <div class="modal-header border-0 p-24">
        <h5 class="modal-title ">
          <i class="icon-arrow-repeat text-success me-16"></i>Verification
        </h5>
        <button type="button" (click)="this.close.emit()" class="btn-close shadow-0" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body px-24 py-0 text-center">
        <div class="row row-24">
          <div class="col-12" *ngIf="!failedmodal">
            <div class="hstack justify-content-between mx-auto vetification-progress {{VerifyClass}}">
              <div *ngIf="snapshotService.getCurrentUserPic()!= null && snapshotService.getCurrentUserPic()!=''"
                class="sq-72 radius-16 flex-center bg-darkgrey">
                <img [src]="snapshotService.getCurrentUserPic()" alt="" class="avatar-circle avatar-border sq-56">
              </div>

              <div *ngIf="snapshotService.getCurrentUserPic()== null || snapshotService.getCurrentUserPic()==''"
                class="sq-72 radius-16 flex-center bg-darkgrey">
                <div class="avatar-sm sq-56 avatar-circle {{this.snapshotService.getCurrentUserColor()}}">
                  {{this.appService.getFirstChar(snapshotService.FULLNAME,2)}}
                </div>
              </div>

              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              
              <div class="sq-72 radius-16 flex-center bg-darkgrey">
                <img [src]="WebCamDataUrl" alt="" class="avatar-circle avatar-border sq-56">
              </div>
            </div>
          </div>
          <div class="col-12">
            <p class="mb-0 fw-500">{{VerifyText}}</p>
          </div>
        </div>
      </div>

      <div class="modal-footer border-0 py-24 px-32">
        <button *ngIf="!retake" class="btn btn-primary text-uppercase fs-14 my-0 mx-auto btn-sm radius-2"
          (click)="verificationModal = false">OK</button>
        <button *ngIf="retake" class="btn btn-primary text-uppercase fs-14 my-0 mx-auto btn-sm radius-2"
          (click)="this.retakeFn.emit()">Retake</button>
      </div>
    </div>
  </div>
</div>