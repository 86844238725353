import { Component, OnInit ,Input,Output,EventEmitter,SimpleChanges} from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MyAttendenceCalenderService } from 'src/app/@core/services/my-attendence-calender.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-attendance-table',
  templateUrl: './attendance-table.component.html',
  styleUrls: ['./attendance-table.component.scss']
})
export class AttendanceTableComponent implements OnInit {

  @Input()from:any;
  @Input() options:any
  @Input() isEmployeeId:any;
  @Input() activeMonth:any;
  @Input() activeYear:any;
  @Input() empDoj:any;
  @Output() gotoAction      =  new EventEmitter();

  tableData = []
  badge:any = []
  page      = 1;
  pageSize  = 20;
  loader    = true;
  today:any =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()

  constructor(
    public appservice: AppService,
    private attendenceCalenderService:MyAttendenceCalenderService,
    private datePipe:DatePipe
    ) {
    this.badge["Leave"]   = "badge-warning"
    this.badge["Absent"]  = "badge-danger"
    this.badge["Present"] = "badge-success"
    this.badge["Holiday"] = "badge-holiday"
    this.badge["Weekoff"] = "badge-weekoff"
   }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {
    this.getTableData();

  }
  getTableData(){
    this.loader = true;
    this.attendenceCalenderService.getCalendarData(this.isEmployeeId,this.activeMonth,this.activeYear).subscribe((res:any)=>{
      this.tableData = res.ouput_data
      this.tableData?.forEach( (value:any, key:any)=>{
        if(this.empDoj>value.date){
          value['attendance_class'] = ""
          value['attendance_txt']   = ""
        }
        else if(value.holiday)
        {
          value['attendance_class'] = "badge-holiday"
          value['attendance_txt']   = "Holiday"
        }
        else if(value.shift.from_time == 0){

          value['attendance_class'] = "badge-shift-not-assigned"
          value['attendance_txt']   = "Shift not assigned"
        }
        // else if(value.date>this.today){

        //   value['attendance_class'] = "status-upcoming"
        //   value['attendance_txt']   = "Upcoming"

        // }
        else if(value.date<this.today){

          value['attendance_class'] = "badge-danger"
          value['attendance_txt']   = "Absent"

        }
      })
      this.loader    = false
    })
  }
}
