<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="ytdSalaryForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-16 mb-16">
                  <div class="col-12 form-row">
                      <p class="text-uppercase required fw-500 mb-8">Financial year
                      </p>
                  </div>
                      <div class="col-12 form-row" >
                          <!-- <label for="" class="form-label ">Financial Year </label> -->
                          <ng-select class="form-ngselect " (change)="yearChange($event)" [ngClass]="{ 'is-invalid': (submitted || f.financial_year.dirty || f.financial_year.touched) && f.financial_year.errors}" placeholder="{{this.messageService.selectplaceholddisplay('year')}}"
                              formControlName="financial_year" name="financial_year" id="financial_year">
                              <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year"> {{year +'-'+
                                  financialYearRange(year)}} </ng-option>

                          </ng-select>
                          <div *ngIf="(submitted || f.financial_year.dirty || f.financial_year.touched) && f.financial_year.errors" class="invalid-feedback">
                            <div *ngIf="f.financial_year.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                          </div>
                      </div>
              </div>
              <div class="row row-16 mb-16">
                  <div class="col-12">
                      <label for="name" class="form-label ">Pay group</label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Pay group')}}"
                          formControlName="pay_group" [items]="payGroupDD" [multiple]="true"
                          groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                          bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect">
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{standalone: true}" /> Select All
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline-flex align-items-center">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                      [ngModelOptions]="{ standalone : true }" class="me-8" />
                                  {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                              </div>
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngIf="ytdSalaryForm.value.pay_group?.length ">
                                  <span class="ng-value-label">{{ytdSalaryForm.value.pay_group?.length }}
                                      pay group
                                      selected.</span>
                              </div>
                          </ng-template>
                      </ng-select>
                      <ul class="selected-values mt-16" *ngIf="ytdSalaryForm.value.pay_group?.length != 0">
                          <li class="ng-value" *ngFor="let item of ytdSalaryForm.value.pay_group,let ki =index">
                              <ng-container *ngFor="let insideitem of payGroupDD, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                      {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                      slice:0:15)+'..':(insideitem['name']) }}</span>
                              </ng-container>
                              <span class="ng-value-icon right"
                                  (click)="clearcommonForm('pay_group',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                  </div>
              </div>
            <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
          </div>
          <!-- test end -->
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class=" btn btn-outline-primary text-uppercase btn-sm"
              (click)="resetPage();resetFilterFunction()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitYtd()">
              Apply
          </button>
      </footer>
  </form>
</div>
