import { Component, OnInit, Inject } from '@angular/core';
import { MyTeamDashboardService } from 'src/app/@core/services/my-team-dashboard.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ChangeDetectorRef } from '@angular/core';
import { KeyValue } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-my-team-dashboard',
  templateUrl: './my-team-dashboard.component.html',
  styleUrls: ['./my-team-dashboard.component.scss']
})

export class MyTeamDashboardComponent implements OnInit {

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private myTeamDashboardService: MyTeamDashboardService,
    public appservice: AppService,
    public messageService: MessageService,
    private cdref: ChangeDetectorRef,
    public router: Router,
  ) {
      this.myTeamDashboardService.emp_at_work.subscribe(result =>{
        if(this.appservice.dateFormatConvert(this.employeeAtWorkData.date) == this.appservice.dateFormatConvert(result.event.date)){
          this.employeeAtWorkData = result.event;
          this.employeeAtWorkCount = result.event?.subheaderlist; 
        }
      })
  }

  activeTab = 1;
  hierarchy = false;
  leaderBoard = false;

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Month';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Rupees';
  animations: boolean = true;
  legendPosition: any = 'below';
  legendTitle: string = '';
  barPadding: number = 40;
  movetoPoint = 0;
  activeItem: any = [];
  year: any = false;
  month: any = false;

  attendanceColor: any = ['#4CBBBB', '#0078CE', '#0078CE66', '#AF8CD1', '#FFA80099', '#00972299', '#0078CE99', '#6166DB']


  permissions: any = [];

  // employeeAtWorkDate: any = this.appservice.dateFormatConvert(new Date());
  employeeAtWorkData: any = [];
  employeeAtWorkCount: any = [];
  employeeAtWorkLoader = false;

  teamUpdateData: any = [];
  teamUpdateLoader = false;

  myTeamData: any = [];
  myTeamLoader = false;
  myTeamReportingData: any = [];
  myTeamFunctionalData: any = [];

  leaderboardData: any = [];
  leaderboardLoader = false;
  leadboardFilter: any = 'Takes Less Leaves';
  leaderboardDataSeeAll: any = [];
  leadboardFilterSeeAll: any = '';
  leaderboardLoaderSeeAll = false;
  leaderboardDataFirst: any = [];
  leaderboardDataSecond: any = [];
  leaderboardDataThird: any = [];


  punchSourceData: any = [];
  punchSourceChartData: any = [];
  punchSourceLabels: any = [];
  punchSourceLoader = false;
  punchSourceListOfMonth: string = "listOfMonth";
  punchSourceCurrentMonth: any = new Date().toLocaleString('default', { month: 'long' });
  punchSourceCurrentYear: any = new Date().getFullYear();

  workforceStatesData: any = [];
  workForceStatesLoader = false;
  workforceListOfMonth: string = "listOfMonth";
  workforceCurrentMonth: any = new Date().toLocaleString('default', { month: 'long' });
  workforceCurrentYear: any = new Date().getFullYear();


  timeOffData: any = [];
  timeOffLoader = false;
  timeOffDropdown: any = [7, 15, 30];
  timeOffSelectedData: any = 7;

  sideViewDataList: any = [];
  sideViewDataLoader = false;
  sideViewHeading: any = '';
  sideViewPanel = false;

  listYears: any = this.appservice.generateArrayOfYears();
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  leaderboardFilter:any = 'Takes Less Leaves';

  ngOnInit(): void {

    this.getPermission();

    this.getDateFormat();

    this.getEmployeeAtWork(new Date());
    this.getTeamUpdates();
    this.getMyTeam();
    // this.getLeaderboard('Takes Less Leaves', 'board');
    // this.getPunchSource();
    this.getWorkforceStates();
    this.getTimeOff();

    this.myTeamDashboardService.myTeamempAtWorkSocket();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }


  getPermission() {
    this.permissions = this.appservice.getPermissions('');
    if (this.permissions == undefined) {
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getEmployeeAtWork(date:any) {
    this.employeeAtWorkLoader = true;
    this.myTeamDashboardService.getEmployeeAtWork(this.appservice.dateFormatConvert(date)).subscribe((res: any) => {
      this.employeeAtWorkData = res?.data;
      this.employeeAtWorkCount = res?.data?.subheaderlist;
      this.employeeAtWorkLoader = false;
    })
  }

  getTeamUpdates() {
    this.teamUpdateLoader = true;
    this.myTeamDashboardService.getTeamUpdates().subscribe((res: any) => {
      this.teamUpdateData = res?.data;
      this.teamUpdateLoader = false;
    })
  }

  getMyTeam() {
    this.myTeamLoader = true;
    this.myTeamDashboardService.getMyTeam().subscribe((res: any) => {
      this.myTeamData = res?.data;
      this.myTeamReportingData = res?.data?.reportingemployeesdata;
      this.myTeamFunctionalData = res?.data?.functionalemployeesdata;
      this.myTeamLoader = false;
    })

  }

  getLeaderboard(filter: any, from: any) {

    this.leaderboardFilter = filter;

    if (from == 'board') {
      this.leaderboardDataFirst = [];
      this.leaderboardDataSecond = [];
      this.leaderboardDataThird = [];
      this.leaderboardLoader = true;
    } else {
      this.leaderboardLoaderSeeAll = true;
    }

    this.myTeamDashboardService.getLeaderboard(filter,this.workforceCurrentMonth, this.workforceCurrentYear).subscribe((res: any) => {
      if (from == 'board') {
        this.leaderboardData = res;
        let topList: any = [];
        topList = res?.toplist;
        if (topList?.length > 0) {
          if (topList?.[0]?.['1st']?.length > 0) {
            for (let i = 0; i < topList?.[0]?.['1st']?.length; i++) {
              this.leaderboardDataFirst.push(topList?.[0]?.['1st']?.[i]?.first_name + " " +
                ((topList?.[0]?.['1st']?.[i]?.middle_name != null && topList?.[0]?.['1st']?.[i]?.middle_name != '') ? topList?.[0]?.['1st']?.[i]?.middle_name :
                  "") + " " + topList?.[0]?.['1st']?.[i]?.last_name)
            }
          }
          if (topList?.[0]?.['2nd']?.length > 0) {
            for (let i = 0; i < topList?.[0]?.['2nd']?.length; i++) {
              this.leaderboardDataSecond.push(topList?.[0]?.['2nd']?.[i]?.first_name + " " +
                ((topList?.[0]?.['2nd']?.[i]?.middle_name != null && topList?.[0]?.['2nd']?.[i]?.middle_name != '') ? topList?.[0]?.['2nd']?.[i]?.middle_name :
                  "") + " " + topList?.[0]?.['2nd']?.[i]?.last_name)
            }
          }
          if (topList?.[0]?.['3rd']?.length > 0) {
            for (let i = 0; i < topList?.[0]?.['3rd']?.length; i++) {
              this.leaderboardDataThird.push(topList?.[0]?.['3rd']?.[i]?.first_name + " " +
                ((topList?.[0]?.['3rd']?.[i]?.middle_name != null && topList?.[0]?.['3rd']?.[i]?.middle_name != '') ? topList?.[0]?.['3rd']?.[i]?.middle_name :
                  "") + " " + topList?.[0]?.['3rd']?.[i]?.last_name)
            }
          }
        }
        this.leaderboardLoader = false;
      } else {
        this.leaderboardDataSeeAll = res?.data;
        this.leaderboardLoaderSeeAll = false;
      }

    })
  }

  getSeAllFunction() {
    this.leaderBoard = true;
    this.leadboardFilterSeeAll = this.leadboardFilter;
    this.leaderboardDataSeeAll = this.leaderboardData?.data;
  }

  subEmp(num: number) {
    return JSON.stringify(num - 2);
  }

  getPunchSource() {

    this.punchSourceData = [];
    this.punchSourceLabels = [];
    this.punchSourceChartData = [];
    this.punchSourceLoader = true;
    this.myTeamDashboardService.getPunchSource(this.workforceCurrentMonth, this.workforceCurrentYear).subscribe((res: any) => {
      this.punchSourceData = res;
      res?.data?.forEach((element: any) => {
        this.punchSourceLabels.push(element.name)
        this.punchSourceChartData.push(element.value)
      });
      this.punchSourceLoader = false;
    })
  }

  getWorkforceStates() {
    this.getPunchSource();
    this.getLeaderboard(this.leaderboardFilter,'board');
    this.workForceStatesLoader = true;
    this.myTeamDashboardService.getWorkforceStates(this.workforceCurrentMonth, this.workforceCurrentYear).subscribe((res: any) => {
      this.workforceStatesData = res;
      this.workForceStatesLoader = false;
    })
  }

  getTimeOff() {
    this.timeOffLoader = true;
    this.myTeamDashboardService.getTimeOff(Number(this.timeOffSelectedData)).subscribe((res: any) => {
      this.timeOffData = res;
      this.timeOffLoader = false;
    })
  }

  sidePanelView(item: any) {
    this.sideViewDataLoader = true;
    this.sideViewDataList = [];
    this.sideViewHeading = '';
    this.sideViewPanel = true;
    if (item == 'ON DUTY') {

      this.sideViewHeading = 'On Duty';
      this.sideViewDataList = this?.employeeAtWorkData?.ondutydata;
      this.sideViewDataLoader = false;
    } else if (item == 'WFH') {

      this.sideViewHeading = 'WFH';
      this.sideViewDataList = this?.employeeAtWorkData?.wfhdata;
      this.sideViewDataLoader = false;
    } else if (item == 'LATE') {

      this.sideViewHeading = 'Late';
      this.sideViewDataList = this?.employeeAtWorkData?.latecomingdata;
      this.sideViewDataLoader = false;
    } else if (item == 'ON LEAVE') {

      this.sideViewHeading = 'On Leave';
      this.sideViewDataList = this?.employeeAtWorkData?.onleavedata;
      this.sideViewDataLoader = false;
    } else if (item == 'NOT IN') {

      this.sideViewHeading = 'Not In';
      this.sideViewDataList = this?.employeeAtWorkData?.notindata;
      this.sideViewDataLoader = false;
    }
  }

  joiningThisMonthSidePanelView(){
    this.sideViewPanel    = true;
    this.sideViewDataList = this?.teamUpdateData?.joiningmonthemployeesdata;
    this.sideViewHeading  = 'Joining This Month';
  }

  leaveingThisMonthSidePanelView(){
    this.sideViewPanel    = true;
    this.sideViewDataList = this?.teamUpdateData?.leavingmonthemployeesdata;
    this.sideViewHeading  = 'Leaving This Month';
  }

  getDateFormat() {
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if (this.matDateFormat.display.dateInput == '') {
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  dateFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 5, 0, 1),
    new Date(new Date().getFullYear() + 1, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  disableDate() {
    return false;
  }

  closePanel(val: any) {
    this.sideViewPanel = val
  }
  getList(key:any){
    return this.leaderboardDataSeeAll?.[0]?.[key]
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  myTeamRouting(empId:any){
    this.router.navigate(['my-team-dashboard/my-team-employee-details/'+empId]);
  }

  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

}
