<div class="container-fluid p-24" style="min-height: calc(100vh - 10rem);">
  <div class="row row-16">
    <div class="col-md-4 col-lg-6">
      <h3>Invite Employees</h3>
      <p class="text-helper mb-md-0">Manage invite links for portal access of new employees</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon2" style="width:22.6875rem;">
          <i class="icon-search icon-left fs-14"></i>
          <div class="btn-close icon-right pointer fs-12" (click)="searchString=''"
            *ngIf="(searchString != '' && searchString != null)"></div>
          <input type="text" class="form-control fs-14" name="" placeholder="{{this.messageService.searchdisplay('')}}"
            [(ngModel)]="searchString" (keyup)="page=1;" id="">
        </div>
        <button class="btn-square btn btn-secondary" placement="bottom-left" (click)="filterpanel=true">
          <i class="icon-filter-left fs-13"></i>
        </button>
      </div>
    </div>
    <app-table-loader *ngIf="loader"></app-table-loader>
    <ng-container *ngIf="!loader">
          <div class="col-12"  *ngIf="filterIt().length<=0">
            <div class="row " style="height: calc(100vh - 14rem);">
              <app-nodata-view class="vstack" [noDataImg]="'bg9.png'" [imgHeight]="'16rem'"
               [noDataText]="'Let\'s Get Started!'"
              [noDataPara]="'Add employees to start sending invites and generating passwords.'"
                [hasPermission]="false"> </app-nodata-view>
            </div>
          </div>
      <div class="col-12" *ngIf="filterIt().length>0">
        <div class="card card-c2 p-0">
          <div class="table-responsive  scrollbar-10 radius-4" style="max-height: calc(100vh - 21rem);">
            <table class="table sticky-header table-striped table-sm td-white-space td-width-15ch ">
              <thead>
                <tr>
                  <th class="form-cell td-checkbox-16" *ngIf="invitePermission?.e">
                    <input class="form-check-input checkbox-16 m-0" type="checkbox" value="" (click)="checkAll($event)"
                      [checked]="pageChange()">
                  </th>
                  <th class="fw-600" scope="col">Employee CODE</th>
                  <th class="fw-600" scope="col">Employee name</th>
                  <th class="fw-600" scope="col">BUSINESS UNIT</th>
                  <th class="fw-600" scope="col">DEPARTMENT</th>
                  <th class="fw-600" scope="col">DESIGNATION</th>
                  <th class="fw-600" scope="col">WORK EMAIL</th>
                  <th class="fw-600" scope="col">MObile number</th>
                  <th class="fw-600" scope="col">Date of Joining</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr *ngIf="filterIt().length<=0">
                  <td colspan="9" class="bg-white">
                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                      <span class="fw-500 fs-14">No data found</span>
                    </div>
                  </td>
                </tr> -->
                <tr
                  *ngFor="let i of $any(inviteData| filter:searchString | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)">
                  <th class="form-cell td-checkbox-16" *ngIf="invitePermission?.e">
                    <input class="form-check-input checkbox-16 m-0" type="checkbox" [(ngModel)]="i.checked"
                      (change)="individualData($event,i.id)">
                  </th>
                  <td data-toggle="tooltip" title="{{i.employee_code}}">{{i.employee_code}}</td>
                  <td data-toggle="tooltip" title="{{i.employee_name}}">{{i.employee_name}}</td>
                  <td data-toggle="tooltip" title="{{i.businessunit_name}}">{{i.businessunit_name}}</td>
                  <td data-toggle="tooltip" title="{{i.department_name}}">{{i.department_name}}</td>
                  <td data-toggle="tooltip" title="{{i.designation_name}}">{{i.designation_name}}</td>
                  <td data-toggle="tooltip" title="{{i.work_email}}">{{i.work_email}}</td>
                  <td data-toggle="tooltip" title="{{i.mobile_number}}">{{i.mobile_number}}</td>
                  <td data-toggle="tooltip" title="{{this.appService.dateFormatDisplay(i.date_of_joined)}}">
                    {{this.appService.dateFormatDisplay(i.date_of_joined)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="this.appService.filteredcount(inviteData,searchString)>pageSize">
        <ngb-pagination class="d-flex justify-content-end" [(page)]="page"
          [collectionSize]="this.appService.filteredcount(inviteData,searchString)" [maxSize]="3" [rotate]="true"
          [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
      </div>
    </ng-container>
  </div>
</div>
<footer class="side-panel-footer radius-0 mt-auto px-24 gap-16 justify-content-end position-sticky bottom-0"
  *ngIf="invitePermission?.e">
  <p class="text-light-400 fs-14 text-center mb-0" *ngIf="getWholeData().length !== 0 && !loader">{{count}} Employees
    selected</p>
  <button type="button" class="btn text-uppercase btn-sm btn btn-outline-danger"
    *ngIf="getWholeData().length !== 0 && !loader" (click)="cancelSelect()" [disabled]="objLength.length ==0">Cancel
    Selection</button>
  <button type="button" class="btn text-uppercase btn-sm btn btn-outline-primary" (click)="generatePassWord()"
    [disabled]="getWholeData().length ==0 || !canGenerate || loader">Generate & View</button>
  <button type="button" class="btn text-uppercase btn-sm btn btn-primary" (click)="sendPassWord()"
    [disabled]="getWholeData().length ==0 || !canSave || loader">Send password</button>
</footer>
<app-error-download [fileName]="'Invite Employees Error'" [successDataCount]="datas.length-failedData.length"
  [downloadMsg]="errorLog" [failedData]="exportData" [modalHeading]="'Send to New User'" (closePanel)="close()"
  [successMsg]="successMsg" [errorMsg]="errorMsg"> </app-error-download>
  <app-common-employee-filters *ngIf='filterpanel === true' [filterForm]="filterForm" [from]="from" [filter]="filterpanel"
  (filterClose)="closefn($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
<div class="modal  modal-alert {{gnrtdPwddPop == true ? 'show': ''}}">
  <div class="modal-dialog modal-dialog-centered " style="--bs-modal-width:45.4375rem">
    <div class="modal-content pb-30">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Generate Password</h4>
        <button type="button" class="btn-close ms-auto m-0" (click)="closePwdList();"></button>
      </div>
      <div class="modal-body overflow-auto" style="max-height: 30rem;">
        <div class="row row-16">
          <div class="col-12" *ngFor="let i of gnrtdPwdList">
            <div class="card card-c2 flex-row py-16">
              <div class="  d-flex align-items-center px-16 gap-16 flex-shrink-0" style="width:16rem">
                <div class="avatar-circle sq-48 fw-500 {{i.employee_data.color_code}}"
                  *ngIf="i.employee_data.profile_image ==  '' || i.employee_data.profile_image ==  null">
                  {{this.appService.getFirstChar(i.employee_data.first_name+"
                  "+i.employee_data.last_name,2)}}</div>
                <img class="avatar-sm sq-48 img-fluid rounded-circle me-1"
                  *ngIf="i.employee_data.profile_image !=  '' && i.employee_data.profile_image !=  null"
                  [src]="i.employee_data.profile_image">
                <div class="overflow-hidden  d-flex flex-column">
                  <span class="fs-14  pb-1 text-trim">{{i.employee_data.first_name+"
                    "+(i.employee_data.middle_name!=null?i.employee_data.middle_name+" ":" " )+i.employee_data.last_name
                    }}</span>
                  <span
                    class="fs-10 fw-500 text-light-400 text-trim text-uppercase ">{{i.employee_data.employee_code}}</span>
                </div>
              </div>
              <div class="vr flex-shrink-0"></div>
              <div class="px-16 w-100">
                <label class="form-label">Password</label>
                <div class="d-flex gap-16">
                  <input type="text" class="form-control" disabled value="{{i.new_password}}" #sharableLink>
                  <button ngbPopover="Copied" popoverClass="popover-input popover-info" placement="top" triggers="click"
                    type="button" class="btn btn-primary fs-20 btn-square " (click)="clipboard.copy(i.new_password)"><i
                      class="icon-copy"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
