
        <div class="col-12">
            <div class="card card-c2 p-0">
                <app-table-loader *ngIf="loader"></app-table-loader>
                <div  *ngIf="!loader"  class="table-responsive radius-4">
                    <table  *ngIf='status=="PENDING"' class="table td-12 th-12 sticky-header td-white-space td-fs-14
                        tr-fs-14 table-striped table-sm form-table">
                        <thead>
                            <tr>
                                <th scope="col" class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value=""   (click)="checkAll($event)" [checked]="checkAllSelected()">
                                </th>
                                <th class="fw-600" scope="col">EMP CODE</th>
                                <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                                <th class="fw-600" scope="col">GROSS PAY</th>
                                <th class="fw-600" scope="col">EMPLOYEE ESI </th>
                                <th class="fw-600" scope="col">OVERRIDE EMPLOYEE ESI </th>
                                <th class="fw-600" scope="col">EMPLOYER ESI </th>
                                <th class="fw-600" scope="col">OVERRIDE EMPLOYER ESI </th>
                                <th class="fw-600" scope="col">COMMENT</th>
                                <th class="fw-600" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody class="cell-16 height-full">
                            <ng-container *ngIf="data.length==0 ">
                                <tr>
                                  <td colspan="10">

                                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                                      <span class="fw-500 fs-14">No data found</span>
                                    </div>
                                  </td>
                                </tr>
                            </ng-container>
                            <tr *ngFor=" let item of $any(data|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                <td class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked">
                                </td>

                                <td>
                                    <div class="{{checkifNoInputError(data.indexOf(item)) && item?.error?'cell-error is-invalid':''}}">
                                        {{item?.employee_code}}
                                        </div>
                                        <div class="invalid-feedback"  *ngIf=" checkifNoInputError(data.indexOf(item)) && item?.error">{{item?.error_description}}
                                        </div>
                                </td>
                                <td class="form-cell">
                                    <ng-select appendTo="body" placeholder="{{this.messageService.selectplaceholddisplay('Employee')}}" [(ngModel)]="item.employee_id" [clearable]="false" (change)="setEmpCode($event,data.indexOf(item))">
                                        <ng-container *ngFor="let emp of employeeList;let i=index">
                                            <ng-container *ngIf="!selctedData.includes(emp.id) || item.employee_id==emp.id ">
                                            <ng-option  [value]="emp.id" >
                                                <span class="ng-value-label">
                                                <div class="d-inline-flex align-items-center">
                                                    <div *ngIf="emp.profile_image ==  '' || emp.profile_image ==  null" class="avatar-sm sq-24 rounded-circle {{emp?.color_code}} me-1" ><span>{{this.appService.getFirstChar(emp.fullname,2)}}</span></div>
                                                    <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="emp.profile_image !=  '' && emp.profile_image !=  null" [src]="emp.profile_image" >
                                                        {{emp.first_name+" "+(emp.middle_name!=null?emp.middle_name+" ":" ")+emp.last_name +" ("+emp.employee_code+")"}}
                                                    </div>
                                                </span>
                                                </ng-option>
                                            </ng-container>
                                        </ng-container>
                                        </ng-select>
                                </td>
                                <td class="form-cell">
                                    {{item?.gross_pay | currency: item?.currency}}
                                </td>
                                <td >{{item?.employee_regular | currency: item?.currency}}</td>
                                <td  class="form-cell">
                                    <input placeholder="{{this.messageService.placeholderdisp('Override Employee ESI')}}" type="text" class="form-control {{(item.inputerror?.upload_employee_amount?.is_valid==false)?'is-invalid':''}}" [(ngModel)]="item.upload_employee_amount"  (keyup)="validateRow(data.indexOf(item),'upload_employee_amount')" (focusout)="focusOutfield(data.indexOf(item),'upload_employee_amount')">
                                    <div *ngIf="(item.inputerror?.upload_employee_amount?.is_valid==false)" class="invalid-feedback">
                                        <div>{{item.inputerror?.upload_employee_amount?.error}}</div>
                                    </div>
                                </td>
                                <td >{{item?.employer_regular | currency: item?.currency}}</td>
                                <td  class="form-cell">
                                    <input  placeholder="{{this.messageService.placeholderdisp('Override Employer ESI')}}"  type="text" class="form-control {{(item.inputerror?.upload_employer_amount?.is_valid==false)?'is-invalid':''}}"  [(ngModel)]="item.upload_employer_amount"  (keyup)="validateRow(data.indexOf(item),'upload_employer_amount')"  (focusout)="focusOutfield(data.indexOf(item),'upload_employer_amount')">
                                    <div *ngIf="(item.inputerror?.upload_employer_amount?.is_valid==false)" class="invalid-feedback">
                                        <div>{{item.inputerror?.upload_employer_amount?.error}}</div>
                                    </div>
                                </td>
                                <td><input placeholder="{{this.messageService.placeholderdisp('Comment')}}"  type="text" class="form-control {{(item.inputerror?.comment?.is_valid==false)?'is-invalid':''}}" [(ngModel)]="item.comment"  (keyup)="validateRow(data.indexOf(item),'comment')" >
                                    <div *ngIf="(item.inputerror?.comment?.is_valid==false)" class="invalid-feedback">
                                        <div>{{item.inputerror?.comment?.error}}</div>
                                    </div>
                                </td>
                                <td> <i class="icon-trash" (click)="deleteRow(data.indexOf(item))"></i> </td>
                            </tr>
                        </tbody>
                    </table>
                    <table  *ngIf='status=="COMPLETED"' class="table td-12 th-12 sticky-header td-white-space td-fs-14
                    tr-fs-14 table-striped table-sm form-table">
                    <thead>
                        <tr>
                            <th scope="col" class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox" value=""   (click)="checkAll($event)" [checked]="checkAllSelected()">
                                    </th>
                            <th class="fw-600" scope="col">EMP CODE</th>
                            <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                            <th class="fw-600" scope="col">GROSS PAY</th>
                            <th class="fw-600" scope="col">EMPLOYEE ESI </th>
                            <th class="fw-600" scope="col">OVERRIDE EMPLOYEE ESI </th>
                            <th class="fw-600" scope="col">EMPLOYER ESI </th>
                            <th class="fw-600" scope="col">OVERRIDE EMPLOYER ESI </th>
                            <th class="fw-600" scope="col">COMMENT</th>
                            <th class="fw-600" scope="col">SALARY STATUS</th>

                            <!-- <th class="fw-600" scope="col"></th> -->
                        </tr>
                    </thead>
                    <tbody class="cell-16 height-full">
                        <ng-container *ngIf="HistoryData.length==0 ">
                            <tr>
                              <td colspan="10">

                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                  <span class="fw-500 fs-14">No data found</span>
                                </div>
                              </td>
                            </tr>
                        </ng-container>
                        <tr *ngFor=" let item of $any(HistoryData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                            <td class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked">
                                    </td>

                                <td>{{item?.employee_code}}</td>
                                <td class="form-cell">{{item?.employee_name}} </td>
                                <td class="form-cell">{{item?.gross_pay | currency: item?.currency}} </td>
                                <td >{{item?.employee_regular | currency: item?.currency}}</td>
                                <td >{{item?.upload_employee_amount | currency: item?.currency}}</td>

                                <td >{{item?.employer_regular | currency: item?.currency}}</td>
                                <td >{{item?.upload_employer_amount | currency: item?.currency}}</td>
                                <td>{{item?.comment=== null || item?.comment=== "" ? "-" : item?.comment}}</td>
                                <td>
                                    <span *ngIf="item.salary_status=='Publish'"  class="badge  badge-success py-1">Published</span>
                                    <span *ngIf="item.salary_status=='Processed'"  class="badge badge-warning py-1">Processed</span>
                                    <span *ngIf="item.salary_status=='Hold'"  class="badge badge-primary py-1">Held</span>
                                    <span *ngIf="item.salary_status=='Freeze'"  class="badge badge-holiday py-1">Freezed</span>
                                    <span *ngIf="item.salary_status=='Unprocessed'"  class="badge badge-danger py-1">Unprocessed</span>
                                </td>
                                <!-- <td> <i class="icon-trash" (click)="deleteCompletedRow(item.id)"></i> </td> -->
                            </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
