<div class="container-fluid p-24 "  *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Expense Category</h3>
            <p class="text-helper mb-md-0">Create different expense categories as per your company's requirement.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div class="form-icon " style="width:22.6875rem;">
                    <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='catPlaceholder' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchexpense($event)" [isLoading]="isLoading"></app-searchbar>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13rem"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                      <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';statusfilter('')">All</button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';statusfilter(true)">Active </button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
                    </div>
                </div>
                <button  class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a" (click)="editDetail=true;dataId=0;"><i
                    class="icon-plus"></i>ADD Expense Category</button>
            </div>
        </div>
    </div>
    <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg6.png'" [noDataText]="'Categorize Expenses!'"
        [noDataPara]="'It looks like there are no expense categories configured yet. Click \'Add Expense Category\' Keep everything tidy and easy to manage.'"
        [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
    </div>
    <div class="row row-16" *ngIf="loader">
     <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
    <ng-template #all_data>
      <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
        <ng-container *ngIf="loader==false">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let category_data of expcategoryData;let i=index">
                <div class="card card-custom1 card-hover">
                    <div class="card-body p-0 d-flex align-items-start">
                        <div class="d-flex view-section" (click)="viewdata(category_data.id)">
                            <div class="logo-img {{category_data.color_code}}">{{this.appService.getFirstChar(category_data.name,2)}} </div>
                            <div class="overflow-hidden">
                                <h6 class="mb-1 tips">{{category_data.name}}</h6>
                                <span class="tips-helper">{{category_data.name}}</span>
                                <div class="fs-10 text-uppercase fw-500  text-success " *ngIf="category_data.is_active">Active
                                </div>
                                <div class="fs-10 text-uppercase fw-500  text-danger " *ngIf="category_data.is_active==false">Deleted
                                </div>
                            </div>
                        </div>
                        <div ngbDropdown class="d-inline-block dropdown-section"  *ngIf="category_data.is_active && permissions">
                            <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                                <i class="icon-three-dots-vertical "></i>
                            </button>
                            <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                                <button ngbDropdownItem (click)="editcategory(category_data.id)" *ngIf="permissions?.e">
                                    <i class="icon-pencil-square me-1"></i> Edit
                                </button>
                                <button class="text-danger" ngbDropdownItem (click)="deleteId=category_data.id;deleteAlert=true;" *ngIf="permissions?.d">
                                    <i class="icon-trash  me-1"></i> Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="infinityloader">
            <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </ng-template>
</div>
<app-expense-category-add *ngIf="editDetail" [editDetail]="editDetail" [dataId]="dataId" [from]="'category'"
    (panelclose)="closefn($event)" (detailout)="detailout($event)">
</app-expense-category-add>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel " (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <app-view-loader *ngIf="viewLoader"></app-view-loader>
                <ng-container *ngIf="!viewLoader" >
                    <div class="d-flex mb-16">
                        <p class="text-uppercase fw-500 mb-0">EXPENSE CATEGORY</p>
                        <a class="link-primary fs-14 ms-auto" (click)="editcategory(viewdatas?.id)" *ngIf="permissions?.e && viewdatas?.is_active"><i
                                class="icon-pencil-square me-1" ></i> Edit</a>
                    </div>
                    <div class="row row-24 mb-24">
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                <div class="row row-16 ">
                                    <div class="col-12 align-items-center d-flex">
                                        <p class="title mb-8">EXPENSE TYPE</p>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Expense Type </div>
                                        <div class="detail-desc"><i
                                                class="bi-record-circle-fill me-8 text-light-400"></i>
                                                {{viewdatas?.expense_type?'Mileage':'Amount'}}
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Status</div>
                                        <div class="detail-desc">
                                            <span class="fs-12rem fw-500 text-success text-uppercase" *ngIf="viewdatas?.is_active">ACTIVE</span>
                                            <span class="fs-12rem fw-500 text-danger text-uppercase" *ngIf="viewdatas?.is_active==false">Deleted</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                <div class="row row-16 ">
                                    <div class="col-12 align-items-center d-flex">
                                        <p class="title mb-8">EXPENSE INFORMATION</p>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Expense Category Name</div>
                                        <div class="detail-desc">{{viewdatas?.name}}</div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Expense Category Code</div>
                                        <div class="detail-desc">{{viewdatas?.code_name}}</div>
                                    </div>
                                    <div class=" col-12">
                                        <div class="detail-title">Description </div>
                                        <div class="detail-desc">{{viewdatas?.description}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="viewdatas?.expense_type==false">
                            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                <div class="row row-16 ">
                                    <div class="col-12 align-items-center d-flex">
                                        <p class="title mb-8">SET LIMITATION</p>
                                    </div>
                                    <div class="col-sm-4 ">
                                        <div class="detail-title">Amount Limit </div>
                                        <div class="detail-desc" *ngIf="viewdatas?.amount_limit_type =='limit_for_all_location'">
                                            <i class="bi-record-circle-fill me-8 text-light-400"></i>
                                            Limit for all location
                                        </div>
                                        <div class="detail-desc" *ngIf="viewdatas?.amount_limit_type =='location_dependent_limit'">
                                            <i class="bi-record-circle-fill me-8 text-light-400"></i>
                                            Location dependent limit
                                        </div>
                                        <div class="detail-desc" *ngIf="viewdatas?.amount_limit_type =='no_limit'">
                                            <i class="bi-record-circle-fill me-8 text-light-400"></i>
                                            No Limit
                                        </div>
                                    </div>
                                    <div class="col-sm-4 " *ngIf="viewdatas?.amount_limit_type =='location_dependent_limit' && viewdatas?.amount_limit_type !='no_limit'">
                                        <div class="detail-title">Location Group </div>
                                        <div class="detail-desc">
                                            <div class="d-flex gap-8 flex-wrap mt-8 ">
                                                <ng-container *ngFor="let location_group of viewdatas?.location_group;let i=index">
                                                    <span class="badge3 py-2">{{location_group.name}}</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="viewdatas?.amount_limit_type !='no_limit'">
                                     <div class="col-sm-4 ">
                                        <div class="detail-title">Specify Limit </div>
                                        <div class="detail-desc">{{viewdatas?.limit}} </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="detail-title">Period for which limit is applicable </div>
                                        <div class="detail-desc">{{viewdatas?.limit_period}} </div>
                                    </div>
                                    </ng-container>
                              </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="viewdatas?.expense_type">
                            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                <div class="row row-16 ">
                                    <div class="col-12 align-items-center d-flex">
                                        <p class="title mb-8">MILEAGE DETAILS</p>
                                    </div>

                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Rate per kilometer </div>
                                        <div class="detail-desc"> {{viewdatas?.rate_per_km}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Do you want to specify distance limit (KM) </div>
                                        <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>
                                            {{viewdatas?.is_distance_limited?'Yes':'No'}}
                                        </div>
                                    </div>
                                    <ng-container  *ngIf="viewdatas?.is_distance_limited">
                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Specify Limit </div>
                                        <div class="detail-desc">{{viewdatas?.limit}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Period for which limit is applicable </div>
                                        <div class="detail-desc">{{viewdatas?.limit_period}}</div>
                                    </div>
                                   </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                <div class="row row-16 ">
                                    <div class="col-12 align-items-center d-flex">
                                        <p class="title mb-8">REQUEST SECTION</p>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Is description mandatory? </div>
                                        <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>
                                         {{viewdatas?.is_description?'Yes':'No'}}
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Is attachment mandatory? </div>
                                        <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>
                                            {{viewdatas?.is_attachment?'Yes':'No'}}
                                        </div>
                                    </div>
                                    <div class=" col-12" *ngIf="viewdatas?.is_attachment">
                                        <div class="detail-title">Define minimum amount to make attachment mandatory? </div>
                                        <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>
                                            {{viewdatas?.is_attachment_minimum_amount?'Yes':'No'}}
                                        </div>
                                    </div>
                                    <div class=" col-12" *ngIf="viewdatas?.is_attachment_minimum_amount">
                                        <div class="detail-title">Specify Expense limit </div>
                                        <div class="detail-desc">{{viewdatas?.attachment_minimum_amount_limit}}  </div>
                                    </div>
                                    <div class=" col-12">
                                        <div class="detail-title">Can the user file expenses for a date prior to his date of joining? </div>
                                        <div class="detail-desc"><i
                                                class="bi-record-circle-fill me-8 text-light-400"></i>
                                                {{viewdatas?.is_file_expense?'Yes':'No'}}
                                        </div>
                                    </div>
                                    <div class="hstack align-items-center gap-8 fs-14" *ngIf="viewdatas?.is_reimbursable ">
                                        <i class="icon-check-square-fill text-light-400"></i>Reimbursable
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                <div class="row row-16 ">
                                    <div class="col-12 align-items-center d-flex">
                                        <p class="title mb-8">CLAIM FREQUENCY</p>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="detail-title">Claim Frequency limit count</div>
                                        <div class="detail-desc">{{viewdatas?.claim_fequency_limit}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Period for which limit is applicable</div>
                                        <div class="detail-desc">{{viewdatas?.claim_limit_period}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- Delete  component -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Expense Category?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Expense Category')}}

          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteAlert = false;deleteClicked = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteexpense(deleteId)">Delete</button>
        </div>
      </div>
    </div>
  </div>
