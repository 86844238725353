import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayGroupService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }
  createPayGroup(data:any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/pay-group-create/`,data, this.httpOption)
  }
  updatePayGroup(id:number,data:any){
    return this.http.put(`${this.API_PATH}payroll/api/v1/pay-group-update/${id}/`,data, this.httpOption)
  }
  getPayGroupList(active_status:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-group/?is_active=${active_status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }
  getDropdownPayGroupList(active_status:any) {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-group/?is_active=${active_status}&ordering=name`, this.httpOption)
  }
  deletePayGroup(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}payroll/api/v1/pay-group-detail/${id}/`,data, this.httpOption)
  }
  getPayGroup(id:number){
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-group-detail/${id}/`, this.httpOption)
  }
  getTemplate(slipname:any){

    return this.http.get(`${this.API_PATH}hr/api/v1/template-landing/?is_active=true&template_type__name=${slipname}&ordering=template_name`, this.httpOption)

  }

  // Appointment Letter Templates
  getAppointmentLetters(){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/?is_active=true&letter_type=Appointment Letter`, this.httpOption)
  }

  // Salary Revision Letter
  getRevisiontLetters(){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/?is_active=true&letter_type=Salary Revision Letter`, this.httpOption)
  }

}
