import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { FormArray, FormBuilder,Validators ,FormGroup } from '@angular/forms';
import { RegularizationRequestService } from 'src/app/@core/services/regularization-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AppService } from 'src/app/app.global';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { RegularizationSelectDateComponent } from './regularization-select-date/regularization-select-date.component';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';

@Component({
  selector: 'app-regularization-setup',
  templateUrl: './regularization-setup.component.html',
  styleUrls: ['./regularization-setup.component.scss']
})
export class RegularizationSetupComponent implements OnInit {

  activeTab = 1;
  listForm  : any
  eligibility: any
  constructor(
    private _location: Location,
    private formBuilder:FormBuilder,
    private regularizationRequestService: RegularizationRequestService,
    public router: Router,
    public appservice:AppService,
    private investmentService:InvestmentDeclerationService
  ) { }

  backClicked() {
    this.investmentService.backClicked('/regularization-setup');
  }

  ngOnInit(): void {
  }

  @ViewChild(RegularizationSelectDateComponent) regulariseChild !: RegularizationSelectDateComponent;


  regularizationSetUpForm: FormGroup = this.formBuilder.group({
    dateForm         : this.formBuilder.array([]),
    editDetails      : this.formBuilder.group({
      regularization_request    : this.formBuilder.array([])
    })
  })

  get editDetailsForm() {
    return this.regularizationSetUpForm.get('editDetails') as FormGroup;
  }

  get dateForm() {
    return this.regularizationSetUpForm.get('dateForm') as FormArray;
  }

  controls() : FormArray {
    return this.editDetailsForm.get('regularization_request') as FormArray
  }

  // Nav Change
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      if(!this.validateOne() || this.invalidDates()){
        changeEvent.preventDefault();
      }
    }
  }

  submitOne(data:any){
    this.activeTab   = 2;
    this.validateOne()
  }

  validateOne(){
    if(this.dateForm.length !=0)
     return true
    else
     return false
  }

  invalidDates(){
    var val = false;
    if(this.regulariseChild){
      let data =  this.regulariseChild.invalidArrayData()
      if(data && data.length > 0){
        val = true
      }else{
        val = false
      }
    }
    return val
  }

  editDetails(val:any){
    this.dateForm.removeAt(val)
  }

  convertTimeFormat(timeString:any) {
    const timeComponents = timeString.split(':').map(Number);
    const hours = timeComponents[0];
    const minutes = timeComponents[1];
    const seconds = timeComponents.length > 2 ? timeComponents[2] : 0;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  submitTwo(){
    let data = this.controls().value
    data.forEach((item:any)=>{
      let checkIn = moment(item['regulized_checkin_time'],'HH:mm:ss').format('HH:mm:ss')
      let checkOut = moment(item['regulized_checkout_time'],'HH:mm:ss').format('HH:mm:ss')
      // Destructoring for making the seconds as zero hh:mm:ss
      const [inHours,inMinutes] = checkIn?.split(':')
      const [outHours,outMinutes] = checkOut?.split(':')

      item['regulized_checkin_time'] = `${inHours}:${inMinutes}:00`
      item['regulized_checkout_time'] = `${outHours}:${outMinutes}:00`
      item['hours'] = item['hours']+':00'
      item['regulized_checkin_date'] = moment(item['regulized_checkin_date']).format('YYYY-MM-DD')
      item['regulized_checkout_date'] = moment(item['regulized_checkout_date']).format('YYYY-MM-DD')
      // delete item['shift_name_code']
      // item['regulized_checkin_date'] = moment(item['regulized_checkin_date']).format('YYYY-MM-DD')
      // item['regulized_checkout_date'] = moment(item['regulized_checkout_date']).format('YYYY-MM-DD')
      // item.regulized_checkin_time = this.convertTimeFormat(item?.regulized_checkin_time);
      // item.regulized_checkout_time = this.convertTimeFormat(item?.regulized_checkout_time);
      // item.hours = this.convertTimeFormat(item?.hours);
    })

    this.regularizationRequestService.regularizationRequestCreate({'regularization_request': data}).subscribe((res:any)=>{
      this.router.navigate(['request']);
    })
  }

  checkValid(){
    var retResult = true;
    const myForm = (<FormArray>this.editDetailsForm.get("regularization_request"));

    for(let k = 0; k < myForm.length; k++ ){
      this.listForm = myForm.at(k);
      if(this.listForm.controls.regulized_date.valid == false && this.listForm.controls.regulized_checkin_date.valid == false && this.listForm.controls.regulized_checkout_date.valid == false && this.listForm.controls.regulized_checkin_time.valid == false && this.listForm.controls.regulized_checkout_time.valid == false && this.listForm.controls.reason.valid == false && this.listForm.controls.hours.valid == false && this.listForm.controls.shift_mapping.valid == false) {
        retResult = false;
      }
    }
    return retResult;
  }

}
