import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function PhonenoValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const Phonenopattern = /^((\\+[0-9]{0}-?)|0)?[0-9]{10}$/.test(value);

      return !Phonenopattern ? {pattern:true}: null;
  }
}

