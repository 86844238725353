<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Company Statutory</h3>
      <p class="text-helper mb-md-0">Define statutory settings for the listed companies as per their policies.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='companysearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchCompany($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';listFilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';listFilter(true)" class="active">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';listFilter(false)">Deleted</button>

          </div>
        </div>
        <!-- <div class=" d-inline-flex btn btn-outline-primary" (click)="financialYear.open()">
                <span class="text-uppercase text-nowrap  fs-12 fw-600 ">FINANCIAL YEAR : </span>
                <ng-select #financialYear class="form-ngselect " appendTo="ng-select"  placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                [(ngModel)]="selectedYear" (change)= "yearChange()" [closeOnSelect]="true" style="z-index: 999;">
                    <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year"> {{year}} </ng-option>
                </ng-select>
            </div> -->
        <div class=" d-inline-flex">
          <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>
        </div>
      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <ng-container *ngIf="!loader">
    <div *ngIf="!company_list_flag;else all_data " class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg2.png'"
        [noDataText]="'It Seems Statutory Settings Need Your Attention'"
        [noDataPara]="'Choose the financial year to define and lock in your statutory settings for the year.'"
        [hasPermission]="false"> </app-nodata-view>
    </div>
    <ng-template #all_data>
      <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        [fromRoot]="true" (scrolled)="onScrollDown()">
        <ng-container *ngFor="let company of companyList; let i = index">
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="card card-custom1 card-hover"
              [routerLink]="['/statutory/company-statutory/',company?.company?.id,selectedYear,status_filter]">
              <div class="card-body p-0 d-flex align-items-start">
                <div class="d-flex view-section">
                  <img class="logo-img img-fluid" src="{{company.company.logo}}">
                  <div class="overflow-hidden me-auto">
                    <h6 class="mb-0 tips">{{company.company.company_name}}</h6>
                    <span class="tips-helper">{{company.company.company_name}}</span>
                    <span class="fs-10 fw-500 text-success text-uppercase"
                      *ngIf="company.company.is_active">Active</span>
                    <span class="fs-10 fw-500 text-danger text-uppercase"
                      *ngIf="company.company.is_active == false">Deleted</span>
                  </div>
                </div>

              </div>
              <div class="card-body p-16 justify-content-between overflow-hidden ">
                <div class="d-flex  gap-8 fs-12">
                  <span class="">Pending</span>
                  <span class="fw-600 text-warning">{{company.statutory_company.pending}}</span>
                </div>
                <div class="d-flex gap-8 fs-12">
                  <span class="">Locked</span>
                  <span class="fw-600 text-success">{{company.statutory_company.locked}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Loader updations -->
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="infinityloader" >
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

  </ng-container>


  <!-- <div class="row row-24">
      <div class="col-md-6 col-sm-12" *ngFor="let i of statutory">
          <div class="card card-custom2 border radius-4 h-100">
              <div class="card-body status  {{
          i.status  ? 'status-success' : 'status-pending'
        }}">
                  <h6>{{ i.name }}</h6>
                  <p class="fs-12 text-light-400" style="min-height:6rem;">
                      {{ i.text }}
                  </p>
                  <ng-container *ngIf="permissions.v && i.status == true">
                    <button (click)="selectSidebar(i.id)" class="btn btn-outline-primary fs-12 h-auto radius-2">Details
                    </button>
                  </ng-container>
                  <ng-container *ngIf="permissions.a && i.status == false">
                    <button  (click)="selectSidebar(i.id)" class="btn btn-outline-primary fs-12 h-auto radius-2">Review
                    </button>
                  </ng-container>
              </div>
          </div>
      </div>
  </div> -->
</div>
