import { Component, Input, OnInit, EventEmitter,Output  } from '@angular/core';
import { RegularizationRequestService } from 'src/app/@core/services/regularization-request.service';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';

@Component({
  selector: 'app-view-regularization-request',
  templateUrl: './view-regularization-request.component.html',
  styleUrls: ['./view-regularization-request.component.scss']
})
export class ViewRegularizationRequestComponent implements OnInit {

  @Input() loader      : any;
  @Input() data        : any;
  @Input() id          : any;
  @Input()regularizationCancel : any

  @Output() closePanel           = new EventEmitter()


  viewstatusPanel      : boolean = false
  statusLoader         : boolean = false
  cancelReq            : boolean = false
  viewStatusData       : any
  viewStatusLevelCnt   : any
  viewStatusmaxLevel   : any
  emp_ID               : any
  cancelId             : any
  status               : any
  specificId           : any;

  constructor(
    public appServ    : AppService,
    private regServ   : RegularizationRequestService,
    private notificationService: NotificationService,
    private investmentService: InvestmentDeclerationService,
  ) {
    this.investmentService.request_id.subscribe(result =>{
      if(Number(this.id) == Number(result))
        this.statusData(this.specificId);
    })
  }

  ngOnInit(): void {
  }

  statusData(id:any){
    this.viewstatusPanel = true;
    this.statusLoader    = true;
    this.specificId      = id;
    if(typeof this.id === 'number' && typeof id === 'number'){
      this.regServ.viewStatusData(this.id,id).subscribe((res:any)=>{
        this.viewStatusData = res;
        if(!res?.apporval_matrix_loading)
          this.statusLoader     = false;
        this.viewStatusLevelCnt = res?.activity?.level_count;
        this.viewStatusmaxLevel = res?.activity?.maxlevel;
      })
    }
  }

  cancelRequest(id:any,status:any,cancelStatus:any,ot:any,comp:any,arrear:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("Attendance regularization request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else if(ot){
      this.notificationService.handleErrorNotification("OT request is already approved.","Error");
    }else if(comp){
      this.notificationService.handleErrorNotification("Comp off request is already approved.","Error");
    }else{
      this.cancelId  = id;
      this.cancelReq = true;
      this.status    = status;
    }
  }

  close(e:any){
    this.cancelReq = e.show
    this.closePanel.emit(false)
  }

}
