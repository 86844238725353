<div class="container-fluid px-32 py-24">
    <div class="row row-16">
        <div class="col-12 d-flex gap-8  align-items-center">
            <span class=" text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
                <button class="btn-reset btn-star ms-auto text-light-500 fs-12"
                    [class.mail-stared]="requestitem['star']==true"
                    (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
                    <i class="bi toggle-star"></i>
                </button>
        </div>
        <div class="col-12 mt-8 card-ribbon">
            <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
                <h5 class="mb-8">Confirmation Approval </h5>
                <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
                    <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
                        *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
                    <span class="badge px-16 ms-auto fs-14 badge-success py-1"
                        *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
                    <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
                        *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
                    <span class="text-light-400">|</span>
                    <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
                </ng-container>
                <!-- Invalid request -->
                <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
                    class="ribbon ribbon-right ribbon-danger  ribbon-sm"
                    style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
            </div>
            <span *ngIf="!requestitem?.request_data?.is_cancel"
            class=" badge-custom {{requestitem.request_type_color_code}} ">Confirmation </span>
            <span *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
                class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
        </div>
        <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar>
        <div class="col-12">
            <p class="mb-0 fs-14" [innerHTML]="requestitem?.message">
            </p>
        </div>
        <div class="col-12">
            <div class="card details-card shadow-0 p-16">
                <div class="row row-16">
                    <div class="col-12 d-flex">
                        <p class="title mb-8">EMPLOYEE DETAILS</p>
                        <a class="ms-auto fs-12 fw-500 link-primary" *ngIf="approverdata?.level>1 " (click)="confirmationDetails=true;">View previous approval details</a>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Name</div>
                        <div class="detail-desc">{{requestitem?.from_person?.first_name}} {{requestitem?.from_person?.middle_name?requestitem?.from_person?.middle_name:''}} {{requestitem?.from_person?.last_name}}</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Employee code</div>
                        <div class="detail-desc">{{requestitem?.from_person?.employee_code}}</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Date of joining</div>
                        <div class="detail-desc">{{this.appService.dateFormatDisplay(requestitem?.requests?.employee?.date_of_joined)}}</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Date of confirmation</div>
                        <div class="detail-desc">{{this.appService.dateFormatDisplay(requestitem?.requests?.employee?.date_of_confirmation)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12"  *ngIf="approverdata?.approved_person_list?.length>0 && requestitem?.achieved_status">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                    <div class="col-12 align-items-center d-flex">
                        <p class="title mb-8">APPROVAL STATUS</p>
                        <span class="badge badge-success ms-auto py-1 px-3" *ngIf="currentdata[0]?.status=='Confirmed'">Confirmed</span>
                        <span class="badge badge-warning ms-auto py-1 px-3" *ngIf="currentdata[0]?.status=='Pending'">Pending</span>
                        <span class="badge badge-primary ms-auto py-1 px-3" *ngIf="currentdata[0]?.status=='Extended Probation'">Extended Probation</span>
                        <span class="badge badge-danger ms-auto py-1 px-3" *ngIf="currentdata[0]?.status=='Terminated'">Terminated</span>
                    </div>
                    <div class=" col-12" *ngIf="currentdata[0]?.status=='Extended Probation'">
                        <div class="detail-title">Extention Period</div>
                        <div class="detail-desc">{{currentdata[0]?.extension_period?currentdata[0]?.extension_period:'--'}} Days</div>
                    </div>
                    <div class=" col-12">
                        <div class="detail-title">Comments</div>
                        <div class="detail-desc">{{currentdata[0]?.comment?currentdata[0]?.comment:'--'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!requestitem?.achieved_status">
          <div class="col-12 d-flex gap-16 py-16 align-items-center position-sticky bottom-0 bg-white">
            <a class="link-danger me-auto" (click)="terminateToggle=true;disablebtnterminate = false; " *ngIf="requestitem?.requests?.confirmation_policy?.is_terminate">Termination</a>
            <button type="button" class="btn btn-outline-primary btn-sm fw-400" (click)="Extendfn();disablebtn=false;" *ngIf="requestitem?.requests?.confirmation_policy?.is_extend==true">Extend
                Probation</button>
            <button type="button" class=" btn btn-primary btn-sm " (click)="confirm=true;disablebtn=false;">Confirm  
                Employee</button>
          </div>    
        </ng-container>
    </div>
</div>


<ng-container *ngIf="viewstatusPanel">
    <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
      <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
          <h5>Activities</h5>
          <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>  
        <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
          [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"
          [from]="fromViewStatus"></app-view-status>  
  
      </form>
    </div>
</ng-container>

<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        
      <div class="modal-content" >
            <div class="modal-header gap-16">
            <i class="bi bi-exclamation-triangle text-danger"></i>
            <h4 class="modal-title">Alert</h4>
            </div>
            <div class="modal-body">
                    <p class="mb-0">Number of probation extensions as per the policy has exceeded.
                    </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="alertToggle = false">OK</button>
            </div>
        </div>
    </div>
</div>

<div class="modal  modal-alert {{terminateToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        
      <div class="modal-content" >
            <div class="modal-header gap-16">
            <h4 class="modal-title" >Terminate</h4>
            </div>
            <div class="modal-body">
              <ng-container [formGroup]="formterminate" >
                <div class=" col-12 form-row">
                    <label for="" class="form-label required">Reason</label>
                    <textarea class="form-control" name="comment" id="comment" rows="3" formControlName="comment" [ngClass]="{ 'is-invalid': (ft.comment.dirty || ft.comment.touched) && (ft.comment.errors)}" placeholder="Enter Reason here..."></textarea>
                    <div *ngIf="ft.comment.errors" class="invalid-feedback">
                      <div *ngIf="ft.comment.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="ft.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
                    </div>  
                </div>
            </ng-container>
            </div>         
            <div class="modal-footer">
                <div class="col-12 d-flex gap-16 justify-content-center">
                    <button type="button" class="  btn btn-outline-danger  text-uppercase btn-sm fw-400"
                        (click)="terminateToggle=false;formterminate.reset();">Cancel</button>
                    <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400" (click)="terminate()" [disabled]="formterminate.invalid || disablebtnterminate" >Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal  modal-alert {{confirm == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        
      <div class="modal-content" >
            <div class="modal-header gap-16">
            <h4 class="modal-title" >Confirm Employee</h4>
            </div>
            <div class="modal-body">
              <ng-container [formGroup]="formconfirm" >
                <div class=" col-12 form-row">
                    <label for="" class="form-label ">Comment</label>
                    <textarea class="form-control" name="comment" id="comment" rows="3" formControlName="comment" [ngClass]="{ 'is-invalid': (fc.comment.dirty || fc.comment.touched) && (fc.comment.errors)}" placeholder="Enter Comment here..."></textarea>
                    <div *ngIf="fc.comment.errors" class="invalid-feedback">                     
                      <div *ngIf="fc.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
                    </div>  
                </div>
            </ng-container>
            </div>         
            <div class="modal-footer">
                <div class="col-12 d-flex gap-16 justify-content-center">
                    <button type="button" class="  btn btn-outline-danger  text-uppercase btn-sm fw-400"
                        (click)="confirm=false;formconfirm.reset();">Cancel</button>
                    
                    <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400" [disabled]="formconfirm.invalid ||disablebtn" (click)="confirmemp()" *ngIf="confirm">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal  modal-alert {{probationToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        
      <div class="modal-content" >
            <div class="modal-header gap-16">
            <h4 class="modal-title">Extend Probation</h4>
            </div>
            <div class="modal-body">
              <ng-container [formGroup]="form" >
                <div class="col-12">
                    <label for="" class="form-label required">Extension period (In Days) </label>
                    <input type="text" formControlName="extension_period" class="form-control"
                    placeholder="{{this.messageService.placeholderdisp('Days')}}" [readonly]="!requestitem?.requests?.confirmation_policy?.is_edit_extend"
                    [ngClass]="{ 'is-invalid': (f.extension_period.touched || f.extension_period.dirty) &&  f.extension_period.errors || f.extension_period.errors?.exist}" >
                  <div *ngIf="(f.extension_period.touched || f.extension_period.dirty) && f.extension_period.errors "
                    class="invalid-feedback">
                    <div *ngIf="f.extension_period.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.extension_period.errors?.pattern && f.extension_period.errors?.max">
                        {{this.messageService.fieldlengthvalidation('value','365')}}</div>
                    <div *ngIf="f.extension_period.errors?.pattern || f.extension_period.errors?.min">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                  </div>
                </div>
                <div class=" col-12 form-row">
                    <label for="" class="form-label required">Reason</label>
                    <textarea class="form-control" name="comment" id="comment" rows="3" formControlName="comment" [ngClass]="{ 'is-invalid': (f.comment.dirty || f.comment.touched) && (f.comment.errors)}" placeholder="Enter Reason here..."></textarea>
                    <div *ngIf="f.comment.errors" class="invalid-feedback">
                      <div *ngIf="f.comment.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
                    </div>                
               </div>
              </ng-container>
            </div>
            <div class="modal-footer">
                <div class="col-12 d-flex gap-16 justify-content-center">
                    <button type="button" class="  btn btn-outline-danger  text-uppercase btn-sm fw-400"
                        (click)="probationToggle=false;form.reset();">Cancel</button>
                    <button type="button" class="btn btn-primary text-uppercase btn-sm fw-400" [disabled]="form.invalid || disablebtn" (click)="Extend()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='confirmationDetails === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details </h5>
            <a class="toggle-panel" (click)="confirmationDetails = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16">
                    <div class="col-12 d-flex">
                        <p class="text-uppercase fw-500 mb-0">Approval details</p>
                    </div>
                <ng-container *ngFor="let approver of prevdata let i = index">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 align-items-center d-flex">
                                    <p class="title mb-8">Approver {{i+1}} - {{approver?.approved_person_name}}</p>
                                    <span class="badge badge-success ms-auto py-1 px-3" *ngIf="approver?.status=='Confirmed'">Confirmed</span>
                                    <span class="badge badge-warning ms-auto py-1 px-3" *ngIf="approver?.status=='Pending'">Pending</span>
                                    <span class="badge badge-primary ms-auto py-1 px-3" *ngIf="approver?.status=='Extended Probation'">Extended Probation</span>
                                    <span class="badge badge-danger ms-auto py-1 px-3" *ngIf="approver?.status=='Terminated'">Termination</span>
                                </div>
                                <div class=" col-12" *ngIf="approver?.status=='Extended Probation'">
                                    <div class="detail-title">Extention Period</div>
                                    <div class="detail-desc">{{(approver?.extension_period)?(approver?.extension_period):'--'}} Days</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Reason</div>
                                    <div class="detail-desc">{{(approver?.comment)?(approver?.comment):'--'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                </div>
            </div>
        </div>
    </div>
</div>