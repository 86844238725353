import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter,Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})

export class ProfileDocumentService {
  profile_doc_data: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpClient,
    public router: Router,
    private websocket: WebSocket,
    private authService: AuthService,
    public appservice:AppService
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }
  WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public employeeDocSocket(){

    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}employee_document/${this.appservice.getSubDomain()}_employee_document_${this.authService.isUserId}/`);

    this.websocket.onopen = (event:any) => {
    }

    this.websocket.onmessage = (event:any) => {
      let data = JSON.parse(event.data);
      this.profile_doc_data.emit(data);
    }

    this.websocket.onclose = (event:any) => {
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if(event.url !== '/profile/snapshot'){
          this.closeSocket();
        }
      }
    });
  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
    }
  }

  getAllDocumentData(id:number){
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-document-list/?employee__id=${id}`, this.httpOption)
  }
  updateDocument(data:any,id:number){
    return this.http.put(`${this.API_PATH}organization/api/v1/employee-document-detail/${id}/`, data, this.httpOption)
  }
  createDocument(data:any){
    return this.http.put(`${this.API_PATH}organization/api/v1/document-create-multiple/`, data, this.httpOption)
  }

   getDocViewStatus(emp_req_id:any, temp_doc_id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${emp_req_id}&request_section=employee_document&specific_id=${temp_doc_id}`, this.httpOption)
  }

}
