<div class="container-fluid px-32 py-24">
    <div class="row row-16">
        <div class="col-12 d-flex gap-8  align-items-center">
            <span class="text-light-500 fs-12 text-nowrap">Today, 18 min ago</span>
            <button class="btn-reset btn-star ms-auto text-light-500 fs-12">
                <i class="bi toggle-star"></i>
            </button>
        </div>
        <div class="col-12 mt-8 card-ribbon">
            <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
                <h5 class="mb-8">Confirmation Approval </h5>
                <div class="d-flex align-items-center ms-auto gap-8">
                    <span class="badge px-16  fs-14 badge-warning py-1">Pending</span>
                    <!-- <span class="badge px-16  fs-14 badge-success py-1">Accepted</span> -->
                    <!-- <span class="badge px-16  fs-14 badge-danger py-1">Rejected</span> -->
                    <span class="text-light-400">|</span>
                    <span class="link-primary fs-14">View Status</span>
                </div>
            </div>
            <span class=" badge-custom ">Confirmation </span>
        </div>
        <div class="col-12">
            <p class="mb-0 fs-14">Hi Kanmani Neha,<br><br>Request to take action on Asset request of employee </p>
        </div>
        <div class="col-12">
            <div class="card details-card shadow-0 indicater1    p-16">
                <div class="row row-16">
                    <div class="col-12">
                        <p class="title mb-8">EMPLOYEE DETAILS</p>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Name</div>
                        <div class="detail-desc">Remya Sazena</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Employee code</div>
                        <div class="detail-desc">EMP10502</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Company</div>
                        <div class="detail-desc">Tesla</div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <div class="detail-title">Department</div>
                        <div class="detail-desc">HR</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 d-flex gap-16 align-items-center  py-16  position-sticky bottom-0 bg-white">
            <a href="" class="link-danger">Termination</a>
            <button class=" btn btn-outline-primary ms-auto btn-sm fw-400">Extend Probation</button>
            <button class=" btn btn-primary  btn-sm fw-400">Confirm Employee</button>
        </div>
    </div>
</div>
<div class="modal  modal-alert " tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
                <h4 class="modal-title">Extend Probation</h4>
                <button class="btn-reset ms-auto text-light-600">
                    <i class="icon-close-lg fs-16"></i></button>
            </div>
            <div class="modal-body">
                <div class="row row-16">
                    <div class="col-12">
                        <label for="" class="form-label required">Extention period </label>
                        <ng-select class="form-ngselect" placeholder="Select "></ng-select>
                    </div>
                    <div class=" col-12 form-row">
                        <label for="" class="form-label required">Reason </label>
                        <textarea type="text" class="form-control " rows="4"
                            placeholder="Enter description here..."></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase ">Cancel</button>
                <button type="button" class="btn btn-primary btn-sm text-uppercase ">Submit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal  modal-alert " tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
                <h4 class="modal-title">Terminate</h4>
                <button class="btn-reset ms-auto text-light-600">
                    <i class="icon-close-lg fs-16"></i></button>
            </div>
            <div class="modal-body">
                <div class="row row-16">
                    <div class=" col-12 form-row">
                        <label for="" class="form-label required">Reason </label>
                        <textarea type="text" class="form-control " rows="4"
                            placeholder="Enter description here..."></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase ">Cancel</button>
                <button type="button" class="btn btn-primary btn-sm text-uppercase ">Submit</button>
            </div>
        </div>
    </div>
</div>