<div class="container-fluid px-32 py-24">
  <div class="row row-16">
    <div class="col-12 d-flex position-relative">
      <span class=" text-light-500 fs-12 text-nowrap">{{requestitem.created_display}}</span>
      <!-- <a class="ms-auto" (click)="requestViewStatus()"> View status</a> -->
      <button class="btn-reset ms-auto btn-star text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
        (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8">
        <h5 class="mb-8">Final Settlement Process</h5>
        <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">

          <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
            *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
          <span class="badge px-16 ms-auto fs-14 badge-success py-1"
            *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
          <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
            *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
          <!-- <span class="text-light-400">|</span>
            <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span> -->
        </ng-container>
        <!-- Invalid request -->
        <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
          class="ribbon ribbon-right ribbon-danger  ribbon-sm" style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
      </div>
      <!-- <h5 class="mb-8">Assign Salary structure</h5> -->
      <span class=" badge-custom  {{requestitem.request_type_color_code}} ">Final Settlement Process</span>
      <span
        *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
        class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>

    <div class="col-12" *ngIf="requestitem?.achieved_status && requestitem.approved_person != null">
      <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
        <i class="bi fs-16 bi-check-square-fill text-success "></i>
        <span class="">Approved by </span>
        <span class="fw-600">{{requestitem.approved_person ==
          requestitem.to_person.id?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
      </div>
    </div>
    <div class="col-12" *ngIf="!requestitem?.achieved_status && requestitem.approved_person == null">
      <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
        <i class="bi fs-16  bi-x-square-fill text-warning "></i>
        <span class="">Pending from </span>
        <span class="fw-600">{{requestitem.approved_person == null?'You':''}}</span>
      </div>
    </div>
    <div class="col-12">
      <p class="mb-0 fs-14" [innerHTML]="requestitem.message">
      </p>

    </div>
    <div class=" col-md-9  col-lg-6">
      <button class="btn btn-outline-primary w-100 fw-400 btn-sm radius-2"
        [disabled]="requestitem.approved_status=='Approved'"
        [routerLink]="['/final-settlement/final-settlement-setup/'+ requestitem.request_data.employee+'/'+requestitem.id]">Review
        Final Settlement</button>
    </div>
    <!-- <div class="col-12">
      <p class="mb-0 fs-14">
        The salary structure must be assigned before the due date.
        <br><br>
        Skizzle Team <br> Kochi, India
      </p>

    </div> -->

  </div>
</div>

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>

      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>



    </form>
  </div>
</ng-container>
<!-- end view status panel -->