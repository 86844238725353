<div class="flex-center flex-column full-page bg-nonlogin">
    <div class="card form-card mx-auto">
        <div class="card-body  p-32">
            <a routerLink="/login" class="text-center d-block">
                <img class="img-fluid org-logo" src="{{currentlogodata}}" alt=""
                *ngIf="currentlogodata != '' && currentlogodata != null">
              <img class="img-fluid org-logo" src="/assets/images/SkizzleHR-Logo/SkizzleHR-HorizontalLogo-Color.svg" alt=""
                *ngIf="currentlogodata == '' || currentlogodata == null">
            </a>
            <h3 class="fw-600 text-center pt-40 pb-8">Session Timeout</h3>
            <p class="text-center">Hi, <span class="fw-600">{{userData?.first_name}}</span> </p>
            <form autocomplete="off" [formGroup]="loginForm" (ngSubmit)="doLogin()">
                <div class="card-body p-0">
                    <div>
                        <div class="mb-3 form-group">
                            <label for="password" class="control-label required form-label">Password</label>
                            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.password.dirty || f.password.touched) && (f.password.errors)}">
                                <input [ngClass]="{ 'is-invalid': (submitted || f.password.dirty || f.password.touched)&& (f.password.errors)}" popoverClass="error-popover" placement="right" required
                                    [type]="show_pass? 'text' : 'password'" class="form-control" name="" id="password"
                                    placeholder="" formControlName="password">
                                <i class="pointer  {{viewicon_password}}" (click)="getpassword('pass')"></i>
                            </div>
                            <div *ngIf="(submitted || f.password.dirty || f.password.touched) && (f.password.errors)"
                                class="invalid-feedback">
                                <div *ngIf="f.password.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="f.password.errors?.wrong_password">{{errorPass}}</div>
                                <div *ngIf="f.password.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>

                            </div>
                        </div>
                        <div class="d-block text-end fs-14 link "><span class="link-primary"
                                routerLink="../forgot-password">Forgot
                                Password?</span></div>
                    </div>
                    <div class="d-grid gap-2 pt-4">
                        <button type="submit" name="" id="" class="btn btn-primary">Login</button>
                    </div>
                    <div class="d-block text-center pt-24 fs-14 link "><span class="link-primary" (click)="doLogout();">Logout</span></div>
                </div>
            </form>
        </div>
    </div>
    <p class="text-center mb-0 py-24"><small><a href="https://www.skizzlehr.tech/pages/privacy-policy.php"
                class="link-secondary" target="_blank">Privacy
                policy</a> | <a href="https://www.skizzlehr.tech/pages/terms-and-condition.php" class="link-secondary"
                target="_blank">Terms and conditions</a> | {{this.appservice.version}}</small>
    </p>
</div>