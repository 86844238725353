<app-table-loader *ngIf="loader"></app-table-loader>
<ng-container *ngIf="!loader">
  <div class="row row-16">
    <div class="col-12">
      <div class="card card-c2 p-0 ">
        <div class="table-responsive   radius-4">
          <table class="table sticky-header table-striped table-sm">
            <thead>
              <tr>
                <th class="fw-600 text-uppercase" scope="col">DATE</th>
                <th class="fw-600 text-uppercase" scope="col">FIRST IN</th>
                <th class="fw-600 text-uppercase" scope="col">LAST OUT</th>
                <th class="fw-600 text-uppercase" scope="col">TOTAL HRS</th>
                <th class="fw-600 text-uppercase" scope="col">OVER TIME</th>
                <th class="fw-600 text-uppercase" scope="col">1ST HALF</th>
                <th class="fw-600 text-uppercase" scope="col">2ND HALF</th>
                <th *ngIf="from != 'myteam' && from != 'HR'" class="fw-600 text-uppercase" scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of $any(tableData | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                <td class="">
                  <div class="d-flex align-items-center gap-8">{{item.date |
                    date:this.appservice.getdateformat()}}<span *ngIf="item.short_leave_status" class="text-warning"> (SHL)</span>
                    <span *ngIf="item.late_coming_status" class="type-indicator sq-12  type-late"></span>
                    <span *ngIf="item.regularized_status" class="type-indicator sq-12  type-regularized"></span>
                    <span *ngIf="item.onduty_status" class="type-indicator sq-12  type-on-duty"></span>
                    <span *ngIf="item.wfh_status" class="type-indicator sq-12  type-WFH"></span>
                    <span *ngIf="item.is_deduction_applied" class="type-indicator sq-12  type-deduction"></span>
                    <span *ngIf="item.override_status" class="type-indicator sq-12  type-override"></span>
                    <span *ngIf="item.is_overrided" class="type-indicator sq-12  type-punch"></span>
                    <span *ngIf="item.early_checkout_status" class="type-indicator sq-12 type-early-checkout"></span>
                  </div>
                  
                </td>
                <td *ngIf="item.checkIn!='None'">{{item.date+" "+item.checkIn |
                  date:this.appservice.gettimeformat()}}</td>
                <td *ngIf="item.checkIn=='None'">--</td>
                <td *ngIf="item.checkOut!='None'">{{item.date+" "+item.checkOut |
                  date:this.appservice.gettimeformat()}}</td>
                <td *ngIf="item.checkOut=='None'">--</td>
                <td *ngIf="appservice.isValidDateTime(item.date+' '+item.actualHour)">{{item.date+"
                  "+item.actualHour|date:'HH:mm'}} Hrs
                </td>
                <td *ngIf="!appservice.isValidDateTime(item.date+' '+item.actualHour)">--</td>
                <td *ngIf="item.overtime_status">{{item.date+" "+item.overTime|date:'HH:mm'}} Hrs</td>
                <td *ngIf="!item.overtime_status">--</td>
                <td><span
                    class="badge {{item.status_firstHalf!=null?badge[item.status_firstHalf]:item?.attendance_class}} fs-14 fw-400 px-16 py-1">{{item.status_firstHalf!=null?item.status_firstHalf:item?.attendance_txt}}</span>
                </td>
                <td><span
                    class="badge  {{item.status_secondHalf!=null?badge[item.status_secondHalf]:item?.attendance_class}} fs-14 fw-400 px-16 py-1">{{item.status_secondHalf!=null?item.status_secondHalf:item?.attendance_txt}}</span>

                </td>
                <td *ngIf="from != 'myteam' && from != 'HR'">
                  <ng-select class="form-ng-select " [items]="options" (change)="this.gotoAction.emit($event)"
                    bindLabel="name" bindValue="name" placeholder="Action">
                  </ng-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex">
      <div class="d-flex flex-wrap gap-16 fs-12">
        <div class="d-flex align-items-center gap-8">
          <span class="type-indicator sq-12 type-early-checkout"></span>Early Checkout
        </div>
        <div class="d-flex align-items-center gap-8">
          <span class="type-indicator sq-12 type-regularized"></span>Regularized
        </div>
        <div class="d-flex align-items-center gap-8">
          <span class="type-indicator sq-12 type-deduction"></span>Deduction
        </div>
        <div class="d-flex align-items-center gap-8">
          <span class="type-indicator sq-12 type-punch"></span>Punch
        </div>
        <div class="d-flex align-items-center gap-8">
          <span class="type-indicator sq-12 type-on-duty"></span>On duty
        </div>
        <div class="d-flex align-items-center gap-8">
          <span class="type-indicator sq-12 type-WFH"></span>WFH
        </div>
        <div class="d-flex align-items-center gap-8">
          <span class="type-indicator sq-12 type-late"></span>Late
        </div>
        <div class="d-flex align-items-center gap-8">
          <span class="type-indicator sq-12 type-override"></span>Override
        </div>
      </div>
      <ngb-pagination class="d-flex ms-auto justify-content-end" [collectionSize]="tableData.length"
        [pageSize]="pageSize" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
      </ngb-pagination>
    </div>
  </div>
</ng-container>