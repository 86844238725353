<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row row-8 mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Employee Directory</h3>
            <p class="text-helper mb-md-0">Employee directory shows basic employee information about your colleagues or
                peers.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <app-searchbar  #search  [searchlist]='searchlistdata' [searchplaceholder]="searchkey"
                    (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchemp($event)"  [isLoading]="sLoading"></app-searchbar>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
                        ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
                    (filterClose)="closefn($event)"  (resetCalled)="resetCalled()" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
                </div>
            </div>
        </div>
        <div class="col-12"><span class="fw-600 fs-14">{{count}} Employees Found </span></div>
    </div>
    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16 row-cols-xl-5" *ngIf="loader">
        <app-common-loader class="col-md-3 col-sm-6" [cardType]="'card12'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
    <div *ngIf="nodata && !loader;else all_data"  class="row" style="height: calc(100vh - 15rem);">
        <!-- <div class="col-12 text-center">
            <img class="img-fluid mx-auto" src="../../../../../assets/images/bg/15 Startup launch.png"
                style="width: 25rem;">
            <p class="text-uppercase text-light-400 fw-700 fs-14 mt-3">NO Employee ADDED YET</p>
        </div> -->
        <app-nodata-view class="vstack" [noDataImg]="'bg9.png'" [noDataText]="'NO EMPLOYEE ADDED YET'" [hasPermission]="false"> </app-nodata-view>
    </div>
    <ng-template #all_data>
        <!-- <ng-container> -->
        <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
            (scrolled)="onScrollDown()">
            <div class="row row-16   row-cols-xl-5">
                <ng-container *ngIf="!loader">
                    <div class="col-md-3 col-sm-6" *ngFor="let i of empdirectoryarray; let j = index">
                        <div class="card card-c2 card-hover p-16 indicater1 indicater-bg{{j%8}} "
                            (click)="viewDetail = true">
                            <div class="d-flex gap-12"
                                (click)="viewemployee(i.id,i.first_name,i.last_name,i.middle_name,i.designation,i.employee_code,i.color_code,i.profile_image)">
                                <img alt="" class="img-fluid avatar-border avatar-circle sq-46"
                                    *ngIf="i.profile_image !=  null && i.profile_image !=  ''"
                                    src="{{i.profile_image}}" />
                                <span *ngIf="i.profile_image ==  null || i.profile_image ==  ''"
                                    class="avatar-sm sq-46 me-8 rounded-circle {{i.color_code}}"><span>{{this.appService.getFirstChar(i.first_name+"
                                        "+i.last_name,2)}}</span></span>
                                <!-- <div class="avatar-circle sq-57 mt-6 mx-auto fw-500 bg-white avatar-border" >SM</div> -->
                                <div class="d-flex flex-column gap-1 overflow-hidden">
                                    <div class="fw-500 fs-14 text-trim">{{i.first_name+"
                                        "+(i.middle_name!=null?i.middle_name+" ":" "
                                        )+i.last_name }}</div>
                                    <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                                        {{i.designation
                                        }}</div>
                                    <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">EMP CODE:
                                        {{i.employee_code
                                        }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- Loader updations -->
                <ng-container *ngIf="infinityloader">
                    <app-common-loader class="col-md-3 col-sm-6" [cardType]="'card12'"
                        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
                </ng-container>
            </div>
        </div>
        <!-- </ng-container> -->
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="infinityloader">
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->
    <div class="side-panel" style="--sidepanel-width:43.75rem;" *ngIf="permissions"
        [class.side-pane-active]='viewDetail === true'>
        <div class="side-panel-container" autocomplete="off">
            <header class="side-panel-head">
                <h5>Snapshot</h5>
                <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
            </header>
            <div class="side-panel-body" style="overflow-y: auto;">
                <div class="container-fluid p-32">
                    <app-view-loader *ngIf="loader1"></app-view-loader>
                    <div class="row row-16 mb-24" *ngIf="!loader1">
                        <div class="col-12">
                            <div class="card card-c2 card-hover p-16 indicater1 ">
                                <div class="d-flex gap-12">
                                    <img alt="" class="img-fluid avatar-border avatar-circle sq-46"
                                        *ngIf="pimage !=  null && pimage !=  ''" src="{{pimage}}" />
                                    <span *ngIf="pimage ==  null || pimage ==  ''"
                                        class="avatar-sm sq-46 me-8 rounded-circle {{colorcode}}"><span>{{this.appService.getFirstChar(fullname,2)}}</span></span>
                                    <!-- <div class="avatar-circle sq-57 mt-6 mx-auto fw-500 bg-white avatar-border" >SM</div> -->
                                    <div class="d-flex flex-column gap-1 overflow-hidden">
                                        <div class="fw-500 fs-14 text-trim">{{fullname}}</div>
                                        <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">{{desig}}
                                        </div>
                                        <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">EMP CODE:
                                            {{emcode}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title">BASIC INFORMATION</span></div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.user?.first_name">
                                        <div class="detail-title">Name</div>
                                        <div class="detail-desc">{{empdetail?.user?.first_name+"
                                            "+(empdetail?.user?.middle_name!=null?empdetail?.user?.middle_name+" ":" "
                                            )+empdetail?.user?.last_name }}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.prefix">
                                        <div class="detail-title">Prefix</div>
                                        <div class="detail-desc">{{empdetail?.prefix}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.date_of_birth">
                                        <div class="detail-title">Date of birth</div>
                                        <div class="detail-desc">
                                            {{this.appService.dateFormatDisplay(this.empdetail.date_of_birth)}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.employee_code">
                                        <div class="detail-title">Employee code</div>
                                        <div class="detail-desc">{{empdetail?.employee_code}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.blood_group">
                                        <div class="detail-title">Blood group</div>
                                        <div class="detail-desc">{{empdetail?.blood_group}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.gender">
                                        <div class="detail-title">Gender</div>
                                        <div class="detail-desc">{{empdetail?.gender}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.work_email">
                                        <div class="detail-title">Work email</div>
                                        <div class="detail-desc">{{empdetail?.work_email}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.nationality">
                                        <div class="detail-title">Nationality</div>
                                        <div class="detail-desc">{{empdetail?.nationality}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.date_of_joined">
                                        <div class="detail-title">Date of joining</div>
                                        <div class="detail-desc">
                                            {{this.appService.dateFormatDisplay(this.empdetail.date_of_joined)}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.mobile_number">
                                        <div class="detail-title">Mobile number</div>
                                        <div class="detail-desc">{{empdetail?.mobile_number}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title">EMPLOYMENT DETAILS</span></div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.employment_status">
                                        <div class="detail-title">Employment status</div>
                                        <div class="detail-desc">{{empdetail?.employment_status}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.employment_type">
                                        <div class="detail-title">Employment type</div>
                                        <div class="detail-desc">{{empdetail?.employment_type}}</div>
                                    </div>
                                    <!-- <div class="col-sm-6 col-12" *ngIf="confirmdate">
                                        <div class="detail-title">Date of confirmation</div>
                                        <div class="detail-desc">{{empdetail?.date_of_confirmation !='' &&
                                            empdetail?.date_of_confirmation !=null &&
                                            empdetail?.date_of_confirmation!='None' &&
                                            empdetail?.date_of_confirmation!='none'?this.appService.dateFormatDisplay(this.empdetail?.date_of_confirmation):
                                            "-"}}</div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title">POSITION DETAILS</span></div>
                                    <div class="col-sm-6 col-12" *ngIf="funcmanger">
                                        <div class="detail-title">Functional manager</div>
                                        <div class="d-flex align-items-center">
                                            <img *ngIf="(empdetail?.functional_manager?.first_name!=null && (empdetail?.functional_manager.profile_image !=  '' && empdetail?.functional_manager.profile_image !=  null))"
                                                class="user-img img-fluid rounded-circle sq-32"
                                                src="{{empdetail?.functional_manager.profile_image}}">
                                            <div class="avatar-circle fs-14 fw-500 bg1 sq-32 rounded-circle"
                                                *ngIf="(empdetail?.functional_manager!=null && (empdetail?.functional_manager.profile_image ==  '' || empdetail?.functional_manager.profile_image ==  null))"
                                                class="avatar-circle fs-14 fw-500 {{empdetail?.functional_manager.color_code}} sq-32 rounded-circle">
                                                <span>{{this.appService.getFirstChar(empdetail?.functional_manager?.first_name+"
                                                    "+empdetail?.functional_manager?.last_name,2)}}</span>
                                            </div>
                                            <div class="detail-desc p-2">{{empdetail?.functional_manager !='' &&
                                                empdetail?.functional_manager !=null &&
                                                empdetail?.functional_manager!='None' &&
                                                empdetail?.functional_manager!='none'?(empdetail?.functional_manager?.first_name+"
                                                "+(empdetail?.functional_manager?.middle_name!=null?empdetail?.functional_manager?.middle_name+"
                                                ":" "
                                                )+empdetail?.functional_manager?.last_name):'-'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="repotmanager">
                                        <div class="detail-title">Reporting manager</div>
                                        <div class="d-flex align-items-center">
                                            <img *ngIf="(empdetail?.reporting_manager?.first_name!=null && (empdetail?.reporting_manager.profile_image !=  '' && empdetail?.reporting_manager.profile_image !=  null))"
                                                class="user-img img-fluid rounded-circle sq-32"
                                                src="{{empdetail?.reporting_manager.profile_image}}">
                                            <div class="avatar-circle fs-14 fw-500 bg1 sq-32 rounded-circle"
                                                *ngIf="(empdetail?.reporting_manager!=null && (empdetail?.reporting_manager.profile_image ==  '' || empdetail?.reporting_manager.profile_image ==  null))"
                                                class="avatar-circle fs-14 fw-500 {{empdetail?.reporting_manager.color_code}} sq-32 rounded-circle">
                                                <span>{{this.appService.getFirstChar(empdetail?.reporting_manager?.first_name+"
                                                    "+empdetail?.reporting_manager?.last_name,2)}}</span>
                                            </div>
                                            <div class="detail-desc p-2">{{empdetail?.reporting_manager !='' &&
                                                empdetail?.reporting_manager !=null &&
                                                empdetail?.reporting_manager!='None' &&
                                                empdetail?.reporting_manager!='none'?(empdetail?.reporting_manager?.first_name+"
                                                "+(empdetail?.reporting_manager?.middle_name!=null?empdetail?.reporting_manager?.middle_name+"
                                                ":" "
                                                )+empdetail?.reporting_manager?.last_name):'-'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.business_unit?.name">
                                        <div class="detail-title">Business unit</div>
                                        <div class="detail-desc">{{empdetail?.business_unit?.name}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.company?.company_name">
                                        <div class="detail-title">Company</div>
                                        <div class="detail-desc">{{empdetail?.company?.company_name}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.designation?.name">
                                        <div class="detail-title">Designation</div>
                                        <div class="detail-desc">{{empdetail?.designation?.name}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.department?.name">
                                        <div class="detail-title">Department</div>
                                        <div class="detail-desc">{{empdetail?.department?.name}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.grade?.name">
                                        <div class="detail-title">Grade</div>
                                        <div class="detail-desc">{{empdetail?.grade?.name}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.branch?.name">
                                        <div class="detail-title">Branch</div>
                                        <div class="detail-desc">{{empdetail?.branch?.name}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12" *ngIf="empdetail?.skill_type">
                                        <div class="detail-title">Skill type</div>
                                        <div class="detail-desc">{{empdetail?.skill_type}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
