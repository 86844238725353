<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Letter Template</h3>
      <p class="text-helper mb-md-0">Different types of letter templates can be configured in this section.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <app-searchbar [searchlist]='lettersDropdown' [searchplaceholder]='searchString'
          (searchEmitter)="searchResults($event)" (keywordsearchEmitter)="searchKeywordResults($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn =='All' }"
            (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn =='Active' }"
            (click)="statusBtn='Active';selectItemList(true)">Active</button>
            <button ngbDropdownItem [class]="{'active':statusBtn =='Deleted' }"
            (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
          </div>
        </div>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a" routerLink="/letter-template/letter-template-setup"><i
            class="icon-plus"></i>ADD
          Letter Template</button>
      </div>
    </div>
  </div>
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <div *ngIf="noData && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg2.png'" [noDataText]="'Ready-to-Use Templates!'"
      [noDataPara]="' It seems your letter templates are not set up yet. Click \'Add Letter Template\' to manage and customize your company\'s letters.'"
      [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let letter of mainAPIData">
        <div class="card card-custom1 card-hover card-ribbon">
          <div class="card-body p-0 d-flex align-items-start">
            <div class="d-flex p-16 view-section" (click)="viewDetails = true;viewComponent(letter?.id)">
              <div class="logo-img {{letter?.color_code}}">{{this.appServ.getFirstChar(letter?.name,1)}}</div>
              <div class="overflow-hidden">
                <h6 class="mb-8 tips">{{letter?.name}}</h6>
                <span class="tips-helper">{{letter?.name}}</span>
                <div class="fs-10 text-uppercase fw-500 {{(letter?.is_active == true) ? 'text-success' : 'text-danger' }}">{{letter?.is_active ? 'Active' : 'Deleted'}}</div>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="letter?.is_active==true && (permissions?.e || permissions?.d)">
              <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                <i class="icon-three-dots-vertical "></i>
              </button>
              <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem *ngIf="permissions?.e" (click)="editLetter(letter?.id)">
                  <i class="icon-pencil-square me-1"></i> Edit
                </button>
                <button class="text-danger" *ngIf="permissions?.d" ngbDropdownItem (click)="deleteAlert = true;deleteId = letter?.id">
                  <i class="icon-trash me-1"></i> Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
  </ng-template>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetails === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="vLoader"></app-view-loader>
        <ng-container *ngIf="!vLoader">
          <div class="row row-16">
            <div class="col-12 d-flex">
              <p class="text-uppercase fw-500 mb-0">Letter DETAILS</p>
              <a class="link-primary fs-14 ms-auto text-nowrap" *ngIf="permissions?.e && vData?.is_active" routerLink="/letter-template/letter-template-setup/{{vData?.id}}"><i
                  class="icon-pencil-square me-1"></i>
                Edit</a>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">LETTER TEMPLATE DETAILS</p>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Template name</div>
                    <div class="detail-desc">{{vData?.name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Module</div>
                    <div class="detail-desc">{{vData?.module?.name == undefined ? 'Others' : vData?.module?.name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc {{(vData?.is_active == true) ? 'text-success' : 'text-danger' }}">{{vData?.is_active ? 'Active' : 'Deleted'}}</div>
                  </div>
                  <div class="col-4 ">
                    <div class="detail-title">Letter type</div>
                    <div class="detail-desc">{{vData?.letter_type?.name}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">PREVIEW</p>
                  </div>
                  <div class=" col-12">
                    <div class="detail-title">Letter template</div>
                    <div class="detail-desc" *ngIf="vData?.pdf_url == ''">-</div>
                    <div  style="width:12.9375rem;" *ngIf="vData?.pdf_url != ''">
                      <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                        <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                        <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                          <span class="tips text-uppercase fs- fw-700 text-trim" *ngIf="vData?.file_name != ''">{{vData?.file_name}}</span>
                          <span class="tips-helper text-uppercase" *ngIf="vData?.file_name != ''">{{vData?.file_name}}</span>
                          <div class="d-flex gap-8">
                            <div class="link-primary" (click)="viewDocument(vData?.pdf_url)">
                              <i class="bi bi-eye me-1"></i>View
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{ deleteAlert == true ? 'show' : '' }}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="icon-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Letter Template?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">
          {{this.msgServ.Deletemsgdisplay('Letter Template')}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm fw-500 text-uppercase"
          (click)="deleteAlert = false">
          Cancel
        </button>
        <button type="button" class="btn btn-danger btn-sm fw-500 text-uppercase" [disabled]="deleteClicked"
          (click)="deleteLetter(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="closeModal($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>
</ng-container>

