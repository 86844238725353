
<!-- START -->
<div class="container-fluid p-24rem"  *ngIf="permissions">
  <div class="row mb-16rem">
      <div class="col-md-4 col-lg-6">
          <h3>Grade</h3>
          <p class="text-helper mb-md-0">Grades pertaining to employees designation can be added here</p>
      </div>
      <div class="col-md-8 col-lg-6">
          <div class="d-flex justify-content-end  gap-16rem">
              <div class="" style="width:22.6875rem;">
                  <div class="">
                      <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='gradesearch' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchGrade($event)" [isLoading]="isLoading"></app-searchbar>
                  </div>
                  <ul class="drop-down-menu search-result {{(inputFocusClass == true) ? 'active' : ''}}">
                      <p class="note"> No Result msg </p>
                      <p class="text-center text-helper mt-1">No Company Found...</p>
                      <p class="note"> Loader </p>
                      <p class="loader text-center">
                          <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
                      </p>
                      <p class="note"> Search Results</p>
                  </ul>
              </div>
              <div ngbDropdown class="d-inline-block">
                  <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                      <i class="icon-filter-left fs-13rem"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-end" >
                      <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';filterItemList('')" >All</button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';filterItemList(true)">Active </button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';filterItemList(false)">Deleted</button>
                  </div>
              </div>

              <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addheadercontent($event)"><i
                      class="icon-plus"></i>Add
                  Grade
              </button>
          </div>
      </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16rem" *ngIf="loader">
      <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata && loader==false;else all_data"  class="row" style="height: calc(100vh - 15rem);">
      <!-- <p class="note"> Display when no grade present </p> -->
      <!-- <div class="col-12 text-center">
          <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
              style="width: 27.375rem;">
          <p class="text-uppercase text-light-400 fw-700 fs-14rem ">NO GRADE ADDED YET</p>
          <a *ngIf="permissions.a" class="text-uppercase text-accent1 link-primary fw-700 fs-14rem" (click)="addheadercontent($event)">Add NOW</a>

      </div> -->
      <app-nodata-view class="vstack" [noDataImg]="'bg2.png'"  [noDataText]="'NO GRADE ADDED YET'"  [hasPermission]="permissions?.a" (addData)="addheadercontent($event)"> </app-nodata-view>

  </div>


  <ng-template #all_data><div class="row row-16rem " infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container  *ngIf="loader==false" >
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let grade of gradelist let i = index">
              <div class="card card-custom1 card-hover">
                  <div class="card-body d-flex p-0 align-items-start">
                    <div class="d-flex view-section" (click)="viewgrade(grade.id)">
                      <div class="logo-img {{grade.color_code}}" >{{this.appService.getFirstChar(grade.name,2)}}</div>
                      <div class="overflow-hidden">
                          <h6 class="mb-0 tips">{{grade.name}}</h6>
                          <span class="tips-helper">{{grade.name}}</span>
                          <span
                              class="fs-10 text-uppercase fw-500 {{(grade.is_active == true) ? 'text-success' : 'text-danger'}}">{{(grade.is_active
                              == true) ? 'Active' : 'Deleted'}}</span>
                      </div>
                    </div>


                  <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="grade.is_active && (permissions.e || permissions.d)">
                    <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                        ngbDropdownToggle>
                        <i class="icon-three-dots-vertical "></i>
                    </button>
                    <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                        <button *ngIf="permissions.e" ngbDropdownItem (click)="editheadercontent(grade.id,grade.is_active)">
                            <i   class="icon-pencil-square me-1"></i> Edit
                        </button>
                        <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deletegradefn(grade.id)">
                            <i class="icon-trash  me-1"></i> Delete
                        </button>
                    </div>
                </div>
                  </div>
              </div>
          </div>
      </ng-container>

       <!-- Loader updations -->
       <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
      </div>

  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
      <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
  </div> -->
  <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel1 === true'>
<form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
        <h5>Details</h5>
        <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg "></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid px-32rem py-24rem">
            <!-- View loader start -->
            <app-view-loader *ngIf="loader1"></app-view-loader>
            <!-- View loader end -->
            <ng-container *ngIf="!loader1">
              <div class="d-flex mb-16rem">
                  <p class="text-uppercase fw-500 mb-0"> GRADE</p>
                  <a class="link-primary fs-14rem d-flex align-items-center ms-auto" (click)="editheadercontent(gradeid,grade_is_active)" *ngIf="grade_is_active && (permissions.e)">
                      <i   class="icon-pencil-square me-1"></i> Edit
                  </a>
              </div>
              <div class="row row-16rem mb-16rem">
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24rem py-16rem">
                          <div class="row row-16rem " >
                              <div class="col-12 "><span class="title">GRADE DETAILS</span></div>
                              <div class="col-6">
                                  <div class="detail-title">Grade name</div>
                                  <div class="detail-desc">{{gradeView}}
                                  </div>
                              </div>
                              <div class="col-6">
                                <div class="detail-title">Status</div>
                                <div class="detail-desc">
                                  <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="grade_is_active">ACTIVE</span>
                                  <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="grade_is_active==false">DELETED</span>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>

              </div>
            </ng-container>
        </div>
    </div>
</form>
</div>
<!-- Save and Edit section start -->
<div class="side-panel" style="--sidepanel-width:23.75rem;" [class.side-pane-active]='showSidePanel === true'>
  <form [formGroup]="gradeForm" (ngSubmit)="onSubmit()" class="side-panel-container" autocomplete="off">
          <header class="side-panel-head">
              <h5><span [innerHTML]="header"></span> Grade</h5>
              <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg "></i></a>
          </header>
          <div class="side-panel-body" style="overflow-y: auto;">
              <div class="container-fluid px-32rem py-24rem">
                <!-- Edit loader start -->
                <app-edit-loader *ngIf="loader1" ></app-edit-loader>
                <!-- Edit loader end -->
                <ng-container *ngIf="!loader1" >
                  <div *ngIf="msg!=''" class="alert {{alertclass}}" role="alert">
                      Grade {{msg}} successfully.
                     <button class="btn-close" ></button>
                  </div>
                  <p class="text-uppercase fw-500 mb-12rem"><span [innerHTML]="header" class="text-uppercase"></span> GRADE DETAILS</p>
                  <div class="row row-12rem ">
                      <div class="col-12 form-row ">
                          <label for="name" class="form-label required">Grade name </label>
                          <!-- <input type="text" class="form-control {{((userPost.form.dirty && pattern == true) ||(userPost.form.dirty && validationCount == true) || (userPost.form.touched && validationmsg == true )) ? 'is-invalid' : ''}}" name="name" formControlName="name" id="name" placeholder="Add Grade Name" ngModel [(ngModel)]=gradeGetRes (ngModelChange)="nameChanged($event)">
                          <div *ngIf="userPost.form.touched && validationmsg==true" class="invalid-feedback">{{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="userPost.form.dirty && validationCount==true" class="invalid-feedback">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                          <div *ngIf="userPost.form.dirty && pattern==true" class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}</div> -->

                          <input type="text" class="form-control" formControlName="name" name="" id="name" value=""
                            placeholder="{{this.messageService.placeholderdisp('grade name')}}"
                            [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched)  &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
                        <div *ngIf="(submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                            <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                            <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        </div>

                      </div>
                  </div>
                </ng-container>
              </div>
          </div>
          <footer class="side-panel-footer gap-32rem"  *ngIf="permissions && !loader1">
              <button *ngIf="isactive==true && permissions.d" class="btn btn-outline-danger text-uppercase btn-sm" (click)="deleteToggle = true;" type="button" [disabled]='disable'>DELETE</button>
              <button *ngIf="(addEditBtn=='Save' && permissions.a) || (addEditBtn=='Save' && permissions.e)" class="ms-auto btn btn-primary text-uppercase btn-sm" type="submit" [disabled]='disable || !gradeForm.valid' ><span [innerHTML]="addEditBtn"></span></button>
          </footer>
      </form>
</div>
<!-- Save and Edit section end -->

<!-- Alert for delete start -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16rem">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Grade?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Grade')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteGrade()">Delete</button>
      </div>
    </div>
  </div>
</div>

