
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-checkin-approval',
  templateUrl: './checkin-approval.component.html',
  styleUrls: ['./checkin-approval.component.scss']
})
export class CheckinApprovalComponent implements OnInit {

  viewDetail                      = false;
  billLoader                      = false;
  viewstatusPanel                 = false;
  viewMapPanel          : boolean = false
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  alertShow                       = 'show';

  pageSize = 20;
  page = 1
  viewProfileModal = false;
  viewProfileImage = false;
  @Input() requestitem : any
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

  constructor(
    private inboxservice: InboxService,
    private investmentService:InvestmentDeclerationService,
    public appService : AppService,
    private fb : FormBuilder,
    public messageService : MessageService,
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(){
    this.page=1
  }
  request:any
  individualStatus : any
  // View Map
  showMap(req:any,status:any){
    this.request = req
    this.individualStatus = status
  }

  requestViewStatus(){
    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,
        // "read_status": read_status,
        // "star": star,
      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  checkall(event:any){
    var newList = this.requestitem?.requests
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    subArray?.forEach((item:any, key:any) => {
      if(item.status != 'Cancelled' &&  item.status != 'Invalid')
      item.checked = event.target.checked
    })
  }


  checkAllSelected(){
    var newList = this.requestitem?.requests
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
        if(item.status != 'Cancelled' && item.status != 'Invalid')
          return item.checked;
        else
          return false
      });
    } else {
      return false;
    }
  }

  form = this.fb.group({
    read_status: [true,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  get f() {return  this.form.controls; }


  refreshInbox(event: boolean){
    if(event){
      this.successEmitter.emit(event);
    }
  }
}
