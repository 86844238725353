<div class="container-fluid p-24">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Salary Revision</h3>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar [searchlist]='revDropdown' [searchplaceholder]="'Search'" (searchEmitter)="filterRule($event)"  [isLoading]="sLoading"
            (keywordsearchEmitter)="searchHistory($event)"></app-searchbar>
        </div>
        <button class="btn-square btn btn-secondary" (click)="showFilter = true;">
          <i class="icon-filter-left fs-13"></i>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="nodata && !loader;else all_data"  class="row " style="height: calc(100vh - 12rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg2.png'" [imgHeight]="'16rem'"
      [noDataText]="'Oops! No Revisions in Sight'"
      [noDataPara]="'It seems there are no salary revisions to display. Click “Revise” to get started and keep your data up-to-date!'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <!-- <ng-container > -->
    <app-common-loader class="col-lg-3 col-md-4 col-sm-6" [cardType]="'card4'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    <!-- </ng-container> -->
  </div>

  <ng-template #all_data>
    <div *ngIf="permissions" class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let item of items,let i = index">
          <div class="card card-c2 card-hover px-16" (click)="empid=item?.employee?.id;viewsalarydata();">
            <div class="card-body pb-8 px-0">
              <div class="d-flex gap-16">
                <img *ngIf="item?.employee?.profile_image !=  null && item?.employee?.profile_image !=  ''" class="avatar-circle avatar-border sq-45"
                  src="{{item?.employee.profile_image}}" />
                <div *ngIf="item?.employee?.profile_image ==  '' || item?.employee?.profile_image ==  null"
                  class="avatar-circle avatar-border fs-14 fw-500 {{item?.employee?.color_code}}  sq-45">{{this.appService.getFirstChar(item?.employee?.first_name+"
                  "+item?.employee?.last_name,2)}}</div>
                <div class="vstack text-trim">
                  <p class="mb-0 fs-12 fw-500 lh-base text-trim">{{item?.employee?.first_name+"
                "+(item?.employee?.middle_name!=null?item?.employee?.middle_name:'')+"
                "+item?.employee?.last_name}}</p>
                  <p class="mb-0 fs-10 fw-500 lh-base text-trim text-light-500 hstack gap-4">
                    <span>{{item?.employee?.employee_code}}</span> |
                    <span>{{item?.employee?.designation_name}}</span>
                  </p>
                  <span class="text-uppercase fs-10 fw-500
                                      {{(item?.employee.employment_status == 'Confirmed') ? 'text-success' :
                                    (item?.employee.employment_status == 'Probation') ? 'text-accent1':
                                    (item?.employee.employment_status == 'Relieved') ? 'text25':
                                    (item?.employee.employment_status == 'Resigned') ? 'text7':
                                    (item?.employee.employment_status == 'Settled') ? 'text23':
                                    (item?.employee.employment_status == 'Retired') ? 'text24':
                                    (item?.employee.employment_status == 'Terminated') ? 'text-danger': ' '}}
                                    ">{{item?.employee.employment_status}}</span>
                </div>
              </div>
            </div>
              <div class="card-body px-0 pt-8 pb-12 d-flex fs-12">
                <div class="me-8">
                  <div class="text-light-400">Last revision</div>
                  <div
                    class="fw-500 status1 {{item.status=='Pending'?'status-warning':item.status=='Accepted'?'status-success':'status-danger'}} ">
                    {{item.last_revision | date:'MMM y'}}</div>
                </div>
                <div class="ms-auto text-end">
                  <div class="text-light-400">Total revisions</div>
                  <div class="fw-500">{{item.total_revision}} {{(item.total_revision>1)?"Times":"Time"}}
                  </div>
                </div>
              </div>
          </div>
        </div>


      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-lg-3 col-md-4 col-sm-6" [cardType]="'card4'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
  </ng-template>

</div>
<div class="side-panel" style="--sidepanel-width:27rem;" [class.side-pane-active]='showFilter === true'
  [formGroup]="filterForm">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="showFilter = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 ">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">FILter by</p>
          </div>
          <div class="col-12 form-row">
            <app-master-select id="applicable_to" formControlName="applicable_to" [type]="'applicable_to'"
              (ngModelChange)="changeApplicableTo(0)" [placeholder]="'Select Filter By'"></app-master-select>
          </div>
          <div class="col-12" *ngIf="f.applicable_to.value!=null">
            <div class="row row-16"
              [ngClass]="{ 'is-invalid': (f.applicable_list.touched) &&  f.applicable_list.errors}">
              <div class="col-12 form-row" *ngIf="list?.length">
                <p class="text-uppercase fw-500 mb-0">{{f.applicable_to.value}} </p>
              </div>
              <div class=" col-12  form-row" *ngIf="list?.length">

                <ng-select *ngIf="f.applicable_to.value  !=  'Employee'" [ngClass]="{ 'is-invalid': (f.applicable_list.touched) &&  f.applicable_list.errors}"
                  placeholder="{{this.messageService.selectplaceholddisplay(f.applicable_to.value)}}"
                  formControlName="applicable_list" [items]="list" [multiple]="true" groupBy="selectedAllGroup"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                  bindValue="id" class="form-ngselect">

                  <ng-template *ngIf="f.applicable_to.value  !=  'Employee'" ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                      [ngModelOptions]="{standalone: true}" /> Select All
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="d-inline-flex align-items-center">
                      <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{ standalone : true }" />
                      <img class="avatar-sm sq-24 img-fluid rounded-circle me-8"
                        *ngIf="item.logo !=  null && item.logo !=  '' && f.applicable_to.value  ==  'Company'"
                        src="{{item.logo}}">
                      <span *ngIf="(item.logo ==  null || item.logo ==  '') && (f.applicable_to.value  ==  'Company')"
                        class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appService.getFirstChar(item?.name,1)}}</span></span>
                      <span class=""> {{ (item?.name?.length>25)? (item?.name |
                        slice:0:25)+'..':(item?.name) }} </span>
                    </div>
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="filterForm.value.applicable_list?.length ">
                      <span class="ng-value-label">{{filterForm.value.applicable_list?.length }}
                        {{f.applicable_to.value}} selected</span>
                    </div>
                  </ng-template>
                </ng-select>

                <ng-select *ngIf="f.applicable_to.value  ==  'Employee'" [ngClass]="{ 'is-invalid': (f.applicable_list.touched) &&  f.applicable_list.errors}"
                  placeholder="{{this.messageService.selectplaceholddisplay(f.applicable_to.value)}}"
                  formControlName="applicable_list" [items]="list" [multiple]="true"   bindLabel="name" [closeOnSelect]="false"
                  bindValue="id" class="form-ngselect">

                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="d-inline-flex align-items-center">
                      <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{ standalone : true }" />

                      <img class="avatar-sm img-fluid sq-24 rounded-circle me-8"
                        *ngIf="item.profile_image !=  null && item.profile_image !=  '' "
                        src="{{item.profile_image}}">
                      <span
                        *ngIf="(item.profile_image ==  null || item.profile_image ==  '') "
                        class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appService.getFirstChar(item.first_name+"
                          "+item.last_name,2)}}</span></span>
                      <span class=""> {{ (item?.name?.length>25)? (item?.name |
                        slice:0:25)+'..':(item?.name) }} </span>
                    </div>
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="filterForm.value.applicable_list?.length ">
                      <span class="ng-value-label">{{filterForm.value.applicable_list?.length }}
                        {{f.applicable_to.value}} selected</span>
                    </div>
                  </ng-template>
                </ng-select>


                <div *ngIf="(f.applicable_list.touched) && f.applicable_list.errors" class="invalid-feedback">
                  <div *ngIf="f.applicable_list.errors.required">
                    {{this.messageService.validationDisplay('required')}}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <ul class="selected-values " *ngIf="filterForm.value.applicable_list?.length != 0">
                  <li class="ng-value" *ngFor="let item of filterForm.value.applicable_list, let m =index">
                    <ng-container *ngFor="let insideitem of list, let k =index">
                      <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        <div class="d-inline-flex align-items-center">
                          <img class="avatar-sm sq-24 img-fluid rounded-circle"
                            *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null && f.applicable_to.value  ==  'Employee'"
                            src="{{insideitem.profile_image}}">
                          <span
                            *ngIf="(insideitem.profile_image ==  null || insideitem.profile_image ==  '') && (f.applicable_to.value  ==  'Employee')"
                            class="avatar-sm sq-24 me-8 rounded-circle bg{{k%5}}">
                            <span>
                              {{this.appService.getFirstChar(insideitem.first_name+"
                              "+insideitem.last_name,2)}}</span>
                          </span>

                          <img class="avatar-sm sq-24 img-fluid rounded-circle"
                            *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null && f.applicable_to.value  ==  'Company'"
                            src="{{insideitem.logo}}">
                          <span
                            *ngIf="(insideitem.logo ==  null || insideitem.logo ==  '') && (f.applicable_to.value  ==  'Company')"
                            class="avatar-sm sq-24 rounded-circle bg{{k%5}}">
                            <span>
                              {{this.appService.getFirstChar(insideitem.name,1)}}</span>
                          </span>
                          <span class=""> {{ (insideitem['name']?.length>15)?
                            (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])
                            }}</span>
                        </div>
                      </span>
                    </ng-container>
                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear(m)"><i
                        class="icon-close"></i></span>
                  </li>
                </ul>

              </div>

            </div>
          </div>


          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">LAST REVISION</p>
          </div>
          <div class="form-icon icon-right vertical-datepicker">
            <input class="form-control datepicker-vertical" type="text" opens="right" ngxDaterangepickerMd
              [closeOnAutoApply]="true" [showCustomRangeLabel]="true"
              [alwaysShowCalendars]="false" [ranges]="ranges" [linkedCalendars]="true" [locale]="{applyLabel: 'ok'}"
              [isInvalidDate]="isInvalidDate" [showClearButton]="true" placeholder="Select date"
              formControlName="last_revision">
            <i class="icon-calendar pointer-event"></i>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">TOTAL REVISIONS</p>
          </div>
          <div class="col-12 form-row">
            <input placeholder="Enter value" class="form-control" formControlName="total_revision"
              [ngClass]="{ 'is-invalid': ( f.total_revision.dirty) &&  f.total_revision.errors}">
            <div *ngIf="( f.total_revision.dirty) && f.total_revision.errors" class="invalid-feedback">
              <div *ngIf="f.total_revision.errors.pattern">
                {{this.messageService.validationDisplay('pattern')}}</div>
              <div *ngIf="f.total_revision.errors.maxlength && !(f.total_revision.errors.pattern)">
                {{this.messageService.fieldlengthvalidation('num','10')}}</div>

            </div>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">MINIMUM ANNUAL CTC</p>
          </div>
          <div class="col-12 form-row">
            <input placeholder="Enter amount" class="form-control" formControlName="min_ctc"
              [ngClass]="{ 'is-invalid': ( f.min_ctc.touched || f.min_ctc.dirty) &&  f.min_ctc.errors}">

            <div *ngIf="( f.min_ctc.touched || f.min_ctc.dirty) && f.min_ctc.errors" class="invalid-feedback">

              <div *ngIf="f.min_ctc.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
              </div>
              <div *ngIf="(f.min_ctc.errors.maxlength && !(f.min_ctc.errors.pattern))">
                {{this.messageService.fieldlengthvalidation('num','10')}}

              </div>
            </div>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">MAXIMUM ANNUAL CTC</p>
          </div>
          <div class="col-12 form-row">
            <input placeholder="Enter amount" class="form-control" formControlName="max_ctc"
              [ngClass]="{ 'is-invalid': ( f.max_ctc.touched || f.max_ctc.dirty) &&  f.max_ctc.errors || filterForm.errors!=null}">

            <div *ngIf="( f.max_ctc.touched || f.max_ctc.dirty) && f.max_ctc.errors || filterForm.errors!=null"
              class="invalid-feedback">

              <div *ngIf="f.max_ctc.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
              </div>
              <div *ngIf="(f.max_ctc.errors?.maxlength && !(f.max_ctc.errors?.pattern))">
                {{this.messageService.fieldlengthvalidation('num','10')}}

              </div>
              <div *ngIf="filterForm.errors!=null">{{this.messageService.greaterThanMessage('Minimum
                annual CTC', 'Maximum annual CTC')}}</div>
            </div>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">Gender</p>
          </div>
          <div class="col-12">
            <div class="form-check ">
              <input class="form-check-input" name="genderMale" type="checkbox" id="genderMale" value=""
                formControlName="genderMale">
              <label class="form-check-label" for="genderMale">
                Male
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check ">
              <input class="form-check-input" name="genderFemale" type="checkbox" id="genderFemale" value=""
                formControlName="genderFemale">
              <label class="form-check-label" for="genderFemale">
                Female
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check ">
              <input class="form-check-input" name="genderOther" type="checkbox" id="genderOther" value=""
                formControlName="genderOther">
              <label class="form-check-label" for="genderOther">
                Other
              </label>
            </div>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">Sort by</p>
          </div>
          <div class="col-12">
            <div class="form-check ">
              <input class="form-check-input" name="sortby" id="all" type="radio" value="all" formControlName="sortby">
              <label class="form-check-label" for="sortby">
                All
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check ">
              <input class="form-check-input" name="sortby" id="latest" type="radio" value="latest"
                formControlName="sortby">
              <label class="form-check-label" for="sortby">
                Latest
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check ">
              <input class="form-check-input" name="sortby" id="name_asc" type="radio" value="name_asc"
                formControlName="sortby">
              <label class="form-check-label" for="sortby">
                Alphabetically (A-Z)
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check ">
              <input class="form-check-input" name="sortby" id="name_desc" type="radio" value="name_desc"
                formControlName="sortby">
              <label class="form-check-label" for="sortby">
                Alphabetically (Z-A)
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">

      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
        (click)="resetForm();showFilter = false;">
        reset
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="filterForm.invalid"
        (click)="showFilter = false;applyFilter()">
        Apply
      </button>
    </footer>
  </div>
</div>
<!-- SALARY REVISION HISTORY VIEW START -->
<div class="side-panel" style="--sidepanel-width:59.0625rem;" [class.side-pane-active]='salaryRevisionHistory === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Salary Revision History</h5>
      <a class="toggle-panel" (click)="salaryRevisionHistory = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <app-salary-revision-history-view *ngIf='salaryRevisionHistory === true' [employeeId]="empid"
        [buttonActivate]="true" [from]="from"></app-salary-revision-history-view>

    </div>
  </div>
</div>
<!-- SALARY REVISION HISTORY VIEW START -->
