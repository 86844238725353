<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='addeditpanel === true'>
    <form [formGroup]="geolocationForm" (ngSubmit)="onSubmit()" class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>{{header}} geo location</h5>
            <a class="toggle-panel" (click)="closefn()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <app-c1-loader *ngIf="loader"></app-c1-loader>
                
                    <div class="row row-16 ">
                        <ng-container *ngIf="!loader">
                            <div class="col-12">
                                <p class="text-uppercase fw-500 mb-0">{{header}} location details</p>
                            </div>
                            <div class="col-12">
                                <label for="" class="form-label required">Location name </label>
                                <input type="text" class="form-control" formControlName="location_name" name="" id="name"
                                    value="" placeholder="{{this.messageService.placeholderdisp('Location name')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.location_name.dirty || f.location_name.touched)  &&  f.location_name.errors || f.location_name.errors?.exist}"
                                    (keyup)="keyUp()">
                                <div *ngIf="(submitted || f.location_name.dirty || f.location_name.touched) && f.location_name.errors || f.location_name.errors?.exist"
                                    class="invalid-feedback">
                                    <div *ngIf="f.location_name.hasError('exist')">
                                        {{this.messageService.validationDisplay(f.location_name.value)}}</div>
                                    <div *ngIf="f.location_name.errors?.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.location_name.errors?.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="!(f.location_name.errors?.pattern) && f.location_name.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div role="alert" class="alert alert-warning py-10 show" *ngIf="isOpen">
                                    <p class="mb-0 fs-14  ">If you are unsure of the geo coordinates, you can use map search
                                        option to easily locate the desired area.</p>
                                    <div class="btn-close pointer" (click)="isOpen=false;"></div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="" class="form-label required">Latitude </label>
                                <input type="text" formControlName="latitude" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('Latitude')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.latitude.touched || f.latitude.dirty) &&  f.latitude.errors || f.latitude.errors?.exist}">
                                <div *ngIf="(submitted || f.latitude.touched || f.latitude.dirty) && f.latitude.errors || f.latitude.errors?.exist"
                                    class="invalid-feedback">
                                    <div *ngIf="f.latitude.errors?.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="!f.latitude.errors?.pattern && f.latitude.errors?.max">
                                        {{this.messageService.fieldlengthvalidation('value','9')}}
                                    </div>
                                    <div *ngIf="f.latitude.errors?.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="" class="form-label required">Longitude </label>
                                <input type="text" formControlName="longitude" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('Longitude')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.longitude.touched || f.longitude.dirty) &&  f.longitude.errors || f.longitude.errors?.exist}">
                                <div *ngIf="(submitted || f.longitude.touched || f.longitude.dirty) && f.longitude.errors || f.longitude.errors?.exist"
                                    class="invalid-feedback">
                                    <div *ngIf="f.longitude.errors?.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="!f.longitude.errors?.pattern && f.longitude.errors?.max">
                                        {{this.messageService.fieldlengthvalidation('value','9')}}</div>
                                    <div *ngIf="f.longitude.errors?.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="" class="form-label required">Radius (m) </label>
                                <input type="text" formControlName="radius" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('Radius')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.radius.touched || f.radius.dirty) &&  f.radius.errors || f.radius.errors?.exist}">
                                <div *ngIf="(submitted || f.radius.touched || f.radius.dirty) && f.radius.errors || f.radius.errors?.exist"
                                    class="invalid-feedback">
                                    <div *ngIf="f.radius.errors?.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="!f.radius.errors?.pattern && f.radius.errors?.max">
                                        {{this.messageService.fieldlengthvalidation('value','1000')}}</div>
                                    <div *ngIf="!f.radius.errors?.pattern && f.radius.errors?.min">
                                        {{this.messageService.fieldlengthvalidation('minvalue','100')}}</div>
                                    <div *ngIf="f.radius.errors?.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12">
                            <div class="position-relative">
                                <div class="position-absolute z-1" style="top:1rem;left:1rem;">
                                    <div class="form-icon icon-left h-40  " style="width: 20.625rem;">
                                        <i class="icon-search"></i>
                                        <input type="text" class="form-control"
                                            (keydown.enter)="$event.preventDefault()"
                                            placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off"
                                            spellcheck="off" type="text" #locsearch>
                                    </div>
                                </div>
                                <agm-map style="height: 450px;" [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                    [styles]="styles">
                                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"
                                        [iconUrl]="'/assets/images/helper/ico-location.png'"
                                        (dragEnd)="markerDragEnd( $event)">
                                    </agm-marker>
                                    <agm-circle [latitude]="lat" [longitude]="lng" [circleDraggable]="false"
                                        [editable]="false" [fillColor]=" '#31AAB780'" [radius]="f.radius.value*1"
                                        [strokeColor]=" '#31AAB7'" [strokeWeight]="1"></agm-circle>
                                </agm-map>
                            </div>


                        </div>
                    </div>
                <!-- </ng-container> -->
            </div>
        </div>

        <footer class="side-panel-footer">
            <button type="submit" class="  btn btn-outline-primary text-uppercase btn-sm" (click)="closefn()">
                Cancel
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                [disabled]='!geolocationForm.valid'>
                SUBMIT
            </button>
        </footer>
    </form>
</div>
