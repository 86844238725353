

    <div class="col-12 d-flex gap-16  py-16">
        <div class="d-flex gap-16">
            <p class="mb-0 d-flex align-items-center gap-6">
                <span class="fw-600">{{countSelected()}}</span>
                <span class="fs-14">Requests selected</span>
            </p>
            <button class="btn btn-outline-danger btn-icon fs-14 radius-2" [disabled]="countSelected()==0" (click)="approveReject('Rejected')"> <i class="icon-close-circle"></i>
                Reject</button>
            <button class="btn btn-outline-success btn-icon fs-14 radius-2"  [disabled]="countSelected()==0" (click)="approveReject('Approved')"> <i class="icon-check-circle"></i>
                Approve</button>
        </div>
        <button class=" btn btn-primary text-uppercase ms-auto btn-sm fw-400"   (click)="confirm()">Submit</button>
    </div>

