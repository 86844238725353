<div class="container-fluid p-32  form-container">
  <app-view-loader *ngIf="viewLoader"></app-view-loader>
  <ng-container *ngIf="!viewLoader">
    <div class="row row-16">
      <div class="col-12">
        <div class="card details-card">
          <div class="card-body p-16 d-flex align-items-start">
            <img *ngIf="data.employee?.profile_image !=  null && data.employee?.profile_image !=  ''"
              class="img-fluid avatar-circle  sq-42 me-16" src="{{data.employee.profile_image}}" />
            <div *ngIf="data.employee?.profile_image ==  '' || data.employee?.profile_image ==  null"
              class="avatar-circle avatar-border sq-42  me-16 {{data.employee?.color_code}} fs-14">
              {{this.appService.getFirstChar(data.employee?.first_name+" "+data.employee?.last_name,2)}}</div>
            <div class="d-flex gap-6 flex-column me-8">
              <p class="fs-14 fw-600 mb-0">{{data.employee?.first_name+"
                "+(data.employee?.middle_name!=null?data.employee?.middle_name:'')+" "+data.employee?.last_name}}
                ({{data.employee?.employee_code}})</p>
              <div class="fs-12 fw-500 text-light-400 text-break">{{data.employee?.designation}} |
                {{data.employee?.company}}</div>
            </div>
            <ng-container *ngIf="data.approved_person_list.length==0 && (from != 'inbox' || from!='Inbox view')">
              <div class="card-body d-flex  flex-center text-center py-16">
                <div class="fs-12 text-muted">Loading </div>
                <div class="ms-4 dot-pulse"></div>
              </div>
            </ng-container>
            <!-- <div class=" ms-auto  fs-12 fw-500"  *ngIf="data?.approved_person_list.length==1 && from != 'inbox'">
                        <div class=" fs-12 d-flex align-items-center gap-8">
                            <i class="bi fs-16  bi-square-fill {{data.approved_person_list[0]['status'] == 'Approved'?'text-success':data.approved_person_list[0]['status'] == 'Pending'?'text-warning':'text-danger'}}  "></i>
                            <span class="fw-600 text-break">
                              {{data.approved_person_list[0]['status'] == 'Approved'?'Approved by':data.approved_person_list[0]['status'] == 'Pending'?'Pending with':'Rejected by'}}
                              <span class="fw-600">{{data.approved_person_list[0].employee_data[0]?.first_name+" "+(data.approved_person_list[0].employee_data[0].middle_name!=null?data.approved_person_list[0].employee_data[0].middle_name+" ":" ")+data.approved_person_list[0].employee_data[0].last_name}} ({{data.approver[0]?.employee_code}})</span></span>
                          </div>
                    </div> -->
            <div class="d-flex align-items-center ms-auto gap-8 text-nowrap">
              <span
                class="badge px-16 ms-auto fs-14 {{data?.status=='Pending' ? 'badge-warning': (data?.status=='Rejected' || data?.status=='Invalid') ? 'badge-danger':'badge-success'}} py-1">{{data?.status}}</span>
              <ng-container *ngIf="from != 'inbox' && from!='Inbox view' && from != 'profile'">
                <span class="text-light-400">|</span>
                <span class="link-primary fs-14" (click)="requestViewStatus(data?.request_obj_id)">View Status</span>
              </ng-container>
              <ng-container *ngIf="from == 'inbox'">
                <span class="text-light-400">|</span>
                <div class="text-end link-primary1 fs-12 fw-500 d-flex align-items-center"
                  (click)="salaryRevisionHistory = true"> <i class="icon-eye me-1"></i> Salary Revision History</div>
              </ng-container>
            </div>
          </div>
          <div class="card-body p-16" *ngIf="tableData?.length>0 && (from == 'inbox' || from=='Inbox view')">
            <ul class="timeline timeline-vetical">
              <li class="timeline-item " *ngFor="let item of tableData,let i = index">
                <div class="timeline-title bg{{i%3 +1}}">
                  {{item.salary_revision.year}}
                </div>
                <div class="timeline-content ">
                  {{item.salary_revision.designation}}
                </div>
              </li>
            </ul>
          </div>
          <!-- <ng-container *ngIf="from != 'inbox' && data.approved_person_list.length>1">
                    <div class="card-body p-16">
                        <div class="dropdown_progres">
                            <ul class="progress_drp progress_vertical">
                              <ng-container *ngFor="let item of data.approved_person_list;let i = index">
                                <li class="progress__item progress__item--accepted"  *ngIf="item['status'] == 'Approved'" >
                                  <div class="progress__tile text-center ">
                                    <div class="fs-12">
                                      <div class="">Approved by </div>
                                      <div class="" >{{item.employee_data[0]?.first_name+" "+(item.employee_data[0].middle_name!=null?item.employee_data[0].middle_name+" ":" ")+item.employee_data[0].last_name}}</div>
                                    </div>
                                  </div>
                                </li>
                                <li class="progress__item progress__item--rejected" *ngIf="item['status'] == 'Rejected'" >
                                  <div class="progress__tile text-center " >
                                    <div class="fs-12">
                                      <div class="">Rejected by </div>
                                        <div class="">{{item.employee_data[0]?.first_name+" "+(item.employee_data[0].middle_name!=null?item.employee_data[0].middle_name+" ":" ")+item.employee_data[0].last_name}}</div>
                                   </div>
                                  </div>
                                </li>
                                <li class="progress__item progress__item--pending" *ngIf="item['status'] == 'Pending'" >
                                  <div class="progress__tile text-center ">
                                    <div class="fs-12">
                                      <div class="">Pending with </div>
                                        <div class="">{{item.employee_data[0]?.first_name+" "+(item.employee_data[0].middle_name!=null?item.employee_data[0].middle_name+" ":" ")+item.employee_data[0].last_name}}</div>
                                   </div>
                                  </div>
                                </li>
                                <ng-container *ngIf="item['action'] == 'Intimation'" >
                                  <li class="progress__item progress__item--intimation" *ngFor="let data of item.employee_data">
                                    <div class="progress__tile text-center " >
                                      <div class="fs-12">
                                        <div class="">Intimation sent to </div>
                                            <div class="">{{data?.first_name+" "+(data.middle_name!=null?data.middle_name+" ":" ")+data.last_name}}</div>
                                     </div>
                                    </div>
                                  </li>
                                </ng-container>
                              </ng-container>
                             </ul>
                        </div>
                  </div>
                </ng-container> -->
          <ng-container *ngIf="from == 'inbox' || from=='Inbox view'">
            <div class="card-body p-16">
              <div class="row">
                <div class="col-sm-3 col-6">
                  <div class="detail-title">Current pay group</div>
                  <div class="detail-desc">{{data.current_pay_group}}</div>
                </div>
                <div class="col-sm-3 col-6">
                  <div class="detail-title">Current annual CTC</div>
                  <div class="detail-desc">{{data?.current_ctc| currency:empSalaryData?.currency}} </div>
                </div>
                <div class="col-sm-3 col-6">
                  <div class="detail-title">Current monthly payout</div>
                  <div class="detail-desc">{{data?.current_ney_pay_monthly| currency:empSalaryData?.currency}} </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="card-body p-16">
            <div class="row">
              <div class="col-sm-3 col-6">
                <div class="detail-title">New pay group</div>
                <div class="detail-desc">{{data.pay_group}}</div>
              </div>
              <div class="col-sm-3 col-6">
                <div class="detail-title">Revised annual CTC</div>
                <div class="detail-desc">
                  <!-- {{data?.revise_ctc| currency:empSalaryData?.currency}} <span
                    class="{{data.increment>0?'graph-increase text-success':'graph-decrease text-danger'}} ms-8 ">
                    {{data.increment>0?(data.increment| currency: empSalaryData?.currency):data.increment*-1 |
                    currency: empSalaryData?.currency}}
                  </span> -->
                <div class="d-flex flex-wrap gap-8">
                  <span class="text-nowrap">{{data?.revise_ctc| currency:empSalaryData?.currency}}</span><span class="text-nowrap {{data.increment>0?'graph-increase text-success':'graph-decrease text-danger'}}">
                    {{data.increment>0?(data.increment| currency: empSalaryData?.currency):data.increment*-1 |
                      currency: empSalaryData?.currency}}
                  </span>
                </div>
                </div>

              </div>
              <div class="col-sm-3 col-6">
                <div class="detail-title">Effective from </div>
                <div class="detail-desc">{{data.effective_from}}</div>
              </div>
              <div class="col-sm-3 col-6">
                <div class="detail-title">Pay month</div>
                <div class="detail-desc">{{data.salary_pay_month}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 fw-500 text-uppercase">Pay COMPONENTS </div>
      <div class="col-12">
        <div class="card card-c2 py-24 p-16">
          <div class="row row-24">
            <div class="col-12" *ngIf="gross_benefits?.length>0">
              <div class="fw-500">Gross benefits</div>
            </div>
            <div class="col-12" *ngIf="gross_benefits?.length>0">
              <div class="card card-c2 ">
                <div class="table-responsive">
                  <table class="table table-striped form-table  table-sm ">
                    <thead>
                      <tr>
                        <th scope="col">Component name</th>
                        <th scope="col">Nature</th>
                        <th scope="col">Formula</th>
                        <th scope="col">Monthly Pay</th>
                        <th scope="col">Yearly Pay</th>
                        <th scope="col">Months</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let gross of gross_benefits">
                        <td>{{gross.name}}</td>
                        <td>{{gross.nature==true?'Fixed':'Variable'}}</td>
                        <td>{{gross.formula}}</td>
                        <td>{{gross.monthly_renum | currency:empSalaryData?.currency}}</td>
                        <td>{{gross.year_renum | currency:empSalaryData?.currency}}</td>
                        <td>{{monthManipulation(gross.pay_month)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="other_benefits?.length>0">
              <div class="fw-500">Other benefits</div>
            </div>
            <div class="col-12" *ngIf="other_benefits?.length>0">
              <div class="card card-c2 ">
                <div class="table-responsive">
                  <table class="table table-striped form-table table-sm ">
                    <thead>
                      <tr>
                        <th scope="col">Component name</th>
                        <th scope="col">Nature</th>
                        <th scope="col">Formula</th>
                        <th scope="col">Monthly Pay</th>
                        <th scope="col">Yearly Pay</th>
                        <th scope="col">Month</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let other of other_benefits">
                        <td>{{other.name}}</td>
                        <td>{{other.nature==true?'Fixed':'Variable'}}</td>
                        <td>{{other.formula}}</td>
                        <td>{{other.monthly_renum | currency:empSalaryData?.currency}}</td>
                        <td>{{other.year_renum | currency:empSalaryData?.currency}}</td>
                        <td>{{monthManipulation(other.pay_month)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="contributions?.length>0">
              <div class="fw-500">Contribution/Retirals</div>
            </div>
            <div class="col-12" *ngIf="contributions?.length>0">
              <div class="card card-c2 ">
                <div class="table-responsive">
                  <table class="table table-striped  table-sm ">
                    <thead>
                      <tr>
                        <th scope="col">Component name</th>
                        <th scope="col">Monthly remuneration</th>
                        <th scope="col">Yearly remuneration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let contributions of contributions">
                        <td>{{contributions.contribution}}</td>
                        <td>{{contributions.monthly_renum | currency:empSalaryData?.currency}}</td>
                        <td>{{contributions.year_renum | currency:empSalaryData?.currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="recurring_deductions?.length>0">
              <div class="fw-500">Recurring deductions</div>
            </div>
            <div class="col-12" *ngIf="recurring_deductions?.length>0">
              <div class="card card-c2 ">
                <div class="table-responsive">
                  <table class="table table-striped form-table table-sm ">
                    <thead>
                      <tr>
                        <th scope="col">Component name</th>
                        <th scope="col">Nature</th>
                        <th scope="col">Formula</th>
                        <th scope="col">Monthly Pay</th>
                        <th scope="col">Yearly Pay</th>
                        <th scope="col">Month</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let recurring of recurring_deductions">
                        <td>{{recurring.name}}</td>
                        <td>{{recurring.nature==true?'Fixed':recurring.nature==false?'Variable':''}}</td>
                        <td>{{recurring.formula}}</td>
                        <td>{{recurring.monthly_renum | currency:empSalaryData?.currency}}</td>
                        <td>{{recurring.year_renum | currency:empSalaryData?.currency}}</td>
                        <td>{{monthManipulation(recurring.pay_month)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex fw-500"> <span class="">Gross pay</span><span
                class="ms-auto">{{empSalaryData?.gross_amount| currency:empSalaryData?.currency}}</span> </div>
            <div class="col-12 d-flex fw-500"> <span class="">Net take home (excluding TDS)</span><span
                class="ms-auto">{{empSalaryData?.net_gross| currency:empSalaryData?.currency}}</span> </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <!-- **********************************Approve/Reject Code*************************************************** -->
        <form class="row row-16" *ngIf="(from =='inbox' || from=='Inbox view') && data?.status=='Pending'" [formGroup]="form">
          <ng-container *ngIf="!requestitem?.achieved_status">
            <div class="col-12">
              <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..."
                class="form-control textarea-comment" formControlName="comment"
                [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
              <div *ngIf="f.comment.errors" class="invalid-feedback">
                <div *ngIf="f.comment.errors?.maxlength">Maximum 500 characters allowed.</div>
              </div>
            </div>
            <div class="col-12 d-flex gap-16">
              <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                (click)="confirm('Rejected')">Decline</button>
              <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
                (click)="confirm('Approved')">Confirm</button>
            </div>
          </ng-container>
        </form>
        <!-- *********************************************************************************************************  -->
      </div>
    </div>
  </ng-container>
</div>
<!-- View status start -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <app-view-status [loader]="loader1" [approvalRequired]="approvalRequired" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>
    </form>
  </div>
</ng-container>
<!-- View status end -->
<!-- SALARY REVISION HISTORY VIEW START -->
<div class="side-panel" style="--sidepanel-width:59.0625rem;" [class.side-pane-active]='salaryRevisionHistory === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Salary revision history </h5>
      <a class="toggle-panel" (click)="salaryRevisionHistory = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <app-salary-revision-history-view *ngIf='salaryRevisionHistory === true' [from]="'Inbox view'"
        [employeeId]="data.employee.id" [buttonActivate]="false"></app-salary-revision-history-view>
    </div>
  </div>
</div>
