import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { AuthService } from '../auth/auth.service';
@Injectable()
export class ResponseInterceptor implements HttpInterceptor{
  cnt = 0
    constructor(
        private notificationService: NotificationService,private authService:AuthService
      ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        return next.handle(request).pipe(
            tap((data:HttpEvent<any>)=>{
              let flag = false;
              let reqbody:any = request.body
              if((request.url.includes('inbox-detail') || request.url.includes('inbox-checkin-update') || (request.url.includes('inbox-update') && (!reqbody.approved_status))  || request.url.includes('inbox-reimbursebill-update') || request.url.includes('inbox-onduty-request-date-update') || request.url.includes ('inbox-attendance-regularization-request-date-update') ||request.url.includes('disable_active_employee') || request.url.includes('enable_deactive_employee') || request.url.includes('fnf-checkin-update')|| request.url.includes('fnf-onduty-update')|| request.url.includes('fnf-regularizarion-update')) && (request.method == "PUT" || request.method == "POST")){
                flag = true;
              }
              if(data instanceof HttpResponse && data.status === 201 && (!data.body.hasOwnProperty('error_flag') || (data.body.hasOwnProperty('error_flag') && data.body.error_flag==false)) && (!data.body.hasOwnProperty('data_already_exists') || (data.body.hasOwnProperty('data_already_exists') && data.body.data_already_exists == false)) && !request.url.includes('disable_active_employee') && !request.url.includes('report-filter-save') && !request.url.includes('assign-shift-create') && !request.url.includes('generate-letter') && !request.url.includes('locations-assigned-profile-create')){
                this.notificationService.handleSuccessNotification("Created Successfully","Success");
              } 
              else if((data instanceof HttpResponse && data.status === 200 || data instanceof HttpResponse && data.status === 204) && ((request.method == "PATCH" || request.method == "DELETE") || (request.method === 'POST' && data.body.hasOwnProperty('deleted_status'))) && !request.url.includes('overide-delete') && !request.url.includes('notification-configurations') && !request.url.includes('notification-quick-link') ){
                this.notificationService.handleSuccessNotification("Deleted Successfully","Success");
              }
              else if(data instanceof HttpResponse && data.status === 200 && request.method == "PUT" && !flag && (!data.body.hasOwnProperty('profile_reset') && (!data.body.hasOwnProperty('is_revision') ||  (data.body.hasOwnProperty('is_revision') && data.body.is_revision==false))) && (!data.body.hasOwnProperty('is_flag')) && (!data.body.hasOwnProperty('is_generate')) && (!request.url.includes('expense-receipt'))){
                this.notificationService.handleSuccessNotification("Updated Successfully","Success");
              }
              else if(data instanceof HttpResponse && data.status === 200 && request.method == "POST" && data.body.hasOwnProperty('profile_reset') && !flag){
                this.notificationService.handleSuccessNotification("Updated Successfully","Success");
              }
              else if(data instanceof HttpResponse && data.status === 200 && request.method == "POST" && data.body.employee_request == '1' && request.url.includes('employee-temporary') && !flag){
                this.notificationService.handleSuccessNotification(data.body.message,"Success");
              }
            }),
            catchError(error => {
              if ( error.status === 400 ||  error.status === 409 )  {
                if((error.status === 409 && (request.url.includes('get-otp'))) || (error.error.hasOwnProperty('seperation-policy-create'))){

                }

                //   else if(error.status === 409 && !request.url.includes('fnf-process-save') && !request.url.includes('final-settlement-save') && !request.url.includes('pay-group-detail') &&  !(error.error.hasOwnProperty('ctc_error')) && !(error.error.hasOwnProperty('reset'))  && !(error.error.hasOwnProperty('employee_code')) && !(error.error.hasOwnProperty('work_email')) && !(error.error.hasOwnProperty('mobile_number')) && (!(error.error.hasOwnProperty('leave_status'))) && !(error.error.hasOwnProperty('statuatory_error')) && !(error.error.hasOwnProperty('component_error'))  && !(error.error.hasOwnProperty('process_error')) && !request.url.includes('pay-group-create')){
                //   this.notificationService.handleErrorNotification("Something went to wrong.","Error");

                //  }
                else if(error.error.hasOwnProperty('name')){
                  this.notificationService.handleErrorNotification(error.error.name,"Error");
                }
                else if(error.error.hasOwnProperty('message')){
                  this.notificationService.handleErrorNotification(error.error.message,"Error");
                }
                else if(error.error.hasOwnProperty('error')
                  && !(error.error.hasOwnProperty('statuatory_error'))
                  && !(error.error.hasOwnProperty('reset'))
                  && !(error.error.hasOwnProperty('component_error'))
                  && !(error.error.hasOwnProperty('leave_status'))
                  && !(error.error.hasOwnProperty('category_error'))
                  || error.error.hasOwnProperty('name_error')
                  ){
                    this.notificationService.handleErrorNotification(error.error.error,"Error");
                  }
                else{

                  if(!(error.error.hasOwnProperty('reset')) && !(error.error.hasOwnProperty('status')) && !(error.error.hasOwnProperty('employee_code')) && !(error.error.hasOwnProperty('work_email')) && !(error.error.hasOwnProperty('mobile_number')) && (!(error.error.hasOwnProperty('leave_status'))) && !(error.error.hasOwnProperty('statuatory_error')) &&  !(error.error.hasOwnProperty('component_error')) &&  !(error.error.hasOwnProperty('pan_number'))){

                    var value:any = "Error Occured";
                    var errorKey = '';
                    Object.keys(error.error).forEach(function(key) {
                      if(typeof error.error[key] === 'string' || error.error[key] instanceof String){
                        value = error.error[key];
                        errorKey = key
                      } else if(typeof error.error[key][0] === 'string' || error.error[key][0] instanceof String){
                        value = error.error[key][0];
                        errorKey = key
                      }
                    });
                    if(errorKey == 'is_salary_processed_in_payout'){
                      value = 'The salary for the month is already processed, unprocess it to approve salary revision.'
                    }
                    this.notificationService.handleErrorNotification(value,"Error");
                  } else if(error.error.hasOwnProperty('error_info')){
                    Object.keys(error.error.error_info).forEach((key) => {
                      if(error.error.error_info[key]?.error){
                        Object.entries(error.error.error_info[key]?.message) .forEach(([key, value]) => {
                          this.notificationService.handleErrorNotification(value,"Error");
                        });
                      } 
                    });
                  }

                }
                // else
                // this.notificationService.handleErrorNotification("Error Occured","Error");
              } else if(error.status === 500 ) {
                if(error instanceof HttpErrorResponse){
                  if(error.error.hasOwnProperty('detail')){
                    this.notificationService.handleErrorNotification(error.error.detail,"Error");
                  }else{
                    this.notificationService.handleErrorNotification(error.error.error,"Error");
                  }
                }
              }  else if(error.status === 403){
                this.cnt ++;
                if(this.cnt == 1)
                  this.notificationService.handleErrorNotification(error.error.error,"Error");
                this.authService.doLogout()
              }
              return throwError(error);
            })
          );
    }
}
