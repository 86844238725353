<div class="container-fluid p-24 "  *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Expense Policy</h3>
            <p class="text-helper mb-md-0">Configure your expense policies here.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div class="form-icon " style="width:22.6875rem;">
                    <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='catPlaceholder' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchexpense($event)" [isLoading]="isLoading"></app-searchbar>
                </div>
                <!-- <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13rem"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                      <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';statusfilter('')">All</button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';statusfilter(true)">Active </button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
                    </div>
                </div> -->
                <button  class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a" (click)="addNewdata()"><i
                    class="icon-plus"></i>ADD Expense Policy</button>
            </div>
        </div>
    </div>
    <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg2.png'" [noDataText]="'Expense Guidelines!'"
        [noDataPara]="'Your expense policies aren’t configured yet. Click \'Add Expense Policy\' to define and manage your company’s expenses'"
        [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
    </div>
    <div class="row row-16rem" *ngIf="loader">
     <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
    <ng-template #all_data>
      <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
        <ng-container *ngIf="loader==false">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let policy_data of exppolicyData;let i=index">
                <div class="card card-custom1 card-hover">
                    <div class="card-body p-0 d-flex align-items-start">
                        <div class="d-flex view-section" (click)="viewdata(policy_data.id)">
                            <div class="logo-img {{policy_data.color_code}}">{{this.appService.getFirstChar(policy_data.name,2)}} </div>
                            <div class="overflow-hidden">
                                <h6 class="mb-1 tips">{{policy_data.name}}</h6>
                                <span class="tips-helper">{{policy_data.name}}</span>
                                <div class="fs-10 text-uppercase fw-500  text-success " *ngIf="policy_data.is_active">Active
                                </div>
                                <div class="fs-10 text-uppercase fw-500  text-danger " *ngIf="policy_data.is_active==false">Deleted
                                </div>
                            </div>
                        </div>
                        <div ngbDropdown class="d-inline-block dropdown-section"  *ngIf="policy_data.is_active && permissions">
                            <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                                <i class="icon-three-dots-vertical "></i>
                            </button>
                            <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                                <button ngbDropdownItem (click)="editpolicy(policy_data.id)" *ngIf="permissions?.e">
                                    <i class="icon-pencil-square me-1"></i> Edit
                                </button>
                                <!-- <button class="text-danger" ngbDropdownItem (click)="deleteId=policy_data.id;deleteAlert=true;" *ngIf="permissions?.d">
                                    <i class="icon-trash  me-1"></i> Delete
                                </button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="infinityloader">
            <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </ng-template>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel " (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <app-view-loader *ngIf="viewLoader"></app-view-loader>
                <ng-container *ngIf="!viewLoader" >
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0">EXPENSE policy</p>
                    <a class="link-primary fs-14 ms-auto" (click)="editpolicy(viewdatas?.id)"  *ngIf="permissions?.e && viewdatas?.is_active"><i
                        class="icon-pencil-square me-1"></i>Edit</a>
                </div>
                <div class="row row-16 mb-24">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 ">
                                    <p class="title mb-8">EXPENSE POLICY</p>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Expense policy name</div>
                                    <div class="detail-desc">{{viewdatas.name}}</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Description </div>
                                    <div class="detail-desc">{{viewdatas.description}}</div>
                                </div>
                                <!-- <div class="col-12">
                                    <div class="detail-title">Do you want to enable travel desk for booking tickets?
                                    </div>
                                    <div class="detail-desc"><i
                                            class="bi-record-circle-fill me-8 text-light-400"></i>Yes
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Do you want to enable travel desk for booking
                                        accommodation?</div>
                                    <div class="detail-desc"><i
                                            class="bi-record-circle-fill me-8 text-light-400"></i>Yes
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 pb-24 pt-16">
                            <div class="card-body px-24 pt-0">
                                <p class="title mb-8">MAP EXPENSE CATEGORY</p>
                            </div>
                            <div class="card-body px-24 py-0">
                                <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom shadow-0 p-0 ">
                                    <li [ngbNavItem]="i+1" class="success" *ngFor="let categorylist of categorylist;let i=index">
                                        <a ngbNavLink class="nav-link">{{categorylist.name}}</a>
                                        <ng-template ngbNavContent>
                                            <div class="row row-16">
                                                <div class="col-12">
                                                    <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                                        <div class="row row-16 ">
                                                            <div class="col-12 align-items-center d-flex">
                                                                <p class="title mb-8">EXPENSE TYPE</p>
                                                            </div>
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Expense Type </div>
                                                                <div class="detail-desc"><i
                                                                        class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                        {{categorylist?.expense_type?'Mileage':'Amount'}}
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Status</div>
                                                                <div class="detail-desc">
                                                                    <span class="fs-12rem fw-500 text-success text-uppercase" *ngIf="categorylist?.is_active">ACTIVE</span>
                                                                    <span class="fs-12rem fw-500 text-danger text-uppercase" *ngIf="categorylist?.is_active==false">Deleted</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                                        <div class="row row-16 ">
                                                            <div class="col-12 align-items-center d-flex">
                                                                <p class="title mb-8">EXPENSE INFORMATION</p>
                                                            </div>
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Expense Category Name</div>
                                                                <div class="detail-desc">{{categorylist?.name}}</div>
                                                            </div>
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Expense Category Code</div>
                                                                <div class="detail-desc">{{categorylist?.code_name}}</div>
                                                            </div>
                                                            <div class=" col-12">
                                                                <div class="detail-title">Description </div>
                                                                <div class="detail-desc">{{categorylist?.description}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12" *ngIf="categorylist?.expense_type==false">
                                                    <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                                        <div class="row row-16 ">
                                                            <div class="col-12 align-items-center d-flex">
                                                                <p class="title mb-8">SET LIMITATION</p>
                                                            </div>
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Amount Limit </div>
                                                                <div class="detail-desc" *ngIf="categorylist?.amount_limit_type =='limit_for_all_location'">
                                                                    <i class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                    Limit for all location
                                                                </div>
                                                                <div class="detail-desc" *ngIf="categorylist?.amount_limit_type =='location_dependent_limit'">
                                                                    <i class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                    Location dependent limit
                                                                </div>
                                                                <div class="detail-desc" *ngIf="categorylist?.amount_limit_type =='no_limit'">
                                                                    <i class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                    No Limit
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4 col-12" *ngIf="categorylist?.amount_limit_type =='location_dependent_limit' && categorylist?.amount_limit_type !='no_limit'">
                                                                <div class="detail-title">Location Group </div>
                                                                <div class="detail-desc">
                                                                    <div class="d-flex gap-8 flex-wrap mt-8 ">
                                                                        <ng-container *ngFor="let location_group of categorylist?.location_group;let i=index">
                                                                            <span class="badge3 py-2">{{location_group.name}}</span>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ng-container *ngIf="categorylist?.amount_limit_type !='no_limit'">
                                                             <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Specify Limit </div>
                                                                <div class="detail-desc">{{categorylist?.limit}} </div>
                                                            </div>
                                                            <div class=" col-12">
                                                                <div class="detail-title">Period for which limit is applicable </div>
                                                                <div class="detail-desc">{{categorylist?.limit_period}} </div>
                                                            </div>
                                                            </ng-container>
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="col-12" *ngIf="categorylist?.expense_type">
                                                    <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                                        <div class="row row-16 ">
                                                            <div class="col-12 align-items-center d-flex">
                                                                <p class="title mb-8">MILEAGE DETAILS</p>
                                                            </div>

                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Rate per kilometer </div>
                                                                <div class="detail-desc"> {{categorylist?.rate_per_km}}</div>
                                                            </div>
                                                            <div class="col-sm-6 col-12">
                                                                <div class="detail-title">Do you want to specify distance limit (KM) </div>
                                                                <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                    {{categorylist?.is_distance_limited?'Yes':'No'}}
                                                                </div>
                                                            </div>
                                                            <ng-container  *ngIf="categorylist?.is_distance_limited">
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Specify Limit </div>
                                                                <div class="detail-desc">{{categorylist?.limit}}</div>
                                                            </div>
                                                            <div class="col-sm-6 col-12">
                                                                <div class="detail-title">Period for which limit is applicable </div>
                                                                <div class="detail-desc">{{categorylist?.limit_period}}</div>
                                                            </div>
                                                           </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                                        <div class="row row-16 ">
                                                            <div class="col-12 align-items-center d-flex">
                                                                <p class="title mb-8">REQUEST SECTION</p>
                                                            </div>
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Is description mandatory? </div>
                                                                <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                 {{categorylist?.is_description?'Yes':'No'}}
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Is attachment mandatory? </div>
                                                                <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                    {{categorylist?.is_attachment?'Yes':'No'}}
                                                                </div>
                                                            </div>
                                                            <div class=" col-12" *ngIf="categorylist?.is_attachment">
                                                                <div class="detail-title">Define minimum amount to make attachment mandatory? </div>
                                                                <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                    {{categorylist?.is_attachment_minimum_amount?'Yes':'No'}}
                                                                </div>
                                                            </div>
                                                            <div class=" col-12" *ngIf="categorylist?.is_attachment_minimum_amount">
                                                                <div class="detail-title">Specify Expense limit </div>
                                                                <div class="detail-desc">{{categorylist?.attachment_minimum_amount_limit}}  </div>
                                                            </div>
                                                            <div class=" col-12">
                                                                <div class="detail-title">Can the user file expenses for a date prior to his date of joining? </div>
                                                                <div class="detail-desc"><i
                                                                        class="bi-record-circle-fill me-8 text-light-400"></i>
                                                                        {{categorylist?.is_file_expense?'Yes':'No'}}
                                                                </div>
                                                            </div>
                                                            <div class="hstack align-items-center gap-8 fs-14" *ngIf="categorylist?.is_reimbursable ">
                                                                <i class="icon-check-square-fill text-light-400"></i>Reimbursable
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                                        <div class="row row-16 ">
                                                            <div class="col-12 align-items-center d-flex">
                                                                <p class="title mb-8">CLAIM FREQUENCY</p>
                                                            </div>
                                                            <div class="col-sm-4 col-12">
                                                                <div class="detail-title">Claim Frequency limit count</div>
                                                                <div class="detail-desc">{{categorylist?.claim_fequency_limit}}</div>
                                                            </div>
                                                            <div class="col-sm-6 col-12">
                                                                <div class="detail-title">Period for which limit is applicable</div>
                                                                <div class="detail-desc">{{categorylist?.claim_limit_period}} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <!-- <li [ngbNavItem]="2" class="success">
                                        <a ngbNavLink class="nav-link">Accomodation Expenses</a>
                                        <ng-template ngbNavContent>
                                        </ng-template>
                                    </li> -->
                                </nav>
                            </div>
                            <div class="card-body px-24 ">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 py-16">
                            <div class="row row-16 ">
                                <div class="col-12 "><span class="title">Applicability</span></div>
                                <div class="col-12">
                                    <div class="detail-title">Applicable to</div>
                                    <div class="detail-desc">{{viewdatas?.applicability?.applicable}}</div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">{{viewdatas?.applicability?.applicable}}(s) </div>
                                      <div class="detail-desc">
                                        <div class="selected-values mt-1" >
                                         <ng-container *ngIf="viewdatas?.applicability?.applicable == 'Employee'">
                                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                            <div class="ng-value-label" title="{{rights['first_name']+rights['middle_name']+rights['last_name'] + ' (' + rights['employee_code'] +' )'}}">
                                              <span class="d-inline-flex align-items-center">
                                                <img class="img-fluid rounded-circle sq-24" src="{{rights.profile_image}}" *ngIf="rights.profile_image !=  '' && rights.profile_image !=  null">
                                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}" *ngIf="rights.profile_image ==  null || rights.profile_image ==  ''">{{this.appService.getFirstChar(rights.first_name,2)}}</span>
                                                  <span> {{rights['first_name']+(rights['middle_name']!=null && rights['middle_name']!=""?" " +rights['middle_name']:"")+" "+rights['last_name'] +" ("+rights['employee_code']+")"}}</span>
                                               </span>
                                            </div>
                                          </li>
                                         </ng-container>
                                         <ng-container *ngIf="viewdatas?.applicability?.applicable == 'Company'">
                                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                            <div class="ng-value-label" title="{{rights.company_name}}">
                                              <span class="d-inline-flex align-items-center">
                                                <img class="img-fluid rounded-circle sq-24"  src="{{rights.logo}}" *ngIf="rights.logo !=  '' && rights.logo !=  null">
                                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"  *ngIf="rights.logo ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                                  <span> {{rights.company_name}}</span>
                                               </span>
                                            </div>
                                          </li>
                                         </ng-container>
                                         <ng-container *ngIf="(viewdatas?.applicability?.applicable != 'Employee') && (viewdatas?.applicability?.applicable != 'Company')">
                                                <div class="d-flex flex-wrap mt-8 gap-8" >
                                                  <ng-container *ngFor="let rights of assignees, let i=index;">
                                                    <span class="badge3"  title="{{rights.name}}"> {{ (rights.length>25)? (rights.name | slice:0:25)+'..':(rights.name)  }}
                                                    </span>
                                                  </ng-container>

                                                </div>
                                        </ng-container>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- Delete  component -->
<!-- <div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Expense Policy?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Expense Policy')}}

          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteAlert = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteexpense(deleteId)">Delete</button>
        </div>
      </div>
    </div>
  </div> -->
