<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row ">
        <div class="col-12 d-flex align-items-start">
            <div>
                <h3>Challan </h3>
                <p class="mb-0 text-helper">Create challans and assign them to the appropriate employees.</p>
            </div>
            <div class="ms-auto d-flex gap-16">
                <div style="width:22.6875rem;">
                    <ng-select #ngSelecter class="form-ngselect fs-14"
                        placeholder="{{this.messageService.selectplaceholddisplay('Company')}}" [(ngModel)]="company"
                        (change)="filterChange(company,'company')" [items]="companyList" bindLabel="company_name"
                        bindValue="id">
                        <ng-template ng-label-tmp let-item="item" let-index="index">
                            <div class="d-inline-flex  align-items-center">

                                <img class="avatar-sm me-8 sq-28 img-fluid rounded-circle"
                                    *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                                <span
                                    *ngIf="(item.logo ==  ''|| item.logo == null) && item.company_name != '' && item.company_name != null"
                                    class="avatar-sm sq-28 me-8 rounded-circle bg{{index%5}}">
                                    <span>{{this.appservice.getFirstChar(item.company_name,1)}}</span>
                                </span>
                                {{item.company_name}}
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div class="d-inline-flex  align-items-center">

                                <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                                    *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                                <span *ngIf="item.logo ==  ''|| item.logo == null"
                                    class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                                    <span>
                                        {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                                </span>
                                {{item.company_name}}
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
                <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>
            </div>
        </div>
    </div>
    <div *ngIf="(nodata && !loader);else all_data"   class="row " style="height: calc(100vh - 15rem);">
      <!-- <div class="col-12 text-center">
          <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
              style="width: 27.375rem;">
          <p class="text-uppercase text-light-400 fw-700 fs-14rem ">NO DATA</p>
      </div> -->
      <app-nodata-view  class="vstack" [noDataImg]="'bg6.png'"  [noDataText]="'NO DATA'" [hasPermission]="false" > </app-nodata-view>
    </div>

    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row mt-24 row-16" *ngIf="loader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card1'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>

    <ng-template #all_data>
        <div class="row mt-24 row-16" *ngIf="!loader">
            <div class="col-12 mt-0 text-uppercase fw-500">Financial year
                {{this.appservice.getFinancialYearRange(selectedYear)}}</div>
            <div class="col-lg-3 col-md-6 " *ngFor="let challan of challanList let i = index">
                <div class="card card-custom1 card-hover"
                    routerLink="/challan/challan-details/{{challan.id}}/{{challan.challan_period}}/{{selectedYear}}/{{company}}">
                    <div class="card-body p-0 d-flex align-items-start">
                        <div class="d-flex align-items-center p-16">
                            <div *ngIf="challan?.challan_period == 'Q1'" class="logo-img cbg-accent2">
                                {{challan?.challan_period}}</div>
                            <div *ngIf="challan?.challan_period == 'Q2'" class="logo-img cbg-warning">
                                {{challan?.challan_period}}</div>
                            <div *ngIf="challan?.challan_period == 'Q3'" class="logo-img cbg-secondary">
                                {{challan?.challan_period}}</div>
                            <div *ngIf="challan?.challan_period == 'Q4'" class="logo-img cbg-success">
                                {{challan?.challan_period}}</div>
                            <div class="overflow-hidden ">
                                <h6 class="tips text-trim mb-0">{{challan?.challan_period == 'Q1'? '1st Quarter':
                                    challan?.challan_period == 'Q2'? '2nd Quarter' : challan?.challan_period == 'Q3'?
                                    '3rd Quarter': '4th Quarter'}} </h6>
                                <span class="tips-helper">{{challan?.challan_period == 'Q1'? '1st Quarter':
                                    challan?.challan_period == 'Q2'? '2nd Quarter' : challan?.challan_period == 'Q3'?
                                    '3rd Quarter': '4th Quarter'}} </span>
                                <span
                                    class="text-uppercase fw-500 fs-10 {{challan?.status == 'Pending' ? 'text-warning' : challan?.status == 'Completed' ? 'text-success' : 'text-danger'}}">{{challan?.status
                                    == 'Unprocessed' ? 'Upcoming' : challan?.status}}</span>
                            </div>
                        </div>

                    </div>
                    <div class="card-body p-16 fs-12">
                        <div class="text-light-400 me-1 text-trim">Quarter duration : </div>
                        <div class="fw-500 text-nowrap"> {{challan?.start_month.substring(0, 3)}} {{challan?.year}} -
                            {{challan?.end_month.substring(0, 3)}} {{challan?.year}}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6 ">
              <div class="card card-custom1 card-hover" routerLink="/challan-setup">
                  <div class="card-body p-0 d-flex align-items-start">
                      <div class="d-flex align-items-center p-16" >
                          <div class="logo-img bg16 text-warning">Q2</div>
                          <div class="overflow-hidden ">
                              <h6 class="tips text-trim mb-0">2nd Quarter </h6>
                              <span class="tips-helper">2nd Quarter </span>
                              <span class="text-danger text-uppercase fw-500 fs-10">UNPROCESSED</span>
                          </div>
                      </div>

                  </div>
                  <div class="card-body p-16 fs-12" >
                      <div class="text-light-400 me-1 text-trim">Quarter duration : </div>
                      <div class="fw-500 text-nowrap"> July 2021 - Sep 2021</div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 col-md-6 ">
              <div class="card card-custom1 card-hover" routerLink="/challan-details">
                  <div class="card-body p-0 d-flex align-items-start">
                      <div class="d-flex align-items-center p-16" >
                          <div class="logo-img text6 bg19">Q3</div>
                          <div class="overflow-hidden ">
                              <h6 class="tips text-trim mb-0">3rd Quarter </h6>
                              <span class="tips-helper">3rd Quarter </span>
                              <span class="text-danger text-uppercase fw-500 fs-10">UNPROCESSED</span>
                          </div>
                      </div>

                  </div>
                  <div class="card-body p-16 fs-12" >
                      <div class="text-light-400 me-1 text-trim">Quarter duration : </div>
                      <div class="fw-500 text-nowrap"> Oct 2021- Dec 2021 </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 col-md-6 ">
              <div class="card card-custom1 card-hover"  routerLink="/challan-setup">
                  <div class="card-body p-0 d-flex align-items-start">
                      <div class="d-flex align-items-center p-16" >
                          <div class="logo-img text-success ">Q4</div>
                          <div class="overflow-hidden ">
                              <h6 class="tips text-trim mb-0">4th Quarter </h6>
                              <span class="tips-helper">4th Quarter </span>
                              <span class="text-danger text-uppercase fw-500 fs-10">UNPROCESSED</span>
                          </div>
                      </div>

                  </div>
                  <div class="card-body p-16 fs-12" >
                      <div class="text-light-400 me-1 text-trim">Quarter duration : </div>
                      <div class="fw-500 text-nowrap">  Apr 2017 - Jun 2021</div>
                  </div>
              </div>
          </div> -->

        </div>
    </ng-template>
    <!-- <div class="row mt-24 row-16">
        <div class="col-12 mt-0 text-uppercase fw-500">Financial year 2019 - 2020</div>
        <div class="col-lg-3 col-md-6 ">
            <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                    <div class="d-flex align-items-center p-16"  >
                        <div class="logo-img bg8 text-accent2 ">Q1</div>
                        <div class="overflow-hidden ">
                            <h6 class="tips text-trim mb-0">1st Quarter </h6>
                            <span class="tips-helper">1st Quarter </span>
                            <span class="text-warning text-uppercase fw-500 fs-10">Pending</span>
                        </div>
                    </div>

                </div>
                <div class="card-body p-16 fs-12" >
                    <div class="text-light-400 me-1 text-trim">Quarter duration : </div>
                    <div class="fw-500 text-nowrap"> July 2021 - Sep 2021</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 ">
            <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                    <div class="d-flex align-items-center p-16" >
                        <div class="logo-img bg16 text-warning">Q2</div>
                        <div class="overflow-hidden ">
                            <h6 class="tips text-trim mb-0">2nd Quarter </h6>
                            <span class="tips-helper">2nd Quarter </span>
                            <span class="text-danger text-uppercase fw-500 fs-10">UNPROCESSED</span>
                        </div>
                    </div>

                </div>
                <div class="card-body p-16 fs-12" >
                    <div class="text-light-400 me-1 text-trim">Quarter duration : </div>
                    <div class="fw-500 text-nowrap"> July 2021 - Sep 2021</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 ">
            <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                    <div class="d-flex align-items-center p-16" >
                        <div class="logo-img text6 bg19">Q3</div>
                        <div class="overflow-hidden ">
                            <h6 class="tips text-trim mb-0">3rd Quarter </h6>
                            <span class="tips-helper">3rd Quarter </span>
                            <span class="text-danger text-uppercase fw-500 fs-10">UNPROCESSED</span>
                        </div>
                    </div>

                </div>
                <div class="card-body p-16 fs-12" >
                    <div class="text-light-400 me-1 text-trim">Quarter duration : </div>
                    <div class="fw-500 text-nowrap"> Oct 2021- Dec 2021 </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 ">
            <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                    <div class="d-flex align-items-center p-16" >
                        <div class="logo-img text-success ">Q4</div>
                        <div class="overflow-hidden ">
                            <h6 class="tips text-trim mb-0">4th Quarter </h6>
                            <span class="tips-helper">4th Quarter </span>
                            <span class="text-danger text-uppercase fw-500 fs-10">UNPROCESSED</span>
                        </div>
                    </div>

                </div>
                <div class="card-body p-16 fs-12" >
                    <div class="text-light-400 me-1 text-trim">Quarter duration : </div>
                    <div class="fw-500 text-nowrap">  Apr 2017 - Jun 2021</div>
                </div>
            </div>
        </div>

    </div> -->
</div>
