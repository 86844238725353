import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { FormBuilder, Validators, FormControl} from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-view-leave-request',
  templateUrl: './view-leave-request.component.html',
  styleUrls: ['./view-leave-request.component.scss']
})

export class ViewLeaveRequestComponent implements OnInit {

  leaveBalance                    = false;
  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  employee : number                = 0;
  leaveType:any                   = [];
  yearList : any                  = []
  currentYear                     = new Date().getFullYear()
  selectedYear :any;
  leave_type:any;
  pastupcoming:number             = 0;
  fileName:any;
  disabled                        = false;
  nullStatus                      = false;
  closeSidePanel = false;
  requestCancel = false;
  @Input()loader1:any;
  @Input()from:any;
  @Input()leaveRequestData:any;
  @Input()requestId:any;
  @Input()requestName:any;
  @Input()compOffData:any;
  @Input()leaveRequestView:any
  @Output() leaveClose: EventEmitter<boolean>     = new EventEmitter();
  @Output() refreshEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() closeViewPage                         = new EventEmitter();
  @Output() leaveViewClose                        = new EventEmitter();

  constructor(
    private authService:AuthService,
    public appservice:AppService,
    private notificationService: NotificationService
    ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if(this.from == 'inbox' && this.leaveRequestData){
        this.employee  = this.leaveRequestData.from_person;
        this.loader1 = false;
      }else{
        this.employee  = this.authService.isEmployeeId;
      }
    }, 1000);
  }


  /********* GET FILE NAME FUNCTION ****************/
   getDocName(name:string){
    if(name != undefined){
      var docname : any  = name.split('_').pop();
      docname = docname.replace(/[&\/\\#,+()$~%'":*?<>{}0-9]/g, '', "");
      return docname;
    }
  };

  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename:any){
    this.modalToggle      = true;
    this.fileName         = filename;
    this.currentDocName   = this.appservice.getDocName(filename);
    this.currentExtension = this.fileName.split('.').pop();
    if(this.currentExtension == 'doc' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'csv'){
      this.modalToggle    = false;
      this.alertToggle    = true;
      this.investalertMsg = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }else if(this.currentExtension == 'pdf'){
      this.modalToggle    = true;
      this.alertToggle    = false;
    }
  }

  /********* LEAVE BALANCE COMPONENT CLOSE FUNCTION ****************/
  leaveBalanceViewClose(data:any){
    this.leaveBalance = data;
  }

  /********* CLOSE FUNCTION ****************/
  closeFunction(){
    this.leaveViewClose.emit(false);
  }

  cancelRequest(status:any,cancelStatus:any,arrear:any,yearEndCancel:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("Leave request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else if(!yearEndCancel){
      this.notificationService.handleErrorNotification("Leave cancellation request cannot be raised as leave year-end has already been processed.","Error");
    }else{
      this.requestCancel = true;
    }
  }

    closeCancelModal(data:any){
    this.requestCancel = data?.show;
    if(data?.action == 'submit')
      this.closeViewPage.emit(data?.show);
    }

    close1(bool:any){
      this.modalToggle = bool;
    }

    closeInfo(bool:any){
      this.alertToggle = false;
    }

  // Date Format for FULL & HALF day
  processedDate(field: string) : string[]{
    let data : any =[]
    if(field == 'fullday'){
      data = this.leaveRequestData?.full_day_dates
    }else{
      data = this.leaveRequestData?.half_day_dates
    }
    const reversedData = data?.map((value:any)=> this.appservice.dateFormatDisplay(value))
    return reversedData?.reverse()
  }

  // test start
  fullday() : string[]{
    let data : any =[]

    data = this.leaveRequestData?.full_day_dates

    const reversedData = data?.map((value:any)=> this.appservice.dateFormatDisplay(value));
    return reversedData?.reverse();
  }

  halfday() {
    let data : any = [];
    if(this.leaveRequestData?.half_day_dates?.length > 0 && this.leaveRequestData?.half_day_dates != null && this.leaveRequestData?.half_day_dates != undefined){

      for(let i=0;i<this.leaveRequestData?.half_day_dates?.length;i++){
         data?.push(this.appservice.dateFormatDisplay(this.leaveRequestData?.half_day_dates[i]))
      }
      data = data?.reverse();
      if(data?.length > 1){
        data[0] = data[0]+' ('+this.leaveRequestData?.from_date_portion+')';
        data[1] = data[1]+' ('+this.leaveRequestData?.to_date_portion+')';
        }else{
          if(this.leaveRequestData?.from_date_portion == 'Full Day')
            data[0] = data[0]+' ('+this.leaveRequestData?.to_date_portion+')';
          else
            data[0] = data[0]+' ('+this.leaveRequestData?.from_date_portion+')';
        }
    }
    return data;
  }
  // test end

}
