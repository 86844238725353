<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{reportName}}</h5>
    <button class="btn-reset text-light-600" routerLink="/attendance-report"><i
        class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <form class="">
      <div class="container-fluid p-32 form-container">
        <div class="row mb-16">
          <div class="col-md-4 col-lg-6">
            <h3>{{reportName}}</h3>
          </div>
          <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
              <!-- Add columns start -->
              <button class="btn-square btn btn-secondary" ngbPopover="Add column"
                popoverClass="popover-default popover-info" container="body" triggers="hover"
                (click)="addColumnFilter = true">
                <i class="icon-plus fs-14"></i>
              </button>
              <!-- Add columns end -->
              <button class="btn-square btn btn-secondary" (click)="viewDetail = true">
                <i class="icon-filter-left fs-13"></i>
              </button>
              <!-- <button class="btn flex-shrink-0 fs-14 fw-600 text-uppercase btn-outline-primary"
                                (click)="scheduleReportFunction()">Schedule
                                Report</button> -->
              <button class="btn flex-shrink-0 fs-10 px-4 fw-700 text-uppercase btn-primary" [disabled]="isLoading"
                (click)="export()">DOWNLOAD
                REPORT</button>
            </div>
          </div>
        </div>
        <app-table-loader *ngIf="isLoading"></app-table-loader>
        <div class="col-12" *ngIf="appliedFilter != 0 && !isLoading">
          <ul class="selected-values  ">
            <ng-container *ngFor="let filter of appliedFilter | keyvalue ">
              <li class="ng-value" *ngIf="filter.value != '' && filter.value != null && filter.value != 'null'">
                <ng-container
                  *ngIf="filter.key == 'Attendance_month' ||filter.key == 'attendance_month' || filter.key == 'arrear_month' || filter.key == 'calendar_month';else all_data1">
                  <ng-container *ngIf="filter.value != 'this_month' && filter.value != 'last_month';else data">
                    <span class="ng-value-label ">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{filter.value}}</span>
                  </ng-container>
                  <ng-template #data>
                    {{removeUnderscoreAddUpperCase(filter.key)}} :
                    {{removeUnderscoreAddUpperCase(filter.value == 'this_month' ? 'current_month' :filter.value)}}
                  </ng-template>
                </ng-container>
                <ng-template #all_data1>
                  <ng-container
                    *ngIf="filter.key == 'from_date' || filter.key == 'to_date' || filter.key == 'attendance_date';else all_data">
                    <ng-container
                      *ngIf="filter.value != 'this_month' && filter.value != 'last_month' && filter.value != 'today' &&  filter.value != 'yesterday' ;else data1">
                      <span class="ng-value-label ">
                        {{removeUnderscoreAddUpperCase(filter.key)}} :
                        {{this.appService.dateFormatDisplay(filter.value)}}</span>
                    </ng-container>
                    <ng-template #data1>
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{removeUnderscoreAddUpperCase(filter.value == 'this_month' ? 'current_month' : filter.value)}}
                    </ng-template>
                  </ng-container>
                </ng-template>
                <ng-template #all_data>
                  <ng-container
                    *ngIf="filter.key === 'branch' || filter.key === 'bu'  || filter.key === 'company'  || filter.key === 'department' || filter.key === 'designation' || filter.key === 'grade' || filter.key === 'employee_list' || filter.key == 'employee_code'  || filter.key === 'emp_status' || filter.key === 'employment_status' || filter.key === 'employment_type' || filter.key === 'emp_type' || filter.key === 'request_status' || filter.key === 'attendance_status' || filter.key === 'late_or_early' || filter.key == 'check_in_type' || filter.key == 'reporting_manager'; else singleData">
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key === 'company' ? tagMultiDataFunction (appliedFilter.company):''">
                      Company :
                      <img class="img-fluid rounded-circle sq-24 mx-1 " src="{{tagMultiData[0].logo}}">
                      {{tagMultiData[0].company_name}}

                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span
                          class="flex-center radius-24 bg-dark-400 px-2">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key === 'employee_list' ? tagMultiDataFunction (appliedFilter.employee_list):''">

                      Employee list :
                      <img class="img-fluid rounded-circle sq-24 mx-1 "
                        *ngIf="tagMultiData[0].profile_image !==null && tagMultiData[0].profile_image !== ''; else firstName"
                        src="{{tagMultiData[0].profile_image}}">
                      <ng-template #firstName>
                        <span
                          class="avatar-sm sq-24 mx-1 rounded-circle {{tagMultiData[0]?.color_code}}">{{this.appService.getFirstChar(tagMultiData[0].first_name+"
                          "+(tagMultiData[0].middle_name!=null?tagMultiData[0].middle_name+" ":" "
                          )+tagMultiData[0].last_name,1)}}
                        </span>
                      </ng-template>
                      {{ tagMultiData[0].first_name+" "+(tagMultiData[0].middle_name!=null?tagMultiData[0].middle_name+"
                      ":" "
                      )+tagMultiData[0].last_name+ " (" +
                      tagMultiData[0].employee_code + ")" }}
                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span class="flex-center radius-24 bg-dark-400 px-2"
                          [ngbPopover]="popContentMultilpe" popoverClass="user-list multi-user"
                          (click)="onPopoverClick($event)" triggers="click" container="body">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>
                    <ng-template #popContentMultilpe>
                      <ul>
                        <ng-container *ngFor="let re of  appliedFilter?.employee_list, let i = index">
                          <ng-container *ngIf="i > 0">
                            <li *ngIf="re?.profile_image !='' && re?.profile_image !=null">
                              <img class="user-avatar" src="{{re?.profile_image}}">
                              <div class="user-details">
                                <span class="user-label">{{re?.first_name+'
                                  '+(re?.middle_name!=null?re?.middle_name:'')+'
                                  '+re?.last_name}}</span>
                                <span class="user-code"> EMP CODE : {{re?.employee_code}}</span>
                              </div>
                            </li>
                            <li *ngIf="(re?.profile_image =='' || re?.profile_image ==null )">
                              <span
                                class="user-avatar {{re?.color_code}}">{{this.appService.getFirstChar(re?.first_name+'
                                '+re?.last_name,2)}}</span>
                              <div class="user-details">
                                <span class="user-label">{{re?.first_name+'
                                  '+(re?.middle_name!=null?re?.middle_name:'')+'
                                  '+re?.last_name}}</span>
                                <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                              </div>
                            </li>
                          </ng-container>
                        </ng-container>
                      </ul>
                    </ng-template>
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key != 'company' && filter.key != 'employee_list' && filter.key !='reporting_manager'">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{tagMultiDataFunction(filter.value)}}
                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span
                          class="flex-center radius-24 bg-dark-400 px-2">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>
                    <!-- Reporting Manager -->
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key === 'reporting_manager' ? tagMultiDataFunction (appliedFilter.reporting_manager):''">

                      Reporting Manager :
                      <img class="img-fluid rounded-circle sq-24 mx-1 "
                        *ngIf="tagMultiData[0].profile_image !==null && tagMultiData[0].profile_image !== ''; else firstName"
                        src="{{tagMultiData[0].profile_image}}">
                      <ng-template #firstName>
                        <span
                          class="avatar-sm sq-24 mx-1 rounded-circle {{tagMultiData[0]?.color_code}}">{{this.appService.getFirstChar(tagMultiData[0].employee_name,1)}}
                        </span>
                      </ng-template>
                      {{ tagMultiData[0].employee_name+'('+ tagMultiData[0].employee_code+')' }}
                    </span>
                  </ng-container>
                  <ng-template #singleData>
                    <ng-container *ngIf="filter.key == 'stop_time'else dataKey">
                      <span>{{removeUnderscoreAddUpperCase(filter.key)}} : {{convertToHHMM(filter?.value)}}</span>
                    </ng-container>
                    <ng-template #dataKey>
                      <span class=""> {{removeUnderscoreAddUpperCase(filter.key == 'long_hault' ? 'long_halt' :
                        filter.key)}} :
                        {{removeUnderscoreAddUpperCase(filter.value)}}</span>
                    </ng-template>
                  </ng-template>
                </ng-template>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                *ngIf="isFilterVisible(reportName, filter.key)"
                (click)="handleTagClose(reportName, filter.key)">×</span>
              </li>
            </ng-container>
          </ul>
        </div>
        <div *ngIf="noData && !isLoading ;else all_data" class="row row-16">
          <div class="col-12">
            <div class="card card-c2 overflow-hidden">
              <!-- <span class="fw-500 fs-14">There are no data currently</span> -->
              <div class="table-responsive " style="max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));
                ">
                <table
                  class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header  td-width-15ch td-white-space"
                  mat-table [dataSource]="apiData" matSort cdkDropList cdkDropListOrientation="horizontal"
                  (cdkDropListDropped)="drop($event)">
                  <ng-container *ngFor="let prop of displayedColumns">
                    <ng-container [matColumnDef]="prop">
                      <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        {{prop}}<i class="fs-14 ms-8 icon-info-circle" *ngIf="hint_dict[prop]"  ngbPopover="{{hint_dict[prop]}}"
                          popoverClass="popover-default popover-info" container="body" triggers="hover"></i>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{element[prop]}}
                      </td>
                    </ng-container>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                </table>
                <div class="text-center card card-c2 flex-center p-16 bg-dark-500 m-12">No data found</div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #all_data>
          <div class="row row-16" *ngIf="!isLoading">
            <div class="col-12" *ngIf=" alertShow">
              <div role="alert" class="alert alert-info   mb-0 pe-32 show">
                <p class="mb-0 fs-14 hstack ">Click and drag the header’s to change the column position
                </p>
                <div class="btn-close fs-9 pointer" (click)="alertShow = false"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 overflow-hidden">
                <div class="table-responsive "
                  style="  max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));">
                  <table
                    class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header td-width-15ch td-white-space"
                    mat-table [dataSource]="apiData" matSort cdkDropList cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="drop($event)">
                    <ng-container *ngFor="let prop of displayedColumns">
                      <ng-container [matColumnDef]="prop">
                        <th mat-header-cell cdkDrag *matHeaderCellDef (click)="onSort(prop)" class="th-sort">
                          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                          {{prop}}
                          <i class="fs-14 ms-8 icon-info-circle" *ngIf="hint_dict[prop]"
                            ngbPopover="{{hint_dict[prop]}}" popoverClass="popover-default popover-info"
                            container="body" triggers="hover"></i>
                          <i class="fs-12 ms-8 icon-th-sort" [ngClass]="{
                                  'th-sort-ascend': sortProperty === prop && direction == 'asc',
                                  'th-sort-descend': sortProperty === prop && direction == 'desc'
                              }"></i>
                        </th>
                        <td mat-cell *matCellDef="let element" title="{{element[prop]}}">
                          {{element[prop]}}
                        </td>
                      </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="lastPageCount > 20 && lastPageCount > apiData?.filteredData?.length">
              <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPageCount"
                [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
                (pageChange)="pageChanged($event)"></ngb-pagination>
            </div>
          </div>
        </ng-template>
      </div>
    </form>
  </main>
</div>

<!-- punch time filter start -->
<ng-container *ngIf="reportName == 'Clocking Time Report'">
  <app-punch-time #appPunchtime [ouFilterForm]="OuFilterForm" [punchTime]="punchTime"
    (resetFilter)="resetFilterFn($event)" [ordering]="ordering" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-punch-time>
</ng-container>

<!-- Remote Punch -->
<ng-container *ngIf="reportName == 'Remote Punch Locations'">
  <app-location-remote #appRemote [ordering]="ordering"
    [ordering]="ordering" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"
    [remoteLocationForm]="remoteLocationForm"
    [ouFilterForm]="OuFilterForm" [viewDetail]="viewDetail" (filterPanel)="panel($event)" [closeControl]="closeControl"
    [resDataFilter]="resDataFilter" (resetFilter)="resetFilterFn($event)"
    (callReports)="reportFnCall($event)" (submitDone)="FilterApiConfig($event)"></app-location-remote>
</ng-container>

<!-- Remote Punch -->
<ng-container *ngIf="reportName == 'Live Location'">
  <app-location-live #appLive [ordering]="ordering"
    [ordering]="ordering" [timeSetup]="appliedFilter?.stop_time" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"
    [liveLocationForm]="liveLocationForm"
    [ouFilterForm]="OuFilterForm" [viewDetail]="viewDetail" (filterPanel)="panel($event)" [closeControl]="closeControl"
    [resDataFilter]="resDataFilter" (resetFilter)="resetFilterFn($event)"
    (callReports)="reportFnCall($event)" (submitDone)="FilterApiConfig($event)"></app-location-live>
</ng-container>

<!-- punch time Report filter end -->

<!-- attendance regularize  filter start -->
<ng-container *ngIf="reportName == 'Attendance Regularization Request'">
  <app-attendance-regularization-request #appAttendanceRegularize [ouFilterForm]="OuFilterForm"
    [attendanceRegularize]="attendanceRegularize" [ordering]="ordering" (resetFilter)="resetFilterFn($event)"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail" [salFilter]="salFilter"
    [requestStatus]="requestStatus" [Years]="yearsList" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"></app-attendance-regularization-request>
</ng-container>

<!-- attendance regularize  Report filter end -->

<!-- arrear days  filter start -->
<ng-container *ngIf="reportName == 'Arrear Days'">
  <app-arrear-days #appArreardays [ouFilterForm]="OuFilterForm" [arrearDays]="arrearDays"
    (resetFilter)="resetFilterFn($event)" [ordering]="ordering" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)" [empstatArr]="" empstatArr
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-arrear-days>
</ng-container>

<!-- arrear days  Report filter end -->

<!-- attendance register  filter start -->
<ng-container *ngIf="reportName == 'Monthly Attendance Register'">
  <app-attendance-register #appAttendanceRegister [ouFilterForm]="OuFilterForm"
    [attendanceRegister]="attendanceRegister" [ordering]="ordering" (resetFilter)="resetFilterFn($event)"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)"
    (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail" [salFilter]="salFilter" [Years]="yearsList"
    [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-attendance-register>
</ng-container>

<!-- attendance register Report filter end -->

<!-- Compoff request   filter start -->
<ng-container *ngIf="reportName == 'Compensatory Off Request'">
  <app-compoff-request #appCompoff [compOffRequest]="compOffRequest" [ouFilterForm]="OuFilterForm"
    (resetFilter)="resetFilterFn($event)" [ordering]="ordering" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [requestStatus]="requestStatus" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"></app-compoff-request>
</ng-container>

<!-- Compoff request Report filter end -->

<!-- Monthly overtime detail report filter start -->
<ng-container *ngIf="reportName == 'Monthly Overtime Detail'">
  <app-monthly-overtime-detail #appMonthlyOvertime [monthlyOvertime]="monthlyOvertime" [ordering]="ordering"
    [ouFilterForm]="OuFilterForm" (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-monthly-overtime-detail>
</ng-container>

<!-- Monthly overtime detail report filter end -->

<!-- Monthly paid day report filter start -->
<ng-container *ngIf="reportName == 'Monthly Paid Day'">
  <app-monthly-paid-day #appMonthlyPaidDay [monthlyPaid]="monthlyPaid" (resetFilter)="resetFilterFn($event)"
    [ordering]="ordering" [ouFilterForm]="OuFilterForm" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    (submitDone)="FilterApiConfig($event)" (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail" [salFilter]="salFilter"
    [Years]="yearsList"
    [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-monthly-paid-day>
</ng-container>

<!-- Monthly paid day report filter end -->

<!-- Monthly shift roaster report filter start -->
<ng-container *ngIf="reportName == 'Monthly Shift Roaster'">
  <app-monthly-shift-roaster #appMonthlyShiftroaster [monthlyShiftRoaster]="monthlyShiftRoaster" [ordering]="ordering"
    [ouFilterForm]="OuFilterForm" (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-monthly-shift-roaster>
</ng-container>

<!-- Monthly shift roaster report filter end -->

<!-- Monthly working hour report filter start -->
<ng-container *ngIf="reportName == 'Monthly Working Hour'">
  <app-monthly-working-hour #appMonthlyWorkingHour [monthlyWorkingHour]="monthlyWorkingHour" [ordering]="ordering"
    [ouFilterForm]="OuFilterForm" (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-monthly-working-hour>
</ng-container>
<!-- Monthly working hour report filter end -->

<!-- On duty report filter start -->
<ng-container *ngIf="reportName == 'On Duty Request'">
  <app-onduty-request #appOndutyRequest [onDutyRequest]="onDutyRequest" (resetFilter)="resetFilterFn($event)"
    [ordering]="ordering" [ouFilterForm]="OuFilterForm" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    (submitDone)="FilterApiConfig($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail" [salFilter]="salFilter" [Years]="yearsList"
    [requestStatus]="requestStatus" [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-onduty-request>
</ng-container>
<!--  On duty report filter end -->

<!-- Overtime report filter start -->
<ng-container *ngIf="reportName == 'Overtime Request'">
  <app-overtime-request #appOvertimeRequest [overtimeRequest]="overtimeRequest" (resetFilter)="resetFilterFn($event)"
    [ordering]="ordering" [ouFilterForm]="OuFilterForm" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    (submitDone)="FilterApiConfig($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail" [salFilter]="salFilter"[Years]="yearsList"
    [requestStatus]="requestStatus" [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-overtime-request>
</ng-container>

<!--  Overtime report filter end -->

<!-- floating holiday report filter start -->
<ng-container *ngIf="reportName == 'Floating Holiday Request Report'">
  <app-floating-holiday-request #appFloatingHoliday [floatingHoliday]="floatingHoliday" [ordering]="ordering"
    [ouFilterForm]="OuFilterForm" (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [requestStatus]="requestStatus" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"></app-floating-holiday-request>
</ng-container>

<!--  Muster roll form filter end -->

<ng-container *ngIf="reportName == 'Muster Roll Form 25'">
  <app-muster-roll-form25 #appMusterRollForm [musterRollForm]="musterRollForm" (resetFilter)="resetFilterFn($event)"
    [ordering]="ordering" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [ouFilterForm]="OuFilterForm" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [pageNumber]="pageNumber" [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-muster-roll-form25>
</ng-container>

<!--  muster roll form filter end -->

<!--  Absent details filter end -->

<ng-container *ngIf="reportName == 'Absent Details'">
  <app-absent-details #appAbsentDetails [absentDetails]="absentDetails" (resetFilter)="resetFilterFn($event)"
    [ordering]="ordering" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [ouFilterForm]="OuFilterForm" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [pageNumber]="pageNumber" [lastPage]="lastPageCount"></app-absent-details>
</ng-container>
<!--  Absent details filter end -->

<!--  Daily attendance filter end -->
<ng-container *ngIf="reportName == 'Daily Attendance'">
  <app-daily-attendance #appDailyAttendance [dailyAttendance]="dailyAttendance" (resetFilter)="resetFilterFn($event)"
    [ordering]="ordering" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [ouFilterForm]="OuFilterForm" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [attendanceStatus]="attendanceStatus" [lateEarlyArr]="lateEarlyArr" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"></app-daily-attendance>
</ng-container>

<!--  Daily attendance filter end -->

<!--  Late Early filter end -->

<ng-container *ngIf="reportName == 'Late And Early Go'">
  <app-late-early #appLateEarly [lateEarly]="lateEarly" (resetFilter)="resetFilterFn($event)" [ordering]="ordering"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" [ouFilterForm]="OuFilterForm"
    (submitDone)="FilterApiConfig($event)" (filterPanel)="panel($event)"
    (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail" [salFilter]="salFilter" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"></app-late-early>
</ng-container>

<!--  Late Early filter end -->

<!-- Work from home report filter start -->
<ng-container *ngIf="reportName == 'Work From Home Request'">
  <app-work-from-home-report #appWorkFromHome [workFromHomeForm]="workFromHomeForm" [ordering]="ordering"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    (submitDone)="FilterApiConfig($event)"  [ouFilterForm]="OuFilterForm"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [requestStatus]="requestStatus" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"></app-work-from-home-report>
</ng-container>
<!-- Work from home report filter end -->


<!-- Checkin request report filter start -->
<ng-container *ngIf="reportName == 'Check In Request'">
  <app-check-in-request-report #appCheckin [checkinForm]="checkinForm" (resetFilter)="resetFilterFn($event)"
    [ordering]="ordering" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [ouFilterForm]="OuFilterForm" (submitDone)="FilterApiConfig($event)"
    (filterPanel)="panel($event)" (callMonthlyReport)="reportFnCall($event)" [viewDetail]="viewDetail"
    [salFilter]="salFilter"
    [Years]="yearsList" [requestStatus]="requestStatus" [checkInarr]="checkInarr" [pageNumber]="pageNumber"
    [lastPage]="lastPageCount"></app-check-in-request-report>
</ng-container>



<div class="modal  modal-alert {{resetFilterPop == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Reset Report Filter?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">This reset report back to default filter. You'll have to change to get it back in the
          list.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="resetFilterPop = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="resetFilterModalClicked"
          (click)="resetFilterApply()">Reset</button>
      </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{applyFilterPop == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="filterSaveForm" autocomplete="off">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Custom Filters Applied</h4>
          <button class="btn-reset  ms-auto" (click)="applyFilterPop = false"> <i class="icon-close-lg fs-16"></i></button>
        </div>
        <div class="modal-body">
          <div class="row row-16">
            <div class="col-12 form-row">
              <div class="form-check">
                <input name="filter" formControlName="filter" type="radio" id="filter1" class="form-check-input "
                  [value]="true" checked>
                <label for="filter1" class="form-check-label">Set the filters as default for the
                  report</label>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check ">
                <input name="filter" formControlName="filter" type="radio" id="filter2" class="form-check-input "
                  [value]="false">
                <label for="filter2" class="form-check-label">Don't set the filter as default</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase"
            [disabled]="saveFilterModalClicked" (click)="saveFilterModal()">Apply</button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- <div class="modal  modal-alert {{scheduleReport == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="scheduleReportFilterMemorizeForm" autocomplete="off">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Custom Filters Applied</h4>
          <button class="btn-reset  ms-auto" (click)="scheduleReport = false"> <i
              class="icon-close-lg fs-16"></i></button>
        </div>
        <div class="modal-body">
          <div class="row row-16">
            <div class="col-12 form-row">
              <div class="form-check">
                <input name="filter" formControlName="filter" type="radio" id="filter1" class="form-check-input "
                  [value]="true" checked>
                <label for="filter1" class="form-check-label">Schedule the default report without
                  filters</label>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check ">
                <input name="filter" formControlName="filter" type="radio" id="filter2" class="form-check-input "
                  [value]="false">
                <label for="filter2" class="form-check-label">{{scheduleText}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase"
            (click)="reportMemorizeFunction()">Schedule Report</button>
        </div>
      </div>
    </form>
  </div>
</div> -->

<!-- Add columns start -->
<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='addColumnFilter === true'>
  <form class="side-panel-container" autocomplete="off" *ngIf="sidePanelForm" [formGroup]="sidePanelForm">
    <header class="side-panel-head">
      <h5>Add columns</h5>
      <a class="toggle-panel" (click)="addColumnFilter = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 mb-16">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-8">Select Columns
            </p>
          </div>
          <!-- With Form setup -->
            <ng-container formArrayName="columnsFormArr">
              <div class="col-12 form-row" *ngFor="let item of columnsFormArrForm().controls; let i = index" [formGroupName]="i">
                <label for="check{{i}}" class="form-check p-0 d-flex">
                  <input type="checkbox" formControlName="checkbox" class="form-check-input ms-0" name="check" id="check{{i}}" (change)="onCheckboxChange(i)">
                  <label for="check{{i}}" class="form-check-label fs-16" style="user-select: none;">{{ item?.get('name')?.value }}</label>
                </label>
              </div>
            </ng-container>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class=" btn btn-outline-primary text-uppercase btn-sm"
        (click)="resetColumns(); addColumnFilter = false; addedColumns = false">
        RESET COLUMNS
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        (click)="headersApply(); addColumnFilter = false; addedColumns = true">
        SAVE COLUMNS
      </button>
    </footer>
  </form>
</div>
<!-- Add columns end -->
