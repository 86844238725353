<div class="container-fluid p-32 form-container" [formGroup]="formGp">
  <div class="row ">
    <p class="text-uppercase fw-500">overtime</p>
  </div>
  <div class="row row-16">
    <div class="col-12" *ngIf="alertNote">
      <div role="alert" class="alert alert-warning py-10 mb-0 pe-32 show">
        <p class="mb-0 fs-14 hstack "> <span class="fw-600">Note : </span>Overtime will not be generated in case of
          half-day working and half-day leave/absent. </p>
        <div class="btn-close fs-9 pointer" (click)="alertNote=false"></div>
      </div>
    </div>
    <div class="col-12 form-row">
      <label for="" class="form-label  ">Do you want to enable overtime?</label>
      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
          ngbPopover="Select yes if you want to enable this option." popoverClass="popover-input popover-info"
          placement="bottom" triggers="hover"></i></span>
    </div>
    <div class="col-lg-12 form-row mt-0">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input formControlName="enable_overtime" class="form-check-input" type="radio" [value]=true
            (click)="setRequired(true,disData);setCompOff()">
          Yes
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input formControlName="enable_overtime" class="form-check-input" type="radio" [value]=false
            (click)="setRequired(false,disNoData)">
          No
        </label>
      </div>
    </div>
    <ng-container *ngIf="f.enable_overtime.value == true && (!isconfirmed || activeOvertime)">
      <div class=" form-row">
        <label for="" class="form-label required">Minimum additional hours required to consider overtime in a day</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover="Select the minimum hours to be considered as overtime in a day." popoverClass="popover-input popover-info"
            placement="bottom" triggers="hover"></i></span>
        <div class="form-icon icon-right"
          [ngClass]="{ 'is-invalid': (submitted || f.min_hour_required_consider_overtime_day.touched || f.min_hour_required_consider_overtime_day.dirty) &&  f.min_hour_required_consider_overtime_day.errors}">
          <igx-time-picker formControlName="min_hour_required_consider_overtime_day"
            placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
            [ngClass]="{ 'is-invalid': (submitted || f.min_hour_required_consider_overtime_day.touched || f.min_hour_required_consider_overtime_day.dirty) &&  f.min_hour_required_consider_overtime_day.errors}"
            class="form-control form-timepicker " (click)="picker3.open()" #picker3 (ngModelChange)="setFromValueWithMinValue()"></igx-time-picker>

          <i *ngIf="f.min_hour_required_consider_overtime_day.value!=null" class="icon-close text-light-400"
            (click)="clearTime(picker3);picker3.close()"></i>
        </div>

        <div
          *ngIf="(submitted || f.min_hour_required_consider_overtime_day.touched || f.min_hour_required_consider_overtime_day.dirty) && f.min_hour_required_consider_overtime_day.errors"
          class="invalid-feedback">
          <div *ngIf="f.min_hour_required_consider_overtime_day.errors.required">
            {{this.messageService.validationDisplay('required')}}
          </div>
        </div>
      </div>

      <div class="col-12 form-row">
        <label for="" class="form-label  ">Do you want to set overtime for workdays</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover="Enable this option if you want to set overtime for working days."
            popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>

      </div>
      <div class="col-lg-12 form-row mt-0">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_workday" class="form-check-input" type="radio" [value]=true
              (click)="setRequired(true,['overtime_eligibility_fullday','max_overtime_hour_allow__working_day']);addOverTime('Working Day')">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_workday" class="form-check-input" type="radio" [value]=false
              (click)="setRequired(false,['overtime_eligibility_fullday','max_overtime_hour_allow__working_day']);removeOvertime('Working Day')">
            No
          </label>
        </div>
      </div>

      <ng-container *ngIf="f.set_overtime_workday.value == true">
        <div class="col-lg-6 form-row">
          <label for="" class="form-label required">Formula to calculate overtime eligibility during workdays</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Select any of the following formula to define overtime for the working day: Working hours - Shift hours : It means working hour for overtime will be calculated as Total Working Hours of employee minus shift hours of employee. Working hours - Minimum hours for full day : It means working hour for overtime will be calculated as Total Working Hours of employee minus minimum working hours of employee. Max Check Out Time - Shift End Time Actual: It means working hour for overtime will be calculated as the Last check out time of the employee minus Shift end time."
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>

          <ng-select formControlName="overtime_eligibility_fullday"
            [ngClass]="{ 'is-invalid': (submitted || f.overtime_eligibility_fullday.touched || f.overtime_eligibility_fullday.dirty) &&  f.overtime_eligibility_fullday.errors}"
            class="form-ngselect " placeholder="{{this.messageService.selectplaceholddisplay('value')}}">
            <ng-option [value]="'Working hours - Shift hours'">Working hours - Shift hours</ng-option>
            <ng-option [value]="'Working hours - Minimum hours for full day'">Working hours - Minimum hours for full
              day</ng-option>
            <ng-option [value]="'Max check out time - Shift end time actual'">Max check out time - Shift end
              time</ng-option>
          </ng-select>
          <div
            *ngIf="(submitted || f.overtime_eligibility_fullday.touched || f.overtime_eligibility_fullday.dirty) && f.overtime_eligibility_fullday.errors"
            class="invalid-feedback">
            <div *ngIf="f.overtime_eligibility_fullday.errors.required">
              {{this.messageService.validationDisplay('required')}}
            </div>
          </div>
        </div>
        <div class="col-lg-6 form-row">
          <label for="" class="form-label required">Maximum overtime hours allowed on working days </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Select the maximum overtime hours on working days.Overtime hours will be calculated up-to maximum limit only."
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
          <div class="form-icon icon-right"
            [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow__working_day.touched || f.max_overtime_hour_allow__working_day.dirty) &&  f.max_overtime_hour_allow__working_day.errors}">
            <igx-time-picker formControlName="max_overtime_hour_allow__working_day"
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
              [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow__working_day.touched || f.max_overtime_hour_allow__working_day.dirty) &&  f.max_overtime_hour_allow__working_day.errors}"
              class="form-control form-timepicker " (click)="picker2.open()" #picker2 (ngModelChange)="setCompensationCriteria('Working Day','max_overtime_hour_allow__working_day','max')"></igx-time-picker>
            <i *ngIf="f.max_overtime_hour_allow__working_day.value!=null" class="icon-close text-light-400"
              (click)="clearTime(picker2);picker2.close()"></i>
          </div>
          <div
            *ngIf="(submitted || f.max_overtime_hour_allow__working_day.touched || f.max_overtime_hour_allow__working_day.dirty) &&  f.max_overtime_hour_allow__working_day.errors"
            class="invalid-feedback">
            <div *ngIf="f.max_overtime_hour_allow__working_day.errors.required">
              {{this.messageService.validationDisplay('required')}}
            </div>
            <div *ngIf="f.max_overtime_hour_allow__working_day.errors?.min">
              {{this.messageService.greaterThanMessage('Minimum hour','Value')}}
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card card-c2 py-16 px-24">
            <div class="row row-16">
              <div class="col-12">
                <p class="title mb-0">OVERTIME COMPENSATION CRITERIA </p>
              </div>
              <ng-container formArrayName="working_day_overtime"*ngFor="let item of workingDayarray().controls; let i=index; ">
                <ng-container [formGroupName]="i">

                  <div class="col-6">
                    <label for="" class="form-label required">Overtime hours between </label>
                    <div class="row">
                      <div class="col">
                        <!-- <input type="text" name="" class="form-control" placeholder="Enter hour" id=""> -->
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || workingDayarray().controls[i].get ('hours_from')?.touched || workingDayarray().controls[i].get ('hours_from')?.dirty) &&  workingDayarray().controls[i].get ('hours_from')?.errors}">
                          <igx-time-picker formControlName="hours_from"
                            placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                            [ngClass]="{ 'is-invalid': (submitted || workingDayarray().controls[i].get ('hours_from')?.touched || workingDayarray().controls[i].get ('hours_from')?.dirty) &&  workingDayarray().controls[i].get ('hours_from')?.errors}"
                            class="form-control form-timepicker " (click)="picker10.open()" #picker10 (ngModelChange)="validateFromTotime('Working Day',i,'hours_from');"></igx-time-picker>
                          <i *ngIf="workingDayarray().controls[i].get ('hours_from')?.value!=null" class="icon-close text-light-400"
                            (click)="clearTime(picker10);picker10.close()"></i>
                        </div>
                        <div  *ngIf="(submitted || workingDayarray().controls[i].get ('hours_from')?.touched || workingDayarray().controls[i].get ('hours_from')?.dirty) &&  workingDayarray().controls[i].get ('hours_from')?.errors" class="invalid-feedback">
                          <div *ngIf="workingDayarray().controls[i].get ('hours_from')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="workingDayarray().controls[i].get ('hours_from')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="workingDayarray().controls[i].get ('hours_from')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum Working day OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="workingDayarray().controls[i].get('hours_from')?.errors?.nomatch  && !(workingDayarray().controls[i].get('hours_from')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="workingDayarray().controls[i].get('hours_from')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                        </div>
                      </div>
                      <div class="col-1 d-flex fs-14 justify-content-center pt-2"> to</div>
                      <div class="col">
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || workingDayarray().controls[i].get ('hours_to')?.touched || workingDayarray().controls[i].get ('hours_to')?.dirty) &&  workingDayarray().controls[i].get ('hours_to')?.errors}">
                          <igx-time-picker formControlName="hours_to" placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (submitted || workingDayarray().controls[i].get ('hours_to')?.touched || workingDayarray().controls[i].get ('hours_to')?.dirty) &&  workingDayarray().controls[i].get ('hours_to')?.errors}" class="form-control form-timepicker " (click)="picker5.open()" #picker5 (ngModelChange)="validateFromTotime('Working Day',i,'hours_to')"></igx-time-picker>
                          <i *ngIf="workingDayarray().controls[i].get ('hours_to')?.value!=null" class="icon-close text-light-400" (click)="clearTime(picker5);picker5.close()"></i>
                        </div>
                        <div *ngIf="(submitted || workingDayarray().controls[i].get ('hours_to')?.touched || workingDayarray().controls[i].get ('hours_to')?.dirty) &&  workingDayarray().controls[i].get ('hours_to')?.errors" class="invalid-feedback">
                          <div *ngIf="workingDayarray().controls[i].get ('hours_to')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="workingDayarray().controls[i].get('hours_to')?.errors?.min  && !(workingDayarray().controls[i].get('hours_to')?.errors?.required)"> {{this.messageService.greaterThanMessage('From value', 'Value')}} </div>
                          <div *ngIf="workingDayarray().controls[i].get('hours_to')?.errors?.nomatch  && !(workingDayarray().controls[i].get('hours_to')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="!(workingDayarray().controls[i].get('hours_to')?.errors?.nomatch) && !(workingDayarray().controls[i].get('hours_to')?.errors?.min) && workingDayarray().controls[i].get ('hours_to')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="!(workingDayarray().controls[i].get('hours_to')?.errors?.nomatch) && !(workingDayarray().controls[i].get('hours_to')?.errors?.min) && !(workingDayarray().controls[i].get ('hours_to')?.errors?.beforeTime) && workingDayarray().controls[i].get ('hours_to')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum Working day OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="workingDayarray().controls[i].get('hours_to')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                          <div *ngIf="workingDayarray().controls[i].get('hours_to')?.errors?.duplicate">
                            {{this.messageService.overlapMsg()}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="" class="form-label required">Pay rate </label>
                    <div class="d-flex" [ngClass]="{ 'is-invalid': (submitted || workingDayarray().controls[i].get('rate')?.touched || workingDayarray().controls[i].get('rate')?.dirty) &&  workingDayarray().controls[i].get('rate')?.errors || workingDayarray().controls[i].get('rate')?.errors?.exist}">

                      <input type="text" formControlName="rate" class="form-control w-100" placeholder="{{this.messageService.placeholderdisp('rate')}}" [ngClass]="{ 'is-invalid': (submitted || workingDayarray().controls[i].get('rate')?.touched || workingDayarray().controls[i].get('rate')?.dirty) &&  workingDayarray().controls[i].get('rate')?.errors || workingDayarray().controls[i].get('rate')?.errors?.exist}">
                      <a class="flex-center ms-auto ps-4 sq-40 text-danger" (click)="removeOvertimeIndex('Working Day',i)" *ngIf="i!=0">
                        <i class="icon-trash "></i>
                      </a>
                    </div>
                    <div *ngIf="(submitted || workingDayarray().controls[i].get('rate')?.touched || workingDayarray().controls[i].get('rate')?.dirty) && workingDayarray().controls[i].get('rate')?.errors || workingDayarray().controls[i].get('rate')?.errors?.exist"
                        class="invalid-feedback">
                        <div *ngIf="workingDayarray().controls[i].get('rate')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="!workingDayarray().controls[i].get('rate')?.errors?.pattern && workingDayarray().controls[i].get('rate')?.errors?.max">
                            {{this.messageService.fieldlengthvalidation('value','3')}}
                        </div>
                        <div *ngIf="workingDayarray().controls[i].get('rate')?.errors?.pattern">
                            {{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!workingDayarray().controls[i].get('rate')?.errors?.pattern && workingDayarray().controls[i].get('rate')?.errors?.min">
                          {{this.messageService.fieldlengthvalidation('minvalue',0)}}
                        </div>
                    </div>
                  </div>


                </ng-container>
              </ng-container>
              <div class="col-12">
                <div class="link-primary1 fs-14 d-flex align-items-center" >
                  <a class="link-primary1" (click)="addOverTime('Working Day')"><i class="icon-plus-square-fill me-8"></i>Add
                    more</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-12 form-row">
        <label for="" class="form-label  ">Do you want to set overtime for weekly off</label>
      </div>
      <div class="col-lg-12 form-row mt-0">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_weekoff" class="form-check-input" type="radio" [value]=true
              (click)="setRequired(true,['max_overtime_hour_allow__weekoff']);addOverTime('Week Off')">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_weekoff" class="form-check-input" type="radio" [value]=false
              (click)="setRequired(false,['max_overtime_hour_allow__weekoff']);removeOvertime('Week Off')">
            No
          </label>
        </div>
      </div>
      <ng-container *ngIf="f.set_overtime_weekoff.value == true">
        <!-- <div class="col-lg-6  ">
          <label for="" class="form-label required">Formula to calculate Overtime eligibility during weekly off </label>
          <ng-select class="form-ngselect " placeholder="Select Value"></ng-select>
        </div> -->
        <div class="col-12 form-row">
          <label for="" class="form-label required">Maximum overtime hours allowed on weekly off </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Select the maximum hours for OT the employee should work on week-off. Overtime beyond the maximum limit will not be considered."
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>

          <div class="form-icon icon-right"
            [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow__weekoff.touched || f.max_overtime_hour_allow__weekoff.dirty) &&  f.max_overtime_hour_allow__weekoff.errors}">
            <igx-time-picker formControlName="max_overtime_hour_allow__weekoff"
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
              [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow__weekoff.touched || f.max_overtime_hour_allow__weekoff.dirty) &&  f.max_overtime_hour_allow__weekoff.errors}"
              class="form-control form-timepicker " (click)="picker11.open()" #picker11 (ngModelChange)="setCompensationCriteria('Week Off','max_overtime_hour_allow__weekoff','max')"></igx-time-picker>

            <i *ngIf="f.max_overtime_hour_allow__weekoff.value!=null" class="icon-close text-light-400"
              (click)="clearTime(picker11);picker11.close()"></i>
          </div>

          <div
            *ngIf="(submitted || f.max_overtime_hour_allow__weekoff.touched || f.max_overtime_hour_allow__weekoff.dirty) && f.max_overtime_hour_allow__weekoff.errors"
            class="invalid-feedback">
            <div *ngIf="f.max_overtime_hour_allow__weekoff.errors?.required">
              {{this.messageService.validationDisplay('required')}}
            </div>
            <div *ngIf="f.max_overtime_hour_allow__weekoff.errors?.min">
              {{this.messageService.greaterThanMessage('Minimum hour','Value')}}
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card card-c2 py-16 px-24">
            <div class="row row-16">
              <div class="col-12">
                <p class="title mb-0">OVERTIME COMPENSATION CRITERIA </p>
              </div>
              <ng-container formArrayName="weekoff_overtime"*ngFor="let item of weekoffarray().controls; let i=index; ">
                <ng-container [formGroupName]="i">

                  <div class="col-6">
                    <label for="" class="form-label required">Overtime hours between </label>
                    <div class="row">
                      <div class="col">
                        <!-- <input type="text" name="" class="form-control" placeholder="Enter hour" id=""> -->
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || weekoffarray().controls[i].get ('hours_from')?.touched || weekoffarray().controls[i].get ('hours_from')?.dirty) &&  weekoffarray().controls[i].get ('hours_from')?.errors}">
                          <igx-time-picker formControlName="hours_from"
                            placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                            [ngClass]="{ 'is-invalid': (submitted || weekoffarray().controls[i].get ('hours_from')?.touched || weekoffarray().controls[i].get ('hours_from')?.dirty) &&  weekoffarray().controls[i].get ('hours_from')?.errors}"
                            class="form-control form-timepicker " (click)="picker12.open()" #picker12 (ngModelChange)="validateFromTotime('Week Off',i,'hours_from')"></igx-time-picker>
                          <i *ngIf="weekoffarray().controls[i].get ('hours_from')?.value!=null" class="icon-close text-light-400"
                            (click)="clearTime(picker12);picker12.close()"></i>
                        </div>
                        <div  *ngIf="(submitted || weekoffarray().controls[i].get('hours_from')?.touched || weekoffarray().controls[i].get ('hours_from')?.dirty) &&  weekoffarray().controls[i].get ('hours_from')?.errors" class="invalid-feedback">
                          <div *ngIf="weekoffarray().controls[i].get ('hours_from')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="!(weekoffarray().controls[i].get('hours_from')?.errors?.nomatch) && !(weekoffarray().controls[i].get('hours_from')?.errors?.min) && weekoffarray().controls[i].get ('hours_from')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="!(weekoffarray().controls[i].get('hours_from')?.errors?.nomatch) && !(weekoffarray().controls[i].get('hours_from')?.errors?.min) && !(weekoffarray().controls[i].get ('hours_to')?.errors?.beforeTime) && weekoffarray().controls[i].get ('hours_to')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum Week off OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="weekoffarray().controls[i].get('hours_from')?.errors?.nomatch  && !(weekoffarray().controls[i].get('hours_from')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="weekoffarray().controls[i].get('hours_from')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                        </div>
                      </div>
                      <div class="col-1 d-flex fs-14 justify-content-center pt-2"> to</div>
                      <div class="col">
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || weekoffarray().controls[i].get ('hours_to')?.touched || weekoffarray().controls[i].get ('hours_to')?.dirty) &&  weekoffarray().controls[i].get ('hours_to')?.errors}">
                          <igx-time-picker formControlName="hours_to" placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (submitted || weekoffarray().controls[i].get ('hours_to')?.touched || weekoffarray().controls[i].get ('hours_to')?.dirty) &&  weekoffarray().controls[i].get ('hours_to')?.errors}" class="form-control form-timepicker " (click)="picker13.open()" #picker13 (ngModelChange)="validateFromTotime('Week Off',i,'hours_to')"></igx-time-picker>
                          <i *ngIf="weekoffarray().controls[i].get ('hours_to')?.value!=null" class="icon-close text-light-400" (click)="clearTime(picker13 );picker13 .close()"></i>
                        </div>
                        <div *ngIf="(submitted || weekoffarray().controls[i].get ('hours_to')?.touched || weekoffarray().controls[i].get ('hours_to')?.dirty) &&  weekoffarray().controls[i].get ('hours_to')?.errors" class="invalid-feedback">
                          <div *ngIf="weekoffarray().controls[i].get ('hours_to')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                            <div *ngIf="weekoffarray().controls[i].get('hours_to')?.errors?.min  && !(weekoffarray().controls[i].get ('hours_to')?.errors?.required)"> {{this.messageService.greaterThanMessage('From value', 'Value')}} </div>
                            <div *ngIf="weekoffarray().controls[i].get('hours_to')?.errors?.nomatch  && !(weekoffarray().controls[i].get('hours_to')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                            <div *ngIf="!(weekoffarray().controls[i].get('hours_to')?.errors?.nomatch) && !(weekoffarray().controls[i].get('hours_to')?.errors?.min) && weekoffarray().controls[i].get ('hours_to')?.errors?.beforeTime">
                              {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                            </div>
                            <div *ngIf="!(weekoffarray().controls[i].get('hours_to')?.errors?.nomatch) && !(weekoffarray().controls[i].get('hours_to')?.errors?.min) && !(weekoffarray().controls[i].get ('hours_to')?.errors?.beforeTime) && weekoffarray().controls[i].get ('hours_to')?.errors?.afterTime">
                              {{this.messageService.lessThanMessage('Maximum Week off OT Hour', 'Value')}}
                            </div>
                            <div *ngIf="weekoffarray().controls[i].get('hours_to')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                            <div *ngIf="weekoffarray().controls[i].get('hours_to')?.errors?.duplicate">
                              {{this.messageService.overlapMsg()}}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="" class="form-label required">Pay rate </label>
                    <div class="d-flex" [ngClass]="{ 'is-invalid': (submitted || weekoffarray().controls[i].get('rate')?.touched || weekoffarray().controls[i].get('rate')?.dirty) &&  weekoffarray().controls[i].get('rate')?.errors || weekoffarray().controls[i].get('rate')?.errors?.exist}">

                      <input type="text" formControlName="rate" class="form-control" placeholder="{{this.messageService.placeholderdisp('Pay rate')}}" [ngClass]="{ 'is-invalid': (submitted || weekoffarray().controls[i].get('rate')?.touched || weekoffarray().controls[i].get('rate')?.dirty) &&  weekoffarray().controls[i].get('rate')?.errors || weekoffarray().controls[i].get('rate')?.errors?.exist}">
                      <a class="flex-center ms-auto ps-4 sq-40 text-danger" (click)="removeOvertimeIndex('Week Off',i)" *ngIf="i!=0">
                        <i class="icon-trash "></i>
                      </a>
                    </div>
                    <div *ngIf="(submitted || weekoffarray().controls[i].get('rate')?.touched || weekoffarray().controls[i].get('rate')?.dirty) && weekoffarray().controls[i].get('rate')?.errors || weekoffarray().controls[i].get('rate')?.errors?.exist"
                        class="invalid-feedback">
                        <div *ngIf="weekoffarray().controls[i].get('rate')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="!weekoffarray().controls[i].get('rate')?.errors?.pattern && weekoffarray().controls[i].get('rate')?.errors?.max">
                            {{this.messageService.fieldlengthvalidation('value','3')}}
                        </div>
                        <div *ngIf="weekoffarray().controls[i].get('rate')?.errors?.pattern">
                            {{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!weekoffarray().controls[i].get('rate')?.errors?.pattern && weekoffarray().controls[i].get('rate')?.errors?.min">
                          {{this.messageService.fieldlengthvalidation('minvalue',0)}}
                        </div>
                    </div>

                  </div>
                </ng-container>
              </ng-container>
              <div class="col-12">
                <div class="link-primary1 fs-14 d-flex align-items-center" >

                  <a class="link-primary1" (click)="addOverTime('Week Off')"><i class="icon-plus-square-fill me-8"></i>Add
                    more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-12 form-row">
        <label for="" class="form-label  ">Do you want to set overtime for holiday</label>
      </div>
      <div class="col-lg-12 form-row mt-0">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_holiday" class="form-check-input" type="radio" [value]=true
              (click)="setRequired(true,['max_overtime_hour_allow__holiday']);addOverTime('Holiday')">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_holiday" class="form-check-input" type="radio" [value]=false
              (click)="setRequired(false,['max_overtime_hour_allow__holiday']);removeOvertime('Holiday')">
            No
          </label>
        </div>
      </div>
      <ng-container *ngIf="f.set_overtime_holiday.value == true">
        <!-- <div class="col-lg-6  ">
          <label for="" class="form-label required">Formula to calculate Overtime eligibility during holiday </label>
          <ng-select class="form-ngselect " placeholder="Select Value"></ng-select>
        </div> -->
        <div class="col-12 form-row">
          <label for="" class="form-label required">Maximum overtime hours allowed on holiday </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Select the maximum number of hours the employee should work on holidays as overtime."
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>

          <div class="form-icon icon-right"
            [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow__holiday.touched || f.max_overtime_hour_allow__holiday.dirty) &&  f.max_overtime_hour_allow__holiday.errors}">
            <igx-time-picker formControlName="max_overtime_hour_allow__holiday"
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
              [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow__holiday.touched || f.max_overtime_hour_allow__holiday.dirty) &&  f.max_overtime_hour_allow__holiday.errors}"
              class="form-control form-timepicker " (click)="picker5.open()" #picker5 (ngModelChange)="setCompensationCriteria('Holiday','max_overtime_hour_allow__holiday','max')"></igx-time-picker>

            <i *ngIf="f.max_overtime_hour_allow__holiday.value!=null" class="icon-close text-light-400"
              (click)="clearTime(picker5);picker5.close()"></i>
          </div>

          <div
            *ngIf="(submitted || f.max_overtime_hour_allow__holiday.touched || f.max_overtime_hour_allow__holiday.dirty) && f.max_overtime_hour_allow__holiday.errors"
            class="invalid-feedback">
            <div *ngIf="f.max_overtime_hour_allow__holiday.errors?.required">
              {{this.messageService.validationDisplay('required')}}
            </div>
            <div *ngIf="f.max_overtime_hour_allow__holiday.errors?.min">
              {{this.messageService.greaterThanMessage('Minimum hour','Value')}}
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card card-c2 py-16 px-24">
            <div class="row row-16">
              <div class="col-12">
                <p class="title mb-0">OVERTIME COMPENSATION CRITERIA </p>
              </div>
              <ng-container formArrayName="holiday_day_overtime"*ngFor="let item of holidayarray().controls; let i=index; ">
                <ng-container [formGroupName]="i">

                  <div class="col-6">
                    <label for="" class="form-label required">Overtime hours between </label>
                    <div class="row">
                      <div class="col">
                        <!-- <input type="text" name="" class="form-control" placeholder="Enter hour" id=""> -->
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || holidayarray().controls[i].get ('hours_from')?.touched || holidayarray().controls[i].get ('hours_from')?.dirty) &&  holidayarray().controls[i].get ('hours_from')?.errors}">
                          <igx-time-picker formControlName="hours_from"
                            placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                            [ngClass]="{ 'is-invalid': (submitted || holidayarray().controls[i].get ('hours_from')?.touched || holidayarray().controls[i].get ('hours_from')?.dirty) &&  holidayarray().controls[i].get ('hours_from')?.errors}"
                            class="form-control form-timepicker " (click)="picker14.open()" #picker14 (ngModelChange)="validateFromTotime('Holiday',i,'hours_from')"></igx-time-picker>
                          <i *ngIf="holidayarray().controls[i].get ('hours_from')?.value!=null" class="icon-close text-light-400"
                            (click)="clearTime(picker14);picker14.close()"></i>
                        </div>
                        <div  *ngIf="(submitted || holidayarray().controls[i].get ('hours_from')?.touched || holidayarray().controls[i].get ('hours_from')?.dirty) &&  holidayarray().controls[i].get ('hours_from')?.errors" class="invalid-feedback">
                          <div *ngIf="holidayarray().controls[i].get ('hours_from')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="!(holidayarray().controls[i].get('hours_from')?.errors?.nomatch) && !(holidayarray().controls[i].get('hours_from')?.errors?.min) && holidayarray().controls[i].get ('hours_from')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="!(holidayarray().controls[i].get('hours_from')?.errors?.nomatch) && !(holidayarray().controls[i].get('hours_from')?.errors?.min) && !(holidayarray().controls[i].get ('hours_to')?.errors?.beforeTime) && holidayarray().controls[i].get ('hours_to')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum Holiday OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="holidayarray().controls[i].get('hours_from')?.errors?.nomatch  && !(holidayarray().controls[i].get('hours_from')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="holidayarray().controls[i].get('hours_from')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                        </div>
                      </div>
                      <div class="col-1 d-flex fs-14 justify-content-center pt-2"> to</div>
                      <div class="col">
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || holidayarray().controls[i].get ('hours_to')?.touched || holidayarray().controls[i].get ('hours_to')?.dirty) &&  holidayarray().controls[i].get ('hours_to')?.errors}">
                          <igx-time-picker formControlName="hours_to" placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (submitted || holidayarray().controls[i].get ('hours_to')?.touched || holidayarray().controls[i].get ('hours_to')?.dirty) &&  holidayarray().controls[i].get ('hours_to')?.errors}" class="form-control form-timepicker " (click)="picker15.open()" #picker15 (ngModelChange)="validateFromTotime('Holiday',i,'hours_to')"></igx-time-picker>
                          <i *ngIf="holidayarray().controls[i].get ('hours_to')?.value!=null" class="icon-close text-light-400" (click)="clearTime(picker15);picker15.close()"></i>
                        </div>
                        <div *ngIf="(submitted || holidayarray().controls[i].get ('hours_to')?.touched || holidayarray().controls[i].get ('hours_to')?.dirty) &&  holidayarray().controls[i].get ('hours_to')?.errors" class="invalid-feedback">
                          <div *ngIf="holidayarray().controls[i].get ('hours_to')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="holidayarray().controls[i].get('hours_to')?.errors?.min  && !(holidayarray().controls[i].get ('hours_to')?.errors?.required)"> {{this.messageService.greaterThanMessage('From value', 'Value')}} </div>
                          <div *ngIf="holidayarray().controls[i].get('hours_to')?.errors?.nomatch  && !(holidayarray().controls[i].get('hours_to')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="!(holidayarray().controls[i].get('hours_to')?.errors?.nomatch) && !(holidayarray().controls[i].get('hours_to')?.errors?.min) && holidayarray().controls[i].get ('hours_to')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="!(holidayarray().controls[i].get('hours_to')?.errors?.nomatch) && !(holidayarray().controls[i].get('hours_to')?.errors?.min) && !(holidayarray().controls[i].get ('hours_to')?.errors?.beforeTime) && holidayarray().controls[i].get ('hours_to')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum Holiday OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="holidayarray().controls[i].get('hours_to')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                          <div *ngIf="holidayarray().controls[i].get('hours_to')?.errors?.duplicate">
                            {{this.messageService.overlapMsg()}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="" class="form-label required">Pay rate </label>
                    <div class="d-flex" [ngClass]="{ 'is-invalid': (submitted || holidayarray().controls[i].get('rate')?.touched || holidayarray().controls[i].get('rate')?.dirty) &&  holidayarray().controls[i].get('rate')?.errors || holidayarray().controls[i].get('rate')?.errors?.exist}">

                      <input type="text" formControlName="rate" class="form-control" placeholder="{{this.messageService.placeholderdisp('Pay rate')}}" [ngClass]="{ 'is-invalid': (submitted || holidayarray().controls[i].get('rate')?.touched || holidayarray().controls[i].get('rate')?.dirty) &&  holidayarray().controls[i].get('rate')?.errors || holidayarray().controls[i].get('rate')?.errors?.exist}">
                      <a class="flex-center ms-auto ps-4 sq-40 text-danger" (click)="removeOvertimeIndex('Holiday',i)" *ngIf="i!=0">
                        <i class="icon-trash "></i>
                      </a>
                    </div>
                    <div *ngIf="(submitted || holidayarray().controls[i].get('rate')?.touched || holidayarray().controls[i].get('rate')?.dirty) && holidayarray().controls[i].get('rate')?.errors || holidayarray().controls[i].get('rate')?.errors?.exist"
                        class="invalid-feedback">
                        <div *ngIf="holidayarray().controls[i].get('rate')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="!holidayarray().controls[i].get('rate')?.errors?.pattern && holidayarray().controls[i].get('rate')?.errors?.max">
                            {{this.messageService.fieldlengthvalidation('value','3')}}
                        </div>
                        <div *ngIf="holidayarray().controls[i].get('rate')?.errors?.pattern">
                            {{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!holidayarray().controls[i].get('rate')?.errors?.pattern && holidayarray().controls[i].get('rate')?.errors?.min">
                          {{this.messageService.fieldlengthvalidation('minvalue',0)}}
                        </div>
                    </div>

                  </div>
                </ng-container>
              </ng-container>
              <div class="col-12">
                <div class="link-primary1 fs-14 d-flex align-items-center" >

                  <a class="link-primary1" (click)="addOverTime('Holiday')"><i class="icon-plus-square-fill me-8"></i>Add
                    more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-12 form-row">
        <label for="" class="form-label  ">Do you want to set overtime for national holiday</label>
      </div>
      <div class="col-lg-12 form-row mt-0">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_nationalholiday" class="form-check-input" type="radio" [value]=true
              (click)="setRequired(true,['max_overtime_hour_allow__nationalholiday']);addOverTime('National Holiday')">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_nationalholiday" class="form-check-input" type="radio" [value]=false
              (click)="setRequired(false,['max_overtime_hour_allow__nationalholiday']);removeOvertime('National Holiday')">
            No
          </label>
        </div>
      </div>
      <ng-container *ngIf="f.set_overtime_nationalholiday.value == true">
        <!-- <div class="col-lg-6  ">
          <label for="" class="form-label required">Formula to calculate Overtime eligibility during national holiday
          </label>
          <ng-select class="form-ngselect " placeholder="Select Value"></ng-select>
        </div> -->
        <div class="col-12 form-row">
          <label for="" class="form-label required">Maximum overtime hours allowed on national holiday </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Select the maximum number of hours the employee should work on a National holiday as overtime."
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>

          <div class="form-icon icon-right"
            [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow__nationalholiday.touched || f.max_overtime_hour_allow__nationalholiday.dirty) &&  f.max_overtime_hour_allow__nationalholiday.errors}">
            <igx-time-picker formControlName="max_overtime_hour_allow__nationalholiday" class="form-control "
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
              [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow__nationalholiday.touched || f.max_overtime_hour_allow__nationalholiday.dirty) &&  f.max_overtime_hour_allow__nationalholiday.errors}"
              class="form-control form-timepicker " (click)="picker6.open()" #picker6 (ngModelChange)="setCompensationCriteria('National Holiday','max_overtime_hour_allow__nationalholiday','max')"></igx-time-picker>
            <i *ngIf="f.max_overtime_hour_allow__nationalholiday.value!=null" class="icon-close text-light-400"
              (click)="clearTime(picker6);picker6.close()"></i>
          </div>
          <div
            *ngIf="(submitted || f.max_overtime_hour_allow__nationalholiday.touched || f.max_overtime_hour_allow__nationalholiday.dirty) && f.max_overtime_hour_allow__nationalholiday.errors"
            class="invalid-feedback">
            <div *ngIf="f.max_overtime_hour_allow__nationalholiday.errors?.required">
              {{this.messageService.validationDisplay('required')}}
            </div>
            <div *ngIf="f.max_overtime_hour_allow__nationalholiday.errors?.min">
              {{this.messageService.greaterThanMessage('Minimum hour','Value')}}
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card card-c2 py-16 px-24">
            <div class="row row-16">
              <div class="col-12">
                <p class="title mb-0">OVERTIME COMPENSATION CRITERIA </p>
              </div>
              <ng-container formArrayName="national_day_overtime"*ngFor="let item of nationalHolidayarray().controls; let i=index; ">
                <ng-container [formGroupName]="i">

                  <div class="col-6">
                    <label for="" class="form-label required">Overtime hours between </label>
                    <div class="row">
                      <div class="col">
                        <!-- <input type="text" name="" class="form-control" placeholder="Enter hour" id=""> -->
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || nationalHolidayarray().controls[i].get ('hours_from')?.touched || nationalHolidayarray().controls[i].get ('hours_from')?.dirty) &&  nationalHolidayarray().controls[i].get ('hours_from')?.errors}">
                          <igx-time-picker formControlName="hours_from"
                            placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                            [ngClass]="{ 'is-invalid': (submitted || nationalHolidayarray().controls[i].get ('hours_from')?.touched || nationalHolidayarray().controls[i].get ('hours_from')?.dirty) &&  nationalHolidayarray().controls[i].get ('hours_from')?.errors}"
                            class="form-control form-timepicker " (click)="picker16.open()" #picker16 (ngModelChange)="validateFromTotime('National Holiday',i,'hours_from')"></igx-time-picker>
                          <i *ngIf="nationalHolidayarray().controls[i].get ('hours_from')?.value!=null" class="icon-close text-light-400"
                            (click)="clearTime(picker16);picker16.close()"></i>
                        </div>
                        <div  *ngIf="(submitted || nationalHolidayarray().controls[i].get ('hours_from')?.touched || nationalHolidayarray().controls[i].get ('hours_from')?.dirty) &&  nationalHolidayarray().controls[i].get ('hours_from')?.errors" class="invalid-feedback">
                          <div *ngIf="nationalHolidayarray().controls[i].get ('hours_from')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="!(nationalHolidayarray().controls[i].get('hours_from')?.errors?.nomatch) && !(nationalHolidayarray().controls[i].get('hours_from')?.errors?.min) && nationalHolidayarray().controls[i].get ('hours_from')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="!(nationalHolidayarray().controls[i].get('hours_from')?.errors?.nomatch) && !(nationalHolidayarray().controls[i].get('hours_from')?.errors?.min) && !(nationalHolidayarray().controls[i].get ('hours_to')?.errors?.beforeTime) && nationalHolidayarray().controls[i].get ('hours_to')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum National holiday OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="nationalHolidayarray().controls[i].get('hours_from')?.errors?.nomatch  && !(nationalHolidayarray().controls[i].get('hours_from')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="nationalHolidayarray().controls[i].get('hours_from')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                        </div>
                      </div>
                      <div class="col-1 d-flex fs-14 justify-content-center pt-2"> to</div>
                      <div class="col">
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || nationalHolidayarray().controls[i].get ('hours_to')?.touched || nationalHolidayarray().controls[i].get ('hours_to')?.dirty) &&  nationalHolidayarray().controls[i].get ('hours_to')?.errors}">
                          <igx-time-picker formControlName="hours_to" placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (submitted || nationalHolidayarray().controls[i].get ('hours_to')?.touched || nationalHolidayarray().controls[i].get ('hours_to')?.dirty) &&  nationalHolidayarray().controls[i].get ('hours_to')?.errors}" class="form-control form-timepicker " (click)="picker17.open()" #picker17 (ngModelChange)="validateFromTotime('National Holiday',i,'hours_to')"></igx-time-picker>
                          <i *ngIf="nationalHolidayarray().controls[i].get ('hours_to')?.value!=null" class="icon-close text-light-400" (click)="clearTime(picker17);picker17.close()"></i>
                        </div>
                        <div *ngIf="(submitted || nationalHolidayarray().controls[i].get ('hours_to')?.touched || nationalHolidayarray().controls[i].get ('hours_to')?.dirty) &&  nationalHolidayarray().controls[i].get ('hours_to')?.errors" class="invalid-feedback">
                          <div *ngIf="nationalHolidayarray().controls[i].get ('hours_to')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="nationalHolidayarray().controls[i].get('hours_to')?.errors?.min  && !(nationalHolidayarray().controls[i].get ('hours_to')?.errors?.required)"> {{this.messageService.greaterThanMessage('From value', 'Value')}} </div>
                          <div *ngIf="nationalHolidayarray().controls[i].get('hours_to')?.errors?.nomatch  && !(nationalHolidayarray().controls[i].get('hours_to')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="!(nationalHolidayarray().controls[i].get('hours_to')?.errors?.nomatch) && !(nationalHolidayarray().controls[i].get('hours_to')?.errors?.min) && nationalHolidayarray().controls[i].get ('hours_to')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="!(nationalHolidayarray().controls[i].get('hours_to')?.errors?.nomatch) && !(nationalHolidayarray().controls[i].get('hours_to')?.errors?.min) && !(nationalHolidayarray().controls[i].get ('hours_to')?.errors?.beforeTime) && nationalHolidayarray().controls[i].get ('hours_to')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum National holiday OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="nationalHolidayarray().controls[i].get('hours_to')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                          <div *ngIf="nationalHolidayarray().controls[i].get('hours_to')?.errors?.duplicate">
                            {{this.messageService.overlapMsg()}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="" class="form-label required">Pay rate </label>
                    <div class="d-flex"  [ngClass]="{ 'is-invalid': (submitted || nationalHolidayarray().controls[i].get('rate')?.touched || nationalHolidayarray().controls[i].get('rate')?.dirty) &&  nationalHolidayarray().controls[i].get('rate')?.errors || nationalHolidayarray().controls[i].get('rate')?.errors?.exist}">

                      <input type="text" formControlName="rate" class="form-control" placeholder="{{this.messageService.placeholderdisp('Pay rate')}}" [ngClass]="{ 'is-invalid': (submitted || nationalHolidayarray().controls[i].get('rate')?.touched || nationalHolidayarray().controls[i].get('rate')?.dirty) &&  nationalHolidayarray().controls[i].get('rate')?.errors || nationalHolidayarray().controls[i].get('rate')?.errors?.exist}">
                      <a class="flex-center ms-auto ps-4 sq-40 text-danger" (click)="removeOvertimeIndex('National Holiday',i)" *ngIf="i!=0">
                        <i class="icon-trash "></i>
                      </a>
                    </div>
                    <div *ngIf="(submitted || nationalHolidayarray().controls[i].get('rate')?.touched || nationalHolidayarray().controls[i].get('rate')?.dirty) && nationalHolidayarray().controls[i].get('rate')?.errors || nationalHolidayarray().controls[i].get('rate')?.errors?.exist"
                        class="invalid-feedback">
                        <div *ngIf="nationalHolidayarray().controls[i].get('rate')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="!nationalHolidayarray().controls[i].get('rate')?.errors?.pattern && nationalHolidayarray().controls[i].get('rate')?.errors?.max">
                            {{this.messageService.fieldlengthvalidation('value','3')}}
                        </div>
                        <div *ngIf="nationalHolidayarray().controls[i].get('rate')?.errors?.pattern">
                            {{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!nationalHolidayarray().controls[i].get('rate')?.errors?.pattern && nationalHolidayarray().controls[i].get('rate')?.errors?.min">
                          {{this.messageService.fieldlengthvalidation('minvalue',0)}}
                        </div>
                    </div>

                  </div>
                </ng-container>
              </ng-container>
              <div class="col-12">
                <div class="link-primary1 fs-14 d-flex align-items-center" >

                  <a class="link-primary1" (click)="addOverTime('National Holiday')"><i class="icon-plus-square-fill me-8"></i>Add
                    more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-12 form-row">
        <label for="" class="form-label  ">Do you want to set overtime for half working day</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover="Enable this option if you want to set overtime for half working day."
            popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>

      </div>
      <div class="col-lg-12 form-row mt-0">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_halfday_work" class="form-check-input" type="radio" [value]=true
              (click)="setRequired(true,['overtime_eligibility_halfday','max_overtime_hour_allow_halfday_working']);addOverTime('Half Day')">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input formControlName="set_overtime_halfday_work" class="form-check-input" type="radio" [value]=false
              (click)="setRequired(false,['overtime_eligibility_halfday','max_overtime_hour_allow_halfday_working']);removeOvertime('Half Day')">
            No
          </label>
        </div>
      </div>
      <ng-container *ngIf="f.set_overtime_halfday_work.value == true">

        <div class="col-lg-6 form-row">
          <label for="" class="form-label required">Formula to calculate overtime eligibility during half working
            day</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="
            To calculate overtime for a half working day, you can use one of the following formulas:

            1: Working hours - Shift hours (First Half/Second Half)
            This formula calculates the OT hours by subtracting the shift hours (either for the first half or second half) from the total working hours.
            2: Working hours - Minimum hours for half day
            This formula calculates the OT hours by subtracting the minimum required hours for a half day from the total working hours." popoverClass="popover-input popover-info"
              placement="bottom" triggers="hover"></i></span>

          <ng-select formControlName="overtime_eligibility_halfday"
            [ngClass]="{ 'is-invalid': (submitted || f.overtime_eligibility_halfday.touched || f.overtime_eligibility_halfday.dirty) &&  f.overtime_eligibility_halfday.errors}"
            class="form-ngselect " placeholder="{{this.messageService.selectplaceholddisplay('value')}}">
            <ng-option [value]="'Working hours - Shift hours (First Half/Second half)'">Working hours - Shift hours
              (First Half/Second half)</ng-option>
            <ng-option [value]="'Working hours - Minimum hours for half day Actual'">Working hours - Minimum hours for
              half day</ng-option>
          </ng-select>
          <div
            *ngIf="(submitted || f.overtime_eligibility_halfday.touched || f.overtime_eligibility_halfday.dirty) && f.overtime_eligibility_halfday.errors"
            class="invalid-feedback">
            <div *ngIf="f.overtime_eligibility_halfday.errors.required">
              {{this.messageService.validationDisplay('required')}}
            </div>
          </div>
        </div>
        <div class="col-lg-6 form-row">
          <label for="" class="form-label required">Maximum overtime hours allowed on half working day </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Set the maximum OT hours for half working day." popoverClass="popover-input popover-info"
              placement="bottom" triggers="hover"></i></span>

          <div class="form-icon icon-right"
            [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow_halfday_working.touched || f.max_overtime_hour_allow_halfday_working.dirty) &&  f.max_overtime_hour_allow_halfday_working.errors}">
            <igx-time-picker formControlName="max_overtime_hour_allow_halfday_working" class="form-control "
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
              [ngClass]="{ 'is-invalid': (submitted || f.max_overtime_hour_allow_halfday_working.touched || f.max_overtime_hour_allow_halfday_working.dirty) &&  f.max_overtime_hour_allow_halfday_working.errors}"
              class="form-control form-timepicker " (click)="picker7.open()" #picker7 (ngModelChange)="setCompensationCriteria('Halfday','max_overtime_hour_allow_halfday_working','max')"></igx-time-picker>

            <i *ngIf="f.max_overtime_hour_allow_halfday_working.value!=null" class="icon-close text-light-400"
              (click)="clearTime(picker7);picker7.close()"></i>
          </div>

          <div
            *ngIf="(submitted || f.max_overtime_hour_allow_halfday_working.touched || f.max_overtime_hour_allow_halfday_working.dirty) && f.max_overtime_hour_allow_halfday_working.errors"
            class="invalid-feedback">
            <div *ngIf="f.max_overtime_hour_allow_halfday_working.errors.required">
              {{this.messageService.validationDisplay('required')}}
            </div>
            <div *ngIf="f.max_overtime_hour_allow_halfday_working.errors?.min">
              {{this.messageService.greaterThanMessage('Minimum hour','Value')}}
            </div>

          </div>
        </div>


        <div class="col-12">
          <div class="card card-c2 py-16 px-24">
            <div class="row row-16">
              <div class="col-12">
                <p class="title mb-0">OVERTIME COMPENSATION CRITERIA </p>
              </div>
              <ng-container formArrayName="half_day_overtime"*ngFor="let item of halfDayarray().controls; let i=index; ">
                <ng-container [formGroupName]="i">

                  <div class="col-6">
                    <label for="" class="form-label required">Overtime hours between </label>
                    <div class="row">
                      <div class="col">
                        <!-- <input type="text" name="" class="form-control" placeholder="Enter hour" id=""> -->
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || halfDayarray().controls[i].get ('hours_from')?.touched || halfDayarray().controls[i].get ('hours_from')?.dirty) &&  halfDayarray().controls[i].get ('hours_from')?.errors}">
                          <igx-time-picker formControlName="hours_from"
                            placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                            [ngClass]="{ 'is-invalid': (submitted || halfDayarray().controls[i].get ('hours_from')?.touched || halfDayarray().controls[i].get ('hours_from')?.dirty) &&  halfDayarray().controls[i].get ('hours_from')?.errors}"
                            class="form-control form-timepicker " (click)="picker19.open()" #picker19 (ngModelChange)="validateFromTotime('Half Day',i,'hours_from')"></igx-time-picker>
                          <i *ngIf="halfDayarray().controls[i].get ('hours_from')?.value!=null" class="icon-close text-light-400"
                            (click)="clearTime(picker19);picker19.close()"></i>
                        </div>
                        <div  *ngIf="(submitted || halfDayarray().controls[i].get ('hours_from')?.touched || halfDayarray().controls[i].get ('hours_from')?.dirty) &&  halfDayarray().controls[i].get ('hours_from')?.errors" class="invalid-feedback">
                          <div *ngIf="halfDayarray().controls[i].get ('hours_from')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="!(halfDayarray().controls[i].get('hours_from')?.errors?.nomatch) && !(halfDayarray().controls[i].get('hours_from')?.errors?.min) && halfDayarray().controls[i].get ('hours_from')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="!(halfDayarray().controls[i].get('hours_from')?.errors?.nomatch) && !(halfDayarray().controls[i].get('hours_from')?.errors?.min) && !(halfDayarray().controls[i].get ('hours_to')?.errors?.beforeTime) && halfDayarray().controls[i].get ('hours_to')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum Half day OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="halfDayarray().controls[i].get('hours_from')?.errors?.nomatch  && !(halfDayarray().controls[i].get('hours_from')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="halfDayarray().controls[i].get('hours_from')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                        </div>
                      </div>
                      <div class="col-1 d-flex fs-14 justify-content-center pt-2"> to</div>
                      <div class="col">
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || halfDayarray().controls[i].get ('hours_to')?.touched || halfDayarray().controls[i].get ('hours_to')?.dirty) &&  halfDayarray().controls[i].get ('hours_to')?.errors}">
                          <igx-time-picker formControlName="hours_to" placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (submitted || halfDayarray().controls[i].get ('hours_to')?.touched || halfDayarray().controls[i].get ('hours_to')?.dirty) &&  halfDayarray().controls[i].get ('hours_to')?.errors}" class="form-control form-timepicker " (click)="picker18.open()" #picker18 (ngModelChange)="validateFromTotime('Half Day',i,'hours_to')"></igx-time-picker>
                          <i *ngIf="halfDayarray().controls[i].get ('hours_to')?.value!=null" class="icon-close text-light-400" (click)="clearTime(picker18);picker18.close()"></i>
                        </div>
                        <div *ngIf="(submitted || halfDayarray().controls[i].get ('hours_to')?.touched || halfDayarray().controls[i].get ('hours_to')?.dirty) &&  halfDayarray().controls[i].get ('hours_to')?.errors" class="invalid-feedback">
                          <div *ngIf="halfDayarray().controls[i].get ('hours_to')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="halfDayarray().controls[i].get('hours_to')?.errors?.min  && !(halfDayarray().controls[i].get('hours_to')?.errors?.required)"> {{this.messageService.greaterThanMessage('From value', 'Value')}} </div>
                          <div *ngIf="halfDayarray().controls[i].get('hours_to')?.errors?.nomatch  && !(halfDayarray().controls[i].get('hours_to')?.errors?.required)"> {{this.messageService.timingPattern()}} </div>
                          <div *ngIf="!(halfDayarray().controls[i].get('hours_to')?.errors?.nomatch) && !(halfDayarray().controls[i].get('hours_to')?.errors?.min) && halfDayarray().controls[i].get ('hours_to')?.errors?.beforeTime">
                            {{this.messageService.greaterThanMessage('Minimum OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="!(halfDayarray().controls[i].get('hours_to')?.errors?.nomatch) && !(halfDayarray().controls[i].get('hours_to')?.errors?.min) && !(halfDayarray().controls[i].get ('hours_to')?.errors?.beforeTime) && halfDayarray().controls[i].get ('hours_to')?.errors?.afterTime">
                            {{this.messageService.lessThanMessage('Maximum Half day OT Hour', 'Value')}}
                          </div>
                          <div *ngIf="halfDayarray().controls[i].get('hours_to')?.errors?.same"> {{this.messageService.sameasvalidation('Value','Previous value')}} </div>
                          <div *ngIf="halfDayarray().controls[i].get('hours_to')?.errors?.duplicate">
                            {{this.messageService.overlapMsg()}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="" class="form-label required">Pay rate </label>
                    <div class="d-flex" [ngClass]="{ 'is-invalid': (submitted || halfDayarray().controls[i].get('rate')?.touched || halfDayarray().controls[i].get('rate')?.dirty) &&  halfDayarray().controls[i].get('rate')?.errors || halfDayarray().controls[i].get('rate')?.errors?.exist}">

                      <input type="text" formControlName="rate" class="form-control" placeholder="{{this.messageService.placeholderdisp('Pay rate')}}" [ngClass]="{ 'is-invalid': (submitted || halfDayarray().controls[i].get('rate')?.touched || halfDayarray().controls[i].get('rate')?.dirty) &&  halfDayarray().controls[i].get('rate')?.errors || halfDayarray().controls[i].get('rate')?.errors?.exist}">
                      <a class="flex-center ms-auto ps-4 sq-40 text-danger" (click)="removeOvertimeIndex('Half Day',i)" *ngIf="i!=0">
                        <i class="icon-trash "></i>
                      </a>
                    </div>
                    <div *ngIf="(submitted || halfDayarray().controls[i].get('rate')?.touched || halfDayarray().controls[i].get('rate')?.dirty) && halfDayarray().controls[i].get('rate')?.errors || halfDayarray().controls[i].get('rate')?.errors?.exist"
                        class="invalid-feedback">
                        <div *ngIf="halfDayarray().controls[i].get('rate')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="!halfDayarray().controls[i].get('rate')?.errors?.pattern && halfDayarray().controls[i].get('rate')?.errors?.max">
                            {{this.messageService.fieldlengthvalidation('value','3')}}
                        </div>
                        <div *ngIf="halfDayarray().controls[i].get('rate')?.errors?.pattern">
                            {{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!halfDayarray().controls[i].get('rate')?.errors?.pattern && halfDayarray().controls[i].get('rate')?.errors?.min">
                          {{this.messageService.fieldlengthvalidation('minvalue',0)}}
                        </div>

                    </div>

                  </div>
                </ng-container>
              </ng-container>
              <div class="col-12">
                <div class="link-primary1 fs-14 d-flex align-items-center">

                  <a class="link-primary1" (click)="addOverTime('Half Day')"><i class="icon-plus-square-fill me-8"></i>Add
                    more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<footer class="submit-footer">
  <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid "
    (click)="validateForm()">NEXT</button>
  <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
</footer>


<div class="modal  modal-alert {{isconfirmed == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16rem">
              <i class="bi bi-info-circle text-accent2"></i>
              <h4 class="modal-title">Enable Overtime?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">The compensatory benefit has already been enabled for employees who work on weekends. "Do you wish to enable overtime benefits as well?"</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="activateOvertime(false)">Cancel</button>
              <button type="button" class="btn btn-primary btn-sm text-uppercase"  [disabled]="isClicked" (click)="activateOvertime(true)">Enable</button>
          </div>
      </div>
  </div>
</div>
