<div class="container-fluid px-32 py-24">
  <div class="row row-16">
    <div class="col-12 d-flex gap-8  align-items-center">
      <span class="text-light-500 fs-12 text-nowrap">{{requestitem.created_display}}</span>
      <button class="btn-reset ms-auto btn-star text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
        (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8"  *ngIf="requestitem?.request_data?.is_cancel == false">{{requestitem?.request_type=='Advance Dispense'?"Dispense Advance":"Expense Advance"}}</h5>
        <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
          <div class="d-flex align-items-center ms-auto gap-8">
            <span class="badge px-16 ms-auto fs-14 badge-warning py-1" *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
            <span class="badge px-16 ms-auto fs-14 badge-success py-1" *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
            <span class="badge px-16 ms-auto fs-14 badge-danger py-1" *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
            <span class="text-light-400" *ngIf="requestitem?.request_type!='Advance Dispense'">|</span>
            <span class="link-primary fs-14"  *ngIf="requestitem?.request_type!='Advance Dispense'" (click)="requestViewStatus()">View Status</span>
          </div>
        </ng-container>
        <span *ngIf="requestitem?.request_data?.status == 'Invalid'" class="ribbon ribbon-right ribbon-danger  ribbon-sm" style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
      </div>
      <span class=" badge-custom {{requestitem?.request_type_color_code}}" *ngIf="requestitem?.request_data?.is_cancel == false">{{requestitem?.request_type=='Advance Dispense'?"Dispense Advance":"Expense Advance"}}</span>
      <span *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
        class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>
    <div class="col-12" *ngIf="requestitem?.achieved_status && requestitem.approved_person != null && requestitem?.request_type=='Advance Dispense'">
      <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
        <i class="bi fs-16 bi-check-square-fill text-success " *ngIf="requestitem?.request_data?.status=='Accepted'"></i>
        <i class="bi fs-16 bi-x-square-fill text-danger" *ngIf="requestitem?.request_data?.status=='Rejected'"></i>
        <span class="">{{requestitem?.request_data?.status}} by </span>
        <span class="fw-600">{{requestitem.approved_person ==
          requestitem.to_person.id?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
      </div>
    </div>
    <div class="col-12" *ngIf="!requestitem?.achieved_status && requestitem.approved_person == null  && requestitem?.request_type=='Advance Dispense'">
      <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
        <i class="bi fs-16  bi-x-square-fill text-warning "></i>
        <span class="">Pending from </span>
        <span class="fw-600">{{requestitem.approved_person == null?'You':''}}</span>
      </div>
    </div>
    <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar>
    <div class="col-12">
      <p class="mb-0 fs-14" [innerHTML]="requestitem?.message"> </p>
    </div>
    <div class="col-12">
      <div class="card details-card shadow-0 px-24 pb-24 pt-16">
        <div class="row row-16 ">
          <div class="col-12 align-items-center d-flex">
            <p class="title mb-8">ADVANCE DETAILS</p>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 p-16">
              <div class="row row-16">
                <!-- <div class="col-sm-4 col-12">
                  <div class="detail-title">Associated Report</div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_advance?.report_name?requestitem?.requests?.expense_advance?.report_name:'--'}}</div>
                </div> -->
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Expense Advance Amount</div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_advance?.amount| currency :
                    requestitem?.requests?.currency}}</div>
                </div>
                <div class="col-sm-4 col-12" *ngIf="requestitem?.request_type=='Advance Dispense'">
                  <div class="detail-title">Dispensed Amount</div>
                  <div class="detail-desc">{{(requestitem?.requests?.inbox_dispansed_amount > 0 ? requestitem?.requests?.inbox_dispansed_amount : requestitem?.requests?.expense_advance?.dispansed_amount) | currency : requestitem?.requests?.currency }} 
                    <span *ngIf="requestitem?.request_type=='Advance Dispense' && !requestitem?.achieved_status" (click)="resetForm()" class="link-primary1 ms-8"><i class="icon-pencil-square me-1"></i>Edit</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Outstanding Amount</div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_advance?.outstanding_amount| currency :
                    requestitem?.requests?.currency}}</div>
                </div>
                <div class=" col-12">
                  <div class="detail-title">Purpose</div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_advance?.purpose}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Required Date</div>
                  <div class="detail-desc">{{appService.dateFormatDisplay(requestitem?.requests?.expense_advance?.expense_date)}}</div>
                </div>
                <!-- <div class="col-sm-4 col-12">
                  <div class="detail-title">Apply to Trip</div>
                  <div class="detail-desc"> Trip 1</div>
                </div> -->
                <div class=" col-12"  *ngIf="requestitem?.requests?.expense_advance?.attachment!=null && requestitem?.requests?.expense_advance?.attachment != ''">
                  <div class="detail-title">Document </div>
                  <div class="detail-desc">
                    <div class="card card-c2  bg-secondary">
                      <div class="p-12 bg-secondary d-flex align-item-center">
                        <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                          <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appService.getDocName(requestitem?.requests?.expense_advance?.attachment) }} </span>
                          <span class="tips-helper ">{{ this.appService.getDocName(requestitem?.requests?.expense_advance?.attachment) }}</span>
                          <div class="d-flex gap-8">
                            <div class="link-primary" (click)="viewDocument(requestitem?.requests?.expense_advance?.attachment)"> <i class="bi bi-eye me-1"></i>View </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container [formGroup]="form" *ngIf="!requestitem?.achieved_status">
      <div class="col-12">
          <textarea name="" id="" rows="3" placeholder="Comment here..." formControlName="comment"
              class="form-control my-12 textarea-comment"
              [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
          <div *ngIf="f.comment.errors" class="invalid-feedback">
              <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
              </div>
          </div>
      </div>
      <div *ngIf="requestitem?.request_type != 'Advance Dispense'" class="col-12 d-flex gap-16">
          <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
              (click)="confirm('Rejected')">Decline</button>
          <button class=" btn btn-primary text-uppercase btn-sm fw-400"
              (click)="confirm('Approved')">Confirm</button>
      </div>
      <div *ngIf="requestitem?.request_type == 'Advance Dispense'" class="bg-white bottom-0 col-12 d-flex gap-16 justify-content-end position-sticky py-16">
        <button class=" btn btn-primary text-uppercase btn-sm fw-400"
            (click)="confirm('Approved')">DISPENSE</button>
    </div>
  </ng-container>
  </div>
</div>
<div  [formGroup]="dispenseform" class="modal  modal-alert {{editAmount == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Edit</h4>
      </div>
      <div class="modal-body">
        <label for="" class="form-label required">Dispense Amount </label>
        <input type="text"  formControlName="inbox_dispansed_amount" class="form-control" name="" id="" placeholder="Enter amount" max="requestitem?.requests?.expense_advance?.dispansed_amount" [ngClass]="{ 'is-invalid': ( df.inbox_dispansed_amount.touched || df.inbox_dispansed_amount.dirty) &&  df.inbox_dispansed_amount.errors }">
        <div *ngIf="(df.inbox_dispansed_amount?.touched || df.inbox_dispansed_amount?.dirty) && df.inbox_dispansed_amount?.errors " class="invalid-feedback">
          <div *ngIf="df.inbox_dispansed_amount?.errors?.required"> {{this.messageService.validationDisplay('required')}}</div>
          <div *ngIf="df.inbox_dispansed_amount?.errors?.pattern"> {{this.messageService.validationDisplay('pattern')}}</div>
          <div *ngIf="df.inbox_dispansed_amount?.errors?.max && !(df.inbox_dispansed_amount?.errors?.pattern)"> Dispensed Amount should not be greater than Expense Amount</div>
          <div *ngIf="!(df.inbox_dispansed_amount?.errors?.pattern) && !df.inbox_dispansed_amount?.errors?.max && df.inbox_dispansed_amount?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','6')}}</div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="editAmount = false">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm"  [disabled]="!dispenseform.valid" (click)="saveDispenseAmt()">Submit</button>
      </div>
    </div>
  </div>
</div>
<app-view-file *ngIf="modalToggle== true" (modal)="this.modalToggle = $event" [modalToggle]="modalToggle"
    [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
<app-alert-toggle *ngIf="alertToggle==true" (modals)="this.alertToggle =  $event" [alertToggle]="alertToggle"
[alertMsg]="alertMsg"></app-alert-toggle>

<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header><app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>
    </form>
  </div>
</ng-container>