import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormBuilder,Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import {  Router } from '@angular/router';
import { userpasswordValidator } from 'src/app/@shared/validators/userpassword.validators';
import { MessageService } from 'src/app/message.global';
import { WebsocketService } from '../../services/websocket.service';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {
  show_pass: boolean = false;
  viewicon_password = "icon-eye-slash";
  show_confirm: boolean = false;
  viewicon_confirm = "icon-eye-slash";
  userData:any = []
  errorPass = ""
  currentlogodata:any = null
  submitted     = false;
  loginForm     = this.formBuilder.group({
                    password :[null,[Validators.required]],
  })
  getpassword(key: any) {
    if (key == 'pass') {

      this.show_pass = !this.show_pass;
      this.viewicon_password = this.show_pass == true ? 'icon-eye' : 'icon-eye-slash';
    }
    else {
      this.show_confirm = !this.show_confirm;
      this.viewicon_confirm = this.show_confirm == true ? 'icon-eye' : 'icon-eye-slash';
    }
  }
  constructor(
    private authService: AuthService,
    private formBuilder:FormBuilder,
    private notificationService:NotificationService,
    public router: Router,
    public messageService : MessageService,
    public webSocketService: WebsocketService,
    public appservice:AppService
    ) { }

  ngOnInit(): void {
    this.currentlogodata = localStorage.getItem('org_logo');
    this.userData = JSON.parse(this.authService.getUserData());
    if(this.userData == null)
      this.router.navigate(['login']);
    if(this.authService.isLoggedIn == true) {
      this.authService.gotoLastVisited();
    }
  }
  doLogin() {
    this.submitted = true;
    if(this.loginForm.invalid)
    return
    this.authService.signIn({username:this.userData?.username,password:this.loginForm.controls.password.value}).subscribe((res:any)=>{

      if(res.error){
        if(!(res.hasOwnProperty('inactiveuser')))
          this.notificationService.handleErrorNotification(res.error,"Error");
        else{
          this.router.navigate(['login']);
        }
      } else {
        localStorage.setItem('access_token', res.token);
        localStorage.setItem('refresh_token', res.refresh);
        localStorage.setItem('userData', JSON.stringify(res.data));

        this.authService.gotoLastVisited();
      }

    },
    (error: any) => {
      if(error.error.username_error == 0 && error.error.reset == 0){
        this.f.password.setErrors({
          'wrong_password' : true
        })
        this.errorPass     = this.messageService.wrongLoginData('wrong_password');
      }
      else  {
        this.router.navigate(['login']);
      }
    })

  }
  get f() {return  this.loginForm.controls; }
  doLogout() {
    localStorage.removeItem('last_visited_url'+this.authService.isUserId);
    this.authService.doLogout();
    this.webSocketService.closeSocket();
  }
}
