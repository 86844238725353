import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-inbox-progress-bar',
  templateUrl: './inbox-progress-bar.component.html',
  styleUrls: ['./inbox-progress-bar.component.scss']
})
export class InboxProgressBarComponent implements OnInit {

  constructor() { }
  @Input() requestitem: any;
  ngOnInit(): void {
  }

}
