import { Component, OnInit,  Inject, EventEmitter, Output, Input, ElementRef, ViewChild } from '@angular/core';
import { EmployeeReportsService } from 'src/app/@core/services/employee-reports.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-disable-portal-access',
  templateUrl: './disable-portal-access.component.html',
  styleUrls: ['./disable-portal-access.component.scss']
})
export class DisablePortalAccessComponent implements OnInit {

  @Input() companyDD: any;
  @Input() gradeDD: any;
  @Input() BranchDD: any;
  @Input() buDD: any;
  @Input() DepDD: any;
  @Input() DesignationDD: any;
  @Input() checkListElements: any;
  @Input() disabled: any;
  @Input() empstatusDD: any;
  @Input() emptypeDD: any;
  @Input() employeeDropdown: any;
  // Above needs to be removed
  @Input() disabledForm  : any;
  @Input() salFilter: any;
  @Input() viewDetail: any;
  @Input() callemp : any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() dateSetup :  any
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering :any
  @Input() ouFilterForm: any
  @Input() resDataFilter:any;
  userDropdown:any
  Years:any = []
  get f()  { return this.disabledForm.controls; }

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();
  @Output() loader                        = new EventEmitter();

  dateFormat          :any

  isInvalidDate = (m: moment.Moment) =>  {
  }
  fromDateFilter = (date: Date | null): boolean => { return true; }
​
  constructor(public employeereportService:EmployeeReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService) { }

  ngOnInit(): void {
    this.yearListFunction()
    this.getDateFormat()
    this.getUserList()
  }
  getUserList(){
    this.employeereportService.userListDropdown()
    .subscribe((res: any) => {

      // if(res.status == 200) {
        if (res?.length > 0) {
          for(let i=0;i<res?.length;i++){
            res[i]['name'] = res[i]['first_name'];
            if(res[i]['first_name'] != null)
            res[i]['name'] = res[i]['first_name']+" ";
            if(res[i]['middle_name'] != null)
            res[i]['name'] += res[i]['middle_name']+" ";
            if(res[i]['last_name'] != null)
            res[i]['name']   += res[i]['last_name'];
            if(res[i]['employee_code'] != null && res[i]['employee_code'] != '')
            res[i]['name']   +=" ("+res[i]['employee_code']+")";

          }
          this.userDropdown  = res;
          // this.selectAllForDropdownItems(this.userDropdown);
        }
      // }
    })
  }

    // Date Format
    getDateFormat(){
      this.dateFormat = this.appservice.getdatepickerformat();
      if(this.dateFormat == ''){
        setTimeout(() => {
          this.getDateFormat();
        }, 1000);
      }
    }

  // Emiting the value of radio to parent
  radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthtype:any,yeartype:any){
    if(e.target.value == 'on' ){
      this.disabledForm?.get(contrlName)?.setValue(true);
     }
     else{
      this.disabledForm?.get(contrlName)?.setValue(val);
      this.disabledForm?.get(monthtype)?.setValue(null);
      this.disabledForm?.get(yeartype)?.setValue(null);

     }
  }
  yearListFunction(){
    let selectedYear = new Date().getFullYear();
    this.employeereportService.yearListDropdown().subscribe((res: any) => {
     if(res?.created_year == selectedYear){
        this.Years.push(res?.created_year)
      }
      else{
        for(let i=selectedYear;i>=res?.created_year;i--){
          this.Years.push(i)
        }
      }
    })
  }
     //function for get month no from month name
    monthChangeFn(event:any){
      // this.monthValue = (new Date(Date.parse(event +" 1, "+this.selectedYear+"")).getMonth()+1);
      // var date = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
      // this.monthValue = String(date.getMonth() + 1).padStart(2, '0');
    }
  setRequired(val:any){
    var arr = ['specific_month','specific_year']
    if(val){
      arr.forEach((item:any)=>{
          this.disabledForm.get(item)?.setValidators([Validators.required]);
          this.disabledForm.get(item)?.updateValueAndValidity();
        })
      }else{
        arr.forEach((item:any)=>{
          this.disabledForm.get(item)?.clearValidators()
          this.disabledForm.get(item)?.updateValueAndValidity();
        })
      }
    }
  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

resetFilterFunction(){
  if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
    if(!!Object.keys(this.resDataFilter)?.length == true){
      this.resetFilter.emit(true)
    }else{
      this.resetFilterControllers('reset');
    }
  }
}

resetFilterControllers(data:any){
  this.viewDetail = false;
  this.filterPanel.emit(this.viewDetail)
  if(data == 'reset'){
    this.ouFilterForm.get('company')?.reset('');
    this.ouFilterForm.get('department')?.reset('');
    this.ouFilterForm.get('bu')?.reset('');
    this.ouFilterForm.get('designation')?.reset('');
    this.ouFilterForm.get('grade')?.reset('');
    this.ouFilterForm.get('branch')?.reset('');
    this.ouFilterForm.get('employee_list')?.reset('');
    this.ouFilterForm.get('employment_status')?.reset('');
    this.ouFilterForm.get('employee_type')?.reset('');
    this.disabledForm.get('disabled_on')?.reset('this_month');
    this.disabledForm.get('disabled_by')?.reset('')
  }
  this.callMonthlyReport.emit({
    reportName : 'Resignation'
  })
}
applyBtn : boolean = false

/************ Resignatin Details FILTER *****************/

 filterFormData(use:any){

  var date = new Date(Date.parse(this.disabledForm.controls.specific_month.value +" 1, "+this.disabledForm.controls.specific_year.value+""))
  var monthValue = String(date.getMonth() + 1).padStart(2, '0');

  if(this.disabledForm.controls.disabled_on.value == true){
    this.disabledForm.get('disabled_on')?.setValue(this.disabledForm.controls.specific_year.value+'-'+monthValue)
  }
  else{
    this.disabledForm.get('disabled_on')?.setValue(this.disabledForm.controls.disabled_on.value)
  }
  let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
  let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
  let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
  let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
  let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
  let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
  let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
  let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
  let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
  let disabled_by    = this.stringifyCont(this.disabledForm.controls.disabled_by.value);
  let disabled_on    = this.disabledForm.controls.disabled_on.value==null?'':this.disabledForm.controls.disabled_on.value;

  if(use == 'filter'){
    return `disabled_on=${disabled_on}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&disabled_by=${disabled_by}`
  }else{
    return JSON.stringify({'disabled_by':this.disabledForm.controls.disabled_by.value,'disabled_on':this.disabledForm.controls.disabled_on.value,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' :this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
  }
 }

submitdisabledForm(){
  let selectedDate:any;
  this.submitted      = true;
  if (this.disabledForm.invalid) {
    setTimeout(()=>{
      this.scrollToFirstChild()
    },400)
    return;
  }

  let offset =0
  let limit = 20
  offset = this.applyBtn == true ? 0 : this.employeereportService.calculateOffset(this.pageNumber)
  let filterForm = this.filterFormData('filter')

  this.submitDone.emit({
    limit : limit,
    offset : offset,
    filterForm : filterForm
  });
  this.viewDetail           = false;
  this.applyBtn = false
}

  // Tags closing
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }

    if(this.closeControl == 'disabled_by'){
      this.disabledForm.get(this.closeControl).setValue('')
    }else{
      this.ouFilterForm.get(this.closeControl).setValue('')
    }

    this.submitdisabledForm();
  }

  checkToday(start:any,end:any){
    const compareValue = start.localeCompare(end)
    if(compareValue == 0)
      return this.appservice.dateFormatDisplay(start)
    else
      return `${this.appservice.dateFormatDisplay(start)} - ${this.appservice.dateFormatDisplay(end)}`
  }

  convertToDate(value:any){
    var date = new Date(value); // M-D-YYYY

    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();

    var dateString = (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
    dateString = dateString.split("-").reverse().join("-")
    return dateString
  }

  resetPage(){
    this.applyBtn = true
    this.employeereportService.calculateOffset(0)
    // this.cd.detectChanges()
  }

  getData() {
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }
  clearcommonForm(item:any,index:any){
    if(item == "employeerm"){
      let daata = this.disabledForm.value.disabled_by;
      daata.splice(index, 1);
      this.disabledForm.patchValue({
        disabled_by: daata
      });
    }

  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
