<div class="side-panel-body" style="overflow-y: auto;">
  <div class="container-fluid p-32">
    <app-view-loader *ngIf="loader1"></app-view-loader>
    <ng-container *ngIf="!loader1">
    <div class="d-flex mb-16" >
      <p class="text-uppercase fw-500 mb-0">WORK FROM HOME REQUEST</p>
    </div>
    <div class="row row-24 ">
      <div class="col-12">
        <div class="card details-card shadow-0 px-24 py-16">
          <div class="row row-24">
            <div class="col-12 d-flex">
              <span class="title">work from home details</span>
              <div class="d-flex align-items-center gap-8 ms-auto">
              <span class=" fs-14 ms-auto px-2 fw-500 badge-warning  badge3"
                *ngIf="workFromHomedata?.status == 'Pending'">
                {{workFromHomedata?.status}}
              </span>
              <span class=" fs-14 ms-auto px-2 fw-500 badge-success  badge3"
                *ngIf="workFromHomedata?.status == 'Approved' || workFromHomedata?.status == 'Accepted'">
                {{workFromHomedata?.status}}
              </span>
              <span class=" fs-14 ms-auto px-2 fw-500 badge-danger  badge3"
                *ngIf="workFromHomedata?.status == 'Rejected'">
                {{workFromHomedata?.status}}
              </span>
              <span class="ribbon ribbon-right ribbon-secondary"
                *ngIf="workFromHomedata?.status == 'Cancelled'">
                {{workFromHomedata?.status}}
              </span>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 p-16">
                <div class="row row-24">
                  <div class="col-md-4">
                    <div class="detail-title">From date</div>
                    <div class="detail-desc">{{appService.dateFormatDisplay(workFromHomedata?.start_date)}} , {{workFromHomedata?.start_date_name}}
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">To date</div>
                    <div class="detail-desc">{{appService.dateFormatDisplay(workFromHomedata?.end_date)}} , {{workFromHomedata?.end_date_name}}</div>

                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">No. of days</div>
                    <div class="detail-desc">{{workFromHomedata?.totday}} Days</div>

                  </div>

                  <div class="col-12">
                    <div class="detail-title">Full day</div>
                    <div class="detail-desc" *ngIf="workFromHomedata.fulldayarr != null && workFromHomedata.fulldayarr !==''">{{createarrayDisplay(workFromHomedata?.fulldayarr)}}</div>
                    <div class="detail-desc" *ngIf="workFromHomedata.fulldayarr == null || workFromHomedata.fulldayarr ==''">--</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Half day</div>
                    <div class="detail-desc" *ngIf="workFromHomedata?.halfdayarr != null && workFromHomedata?.halfdayarr !=''">{{createarrayDisplay(workFromHomedata?.halfdayarr)}}</div>
                    <div class="detail-desc" *ngIf="workFromHomedata?.halfdayarr == null || workFromHomedata?.halfdayarr ==''">--</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Reason</div>
                    <div class="detail-desc">{{workFromHomedata?.reason}}</div>
                  </div>
                  <div class="col-12" *ngIf="workFromHomecancel">
                    <div class="detail-title">Reason for cancellation</div>
                    <div class="detail-desc">{{workFromHomedata?.remark}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  </div>
</div>
<ng-container *ngIf="workFromHomecancel==false">
  <footer class="side-panel-footer position-absolute bottom-0 w-100" *ngIf="workFromHomedata?.status == 'Pending' || workFromHomedata?.status == 'Accepted'">
    <button type="submit" class="ms-auto btn btn-outline-danger text-uppercase btn-sm"
      (click)="cancelRequest(workFromHomedata?.status, workFromHomedata?.wfhid,workFromHomedata?.cancellation_raised_status,workFromHomedata?.arrear_valid)" >
      CANCEL REQUEST
    </button>
  </footer>
</ng-container>

<ng-container *ngIf="requestCancel">
  <app-cancel-request-modal [requestCancel]="requestCancel" [requestName]="'Work From Home'" [requestId]="cancelId"
    [requestStatus]="workFromHomedata?.status" (modal)="close($event)"></app-cancel-request-modal>
</ng-container>
