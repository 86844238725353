import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FamilyService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getFamilylData(id:number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-detail-view/${id}/`, this.httpOption)
  }

  updateFamilyData(id: number, body:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/family-detail/${id}/`, body, this.httpOption)
  }
  createChildData(body:any) {
    return this.http.post(`${this.API_PATH}organization/api/v1/children-list/`, body, this.httpOption)
  }
  updateChildData(id:number,body:any){
    return this.http.put(`${this.API_PATH}organization/api/v1/children-detail/${id}/`, body, this.httpOption)
  }
  deleteChildData(id:number){
    return this.http.delete(`${this.API_PATH}organization/api/v1/children-detail/${id}/`,this.httpOption)
  }
  createDependentData(body:any) {
    return this.http.post(`${this.API_PATH}organization/api/v1/dependent-list/`, body, this.httpOption)
  }
  updateDependentData(id:number,body:any){
    return this.http.put(`${this.API_PATH}organization/api/v1/dependent-detail/${id}/`, body, this.httpOption)
  }
  deleteDependentData(id:number){
    return this.http.delete(`${this.API_PATH}organization/api/v1/dependent-detail/${id}/`,this.httpOption)
  }
  getSingleChildData(id:number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/children-detail/${id}`, this.httpOption)
  }
  getSingleDependentData(id:number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/dependent-detail/${id}`, this.httpOption)
  }
  createFamilyData(body:any) {
    return this.http.post(`${this.API_PATH}organization/api/v1/family-create/`, body, this.httpOption)
  }
  createCompleteFamilyData(body:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/family-data-create/`, body, this.httpOption)
  }

}
