<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="liveLocationForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
                <div class="row row-16 mb-16">

                  <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.request_month.errors}">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0 required">Month</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="request_month" type="radio" id="attendance_month1"
                                class="form-check-input  " formControlName="request_month" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'request_month','last_month','specific_month','specific_year');setRequired(false)">
                            <label for="attendance_month1" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="request_month" type="radio" id="attendance_month2"
                                class="form-check-input " formControlName="request_month" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'request_month','this_month','specific_month','specific_year');setRequired(false)">
                            <label for="attendance_month2" class="form-check-label">Current month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="request_month" type="radio" id="attendance_month3"
                                class="form-check-input " formControlName="request_month" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'request_month',true,'specific_month','specific_year');setRequired(true)">
                            <label for="attendance_month3" class="form-check-label">Specific month</label>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="liveLocationForm.value.request_month==true">

                      <div class="col-12 form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                          <label for="" class="form-label">Year </label>
                          <ng-select class="form-ngselect h-36 fs-14"
                          formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                              <ng-option *ngFor="let yData of Years"
                                  [value]="yData">{{yData}}</ng-option>
                          </ng-select>
                      </div>
                      <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                          <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                          </div>
                      </div>
                      <div class="col-12 form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched) &&  f?.specific_month?.errors}">
                          <label for="" class="form-label">Month </label>
                          <app-master-select class="config-app-master" style="width:9rem"
                          [placeholder]="month_placeholder"
                                  id="month" [isrequired]="false" [selectedItem]="liveLocationForm.value.specific_month"
                                [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                                  (ngModelChange)="monthChangeFn($event)" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched) &&  f?.specific_month?.errors}"> </app-master-select>
                      </div>
                      <div *ngIf="(submitted || f?.specific_month?.touched)&& f?.specific_month?.errors" class="invalid-feedback">
                          <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                          </div>
                      </div>

                    </ng-container> -->
                    <ng-container *ngIf="liveLocationForm.value.request_month==true">

                      <div class="col-6">
                          <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                              <label for="" class="form-label">Year </label>
                              <ng-select class="form-ngselect  fs-14"
                              formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                  <ng-option *ngFor="let yData of Years"
                                      [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                          </div>
                          <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                              <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                        <div class="col-6">
                          <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}">
                              <label for="" class="form-label">Month </label>
                              <app-master-select class="config-app-master" style="width:9rem"
                              [placeholder]="month_placeholder"
                                      id="month" [isrequired]="false" [selectedItem]="liveLocationForm.value.specific_month"
                                  [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                                      (ngModelChange)="monthChangeFn($event)" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}"> </app-master-select>
                          </div>
                          <div *ngIf="(submitted || f?.specific_month?.touched || f?.specific_month?.dirty)&& f?.specific_month?.errors" class="invalid-feedback">
                              <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                  </ng-container>
                  </div>
                  <div *ngIf="(submitted) && f.request_month.errors" class="invalid-feedback">
                      <div *ngIf="f.request_month.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>

                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-0 required">Request Status </p>
                  </div>
                  <div class="col-12 form-row">
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('request status')}}"
                          formControlName="request_status" [items]="requestStatusList" [multiple]="true"
                          groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                          bindLabel="name" [closeOnSelect]="false" bindValue="value" class="multiselect" [ngClass]="{ 'is-invalid': (submitted) &&  f.request_status.errors}">
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{standalone: true}" /> Select All
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" />
                              {{ item.name }}
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngIf="liveLocationForm.value.request_status?.length ">
                                  <span class="ng-value-label">{{liveLocationForm.value.request_status?.length}}
                                      request status
                                      selected</span>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-index="index">
                              {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                          </ng-template>
                      </ng-select>
                      <div *ngIf="(submitted)  && f.request_status.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.request_status.errors.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                    </div>
                      <ul class="selected-values mt-16" *ngIf="liveLocationForm.value.request_status?.length != 0">
                          <li class="ng-value" *ngFor="let item of liveLocationForm.value.request_status,let ki =index">
                              <ng-container *ngFor="let insideitem of requestStatusList, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['name']==(item === 'Accepted' ? 'Approved' : item)">
                                      {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                      slice:0:15)+'..':(insideitem['name']) }}</span>
                              </ng-container>
                              <span *ngIf="disabled==false" class="ng-value-icon right"
                                  (click)="clearcommonForm('requestStat',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                  </div>

                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-0 required">Request Type </p>
                  </div>
                  <div class="col-12 form-row">
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('request type')}}"
                          formControlName="request_type" [items]="requestTypeList" [multiple]="true"
                          groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                          bindLabel="name" [closeOnSelect]="false" bindValue="value" class="multiselect" [ngClass]="{ 'is-invalid': (submitted) &&  f.request_type.errors}">
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{standalone: true}" /> Select All
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" />
                              {{ item.name }}
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngIf="liveLocationForm.value.request_type?.length ">
                                  <span class="ng-value-label">{{liveLocationForm.value.request_type?.length}}
                                      request type
                                      selected</span>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-index="index">
                              {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                          </ng-template>
                      </ng-select>
                      <div *ngIf="(submitted)  && f.request_type.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.request_type.errors.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                    </div>
                      <ul class="selected-values mt-16" *ngIf="liveLocationForm.value.request_type?.length != 0">
                          <li class="ng-value" *ngFor="let item of liveLocationForm.value.request_type,let ki =index">
                              <ng-container *ngFor="let insideitem of requestTypeList, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['name']==(item === 'Accepted' ? 'Approved' : item)">
                                      {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                      slice:0:15)+'..':(insideitem['name']) }}</span>
                              </ng-container>
                              <span *ngIf="disabled==false" class="ng-value-icon right"
                                  (click)="clearcommonForm('requestType',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                  </div>

                  <!-- OU Item -->
                  <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>

                </div>
            </div>
          </div>
          <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage('reset');resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage('apply');submitassetRequest()">
                Apply
            </button>
        </footer>
        </form>
      </div>

