<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row row-24">
        <div class="col-12">
            <h3>Login Page Customization</h3>
            <p class="text-helper mb-md-0">Customize your login page here by editing the data in the left panel to see the preview towards the right panel</p>
        </div>
        <app-edit-loader *ngIf="loader"></app-edit-loader>
        <ng-container *ngIf="loader==false">
          <div class="col-md-6">
              <div class="card card-c2 p-24">
                <form class="" autocomplete="off" [formGroup]="org_form">
                  <div class="row row-24">
                      <!-- <div class="col-12" *ngIf="defaultbtn">
                          <label for="name" class="form-label required">Primary heading </label>
                          <input type="text"  class="form-control"  value="Sit back and relax" placeholder="" >
                      </div>
                      <div class="col-12" *ngIf="defaultbtn">
                          <label for="" class="form-label required">Secondary heading </label>
                          <textarea type="text" class="form-control " rows="3" placeholder="" >We simplify, streamline and optimize your HR processes</textarea>
                      </div> -->
                      <div class="col-12">
                          <label for="welcome_message" class="form-label required">Welcome message </label>
                          <input type="text" formControlName="welcome_message" class="form-control"
                                      autofill="welcome_message" [(ngModel)]="welcom_msg"
                                      [ngClass]="{ 'is-invalid': (submitted || f.welcome_message?.dirty || f.welcome_message?.touched) && f.welcome_message?.errors || f.welcome_message?.errors?.exist}"
                                      id="welcome_message" placeholder="{{this.messageService.placeholderdisp('welcome message')}}" (keyup)="valuechange($event)">
                                  <div *ngIf="(submitted || f.welcome_message?.dirty || f.welcome_message?.touched) && f.welcome_message?.errors || f.welcome_message?.errors?.exist"
                                      class="invalid-feedback">
                                          <div *ngIf="f.welcome_message?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                          <div *ngIf="!(f.welcome_message?.errors?.pattern) && f.welcome_message?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','18')}}
                                          </div>
                                          <div *ngIf="f.welcome_message?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                  </div>
                      </div>
                      <div class="col-12" >
                          <label for="logo" class="form-label required">Background image </label>
                          <label for="logo"
                            [ngClass]="{ 'is-invalid': (submitted ||  f.logo?.touched || invalidFile) && (f.logo.errors || invalidFile) }"
                            class="upload-btn ">
                            <img *ngIf="imageUrl!=''" class="upload-img" [src]="imageUrl">
                            <img *ngIf="imageUrl =='' || imageUrl == null && defaultbtn==false" class="upload-img" src="{{currentdata?.background_image}}">
                            <div  class="upload-text" *ngIf="permissions?.e">Upload
                              <span  class="text-primary">New image </span>
                            </div>
                            <app-file-upload [crop]="true" [round]="false" [id]="inputId" [width]="504"
                              [FileType]="'.png,.jpeg,.jpg'" (dataURL)="getURL($event)" (fileformat)="getfileformat($event)" (validsize)="getvalidsize($event)"></app-file-upload 
                              >
                          </label>
                          <div *ngIf="(submitted ||  f.logo?.touched || invalidFile) && (f.logo.errors || invalidFile)" class="invalid-feedback">
                              <div *ngIf="!(invalidFile)">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="invalidFile">{{errormsg}}</div>
                          </div>
                          <div class="fs-12 text-light-400">
                            <span class="text-nowrap">For best quality, please upload images with dimensions of 500x800 pixels or larger.</span>
                          </div>
                      </div>
                     <div class="col-12 d-flex align-items-center gap-16 justify-content-end">
                        <button class="link-primary px-3 text-uppercase fs-14 btn-reset" (click)="setdefualt()" href="#" [disabled]="defaultbtn">Set to default</button>
                        <button class="btn btn-primary text-uppercase btn-sm" [disabled]="btnenable==false" (click)="saveorg()" >Save</button>
                      </div>
                  </div>
                </form>
              </div>
          </div>
          <div class="col-md-6">
            <div class="card card-c2 p-24">
                 <div class="row row-12">
                    <div class="col-12"><div class="fw-500 text-uppercase">LOGIN PREVIEW</div></div>
                    <div class="col-12">
                        <p class=" mb-0 text-helper">Customize your login page completely according to your organization brand guideline. You can edit the welcome message and upload a new background image to view here. </p>
                    </div>
                    <div class="col-12" *ngIf="Isalert">
                        <div role="alert" class="alert alert-warning mb-0 show">
                            <p class="mb-0 fs-14">Your organization logo will be used in the login page for branding</p>
                            <div class="btn-close pointer" (click)="Isalert=false;"></div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="box">
                            <div class="full-page">
                                <div class="bg-section text-center" [ngStyle]="{'background-image': 'url(' + imageUrl + ')'}" *ngIf="imageUrl != '' && imageUrl != null">
                                  <div class="mx-auto" style="max-width: 31.25rem;" *ngIf="defaultbtn">
                                    <div class="fs-44 pb-21 fw-700">Sit back and relax</div>
                                    <div class="fs-28 fw-600">We simplify, streamline and optimize your HR processes</div>
                                  </div>
                                </div>
                                <div class="bg-section text-center" style="background-image: url({{currentdata?.background_image}});" *ngIf="imageUrl == '' || imageUrl == null && defaultbtn == false">
                                  <div class="mx-auto" style="max-width: 31.25rem;" *ngIf="defaultcls">
                                    <div class="fs-44 pb-21 fw-700">Sit back and relax</div>
                                    <div class="fs-28 fw-600">We simplify, streamline and optimize your HR processes</div>
                                  </div>
                                </div>
                                <div class="form-section">
                                  <div class="card p-32 mx-auto mt-4 form-card">
                                    <a routerLink="/" class="text-center d-block">
                                      <img class="img-fluid org-logo" src="{{organizationlogo}}" alt="">
                                    </a>
                                    <h3 class=" fw-600 text-center py-40" *ngIf="welcom_msg == '' ||welcom_msg == null">{{currentdata?.welcome_message}}</h3>
                                    <h3 class=" fw-600 text-center py-40" *ngIf="welcom_msg != ''">{{welcom_msg}}</h3>
                                    <form >

                                      <div class="mb-24">
                                        <label for="username" class="form-label">Email or employee code</label>
                                        <input type="text" class="form-control" placeholder="alex@skizzle.com" >
                                      </div>
                                      <div class="mb-16" >
                                        <label for="" class="form-label">Password</label>
                                        <div class="form-icon icon-right" >
                                          <input type="text"  class="form-control" placeholder="*************" >
                                          <i  class="pointer bi bi-eye" ></i>
                                        </div>

                                      </div>
                                      <div class="d-block text-end fs-14 link "><span class="link-primary" >Forgot password?</span></div>
                                      <div class="d-grid gap-2 pt-5">
                                         <button  type="submit" class="btn btn-primary" >Login</button>
                                      </div>
                                    </form>
                                  </div>
                                  <p class="text-center mb-0  text-light-400 py-24"><small> Privacy policy  |  Terms and conditions | {{this.appservice.version}}</small></p>
                                </div>
                              </div>
                        </div>

                    </div>
                 </div>
            </div>
          </div>
        </ng-container>
    </div>
</div>
