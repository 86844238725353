import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { EmailValidator, FormBuilder,  Validators } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { OrganizationService } from '../../../../@core/services/organization.service';
import { CompanyService } from '../../../../@core/services/company.service';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { PinValidator } from 'src/app/@shared/validators/pinnum.validators';
import { EmailinputValidator } from 'src/app/@shared/validators/emailinput.validators';
import { websiteValidator } from 'src/app/@shared/validators/website.validators';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { AppService } from 'src/app/app.global';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  showSidePanel=false;
  slider_head:string="";
  slider_class:any = ""
  org_form:any;
  org_flag = false;
  editflag =false;
  imageUrl: any ;
  submitted = false;
  invalidFile           = false;
  create_flag = false;
  logo_flag = false;
  loader = true;
  errormsg:any          = "";
  FileType: string = "image/png, image/gif, image/jpeg"
  inputId: string = "logo";
  city_list1: any;
  city_list2: any;
  user_list:any;
  region_list:any;
  billing_region_list:any;
  country_list: any;
  phoneCode:any[]             = [];
  validated : boolean = false
  companyUnit : any
  routeComponent : any
  CountrySelect = false;
  CountrySelect1 = false;
  StateSelect = false;
  countryInd = false;
  pinValid = true;
  billing_pinValid = true;
  mobValid = true;
  addValid = true;
  organizationdata:any=[];
  orgid:any;
  checkedbox:any;
  private subjectKeyUp = new Subject<any>();
  value: any;
  permissions:any = [];

  constructor(
    public messageService : MessageService,
    public formBuilder: FormBuilder,
    public OrganizationService : OrganizationService,
    private companyService: CompanyService,
    public existDataService :  ExistDatasService,
    public appService: AppService

  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService(d.fieldName, d.fieldValue);
    })
    this.getPermission();
    this.getorgdata();
    this.CountrySelect1 = true;
    this.CountrySelect = true;
    this.StateSelect = true;
    this.org_form = this.formBuilder.group({
      logo: [''],
      organization_name: ['', [Validators.required,UnamepatternValidator(), Validators.maxLength(100)]],
      website: ['', [Validators.required,websiteValidator(),Validators.maxLength(50)]],
      register_address: ['', [Validators.required, Validators.maxLength(500)]],
      register_country: [null, [Validators.required]],
      register_state: [null, [Validators.required]],
      register_city: [null, [Validators.required]],
      register_pin: ['', [Validators.required, PinValidator()]],
      address_register_same: [''],
      phone_code: [''],
      phone_number: ['', [Validators.minLength(10), Validators.maxLength(10),NumberpatternValidator()]],
      contact_person: ['', [NamefieldValidator(), Validators.maxLength(100)]],
      billing_contact_email: ['', [Validators.required,EmailinputValidator()]],
      billing_address: ['', [Validators.required, Validators.maxLength(500)]],
      billing_country: [null, [Validators.required]],
      billing_state: [null, [Validators.required]],
      billing_city: [null, [Validators.required]],
      billing_pin: ['', [Validators.required, PinValidator()]],
    });
    this.countryList();
    // this.cityList();
    // this.cityList2();
    // this.regionList1();
    // this.regionList2();
    this.userList();
    this.getPhonecode();
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  get f() { return this.org_form.controls; }
   /**************** METHOD FOR GETTING COUNTRY LIST *********************/
  countryList() {
    this.companyService.getCountryList()
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.country_list = res.body;
      }
    })
  }
  /***************** COUNTRY SELECT **************************/

    countrySelectFun1() {
      this.org_form.get('register_state').reset();
      this.org_form.get('register_city').reset();
      this.region_list = [];
      this.city_list1   = [];
      this.CountrySelect = true;
      this.regionList1();

    }
    countrySelectFun2() {
      this.org_form.get('billing_state').reset();
      this.org_form.get('billing_city').reset();
      this.billing_region_list = [];
      this.city_list2   = [];
      this.CountrySelect1 = true;
      this.regionList2();
    }
      /**************** METHOD FOR GETTING REGION LIST *********************/
  regionList1() {
    if (typeof this.org_form.getRawValue().register_country === 'number'){
      this.companyService.getRegionList(this.org_form.getRawValue().register_country)
      .subscribe((res: any) => {
        if (res.length != 0) {
          this.region_list = res.body;
        }
      })
    }
  }
  regionList2() {
    if (typeof this.org_form.getRawValue().billing_country === 'number'){
      this.companyService.getRegionList(this.org_form.getRawValue().billing_country)
      .subscribe((res: any) => {
        if (res.length != 0) {
          this.billing_region_list = res.body;
        }
      })
    }
  }
  /**************** METHOD FOR GETTING CITY LIST *********************/
  stateSelectFun1(){
    this.org_form.get('register_city').reset();
    this.city_list1 = [];
    this.cityList();
  }
  stateSelectFun2(){
    this.org_form.get('billing_city').reset();
    this.city_list2 = [];
    this.cityList2();
  }
  cityList() {
    if(this.org_form?.getRawValue()?.register_state != null){
    this.companyService.getCityList(this.org_form.getRawValue().register_state, 'true')
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.city_list1 = res.body;
      }
    })
    }
  }

  cityList2() {
    if(this.org_form.getRawValue().billing_state !=''){
  this.companyService.getCityList(this.org_form.getRawValue().billing_state, 'true')
  .subscribe((res: any) => {
    if (res.length != 0) {
      this.city_list2 = res.body;
    }
  })
  }
  }
    /************ METHOD FOR GETTING USER LIST FOR CONTACT PERSON AND SIGNATORY NAME ***********/
    userList() {
      this.companyService.getUserList()
      .subscribe((res: any) => {

          this.user_list = res.body;
          let len = this.user_list.length;
          for (let i = 0; i < len; i++) {
            this.user_list[i]['fullname']  = "";
            if(this.user_list[i]['first_name'] != null)
             this.user_list[i]['fullname'] += this.user_list[i]['first_name']+" ";
            if(this.user_list[i]['middle_name'] != null)
             this.user_list[i]['fullname'] += this.user_list[i]['middle_name']+" ";
            if(this.user_list[i]['last_name'] != null)
             this.user_list[i]['fullname'] += this.user_list[i]['last_name'];


          }

      })
    }

  getPhonecode(){
    this.companyService.getCountryCode().subscribe((res:any)=>{
      this.phoneCode  = res.body;
    })

  }
  checkValue(event:any){
    if(event.target.checked){
      this.checkedbox =true;
      this.setbillingSelectData();
    }
    else{
      this.checkedbox =false;
      this.org_form.get('billing_country')?.enable();
      this.org_form.get('billing_city')?.enable();
      this.org_form.get('billing_state')?.enable();
      this.org_form.get('billing_pin')?.enable();
      this.org_form.get('billing_address')?.enable();
      this.org_form.get('billing_address')?.setValue("");
      this.org_form.get('billing_city')?.setValue(null);
      this.org_form.get('billing_state')?.setValue(null);
      this.org_form.get('billing_country')?.setValue(null);
      this.org_form.get('billing_pin')?.setValue("");
    }
  }
  currentValueChange(){
    if(this.org_form.get('address_register_same')?.value==true)
      this.setbillingSelectData();
  }
  setbillingSelectData(){

    // this.org_form.get('billing_pin')?.disable();
    // this.org_form.get('billing_city')?.disable();
    // this.org_form.get('billing_state')?.disable();
    // this.org_form.get('billing_country')?.disable();
    // this.org_form.get('billing_address')?.disable();
    this.org_form.get('billing_address')?.setValue(this.org_form.value.register_address);
    if(this.org_form.get('register_country').valid){
      this.org_form.get('billing_country')?.setValue(this.org_form.value.register_country);
    }
    if(this.org_form.get('register_state').valid){
      this.org_form.get('billing_state')?.setValue(this.org_form.value.register_state);
      this.regionList2();
    }
    if(this.org_form.get('register_city').valid){
      this.org_form.get('billing_city')?.setValue(this.org_form.value.register_city);
      this.cityList2();
    }
    this.org_form.get('billing_pin')?.setValue(this.org_form.value.register_pin);
  }
  getURL(event: any) {
    this.invalidFile = false;
    this.errormsg = "";
    this.imageUrl = event;
    this.logo_flag = true;
    this.org_form.get('logo').setValue(event);
  }
  deleteLogo(event: any) {
    this.invalidFile = false;
    this.errormsg = "";
    this.org_form.get('logo').setValue('');
    this.imageUrl = '';
    this.logo_flag = true;
  }
  getfileformat(event:any){
    this.org_form.get('logo').setValue('');
    this.imageUrl    = '';
    this.invalidFile = false;
    if(event==false){
      this.invalidFile = true;
      this.errormsg = this.messageService.validateFileFormat('jpeg,png,jpg');
    }
  }
  getvalidsize(event:any){
    this.org_form.get('logo').setValue('');
    this.imageUrl    = '';
    this.invalidFile = true;
    this.errormsg    = event;
  }

  getorgdata(){
    this.loader= true;
    this.OrganizationService.getorgdataid()
    .subscribe((res: any) => {
      if(res.length != 0){
        this.org_flag = false;
        this.orgid = res[0]['id'];
        this.OrganizationService.getorgdata(this.orgid)
        .subscribe((res: any) => {
          this.slider_head      ="Edit";
          this.slider_class = "icon-pencil-square"
          this.organizationdata = res;

          this.loader = false;
        })
      }else{
        this.slider_head      ="Add";
        this.slider_class = "icon-plus"
        this.org_flag = true;
        this.loader = false;
      }
    })
  }
  addorg(){
    this.showSidePanel  =true;
    if(this.slider_head     == 'Edit'){
      this.editflag =true;
      this.submitted = false;
      this.OrganizationService.getorgdata(this.orgid)
      .subscribe((res: any) => {
        // console.log(res);

        this.imageUrl = res.logo;
        this.org_form.reset({
        'logo':res.logo,
        'organization_name': res.organization_name,
        'website': res.website,
        'register_address':res.register_address,
        'register_country':res.register_country,
        'register_state': res.register_state,
        'register_city':res.register_city,
        'register_pin':res.register_pin,
        'phone_number':res.phone_number,
        'billing_address':res.billing_address,
        'billing_country':res.billing_country,
        'billing_state':res.billing_state,
        'billing_city':res.billing_city,
        'billing_pin':res.billing_pin,
        'contact_person':res.contact_person,
        'billing_contact_email':res.billing_contact_email});
      this.regionList1();
      this.regionList2();
      this.cityList();
      this.cityList2();
    })
    }else{
      this.imageUrl         = '';
      this.org_form.reset();
    }
  }
  saveorg(){
    this.submitted = true;
    if(this.org_form.invalid)
    return;
    let myFormData ={
      'logo' : this.org_form.value.logo,
      'organization_name' : this.org_form.value.organization_name,
      'website' : this.org_form.value.website,
      'register_address' : this.org_form.value.register_address,
      'register_country' : this.org_form.value.register_country,
      'register_state' : this.org_form.value.register_state,
      'register_city' : this.org_form.value.register_city,
      'register_pin' : this.org_form.value.register_pin,
      'phone_code' : this.org_form.value.phone_code!=null?this.org_form.value.phone_code:91,
      'phone_number' : this.org_form.value.phone_number === null ? '' : this.org_form.value.phone_number,
      'billing_address' : this.org_form.getRawValue().billing_address,
      'billing_country' : this.org_form.getRawValue().billing_country,
      'billing_state' : this.org_form.getRawValue().billing_state,
      'billing_city' : this.org_form.getRawValue().billing_city,
      'billing_pin' : this.org_form.getRawValue().billing_pin,
      'contact_person' : this.org_form.value.contact_person,
      'billing_contact_email' : this.org_form.value.billing_contact_email
    }
      if( this.org_flag == true){
        this.OrganizationService.createorgdata(myFormData)
        .subscribe((res: any) => {
          // this.msg          = "created";
          this.getorgdata();
        }
        )
      this.submitted        = false;

      }else{
        this.OrganizationService.updateorgdata(myFormData,this.orgid)
        .subscribe((res: any) => {
         // this.msg          = "updated";
         this.getorgdata();
        }
        )
      }
      this.showSidePanel  =false;
  }

  // Simplified function to use for validation
  existService(fieldName:any, fieldValue:any){
    if(!this.editflag){
      this.value ={
        "page":"organization",
        "field":fieldName,
        "value": fieldValue
      }
    }else{
       this.value ={
        "id":this.orgid,
        "page":"organization",
        "field":fieldName,
        "value": fieldValue
      }
    }

    const fc = this.org_form.get(fieldName);

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.companyUnit = res.body

      this.loader = false

      if(this.companyUnit.data ==='invalid'){
        fc?.setErrors({
          'exist' : true
        })
      }
    })

  }
  // Keyup for Validation
  keyUp(fieldName :any, fieldValue:any){
    this.subjectKeyUp.next({fieldName, fieldValue})
  }

}
