import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { LivelocationService } from 'src/app/@core/services/livelocation.service';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject ,Observable,interval, ReplaySubject } from 'rxjs';
import { delay, switchMap, takeUntil } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import { MyTeamAttendanceService } from 'src/app/@core/services/myteam-attendance.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

​
@Component({
  selector: 'app-live-location',
  templateUrl: './live-location.component.html',
  styleUrls: ['./live-location.component.scss']
})
export class LiveLocationComponent implements OnInit {
  viewEmployees = false;
  viewDetail = false;
  status = "mapView";
  permissions: any
  page = 1;
  listlength = 0;
  pageSize: any = 20;
  geolocationlist: any = [];
  searchKeyword = "";
  activeToggle: any = "True";
  loader1 = false;
  loader = true;
  nodata = false;
  searchlistdata: any = [];
  searchData = new Subject<any>();
  locsearch: string = this.messageService.searchdisplay('Employee');
  filter = false;
  filterscroll = false;
  filterdata: any = '';
  filterForm = this.formBuilder.group({
    company: '',
    bu: '',
    department: '',
    designation: '',
    grade: '',
    emp_status: '',
    emp_type: '',
    branch: '',
    repomanager: '',
    funmanager: '',
    sortby: ['All'],
  });
  from = 'Map';
  viewdata: any = [];
  gpsdata: any = [];
  loadergps = false;
  viewempmap = false;
  private geoCoder: any;
  google: any;
  show = false;
  @Input() fromfile: boolean = false;
  frompage = 'hr';
  header = 'Live';
  subheader = 'employees';
  checkList: any = []
  listedEmp: any = []
  observableRef:any
  @ViewChild(SearchbarComponent) child:any;
  sLoading = false;
  ​
  constructor(
    public appService: AppService,
    private livelocationService: LivelocationService,
    public messageService: MessageService,
    public formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private MyTeamAttendanceService:MyTeamAttendanceService,
    private authService:AuthService
  ) {
    this.locLiveUpdate();
  }
​
  ngOnInit(): void {
    if(this.authService.isEmployeeId != 0)
      this.getReportingList()
    if (this.fromfile == true) {
      this.frompage = 'team';
      this.header = 'Team';
      this.subheader = 'Track real-time location details of your team members.';
    } else {
      this.frompage = 'hr';
      this.header = 'Live';
      this.subheader = 'Track your employees live location here.';
    }
    this.getPermission();
    this.gpslostCnt();
    this.showDataItems();
    this.searchData.pipe(delay(1000),
      switchMap(() => this.livelocationService.getlocationList('', '', this.searchKeyword, this.filterdata, this.frompage))).subscribe((res: any) => {
        this.listdata(res);
      })
​
    this.livelocationService.liveLocationSocket();

    this.observableRef = interval(60000).pipe(takeUntil(this.destroyed$),
      switchMap(() =>this.livelocationService.getgpsLostCnt(this.frompage))
    ).subscribe((res:any) => {
      this.GPSLostCnt = res?.body?.count;
    });
  }
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  getReportingList(){
    this.MyTeamAttendanceService.empDDList(true, 'user__first_name').subscribe((res: any) => {
      res?.data?.forEach((val:any) => {
        this.checkList.push(val['id'])
      });

    });
  }
  locLiveUpdate() {
    this.livelocationService.loc_item.subscribe(result => {
      let str = result.event;
      str = str.replaceAll("\"", "&quot;")
      str = str.replaceAll("'", "\"");
      let data = JSON.parse(str);
      let iIndex = this.listedEmp.indexOf(data?.employee?.id)

      if ((this.checkList.indexOf(data?.employee?.id) > -1 || this.authService.isEmployeeId ==0) && iIndex==-1 && data?.employee.id != undefined) {
        let value: any = [];
        value['lat'] = parseFloat( data.latitude);
        value['long'] = parseFloat( data.longitude);
        if (data.employee.profile_image == null || data.employee.profile_image == '') {
          value['color_code'] = data.employee.color_code
        }
        value['employee_id'] = data?.employee?.id;
        value['name'] = data.employee.name;
        value['label'] = this.appService.getFirstChar(data.employee.name, 2);
        value['designation'] = data.employee.designation
        value['location'] = data.location?data.location:data.latitude+", "+ data.longitude
        value['current_lat_long'] = data.latitude+", "+ data.longitude
        value['employee_code'] = data.employee.employee_code
        value['current_datetime'] = data.created_at
        value['profile_image'] = data.employee.profile_image
        if (data.employee.profile_image != '' && data.employee.profile_image != null) {
          value['icon'] = {
            url: data.employee.profile_image,
            labelOrigin: { x: 16, y: 48 },
            scaledSize: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 0)
          }
        } else {
          value['icon'] = '/assets/images/helper/map-img/map-' + data.employee.employee_code + '.png'
        }

        value['mark_in_location']=  data.mark_in_location;
        value['mark_in_details']=  data.mark_in_details;
        value['gps_on_or_off']=  data.gps;

        this.listedEmp.push(data?.employee?.id)
        this.geolocationlist.push(value);
        // this.searchlistdata.push({
        //   color_code :  data.employee.color_code,
        //   employee_code :  data.employee.employee_code,
        //   id :  data?.employee?.id,
        //   name :   data.employee.name+ " " + "(" +data.employee.employee_code + ")",
        //   image :  data.employee.profile_image,
        // })
        this.arrangeListData()
      }
      if(iIndex>-1){
        // this.geolocationlist.splice(iIndex,1)
        // this.listedEmp.splice(iIndex,1)
        // this.searchlistdata.splice(iIndex,1)

      }
      if( this.geolocationlist.length>0){
        this.nodata = false;
      } else {
      this.nodata = true;
      }
    })

  }
  arrangeListData(){
    this.geolocationlist.sort(function(a:any,b:any) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
    // this.searchlistdata.sort(function(a:any,b:any) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
  }

​
  getPermission() {
    if (this.fromfile == true) {
      this.permissions = this.appService.getPermissions('/my-team-location');
    } else {
      this.permissions = this.appService.getPermissions('/employee-live-location');
    }
    if (this.permissions == undefined) {
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
​

  showDataItems() {
    this.loader = true;
    this.livelocationService.getlocationList('', '', this.searchKeyword, this.filterdata, this.frompage).subscribe((res: any) => {
      this.listdata(res);
    })  ​
    // this.livelocationService.employeeListData(this.activeToggle, this.filterdata, this.frompage).subscribe((res: any) => {
    //   for (let i = 0; i < res.body.length; i++) {

    //     if (res.body[i]['name'] != null)
    //       res.body[i]['name'] = res.body[i]['name'] + " " + "(" + res.body[i]['employee_code'] + ")";
    //     res.body[i]['image'] = res.body[i]['profile_image'];
    //   }  ​
    //   this.searchlistdata = res.body;
    //   this.sLoading = false;
    //   this.loader = false;
    // });
  }
  listdata(res: any) {
    this.geolocationlist = [];
    this.listlength = res.body.length;​
    if (res.body.length == 0) {
      this.nodata = true;
    } else {
      this.nodata = false;
      }
    for (let i = 0; i < this.listlength; ++i) {
      if (res.body[i]['current_location'] != '') {
    this.mapsAPILoader.load().then(() => {
      let value = res.body[i]['current_lat_long'].split(',');
      value['lat'] = value[0];
      value['long'] = value[1];
      value['lat']  =  value['lat'] .replaceAll("\"", "&quot;")
      value['long']  =  value['long'] .replaceAll("\"", "&quot;").trim()
      res.body[i]['long'] = parseFloat(  value['long'])
      res.body[i]['lat'] = parseFloat(  value['lat'])
      res.body[i]['label'] = this.appService.getFirstChar(res.body[i]['employee_name'], 2);
      res.body[i]['name'] = res.body[i]['employee_name'];
      if(res.body[i]['current_location'] !='None'){
        res.body[i]['location'] = res.body[i]['current_location'];
      }else{
            res.body[i]['location'] = res.body[i]['current_lat_long'];
      }
      if (res.body[i]['profile_image'] != '' && res.body[i]['profile_image'] != null) {
        res.body[i]['icon'] = {
          url: res.body[i]['profile_image'],
          labelOrigin: { x: 16, y: 48 },
          scaledSize: new google.maps.Size(30, 30),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0),
        };
      } else {
            res.body[i]['icon'] = '/assets/images/helper/map-img/map-' + res.body[i]['color_code'] + '.png'
      }
          this.listedEmp.push(parseInt(res.body[i]['employee_id']))
          this.geolocationlist.push(res.body[i]);
    });
  }
    }
    this.loader = false;
    this.sLoading = false;
  }

  styles: any = [{
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#0c0c0c"
      }
    ]
  }]
  clusterStyles: any = [
    {
      textColor: "#FFFFFF",
      url: "/assets/images/helper/map-img/map-cluster1.png",
      height: 50,
      width: 50
    }
  ];
  showInfo(p: any) {
    this.openedWindow = p;
  }
​
  hideInfo(p: any) {
    this.openedWindow = -1;
  }
  openedWindow:any
​  openWindow(id: any) {
    if (this.isInfoWindowOpen(id))
      this.openedWindow = -1;
    else
      this.openedWindow = id; // alternative: push to array of numbers
  }

isInfoWindowOpen(id: any) {
  return this.openedWindow == id; // alternative: check if id is in array
}
  searchresults(data: any) {
    this.loader = true
    if (data == '') {
      this.searchKeyword = ""
      this.page = 1;
      this.geolocationlist  = [];
      this.searchData.next()
    } else {
      this.page = 1;
      for (let k = 0; k < this.geolocationlist.length; k++) {
        if (this.geolocationlist[k]['employee_id'] == data) {
          let temparr: any = this.geolocationlist[k];
          this.geolocationlist = [];
          if (temparr['current_location'] != '') {
          this.geolocationlist.push(temparr);
          }
          this.loader = false;
        }
      }
    }
  }
​
  searchlocation(searchKeyword: any) {
    this.loader = true
    this.sLoading = true;
    this.geolocationlist = [];
    this.searchKeyword = searchKeyword
    if (this.searchKeyword != "") {
      this.page = 1;
      this.searchData.next()
    } else {
      this.page = 1;
    }
  }
​
  filterclick() {
    this.filter = true;
  }
​
  resultfilterfn(data: any) {
    this.filterdata = data;
    this.applyfilterfn();
  }
​
  closefn(daata: any) {
    this.filter = daata;
  }
​
  resetCalled(e: any) {
    this.filter = false;
  }
​
  applyfilterfn() {
    this.loader = true;
    this.nodata = false;
    this.child.clearBar();
    // this.showDataItems();
    this.filter = false;
  }
  origin: any
  destination: any
  viewemployee(id: any) {
    // this.loader1            = true;
    this.livelocationService.getOneemployee(id).subscribe((res: any) => {
      if (res.body?.real_time_data?.length) {
        this.viewdata = res.body;
        let lastkey = res.body?.real_time_data?.length - 1
        this.origin = { lat: res.body?.real_time_data[0]?.latitude, lng: res.body?.real_time_data[0]?.longitude };
        this.destination = { lat: res.body?.real_time_data[lastkey]?.latitude, lng: res.body?.real_time_data[lastkey]?.longitude };
​
        this.viewempmap = true;
      }
      // this.loader1            = false;
    })
  }
​
  gpslostlist() {
    this.loadergps = true;
    this.livelocationService.getgpslocationList(this.frompage).subscribe((res: any) => {
      this.gpsdata = res.body;
      this.loadergps = false;
    })
  }
  GPSLostCnt:any
  gpslostCnt() {
    // this.loadergps = true;
    this.livelocationService.getgpsLostCnt(this.frompage).subscribe((res: any) => {
      this.GPSLostCnt = res?.body?.count;
      // this.loadergps = false;
    })
  }
​
viewEmployeesList:any = []
searchString:any = ''

 clickOnCluster(cluster: any): void {
    this.viewEmployeesList= []

    this.geolocationlist.forEach((item:any, key:any) => {
      var latLng = new google.maps.LatLng(item['lat'],item['long']);
      var marker = new google.maps.Marker({ position: latLng });
      let maxZoomService = new google.maps.MaxZoomService();

      if(cluster.getBounds().contains(marker.getPosition())){
        this.viewEmployeesList.push(item)
        maxZoomService.getMaxZoomAtLatLng(latLng,(result: any) => {if(result?.zoom<=this.map?.zoom){
          this.viewEmployees = true
        }})
      }

    });
  }
  map:any
  onMapReady(map:any) {
    this.map = map;
  }

}
