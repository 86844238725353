import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { LeavePolicyService } from 'src/app/@core/services/leave-policy.service';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-ou-filters',
  templateUrl: './ou-filters.component.html',
  styleUrls: ['./ou-filters.component.scss']
})
export class OuFiltersComponent implements OnInit {

  @Input() ouFilterForm          : any
  @Input() mainForm              : any
  @Input() companyDD             : any
  @Input() buDD                  : any
  @Input() DesignationDD         : any
  @Input() BranchDD              : any
  @Input() DepDD                 : any
  @Input() gradeDD               : any
  @Input() empstatusDD           : any
  @Input() emptypeDD             : any
  @Input() employeeDropdown      : any
  @Input() disabled              : any
  @Input() from                  : any
  @Input() reportingDD           : any
  @Input() type                  : any
  @Input() leaveType             : any
  @Input() leavePolicy           : any
  @Input() validationControls    : any
  @Input() isPolicyRequired      : any
  @Input() isTypeRequired        : any
  @Input() submitted             : any
  @Input() viewDetail            : any

  get f(){
    return this.ouFilterForm.controls
  }

  emptypeDDn = [
    { id: "Contractual", name: "Contractual" },
    { id: "Consultant", name: "Consultant" },
    { id: "Permanent", name: "Permanent" },
    { id: "Trainee", name: "Trainee" },
    { id: "Wages", name: "Wages" },
  ];
  empstatusDDn = [
    { id: 0, name: "Probation" },
    { id: 1, name: "Confirmed" },
    { id: 2, name: "Relieved" },
    { id: 3, name: "Resigned" },
    { id: 4, name: "Settled" },
    { id: 5, name: "Terminated" },
    { id: 6, name: "Retired" },
  ];

  companyD         : any = []
  buD              : any = []
  depDD             : any = []
  desigDD           : any = []
  gradeD           : any = []
  branchDD          : any = []
  empDD             : any = []
  leaveTypeDD       : any = []
  // unPaidDD          : any = []
  leavePolicyDD     : any = []

  placeHolder = this.messageService.selectplaceholddisplay('Reporting Manager')

  constructor(
    private reportService : ReportsService,
    public messageService : MessageService,
    public appservice : AppService,
    public formBuilder : FormBuilder,
    private employeeServ : EmployeeService,
    private roleServ : RoleService,
    private lvPolicy : LeavePolicyService,
    private lvType   : leavetypeService,
    public cd : ChangeDetectorRef,
    private leaveService: LeaveReportService
  ) { }

  setRequired(){
    if(this.validationControls?.length > 0){
      this.validationControls.forEach((item:any)=>{
        this.ouFilterForm.get(item).setValidators([Validators.required])
        this.ouFilterForm.get(item).updateValueAndValidity()
      })
      this.cd.detectChanges()
    }
  }

  ngOnInit(): void {
    this.selectAllForDropdownItems(this.empstatusDDn);
    this.selectAllForDropdownItems(this.emptypeDDn);
    this.setRequired()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewDetail'] && changes['viewDetail'].currentValue) {
        // Dropdowns
        this.getCompanyList();
        this.getBUList();
        this.getDepList();
        this.getDesignationList();
        this.getGradeList();
        this.getBranchList();
        this.getEmployeeList();
        this.getLeavePolicyList();
        this.getUnpaidLeaveList();
        this.getFullBUList()
        if(this.mainForm == 'lop'){
          this.getUnpaidLeaveList();
        }else{
          this.getLeaveTypeList();
        }
      }
  }
  handleApiCall(apiCall: any, responseArray: any[],heading:any) {
    apiCall.pipe(
      catchError(error => {
        return of(null);
      })
    ).subscribe((response: HttpResponse<any>) => {
      if (response && response.status === 200 && response.body?.length > 0) {
        if(heading == 'employee' && Array.isArray(response.body)){
          for (let i = 0; i < response?.body?.length; i++) {
            const item = response.body[i];
            item['name'] = item['first_name'] ?? '';
            if (item['first_name'] != null) {
              item['name'] = item['first_name'] + " ";
            }
            if (item['middle_name'] != null) {
              item['name'] += item['middle_name'] + " ";
            }
            if (item['last_name'] != null) {
              item['name'] += item['last_name'];
            }
            item['name'] += " (" + item['employee_code'] + ")";
          }
          this.employeeDropdown = response?.body;
          let len = this.employeeDropdown.length;
          for (let i = 0; i < len; i++) {
            this.employeeDropdown[i]['fullname'] = "";
            if (this.employeeDropdown[i]['first_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name'] + " ";
            if (this.employeeDropdown[i]['middle_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name'] + " ";
            if (this.employeeDropdown[i]['last_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
          }
        }else{
          responseArray.push(...response.body);
        }
        this.selectAllForDropdownItems(responseArray)
      }
    });
    this.cd.detectChanges()
  }

  clearcommonForm(item:any,index:any){
    if(item == "company"){
      let daata = this.ouFilterForm.value.company;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        company: daata
      });
      this.getBUListFunction();
    }else if(item == "bu"){
      let daata = this.ouFilterForm.value.bu;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        bu: daata
      });
    }else if(item == "department"){
      let daata = this.ouFilterForm.value.department;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        department: daata
      });
    }else if(item == "designation"){
      let daata = this.ouFilterForm.value.designation;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        designation: daata
      });
    }else if(item == "grade"){
      let daata = this.ouFilterForm.value.grade;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        grade: daata
      });
    }else if(item == "branch"){
      let daata = this.ouFilterForm.value.branch;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        branch: daata
      });
    }else if(item == "employee"){
      let daata = this.ouFilterForm.value.employee_list;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        employee_list: daata
      });
    }else if(item == "employment_status"){
      let daata = this.ouFilterForm.value.employment_status;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        employment_status: daata
      });
    }else if(item == "employee_type"){
      let daata = this.ouFilterForm.value.employee_type;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        employee_type: daata
      });
    }else if(item == 'reporting_manager'){
      let daata = this.ouFilterForm.value.reporting_manager;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        reporting_manager: daata
      });
    }else if(item == 'leavePolicy'){
      let daata = this.ouFilterForm.value.leave_policy;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        leave_policy: daata
      });
    }else if(item == 'leaveType'){
      let daata = this.ouFilterForm.value.leave_type;
      daata.splice(index, 1);
      this.ouFilterForm.patchValue({
        leave_type: daata
      });
    }
  }

  // BU
  getBUListFunction(){
    this.ouFilterForm.get('bu')?.setValue('');
    this.buDD = []
    this.getBUList();
  }

  getBUList(){
    let comp = this.ouFilterForm.value.company;
    this.ouFilterForm.get('bu')?.setValue('');

    if(comp != undefined && comp != null && comp != ''){
      this.reportService.getBuList(comp).subscribe((res: any) => {
        this.buDD = res;
        this.selectAllForDropdownItems(this.buDD);
      })
    }
  }

  getFullBUList(){
    this.employeeServ.getBuList().subscribe((res: any) => {
      this.buDD = res;
      this.selectAllForDropdownItems(this.buDD);
    })
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  getCompanyList() {
    this.roleServ.getCompanyList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.companyDD = res.body;
        this.selectAllForDropdownItems(this.companyDD);
      }
    });
  }

   // Department List
   getDepList() {
    this.roleServ.getDepartmentList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.DepDD = res.body;
        this.selectAllForDropdownItems(this.DepDD);
      }
    });
  }

  // Designation List
  getDesignationList() {
    this.roleServ.getDesignationList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.DesignationDD = res.body;
        this.selectAllForDropdownItems(this.DesignationDD);
      }
    });
  }

  // Grade List
  getGradeList() {
    this.roleServ.getGradeList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.gradeDD = res.body;
        this.selectAllForDropdownItems(this.gradeDD);
      }
    });
  }

  // Branch List
  getBranchList() {
    this.reportService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }

  // Employee List
  getEmployeeList() {
    this.leaveService.getEmpList(true)
      .subscribe((res: any) => {

        if (res?.length > 0) {
          for (let i = 0; i < res?.length; i++) {
            res[i]['name'] = res[i]['first_name'];
            if (res[i]['first_name'] != null)
              res[i]['name'] = res[i]['first_name'] + " ";
            if (res[i]['middle_name'] != null)
              res[i]['name'] += res[i]['middle_name'] + " ";
            if (res[i]['last_name'] != null)
              res[i]['name'] += res[i]['last_name'];
            res[i]['name'] += " (" + res[i]['employee_code'] + ")";

          }
          this.employeeDropdown = res;
          let len = this.employeeDropdown.length;
          for (let i = 0; i < len; i++) {
            this.employeeDropdown[i]['fullname'] = "";
            if (this.employeeDropdown[i]['first_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name'] + " ";
            if (this.employeeDropdown[i]['middle_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name'] + " ";
            if (this.employeeDropdown[i]['last_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
          }
          // this.selectAllForDropdownItems(this.employeeDropdown);
        }


      })
  }

  // Leave Type
  getLeaveTypeList() {
    this.lvPolicy.getleavetypeList().subscribe((res: any) => {
      this.leaveType = res
      this.selectAllForDropdownItems(this.leaveType)
    })
  }

  unPaidType: any

  getUnpaidLeaveList() {
    this.lvType.getUnPaidDropDownList().subscribe((res: any) => {
      this.leaveType = res
      this.selectAllForDropdownItems(this.leaveType)
    })
  }

  // Leave Policy
  getLeavePolicyList() {
    this.lvPolicy.getSearchList(true).subscribe((res: any) => {
      this.leavePolicy = res
      this.selectAllForDropdownItems(this.leavePolicy)
    })
  }

}
