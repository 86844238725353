<div class=""  [class]="{'container-fluid':isCancel === false}" [class.p-32]="isCancel === false">
    <div class="row row-16">
        <div class="col-12">
            <div *ngIf="isCancel == false" class="mb-0 fw-500 text-uppercase">DETAILS</div>
        </div>
        <app-view-loader *ngIf ="loader"></app-view-loader>
        <ng-container *ngIf ="!loader">
            <div class="col-12">
                <div class="card card-c2 p-24">
                    <div class="row row-12">

                      <div  *ngIf="isCancel == true" class="col-12">
                        <div class="d-flex">
                            <p><span class="title">REIMBURSEMENT DETAILS</span></p>
                            <span (click)="showTrend = true" class="text-accent2 pointer ms-auto fs-12 fw-500"><i class="bi bi-graph-up me-1"></i>Reimbursement Request Trend</span>
                        </div>
                      </div>

                        <div class="col-12">
                            <div class="card details-card indicater1 indicater-bg2 bg-secondary px-16 py-12">
                              <div class="row" *ngIf="from === 'inbox'  ||(from === 'fnf' && isCancel === false) ">
                                <div class="col-5">
                                  <div class="fw-600 fs-14 text-uppercase">{{billDetails?.reimburse_bill?.reimbursement.paycomponent}}</div>
                                  <div class="fs-10 text-success fw-500 mt-1 text-uppercase" *ngIf="billDetails?.reimburse_bill?.reimbursement?.as_of_date == 'Monthly'">AS OF {{currentMonth}} {{currentYear}}</div>

                                  <div class="fs-10 text-success fw-500 mt-1 text-uppercase" *ngIf="billDetails?.reimburse_bill?.reimbursement?.as_of_date == 'Yearly'">FISCAL YEAR {{currentYear}} - {{(currentYear+1)}}</div>
                                </div>

                                <div class="col">
                                  <div class="detail-title">Eligible</div>
                                  <div class="detail-desc">{{billDetails?.reimburse_bill?.reimbursement?.balance | currency : currency}}</div>
                                </div>
                                <div class="col">
                                    <div class="detail-title">Claimed</div>
                                    <div class="detail-desc">{{billDetails?.reimburse_bill?.reimbursement.claimed | currency : currency}}</div>
                                  </div>
                              </div>
                              <div class="row" *ngIf="from === 'fnf' && isCancel === true">
                                <div class="col-5">
                                  <div class="fw-600 fs-14 text-uppercase">{{billDetails?.reimburse_bill?.paycomponent}}</div>
                                  <div class="fs-10 text-success fw-500 mt-1 text-uppercase" *ngIf="billDetails?.reimburse_bill?.as_of_date == 'Monthly'">AS OF {{currentMonth}} {{currentYear}}</div>

                                  <div class="fs-10 text-success fw-500 mt-1 text-uppercase" *ngIf="billDetails?.reimburse_bill?.as_of_date == 'Yearly'">FISCAL YEAR {{currentYear}} - {{(currentYear+1)}}</div>
                                </div>

                                <div class="col">
                                  <div class="detail-title">Eligible</div>
                                  <div class="detail-desc">{{billDetails?.reimburse_bill?.balance | currency : currency}}</div>
                                </div>
                                <div class="col">
                                    <div class="detail-title">Claimed</div>
                                    <div class="detail-desc">{{billDetails?.reimburse_bill?.claimed | currency : currency}}</div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        <div class="col-12" >
                            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                <div class="row row-16"  >
                                  <ng-container *ngIf="from === 'inbox'  ||(from === 'fnf' && isCancel === false) ">
                                    <div class="col-12"><span class="fw-600 fs-12 text-uppercase">Bill Details </span></div>

                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Claim Date</div>
                                        <div class="detail-desc">{{formatDate(billDetails?.reimburse_bill?.claim_date)}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Bill Date</div>
                                        <div class="detail-desc">{{formatDate(billDetails?.reimburse_bill?.bill_date)}}</div>
                                    </div>
                                    <!-- <div class="col-sm-6 col-12" *ngIf="isCancel === false">
                                        <div class="detail-title">Status</div>
                                        <div class="detail-desc" *ngIf="billDetails.status == 'Pending'">
                                            <span class="badge px-16 mt-1 fs-14 badge-warning
                                            py-1">Pending</span>
                                        </div>
                                        <div class="detail-desc" *ngIf="billDetails.status == 'Approved'">
                                            <span class="badge px-16 mt-1 fs-14 badge-success
                                            py-1">Accepted</span>
                                        </div>
                                        <div class="detail-desc" *ngIf="billDetails.status == 'Rejected'">
                                            <span class="badge px-16 mt-1 fs-14 badge-danger
                                            py-1">Rejected</span>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Bill No</div>
                                        <div class="detail-desc">{{billDetails?.reimburse_bill?.bill_no}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Bill Amount</div>
                                        <div class="detail-desc">{{billDetails?.reimburse_bill?.bill_amount| currency : currency}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Description</div>
                                        <div class="detail-desc">{{billDetails?.reimburse_bill?.description}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title mb-2">Document</div>
                                        <div style="width:12.9375rem;">



                                            <div class="card card-c2  bg-secondary" >
                                                <div class="p-12 bg-secondary d-flex align-item-center">
                                                    <!-- <img src="\assets\images\fileIcon.png" class="me-24 img-fluid" style="width: 2.0625rem; height: 2.625rem;"> -->
                                                    <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>

                                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                                      <span class="tips text-uppercase fs- fw-700 text-trim" >{{this.appService.getDocName(billDetails?.reimburse_bill?.document)}}</span>
                                                      <span class="tips-helper ">{{this.appService.getDocName(billDetails?.reimburse_bill?.document)}}</span>

                                                      <div class="d-flex gap-8">
                                                        <div class="link-primary" (click)="viewDocument(billDetails?.reimburse_bill?.document)">
                                                          <i class="bi bi-eye me-1"></i>View
                                                        </div>
                                                        <div class="link-primary" (click)="this.appService.downloadFile(billDetails?.reimburse_bill?.document,this.appService.getDocName(billDetails?.reimburse_bill?.document))">
                                                          <i class="bi bi-download me-1"></i>Download
                                                        </div>
                                                      </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                  </ng-container>

                                    <ng-container *ngIf="(from === 'inbox'  ||(from === 'fnf' && isCancel === true)) && (billDetails?.reimburse_bill?.status == 'Cancelled' && billDetails?.status != 'Approved')">
                                      <div class="col-12">
                                        <div class="detail-title">Reason for cancellation</div>
                                        <div class="detail-desc">{{(billDetails?.reimburse_bill?.remark != undefined && billDetails?.reimburse_bill?.remark != null) ? billDetails?.reimburse_bill?.remark : billDetails?.remark}}</div>
                                      </div>
                                    </ng-container>

                                </div>
                                <div class="row row-16"  *ngIf="from === 'fnf' && isCancel === true">
                                    <div class="col-12"><span class="title">Reimbursement </span></div>


                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Claim Date</div>
                                        <div class="detail-desc">{{formatDate(billDetails?.reimburse_bill?.reimbursement[0]?.claim_date)}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Bill Date</div>
                                        <div class="detail-desc">{{formatDate(billDetails?.reimburse_bill?.reimbursement[0]?.bill_date)}}</div>
                                    </div>
                                   
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Bill No</div>
                                        <div class="detail-desc">{{billDetails?.reimburse_bill?.reimbursement[0]?.bill_no}}</div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="detail-title">Bill Amount</div>
                                        <div class="detail-desc">{{billDetails?.reimburse_bill?.reimbursement[0]?.bill_amount| currency : currency}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Description</div>
                                        <div class="detail-desc">{{billDetails?.reimburse_bill?.reimbursement[0]?.description}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title mb-2">Document</div>
                                        <div style="width:12.9375rem;">



                                            <div class="card card-c2  bg-secondary" >
                                                <div class="p-12 bg-secondary d-flex align-item-center">
                                                    <!-- <img src="\assets\images\fileIcon.png" class="me-24 img-fluid" style="width: 2.0625rem; height: 2.625rem;"> -->
                                                    <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>

                                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                                      <span class="tips text-uppercase fs- fw-700 text-trim" >{{this.appService.getDocName(billDetails?.reimburse_bill?.reimbursement[0]?.document)}}</span>
                                                      <span class="tips-helper ">{{this.appService.getDocName(billDetails?.reimburse_bill?.reimbursement[0]?.document)}}</span>

                                                      <div class="d-flex gap-8">
                                                        <div class="link-primary" (click)="viewDocument(billDetails?.reimburse_bill?.reimbursement[0]?.document)">
                                                          <i class="bi bi-eye me-1"></i>View
                                                        </div>
                                                        <div class="link-primary" (click)="this.appService.downloadFile(billDetails?.reimburse_bill?.reimbursement[0]?.document,this.appService.getDocName(billDetails?.reimburse_bill?.reimbursement[0]?.document))">
                                                          <i class="bi bi-download me-1"></i>Download
                                                        </div>
                                                      </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>


    </div>
</div>

<!-- un supported view format alert -->
<ng-container *ngIf="alertToggle==true">
  <app-alert-toggle (modals)="closed($event)" [alertToggle]="alertToggle" [alertMsg]="alertMsg"></app-alert-toggle>
</ng-container>
  <!-- Doc Preview Start -->
 <!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<!-- Reimbursement Trend Graph -->
<div *ngIf="showTrend" class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showTrend === true'>
  <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Reimbursement Analysis</h5>
          <a class="toggle-panel" (click)="showTrend = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <app-reimbursement-analysis [empId]="empId"></app-reimbursement-analysis>
      </div>
  </form>
</div>
<!-- End -->
