import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeLetterHrService } from 'src/app/@core/services/employee-letter-hr.service';
import { FormBuilder,  Validators } from '@angular/forms';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { debounceTime,  delay,switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { spaceValidator } from 'src/app/@shared/validators/space.validators';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-employee-letters',
  templateUrl: './employee-letters.component.html',
  styleUrls: ['./employee-letters.component.scss']
})
export class EmployeeLettersComponent implements OnInit {
  generateMiscLetter = false;
  viewDetails = false;
  regenerate = false;
  permissions:any=[];
  changeletter=false;
  changeletterkey:any='';
  offset                   = 0;
  listlength               = 0;
  direction                = '';
  defaultlimit:any         = 20;
  loader =false;
  searchlistdata:any=[];
  infinityloader           = false;
  nodata                   = false;
  empDetailsList   :any   = [];
  emplist:any      = [];
  searchKeyword            = "";
  searchplaceholder:string       = this.messageService.searchdisplay('Employee');
  Viewdata:any
  activeToggle:any         = '';
  unpublishtoggle =false;
  letterid:any;
  submitted                : any;

  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;
  disabled                        = false;

  filter             = false;
  filterdata:any;
  filterForm                = this.formBuilder.group({
    company               : '',
    bu                    : '',
    department            : '',
    designation           : '',
    grade                 : '',
    emp_status            : '',
    emp_type              : '',
    branch                : '',
    repomanager           :'',
    funmanager            :'',
    sortby                : ['All'],
  }
);
from = 'Employehrletter';
loader1 = false;
statusList:any=[];
miscletterform              = this.formBuilder.group({
  is_specific_employee: [true, [Validators.required]],
  employee: [''],
  company: [''],
  businessunit: [''],
  department: [''],
  grade: [''],
  designation: [''],
  branch: [''],
  letter_template: [null, [Validators.required]],
  remarks: ['', [Validators.required,Validators.maxLength(500),spaceValidator()]],
})

companyDD :any =  [{
  id            : 0,
  company_name  : ''
}];

buDD = [{
  id          : 0,
  name        : ''
}];

DepDD = [{
  id          : 0,
  name        : ''
}];

DesignationDD = [{
  id          : 0,
  name        : ''
}];

BranchDD = [{
  id          : 0,
  name        : ''
}];

gradeDD = [{
  id           : 0,
  name         : ''
}];
employeeData :any   = [];
templatelist:any=[];
templatedata:any=[];
title:any ='View and download the pending and generated letters in this section.';
searchData : Subject<void> = new Subject();
  empId = 0;
sLoading=false;

resetcall = false;
@ViewChild(SearchbarComponent) child:any;

  constructor(
    private EmployeeLetterHrService : EmployeeLetterHrService,
    public formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public messageService : MessageService,
    private employeeService : EmployeeService,
    private rs              : RoleService,
    private notificationService              : NotificationService,
  ) { }

  ngOnInit(): void {
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.getPermission();
    this.showDataItems();
    this.setRequired('company','employee');
    this.searchData.pipe(delay(1000),
      switchMap(() => this.EmployeeLetterHrService.empletterList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword,this.filterdata,this.empId)
    )).subscribe((res: any) => {
      this.listlength = res?.body?.results?.length;
      if(this.offset == 0){
        this.emplist = [];
      }

      if (res?.body?.results?.length == 0) {
        this.nodata = this.offset == 0 ? true :false;
      }else {
        this.nodata = false;
        this.empDetailsList = res?.body?.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
    });
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/employee-letters-hr');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  showdatacommonfn(res:any){
    this.listlength = res?.body?.results?.length;
      if(this.offset == 0){
        this.emplist = [];
        this.empDetailsList=[];
      }

      if (res?.body?.results?.length == 0) {
        this.nodata = this.offset == 0 ? true :false;
      }else {
        this.nodata = false;
        this.empDetailsList = res?.body?.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      // this.loader = false;
  }

  showDataItems(){
    this.loader = true;
    this.EmployeeLetterHrService.empletterList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword,this.filterdata,this.empId).subscribe((res: any)=> {
      this.showdatacommonfn(res);
    })

    // this.EmployeeLetterHrService.empListData(this.activeToggle,this.filterdata).subscribe((res: any) => {
    //   for(let i=0;i<res.body.length;i++){
    //     res.body[i]['name']  = "";
    //     if(res.body[i]['first_name'] != null)
    //       res.body[i]['name'] += res.body[i]['first_name']+" ";
    //     if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
    //       res.body[i]['name'] += res.body[i]['middle_name']+" ";
    //     if(res.body[i]['last_name'] != null)
    //       res.body[i]['name'] += res.body[i]['last_name'];
    //       res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";

    //       res.body[i]['image'] = res.body[i]['profile_image'];
    //   }
    //   this.searchlistdata =  res?.body;
      // this.loader = false;
    // });

    this.EmployeeLetterHrService.lettercount(this.filterdata).subscribe((res: any) => {
      this.statusList=res.body;
    });

  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.emplist.push(this.empDetailsList[i]);
    }
    this.loader = false;
    this.sLoading=false;
  }

  searchresults(event:any){
    this.loader  = true;
    this.emplist = [];
    this.empId   = 0
    if(event == ""){
      this.searchKeyword  = "";
      this.offset     = 0;
      this.searchData.next()
    }else{
      this.empId = event
      this.searchData.next()
    }
  }

  searchemp(searchKeyword:any){
    this.sLoading= true;
    this.loader  = true;
    this.offset  = 0;
    this.emplist = [];
    this.empId   = 0
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }


  viewletter(id:any){
    console.log("reger",this.regenerate)
    if(this.permissions.v){
      this.loader1     = true;
      this.EmployeeLetterHrService.getOneemp(id).subscribe((res: any) => {
        this.Viewdata   = res.body;
        if(!this.regenerate){
          this.loader1            = false;
        }
      })
    }
  }

  viewDocument(pdf_url:any,exactFile:any){
    this.modalToggle            = true;
    this.fileName               = pdf_url;
    this.currentDocName         = this.removeUnderScore(exactFile)
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  // Remove Underscore
  removeUnderScore(val:any){
    if(val?.includes('_')){
      return val?.split('_').join(' ')
    }else{
      return val
    }
  }

  close(bool:any){
    this.modalToggle = bool;
  }

  closegen(bool:any){
    this.regenerate = bool;
  }

  selectItemList(value:any){
    // this.filterdata = '';
    if(value=='Pending'){
      this.title='Pending Letters can be generated on behalf of the task owners in this section.';
    }else{
      this.title='View and download the pending and generated letters in this section.';
    }
      this.loader           = true;
      this.activeToggle     = value;
      this.offset           = 0;
      this.emplist          = [];
      this.empDetailsList   = [];
      this.showDataItems();

    this.child.clearBar();
  }

  filterclick(){
    this.filter = true;
   }

   resultfilterfn(data:any){
    if(this.resetcall == true){
      this.filterdata = '';
    }else{
      this.filterdata = data;
    }
        this.offset = 0;
    this.child.clearBar();
    this.applyfilterfn();
  }

  closefn(daata:any){
    this.filter = daata;
   }

   applyfilterfn(){
    this.loader = true;
    this.nodata       = false;
    this.showDataItems();
    this.filter = false;
    this.resetcall = false;
   }

   resetCalledfn(ev:any){
    if(ev == true){
      this.resetcall = ev;
    }
   }

   onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  addheadercontent(event:any){
    this.generateMiscLetter = true;
    this.disabled = false;
  }

  onSubmit() {
    this.submitted = true;
    this.searchKeyword = '';
    if (this.miscletterform.invalid ) {
          return;
    }
    this.disabled = true;
    let miscdata = {
      "name" : this.miscletterform.value.name,
      "is_specific_employee"   :this.miscletterform.value.is_specific_employee,
      "letter_template"   :this.miscletterform.value.letter_template,
      "employee"     : this.miscletterform.value.employee?this.miscletterform.value.employee:[],
      "company"     : this.miscletterform.value.company?this.miscletterform.value.company:[],
      "businessunit"     : this.miscletterform.value.businessunit?this.miscletterform.value.businessunit:[],
      "department"     : this.miscletterform.value.department?this.miscletterform.value.department:[],
      "designation"     : this.miscletterform.value.designation?this.miscletterform.value.designation:[],
      "branch": this.miscletterform.value.branch?this.miscletterform.value.branch:[],
      "grade": this.miscletterform.value.grade?this.miscletterform.value.grade:[],
      "remarks": this.miscletterform.value.remarks
    };
      this.EmployeeLetterHrService.createemp(miscdata).subscribe((res: any) => {
        this.notificationService.handleSuccessNotification("Letter generated successfully.","Success");
        this.offset = 0;
        this.emplist = [];
        this.showDataItems()
        this.generateMiscLetter    = false;
        this.forreset();
      },(err: any) => {
        this.disabled = false;
      });
      this.submitted        = false;
      this.child.clearBar();
  }

  regeneratefn(event:any){
      this.offset = 0;
      this.emplist = [];
      this.showDataItems();
      this.regenerate    = false;
  }

  unpublish(id:any){
    this.EmployeeLetterHrService.empunpublish(id,{'status' : 'Unpublished'},'hr').subscribe((res: any) => {
      this.offset = 0;
      this.emplist = [];
      this.showDataItems()
      this.unpublishtoggle    = false;
      this.miscletterform.reset();
    });
  }

  forreset(){
    this.miscletterform.reset();
    this.setRequired('company','employee');
    this.miscletterform.get('is_specific_employee')?.setValue(true)
  }

  changeApplicable(){
    this.miscletterform.get('company')?.reset([])
    this.miscletterform.get('businessunit')?.reset([])
    this.miscletterform.get('department')?.reset([])
    this.miscletterform.get('designation')?.reset([])
    this.miscletterform.get('branch')?.reset([])
    this.miscletterform.get('grade')?.reset([])
    this.miscletterform.get('employee')?.reset([])
    this.miscletterform.get('remarks')?.reset()
    this.miscletterform.get('letter_template')?.reset();
  }

  get f(){ return this.miscletterform.controls }

  getCompanyList(){
    this.employeeService.getCompanyList().subscribe((res: any) => {
      this.companyDD = res;
      this.selectAllForDropdownItems(this.companyDD);
    });
  }
  getbulist(data:any){
    this.buDD = []
    this.rs.getBuList(data).subscribe((res: any) => {
      this.buDD = res.body;
      this.selectAllForDropdownItems(this.buDD);
    });
    this.miscletterform.get('bu')?.patchValue(this.buDD)
    if(data.length == 0){
      this.miscletterform.patchValue({
        businessunit:[]
      });
    }
  }

  getDepList(){
    this.employeeService.getDepartmentList().subscribe((res: any) => {
          this.DepDD = res;
          this.selectAllForDropdownItems(this.DepDD);
    });
  }

  getDesignationList(){
    this.employeeService.getDesignationList().subscribe((res: any) => {
          this.DesignationDD = res;
          this.selectAllForDropdownItems(this.DesignationDD);
    });
  }

  getGradeList(){
    this.employeeService.getGradeList().subscribe((res: any) => {
          this.gradeDD = res;
          this.selectAllForDropdownItems(this.gradeDD);
    });
  }

  getBranchList(){
    this.employeeService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }

  getlettertemplatelist(){
    this.EmployeeLetterHrService.lettertemplatelist('hr','').subscribe((res: any) => {
      this.templatelist = res.body;
    });
  }

  getlettertemplatdetail(){
    this.EmployeeLetterHrService.letterpreview(this.miscletterform.value?.letter_template).subscribe((res: any) => {
      this.templatedata = res.body;
    });
  }

  getEmployeeList(){
    this.EmployeeLetterHrService.getempList(true).subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          for(let i=0;i<res.body.length;i++){
            res.body[i]['name']  = "";
            if(res.body[i]['first_name'] != null)
              res.body[i]['name'] += res.body[i]['first_name']+" ";
            if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
              res.body[i]['name'] += res.body[i]['middle_name']+" ";
            if(res.body[i]['last_name'] != null)
              res.body[i]['name'] += res.body[i]['last_name'];
              res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";
          }
          this.employeeData = res.body;
          this.selectAllForDropdownItems(this.employeeData);

        }
      }
    });
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  clearcommonForm(item:any,index:number){
    if(item == "company"){
      let daata = this.miscletterform.value.company;

      daata.splice(index, 1);
      this.miscletterform.patchValue({
        company: daata,
        businessunit:[]

      });
      this.getbulist(daata)
     } else if(item == "businessunit"){
      let daata = this.miscletterform.value.businessunit;
      daata.splice(index, 1);
      this.miscletterform.patchValue({
        businessunit: daata
      });
    } else if(item == "department"){
      let daata = this.miscletterform.value.department;
      daata.splice(index, 1);
      this.miscletterform.patchValue({
        department: daata
      });
    } else if(item == "designation"){
      let daata = this.miscletterform.value.designation;
      daata.splice(index, 1);
      this.miscletterform.patchValue({
        designation: daata
      });
    } else if(item == "grade"){
      let daata = this.miscletterform.value.grade;
      daata.splice(index, 1);
      this.miscletterform.patchValue({
        grade: daata
      });
    } else if(item == "branch"){
      let daata = this.miscletterform.value.branch;
      daata.splice(index, 1);
      this.miscletterform.patchValue({
        branch: daata
      });
    }
    else if(item == "employee"){
      let daata = this.miscletterform.value.employee;
      daata.splice(index, 1);
      this.miscletterform.patchValue({
        employee: daata
      });
    }
  }

  setRequired(key1:any,req:any){
    this.miscletterform.get(req)?.setValidators([Validators.required])
    this.miscletterform.get(key1)?.clearValidators();
    this.miscletterform.get(req)?.updateValueAndValidity();
    this.miscletterform.get(key1)?.updateValueAndValidity();
    this.miscletterform.get(key1)?.reset('');
  }

  // Download File
  downLoad(val:any){
    let url = this.appService.getDocName(val).split('.')[0]
    this.appService.downloadFile(val,url)
  }

  addmiscletter(){
    this.getCompanyList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    this.getEmployeeList();
    this.getlettertemplatelist();
    this.generateMiscLetter = true;
    this.disabled = false;
  }

}
