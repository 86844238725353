<form class="" [formGroup]="formGp">
  <div class="container-fluid p-32 form-container">
    <div class="row mb-16">
      <div class="col-md-4 col-lg-6">
        <p class="text-uppercase fw-500 mb-0">REPORT DETAILS</p>
      </div>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex justify-content-end  gap-16">
          <div style="width:22.6875rem;">
            <app-searchbar [searchlist]='reportSearchData' [searchplaceholder]="searchkey" (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchemp($event)"></app-searchbar>
          </div>
          <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="newReport=true"><i class="icon-plus"></i>Add
            New Report</button>
        </div>
      </div>
    </div>
    <div class="row row-16">

      <div class="row row-16rem" *ngIf="reportListLoader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </div>

      <ng-container *ngIf="reportListData?.length > 0 && !reportListLoader;">
      <div class="col-md-4" *ngFor="let report of reportListData;let i=index;">
        <label class="radio-group" for="btnradio{{i}}">
          <input type="radio" class="radio-check" name="btnradio" id="btnradio{{i}}" autocomplete="off" (change)="setReport(report,i)" [checked]="f.id.value==report?.id?true:false">
          <div class="radio-content card  details-card indicater1">
            <div class="card-body pb-8">
              <h6 class="fs-14 fw-600 text-uppercase">{{report?.name}}</h6>
              <div class="d-flex align-items-center">
                <span class="text-success fs-10 fw-500 text-uppercase">{{this.appService.dateFormatDisplay(report?.from_date)}} - {{this.appService.dateFormatDisplay(report?.to_date)}}</span>
                <span class="badge {{report?.status == 'Draft' ? 'badge-secondary' : 'badge-danger'}}  ms-auto py-1 px-3">{{report?.status}}</span>
              </div>
            </div>
            <div class="card-body pt-8">
              <div class="row row-16 ">
                <div class="col-6">
                  <div class="detail-title">Total</div>
                  <div class="detail-desc">{{(report?.total_amount == null || report?.total_amount == '') ? '-' : report?.total_amount | currency:currency}} </div>
                </div>
                <div class="col-6">
                  <div class="detail-title">To be reimbursed</div>
                  <div class="detail-desc">{{(report?.to_reimbursed == null || report?.to_reimbursed == '') ? '-' : report?.to_reimbursed | currency:currency}} </div>
                </div>
              </div>
            </div>
          </div>
        </label>
      </div>
      </ng-container>

      <div *ngIf="reportListData?.length == 0 && !reportListLoader;" class="row pt-4">
        <app-nodata-view [noDataText]="'No Data'" [hasPermission]="false"> </app-nodata-view>
      </div>
    </div>
  </div>
   <!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="formGp.invalid" (click)="changeTab()">NEXT</button>
  </footer>
</form>

<!-- Add new report -->
<app-add-new-report *ngIf="newReport" [newReport]="newReport" (submitreport)="submitreport()" (closereport)="closereport()"></app-add-new-report>
<!-- End -->
