<form class="side-panel-container" [formGroup]="leaveConfigForm" autocomplete="off">
    <ng-container formArrayName="creditdetailsform" *ngFor="let config of creditdetailsForm.controls; let i = index">
        <ng-container [formGroupName]="i">
            <ng-container *ngIf="leaveTypeCode==creditdetailsForm.controls[i].get('leave_code')?.value">
                <div class="card card-c2 border-0">
                    <div class="card-body py-24 px-16">
                        <div class="row row-16">
                            <div class="col-12 ">
                                <p class="text-uppercase mb-0 fw-500">LEAVE CREDIT DETAILS</p>
                            </div>
                            <div class="col-12 ">
                                <div class="form-row">
                                    <label for=" " class="form-label required ">How do you want to grant this leave? </label>
                                    <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If “Auto” option is selected, the system will automatically grant the leave balance. If Manual is selected, HR should grant the leave manually." popoverClass="popover-default popover-info"  triggers="hover"></i></span>

                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                    formControlName="grant_leave" [value]=false (ngModelChange)="checkCreditRuleApplicable(false)">
                                    <label class="form-check-label">
                                        Auto
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                    formControlName="grant_leave" [value]=true (ngModelChange)="checkCreditRuleApplicable(true)">
                                    <label class="form-check-label">
                                        Manual
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="creditdetailsForm.controls[i].get('grant_leave')?.value == false;else manualsection">
                      <div class="card-body d-flex py-0 px-16 align-items-center">
                        <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab3" class="nav nav-custom shadow-0 p-0">

                            <ng-container *ngFor="let role of leavemapping_creditrule().controls; let i=index;">
                            <li [ngbNavItem]="i" class="success hstack position-relative pe-16">
                                <a ngbNavLink class="nav-link {{i==0?'pe-36':''}}">Credit Rule {{i+1}} </a>
                                <button *ngIf="i!=0" class="btn-reset end-0 position-absolute" (click)="removeCreditRule(i)"><i class="bi fs-18 ms-8 bi-x-circle-fill"></i></button>
                                <ng-template ngbNavContent>
                                    Credit Rule {{i+1}}
                                </ng-template>
                            </li>
                            </ng-container>

                        </nav>
                        <span class="ms-auto link-primary1 fs-14" (click)="addcreditRule()">
                          <i class="icon-plus-square-fill fs-16 me-8"></i>Add Rule  
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                            ngbPopover="If there are multiple credit rules to be defined for probation/confirmed, you can click on the “Add rule” option and define multiple credit rules."
                            popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                        </span>
                      </div>

                      <div class="card-body  px-16 pt-16 py-32">
                          <div class="row row-16">
                              <ng-container  formArrayName="leavemapping_creditrule">
                                  <ng-container *ngFor="let role of leavemapping_creditrule().controls; let i=index;"[formGroupName]="i">
                                    <ng-container *ngIf="i==activeTab3">
                                      <div class="col-6">
                                          <label for="" class="form-label required">Effective from </label>
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select the option to define from when leave granting rule is effective. You can enter the number and also select (days/month/year) from the dropdown as per your requirement. If you want to make this rule effective from employee’s Date of joining(DOJ) then select the option DOJ or else if you want to make this rule effective from employee’s Date of Confirmation(DOC) then select the option DOC." popoverClass="popover-default popover-info"  triggers="hover"></i></span>

                                          <div class="input-group1 ms-auto " [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('effective_from')?.touched || leavemapping_creditrule().controls[i].get('effective_from')?.dirty) &&  leavemapping_creditrule().controls[i].get('effective_from')?.errors}">
                                              <input style="width:70%" class="form-control" formControlName="effective_from" placeholder="Enter Value" [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('effective_from')?.touched || leavemapping_creditrule().controls[i].get('effective_from')?.dirty) &&  leavemapping_creditrule().controls[i].get('effective_from')?.errors}">
                                              <span class="divider"></span>
                                              <ng-select style="width:30%" class="form-ngselect" placeholder="Days"  formControlName="effective_type">
                                                <ng-option *ngFor="let effectiveValues of effectiveValues" [value]="effectiveValues">{{effectiveValues}}
                                                </ng-option>
                                              </ng-select>
                                          </div>
                                          <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('effective_from')?.touched || leavemapping_creditrule().controls[i].get('effective_from')?.dirty) && leavemapping_creditrule().controls[i].get('effective_from')?.errors" class="invalid-feedback">
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('effective_from')?.errors?.required"> {{this.messageService.validationDisplay('required')}}</div>
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('effective_from')?.errors?.pattern"> {{this.messageService.validationDisplay('pattern')}}</div>
                                          </div>
                                      </div>

                                      <div class="col-6">
                                          <label for="" class="form-label required">From DOJ/DOC </label>
                                          <ng-select class="form-ngselect" placeholder="Select DOJ/DOC" formControlName="from_doj_doc" [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('from_doj_doc')?.touched || leavemapping_creditrule().controls[i].get('from_doj_doc')?.dirty) &&  leavemapping_creditrule().controls[i].get('from_doj_doc')?.errors}">
                                              <ng-option *ngFor="let from_DOJ_DOC of from_DOJ_DOC" [value]="from_DOJ_DOC.value">{{from_DOJ_DOC.name}}
                                              </ng-option>
                                          </ng-select>
                                          <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('from_doj_doc')?.touched || leavemapping_creditrule().controls[i].get('from_doj_doc')?.dirty) && leavemapping_creditrule().controls[i].get('from_doj_doc')?.errors" class="invalid-feedback">
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('from_doj_doc')?.errors?.required"> {{this.messageService.validationDisplay('required')}}</div>

                                          </div>
                                      </div>

                                      <div class="col-6">
                                          <label for="name" class="form-label required">When do you want this leave to be credited
                                          </label>
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select the accrual period (Monthly/Yearly/Half yearly/ Quarterly) from the drop-down. Specified number of leave will be credited to employee leave balance on every 1st of accrual cycle." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          <ng-select (change)="changemonthRule(i)" type="text" class="form-ngselect" placeholder="Select period" formControlName="do_want_to_credit_leave" [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.touched || leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.dirty) &&  leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.errors}">
                                              <ng-option *ngFor="let creditedLeaveValue of creditedLeaveValues" [value]="creditedLeaveValue">{{creditedLeaveValue}}
                                              </ng-option>
                                          </ng-select>
                                        <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.touched || leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.dirty) && leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.errors?.required">
                                                {{this.messageService.validationDisplay('required')}}</div>

                                        </div>
                                      </div>

                                      <div class="col-6">
                                          <label for="name" class="form-label required">Date of accrual </label>
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Date on which the leave should be credited to the employees. Choose from 1st to 28th or last day of the month. Specified leaves will be credited to the employee leave balance on this date." popoverClass="popover-default popover-info"  triggers="hover"></i></span>


                                          <app-master-select class="config-app-master" [placeholder]="'Select day'" id="from_date"
                                                            [isrequired]="'true'"  formControlName="which_dayof_month" [type]="'schedule_dates'" [range]="28" [lastDay]="true" [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('which_dayof_month')?.touched || leavemapping_creditrule().controls[i].get('which_dayof_month')?.dirty) &&  leavemapping_creditrule().controls[i].get('which_dayof_month')?.errors}"></app-master-select>

                                          <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('which_dayof_month')?.touched || leavemapping_creditrule().controls[i].get('which_dayof_month')?.dirty) && leavemapping_creditrule().controls[i].get('which_dayof_month')?.errors"
                                          class="invalid-feedback">
                                          <div *ngIf="leavemapping_creditrule().controls[i].get('which_dayof_month')?.errors?.required">
                                              {{this.messageService.validationDisplay('required')}}</div>


                                          </div>
                                      </div>

                                      <div class="col-6">
                                        <label for="name" class="form-label required">Leave credit count </label>
                                        <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enter how many leave days should be credited." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                        <input (ngModelChange)="updateValidity(i)" type="text" class="form-control "  formControlName="no_of_leaves_credited" placeholder="Enter Days"  [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.touched || leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.dirty) &&  leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.errors}">
                                        <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.touched || leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.dirty) && leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.hasError('min') && !(leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.errors?.pattern)">
                                              <!-- {{this.messageService.fieldlengthvalidation('minvalue',0.1)}} -->
                                              {{this.messageService.validationDisplay('pattern')}}
                                                </div>
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('no_of_leaves_credited')?.errors?.pattern">
                                              {{this.messageService.validationDisplay('pattern')}}</div>

                                        </div>
                                      </div>



                                      <div class="col-12">
                                          <label for="name" class="form-label required">How do you want to credit leave if employee joins middle of the accrual cycle? </label>
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  [ngbPopover]="popContent" popoverClass="popover-default popover-info"  triggers="hover"></i>
                                            <ng-template #popContent>
                                                Select the appropriate option from below:
                                                <ul><li>Prorate Based on Joining Date: Leaves are adjusted based on the joining date in the month. For example, if an employee is entitled to 2 leaves per month and joins on the 15th, they will receive 1 leave for that month.</li>
                                                <li>Do not pro-rate: Full leave entitled for the month will be credited even if the employee joins in the middle of the month.</li></ul></ng-template></span>
                                              <ng-select type="text" class="form-ngselect" placeholder="Select options"
                                              formControlName="leave_credit_timeofjoining" 
                                              [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.touched || leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.dirty) &&  leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.errors}">
                                              <ng-option *ngFor="let accuralLeaveValues of accuralLeaveValues" [value]="accuralLeaveValues">{{accuralLeaveValues}}
                                                  </ng-option>
                                            </ng-select>
                                          <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.touched || leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.dirty) && leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.errors"
                                          class="invalid-feedback">
                                          <div *ngIf="leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.errors?.required">
                                              {{this.messageService.validationDisplay('required')}}</div>
                                          </div>
                                      </div>

                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.value == 'Prorate based on credit rule start date' && leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.value == 'Monthly'">
                                        <div class="col-lg-12 ">
                                            <div class="form-row">
                                                <label for=" " class="form-label ">Do you want to apply first month rule? </label>
                                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="This rule is used to credit leave balance for new joiners in their joining month based on the date of joining. Enter the From date & To date, and the Number of leaves to be credited. This rule is also considered when policy change happens." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio"  formControlName="first_month_rule" [value]=true (click)="addFstMonthRule(i,true)">
                                                <label class="form-check-label">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" formControlName="first_month_rule" [value]=false (click)="addFstMonthRule(i,false)">
                                                <label class="form-check-label">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                      </ng-container>
                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('first_month_rule')?.value && leavemapping_creditrule().controls[i].get('leave_credit_timeofjoining')?.value == 'Prorate based on credit rule start date' && leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.value == 'Monthly'">
                                          <ng-container  formArrayName="leavecreditrule_firstmonth">
                                            <div class="col-12 fs-14 fw-500 text-uppercase">
                                                FIRST MONTH RULE
                                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="First-month rule will be available when user selects When do you want this leave to be credited as Monthly and How do you want to credit leave if an employee joins the middle of the accrual cycle? as Start of policy." popoverClass="popover-default popover-info" triggers="hover"></i></span>
                                            </div>
                                              <ng-container *ngFor="let role of firstmonth(i).controls; let first=index;" [formGroupName]="first">

                                                <div class="col-4">
                                                    <label for="" class="form-label required">From date </label>
                                                </div>
                                                <div class="col-4">
                                                    <label for="" class="form-label required">To date </label>
                                                </div>
                                                <div class="col">
                                                    <label for="" class="form-label required">Count</label>
                                                </div>
                                                <div style="width:2.5rem" *ngIf="first!=0">&nbsp;</div>

                                                <div class="col-12" >
                                                    <div class="align-items-start d-flex flex-nowrap row row-16">
                                                      <div class="col-4 form-row mt-0">
                                                        <app-master-select (ngModelChange)="validateFstRule(i,first)" class="config-app-master" [placeholder]="'Select day'" id="from_date"
                                                            [isrequired]="'true'"  formControlName="from_date" [type]="'schedule_dates'" [range]="28" [lastDay]="false" [ngClass]="{ 'is-invalid': (saveBtnClicked || firstmonth(i).controls[first].get('from_date')?.touched || firstmonth(i).controls[first].get('from_date')?.dirty) &&  firstmonth(i).controls[first].get('from_date')?.errors}"></app-master-select>
                                                            <div *ngIf="(saveBtnClicked || firstmonth(i).controls[first].get('from_date')?.touched || firstmonth(i).controls[first].get('from_date')?.dirty) && firstmonth(i).controls[first].get('from_date')?.errors" class="invalid-feedback">
                                                              <div *ngIf="firstmonth(i).controls[first].get('from_date')?.errors?.required">
                                                                  {{this.messageService.validationDisplay('required')}}
                                                              </div>
                                                              <div *ngIf="firstmonth(i).controls[first].get('from_date')?.errors?.min">
                                                                {{this.messageService.validationDisplay('pattern')}}</div>
                                                            </div>
                                                      </div>
                                                        <div class="col-4 form-row mt-0">
                                                            <app-master-select (ngModelChange)="validateFstRule(i,first)"  class="config-app-master" [placeholder]="'Select day'" id="to_date" [isrequired]="'true'"  formControlName="to_date" [type]="'schedule_dates'" [range]="28" [lastDay]="true" [ngClass]="{ 'is-invalid': (saveBtnClicked || firstmonth(i).controls[first].get('to_date')?.touched || firstmonth(i).controls[first].get('to_date')?.dirty) &&  firstmonth(i).controls[first].get('to_date')?.errors}"></app-master-select>
                                                            <div *ngIf="(saveBtnClicked || firstmonth(i).controls[first].get('to_date')?.touched || firstmonth(i).controls[first].get('to_date')?.dirty) && firstmonth(i).controls[first].get('to_date')?.errors" class="invalid-feedback">
                                                              <div *ngIf="firstmonth(i).controls[first].get('to_date')?.errors?.required">
                                                                    {{this.messageService.validationDisplay('required')}}
                                                              </div>
                                                              <div *ngIf="firstmonth(i).controls[first].get('to_date')?.errors?.min">
                                                                {{this.messageService.validationDisplay('pattern')}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col form-row mt-0">
                                                            <input type="text" class="form-control " formControlName="count_number" placeholder="Enter Count" [ngClass]="{ 'is-invalid': (saveBtnClicked || firstmonth(i).controls[first].get('count_number')?.touched || firstmonth(i).controls[first].get('count_number')?.dirty) &&  firstmonth(i).controls[first].get('count_number')?.errors}">
                                                            <div *ngIf="(saveBtnClicked || firstmonth(i).controls[first].get('count_number')?.touched || firstmonth(i).controls[first].get('count_number')?.dirty) && firstmonth(i).controls[first].get('count_number')?.errors" class="invalid-feedback">
                                                              <div *ngIf="firstmonth(i).controls[first].get('count_number')?.errors?.required">
                                                                  {{this.messageService.validationDisplay('required')}}
                                                              </div>
                                                              <!-- Hiding 0.1 validation as per client requirement -->
                                                              <!-- <div *ngIf="firstmonth(i).controls[first].get('count_number')?.hasError('min') && !(firstmonth(i).controls[first].get('count_number')?.errors?.pattern)">
                                                                {{this.messageService.fieldlengthvalidation('minvalue',0.1)}}
                                                                  </div> -->
                                                                  <div *ngIf="firstmonth(i).controls[first].get('count_number')?.hasError('max') && !(firstmonth(i).controls[first].get('count_number')?.errors?.pattern)">
                                                                    {{this.messageService.fieldlengthvalidation('value','Leave credit count')}}
                                                                  </div>
                                                              <div *ngIf="firstmonth(i).controls[first].get('count_number')?.errors?.pattern">
                                                                  {{this.messageService.validationDisplay('pattern')}}
                                                              </div>
                                                            </div>
                                                        </div>
                                                      <a class="flex-center sq-40 text-danger mt-0" *ngIf="first!=0" (click)="removeFirstRule(i,first)">
                                                          <i class="icon-trash "></i>
                                                      </a>
                                                    </div>
                                                </div>
                                              </ng-container>
                                          </ng-container>
                                          <div class="col-12 mt-8">
                                              <div class="link-primary1 fs-14" (click)="addFstMonthRule(i,true)">
                                                  <i class="icon-plus-square-fill me-8" ></i>Add
                                              </div>
                                          </div>
                                      </ng-container>

                                      <div class="col-12">
                                          <label for="name" class="form-label required">How do you want to credit leave if an employee leaves in the middle of the accrual cycle </label>
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select the appropriate option: Prorate based on policy end date (i.e leaving date of employee) / Do not prorate" popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          <ng-select type="text" class="form-ngselect" placeholder="Select options" formControlName="leave_credit_timeofexiting"  [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.touched || leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.dirty) &&  leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.errors}">
                                            <ng-option *ngFor="let leave_credit_timeofexiting_values of leave_credit_timeofexiting_values" [value]="leave_credit_timeofexiting_values">{{leave_credit_timeofexiting_values}}
                                            </ng-option>
                                          </ng-select>
                                          <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.touched || leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.dirty) && leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.errors" class="invalid-feedback">
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                          </div>
                                      </div>

                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.value == 'Prorate based on exit date of employee' && leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.value == 'Monthly'">
                                        <div class="col-lg-12 ">
                                            <div class="form-row">
                                                <label for=" " class="form-label ">Do you want to apply last month rule? </label>
                                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select the option yes, if you want to enable last month rule." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" formControlName="last_month_rule" [value]=true (click)="addLstMonthRule(i,true)">
                                                <label class="form-check-label">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" formControlName="last_month_rule" [value]=false (click)="addLstMonthRule(i,false)">
                                                <label class="form-check-label">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                      </ng-container>

                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('last_month_rule')?.value && leavemapping_creditrule().controls[i].get('leave_credit_timeofexiting')?.value == 'Prorate based on exit date of employee' && leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.value == 'Monthly'">
                                        <ng-container  formArrayName="leavecreditrule_lastmonth">
                                          <div class="col-12 fs-14 fw-500 text-uppercase">
                                          LAST MONTH RULE
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="This rule is applicable while crediting leave balance for an employee who is exiting the organization. Rules can be set for the leaving month based on date of leaving." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          </div>
                                            <ng-container *ngFor="let role of lastmonth(i).controls; let last=index;"
                                            [formGroupName]="last">

                                          <div class="col-4">
                                              <label for="" class="form-label required">From date </label>
                                          </div>
                                          <div class="col-4">
                                              <label for="" class="form-label required">To date </label>
                                          </div>
                                          <div class="col">
                                              <label for="" class="form-label required">Count </label>
                                          </div>
                                          <div style="width:2.5rem" *ngIf="last!=0">&nbsp;</div>

                                          <div class="col-12" >
                                              <div class="align-items-start d-flex flex-nowrap row row-16">
                                                <div class="col-4 form-row mt-0">
                                                  <app-master-select  (ngModelChange)="validateLstRule(i,last)" class="config-app-master" [placeholder]="'Select day'" id="from_date"
                                                      [isrequired]="'true'"  formControlName="from_date" [type]="'schedule_dates'" [range]="28" [lastDay]="false" [ngClass]="{ 'is-invalid': (saveBtnClicked || lastmonth(i).controls[last].get('from_date')?.touched || lastmonth(i).controls[last].get('from_date')?.dirty) &&  lastmonth(i).controls[last].get('from_date')?.errors}"></app-master-select>
                                                      <div *ngIf="(saveBtnClicked || lastmonth(i).controls[last].get('from_date')?.touched || lastmonth(i).controls[last].get('from_date')?.dirty) && lastmonth(i).controls[last].get('from_date')?.errors" class="invalid-feedback">
                                                        <div *ngIf="lastmonth(i).controls[last].get('from_date')?.errors?.required">
                                                            {{this.messageService.validationDisplay('required')}}
                                                        </div>
                                                        <div *ngIf="lastmonth(i).controls[last].get('from_date')?.errors?.min">
                                                            {{this.messageService.validationDisplay('pattern')}}
                                                        </div>
                                                      </div>
                                                </div>
                                                  <div class="col-4 form-row mt-0">
                                                      <app-master-select (ngModelChange)="validateLstRule(i,last)" class="config-app-master" [placeholder]="'Select day'" id="to_date" [isrequired]="'true'"  formControlName="to_date" [type]="'schedule_dates'" [range]="28" [lastDay]="true" [ngClass]="{ 'is-invalid': (saveBtnClicked || lastmonth(i).controls[last].get('to_date')?.touched || lastmonth(i).controls[last].get('to_date')?.dirty) &&  lastmonth(i).controls[last].get('to_date')?.errors}"></app-master-select>
                                                      <div *ngIf="(saveBtnClicked || lastmonth(i).controls[last].get('to_date')?.touched || lastmonth(i).controls[last].get('to_date')?.dirty) && lastmonth(i).controls[last].get('to_date')?.errors" class="invalid-feedback">
                                                        <div *ngIf="lastmonth(i).controls[last].get('to_date')?.errors?.required">
                                                              {{this.messageService.validationDisplay('required')}}
                                                        </div>
                                                        <div *ngIf="lastmonth(i).controls[last].get('to_date')?.errors?.min">
                                                            {{this.messageService.validationDisplay('pattern')}}
                                                        </div>
                                                      </div>
                                                  </div>
                                                  <div class="col form-row mt-0">
                                                      <input type="text" class="form-control " formControlName="count_number" placeholder="Enter Count" [ngClass]="{ 'is-invalid': (saveBtnClicked || lastmonth(i).controls[last].get('count_number')?.touched || lastmonth(i).controls[last].get('count_number')?.dirty) &&  lastmonth(i).controls[last].get('count_number')?.errors}">
                                                      <div *ngIf="(saveBtnClicked || lastmonth(i).controls[last].get('count_number')?.touched || lastmonth(i).controls[last].get('count_number')?.dirty) && lastmonth(i).controls[last].get('count_number')?.errors" class="invalid-feedback">
                                                        <div *ngIf="lastmonth(i).controls[last].get('count_number')?.errors?.required">
                                                            {{this.messageService.validationDisplay('required')}}
                                                        </div>
                                                        <!-- Hiding 0.1 validation as per client requirement -->
                                                        <!-- <div *ngIf="lastmonth(i).controls[last].get('count_number')?.hasError('min') && !(lastmonth(i).controls[last].get('count_number')?.errors?.pattern)">
                                                          {{this.messageService.fieldlengthvalidation('minvalue',0.1)}}
                                                            </div> -->
                                                            <div *ngIf="lastmonth(i).controls[last].get('count_number')?.hasError('max') && !(lastmonth(i).controls[last].get('count_number')?.errors?.pattern)">
                                                                {{this.messageService.fieldlengthvalidation('value','Leave credit count')}}
                                                              </div>
                                                        <div *ngIf="lastmonth(i).controls[last].get('count_number')?.errors?.pattern">
                                                            {{this.messageService.validationDisplay('pattern')}}
                                                        </div>
                                                      </div>
                                                  </div>

                                                  <a class="flex-center sq-40 text-danger mt-0" *ngIf="last!=0" (click)="removeLastRule(i,last)">
                                                    <i class="icon-trash "></i>
                                                </a>
                                              </div>
                                          </div>
                                        </ng-container>
                                        </ng-container>
                                        <div class="col-12 mt-8">
                                            <div class="link-primary1 fs-14" (click)="addLstMonthRule(i,true)">
                                                <i class="icon-plus-square-fill me-8" ></i>Add
                                            </div>
                                        </div>
                                      </ng-container>
                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.value == 'Monthly'">

                                        <div class="col-lg-12 " >
                                            <div class="form-row">
                                                <label for=" " class="form-label ">Do you want to Grant leaves based on previous
                                                    month attendance?
                                                </label>
                                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If this option is enabled, the system will grant leaves to the employees based on the previous month’s attendance. In this rule, leave balance is credited based on working days of previous month." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" formControlName="grant_leave_prev_month" [value]=true (ngModelChange)="addValidators()">
                                                <label class="form-check-label">Yes </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" formControlName="grant_leave_prev_month" [value]=false (ngModelChange)="addValidators()">
                                                <label class="form-check-label">No </label>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="leavemapping_creditrule().controls[i].get('grant_leave_prev_month')?.value == true && leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.value == 'Monthly'">
                                        <div class="col-lg-12 ">
                                            <div class="form-row">
                                                <label for=" " class="form-label ">Required attendance is calculated from </label>
                                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If Present days is selected, the system will grant leave based on the present days, which include week off’s or holidays. If paid days is selected, the system will grant leave based on the paid days. Paid days include week off’s and holidays." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio"
                                                formControlName="req_attend_calculate" [value]=false>
                                                <label class="form-check-label">Present days </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input"  formControlName="req_attend_calculate" type="radio" [value]=true>
                                                <label class="form-check-label">Paid days </label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="name" class="form-label required">Credit leave if the previous month's attendance is or above</label>
                                            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Set minimum number of working days in previous month to credit leave balance in the current month. For example, if you set this value as 20, if employee works for 20 days or above the system should grant the full leave(1.5 days leave)." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                            <input type="text" class="form-control " formControlName="prev_month_greater_than" placeholder="Enter Days" [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.touched || leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.dirty) &&  leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.errors}">
                                            <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.touched || leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.dirty) && leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.errors?.required">
                                                {{this.messageService.validationDisplay('required')}}</div>
                                                <div *ngIf="leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.hasError('min') && !(leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.errors?.pattern)">
                                                  <!-- {{this.messageService.fieldlengthvalidation('minvalue',0.1)}} -->
                                                  {{this.messageService.validationDisplay('pattern')}}
                                                    </div>
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('prev_month_greater_than')?.errors?.pattern">
                                                  {{this.messageService.validationDisplay('pattern')}}</div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12 ">
                                            <div class="form-row">
                                                <label for=" " class="form-label ">If the above criteria not met, leaves will
                                                </label>
                                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select the appropriate option. “Lapse” option will lapse the leaves of the employee if the employee’s working days is less than the specified days. No leaves will be credited to the employee. “Prorate” will prorate the employee’s leave based on the working days. System will prorate the total leave quota to be credited." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="criteria_not_met" type="radio" [value]=false>
                                                <label class="form-check-label">Lapse </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="criteria_not_met" type="radio" [value]=true>
                                                <label class="form-check-label">Prorate </label>
                                            </div>
                                        </div>
                                        </ng-container>
                                      </ng-container>

                                      <div class="col-lg-12 ">
                                          <div class="form-row">
                                              <label for=" " class="form-label ">Do you want to Roundoff of leaves while granting?
                                              </label>
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If Round-off of leaves is enabled, leave balances will round off as per the settings defined." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" formControlName="roundoff_leave_granting" type="radio" [value]=true (ngModelChange)="addValidators()">
                                              <label class="form-check-label">Yes </label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" formControlName="roundoff_leave_granting" [value]=false (ngModelChange)="addValidators()">
                                              <label class="form-check-label">No </label>
                                          </div>
                                      </div>


                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('roundoff_leave_granting')?.value">
                                        <div class="col-12 mt-8">
                                            <ng-select type="text" class="form-ngselect" placeholder="Select round off options" formControlName="roundoff_option"  [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('roundoff_option')?.touched || leavemapping_creditrule().controls[i].get('roundoff_option')?.dirty) &&  leavemapping_creditrule().controls[i].get('roundoff_option')?.errors}">
                                              <ng-option *ngFor="let roundoffValues of roundoffValues" [value]="roundoffValues">{{roundoffValues}}
                                              </ng-option>
                                            </ng-select>
                                            <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('roundoff_option')?.touched || leavemapping_creditrule().controls[i].get('roundoff_option')?.dirty) && leavemapping_creditrule().controls[i].get('roundoff_option')?.errors" class="invalid-feedback">
                                              <div *ngIf="leavemapping_creditrule().controls[i].get('roundoff_option')?.errors?.required"> {{this.messageService.validationDisplay('required')}}</div>

                                            </div>
                                        </div>
                                      </ng-container>

                                      <!-- New Field -->
                                      <div class="col-lg-12 " >
                                        <div class="form-row">
                                            <label for=" " class="form-label ">Does this credited leave get lapsed after a specific period?
                                            </label>

                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="credit_lapse_enable" [value]=true (ngModelChange)="addValidators()">
                                            <label class="form-check-label">Yes </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="credit_lapse_enable" [value]=false (ngModelChange)="addValidators()">
                                            <label class="form-check-label">No </label>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="leavemapping_creditrule().controls[i].get('credit_lapse_enable')?.value == true">
                                    <div class="col-lg-12 ">
                                        <div class="form-row">
                                            <label for=" " class="form-label required">After what period of time, this leave will get lapsed? </label>

                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                            formControlName="credit_lapse_cond" [value]=false (ngModelChange)="addValidators();makeItAsEmpty('credit_lapse',i)">
                                            <label class="form-check-label">Month </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input"  formControlName="credit_lapse_cond" type="radio" [value]=true (ngModelChange)="addValidators();makeItAsEmpty('credit_lapse',i)">
                                            <label class="form-check-label">Days </label>
                                        </div>
                                    </div>
                                    <div class="col-12">

                                        <input type="text" class="form-control " formControlName="credit_lapse" [placeholder]="leavemapping_creditrule().controls[i].get('credit_lapse_cond')?.value == false ? 'Enter month' : 'Enter days'" [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('credit_lapse')?.touched || leavemapping_creditrule().controls[i].get('credit_lapse')?.dirty) &&  leavemapping_creditrule().controls[i].get('credit_lapse')?.errors}">
                                        <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('credit_lapse')?.touched || leavemapping_creditrule().controls[i].get('credit_lapse')?.dirty) && leavemapping_creditrule().controls[i].get('credit_lapse')?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('credit_lapse')?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('credit_lapse')?.hasError('min') && !(leavemapping_creditrule().controls[i].get('credit_lapse')?.errors?.pattern)">
                                              {{this.messageService.fieldlengthvalidation('minvalue',1)}}
                                        </div>
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('credit_lapse')?.hasError('max') && !(leavemapping_creditrule().controls[i].get('credit_lapse')?.errors?.pattern)">
                                          {{this.messageService.fieldlengthvalidation('value',60)}}
                                        </div>
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('credit_lapse')?.hasError('maxlength') && !(leavemapping_creditrule().controls[i].get('credit_lapse')?.errors?.pattern)">
                                          {{this.messageService.fieldlengthvalidation('num','3')}}
                                        </div>
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('credit_lapse')?.errors?.pattern">
                                              {{this.messageService.validationDisplay('pattern')}}</div>

                                        </div>
                                    </div>

                                    </ng-container>

                                      <!-- End -->

                                      <div class="col-lg-12 ">
                                          <div class="form-row">
                                              <label for=" " class="form-label ">Do you want to provide leave opening balance?
                                              </label>
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option if you want provide leave balance at the beginning of a leave cycle or if you want to credit leave balance when a new employee joins." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" formControlName="leave_opening_balance_enable"  [value]=true (ngModelChange)="addValidators()">
                                              <label class="form-check-label">Yes </label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" formControlName="leave_opening_balance_enable"  type="radio" [value]=false (ngModelChange)="addValidators()">
                                              <label class="form-check-label">No </label>
                                          </div>
                                      </div>
                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('leave_opening_balance_enable')?.value">
                                        <div class="col-12">
                                          <label for="name" class="form-label required">Opening balance count </label>
                                          <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enter the number of Leave days to be provided as opening balance." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          <input type="text" formControlName="leave_opening_balance" class="form-control " placeholder="Enter Days "  [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('leave_opening_balance')?.touched || leavemapping_creditrule().controls[i].get('leave_opening_balance')?.dirty) &&  leavemapping_creditrule().controls[i].get('leave_opening_balance')?.errors}">
                                          <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('leave_opening_balance')?.touched || leavemapping_creditrule().controls[i].get('leave_opening_balance')?.dirty) && leavemapping_creditrule().controls[i].get('leave_opening_balance')?.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('leave_opening_balance')?.errors?.required">
                                                {{this.messageService.validationDisplay('required')}}</div>
                                                <div *ngIf="leavemapping_creditrule().controls[i].get('leave_opening_balance')?.hasError('min') && !(leavemapping_creditrule().controls[i].get('leave_opening_balance')?.errors?.pattern)">
                                                  <!-- {{this.messageService.fieldlengthvalidation('minvalue',0.1)}} -->
                                                  {{this.messageService.validationDisplay('pattern')}}
                                                    </div>
                                                <div *ngIf="leavemapping_creditrule().controls[i].get('leave_opening_balance')?.errors?.pattern">
                                                  {{this.messageService.validationDisplay('pattern')}}</div>
                                          </div>
                                        </div>
                                      </ng-container>
                                      <div class="col-lg-12 ">
                                          <div class="form-row">
                                              <label for=" " class="form-label ">Do you want to carry over previous policy balance
                                                  when credit rule change?
                                              </label>
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If the leave policy changes, for example, when transitioning from probation to confirmed employment, employees have the option to transfer their remaining leave balance to the new policy." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" formControlName="carry_over_prev_creditrule" type="radio" [value]=true (ngModelChange)="addValidators()">
                                              <label class="form-check-label">Yes </label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" formControlName="carry_over_prev_creditrule" [value]=false  (ngModelChange)="addValidators()">
                                              <label class="form-check-label">No </label>
                                          </div>
                                      </div>
                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('carry_over_prev_creditrule')?.value">
                                        <div class="col-lg-12 form-row ">
                                        <div class="form-row">
                                            <label for="name" class="form-label required">Maximum carry over limit </label>
                                            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Set limit for the carry over during change of leave policy." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                        </div>
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio" formControlName="max_carry_over_status"
                                              [value]=false (ngModelChange)="addValidators()">
                                          <label class="form-check-label">
                                              All
                                          </label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio" formControlName="max_carry_over_status"
                                              [value]=true (ngModelChange)="addValidators()">
                                          <label class="form-check-label">
                                              Set limit
                                          </label>
                                      </div>
                                      </div>
                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('max_carry_over_status')?.value">
                                        <div class=" col-12 form-row mt-8" [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('carry_over_limit')?.touched || leavemapping_creditrule().controls[i].get('carry_over_limit')?.dirty) &&  leavemapping_creditrule().controls[i].get('carry_over_limit')?.errors}">
                                            <input type="text" class="form-control " formControlName="carry_over_limit" placeholder="Enter Days " [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('carry_over_limit')?.touched || leavemapping_creditrule().controls[i].get('carry_over_limit')?.dirty) &&  leavemapping_creditrule().controls[i].get('carry_over_limit')?.errors}">
                                        </div>
                                        <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('carry_over_limit')?.touched || leavemapping_creditrule().controls[i].get('carry_over_limit')?.dirty) && leavemapping_creditrule().controls[i].get('carry_over_limit')?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('carry_over_limit')?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                            <div *ngIf="leavemapping_creditrule().controls[i].get('carry_over_limit')?.hasError('min') && !(leavemapping_creditrule().controls[i].get('carry_over_limit')?.errors?.pattern)">
                                              <!-- {{this.messageService.fieldlengthvalidation('minvalue',0.1)}} -->
                                              {{this.messageService.validationDisplay('pattern')}}
                                                </div>
                                          <div *ngIf="leavemapping_creditrule().controls[i].get('carry_over_limit')?.errors?.pattern">
                                              {{this.messageService.validationDisplay('pattern')}}</div>
                                        </div>
                                      </ng-container>
                                      </ng-container>
                                      <div class="col-lg-12 ">
                                          <div class="form-row">
                                              <label for=" " class="form-label ">Do you want to carry forward the leave balance to next year?
                                              </label>
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enable this option if you want to carry forward the employee's leave balance to the next leave cycle. " popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" formControlName="carry_forward_next_year" type="radio" [value]=true (ngModelChange)="addValidators()">
                                              <label class="form-check-label">Yes </label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" formControlName="carry_forward_next_year" type="radio" [value]=false (ngModelChange)="addValidators()">
                                              <label class="form-check-label">No </label>
                                          </div>
                                      </div>
                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('carry_forward_next_year')?.value">
                                        <!-- <div class="col-12">
                                            <label for="name" class="form-label required">Carry forward limit </label>
                                            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Set the maximum number of leaves that can be carried forward to the next cycle." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                            <input type="text" formControlName="carry_forward_limit" class="form-control " placeholder="Enter Days " [ngClass]="{ 'is-invalid': (saveBtnClicked || leavemapping_creditrule().controls[i].get('carry_forward_limit')?.touched || leavemapping_creditrule().controls[i].get('carry_forward_limit')?.dirty) &&  leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors}">
                                        </div>
                                        <div *ngIf="(saveBtnClicked || leavemapping_creditrule().controls[i].get('carry_forward_limit')?.touched || leavemapping_creditrule().controls[i].get('carry_forward_limit')?.dirty) && leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                        <div *ngIf="leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors?.pattern">
                                              {{this.messageService.validationDisplay('pattern')}}</div>

                                        </div> -->
                                        <div class="col-lg-12 form-row ">
                                          <div class="form-row">
                                              <label for=" " class="form-label  ">Maximum carry forward limit </label>
                                              <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Set the maximum number of leaves that can be carried forward to the next cycle. " popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" formControlName="max_carry_forward_status"
                                                  [value]=false (ngModelChange)="addValidators()">
                                              <label class="form-check-label">
                                                  All
                                              </label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" formControlName="max_carry_forward_status"
                                                  [value]=true (ngModelChange)="addValidators()">
                                              <label class="form-check-label">
                                                  Set limit
                                              </label>
                                          </div>
                                      </div>
                                      <ng-container *ngIf="leavemapping_creditrule().controls[i].get('max_carry_forward_status')?.value">
                                        <div class=" col-12 form-row mt-8">
                                            <input type="text" class="form-control " formControlName="carry_forward_limit"
                                                placeholder="Enter value"
                                                [ngClass]="{ 'is-invalid': ( leavemapping_creditrule().controls[i].get('carry_forward_limit')?.touched || leavemapping_creditrule().controls[i].get('carry_forward_limit')?.dirty) &&  leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors}">
                                            <div *ngIf="(leavemapping_creditrule().controls[i].get('carry_forward_limit')?.touched || leavemapping_creditrule().controls[i].get('carry_forward_limit')?.dirty) && leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}
                                                </div>
                                                <div *ngIf="leavemapping_creditrule().controls[i].get('carry_forward_limit')?.hasError('min') && !(leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors?.pattern)">
                                                  <!-- {{this.messageService.fieldlengthvalidation('minvalue',0.1)}} -->
                                                  {{this.messageService.validationDisplay('pattern')}}
                                                    </div>
                                                <div *ngIf="leavemapping_creditrule().controls[i].get('carry_forward_limit')?.errors?.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}
                                                </div>

                                            </div>
                                        </div>
                                    </ng-container>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                              </ng-container>

                          </div>
                      </div>
                    </ng-container>
                    <ng-template #manualsection>
                      <div class="px-16 pb-32">
                        <div class="row row-16">
                          <div class="col-lg-12 ">
                              <div class="form-row">
                                  <label for=" " class="form-label ">Do you want to carry forward the leave balance to next year?
                                  </label>
                                  <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="This is an option to carry forward leave balance to the next leave cycle. Select yes if you want to enable this." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                              </div>
                              <div class="form-check form-check-inline">
                                  <input class="form-check-input" formControlName="carry_forward_feature_year" type="radio" [value]=true (ngModelChange)="addManualValidator()">
                                  <label class="form-check-label">Yes </label>
                              </div>
                              <div class="form-check form-check-inline">
                                  <input class="form-check-input" formControlName="carry_forward_feature_year" type="radio" [value]=false (ngModelChange)="addManualValidator()">
                                  <label class="form-check-label">No </label>
                              </div>
                          </div>
                          <ng-container *ngIf="creditdetailsForm.controls[i].get('carry_forward_feature_year')?.value == true">
                            <!-- <div class="col-12">
                                <label for="name" class="form-label required">Carry forward limit </label>
                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Set the maximum number of leaves that can be carried forward to the next cycle." popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                                <input type="text" formControlName="carry_forward_count" class="form-control " placeholder="Enter Days " [ngClass]="{ 'is-invalid': (saveBtnClicked || creditdetailsForm.controls[i].get('carry_forward_count')?.touched || creditdetailsForm.controls[i].get('carry_forward_count')?.dirty) &&  creditdetailsForm.controls[i].get('carry_forward_count')?.errors}">
                            </div>
                            <div *ngIf="(saveBtnClicked || creditdetailsForm.controls[i].get('carry_forward_count')?.touched || creditdetailsForm.controls[i].get('carry_forward_count')?.dirty) &&  creditdetailsForm.controls[i].get('carry_forward_count')?.errors"
                            class="invalid-feedback">
                            <div *ngIf="creditdetailsForm.controls[i].get('carry_forward_count')?.errors?.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="creditdetailsForm.controls[i].get('carry_forward_count')?.errors?.pattern">
                                  {{this.messageService.validationDisplay('pattern')}}</div>

                            </div> -->
                            <div class="col-lg-12 form-row ">
                              <div class="form-row">
                                  <label for=" " class="form-label  ">Maximum carry forward limit </label>
                                  <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Set the maximum number of leaves that can be carried forward to the next cycle. " popoverClass="popover-default popover-info"  triggers="hover"></i></span>
                              </div>
                              <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" formControlName="max_carry_forward_count_status"
                                      [value]=false (ngModelChange)="addManualValidator()">
                                  <label class="form-check-label">
                                      All
                                  </label>
                              </div>
                              <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" formControlName="max_carry_forward_count_status"
                                      [value]=true (ngModelChange)="addManualValidator()">
                                  <label class="form-check-label">
                                      Set limit
                                  </label>
                              </div>
                          </div>
                          <ng-container *ngIf="creditdetailsForm.controls[i].get('max_carry_forward_count_status')?.value">
                              <div class=" col-12 form-row mt-8">
                                  <input type="text" class="form-control " formControlName="carry_forward_count"
                                      placeholder="Enter value"
                                      [ngClass]="{ 'is-invalid': ( creditdetailsForm.controls[i].get('carry_forward_count')?.touched || creditdetailsForm.controls[i].get('carry_forward_count')?.dirty) &&  creditdetailsForm.controls[i].get('carry_forward_count')?.errors}">
                                  <div *ngIf="(creditdetailsForm.controls[i].get('carry_forward_count')?.touched || creditdetailsForm.controls[i].get('carry_forward_count')?.dirty) && creditdetailsForm.controls[i].get('carry_forward_count')?.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="creditdetailsForm.controls[i].get('carry_forward_count')?.errors?.required">
                                          {{this.messageService.validationDisplay('required')}}
                                      </div>
                                      <div *ngIf="creditdetailsForm.controls[i].get('carry_forward_count')?.errors?.pattern">
                                          {{this.messageService.validationDisplay('pattern')}}
                                      </div>
                                      <div *ngIf="!(creditdetailsForm.controls[i].get('carry_forward_count')?.errors?.pattern) && creditdetailsForm.controls[i].get('carry_forward_count')?.errors?.min">
                                        <!-- {{this.messageService.fieldlengthvalidation('minvalue',0.1)}} -->
                                        {{this.messageService.validationDisplay('pattern')}}
                                    </div>

                                  </div>
                              </div>
                          </ng-container>

                          </ng-container>
                        </div>
                      </div>
                    </ng-template>
                </div>

          </ng-container>
        </ng-container>
    </ng-container>
</form>
