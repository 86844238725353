import { Component, Input, OnInit, Output,  EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-status',
  templateUrl: './view-status.component.html',
  styleUrls: ['./view-status.component.scss']
})
export class ViewStatusComponent implements OnInit {

  @Input() loader:any;
  @Input() approvalRequired:any;
  @Input() viewStatusData:any;
  @Input() viewStatusLevelCnt:any;
  @Input() viewStatusmaxLevel:any;
  @Input() from:any;


  constructor(
    public appservice:AppService,
  ) { }

  ngOnInit(): void {
  }




}
