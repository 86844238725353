import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PreviousRouteService } from './previous-route.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ExpenseReportRequestService {

  constructor(
    private http: HttpClient,
    public router: Router,
    private previousRouteService: PreviousRouteService,
    private _location: Location
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  reportList(search:any) {
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report/?search=${search}&status_filter=["Rejected","Draft"]&is_active=true`, this.httpOption)
  }

  reportListDropDown() {
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report/?status_filter=["Rejected","Draft"]&ordering=name&is_active=true`, this.httpOption)
  }

  getSingleReport(id:any) {
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report/${id}/`, this.httpOption)
  }

  createReport(body: any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-report/`, body, this.httpOption)
  }

  advanceList() {
    return this.http.get(`${this.API_PATH}request/api/v1/expense-advance-list/?unmap=True`, this.httpOption)
  }

  receiptList(expense_date:any) {
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-receipt/?status_filter=["Unreported","Expired"]&is_active=true&expense_date=${expense_date}`, this.httpOption)
  }

  getSingleReceiptData(id:any) {
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-receipt/${id}/`, this.httpOption)
  }

  receiptUpdate(id: number, body:any) {
    return this.http.put(`${this.API_PATH}expense/api/v1/expense-receipt/${id}/`, body, this.httpOption)
  }

  updateExpenseReport(id: number, body:any) {
    return this.http.put(`${this.API_PATH}expense/api/v1/expense-report/${id}/`, body, this.httpOption)
  }

  createExpenseReportRequest(body: any){
    return this.http.post(`${this.API_PATH}request/api/v1/expense-report-request-list/`, body, this.httpOption)
  }

  viewRequestRequest(type:any,id:number){
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=${type}&request_id=${id}`,this.httpOption)
  }

  saveReceipt( body:any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-receipt/`, body, this.httpOption)
  }

  getAdvanceDetailedView(id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/expense-advance-detail/${id}/`, this.httpOption);
  }

  backClicked() {
    if(this.previousRouteService.getPreviousUrl() == '/login')
    this.router.navigate(['request']);
    else
    this._location.back();
  }
}
