<!-- <form class=""> -->
<div class="container-fluid p-32 form-container" *ngIf="permissions">
  <div class="row row-24 ">
    <div class="col-12 d-flex flex-wrap align-items-start">
      <div class="mb-0 fw-500 text-uppercase">Attendance - {{month}} {{year}}</div>
      <div class="d-flex justify-content-end align-items-center ms-auto flex-wrap gap-16">
        <!-- <a *ngIf="countSelected()>=1 && permissions?.a"  class="link-danger text-nowrap"  (click)="bulkDelete()"><i class="icon-trash me-8 "></i>Delete</a> -->
        <div style="min-width:22.688rem;">
          <div class="form-icon2">
            <i class="icon-search icon-left fs-14"></i>
            <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
              (click)="searchString=''"></div>
            <input class="form-control  fs-14" autocomplete="off" placeholder="{{this.messageService.searchdisplay('novalue')}}  "
              name="searchString" [(ngModel)]="searchString" (keyup)="page=1" autocomplete="off"/>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block" (click)="filter  = true">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
        </div>
        <button *ngIf="permissions?.a && status=='Completed'"
          class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 text-uppercase"
          (click)="showSidePanel=true;fileName=''" [disabled]="loader">Import </button>
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
            [checked]='status=="Pending"' (click)='status="Pending";searchString="";getProcessList();clearfilter()'
            [disabled]="loader||status=='Pending'">
          <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio1">PENDING</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
            [checked]='status=="Completed"' (click)='status="Completed";searchString="";historyList();clearfilter()'
            [disabled]="loader||status=='Completed'">
          <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">COMPLETED</label>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="!isClosed && status=='Completed' && !loader">
      <div role="alert" class="alert alert-warning mb-0 show">
        <p class="mb-0 fs-14">Cannot unprocess the attendance if salary is already processed.</p>
        <div class="btn-close pointer" (click)="isClosed=true;"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-c2 p-0">
        <app-table-loader *ngIf="loader"></app-table-loader>
        <div *ngIf="!loader" class="table-responsive radius-4" style="max-height: calc(100vh - 22.2rem);">
          <table *ngIf="status=='Pending'" class="table  sticky-header td-white-space td-fs-14
                            tr-fs-14  table-sm form-table  td-12  table-striped sticky-first-col sticky-last-col">
            <thead>
              <tr>
                <th class="form-cell">
                  <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)"
                    [checked]="checkallSelected()">
                </th>
                <th class="fw-600" scope="col">EMP CODE</th>
                <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                <!-- <th class="fw-600" scope="col">COMPANY</th> -->
                <th class="fw-600" scope="col">DEPARTMENT</th>
                <th class="fw-600" scope="col">DESIGNATION</th>
                <th class="fw-600" scope="col">ATTENDANCE POLICY</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="processList?.length==0">
                <tr>
                  <td colspan="10">
                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                      <span class="fw-500 fs-14">No data found</span>
                    </div>
                  </td>
                </tr>
              </ng-container>
              <tr
                *ngFor="let item of $any(processList|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                <td class="form-cell">
                  <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked">
                </td>
                <td> {{item.employee_code}}</td>
                <td class="text-trim w-15ch" title="{{item.name}}"
                data-toggle="tooltip"> {{item.name}}</td>
                <!-- <td > {{item.company}}</td> -->
                <td> {{item.department}}</td>
                <td> {{item.designation}}</td>
                <td> {{item.attendance_rule_name}}</td>
              </tr>
            </tbody>
          </table>
          <table *ngIf="status=='Completed'" class="table table-sticky-requriement td-12 th-12 sticky-header td-fs-14
                            tr-fs-14  table-sm form-table  table-striped ">
            <thead>
              <tr>
                <th class="form-cell" sty>
                  <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)"
                    [checked]="checkallSelected()">
                </th>
                <th class="fw-600" scope="col">EMP CODE</th>
                <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                <th class="fw-600 bg21" scope="col">PAID DAYS</th>
                <th class="fw-600" scope="col">PRESENT DAYS</th>
                <th class="fw-600" scope="col">WEEKLY OFF'S</th>
                <th class="fw-600" scope="col">LEAVE</th>
                <th class="fw-600" scope="col">HOLIDAY <span
                    class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span></th>
                <th class="fw-600" scope="col">ABSENT</th>
                <th class="fw-600" scope="col">UNPAID LEAVE</th>
                <th class="fw-600" scope="col">PAID DAYS DEDUCTION</th>
                <th class="fw-600" scope="col">LEAVE DEDUCTION</th>
              </tr>
            </thead>
            <tbody class="height-full">
              <ng-container *ngIf="historyData.length==0 ">
                <tr>
                  <td colspan="12">
                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                      <span class="fw-500 fs-14">No data found</span>
                    </div>
                  </td>
                </tr>
              </ng-container>
              <tr
                *ngFor="let item of $any(historyData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                <td class="form-cell">
                  <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked">
                </td>
                <td>
                  <div class=" {{item?.is_valid?'cell-error is-invalid':''}}">
                    {{item.employee_code}}</div>
                  <div *ngIf="item?.is_valid" class="invalid-feedback">{{item?.error}}</div>
                </td>
                <td class="text-trim w-15ch" title="{{item.name}}" data-toggle="tooltip">{{item.name}}</td>
                <td class="bg21">{{item.actual_paid_days}}</td>
                <td>
                  <div class="form-icon icon-right {{item?.prasent_days_invalid ?'is-invalid':''}}">
                    <input type="text" value="{{item.prasent_days}}" [(ngModel)]="item.prasent_days"
                      (keyup)="keyup('prasent_days',item?.employee_id)"
                      class=" form-control {{item?.prasent_days_invalid ?'is-invalid':''}}" style="min-width: 6.25rem;">
                    <ng-container *ngIf="item?.prasent_days!=item?.original_prasent_days">
                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent"
                        popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                      <ng-template #popContent>
                        <div class="d-flex flex-column flex-center">
                          <span class="fs-14 text-center">Actual days : {{item?.original_prasent_days}}</span>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                  <div *ngIf="item?.prasent_days_invalid==true" class="invalid-feedback">
                    <div *ngIf="item?.prasent_days_invalid==true">{{item?.prasent_days_msg}}</div>
                  </div>
                </td>
                <td>
                  <div class="form-icon icon-right {{item?.weekly_offs_invalid ?'is-invalid':''}}">
                    <input class=" form-control {{item?.weekly_offs_invalid ?'is-invalid':''}}" type="text"
                      value="{{item.weekly_offs}}" [(ngModel)]="item.weekly_offs" (keyup)="keyup('weekly_offs',item?.employee_id)"
                      style="min-width: 6.25rem;">
                    <ng-container *ngIf="item?.weekly_offs!=item?.original_weekly_offs">
                      <ng-template #popContent>
                        <div class="d-flex flex-column flex-center">
                          <span class="fs-14 text-center">Actual days : {{item?.original_weekly_offs}}</span>
                        </div>
                      </ng-template>
                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent"
                        popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                    </ng-container>
                  </div>
                  <div *ngIf="item?.weekly_offs_invalid" class="invalid-feedback">
                    <div *ngIf="item?.weekly_offs_invalid">{{item?.weekly_offs_msg}}</div>
                    <!-- <div *ngIf="item.days_pattern ==true">{{item?.days_pattern_msg}}</div> -->
                  </div>
                </td>
                <td>
                  <div class="form-icon icon-right {{(item?.leave_paid_invalid) ?'is-invalid':''}}">
                    <input class=" form-control {{(item?.leave_paid_invalid) ?'is-invalid':''}}" type="text"
                      value="{{item.leave_paid}}" [(ngModel)]="item.leave_paid" (keyup)="keyup('leave_paid',item?.employee_id)"
                      style="min-width: 6.25rem;">
                    <ng-container *ngIf="item?.leave_paid!=item?.original_leave_paid">
                      <ng-template #popContent>
                        <div class="d-flex flex-column flex-center">
                          <span class="fs-14 text-center">Actual days : {{item?.original_leave_paid}}</span>
                        </div>
                      </ng-template>
                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent"
                        popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                    </ng-container>
                  </div>
                  <div *ngIf="item?.leave_paid_invalid" class="invalid-feedback">
                    <div *ngIf="item?.leave_paid_invalid">{{item?.leave_paid_msg}}</div>
                  </div>

                </td>
                <td>
                  <div class="form-icon icon-right  {{(item.holiday_paid_invalid) ?'is-invalid':''}}">
                    <input class="form-control {{(item.holiday_paid_invalid) ?'is-invalid':''}}" type="text"
                      value="{{item.holiday_paid}}" [(ngModel)]="item.holiday_paid" (keyup)="keyup('holiday_paid',item?.employee_id)"
                      style="min-width: 6.25rem;">
                    <ng-container *ngIf="item?.holiday_paid!=item?.original_holiday_paid">
                      <ng-template #popContent>
                        <div class="d-flex flex-column flex-center">
                          <span class="fs-14 text-center">Actual days : {{item?.original_holiday_paid}}</span>
                        </div>
                      </ng-template>
                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent"
                        popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                    </ng-container>
                  </div>
                  <div *ngIf="item.holiday_paid_invalid" class="invalid-feedback">
                    <div *ngIf="item.holiday_paid_invalid">{{item?.holiday_paid_msg}}</div>
                  </div>

                </td>
                <!-- <td>{{item.absent_unpaid}}</td> -->
                <td>
                  <div class="form-icon icon-right  {{(item.absent_unpaid_invalid) ?'is-invalid':''}}">
                    <input class="form-control {{(item.absent_unpaid_invalid) ?'is-invalid':''}}" type="text"
                      value="{{item.absent_unpaid}}" [(ngModel)]="item.absent_unpaid" (keyup)="keyup('absent_unpaid',item?.employee_id)"
                      style="min-width: 6.25rem;">
                    <ng-container *ngIf="item?.absent_unpaid!=item?.original_absent_unpaid">
                      <ng-template #popContent>
                        <div class="d-flex flex-column flex-center">
                          <span class="fs-14 text-center">Actual days : {{item?.original_absent_unpaid}}</span>
                        </div>
                      </ng-template>
                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent"
                        popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                    </ng-container>
                  </div>
                  <div *ngIf="item.absent_unpaid_invalid" class="invalid-feedback">
                    <div *ngIf="item.absent_unpaid_invalid">{{item?.absent_unpaid_msg}}</div>
                  </div>

                </td>
                <!-- <td>{{item.leave_unpaid}}</td> -->
                <td>
                  <div class="form-icon icon-right  {{(item.leave_unpaid_invalid) ?'is-invalid':''}}">
                    <input class="form-control {{(item.leave_unpaid_invalid) ?'is-invalid':''}}" type="text"
                      value="{{item.leave_unpaid}}" [(ngModel)]="item.leave_unpaid" (keyup)="keyup('leave_unpaid',item?.employee_id)"
                      style="min-width: 6.25rem;">
                    <ng-container *ngIf="item?.leave_unpaid!=item?.original_leave_unpaid">
                      <ng-template #popContent>
                        <div class="d-flex flex-column flex-center">
                          <span class="fs-14 text-center">Actual days : {{item?.original_leave_unpaid}}</span>
                        </div>
                      </ng-template>
                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent"
                        popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                    </ng-container>
                  </div>
                  <div *ngIf="item.leave_unpaid_invalid" class="invalid-feedback">
                    <div *ngIf="item.leave_unpaid_invalid">{{item?.leave_unpaid_msg}}</div>
                  </div>

                </td>
                <!-- <td>{{item.deduction_paid_days}}</td> -->
                <td>
                  <div class="form-icon icon-right  {{(item.deduction_paid_days_invalid) ?'is-invalid':''}}">
                    <input class="form-control {{(item.deduction_paid_days_invalid) ?'is-invalid':''}}" type="text"
                      value="{{item.deduction_paid_days}}" [(ngModel)]="item.deduction_paid_days" (keyup)="keyup('deduction_paid_days',item?.employee_id)"
                      style="min-width: 6.25rem;">
                    <ng-container *ngIf="item?.deduction_paid_days!=item?.original_deduction_paid_days">
                      <ng-template #popContent>
                        <div class="d-flex flex-column flex-center">
                          <span class="fs-14 text-center">Actual days : {{item?.original_deduction_paid_days}}</span>
                        </div>
                      </ng-template>
                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent"
                        popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                    </ng-container>
                  </div>
                  <div *ngIf="item.deduction_paid_days_invalid" class="invalid-feedback">
                    <div *ngIf="item.deduction_paid_days_invalid">{{item?.deduction_paid_days_msg}}</div>
                  </div>

                </td>
                <td>{{item.deduction_leaves}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!loader">
    <div class="col-12 mt-4" *ngIf="filteredcount()>pageSize">
      <ngb-pagination class="d-flex justify-content-end" [pageSize]="pageSize" [collectionSize]="filteredcount()"
        [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
    </div>
  </ng-container>
</div>
<footer *ngIf="!loader && status=='Pending'" class="submit-footer  gap-16">
  <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="processList?.length>0">
    <span class="">{{countSelected()}} of {{processList?.length}} selected </span>
    <span *ngIf="processList.length != countSelected()" class="link-primary fw-500" (click)="selectAll();">Select all
      {{processList?.length}} employees</span>
    <span *ngIf="processList.length == countSelected()" class="link-primary fw-500" (click)="unselectAll();">Unselect
      all {{processList?.length}} employees</span>
  </p>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="processList?.length==0 || countSelected()==0 "
    (click)="confirmation();">Process Attendance</button>
</footer>
<footer *ngIf="!loader && status=='Completed'" class="submit-footer  gap-16">
  <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="historyData?.length>0">
    <span class="">{{countSelected()}} of {{historyData?.length}} selected </span>
    <span *ngIf="historyData.length != countSelected()" class="link-primary fw-500" (click)="selectAll();">Select all
      {{historyData?.length}} employees</span>
    <span *ngIf="historyData.length == countSelected()" class="link-primary fw-500" (click)="unselectAll();">Unselect
      all {{historyData?.length}} employees</span>
  </p>
  <button class="btn btn-primary btn-sm text-uppercase"
    [disabled]="historyData?.length==0 || countSelected()==0 || checkValidity()==countSelected()"
    (click)="confirmation();">Unprocess Attendance</button>
  <button class="btn btn-primary btn-sm text-uppercase"
    [disabled]="historyData?.length==0 || countSelected()==0 || checkValidity()==countSelected()"
    (click)="editConfirmation()">Save</button>
</footer>
<!-- </form> -->
<!-- Slider -->
<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Import Attendance - {{month}} {{year}}</h5>
      <a class="toggle-panel" (click)="deleteFile();showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card card-c2 p-24 ">
              <div class="title mb-24">New Attendance - {{month}} {{year}}</div>
              <div class="row" *ngIf="fileName">
                <div class="col-md-6">
                  <div class="card card-c2 bg-secondary  mb-24" *ngIf="fileName!=''">
                    <div class="d-flex p-24 ">
                      <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                      <span class="text-trim fw-500">{{fileName}}</span>
                      <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                      <span class="link-danger fw-500 flex-shrink-0" (click)="deleteFile()"><i
                          class="bi bi-trash ms-8 "></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                  (change)="onFileChange($event)" id="upload" hidden>Add New Attendance
              </label>
              <span class="invalid-feedback"
                *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="title mb-24">Instructions </div>
              <ol class="fs-14 ps-16 mb-0 li-24">
                <li>Download the <a class="link-primary" href="assets/docs/sample-attendance-import.xlsx"> <i
                      class="icon-download me-1"></i> Sample Excel
                    template</a></li>
                <li>To import the attendance details of the employees, click on the Add New Attendance
                  button.</li>
                <li>Choose the file to be imported.</li>
                <li>The file chosen should be in (.xls/.csv)format only.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer gap-32 pt-16">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || submiited"
        type="button" (click)="showSidePanel = false;uploadData()">Upload</button>
    </footer>
  </div>
</div>
<div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">{{countSelected()}} Employees selected</h4>
        <button type="button" class="btn-close ms-auto my-0" (click)="confirmMsg = false"></button>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{confirmMsgText}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="confirmMsg = false;">Cancel</button>
        <button [disabled]="saveComplete" *ngIf="purpose=='processing'" type="button"
          class="btn btn-primary btn-sm text-uppercase" (click)="saveAttendanceData();confirmMsg = false;">Save</button>
        <button [disabled]="saveComplete" *ngIf="purpose=='override'" type="button"
          class="btn btn-primary btn-sm text-uppercase" (click)="editCompletedData();confirmMsg = false;">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Error Occured</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{deleteMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle = false">Close</button>
      </div>
    </div>
  </div>
</div>
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
  (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="empalertMsg"></app-info-popup>
</ng-container>
<app-upload-progress-bar *ngIf=" isDataUpload !=0" [body]="body" [progress]="percentage| number:'1.0-0'"
  [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Uploading'"
  [processingText]='"Proccessing...."'></app-upload-progress-bar>
<app-error-download [fileName]="'Attendance '+term+' Error'" [successDataCount]="successDataCount"
  [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]=" term+' Attendance Data'"
  (closePanel)="downloadMsg = false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg"> </app-error-download>
<app-upload-progress-bar *ngIf="showProgress && startProgress" [progress]="progPercentage| number:'1.0-0'"
  [iscompleted]="iscompletedA" [isProgressStart]="isProgressStartA" [heading]="'Attendance Processing'"
  [timeLeft]="timeLeft" [body]="body" (closePanel)="closeProgress()"
  [processingText]='"Proccessing...."'></app-upload-progress-bar>
<app-error-download *ngIf="errorLog" [fileName]="'Attendance Process Error'" [successDataCount]="aps.PROCESSCOUNT"
  [downloadMsg]="errorLog" [failedData]="exportData" [modalHeading]="'Attendance Process'"
  (closePanel)="errorLog =false" [successMsg]="successMsgA" [errorMsg]="errorMsgA"> </app-error-download>
