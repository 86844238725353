import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { OnDutyService } from 'src/app/@core/services/on-duty.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-od-select-date',
  templateUrl: './od-select-date.component.html',
  styleUrls: ['./od-select-date.component.scss']
})
export class OdSelectDateComponent implements OnInit {

  @Input() formGrp : any
  @Input() data    : any
  @Output() submitmethod     = new EventEmitter();

  loader           : boolean = false
  disabled         : boolean = true
  selectedDate     : any     = new Date()
  empId            : any     = this.authServ.isEmployeeId
  month            : any     = new Date().getMonth()
  year             : any     =  new Date().getFullYear()
  dateSelected     : any     = []
  alertOnDutyArray : any     = []
  dayCount         : any     = '00'
  eligibility      : any
  alertToggle      : any
  alertToggleModal : any
  alertMsg         : any
  alertMsgModal    : any
  get_eligible_days: any
  prior_days       : any
  today:any =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  public calendarOptions: CalendarOptions = this.initCalendar();
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  validRange = { start: '', end: '' };

  overTimeDateArray: any     = [];
  compOffDateArray: any      = [];

  constructor(
    private dutyServ: OnDutyService,
    private authServ: AuthService,
    private fb      : FormBuilder,
    private appServ : AppService,
    private datePipe:DatePipe,
    public cd : ChangeDetectorRef
  ) { }
  ngAfterViewInit(): void {
    setTag();
    //setTag is function to add class to parent element to show tag
    function setTag() {
      var allTagDays = document.querySelectorAll(".leave,.week-off,.holiday,.absent,.half-day");
      for (var i = 0; i < allTagDays.length; i++) {
        if (allTagDays[i].classList.contains('leave')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('leave')
        } else if (allTagDays[i].classList.contains('week-off')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('week-off')
        } else if (allTagDays[i].classList.contains('holiday')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('holiday')
        } else if (allTagDays[i].classList.contains('absent')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('absent')
        } else if (allTagDays[i].classList.contains('half-day')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('half-day')
        }
      }
    }
   //To trigger the setTag() on next and prev month button
   var elements = document.querySelectorAll(".fc-next-button, .fc-prev-button");

   for (var i = 0; i < elements.length; i++) {
     elements[i].addEventListener("click",  () => {
       setTag();
       const calendarApi = this.calendarComponent.getApi()
       const currentDate = calendarApi.view.currentStart;
       this.month = currentDate.getMonth();
       this.year = currentDate.getFullYear();
       this.selectedDate = currentDate
       this.getDatesInfo()
       if(this.formGrp.length!=0){
        this.checkCell()
      }
      if(this.alertOnDutyArray.length!=0){
        this.checkInvalidDate()
      }
     });
   }
  }

  public ngOnInit(): void {
    // HACK Rerender the calendar and correctly display it
    setTimeout(() => { this.calendarOptions.footerToolbar = false; }, 300)
    // Initial API Call
    // this.getDatesInfo();
    // Count
    let count = this.formGrp.getRawValue().length
    if(count  < 10){
      this.dayCount = '0'+count
    }else{
      this.dayCount = count
    }
    // Eligibility
    this.checkEligibility(this.empId)
    if(this.formGrp.length!=0){
      this.checkCell()
    }
    if(this.alertOnDutyArray.length!=0){
      this.checkInvalidDate()
    }
    if(this.data.value.dutyRequest.length!=0){
      let datas = this.data.value.dutyRequest
      datas.forEach((item:any,index:any)=>{
        this.formGrp.at(index).patchValue({
          'onduty_date': item?.onduty_date,
          'checkin_date' : item?.checkin_date,
          'checkout_date' : item?.checkout_date,
          'checkin_time':item?.checkin_time == "" ?  "":item?.checkin_time,
          'checkout_time': item?.checkout_time == "" ? "" : item?.checkout_time,
          'hours': item?.hours == "" ? "" : item?.hours,
          'reason' : item?.reason,
          'shift_mapping': (item?.shift_mapping == "null" ? null:item?.shift_mapping),
          'shift_name' :  (item?.shift_name == "null" ? null:item?.shift_name),
          'shift_checkin_date': item?.onduty_date,
          'shift_checkout_date': this.nightShiftCheckoutDate(item?.onduty_date),

          'allowed_checkin_date'  : item?.allowed_checkin_date,
          'allowed_checkin_time'  : item?.allowed_checkin_time,
          'allowed_checkout_date' : item?.allowed_checkout_date,
          'allowed_checkout_time' : item?.allowed_checkout_time,
        })
      })
    }
  }

  checkCell(){
    let formData = this.formGrp.getRawValue()
    if(formData.length)
    setTimeout(()=>{
      var element = document.getElementsByClassName('fc-day') as HTMLCollection
      for (var i = 0; i < element.length; i++) {
        var day = element[i].getAttribute('data-date')
        for (var j = 0; j < formData.length; j++) {
          if (day == formData[j].onduty_date) {
            element[i].classList.add('selected-day')
              break;
          }
        }
      }
    })
  }

  checkInvalidDate(){
    setTimeout(()=>{
      var element = document.getElementsByClassName('fc-day') as HTMLCollection
      for (var i = 0; i < element.length; i++) {
        var day = element[i].getAttribute('data-date')
        for (var j = 0; j < this.alertOnDutyArray.length; j++) {
          if (day == this.alertOnDutyArray[j]) {
            element[i].classList.add('selected-day')
              break;
          }
        }
      }
    })
  }

  private initCalendar(): CalendarOptions {
    return {
      initialView: 'dayGridMonth',
      height: 'auto',
      stickyHeaderDates: false,
      headerToolbar: {
        start: 'prev',
        center: 'title',
        end: 'next'
      },
      dateClick: this.handleDateClick.bind(this)
    };
  }

  // Date Click
  handleDateClick(dateClickEvent:any){
    if (dateClickEvent.dayEl.classList.contains('fc-day-other') != true && dateClickEvent.dayEl.dataset?.shift_mapping != "null") {
      dateClickEvent.dayEl.classList.toggle("selected-day");
    }

    let range = this.setDateRange(this.get_eligible_days,this.eligibility?.is_allow_backdated_onduty,this.eligibility?.is_future_on_duty)
    for(let  i=0;i< range.length;i++){
      if(range[i] == dateClickEvent.dayEl.dataset.date){
        if(dateClickEvent.dayEl.classList.contains('selected-day')){

            // Pending request, Overtime and Comp off validations start
          if(dateClickEvent?.dayEl?.dataset?.req_pending_status == "true"){
            this.showValidation('Pending');
            dateClickEvent.dayEl.classList.remove('selected-day')
          }else if(dateClickEvent?.dayEl?.dataset?.overtime_processed == "true"){
            this.overTimeDateArray?.push((dateClickEvent?.dayEl?.dataset?.date));
            this.showValidation('Overtime');
          }else if(dateClickEvent?.dayEl?.dataset?.compoff_raised == "true"){
            this.compOffDateArray?.push(dateClickEvent?.dayEl?.dataset?.date);
            this.showValidation('CompOff');
          }else if(dateClickEvent?.dayEl?.dataset?.wfh_raised == "true"){
            this.showValidation('Wfh');
            dateClickEvent.dayEl.classList.remove('selected-day')
          }else if(dateClickEvent?.dayEl?.dataset?.arrear_valid == "false"){
            this.showValidation('Arrear');
            dateClickEvent.dayEl.classList.remove('selected-day')
          }
          // End

          else if(dateClickEvent.dayEl.dataset.shift_mapping !== "null"){
            this.formGrp.push(
              this.fb.group({
                'onduty_date': dateClickEvent.dayEl.dataset?.date,
                'shift_name': (dateClickEvent.dayEl.dataset?.shift_name == "null" ? null:dateClickEvent.dayEl.dataset?.shift_name),
                'checkin_time': dateClickEvent.dayEl.dataset?.starttime == '' ? dateClickEvent.dayEl.dataset?.shift_start_time : dateClickEvent.dayEl.dataset?.starttime,
                'checkout_time': dateClickEvent.dayEl.dataset?.endtime == '' ? dateClickEvent.dayEl.dataset?.shift_end_time : dateClickEvent.dayEl.dataset?.endtime,
                'checkin_date' : (dateClickEvent.dayEl.dataset?.original_checkin_date == '' || dateClickEvent.dayEl.dataset?.original_checkin_date == null || dateClickEvent.dayEl.dataset?.original_checkin_date == 'null') ? dateClickEvent.dayEl.dataset?.date : dateClickEvent.dayEl.dataset?.original_checkin_date,

                'checkout_date' : ((dateClickEvent.dayEl.dataset?.original_checkout_date == '' || dateClickEvent.dayEl.dataset?.original_checkout_date == null || dateClickEvent.dayEl.dataset?.original_checkout_date == 'null') && (dateClickEvent.dayEl.dataset?.shift_start_time > dateClickEvent.dayEl.dataset?.shift_end_time ))? this.nightShiftCheckoutDate(dateClickEvent.dayEl.dataset?.date) : ((dateClickEvent.dayEl.dataset?.original_checkout_date == '' || dateClickEvent.dayEl.dataset?.original_checkout_date == null || dateClickEvent.dayEl.dataset?.original_checkout_date == 'null') && (dateClickEvent.dayEl.dataset?.shift_start_time < dateClickEvent.dayEl.dataset?.shift_end_time ))? dateClickEvent.dayEl.dataset?.date : dateClickEvent.dayEl.dataset?.original_checkout_date,

                'hours': this.toHoursAndMinutes(dateClickEvent.dayEl.dataset?.time),
                'status': dateClickEvent.dayEl.dataset?.status,
                'reason' : '',
                'shift_mapping': (dateClickEvent.dayEl.dataset?.shift_mapping == "null" ? null:dateClickEvent.dayEl.dataset?.shift_mapping),
                'shift_name_code' : dateClickEvent.dayEl.dataset?.shift_name+' ('+dateClickEvent.dayEl.dataset?.shift_code+')',

                'shift_checkin_date': dateClickEvent.dayEl.dataset?.date,
                'shift_checkout_date': this.nightShiftCheckoutDate(dateClickEvent.dayEl.dataset?.date),

                'allowed_checkin_date': dateClickEvent.dayEl.dataset?.allowed_checkin_date,
                'allowed_checkin_time': dateClickEvent.dayEl.dataset?.allowed_checkin_time,
                'allowed_checkout_date': dateClickEvent.dayEl.dataset?.allowed_checkout_date,
                'allowed_checkout_time': dateClickEvent.dayEl.dataset?.allowed_checkout_time,
              })
            )
          }else{
            dateClickEvent.dayEl.classList.remove('selected-day')
          }
        }else{
          let spec_date = dateClickEvent.dayEl.getAttribute('data-date')
          if(this.formGrp.value.findIndex((item:any) => item?.onduty_date === spec_date) >=0){
          this.formGrp.removeAt(this.formGrp.value.findIndex((item:any) => item?.onduty_date === spec_date))
          }

          // Overtime and Compoff validation date removal
          if(this.overTimeDateArray?.length > 0 && dateClickEvent?.dayEl?.dataset?.overtime_processed == "true"){
            const index = this.overTimeDateArray?.indexOf(dateClickEvent?.dayEl?.dataset?.date);
            if (index > -1) {
              this.overTimeDateArray?.splice(index, 1);
            }
            this.showValidation('Overtime');
          }

          if(this.compOffDateArray?.length > 0 && dateClickEvent?.dayEl?.dataset?.compoff_raised == "true"){
            const index = this.compOffDateArray?.indexOf(dateClickEvent?.dayEl?.dataset?.date);
            if (index > -1) {
              this.compOffDateArray?.splice(index, 1);
            }
            this.showValidation('Compoff');

          }
         //  End

        }
      }
      else{
        if(range.every((item:any)=> (item == dateClickEvent.dayEl.dataset.date) == false)){
          if(dateClickEvent.dayEl.classList.contains('selected-day')){
            if(dateClickEvent.dayEl.dataset.shift_mapping !== "null"){
              // this.alertOnDutyArray.push(dateClickEvent.dayEl.dataset.date)
              this.validationFn(dateClickEvent.dayEl.dataset.date)
              dateClickEvent.dayEl.classList.remove('selected-day')
            }
          }
          // else{
          //   const index = this.alertOnDutyArray?.indexOf(dateClickEvent?.dayEl?.dataset?.date);
          //   if (index > -1) {
          //     this.alertOnDutyArray?.splice(index, 1);
          //   }
          //   if(this.alertOnDutyArray.length == 0){
          //     this.removeValidation()
          //   }
          //   this.validationFn(this.alertOnDutyArray)
          // }

        }
      }
    }
    let count = this.formGrp.getRawValue().length
    if(count  < 10){
      this.dayCount = '0'+count
    }else{
      this.dayCount = count
    }
    if(this.overTimeDateArray?.length == 0 && this.compOffDateArray?.length == 0){
      this.removeValidation()
    }
    this.cd.detectChanges()
  }

  // Validation
  validationFn(item:any){
    let hasPreviousDay = false;
    let hasUpcomingDay = false;

    const eligibleDate = this.eligibility?.is_allow_backdated_onduty == false ? moment().format('YYYY-MM-DD') : this.get_eligible_days;

      if(moment(item).isBefore(moment(eligibleDate))){
        hasPreviousDay = true
        if(!this.eligibility?.is_allow_backdated_onduty){
          this.showValidation("OnDutyNoBackDated")
        }else{
          this.showValidation('OnDutyBackDated');
        }
      }else{
        if(moment(item).isAfter(moment())){
          hasUpcomingDay = true
          if(!this.eligibility?.is_future_on_duty){
            this.showValidation('OnDutyFuture');
          }else{
            let exactStart = moment(new Date()).add((this.prior_days+1),'days').format('YYYY-MM-DD')
            if(this.eligibility?.prior_day_for_on_duty > 0 && moment(item).isBetween(moment(),moment(exactStart))){
              this.showValidation('OnDutyPrior');
            }
          }
        }
      }
    if(hasUpcomingDay && hasPreviousDay){
      this.showValidation('BothPresent')
    }
  }

  // Initial Call
  getDatesInfo(){
    this.loader = true
    var count = 0
    this.dutyServ.getDateInfo(this.empId,Number(this.month)+1,this.year).subscribe((res:any)=>{
      this.calendarOptions.events = res.body.data
      let max_days = this.eligibility?.max_on_duty_back_days

      // New Updations regarding backdated days start
      if(this.eligibility?.backdated_onduty_day_type == 'day'){
        this.get_eligible_days = moment(new Date()).subtract(max_days, 'days').format('YYYY-MM-DD');
      }else{
        this.get_eligible_days = this.nMonthsAgo(new Date(),max_days).toLocaleDateString('fr-CA');
      }
      // end

      // this.get_eligible_days = this.nMonthsAgo(new Date(),max_days).toLocaleDateString('fr-CA')
      this.prior_days = this.eligibility?.prior_day_for_on_duty
      let timeFormat = this.appServ.gettimeformat()
      let doj = this.eligibility?.date_of_joined

      let range = this.setDateRange(this.get_eligible_days,this.eligibility?.is_allow_backdated_onduty,this.eligibility?.is_future_on_duty)
      let range_length = range.length
      this.calendarOptions.eventContent = (arg) =>{
        count++;
        var dateString;
         // Get the date mentioned in events
         dateString = moment(arg.event._instance?.range.start).format('YYYY-MM-DD')
         // Get status
         var status_firstHalf  = arg.event.extendedProps.first_half_status;
         var status_secondHalf = arg.event.extendedProps.second_half_status;
         var holiday           = arg.event.extendedProps.holiday;

         var dateStatus = arg.event.extendedProps.status
         var shiftmapping = arg.event.extendedProps.shift_mapping
         // Get the cell from calender which is mentioned in event
         var dateTableCell = document.querySelector('[data-date ="' + dateString + '"]') as HTMLElement
         //Dont add to Prev and Next Month
         if (!dateTableCell?.classList.contains('fc-day-other')) {
           // Add class to table cell accoding to status
           dateTableCell?.classList.add('cell-status');
           if(moment(doj).isAfter(moment(dateString),'date')){
            dateTableCell?.classList.add('status-unknown');
           }else{
            if(shiftmapping == null)
              dateTableCell?.classList.add('status-shift-unassigned');
            else if(holiday)
              dateTableCell?.classList.add('status-holiday');
            else if(status_firstHalf == "Present" && status_secondHalf =="Present")
             dateTableCell?.classList.add('status-present');
            else if(status_firstHalf == "Leave" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-leave');
            else if(status_firstHalf == "Weekoff" && status_secondHalf == "Weekoff")
              dateTableCell?.classList.add('status-weekoff');
            else if(status_firstHalf == "Absent" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-absent');
            else if(status_firstHalf == "Present" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-present-leave');
            else if(status_firstHalf == "Present" && status_secondHalf =="Weekoff")
              dateTableCell?.classList.add('status-present-weekoff');
            else if(status_firstHalf == "Present" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-present-absent');
            else if(status_firstHalf == "Leave" && status_secondHalf =="Present")
              dateTableCell?.classList.add('status-leave-present');
            else if(status_firstHalf == "Leave" && status_secondHalf =="Weekoff")
              dateTableCell?.classList.add('status-leave-weekoff');
            else if(status_firstHalf == "Leave" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-leave-absent');
            else if(status_firstHalf == "Weekoff" && status_secondHalf =="Present")
              dateTableCell?.classList.add('status-weekoff-present');
            else if(status_firstHalf == "Weekoff" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-weekoff-leave');
            else if(status_firstHalf == "Weekoff" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-weekoff-absent');
            else if(status_firstHalf == "Absent" && status_secondHalf =="Present")
              dateTableCell?.classList.add('status-absent-present');
            else if(status_firstHalf == "Absent" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-absent-leave');
            else if(status_firstHalf == "Absent" && status_secondHalf =="Weekoff")
              dateTableCell?.classList.add('status-absent-weekoff');
            // else if(dateString<this.today)
            //   dateTableCell?.classList.add('status-absent');

            // else if (dateStatus == 'present') {
            //  dateTableCell?.classList.add('status-present');
            // } else if (dateStatus == 'first-leave') {
            //   dateTableCell?.classList.add('status-leave-present');
            // }else if (dateStatus == 'second-leave') {
            //   dateTableCell?.classList.add('status-present-leave');
            // }else if (dateStatus == 'first-absent') {
            //   dateTableCell?.classList.add('status-absent-present');
            // }else if (dateStatus == 'second-absent') {
            //   dateTableCell?.classList.add('status-present-absent');
            // }else if (dateStatus == 'leave') {
            //   dateTableCell?.classList.add('status-leave');
            // } else if (dateStatus == 'Weekoff') {
            //   dateTableCell?.classList.add('status-weekoff');
            // } else if (dateStatus == 'holiday') {
            //   dateTableCell?.classList.add('status-holiday');
            // } else if (dateStatus == 'Absent') {
            //   dateTableCell?.classList.add('status-absent');
            // } else if (dateStatus == 'restricted-holiday') {
            //   dateTableCell?.classList.add('status-restricted-holiday');
            // } else if(dateStatus == 'leave-weekoff'){
            //   dateTableCell?.classList.add('status-leave-weekoff');
            // } else if(dateStatus == 'leave-absent'){
            //   dateTableCell?.classList.add('status-leave-absent');
            // } else if(dateStatus == 'present-weekoff'){
            //   dateTableCell?.classList.add('status-present-weekoff');
            // } else if(dateStatus == 'absent-weekoff'){
            //   dateTableCell?.classList.add('status-absent-weekoff');
            // } else if(dateStatus == 'absent-leave'){
            //   dateTableCell?.classList.add('status-absent-leave');
            // } else if(dateStatus == 'weekoff-leave'){
            //   dateTableCell?.classList.add('status-weekoff-leave');
            // } else if(dateStatus == 'weekoff-present'){
            //   dateTableCell?.classList.add('status-weekoff-present');
            // } else if(dateStatus == 'weekoff-absent'){
            //   dateTableCell?.classList.add('status-weekoff-absent');
            // } else if(dateString<this.today)
            //   dateTableCell?.classList.add('status-absent');
           }
            //To Set Needed data attributes so that can be accessed in dateClick()
           dateTableCell.dataset.starttime = arg.event.extendedProps.starttime;
           dateTableCell.dataset.endtime = arg.event.extendedProps.endtime;
           dateTableCell.dataset.time = arg.event.extendedProps.time;
           dateTableCell.dataset.status = arg.event.extendedProps.status;
           dateTableCell.dataset.shift_mapping = arg.event.extendedProps.shift_mapping;
           dateTableCell.dataset.shift_name = arg.event.extendedProps.shift_name;
           dateTableCell.dataset.shift_code = arg.event.extendedProps.shift_code;
           dateTableCell.dataset.compoff_raised     = arg?.event?.extendedProps?.compoff_raised;
           dateTableCell.dataset.overtime_processed = arg?.event?.extendedProps?.overtime_processed;
           dateTableCell.dataset.original_checkin_date = arg?.event?.extendedProps?.original_checkin_date;
           dateTableCell.dataset.original_checkout_date = arg?.event?.extendedProps?.original_checkout_date;
           dateTableCell.dataset.req_pending_status = arg?.event?.extendedProps?.req_pending_status;
           dateTableCell.dataset.shift_start_time = arg?.event?.extendedProps?.shift_start_time;
           dateTableCell.dataset.shift_end_time   = arg?.event?.extendedProps?.shift_end_time;

           dateTableCell.dataset.allowed_checkin_date  = arg?.event?.extendedProps?.allowed_checkin_date;
           dateTableCell.dataset.allowed_checkin_time  = arg?.event?.extendedProps?.allowed_checkin_time;
           dateTableCell.dataset.allowed_checkout_date = arg?.event?.extendedProps?.allowed_checkout_date;
           dateTableCell.dataset.allowed_checkout_time = arg?.event?.extendedProps?.allowed_checkout_time;

           dateTableCell.dataset.wfh_raised = arg?.event?.extendedProps?.wfh_raised;

           dateTableCell.dataset.arrear_valid = arg?.event?.extendedProps?.arrear_valid;

         }
         let timeEvent = document.createElement('div')
         timeEvent.classList.add("time-event");
           //Check-In Time
           let startTime = document.createElement('span')
           startTime.classList.add("start-time");
           if (arg.event.extendedProps.starttime != "" &&
             arg.event.extendedProps.starttime != undefined) {
             startTime.innerHTML = moment(arg.event.extendedProps.starttime,'HH:mm:ss').format(timeFormat)
           } else {
             startTime.innerHTML = '--:--'
           }
           //Check-out time
           let endTime = document.createElement('span')
           endTime.classList.add("end-time");
           if (arg.event.extendedProps.endtime != "" &&
             arg.event.extendedProps.endtime != undefined)  {
             endTime.innerHTML = moment(arg.event.extendedProps.endtime,'HH:mm:ss').format(timeFormat)
           } else {
             endTime.innerHTML = '--:--'
           }
           //Total Work Hour
           let duration = document.createElement('span')
           duration.classList.add("duration");
           if (arg.event.extendedProps.time != "" &&
           arg.event.extendedProps.time != undefined ) {
            // let timeWihMin = moment.utc(Number(arg.event.extendedProps.time)*1000).format('k:mm')

            const durations = moment.duration(Number(arg.event.extendedProps.time), 'seconds');
            const hours = Math.floor(durations.asHours());
            const minutes = durations.minutes();
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            let timeWihMin = formattedHours + ':' + formattedMinutes;

            if(arg.event.extendedProps.overtime_status){
              //Add overtime class if its overtime data
              duration.classList.add("duration","text-warning");
              duration.innerHTML = timeWihMin + " Hrs. OT"
            }else{
              duration.innerHTML = timeWihMin + " Hrs"
            }
           } else {
             duration.classList.add("duration");
               duration.innerHTML =  "-- Hrs"
           }
          //  No Shift Class
          // if(arg.event.extendedProps.shift_mapping == null){
          //   dateTableCell?.classList.add('status-shift-unassigned');
          // }
           //Icon for checkbox
          //  let startDate = range[0]
          //  let endDate = range[range_length-1]
          //  if(moment(arg.event._instance?.range.start,'YYYY-MM-DD').isBetween(startDate,endDate)){
          //   range.forEach((item:any)=>{
          //     if(item!==null && (Number(moment(arg.event._instance?.range.start).format('D')) == item.slice(-2))){
          //       let checkboxIcon = document.createElement('i')
          //       checkboxIcon.classList.add("bi", "checkbox-icon");
          //       if(arg.event.extendedProps.shift_mapping == null){
          //         checkboxIcon.setAttribute('readonly', 'true')
          //       }else{
          //         checkboxIcon.removeAttribute('readonly')
          //       }
          //       timeEvent.append(checkboxIcon)
          //     }
          //   })
          //  }
          let checkboxIcon = document.createElement('i')
          checkboxIcon.classList.add("bi", "checkbox-icon");
          if(arg.event.extendedProps.shift_mapping == null){
            checkboxIcon.setAttribute('disabled', 'true')
          }else{
            checkboxIcon.removeAttribute('disabled')
          }
           timeEvent.append(startTime, endTime, duration, checkboxIcon);
         // }
         let arrayOfDomNodes = [timeEvent]
         return { domNodes: arrayOfDomNodes }
      }
      this.loader = false
    })
  }

  invalidArrayData(){
    return this.alertOnDutyArray
  }

  // Eligibility
  checkEligibility(id:number){
    this.dutyServ.checkEligibility(id).subscribe((res:any)=>{
      this.eligibility = res.body.data
      this.getDatesInfo()
    })
  }

  // Alert
  showValidation(type:any){
    this.alertToggle = true;
    const start = moment(this.get_eligible_days);
    const end = moment();
    const diff = end.diff(start, "days")
    if(type == 'OnDutyBackDated'){
      // this.alertMsg = "You cannot raise more than "+ diff +" backdated request. Please review your request";
      // this.alertMsgModal = "Backdated on-duty request cannot be raised for days prior to "+ diff +" days."
      this.alertMsgModal = "Backdated on-duty request cannot be raised for the selected days as per the policy."
      this.alertToggleModal = true
    }else if(type == 'OnDutyFuture'){
      // this.alertMsg = "You cannot raise on duty request for future dates."
      this.alertMsgModal = "On-duty request for future dates cannot be raised as per the policy."
      this.alertToggleModal = true
    }else if(type == 'OnDutyNoBackDated'){
      // this.alertMsg = "You cannot raise backdated request."
      this.alertMsgModal = "Backdated on-duty request cannot be raised as per the policy."
      this.alertToggleModal = true
    }else if(type == 'OnDutyPrior'){
      // this.alertMsg = "submit an On-Duty request "+this.prior_days+" days before the On-Duty date."
      this.alertMsgModal = "Submit on-duty request " + this.prior_days +" days prior the on-duty date."
      this.alertToggleModal = true
    }
    else if(type == 'Overtime'){

      let dateArray = [];

      for(let i=0;i<this.overTimeDateArray?.length;i++){
        dateArray.push(this.appServ.dateFormatDisplay(this.overTimeDateArray[i]));
      }

      // this.alertMsg = "Overtime has already been processed for the selected dates" +' ('+dateArray?.join(', ')+')'+ ". You cannot raise the on duty request for these dates."

      this.alertMsg = "On duty request cannot be raised as OT hours and attendance has already been processed for this dates" +' ('+dateArray?.join(', ')+')'+ ".";

    }else if(type == 'CompOff'){

      let dateArray = [];

      for(let i=0;i<this.compOffDateArray?.length;i++){
        dateArray.push(this.appServ.dateFormatDisplay(this.compOffDateArray[i]));
      }

      // this.alertMsg = "Compensatory off credited against the selected dates" +' ('+dateArray?.join(', ')+')'+ " has already been availed. You cannot raise on duty request for the selected dates.";

      this.alertMsg = "On duty request cannot be raised, as comp-off for this dates" +' ('+dateArray?.join(', ')+')'+ " has already been availed."
    }else if(type == 'Pending'){
      this.alertMsgModal = "On duty request has already been raised for the selected date."
      this.alertToggleModal = true;
    }else if(type == 'Wfh'){
      this.alertMsgModal = "Work from home request has been raised for this date."
      this.alertToggleModal = true;
    }else if(type == 'Arrear'){
      this.alertMsgModal = "On-duty request cannot be raised prior to the arrear month as per the policy."
      this.alertToggleModal = true;
    }
  }

  removeValidation(){
    this.alertToggle = false;
    this.alertMsg    = '';
    let alertCls     = document.querySelector('.alert') as HTMLElement;
    alertCls && alertCls.classList.remove('show')
  }

  nMonthsAgo(date:any, n:any) {
    const y = date.getFullYear() - Math.trunc(n / 12)
    const m = date.getMonth() - n % 12
    let d = date.getDate()
    if (d > 27) {
      const lastDateofMonth = new Date(y, m + 1, 0).getDate()
      d = Math.min(d, lastDateofMonth)
    }
    // return new Date(y, m, d)
    return new Date(y, m, 1)
  }

  // Submit
  submitDates(){
    this.submitmethod.emit()
  }

   // Modal close for Invalid Extension
  closed(bool:any){
    this.alertToggleModal = bool
  }

  setDateRange(day:any,back:any,future:any){
    let arr : any = []
    var dummyArr : any = []
    let eligibleDate = new Date(day)
    var currentDate = new Date();
    let firstDayOfMonth = this.eligibility.backdated_onduty_day_type == 'day' ? eligibleDate : new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);
    // let lastDayOfMonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);

    let lastDays = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);
    let lastDayOfMonth;

    if(moment(lastDays).format('YYYY-MM-DD') == moment(this.selectedDate).format('YYYY-MM-DD')){
      // If the current date is equal to the last day of the month
      lastDayOfMonth = moment().add(1,'days');
    }else{
      // Other case
      lastDayOfMonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);
    }

    if(back && future){
      // Both are there
      // Checking if current month
      if(this.selectedDate.getMonth() == new Date().getMonth()){
        // If prior days are there
        if(this.eligibility.is_prior_notice_for_on_duty){
          if((firstDayOfMonth.getDate() == new Date().getDate()) && (firstDayOfMonth.getMonth() == new Date().getMonth())){
            // If the date is first day of the month
            let sampleArr : any = []
            sampleArr = this.getDaysArray(firstDayOfMonth,firstDayOfMonth)
            sampleArr.pop()
            dummyArr = sampleArr
          }else{
            dummyArr = this.getDaysArray(firstDayOfMonth,moment().subtract(1, 'day').toDate())
          }
          let exactStart = moment(new Date()).add((this.prior_days+1),'days').format('YYYY-MM-DD')
          let exactEnd = moment(lastDayOfMonth).format('YYYY-MM-DD')
          dummyArr.push(...this.getDaysArray(exactStart,exactEnd))
          arr = dummyArr
        }
        // If no prior days
        else{
          arr = this.getDaysArray(firstDayOfMonth,lastDayOfMonth)
        }
      }
      // if selected less than current month
      else{
        let exactStart = Math.floor(moment(this.selectedDate).diff(moment(eligibleDate),'months',true))
        let prior_date = moment(new Date()).add((this.prior_days+1),'days').format('YYYY-MM-DD')

        // If eligible date comes
        if(exactStart == -1){
          let lastDay = moment(lastDayOfMonth).add(2,'days')
          arr = this.getDaysArray(eligibleDate,lastDay)
        }
        // month till eligible dates
        else if(exactStart >-1){
          // Prior days count exceed current month
          if(this.selectedDate.getMonth() == new Date(prior_date).getMonth()){
            let lastDay = moment(lastDayOfMonth).add(2,'days')
            arr = this.getDaysArray(prior_date,lastDay)
          }else{
            arr = this.getDaysArray(firstDayOfMonth,lastDayOfMonth)
          }
        }
        // If it crosses eligible month
        else{
          let today = moment()
          arr = [today.format('YYYY-MM-DD')]
        }
      }
    }
    // If backdates is true and No future is enabled
    else if(back && !future){
      // If current month
      if(this.selectedDate.getMonth() == new Date().getMonth()){


        if(moment(firstDayOfMonth).format('YYYY-MM-DD') == moment(this.selectedDate).format('YYYY-MM-DD')){
          // If the current date is equal to the first day of the month
          let a =  moment(firstDayOfMonth).subtract(1,'day');
          arr   = this.getDaysArray(a,moment().subtract(1, 'day').toDate());
        }else{
          // Other case
          arr = this.getDaysArray(firstDayOfMonth,moment().subtract(1, 'day').toDate())
        }

        // arr = this.getDaysArray(firstDayOfMonth,moment().subtract(1, 'day').toDate())
      }
      // If less than the current date
      else{
        let exactStart = Math.floor(moment(this.selectedDate).diff(moment(eligibleDate),'months',true))

        // If eligible date comes
        if(exactStart == -1){
          let lastDay = moment(lastDayOfMonth).add(2,'days')
          arr = this.getDaysArray(eligibleDate,lastDay)
        }
        // month till eligible dates
        else if(exactStart <= 4 && exactStart>-1){
          if(moment(this.selectedDate).isSameOrBefore(moment())){
            arr = this.getDaysArray(firstDayOfMonth,lastDayOfMonth)
          }
          else{
            let today = moment()
            arr = [today.format('YYYY-MM-DD')]
          }
        }
        // If it crosses eligible month
        else{
          let today = moment()
          arr = [today.format('YYYY-MM-DD')]
        }
      }
    }else if(!back && future){
        let prior_date = moment(new Date()).add((this.prior_days+1),'days').format('YYYY-MM-DD')
       // Checking if current month
      if(this.selectedDate.getMonth() == new Date().getMonth()){
        // If prior days are there
        if(this.eligibility.is_prior_notice_for_on_duty){
          dummyArr.push(moment(new Date()).format('YYYY-MM-DD'))
          let exactStart = moment(currentDate).add((this.prior_days+1),'days').format('YYYY-MM-DD')
          let exactEnd = moment(lastDayOfMonth).format('YYYY-MM-DD')
          if(new Date(prior_date).getMonth() == this.selectedDate.getMonth()){
            dummyArr.push(...this.getDaysArray(exactStart,exactEnd))
          }else{
            dummyArr = []
            dummyArr.push(...this.getDaysArray(new Date(),new Date()))
          }
          arr = dummyArr
        }
        // If no prior days
        else{
          let lastDay = moment(lastDayOfMonth).add(2,'days')
          arr = this.getDaysArray(new Date(),lastDay)
        }
      // Greater dates than current
      }else{
        if(!(moment(this.selectedDate).isSameOrBefore(moment()))){
          // Prior days count exceed current month
          if(this.selectedDate.getMonth() == new Date(prior_date).getMonth()){
            let lastDay = moment(lastDayOfMonth).add(2,'days')
            arr = this.getDaysArray(prior_date,lastDay)
          }else{
            arr = this.getDaysArray(firstDayOfMonth,lastDayOfMonth)
          }
        }else{
          let today = moment()
          arr = [today.format('YYYY-MM-DD')]
        }
      }
      // If both are not given
    }else{
      // Show only current date because backdate and future are false
      let today = moment()
      arr = [today.format('YYYY-MM-DD')]
    }
    return arr;
  }

    /**
 * Takes two values and returns date range
 * @param a input will be start date
 * @param b input will be end date
 * @returns date range
 */

  getDaysArray = (start:any,end:any) =>{
    for(var arr = [],dt= new Date(start);dt<=new Date(end); dt.setDate(dt.getDate()+1)){
      arr.push(moment(new Date(dt)).format('YYYY-MM-DD'))
      if(dt.getDate()==new Date(end).getDate()){
        arr.push(moment(new Date(end)).add(1,'days').format('YYYY-MM-DD'))
      }
    }
    return arr;
  }

      /**
 * Takes one value and returns value in hours
 * @param a input will be month
 * @returns result in hours
 */
  toHoursAndMinutes(sec:any) {
    const totalMinutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    const hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    const formattedHours: string = (hours.toString().length <2) ? "0" + hours.toString() : hours.toString();
    const formattedMinutes: string = (minutes.toString().length <2) ? "0" + minutes.toString() : minutes.toString();
    return formattedHours+':'+formattedMinutes
  }

  nightShiftCheckoutDate(date:any){
    var day     = new Date(date);
    var nextDay = new Date(day);
    nextDay?.setDate(day.getDate() + 1);
    return nextDay;
  }
}

