<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Holiday Policy </h3>
      <p class="text-helper mb-md-0">Set relevant guidelines for the holiday pay and assign them to the applicable OU’s
      </p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <div class="">
            <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='holidaypolicysearch'
              (searchEmitter)="searchresults($event)"
              (keywordsearchEmitter)="searchholidaypolicy($event)" [isLoading]="isLoading"></app-searchbar>
          </div>


        </div>
        <div class="" style="width:10.6875rem;">
          <ng-select class="form-ngselect " (change)="yearChange($event)" [(ngModel)]="selectedYear"
            placeholder="{{this.messageService.selectplaceholddisplay('year')}}" id="year">
            <ng-option class="fs-12" *ngFor="let years of yearList" [value]="years"> {{years}} </ng-option>

          </ng-select>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';selectItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
          </div>
        </div>
        <button routerLink="/holiday-rule/holiday-policy-setup/new"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a"><i class="icon-plus"></i>Add
          HOLIDAY POLICY</button>
      </div>
    </div>
  </div>
  <app-loader *ngIf="loader"></app-loader>
  <div *ngIf="!hp_list_map_flag && !loader;else all_data " class="row " style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg8.png'" [noDataText]="'Holiday Guidelines!'"
      [noDataPara]="'It looks like your holiday policy isn’t set up yet. Click \'Add holiday policy\' to create one now!'"
      [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16 " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
      <div class="  col-md-3 col-sm-6  " *ngFor="let policy of holidayPolicyDetails">
        <div class="card card-custom1 card-hover">
          <div class="card-body d-flex align-items-start p-0">
            <div class="d-flex  view-section" (click)="viewHolidayPolicy(policy.id)">
              <div class="logo-img {{policy.color_code}}">{{this.appService.getFirstChar(policy.holiday_policy_name,2)}}
              </div>

              <div class="overflow-hidden">
                <h6 class="mb-0 fw-500 text-trim tips">{{policy.holiday_policy_name}}</h6>
                <p class="mb-0  tips-helper">{{policy.holiday_policy_name}}</p>
                <span
                  class="fs-10 text-uppercase fw-500 {{(policy.is_active == true) ? 'text-success' : 'text-danger'}}">{{(policy.is_active
                  == true) ? 'Active' : 'Deleted'}}</span>
              </div>
            </div>
            <div ngbDropdown class="dropdown-section" *ngIf="policy.is_active && (permissions?.e || permissions?.d)">
              <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                <i class="icon-three-dots-vertical fs-16"></i>
              </button>
              <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="editHolidayPolicy(policy.id)" *ngIf="permissions?.e">
                  <i class="icon-pencil-square me-1"></i> Edit
                </button>
                <button class="text-danger" ngbDropdownItem (click)="deleteHolidayPolicy(policy.id)"
                  *ngIf="permissions?.d">
                  <i class="icon-trash  me-1"></i> Delete
                </button>
              </div>
            </div>
          </div>
          <div class="card-body p-16" (click)="viewDetail = true">
            <div class="d-flex w-100">
              <div class="text-light-400 fs-10 text-uppercase me-8">YEAR</div>
              <div class=" fs-12 ms-auto">{{policy.year}}</div>
            </div>
          </div>
        </div>
      </div>
      </ng-container>
    </div>
  </ng-template>

</div>

<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>

    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <ng-container *ngIf="!loader1">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">HOLIDAY POLICY </p>
            <a *ngIf="viewHolidayPolicyDetails.is_active && permissions?.e"
              (click)="editHolidayPolicy(viewHolidayPolicyDetails.id)"
              class="link-primary fs-14 d-flex align-items-center ms-auto">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">HOLIDAY POLICY DETAILS</span></div>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="detail-title">Holiday policy name</div>
                    <div class="detail-desc">{{viewHolidayPolicyDetails?.holiday_policy_name}}</div>
                  </div>

                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="detail-title">Holiday policy year</div>
                    <div class="detail-desc">{{viewHolidayPolicyDetails?.year}}</div>
                  </div>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span
                        class="fs-12 text-uppercase fw-500 {{(viewHolidayPolicyDetails.is_active == true) ? 'text-success' : 'text-danger'}}">{{(viewHolidayPolicyDetails.is_active
                        == true) ? 'Active' : 'Deleted'}}</span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="detail-title">Is floating holiday applicable?</div>
                    <div class="detail-desc">{{viewHolidayPolicyDetails?.is_floating_holiday== true?'Yes':'No'}}</div>
                  </div>
                  <ng-container *ngIf="viewHolidayPolicyDetails?.is_floating_holiday == true">
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="detail-title">Maximum request count</div>
                      <div class="detail-desc">{{viewHolidayPolicyDetails?.max_floating_request}}</div>
                    </div>
                  </ng-container>
                  <div class="col-lg-12 col-sm-6 col-12">
                    <div class="detail-title">Do you want to prorate floating holidays in case of mid year joiners?
                    </div>
                    <div class="detail-desc">{{viewHolidayPolicyDetails?.prorate_floating_holiday == true?'Yes':'No'}}
                    </div>
                  </div>

                  <ng-container *ngFor="let holiday of viewHolidayPolicyDetails.prorated_holiday_policy
                                    ">
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="detail-title">From month</div>

                      <div class="detail-desc">{{holiday?.from_month}}</div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="detail-title">To month</div>
                      <div class="detail-desc">{{holiday?.to_month}}</div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="detail-title">Count</div>
                      <div class="detail-desc">{{holiday?.count_number}}</div>
                    </div>
                  </ng-container>




                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">HOLIDAY MAPPING</span></div>
                  <div class="col-12">
                    <div class="detail-title">Mapped holiday</div>
                    <div class="detail-desc">
                      <div class="d-flex gap-8 flex-wrap mt-8 ">
                        <ng-container *ngFor="let holiday of viewHolidayPolicyDetails.holiday_mapped_ids">
                          <span class="badge w-50ch">{{holiday.holiday_name}}</span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">RIGHTS</span></div>
                  <div class="col-12">
                    <div class="detail-title">Applicable to</div>
                    <div class="detail-desc">{{viewApplicable}}</div>
                  </div>
                  <div class="col-12 ">
                    <div class="detail-title">{{viewApplicable}}(s) </div>
                    <div class="detail-desc">
                      <!-- <div class="d-flex gap-8 flex-wrap mt-8 ">
                                                <ng-container *ngFor="let item of viewRightsArray">
                                                    <span class="badge w-50ch">{{item}}</span>
                                                </ng-container>
                                            </div> -->
                      <div class="selected-values mt-1">
                        <ng-container *ngIf="viewApplicable == 'Employee'">
                          <li class="ng-value" *ngFor="let rights of viewRightsArray, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="viewApplicable == 'Company'">
                          <li class="ng-value" *ngFor="let rights of viewRightsArray, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="(viewApplicable != 'Employee') && (viewApplicable != 'Company')">
                          <div class="d-flex flex-wrap mt-8 gap-8">
                            <ng-container *ngFor="let rights of viewRightsArray, let i=index;">
                              <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                                slice:0:25)+'..':(rights) }}
                              </span>
                            </ng-container>

                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="icon-trash text-danger"></i>
        <h4 class="modal-title">Delete Holiday Policy?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Holiday Policy')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase fw-500"
          (click)="deleteAlert = false">CANCEL</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase fw-500" [disabled]="deleteClicked"
          (click)="confirmDelete()" *ngIf="deleteAlert==true && permissions?.d">DELETE</button>
      </div>
    </div>
  </div>
</div>
